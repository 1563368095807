<li
  *ngIf="date !== undefined && date.active && componentRequestor === 'tableReservation'"
  class="grow {{ date.selected ? 'activeDay' : '' }}"
  style="cursor: pointer"
  (click)="dayClicked()"
  [ngStyle]="{ 'background-color': date.status }">
  {{ date.date.getDate() }}
</li>

<li
  *ngIf="date !== undefined && date.active && componentRequestor === 'conference'"
  class="grow"
  style="cursor: pointer"
  (click)="dayClicked()"
  [ngStyle]="{
    'background-color': date.status,
    color: date.status === stateColor ? 'white' : 'black'
  }">
  {{ date.date.getDate() }}
</li>

<li *ngIf="date !== undefined && date.active === false" [ngStyle]="{ 'background-color': date.status }">
  {{ date.date.getDate() }}
</li>

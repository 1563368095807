import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ApplicationStateService, BasketService, Booking } from 'src/app/core';
import { DateHelper } from 'src/app/helpers';
import { Messenger } from 'src/app/modules/messenger';
import { ExtraServiceWithRoomsInformation } from './add-on.component';
import { BehaviorSubject } from 'rxjs';
import { BookingExtraServiceModel } from '../../core/modules/hotel/data-hotel.service';
import moment from 'moment';

@Component({
  selector: 'app-add-on-card',
  templateUrl: './add-on-card.component.html',
})
export class AddOnCardComponent {
  addOnInformation: AddOnInformation = {
    totalAmount: 0,
    guestCount: 0,
  };

  totalAddOnInformation$ = new BehaviorSubject(this.addOnInformation);
  addOnItem: ExtraServiceWithRoomsInformation | undefined;
  rooms: HotelAddonRoomModel[] | undefined;
  arrivalDate: number | undefined;
  departureDate: number | undefined;
  arrivalName: string | undefined;
  departureName: string | undefined;

  @ViewChild('messageAddOnDialog', { static: false })
  messageAddOnDialog: TemplateRef<HotelAddonModel> | undefined;

  isMaxHeight = false;

  @Output() itemSelect = new EventEmitter<AddOnUpdate[] | undefined>();
  @Input() set addOn(addOn: ExtraServiceWithRoomsInformation | undefined) {
    if (addOn) {
      this.addOnItem = { ...addOn };
    }
  }

  constructor(
    private messenger: Messenger,
    private basketService: BasketService,
    private applicationState: ApplicationStateService,
  ) {}

  setMaxHeight(isMaxHeight: boolean) {
    this.isMaxHeight = isMaxHeight;
  }

  onClick(addOn: ExtraServiceWithRoomsInformation) {
    if (this.addOnItem) {
      const rooms = AddOnCardComponent.calcValidDays(addOn);
      const { syncedRooms, syncedPricing } = this.syncBasket(rooms);
      this.buildDateStringForContentDialog();

      if (this.messageAddOnDialog) {
        this.applicationState.stopScrolling(true);
        this.totalAddOnInformation$.next(syncedPricing);
        const message = this.messenger.show(
          this.messageAddOnDialog,
          {
            addOn,
            rooms: syncedRooms,
            guestIncrement: (day: HotelAddonRoomDayModel, price: number) => {
              if (day.guestSelectCount < day.maxGuestCount) {
                day.guestSelectCount += 1;

                const { guestCount } = this.totalAddOnInformation$.getValue();

                const newGuestCount = guestCount + 1;
                this.totalAddOnInformation$.next({
                  totalAmount: newGuestCount * price,
                  guestCount: newGuestCount,
                });
              }
            },
            guestDecrement: (day: HotelAddonRoomDayModel, price: number) => {
              if (day.guestSelectCount >= 1) {
                day.guestSelectCount -= 1;

                const { guestCount } = this.totalAddOnInformation$.getValue();

                const newGuestCount = guestCount - 1;
                this.totalAddOnInformation$.next({
                  totalAmount: newGuestCount * price,
                  guestCount: newGuestCount,
                });
              }
            },
            accept: () => {
              this.applicationState.stopScrolling(false);
              this.messenger.close(message);

              this.onContentDialogSubmit(addOn, syncedRooms);
            },
            close: () => {
              this.applicationState.stopScrolling(false);
              this.messenger.close(message);
            },
          },
          () => {
            this.applicationState.stopScrolling(false);
            message.data.close();
          },
          'dialog-container-services-messenger',
        );
      }
    }
  }

  onContentDialogSubmit(addOn: ExtraServiceWithRoomsInformation, rooms: HotelAddonRoomModel[]) {
    const roomAddOns: AddOnUpdate[] = [];
    rooms.forEach((room) => {
      const { guestCount, totalAmount } = room.days.reduce(
        (previousValue, currentValue) => {
          return {
            guestCount: previousValue.guestCount + currentValue.guestSelectCount,
            totalAmount: previousValue.totalAmount + currentValue.guestSelectCount * addOn.Amount,
          };
        },
        { guestCount: 0, totalAmount: 0 },
      );

      const dates = room.days
        .map((day) => {
          // Set to start of day
          day.date.setUTCHours(0, 0, 0, 0);

          return {
            Date: day.date.toISOString(),
            selectedCount: day.guestSelectCount,
            maxCount: -1,
          };
        })
        .filter((day) => day.selectedCount > 0);

      const bookingAddon: BookingExtraServiceModel = {
        Amount: totalAmount,
        Id: addOn.Id,
        Count: guestCount,
        Dates: dates,
        Name: addOn.Name,
        ServiceId: addOn.ServiceId,
      };

      const roomAddOn: AddOnUpdate = {
        room: room,
        addOn: bookingAddon,
      };

      roomAddOns.push(roomAddOn);
    });

    this.itemSelect.emit(roomAddOns);
  }

  buildDateStringForContentDialog() {
    const basket = this.basketService.get();

    if (basket) {
      this.arrivalDate = basket.params.ArrivalDate;
      this.departureDate = basket.params.DepartueDate;
      this.arrivalName = basket.params.ArrivalMonthName;
      this.departureName = basket.params.DepartueMonthName;
    }
  }

  get inBasket() {
    const basket = this.basketService.get();
    return basket?.bookings.some((b) => b.AddOns?.some((a) => a.Id === this.addOnItem?.Id));
  }

  get basketInfo(): AddOnInformation {
    const basket = this.basketService.get();

    let guestCount = 0;
    basket?.bookings.forEach((booking) => {
      booking.AddOns?.forEach((addOn) => {
        if (addOn.Id !== this.addOnItem?.Id) {
          return;
        }

        addOn.Dates.forEach((date) => {
          guestCount += date.selectedCount;
        });
      });
    });

    return {
      totalAmount: (this.addOnItem?.Amount ?? 0) * guestCount,
      guestCount: guestCount,
    };
  }

  removeAll(removeAddOn: ExtraServiceWithRoomsInformation) {
    const basket = this.basketService.get();

    if (basket === null) {
      return;
    }

    const newBookings: Booking[] = [];
    basket.bookings.forEach((booking) => {
      const newAddOn = booking.AddOns?.filter((addOn) => addOn.Id !== removeAddOn.Id);

      if (newAddOn) {
        newBookings.push({ ...booking, AddOns: newAddOn });
      } else {
        newBookings.push(booking);
      }
    });

    this.basketService.set({
      ...basket,
      bookings: newBookings,
    });
  }

  private syncBasket(rooms: HotelAddonRoomModel[]): {
    syncedRooms: HotelAddonRoomModel[];
    syncedPricing: AddOnInformation;
  } {
    const basket = this.basketService.get();
    const addOnInBasket = basket?.bookings.some((b) => b.AddOns && b.AddOns.length > 0);
    if (!addOnInBasket || !basket) {
      // Since this is add-ons for seatings only, reduce each room's days to the first, applying the room's maxGuestCount to all days,
      rooms.forEach((room) => {
        room.days = [{ ...room.days[0], maxGuestCount: room.maxGuestCount }];
      });

      return {
        syncedRooms: rooms,
        syncedPricing: {
          guestCount: 0,
          totalAmount: 0,
        },
      };
    }

    const syncedRooms: HotelAddonRoomModel[] = [];
    let guestCount = 0;
    rooms.forEach((room) => {
      // When booking AddOns seatings only (no rooms), always use first booking in basket.
      const onlySeating = basket.params.RateCode === '' && basket.params.BookingFlow === 1;
      const booking = basket.bookings[onlySeating ? 0 : room.roomId];

      if (!booking) {
        return;
      }

      const basketAddOn = booking.AddOns?.find((a) => a.Id === this.addOnItem?.Id);

      if ((booking.AddOns && booking.AddOns.length === 0) || !basketAddOn) {
        syncedRooms.push(room);
        return;
      }
      room.days = room.days.map((day) => {
        const basketDate = basketAddOn.Dates.find((d) => {
          const date = new Date(d.Date);

          // Set UTC Date to the date of the addOn
          date.setUTCDate(date.getDate());
          // Set UTC Date to start of day
          date.setUTCHours(0, 0, 0, 0);

          return DateHelper.isEqual(date, day.date);
        });

        if (basketDate) {
          guestCount += basketDate.selectedCount;
          return {
            date: new Date(basketDate.Date),
            weekDayName: DateHelper.GetTranslatedWeekDay(new Date(basketDate.Date).getDay() - 1),
            maxGuestCount: day.maxGuestCount,
            minGuestCount: 0,
            guestSelectCount: guestCount,
          };
        }

        return day;
      });

      syncedRooms.push(room);
    });

    return {
      syncedRooms,
      syncedPricing: {
        guestCount: guestCount,
        totalAmount: guestCount * (this.addOnItem?.Amount ?? 0),
      },
    };
  }

  private static calcValidDays(addOn: ExtraServiceWithRoomsInformation): HotelAddonRoomModel[] {
    const rooms: HotelAddonRoomModel[] = addOn.rooms.map((b) => {
      return {
        roomId: b.id,
        maxGuestCount: b.maxGuestCount,
        days: [],
      };
    });

    for (const room of rooms) {
      for (const day of addOn.Dates) {
        const date = moment(day.Date).utc().toDate();
        const monthToAdd = new Date().getMonth() !== date.getMonth() && date.getDate() === 1 ? 1 : 0;

        // Set UTC Date to the date of the addOn
        date.setUTCDate(date.getDate());
        // Set UTC Date to start of day
        date.setUTCHours(0, 0, 0, 0);
        // Set UTC month
        date.setUTCMonth(date.getMonth() + monthToAdd);

        // Only add the date if it's not already added, to avoid duplicate dates
        if (!room.days.some((dayCheck) => dayCheck.date.getTime() === date.getTime())) {
          const dateModel = {
            date: date,
            weekDayName: DateHelper.GetTranslatedWeekDay(new Date(day.Date).getDay() - 1),
            maxGuestCount: room && room.maxGuestCount !== undefined ? room.maxGuestCount : 0,
            minGuestCount: 0,
            guestSelectCount: 0,
          } as HotelAddonRoomDayModel;
          room.days.push(dateModel);
        }
      }
    }

    return rooms;
  }
}

export interface AddOnInformation {
  totalAmount: number;
  guestCount: number;
}

export interface HotelAddonModel {
  addOn: ExtraServiceWithRoomsInformation;
  rooms: HotelAddonRoomModel[];
  guestIncrement: (day: HotelAddonRoomDayModel, price: number) => void;
  guestDecrement: (day: HotelAddonRoomDayModel, price: number) => void;
  accept: () => void;
  close: () => void;
}

interface HotelAddonRoomModel {
  roomId: number;
  maxGuestCount: number;
  days: HotelAddonRoomDayModel[];
}

interface HotelAddonRoomDayModel {
  date: Date;
  weekDayName: string;
  maxGuestCount: number;
  minGuestCount: number;
  guestSelectCount: number;
}

export interface AddOnUpdate {
  room: HotelAddonRoomModel;
  addOn: BookingExtraServiceModel;
}

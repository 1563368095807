import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-item',
  templateUrl: './loader-item.component.html',
})
export class LoaderItemComponent {
  @Input() msg: string | undefined;
  @Input() transparent = false;
}

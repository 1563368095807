import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationStateService } from '../../../core';
import { Day } from './products.component';

@Component({
  selector: 'app-calendar-tile',
  templateUrl: './calendar-tile.component.html',
})
export class CalendarTileComponent {
  @Input() componentRequestor: string | undefined;
  @Input() width: number | undefined;
  @Input() fullHeight: number | undefined;
  @Input() date: Day | undefined;
  @Input() month: string | undefined;
  @Output() clickedDay = new EventEmitter<Day>();

  highlight = false;
  stateColor = this.applicationState.ApplicationColor;

  constructor(private applicationState: ApplicationStateService) {}

  dayClicked() {
    this.clickedDay.emit(this.date);
    this.highlight = true;
  }
}

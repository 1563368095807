import { Injectable } from '@angular/core';
import { from, lastValueFrom, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { LogService } from '@com/logging';
import { first, shareReplay, switchMap } from 'rxjs/operators';
import { StorageClient } from 'src/app/helpers';
import { Locale, SupportedLocales, getIsoFromLocale } from 'src/i18n';
import { ConfigService } from '../config';
import { DateAdapter } from '@angular/material/core';

export { Locale };

const CssClasses = {
  [Locale.DK]: 'flag-dk',
  [Locale.DE]: 'flag-de',
  [Locale.GB]: 'flag-gb',
  [Locale.FO]: 'flag-fo',
  [Locale.IS]: 'flag-is',
  [Locale.GL]: 'flag-gl',
  [Locale.SE]: 'flag-se',
  [Locale.NO]: 'flag-no',
};

let translations: Partial<{ [K in Locale]: () => Promise<any> }> = {};

@Injectable()
export class LocaleService {
  private static StoredLocale = new StorageClient(sessionStorage, 'locale', null as string | null);
  static SupportedLocales = SupportedLocales;
  private _locale$ = new Subject<Locale>();
  locale$ = this._locale$.pipe(shareReplay(1));
  translations$ = this.locale$.pipe(switchMap((locale) => from(LocaleService.LoadTranslations(locale))));

  constructor(
    private log: LogService,
    private http: HttpClient,
    private config: ConfigService,
    private dateAdapter: DateAdapter<any>,
  ) {
    if (LocaleService.SupportedLocales.length === 0) {
      alert('Fatal error. No locales are supported by application.');
    }
    this.locale$.subscribe((locale) => LocaleService.StoredLocale.set(locale));
  }

  async getLocale() {
    return await lastValueFrom(this.locale$.pipe(first()));
  }

  async setLocale(locale: Locale) {
    this.dateAdapter.setLocale(getIsoFromLocale(locale));
    await LocaleService.LoadTranslations(locale);
    this._locale$.next(locale);
  }

  async getCultures() {
    const config = await this.config.getAppSettings();
    const cultures = [] as Culture[];
    try {
      const locales = await lastValueFrom(
        this.http.get<GetCulturesResponse>(config.ECommerceDataProvider + '/api/ECommerceData/GetLanguages'),
      );
      // .get<GetCulturesResponse>('https://localhost:44343/api/ECommerceData/GetLanguages')
      if (locales.length === 0) {
        throw new Error('No languages were provided by server');
      }
      const failedCultureCodes = [] as string[];
      locales.forEach((c) => {
        try {
          const locale = LocaleService.ToLocale(c.ISOCode);
          cultures.push({
            Code: locale,
            Name: c.Name,
            CssClass: CssClasses[locale],
          });
        } catch (err) {
          failedCultureCodes.push(c.ISOCode);
        }
      });
      if (failedCultureCodes.length > 0) {
        this.log.error(`Unknown culture codes used: ${failedCultureCodes}`);
      }
      if (cultures.length === 0) {
        throw new Error(
          `Any provided culture is not supported by application. ${JSON.stringify({ cultures, SupportedLocales })}`,
        );
      }
    } catch (err: any) {
      this.log.fatal(`Failed to load cultures from server. Err: ${err.message}`);
      throw new Error('Failed to load available languages');
    }
    return cultures;
  }

  async determineLocale(cultures: Culture[], urlLocale: string | undefined) {
    const prefferedLocales = this.getPrefferedLocales(urlLocale);
    let prefferedLocale = prefferedLocales.find((locale) => !!cultures.find((culture) => culture.Code === locale));
    if (!prefferedLocale) {
      this.log.warn('No preffered locales are available.');
      const backendLanguage = await this.getBackendLocale();
      prefferedLocale = cultures.find((culture) => culture.Code === backendLanguage) ? backendLanguage : undefined;
      if (!prefferedLocale) {
        this.log.error(`Backend language ${backendLanguage} is not supported by application`);
        prefferedLocale = cultures[0].Code;
      }
    }
    return prefferedLocale;
  }

  private async getBackendLocale() {
    const backendLanguage = (await this.config.getInitialSearchParams()).language;
    try {
      return LocaleService.ToLocale(backendLanguage);
    } catch {
      this.log.error(`Backend language ${backendLanguage} is not supported by application`);
      return undefined;
    }
  }

  private getBrowserLocales(): string[] {
    const n: Navigator & { userLanguage?: string } = navigator;
    let locales: string[] = [];
    if (n.language) {
      locales.push(n.language);
    }
    if (n.userLanguage) {
      locales.push(n.userLanguage);
    }
    if (n.languages instanceof Array) {
      locales = locales.concat(n.languages);
    }
    return locales;
  }

  private getPrefferedLocales(urlLocale: string | undefined) {
    const languages: Locale[] = [];
    if (urlLocale) {
      const language = LocaleService.SupportedLocales.find((l) => l === urlLocale);
      if (language) {
        languages.push(language);
      } else {
        this.log.error(`${urlLocale} provided in URL is not supported.`);
      }
    }
    const storedLocale = LocaleService.StoredLocale.get();
    if (storedLocale) {
      const language = LocaleService.SupportedLocales.find((l) => l === storedLocale);
      if (language) {
        languages.push(language);
      } else {
        this.log.error(`Stored language ${storedLocale} is not supported by application`);
      }
    }
    const browserLocales = this.getBrowserLocales();
    browserLocales.forEach((l) => {
      LocaleService.SupportedLocales.forEach((supportedLocale) => {
        if (supportedLocale.substring(0, 2) === l.substring(0, 2)) {
          languages.push(supportedLocale);
        }
      });
    });
    if (languages.length === 0) {
      this.log.info('There are no available browser languages application can use');
    }
    return languages;
  }

  static ToLocale(cultureCode: string) {
    const locale = LocaleService.SupportedLocales.find((l) => l === (cultureCode as Locale));
    if (!locale) {
      throw new Error(`${cultureCode} language is not supported`);
    }
    return locale;
  }

  static async PopulateTranslations() {
    translations = SupportedLocales.reduce((previousValue, currentValue) => {
      previousValue[currentValue] = async () =>
        (
          await import(
            /* webpackChunkName: "[request]" */
            /* webpackMode: "eager" */
            `src/i18n/locale-${currentValue}`
          )
        ).messages;
      return previousValue;
    }, {} as typeof translations);
  }

  static async LoadTranslations(locale: Locale) {
    if (Object.keys(translations).length === 0) {
      await LocaleService.PopulateTranslations();
    }

    return translations[locale]?.();
  }
}

type GetCulturesResponse = {
  ISOCode: string;
  Name: string;
  ImageUrl: string;
}[];

export interface Culture {
  Code: Locale;
  Name: string;
  CssClass: string;
}

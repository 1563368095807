import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LogService } from '@com/logging';
import { ApplicationStateService, CustomerService, Loader, LocaleService } from 'src/app/core';
import { Messenger } from 'src/app/modules/messenger';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent implements OnInit {
  form = {
    email: '',
    password: '',
  };
  recovery = false;
  hasError = false;
  @Output() formSubmit = new EventEmitter();

  @ViewChild('messageSendTemplate', { static: false }) messageSendTemplate: TemplateRef<MessageSend> | undefined;

  constructor(
    private customerService: CustomerService,
    private loader: Loader,
    private log: LogService,
    private messenger: Messenger,
    private applicationState: ApplicationStateService,
    private locale: LocaleService,
  ) {}

  async ngOnInit() {}

  onRecoverClick() {
    this.hasError = false;
    this.recovery = true;
  }

  onBackToLoginClick() {
    this.hasError = false;
    this.recovery = false;
  }

  async onLoginFormSubmit(f: NgForm) {
    this.hasError = false;
    if (f.valid) {
      await this.loader.using(async () => {
        try {
          await this.customerService.login(this.form.email.trim(), this.form.password);
          this.formSubmit.emit();
        } catch (err) {
          this.hasError = true;
          this.log.info(`LoginForm login failed with error: ${(err as Error).message}`);
        }
      }, 'LOA_LoggingIn');
    } else {
      Object.keys(f.form.controls).forEach((key) => {
        const control = f.form.get(key);
        if (control) {
          control.markAsTouched();
        }
      });
    }
  }

  async onRecoveryFormSubmit(f: NgForm) {
    this.hasError = false;
    if (f.valid) {
      await this.loader.using(async () => {
        try {
          const lang = await this.locale.getLocale();

          await this.customerService.resetCustomerByEmail(
            this.form.email,
            this.applicationState.CurrentHotelCode,
            lang,
          );
          this.recovery = false;
          this.showMessageSendMessage();
          this.formSubmit.emit();
        } catch (err) {
          this.hasError = true;
          this.log.info(`LoginForm recovery failed with error: ${(err as Error).message}`);
        }
      }, 'LOA_SendingInformation');
    } else {
      Object.keys(f.form.controls).forEach((key) => {
        const control = f.form.get(key);
        if (control) {
          control.markAsTouched();
        }
      });
    }
  }

  private showMessageSendMessage() {
    if (!this.messageSendTemplate) {
      throw new Error('Template for message send message must exist');
    }
    const message = this.messenger.show(
      this.messageSendTemplate,
      {
        close: () => {
          this.messenger.close(message);
        },
      },
      () => {
        message.data.close();
      },
    );
  }
}

interface MessageSend {
  close: () => void;
}

<div class="overlay {{ transparent ? '' : 'overlay-filled' }} overlay-main">
  <div class="loader-box">
    <div class="sk-chase">
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
    </div>
  </div>
  <span *ngIf="msg" class="load-message" id="loader-text">{{ msg }}</span>
  <span *ngIf="!msg" class="load-message" id="loader-text">Loading content</span>
</div>

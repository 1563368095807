import {
  CustomError,
  DateHelper,
  InvalidCustomerError,
  InvalidPromocodeError,
  PromocodeIsOutsideRangeError,
  UTCDateString,
} from 'src/app/helpers';

export interface RoomLookupErrorReasonObj {
  t: string;
  p?: { [key: string]: any };
}

abstract class RoomLookupErrorReason {
  params?: { [key: string]: any };

  abstract getType(): string;

  toJSON() {
    const obj: RoomLookupErrorReasonObj = {
      t: this.getType(),
      p: this.params,
    };
    return JSON.stringify(obj);
  }
}

export class RoomsNotAvailableReason extends RoomLookupErrorReason {
  static Type = 'NothingAvailable';
  getType() {
    return RoomsNotAvailableReason.Type;
  }
}

export class RoomsSoldOutReason extends RoomLookupErrorReason {
  static Type = 'SoldOut';
  getType() {
    return RoomsSoldOutReason.Type;
  }
}

export class CustomerInvalidReason extends RoomLookupErrorReason {
  static Type = 'CustomerInvalid';
  getType() {
    return CustomerInvalidReason.Type;
  }
}

export class RoomsPromocodeInvalidReason extends RoomLookupErrorReason {
  static Type = 'PromocodeInvalid';
  getType() {
    return RoomsPromocodeInvalidReason.Type;
  }
}

export class RoomsPromocodeOutsideRangeReason extends RoomLookupErrorReason {
  static Type = 'PromocodeOutsideRange';

  params: {
    start: Date;
    end: Date;
  };

  constructor(params: { [key: string]: string }) {
    super();
    if (!params.start || !params.end) {
      throw new Error('Passed data is not correct PromocodeOutsideRange');
    } else {
      this.params = {
        start: DateHelper.FromUtcDate(params.start as UTCDateString),
        end: DateHelper.FromUtcDate(params.end as UTCDateString),
      };
    }
  }

  getType() {
    return RoomsPromocodeOutsideRangeReason.Type;
  }
}

export class RoomLookupError extends Error {
  static readonly Type = 'RoomLookupError';
  readonly type = RoomLookupError.Type;
  constructor(reason: RoomLookupErrorReason) {
    super(reason.toJSON());
  }

  static Handle(err: Error) {
    if ((err as CustomError).type === InvalidCustomerError.Type) {
      throw new RoomLookupError(new CustomerInvalidReason());
    }
    if ((err as CustomError).type === InvalidPromocodeError.Type) {
      throw new RoomLookupError(new RoomsPromocodeInvalidReason());
    }
    if ((err as CustomError).type === PromocodeIsOutsideRangeError.Type) {
      const error = err as PromocodeIsOutsideRangeError;
      throw new RoomLookupError(
        new RoomsPromocodeOutsideRangeReason({
          start: error.start,
          end: error.end,
        }),
      );
    }
    throw err;
  }
}

<div
  class="seating"
  [ngStyle]="{ 'max-height': isMaxHeight ? '200px' : null }"
  (click)="onNavigate()"
  appMaxHeight
  maxHeight="200"
  (isMaxHeight)="setMaxHeight($event)">
  <div class="seating__image" [ngStyle]="{ 'background-image': 'url(' + imageSrc + ')' }" *ngIf="imageSrc"></div>
  <div class="seating__no-image" *ngIf="!imageSrc">
    <span class="fa fa-camera"></span>
    {{ 'RPA_Image' | t | async }}
  </div>
  <div class="seating__content">
    <div class="seating__content-header" [innerHTML]="header"></div>
    <div class="seating__content-text" [innerHTML]="description"></div>
    <div class="seating__content-text--overlay" *ngIf="isMaxHeight"></div>
  </div>
</div>

import { Injectable, NgZone, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Messenger {
  messages$ = new BehaviorSubject<Message<any>[]>([]);

  constructor(private zone: NgZone) {}

  show<T>(template: TemplateRef<T>, data: T, onOverlayClick?: () => void, customId?: string) {
    const message: Message<T> = {
      template,
      data,
      onOverlayClick,
      customId: customId,
    };
    this.zone.run(() => {
      // ensure that all messages are shown and picked-up by angular
      this.messages$.next([...this.messages$.value, message]);
    });
    return message;
  }

  close<T>(message: Message<T>) {
    this.messages$.next(this.messages$.getValue().filter((x) => x !== message));
  }
}

export interface Message<T> {
  template: TemplateRef<T>;
  data: T;
  onOverlayClick?: () => void;
  customId?: string;
}

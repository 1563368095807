import { Injectable } from '@angular/core';
import { Country } from './country';

@Injectable()
export class CountryService {
  static Default: Country = {
    AreaCode: '+45',
    Code: 'DK',
    Name: 'Denmark',
  };

  constructor() {}

  async getCountries() {
    return (
      await import(
        /* webpackChunkName: "countries" */
        /* webpackMode: "eager" */
        './countries'
      )
    ).COUNTRIES;
  }

  async getCountry(code: string) {
    const country = (await this.getCountries()).find((c) => c.Code === code);
    if (country) {
      return country;
    } else {
      throw new Error(`Country with '${code}' code name does not exists`);
    }
  }
}

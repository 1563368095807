<div class="step-list">
  <div class="overel-container">
    <div class="overel overel-center">
      <div *ngFor="let step of steps$ | async" class="step-list-item" [ngClass]="{ active: step.active }">
        <i *ngIf="step.complete" class="fas fa-check text-success"></i>
        <i *ngIf="step.error" class="fas fa-times text-danger"></i>
        <span *ngIf="!step.complete && !step.error">
          {{ step.title }}
        </span>
      </div>
    </div>
  </div>
</div>

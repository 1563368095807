import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { Subject } from 'rxjs';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  private lastError: Error | undefined;
  errors = new Subject<Error>();

  constructor(private log: LogService) {
    this.errors.subscribe((err) => {
      if (!err.message.includes('SoldOut')) {
        this.log.error(`Global error happend: ${err.message}.`);
      }
    });
  }

  handleError(err: UncaughtPromiseError) {
    let error: Error = err;
    if (err.rejection && err.rejection instanceof Error) {
      error = err.rejection;
    }
    console.error(error);
    if (!this.lastError || error.name !== this.lastError.name || error.message !== this.lastError.message) {
      this.lastError = error;
      this.errors.next(error);
      setTimeout(() => (this.lastError = undefined), 0); // ignore all similar errors executed in one EventFrame
    }
  }
}

interface UncaughtPromiseError extends Error {
  promise: Promise<any>;
  rejection: Error;
}

import { Pipe, PipeTransform } from '@angular/core';

import { map } from 'rxjs/operators';
import { LocaleService } from 'src/app/core';
import { /*SpectraDate,*/ AspDateString, DateHelper } from 'src/app/helpers';

@Pipe({ name: 'date' })
export class DatePipe implements PipeTransform {
  constructor(private ls: LocaleService) {}

  transform(date: /*SpectraDate | */ Date | undefined, daysShift: number | undefined) {
    return this.ls.translations$.pipe(
      map((translations) => {
        /*if (date instanceof SpectraDate) {
          const d = daysShift ? date.addDays(daysShift) : date;
          const month = translations[
            `CAL_M${DateHelper.format(d, 'MM')}` as 'CAL_M01'
          ].substring(0, 3);
          return DateHelper.format(d, `DD[.] [${month}] YYYY`);
        }

        else */ if (date instanceof Date) {
          const d = daysShift ? DateHelper.addDays(date, daysShift) : date;
          const month = translations[`CAL_M${DateHelper.format(d, 'MM')}` as 'CAL_M01'].substring(0, 3);
          return DateHelper.format(d, `DD[.] [${month}] YYYY`);
        } else if (typeof date === 'string') {
          const _date = DateHelper.FromAspDate(date as AspDateString);
          const d = daysShift ? DateHelper.addDays(_date, daysShift) : _date;
          const month = translations[`CAL_M${DateHelper.format(d, 'MM')}` as 'CAL_M01'].substring(0, 3);
          return DateHelper.format(d, `DD[.] [${month}] YYYY`);
        }

        return '';
      }),
    );
  }
}

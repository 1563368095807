import { Pipe, PipeTransform } from '@angular/core';

import { map } from 'rxjs/operators';
import { LocaleService } from 'src/app/core';
import { Messages } from 'src/i18n';

@Pipe({ name: 't' })
export class TranslatePipe implements PipeTransform {
  constructor(private ls: LocaleService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(key: keyof Messages, ...params: string[]) {
    return this.ls.translations$.pipe(
      map((translations) => {
        const value = translations[key];
        if (value !== undefined) {
          return value;
        } else {
          return 'Error';
        }
      }),
    );
  }
}

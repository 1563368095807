import { Injectable } from '@angular/core';
import { HotelCodeString } from '../config';
import { Locale } from '../locale';
import { DataHotelService, GiftcardReservationPayment, GuestCombination } from './data-hotel.service';

@Injectable()
export class HotelService {
  currentLang: Locale | undefined;

  constructor(private dataHotelService: DataHotelService) {}

  // New endpoints
  async GetECommerceExtraServiceInfo(
    hotelCode: string,
    itemNumber: string,
    lang: string,
    ratePlan?: string,
    roomType?: string,
  ) {
    return await this.dataHotelService.GetECommerceExtraServiceInfo(hotelCode, itemNumber, lang, ratePlan, roomType);
  }

  async GetECommerceDepartmentInformation(hotelCode: string, lang: string) {
    return await this.dataHotelService.GetECommerceDepartmentInformation(hotelCode, lang);
  }

  async GetECommerceDepartmentsInformation() {
    return await this.dataHotelService.GetECommerceDepartmentsInformation();
  }

  async GetECommerceRoomInfo(hotelCode: string, roomCode: string, lang: string) {
    return await this.dataHotelService.GetECommerceRoomInfo(hotelCode, roomCode, lang);
  }

  async GetECommerceRateInfo(hotelCode: string, rateCode: string, lang: string) {
    return await this.dataHotelService.GetECommerceRateInfo(hotelCode, rateCode, lang);
  }

  async IsSeatingsAvailable(hotelCode: string) {
    return await this.dataHotelService.IsSeatingsAvailable(hotelCode);
  }

  async IsTablesAvailable(hotelCode: string) {
    return await this.dataHotelService.IsTablesAvailable(hotelCode);
  }

  async GetECommerceSeatingsMonths(hotelCode: string, fromDate: Date, toDate: Date, bookingflow: boolean) {
    return await this.dataHotelService.GetECommerceSeatingsAvailableMonths(hotelCode, fromDate, toDate, bookingflow);
  }

  async GetRoomAvailability(
    hotelCode: string,
    fromDate: Date,
    toDate: Date,
    rateCode: string | undefined,
    companyId: number | undefined,
  ) {
    return await this.dataHotelService.GetStayAvailability(hotelCode, fromDate, toDate, rateCode, companyId);
  }

  async GetECommerceSeatings(hotelCode: string, fromDate: Date, toDate: Date, lang: string, distinctByDate = false) {
    return await this.dataHotelService.GetECommerceSeatings(hotelCode, fromDate, toDate, lang, distinctByDate);
  }

  async GetEcommerceSeating(hotelCode: string, fromDate: Date, toDate: Date, lang: string, characteristics: string) {
    return await this.dataHotelService.GetECommerceSeating(hotelCode, fromDate, toDate, lang, characteristics);
  }

  async GetECommerceSeatingPortalInfo(
    hotelCode: string,
    fromDate: Date,
    todate: Date,
    seatingCode: string,
    bookingflow: boolean,
    rateCode: string,
  ) {
    return await this.dataHotelService.GetECommerceSeatingPortalInfo(
      hotelCode,
      fromDate,
      todate,
      seatingCode,
      bookingflow,
      rateCode,
    );
  }

  async GetECommerceTablePortalInfo(
    hotelCode: string,
    fromDate: Date,
    toDate: Date,
    seatingCode: string,
    lang: string,
    bookingflow: boolean,
    rateCode: string,
  ) {
    return await this.dataHotelService.GetECommerceTablePortalInfo(
      hotelCode,
      fromDate,
      toDate,
      seatingCode,
      lang,
      bookingflow,
      rateCode,
    );
  }

  async GetECommerceConferenceRoomsInfo(hotelCode: string, lang: string) {
    return await this.dataHotelService.GetECommerceConferenceRoomsInfo(hotelCode, lang);
  }

  async GetECommerceConferenceRoomInfo(hotelCode: string, lang: string, roomCode: string) {
    return await this.dataHotelService.GetECommerceConferenceRoomInfo(hotelCode, lang, roomCode);
  }

  async GetSpectraRoomInfo(
    hotelCode: string,
    fromdate: Date,
    todate: Date,
    rateplan: string,
    guests: GuestCombination[],
    language: string,
    companyId: number | undefined,
  ) {
    return await this.dataHotelService.GetSpectraRoomInfo(
      hotelCode,
      fromdate,
      todate,
      rateplan,
      guests,
      language,
      companyId,
    );
  }

  async GetSpectraRateInfo(
    hotelCode: string,
    fromdate: Date,
    todate: Date,
    roomCode: string,
    guests: GuestCombination[],
    language: string,
    companyId: number | undefined,
  ) {
    return await this.dataHotelService.GetSpectraRateInfo(
      hotelCode,
      fromdate,
      todate,
      roomCode,
      guests,
      language,
      companyId,
    );
  }

  async getTableAvailability(
    hotelCode: HotelCodeString,
    arrival: Date,
    departure: Date,
    guestCount: number,
    bookingFlow: boolean,
    rateCode: string,
  ) {
    return await this.dataHotelService.getTableAvailability(
      hotelCode,
      arrival,
      departure,
      guestCount,
      bookingFlow,
      rateCode,
    );
  }

  async payAmountWithGiftcard(model: GiftcardReservationPayment) {
    return await this.dataHotelService.payAmountWithGiftcard(model);
  }

  async BuildApplicationState() {
    await this.dataHotelService.initializeImportantDateToApplicationState();
  }

  async GetCancellationProtection(hotelCode: string) {
    return await this.dataHotelService.GetSpectraCancellationprotection(hotelCode);
  }

  async GetCompanyAgreementDiscount(hotelCode: string, companyId: number) {
    return await this.dataHotelService.GetCompanyAgreementDiscount(hotelCode, companyId);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Loader } from './loader';
import { LoaderItemComponent } from './loader-item.component';
import { LoaderComponent } from './loader.component';

@NgModule({
  imports: [CommonModule],
  providers: [Loader],
  declarations: [LoaderComponent, LoaderItemComponent],
  exports: [LoaderComponent, LoaderItemComponent],
})
export class LoaderModule {}

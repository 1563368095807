import { Component, OnInit } from '@angular/core';
import { LogService } from '@com/logging';
import { ApplicationStateService, OriginEnum } from '../../core';
import { BookingService } from '../../core/services/booking.service';
import { NavigationHelper } from '../../helpers/navigation-helper';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
})
export class PaymentFailedComponent implements OnInit {
  constructor(
    private log: LogService,
    private navigationHelper: NavigationHelper,
    private bookingService: BookingService,
    private applicationState: ApplicationStateService,
  ) {}

  async ngOnInit() {
    this.log.debug(`PaymentFailedRoute ngOnInit()`);
    window.scrollTo(0, 0);

    if (this.applicationState.Origin !== OriginEnum.PaymentLink) {
      await this.cancelReservation();
    }
  }

  async cancelReservation() {
    const res = this.bookingService.getReservationOrFail();

    if (this.applicationState.IsReservationFromPaymentLink(res)) {
      this.log.debug(`Reservation with reservationId ${res.reservationId} was created by payment link!`);
      return;
    }

    this.log.warn(
      `Reservation with reservationId ${res.reservationId} was cancelled due to an error when creating reservation or creating payment!`,
    );

    await this.bookingService.CancelReservation(res.reservationId);
  }

  async next() {
    await this.navigationHelper.continue('/payment-failed', '/customer');
  }
}

import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class Loader {
  private messages$ = new BehaviorSubject([] as LoaderMessage[]);
  message$ = this.messages$.pipe(
    map((messages) =>
      messages.length > 0
        ? {
            ...messages[0],
            transparent: messages.every((m) => m.transparent), // if at least one is not transparent then every must be not transparent
          }
        : undefined,
    ),
  );

  constructor(protected log: LogService) {}

  show(msg?: string, transparent = true) {
    return this.addMessage(msg, transparent);
  }

  hideAll() {
    this.messages$.value.forEach((message) => message.hide());
  }

  async using<T>(action: (loader: LoaderMessage) => Promise<T>, msg?: string, transparent = true) {
    const loader = this.show(msg, transparent);
    try {
      return await action(loader);
    } finally {
      loader.hide();
    }
  }

  private addMessage(msg: string | undefined, transparent: boolean, timeout = 120000) {
    const message = {
      msg,
      transparent,
      hide: () => this.hide(message),
    };
    this.messages$.next([message, ...this.messages$.value]);
    const err = new Error();
    setTimeout(() => {
      const messageIndex = this.messages$.value.indexOf(message);
      if (messageIndex !== -1) {
        err.message = `LoaderService is not closed before timeout(${timeout})`;
        this.log.error(err.message, err.stack);
        message.hide();
      }
    }, timeout);
    return message;
  }

  private hide(m: LoaderMessage) {
    this.log.trace(`LoaderService hide(${m.msg}, ${m.transparent})`);
    this.messages$.next(this.messages$.value.filter((x) => x !== m));
  }
}

export interface LoaderMessage {
  msg?: string;
  transparent: boolean;
  hide: () => void;
}

<div class="container" style="margin-top: 2%">
  <a class="desktop-show mobile-hide applicationColor" style="position: absolute; font-size: 20px" (click)="goBack()"
    ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
  >

  <h2 style="text-align: center; margin-top: 20px">
    <span class="v-middle">Dine søgekriterier gav desværre ingen resultater</span>
  </h2>

  <hr
    style="
      height: 2.5px;
      border: none;
      margin-top: 40px;
      margin-bottom: 40px;
      background: -webkit-gradient(
        linear,
        0 0,
        100% 0,
        from(transparent),
        to(transparent),
        color-stop(50%, var(--primary-application-color))
      );
    " />
</div>

<div class="container applicationColor" *ngIf="dates">
  <!--<div>
    <p style="color:orangered">Dit ophold skal minimum være 4 dage i denne periode. Se kalender for at ændre dit ophold.</p>
  </div>-->
  <div class="cal-frame">
    <ul class="weekdays-guid">
      <li>{{ 'CAL_Mon' | t | async }}</li>
      <li>{{ 'CAL_Tirs' | t | async }}</li>
      <li>{{ 'CAL_Ons' | t | async }}</li>
      <li>{{ 'CAL_Tors' | t | async }}</li>
      <li>{{ 'CAL_Fre' | t | async }}</li>
      <li>{{ 'CAL_Lor' | t | async }}</li>
      <li>{{ 'CAL_Sun' | t | async }}</li>
      <li>Week</li>
    </ul>
    <div class="cal-col">
      <ul class="day">
        <li
          *ngFor="let day of dates.days"
          [ngClass]="{
            dayAvailable: day.selected,
            grow: !day.weekNumber && !day.selected && day.active
          }"
          [ngStyle]="{
            'background-color': !day.weekNumber ? day.status : '',
            cursor: !day.weekNumber && !day.selected && day.active ? 'pointer' : ''
          }"
          (click)="changeArrival(day)">
          <span *ngIf="!day.weekNumber">
            {{ day.date.getDate() }}
          </span>
          <span *ngIf="day.weekNumber">{{ day.weekNumber }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div style="margin-top: 25px; display: flex; background-color: #eeeeee; padding: 5px">
    <div style="width: 25px; height: 25px; background-color: #f87e7d; margin-right: 5px"></div>
    <p>{{ 'CAL_Occupied' | t | async }}</p>

    <div style="width: 25px; height: 25px; background-color: white; margin-left: 10px; margin-right: 5px"></div>
    <p>{{ 'CAL_Available' | t | async }}</p>

    <div style="width: 25px; height: 25px; background-color: #ffb759; margin-left: 10px; margin-right: 5px"></div>
    <p>{{ 'CAL_Arrival' | t | async }}</p>

    <div style="width: 25px; height: 25px; background-color: #729ef7; margin-left: 10px; margin-right: 5px"></div>
    <p>{{ 'CAL_Departure' | t | async }}</p>

    <div style="width: 25px; height: 25px; border: 2px solid #75bfa0; margin-left: 10px; margin-right: 5px"></div>
    <p>Valgt</p>
  </div>
  <button (click)="moveNext()" style="margin-left: 20px" class="btn btn-primary with-shadow" id="stayBooking">
    {{ 'COM_Continue' | t | async }}
  </button>
</div>

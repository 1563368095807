import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from '../../../../projects/com-logging/src/public-api';
import { theme } from '../../../styles/theme';
import {
  ApplicationStateService,
  BasketQueryParams,
  ConfigService,
  HotelCodeString,
  HotelService,
  Loader,
  LocaleService,
} from '../../core';

@Injectable()
export class DesignConfiguration {
  constructor(
    private loader: Loader,
    private hotelService: HotelService,
    private applicationState: ApplicationStateService,
    private log: LogService,
    private config: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private localeService: LocaleService,
  ) {}

  async addPageBackground() {
    await this.loader.using(
      async () => {
        await this.hotelService.BuildApplicationState();
        const res = this.applicationState.BackGroundimage;
        if (res && !res.includes('no-image')) {
          if (this.applicationState.AnimateBackground === 'false') {
            document.body.classList.remove('addBackGroundWithAnimation');
            document.body.classList.add('addBackGround');
          } else {
            document.body.classList.add('addBackGroundWithAnimation');
          }
          document.body.style.backgroundImage = 'url(' + res + ')';
          const w = document.documentElement.clientWidth;
          if (w <= 1170) {
            document.body.classList.add('addBackGround');
            document.body.classList.remove('addBackGroundWithAnimation');
          }
        } else {
          document.body.classList.remove('addBackGround');
          document.body.style.backgroundImage = '';
        }
      },
      'LOA_HotelInformation',
      false,
    );
  }

  addProductScrolling() {
    const w = document.documentElement.clientWidth;
    if (w <= 1170) {
      return true;
    } else {
      return false;
    }
  }

  async applyTheme() {
    await this.loader.using(
      async () => {
        try {
          const url = new URL(window.location.href);
          const hotelCode = url.searchParams.get('HotelCode')
            ? url.searchParams.get('HotelCode')
            : this.applicationState.CurrentHotelCode;
          if (hotelCode) {
            const res = await this.hotelService.GetECommerceDepartmentInformation(
              this.applicationState.CurrentHotelCode,
              await this.localeService.getLocale(),
            );
            this.applicationState.ApplicationColor = res.Settings.CustomColor as string;
            this.applicationState.ApplicationColorTextIcon = res.Settings.CustomColorTextIcon as string;
          }
          await this.hotelService.BuildApplicationState();
          this.applicationState.ApplicationColor = await this.RestrictColor(
            this.applicationState.ApplicationColor,
            (
              await this.config.getAppSettings()
            ).PrimaryColor,
          );
          this.applicationState.ApplicationColorTextIcon = await this.RestrictColor(
            this.applicationState.ApplicationColorTextIcon,
            (
              await this.config.getAppSettings()
            ).PrimaryColor,
          );

          document.documentElement.style.setProperty(
            '--primary-application-color',
            this.applicationState.ApplicationColor,
          );
          document.documentElement.style.setProperty(
            '--primary-application-color-text-icon',
            this.applicationState.ApplicationColorTextIcon,
          );
          document.documentElement.style.setProperty(
            '--primary-application-font',
            this.applicationState.ApplicationFont,
          );

          this.config.applyStyles(theme(this.applicationState.ApplicationColor));
          this.decideDynamicTextColor(this.applicationState.ApplicationColor);
        } catch (err: any) {
          this.log.fatal(`App applyTheme() failed. ${err.message}`);
        }
      },
      undefined,
      false,
    );
  }

  async applyHotelChange(hotelCode: HotelCodeString) {
    const queryParams: Partial<BasketQueryParams> = {
      HotelCode: hotelCode,
    };
    await this.router.navigate([], {
      replaceUrl: true,
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams,
    });
  }

  private async RestrictColor(color: string, fallback: string) {
    const RestrictedColors = ['', 'none', 'inherit', 'initial'];
    return RestrictedColors.some((restrictedColor) => restrictedColor === color) ? fallback : color;
  }

  private decideDynamicTextColor(color: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

    let textColor = getComputedStyle(document.documentElement).getPropertyValue('--color-dynamic');

    if (result !== null) {
      const R = parseInt(result[1], 16);
      const G = parseInt(result[2], 16);
      const B = parseInt(result[3], 16);

      const luminance = (0.299 * R + 0.587 * G + 0.114 * B) / 255;

      if (luminance > 0.5) {
        textColor = '#000000';
      } else {
        textColor = '#FFFFFF';
      }
    }

    this.applicationState.ApplicationTextColor = textColor;
    document.documentElement.style.setProperty('--color-dynamic', this.applicationState.ApplicationTextColor);
  }
}

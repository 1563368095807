import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { ApplicationStateService } from '../../services';
import { ConfigService } from '../config';
import { HotelService } from '../hotel';
import { ReservationType } from '../payment';
import {
  TrackingClient,
  TrackingClientCartItem,
  TrackingClientPurchase,
  TrackingClientViewCart,
  TrackingClientViewItem,
} from './tracking-client.interface';
import { GoogleAnalyticsPurchaseItem } from './Models/google-analytics-purchase.item';
import { GoogleAnalyticsAddToCartEvent } from './Events/google-analytics-add-to-cart.event';
import { GoogleAnalyticsPurchaseEvent } from './Events/google-analytics-purchase.event';
import { GoogleAnalyticsRemoveFromCartEvent } from './Events/google-analytics-remove-from-cart.event';
import { GoogleAnalyticsViewItemEvent } from './Events/google-analytics-view-item.event';
import { GoogleAnalyticsViewCartEvent } from './Events/google-analytics-view-cart.event';
import { GoogleAnalyticsBeginCheckoutEvent } from './Events/google-analytics-begin-checkout.event';
import { GoogleAnalyticsViewCartModel } from './Models/google-analytics-view-cart.model';

@Injectable()
export class GoogleTagManagerClientService implements TrackingClient {
  private googleTagManager = this.initGoogleTagManager();

  constructor(
    private log: LogService,
    private configService: ConfigService,
    private hotelService: HotelService,
    private applicationstate: ApplicationStateService,
  ) {}
  async ecommerceAddToCart(item: TrackingClientCartItem): Promise<void> {
    const cartItem: GoogleAnalyticsPurchaseItem = item;
    cartItem.affiliation = this.applicationstate.CurrentHotelCode;
    await this.withGoogleTagManger(async (dataLayer) => {
      const event: GoogleAnalyticsAddToCartEvent = {
        event: 'add_to_cart',
        ecommerce: {
          currency: this.applicationstate.HotelCurrency,
          value: (cartItem.price ?? 0) * (cartItem.quantity ?? 1),
          items: [cartItem],
        },
      };
      dataLayer.push(null);
      dataLayer.push(event);
      //this.log.debug('GoogleTagManagerService addItemToCart() push', { event });
    });
  }
  async ecommerceRemoveFromCart(item: TrackingClientCartItem): Promise<void> {
    const cartItem: GoogleAnalyticsPurchaseItem = item;
    cartItem.affiliation = this.applicationstate.CurrentHotelCode;
    await this.withGoogleTagManger(async (dataLayer) => {
      const event: GoogleAnalyticsRemoveFromCartEvent = {
        event: 'remove_from_cart',
        ecommerce: {
          currency: this.applicationstate.HotelCurrency,
          value: (cartItem.price ?? 0) * (cartItem.quantity ?? 1),
          items: [cartItem],
        },
      };
      dataLayer.push(null);
      dataLayer.push(event);
      //this.log.debug('GoogleTagManagerService removeItemToCart() push', { event });
    });
  }
  async ecommercePurchase(purchase: TrackingClientPurchase): Promise<void> {
    await this.withGoogleTagManger(async (dataLayer) => {
      const event: GoogleAnalyticsPurchaseEvent = {
        event: 'purchase',
        ecommerce: {
          currency: this.applicationstate.HotelCurrency,
          transaction_id: purchase.transaction_id,
          value: purchase.value,
          items:
            purchase.items?.map((item) => {
              const cartItem: GoogleAnalyticsPurchaseItem = item;
              cartItem.affiliation = this.applicationstate.CurrentHotelCode;
              return cartItem;
            }) ?? [],
        },
      };
      dataLayer.push(null);
      dataLayer.push(event);
      //this.log.debug('GoogleTagManagerService purchase() push', { event });
    });
  }
  async ecommerceViewItem(item: TrackingClientViewItem): Promise<void> {
    const event: GoogleAnalyticsPurchaseItem = item;
    event.affiliation = this.applicationstate.CurrentHotelCode;
    await this.withGoogleTagManger(async (dataLayer) => {
      const event: GoogleAnalyticsViewItemEvent = {
        event: 'view_item',
        ecommerce: {
          currency: this.applicationstate.HotelCurrency,
          value: (item.price ?? 0) * (item.quantity ?? 1),
          items: [item],
        },
      };
      dataLayer.push(null);
      dataLayer.push(event);
      //this.log.debug('GoogleTagManagerService viewItem() push', { event });
    });
  }
  async ecommerceViewCart(item: TrackingClientViewCart): Promise<void> {
    const model: GoogleAnalyticsViewCartModel = item;
    model.currency = this.applicationstate.HotelCurrency;
    await this.withGoogleTagManger(async (dataLayer) => {
      const event: GoogleAnalyticsViewCartEvent = {
        event: 'view_cart',
        ecommerce: model,
      };
      dataLayer.push(null);
      dataLayer.push(event);
      //this.log.debug('GoogleTagManagerService viewCart() push', { event });
    });
  }
  async ecommerceBeginCheckout(m: TrackingClientViewCart): Promise<void> {
    await this.withGoogleTagManger(async (dataLayer) => {
      const event: GoogleAnalyticsBeginCheckoutEvent = {
        event: 'begin_checkout',
        ecommerce: {
          currency: this.applicationstate.HotelCurrency,
          value: m.value,
          items:
            m.items?.map((item) => {
              const cartItem: GoogleAnalyticsPurchaseItem = item;
              cartItem.affiliation = this.applicationstate.CurrentHotelCode;
              return cartItem;
            }) ?? [],
        },
      };
      dataLayer.push(null);
      dataLayer.push(event);
      //this.log.debug('GoogleTagManagerService beginCheckout() push', { event });
    });
  }
  /**
   * @deprecated Use `ecommerceAddToCart` instead
   * @param category
   * @param entityType
   * @param name
   * @param count
   */
  async addToCart(category: string, entityType: string, name: string, count: number): Promise<void> {
    this.log.warn('using deprecated method addToCart()');
    await this.ecommerceAddToCart({
      item_name: name,
      quantity: count,
      item_category: entityType,
    });
    //this.log.debug('GoogleTagManagerService addToCart() push', { event });
  }
  /**
   * @deprecated Use `ecommerceRemoveFromCart` instead
   * @param category
   * @param entityType
   * @param name
   * @param count
   */
  async removeFromCart(category: string, entityType: string, name: string, count: number): Promise<void> {
    this.log.warn('using deprecated method removeFromCart()');
    await this.withGoogleTagManger(async () => {
      await this.ecommerceRemoveFromCart({
        item_name: name,
        quantity: count,
        item_category: entityType,
      });
      //this.log.debug('GoogleTagManagerService removeFromCart() push', { event });
    });
  }
  async revenue(reservation: ReservationType) {
    if (reservation.rooms.length !== 0) {
      const hotelCode = reservation.rooms[0].hotelCode;
      await this.withGoogleTagManger(async () => {
        const products: GoogleAnalyticsPurchaseItem[] = [];
        await Promise.all(
          reservation.rooms.map(async (room) => {
            const { Name: roomName } = await this.hotelService.GetECommerceRoomInfo(
              room.hotelCode,
              room.roomCode,
              reservation.lang,
            );
            const { Code: rateName } = await this.hotelService.GetECommerceRateInfo(
              room.hotelCode,
              room.ratePlan,
              reservation.lang,
            );
            products.push({
              item_id: `${room.roomCode}-${room.ratePlan}`,
              item_name: `${roomName}/${rateName}`,
              item_category: 'Room',
              price: room.price,
              quantity: 1,
            });
            await Promise.all(
              room.services.map(async (service) => {
                const { Name: serviceName } = await this.hotelService.GetECommerceExtraServiceInfo(
                  room.hotelCode,
                  service.serviceCode,
                  reservation.lang,
                );
                products.push({
                  item_id: `${room.roomCode}-${rateName}-${service.serviceCode}`,
                  item_name: `${roomName}/${rateName}/${serviceName}`,
                  item_category: 'ExtraService',
                  price: service.price,
                  quantity: service.count,
                  affiliation: hotelCode,
                });
              }),
            );
          }),
        );
        await this.ecommercePurchase({
          transaction_id: reservation.reservationId,
          value: reservation.total,
          items: products,
        });
      });
    } else if (reservation.seats.length !== 0) {
      const hotelCode = this.applicationstate.CurrentHotelCode;
      if (hotelCode !== undefined) {
        await this.withGoogleTagManger(async () => {
          const products: GoogleAnalyticsPurchaseItem[] = [];

          for (const seat of reservation.seats) {
            products.push({
              item_id: `${seat.seatCode}`,
              item_name: `${seat.seatCode}/${seat.seatrate}`,
              item_category: 'Seating',
              price: seat.total,
              quantity: seat.seatGuests.length,
              affiliation: hotelCode,
            });

            await this.ecommercePurchase({
              transaction_id: reservation.reservationId,
              value: reservation.total,
              items: products,
            });
          }
        });
      }
    } else if (reservation.giftcards.length !== 0) {
      const hotelCode = this.applicationstate.CurrentHotelCode;
      if (hotelCode !== undefined) {
        await this.withGoogleTagManger(async () => {
          const products: GoogleAnalyticsPurchaseItem[] = [];

          for (const card of reservation.giftcards) {
            products.push({
              item_id: `${card.cardName}`,
              item_name: `${card.cardName}`,
              item_category: 'Giftcard',
              price: card.total,
              quantity: 1,
              affiliation: hotelCode,
            });

            await this.ecommercePurchase({
              transaction_id: reservation.reservationId,
              value: reservation.total,
              items: products,
            });
          }
        });
      }
    }
  }

  private async withGoogleTagManger(handler: (googleTagManager: GoogleTagManger) => Promise<void> | void) {
    const googleTagManager = await this.googleTagManager;
    if (googleTagManager) {
      if (handler instanceof Promise) {
        await handler(googleTagManager);
      } else {
        void handler(googleTagManager);
      }
    }
  }

  private async initGoogleTagManager() {
    const config = await this.configService.getGaParams();
    const googleTagManagerAccountCode = config.GoogleTagManagerAccountCode;
    if (googleTagManagerAccountCode) {
      const googleTagManager = GoogleTagManagerClientService.InitGoogleTagManagerScript(googleTagManagerAccountCode);
      //this.log.debug(`Google Tag Manger is registered for '${googleTagManagerAccountCode}' account`);
      return googleTagManager;
    } else {
      return undefined;
    }
  }
  /*
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-XXXX');</script>
    */
  private static InitGoogleTagManagerScript(accountCode: string) {
    const w = window as unknown as Window & { dataLayer: GoogleTagManger };
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const firstScript = document.getElementsByTagName('script')[0];
    const node = document.createElement('script');
    node.async = true;
    node.src = `https://www.googletagmanager.com/gtm.js?id=${accountCode}`;
    (firstScript.parentNode as Node).insertBefore(node, firstScript);
    return w.dataLayer;
  }
}

export type GoogleTagManger = { [key: string]: any } | null[];

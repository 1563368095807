import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HotelCodeString, PromoCodeString } from 'src/app/core';
import { StorageClient } from 'src/app/helpers';
import { DataPromotionService, IPromotionService } from './data-promotion.service';
@Injectable()
export class PromotionService implements IPromotionService {
  private promocode = new StorageClient(sessionStorage, 'Promotion', null as PromoCodeString | null);
  promocode$ = new BehaviorSubject(((promocode) => (promocode ? promocode : undefined))(this.promocode.get()));

  constructor(private dataPromotionService: DataPromotionService) {
    this.promocode$.subscribe((promocode) => {
      this.promocode.set(promocode ? promocode : null);
    });
  }

  getPromocode() {
    return this.promocode$.getValue();
  }

  async getPromotionStatus(hotelCode: HotelCodeString, promocode: PromoCodeString) {
    return await this.dataPromotionService.getPromotionStatus(hotelCode, promocode);
  }
}

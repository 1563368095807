<div *ngIf="!insideDetails" class="expandable-media-list zoom-content-effect">
  <ng-template ngFor let-group [ngForOf]="groupList">
    <div [ngClass]="usePageContainer ? 'container' : ''">
      <div class="row d-flex">
        <div *ngFor="let item of group" [ngClass]="usePageContainer ? 'col l4 m6 s12 py-1' : 'col l6 m6 s12 py-1'">
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: {
                  item: item,
                  selected: item === selected
                }
              }
            "></ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="group.includes(selected)" class="expandable-media-description">
      <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: selected }"></ng-container>
    </div>
  </ng-template>
</div>

<div *ngIf="insideDetails" class="expandable-media-list zoom-content-effect">
  <ng-template ngFor let-group [ngForOf]="groupList">
    <div class="row d-flex">
      <div *ngFor="let item of group" class="col l4 m6 s12 py-1">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              $implicit: {
                item: item,
                selected: item === selected
              }
            }
          "></ng-container>
      </div>
    </div>
    <div *ngIf="group.includes(selected)" class="expandable-media-description">
      <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: selected }"></ng-container>
    </div>
  </ng-template>
</div>

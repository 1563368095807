import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogService } from '@com/logging';
import { BehaviorSubject } from 'rxjs';
import { AppQueryParams } from 'src/app/app.component';
import moment, { Moment } from 'moment';
import {
  ApplicationStateService,
  AQC,
  Booking,
  BookingSeating,
  RateCodeString,
  RoomCodeString,
  SeatingData,
  SeatingRate,
} from 'src/app/core';
import { BasketQueryParams, BasketService, Loader, LocaleService, SearchParams, TrackingService } from 'src/app/core';
import { DateHelper, Guid, UTCDateString, UTCDateTimeString } from 'src/app/helpers';
import { HotelService } from '../../core/modules/hotel/hotel.service';
import { SeatProfileType } from '../../core/modules/payment';
import { NavigationHelper } from '../../helpers/navigation-helper';
import { SearchComponent } from '../search/search.component';
import { HighlightDate } from '../seating-standalone/seating-date-calendar.component';
@Component({
  selector: 'app-seating',
  templateUrl: './seating.component.html',
})
export class SeatingComponent implements OnInit, OnDestroy {
  private seatingsAreChoosen = false;
  chosenDate$ = new BehaviorSubject<Moment | null>(null);
  minDate: Moment | undefined;
  maxDate: Moment | undefined;
  selectedSeating: SeatingUiModel | undefined;
  basket = this.getBasket(this.route.snapshot.queryParams as AppQueryParams);
  onlySeating = false;
  canContinue = false;
  seatingSelected: Guid | undefined;
  sealectedSeatingDate: Date | undefined;
  seatingsToBasketArray: BookingSeating[] = [];
  state: ApplicationStateService | undefined;
  isBookingFlow = false;
  startAt: Date | undefined;
  seatingWrapper: SeatingWrapper[] | undefined;
  updateCalendar = false;
  lang = '';

  // Calendar
  highlightedDates: HighlightDate[] = [];
  availableDates: Moment[] = [];
  dateHasBeenClicked = false;

  @ViewChildren('seatingItem') seatingItemRef!: QueryList<ElementRef>;
  @ViewChildren('seatingSelectedItem') seatingSelectedItemRef!: QueryList<ElementRef>;

  constructor(
    private log: LogService,
    private route: ActivatedRoute,
    private loader: Loader,
    private basketService: BasketService,
    private hotelService: HotelService,
    private localeService: LocaleService,
    private trackingService: TrackingService,
    private applicationState: ApplicationStateService,
    private navigationHelper: NavigationHelper,
  ) {
    this.chosenDate$.subscribe(async (x) => {
      if (x) {
        if (
          this.dateHasBeenClicked ||
          (this.basket.bookings &&
            this.basket.bookings[0] &&
            this.basket.bookings[0].Seatings &&
            this.seatingsAreChoosen)
        ) {
          await this.loader.using(async () => {
            const convertedDate = DateHelper.convertMomentToDate(x);
            const res = await this.buildSeatingData(convertedDate, convertedDate);
            this.seatingWrapper = res;

            if (this.selectedSeating) {
              const dates = await this.buildAvailibleDates(this.basket.bookings);
              this.selectedSeating.seatingTimesUiModel = dates;
            }
          }, 'LOA_Availability' as any);
        }
      }
    });
  }

  async ngOnInit() {
    this.lang = await this.localeService.getLocale(); //

    this.localeService.locale$.subscribe(async (x) => {
      if (x) {
        if (this.lang !== x) {
          await this.loadPageContent();
          this.lang = x;
        }
      }
    });

    await this.loadPageContent();

    if (this.onlySeating && this.seatingWrapper && this.seatingWrapper.length === 0) {
      await this.navigationHelper.navigateToPage('/add-on/1');
    }
  }

  async loadPageContent() {
    await this.loader.using(async () => {
      this.state = this.applicationState;

      document.body.classList.remove('addBackGround');
      document.body.classList.remove('addBackGroundWithAnimation');
      document.body.style.backgroundImage = '';

      if (this.basket.bookings.length !== 0 && this.basket.bookings[0].RoomName !== '') {
        this.isBookingFlow = true;
      }

      // Check if any selected seating in basket
      if (
        this.basket &&
        this.basket.bookings[0] &&
        this.basket.bookings[0].Seatings &&
        this.basket.bookings[0].Seatings.length !== 0
      ) {
        this.seatingsAreChoosen = true;
      }

      if (this.basket.bookings.length === 0) {
        this.basket.params.rooms = [];
      }
      if (this.basket.bookings[0] && this.basket.bookings[0].RoomCode !== ('' as RoomCodeString)) {
        this.onlySeating = true;
      } else {
        await this.buildCalendarData(undefined);
        this.onlySeating = false;
      }
      if (this.basket.bookings[0] && this.basket.bookings[0].Seatings && this.basket.bookings[0].Seatings[0]) {
        this.seatingSelected = this.basket.bookings[0].Seatings[0].Id as Guid;
        this.sealectedSeatingDate = this.basket.bookings[0].Seatings[0].Date;
      }

      document.body.classList.remove('addBackGround');
      document.body.style.backgroundImage = '';
      await this.prepareNavigation();

      if (!this.seatingSelected) {
        const res = await this.buildSeatingData(new Date(), DateHelper.addMonth(new Date(), 12));
        this.seatingWrapper = res;
      } else {
        if (this.sealectedSeatingDate) {
          let res = [] as SeatingWrapper[];

          if (!this.onlySeating) {
            res = await this.buildSeatingData(new Date(), DateHelper.addMonth(new Date(), 12));
          } else {
            const convertedDate = DateHelper.convertMomentToDate(moment(this.sealectedSeatingDate));
            res = await this.buildSeatingData(convertedDate, convertedDate);
          }
          this.seatingWrapper = res;
          if (this.selectedSeating) {
            const dates = await this.buildAvailibleDates(this.basket.bookings);
            this.selectedSeating.seatingTimesUiModel = dates;
          }
        }
      }
    }, 'LOA_Availability' as any);
  }

  calendarOutput(date: Moment) {
    const oldValue = this.dateHasBeenClicked;
    this.dateHasBeenClicked = true;
    this.selectedSeating = undefined;
    if (!oldValue) {
      this.chosenDate$.next(date);
    }
  }

  async resetDate() {
    await this.loader.using(async () => {
      this.chosenDate$.next(null);
      this.dateHasBeenClicked = false;
      const res = await this.buildSeatingData(new Date(), DateHelper.addMonth(new Date(), 12));
      this.seatingWrapper = res;
      this.deselectAllSeatings();
      this.selectedSeating = undefined;
      await this.buildCalendarData(undefined);

      this.updateCalendar = true;
    }, 'LOA_Availability' as any);
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async prepareNavigation() {
    const url = '/' + this.route.snapshot.url.join('/');
    await this.navigationHelper.continueMobileNavigationbar(false, true, url, '/add-on');
  }

  buildSelectedSeatsFromBasket(basketSeating: BookingSeating[], seatings: SeatingTimesUiModel[]) {
    this.seatingsToBasketArray = basketSeating;
    for (const item of seatings) {
      const res = basketSeating.find(
        (x) =>
          x.Id === item.vareNr &&
          DateHelper.FromUtcDate(x.Date.toString() as UTCDateString).getDate() ===
            DateHelper.FromUtcDate(item.arrival.toString() as UTCDateString).getDate(),
      );

      if (res && res.TicketTotal) {
        item.SelectedseatId = res.Id;
        item.SelectedDate = res.Date;
        item.SelectedName = res.Name;
        item.totalPrice = res.TicketTotal * res.SinglePrice;
        item.ticketSelected = res.TicketTotal;
        const clock = item.clock.find((x) => x.time === res.Time);
        if (clock) {
          const clockSelected = clock.time ? clock.time : undefined;
          clock.selectedTime = clockSelected;
        }
      }
    }
  }

  async onMoveToNextClick() {
    await this.navigationHelper.continue('/seating/1', '/add-on/1');
  }

  async onSeatingClick(e: Event | null, seating: SeatingUiModel, forceState: boolean | null = null) {
    if (seating.selected === forceState) {
      return;
    }
    this.deselectAllSeatings();

    if (e) {
      e.stopPropagation();
    }

    if (!this.onlySeating && !this.dateHasBeenClicked) {
      await this.buildCalendarData(seating.characteristics, true);
      this.chosenDate$.next(moment(seating.date));
      this.startAt = DateHelper.convertMomentToDate(moment(seating.date));
      this.updateCalendar = true;
    }

    if (this.selectedSeating && seating.seatingPlanId === this.selectedSeating.seatingPlanId) {
      this.selectedSeating = undefined;
      return;
    }

    seating.selected = forceState ? forceState : !seating.selected;
    this.selectedSeating = seating;
    const res = await this.buildAvailibleDates(this.basket.bookings);
    this.selectedSeating.seatingTimesUiModel = res;
    this.trackingService.ecommerceViewItem({
      item_id: seating.seatingPlanId,
      item_name: seating.characteristics,
      item_category: 'Seating',
    });
  }

  deselectAllSeatings() {
    if (this.seatingWrapper) {
      for (const w of this.seatingWrapper) {
        w.data.forEach((x) => {
          x.selected = false;
        });
      }
    }
  }

  onSeatingDateChange(event: string, rate: SeatingTimesUiModel) {
    rate.clock.forEach((item) => {
      item.selectedTime = undefined;
    });
    const clock = rate.clock.find((x) => x.time === event);
    if (clock) {
      clock.selectedTime = event;
      rate.tickets = this.buildAvailibleTickets(clock.maxAvailible);
    }
  }

  buildTicketPrice(price: number, ticketCount: number) {
    const totalPrice = price * ticketCount;
    return totalPrice;
  }

  buildAvailibleTickets(ticketsNumber: number) {
    const availibleTickets = [] as number[];
    let counter = 0;
    while (ticketsNumber !== 0 && counter !== ticketsNumber + 1) {
      availibleTickets.push(counter);
      counter++;
    }
    return availibleTickets;
  }

  async onRateSelect(seating: SeatingUiModel, rate: SeatingTimesUiModel) {
    // Quantity / Price change from "Select" (none) to an actual quantity.
    const seatingToBasket = {} as BookingSeating;

    rate.totalPrice = this.buildTicketPrice(rate.price, rate.ticketSelected);
    const clockDate = rate.clock.find((x) => x.selectedTime);

    if (clockDate) {
      seatingToBasket.Date = clockDate.date;
      seatingToBasket.Time = clockDate.time;
    } else {
      seatingToBasket.Date = rate.clock[0].date;
      seatingToBasket.Time = rate.clock[0].time;
    }

    const midDate = DateHelper.FromUtcDate(rate.arrival.toString() as UTCDateString);
    const month = DateHelper.getTranslatedMonth(midDate.getMonth());

    seatingToBasket.day = midDate.getDate().toString();
    seatingToBasket.month = month;
    seatingToBasket.year = midDate.getFullYear().toString();
    seatingToBasket.Id = rate.vareNr;
    seatingToBasket.Name = rate.name;
    seatingToBasket.SinglePrice = rate.price;
    seatingToBasket.TicketTotal = rate.ticketSelected;
    seatingToBasket.SeatRate = seating.characteristics;
    seatingToBasket.SeatingPlanName = seating.name;
    seatingToBasket.SeatCode = 'DELT';
    seatingToBasket.seatId = rate.ID;
    seatingToBasket.type = 'SeatingBooking';
    seatingToBasket.returnUrl = {};

    const seatGuests = [] as SeatProfileType[];
    const modiDate = DateHelper.FromUtcDate(seatingToBasket.Date.toString() as UTCDateString);
    const guest: SeatProfileType = {
      Count: seatingToBasket.TicketTotal ? seatingToBasket.TicketTotal : 0,
      Date: seatingToBasket.Date
        ? (DateHelper.toServerDateFormatString(modiDate) as UTCDateString)
        : (DateHelper.toServerDateFormatString(new Date()) as UTCDateString),
      Name: seatingToBasket.Name,
      Price: seatingToBasket.SinglePrice,
      Time: seatingToBasket.Time ? seatingToBasket.Time : '',
      SeatId: seatingToBasket.Id,
      VareNr: rate.vareNr,
      AQC: rate.AQC,
    };
    seatGuests.push(guest);

    seatingToBasket.SeatGuest = seatGuests;
    this.seatingsToBasketArray.push(seatingToBasket);

    const bask = this.basket.bookings[0];

    if (bask) {
      this.basket.bookings[0] = {
        Seatings: this.seatingsToBasketArray,
        HasPromotionForCustomer: bask.HasPromotionForCustomer,
        RateCode: bask.RateCode,
        RateName: bask.RateName,
        RoomCode: bask.RoomCode,
        RoomName: bask.RoomName,
        RateDescription: bask.RateDescription ? bask.RateDescription : '',
        AddOns: bask.AddOns,
        TableSeatings: bask.TableSeatings,
        ConferenceRateCode: '',
        OriginalPrice: bask.OriginalPrice,
        ConferenceRoomCode: '',
        Price: bask.Price,
      };
    } else {
      this.basket.bookings[0] = {
        Seatings: this.seatingsToBasketArray,
        HasPromotionForCustomer: false,
        RateCode: '' as RateCodeString,
        RateName: '',
        RoomCode: '' as RoomCodeString,
        RoomName: '',
        RateDescription: '',
        ConferenceRateCode: '',
        ConferenceRoomCode: '',
        Price: undefined,
        OriginalPrice: undefined,
      };
    }

    this.trackingService.ecommerceAddToCart({
      item_id: rate.vareNr,
      item_name: seating.characteristics,
      item_category: 'Seating',
      quantity: rate.ticketSelected,
      price: rate.price,
    });
    rate.SelectedseatId = rate.vareNr;
    rate.SelectedName = rate.name;
    rate.SelectedDate = rate.arrival;

    this.sealectedSeatingDate = seating.date;
    this.basketService.set(this.basket);

    if (!this.onlySeating) {
      this.highlightedDates.push({
        date: rate.arrival,
        occupied: false,
        inBasket: true,
      } as HighlightDate);
      this.updateCalendar = true;
    }

    this.canContinue = true;
  }

  onSelectedRateClick(rate: SeatingTimesUiModel) {
    // Quantity / Price changed from one quantity to another quantity.
    this.basketService.set(this.basket);

    const existingSeating = this.seatingsToBasketArray.find(
      (seating) =>
        seating.seatId === rate.ID &&
        DateHelper.FromUtcDate(seating.Date.toString() as UTCDateString).getDate() ===
          DateHelper.FromUtcDate(rate.arrival.toString() as UTCDateString).getDate() &&
        seating.Name === rate.name,
    );
    if (existingSeating) {
      const existingSeatingTicketTotal = existingSeating.TicketTotal ?? 0;
      if (rate.ticketSelected != 0) {
        // Update seating quantity
        existingSeating.TicketTotal = rate.ticketSelected;
        if (existingSeating.SeatGuest.length > 0) {
          existingSeating.SeatGuest[0].Count = rate.ticketSelected;
        }
        const ticketTotalDelta = rate.ticketSelected - existingSeatingTicketTotal;
        if (ticketTotalDelta > 0) {
          this.trackingService.ecommerceAddToCart({
            item_name: `${existingSeating?.SeatRate}`,
            item_id: `${existingSeating?.Id}`,
            item_category: 'Seating',
            quantity: ticketTotalDelta,
            price: existingSeating?.SinglePrice,
          });
        } else {
          this.trackingService.ecommerceRemoveFromCart({
            item_name: `${existingSeating?.SeatRate}`,
            item_id: `${existingSeating?.Id}`,
            item_category: 'Seating',
            quantity: Math.abs(ticketTotalDelta),
            price: existingSeating?.SinglePrice,
          });
        }
      } else {
        // Remove seating quantity
        const index = this.seatingsToBasketArray.indexOf(existingSeating);
        this.seatingsToBasketArray.splice(index, 1);
        rate.SelectedseatId = undefined;
        this.trackingService.ecommerceRemoveFromCart({
          item_name: `${existingSeating?.SeatRate}`,
          item_id: `${existingSeating?.Id}`,
          item_category: 'Seating',
          quantity: existingSeatingTicketTotal,
          price: existingSeating?.SinglePrice,
        });
      }
    }

    if (this.basket.bookings[0].Seatings === undefined || this.basket.bookings[0].Seatings.length === 0) {
      this.onlySeating = false;
    }
    this.basketService.set(this.basket);
  }

  async goBack() {
    if (this.basket.bookings[0] && this.basket.bookings[0].RoomCode === ('' as RoomCodeString)) {
      await this.navigationHelper.goBack(true);
    } else {
      await this.navigationHelper.goBack();
    }
  }

  async buildCalendarData(itemNumber: string | undefined, isRebuild = false) {
    await this.loader.using(async () => {
      this.availableDates = [];
      let seatingSpectra = await this.hotelService.GetECommerceSeatings(
        this.applicationState.CurrentHotelCode,
        new Date(),
        DateHelper.addMonth(new Date(), 12),
        await this.localeService.getLocale(),
        true,
      );

      if (itemNumber) {
        seatingSpectra = seatingSpectra.filter((x) => x.Characteristics === itemNumber);
      }

      for (const item of seatingSpectra) {
        for (const rate of item.Rates) {
          this.availableDates.push(moment(rate.Arrival));

          if (
            this.seatingsAreChoosen &&
            this.basket.bookings[0].Seatings &&
            this.basket.bookings[0].Seatings.filter((x) => x.Date === rate.Arrival).length > 0
          ) {
            this.highlightedDates.push({
              date: rate.Arrival,
              occupied: false,
              inBasket: true,
            } as HighlightDate);
          }
        }
      }

      if (!isRebuild) {
        if (this.highlightedDates.length !== 0) {
          this.startAt = DateHelper.convertMomentToDate(moment(this.highlightedDates[0].date));
        } else {
          this.startAt = DateHelper.convertMomentToDate(this.availableDates[0]);
        }
      }
    }, 'LOA_Availability' as any);
    this.availableDates = this.availableDates.sort((a, b) => moment(a, 'DD-MM-YYYY').diff(moment(b, 'DD-MM-YYYY')));
  }

  private getBasket(queryParams: Partial<BasketQueryParams>) {
    const hotelCode = SearchComponent.ParseHotelCode(queryParams);
    const arrival = SearchComponent.ParseArrival(queryParams) || new Date();
    const stay = SearchComponent.ParseStay(queryParams) || 30;

    let rooms = SearchComponent.ParseRooms(queryParams);
    const single = SearchComponent.ParseSingle(queryParams);
    if (rooms === undefined) {
      rooms = [];
    }

    if (hotelCode && arrival && stay && rooms) {
      const params: SearchParams = {
        hotelCode,
        arrival,
        stay,
        rooms,
        single,
        ArrivalDate: 0,
        ArrivalFullYear: 0,
        ArrivalMonthName: '',
        DepartueDate: 0,
        DepartueFullYear: 0,
        DepartueMonthName: '',
        BookingFlow: 1,
        RateCode: '',
        ConferenceRoomCode: '',
      };
      return this.basketService.create(params);
    } else {
      const basket = this.basketService.get();
      if (basket) {
        return basket;
      } else {
        throw new Error('Basket must not be empty');
      }
    }
  }

  private async buildSeatingData(date: Date, endDate: Date) {
    const seatingWrapperArray = [] as SeatingWrapper[];
    const seatingArray = [] as SeatingUiModel[];

    if (!this.onlySeating) {
      const seatingSpectra = await this.hotelService.GetECommerceSeatings(
        this.applicationState.CurrentHotelCode,
        this.dateHasBeenClicked ? date : new Date(),
        this.dateHasBeenClicked ? endDate : DateHelper.addMonth(endDate, 12),
        await this.localeService.getLocale(),
      );

      if (seatingSpectra && seatingSpectra.length !== 0) {
        const seats = seatingSpectra.filter((x) => x.Rates !== null);

        for (const seatItem of seats) {
          const seatingPortal = await this.hotelService.GetECommerceSeatingPortalInfo(
            this.applicationState.CurrentHotelCode,
            date,
            endDate,
            seatItem.ItemNumber,
            this.isBookingFlow,
            this.basket.bookings[0] ? this.basket.bookings[0].RateCode : '',
          );

          const info = seatingPortal.find((x) => x.ID === seatItem.ItemNumber);
          if (info) {
            const seatingUiModel = {
              image: info.Image,
              characteristics: seatItem.Characteristics,
              long: info.Long,
              name: info.Name,
              sortIndex: info.SortIndex,
              seatingPlanId: info.ID,
              date: seatItem.Rates[0].Arrival,
            } as SeatingUiModel;

            if (this.basket && this.basket.bookings[0] && this.basket.bookings[0].Seatings) {
              seatingUiModel.selected =
                this.basket.bookings[0].Seatings.filter((x) => x.Id === info.ID).length === 0 ? false : true;

              if (seatingUiModel.selected) {
                this.selectedSeating = seatingUiModel;
              }
            }
            seatingArray.push(seatingUiModel);
          }
        }

        // Populate seatingWrapperArray with seating items of each characteristics
        // This can add more than one per characteristics if multiple are selected
        // -- in that case, in code below, it will be reduced to only contain the last selected item.
        const selectedSeatingPlanId = this.selectedSeating?.seatingPlanId ?? '';
        for (const seating of seatingArray) {
          const midDate = DateHelper.FromUtcDate(seating.date.toString() as UTCDateString);
          const month = DateHelper.getTranslatedMonth(midDate.getMonth());
          const target = seating.seatingPlanId === selectedSeatingPlanId;
          const wrapperDataExist = seatingWrapperArray.find((x) => x.data.length > 0) !== undefined;
          if (wrapperDataExist) {
            const wrapper = seatingWrapperArray[0];
            if (wrapper) {
              // Add current seating to wrapperArray if:
              // -- no other seats with same characteristics are in the array
              // -- current seating matches the selected seatingPlanId
              const matches = wrapper.data.find((x) => x.characteristics == seating.characteristics);
              const addToWrapper = matches === undefined || target;
              if (addToWrapper) {
                wrapper.data.push(seating);
                this.sortList(wrapper.data);
              }
            }
          } else {
            // Create wrapperArray, and add current seating.
            const wrapper = {} as SeatingWrapper;
            if (month) {
              wrapper.month = month;
              wrapper.data = [] as SeatingUiModel[];
              wrapper.data.push(seating);
              seatingWrapperArray.push(wrapper);
            }
          }
        }

        // Ensure only the last item with selected=true is included in the result
        const wrapperData = seatingWrapperArray[0].data;
        const selectedItems = wrapperData.filter((item) => item.selected === true);
        if (selectedItems.length > 1) {
          let count = 0;
          for (let i = wrapperData.length - 1; i >= 0; i--) {
            if (wrapperData[i].selected && ++count > 1) {
              wrapperData.splice(i, 1);
            }
          }
        }

        // Ensure only one of each characteristics is included in the result, favoring items with selected=true
        const characteristicsMatches: string[] = [];
        seatingWrapperArray[0].data.forEach((item) => {
          const count = seatingWrapperArray[0].data.filter((x) => x.characteristics === item.characteristics).length;
          if (count > 1) {
            characteristicsMatches.push(item.characteristics);
          }
        });

        if (characteristicsMatches.length > 0) {
          seatingWrapperArray[0].data = seatingWrapperArray[0].data.filter((item) => {
            // Remove all hits where selected=false, since multiple hits will only happen if multiple are selected (and multiple selected=true were filtered out above)
            return !(characteristicsMatches.includes(item.characteristics) && !item.selected);
          });
        }

        // Set minDate to the day before first item in seatingArray, if not already set and seatingArray has items.
        if (this.minDate === undefined && seatingArray.length > 0) {
          this.minDate = moment(seatingArray[0].date).add(-1, 'day');
          if (this.seatingsAreChoosen) {
            this.chosenDate$.next(moment(this.sealectedSeatingDate));
          }
        }
        // set maxDate to the last date in availableDates, if not already set.
        if (this.maxDate === undefined) {
          this.maxDate = moment(this.availableDates[this.availableDates.length - 1]);
        }
      }
    } else {
      const { hotelCode, arrival, stay } = this.basket.params;

      let seatings = [] as SeatingData[];

      const spectraSeatings = await this.hotelService.GetECommerceSeatings(
        hotelCode,
        arrival,
        DateHelper.addDays(arrival, stay),
        await this.localeService.getLocale(),
      );

      for (const item of spectraSeatings) {
        if (seatings.filter((x) => x.Characteristics === item.Characteristics).length === 0) {
          seatings.push(item);
        }
      }

      if (seatings && seatings.length !== 0) {
        seatings = seatings.filter((x) => x.Rates);

        for (const item of seatings) {
          const seatingPortal = await this.hotelService.GetECommerceSeatingPortalInfo(
            this.applicationState.CurrentHotelCode,
            arrival,
            DateHelper.addDays(arrival, stay),
            item.ItemNumber,
            this.isBookingFlow,
            this.basket.bookings[0] ? this.basket.bookings[0].RateCode : '',
          );

          const info = seatingPortal.find((x) => x.ID === item.ItemNumber);

          if (info) {
            const seatingUiModel = {} as SeatingUiModel;
            seatingUiModel.image = info.Image;
            seatingUiModel.characteristics = item.Characteristics;
            seatingUiModel.long = info.Long;
            seatingUiModel.sortIndex = info.SortIndex;
            seatingUiModel.seatingPlanId = info.ID;
            seatingUiModel.date = item.Rates[0].Arrival;
            seatingUiModel.selected = false;
            seatingUiModel.name = info.Name;

            if (this.basket && this.basket.bookings && this.basket.bookings[0].Seatings) {
              seatingUiModel.selected =
                this.basket.bookings[0].Seatings.filter((x) => x.Id === info.ID).length === 0 ? false : true;
              if (seatingUiModel.selected) {
                this.selectedSeating = seatingUiModel;
              }
            }
            seatingArray.push(seatingUiModel);
          }
        }

        for (const seating of seatingArray) {
          const midDate = DateHelper.FromUtcDate(seating.date.toString() as UTCDateString);
          const month = DateHelper.getTranslatedMonth(midDate.getMonth());
          const exist = seatingWrapperArray.find((x) => x.month === month) !== undefined;
          if (exist) {
            const wrapper = seatingWrapperArray.find((x) => x.month === month);

            if (wrapper) {
              const skip = wrapper.data.find((x) => x.seatingPlanId === seating.seatingPlanId) === undefined;

              if (skip) {
                wrapper.data.push(seating);
              }
            }
          } else {
            const wrapper = {} as SeatingWrapper;
            if (month) {
              wrapper.month = month;
              wrapper.data = [] as SeatingUiModel[];
              wrapper.data.push(seating);
              seatingWrapperArray.push(wrapper);
            }
          }
        }
      } else {
        await this.navigationHelper.navigateToPage('/add-on/1');
      }
    }

    for (const item of seatingWrapperArray) {
      item.data = this.sortList(item.data);
    }

    const { seatingCode, showList } = this.GetSeatingCodeAndShowListFromQS();

    // Select seating, if seatingPlanId matches seatingCode in QueryString
    if (seatingCode !== '') {
      const reArrangedSeatingWrapperArray = seatingWrapperArray;

      // Filter or rearrange seatings, depending on showList being true in QueryString or not
      if (showList) {
        // Rearrange the seatings, so the selected seating is first

        const rearrangedArray = [
          // Add the selected seating as first array entry
          ...reArrangedSeatingWrapperArray[0].data.filter((item) => item.seatingPlanId === seatingCode),
          // Add the rest of the seatings
          ...reArrangedSeatingWrapperArray[0].data.filter((item) => item.seatingPlanId !== seatingCode),
        ];

        reArrangedSeatingWrapperArray[0].data = rearrangedArray;
      } else {
        // Filter the seatings, so only the selected seating is included

        const filteredArray = [
          // Add the selected seating as only array entry
          ...reArrangedSeatingWrapperArray[0].data.filter((item) => item.seatingPlanId === seatingCode),
        ];

        if (filteredArray.length > 0) {
          reArrangedSeatingWrapperArray[0].data = filteredArray;
        }
      }

      this.SelectBySeatingCode(reArrangedSeatingWrapperArray, seatingCode);
      return reArrangedSeatingWrapperArray;
    }
    return seatingWrapperArray;
  }

  private GetSeatingCodeAndShowListFromQS(): {
    seatingCode: string;
    showList: boolean;
  } {
    // Get all QueryString parameter data
    const urlParams = new URLSearchParams(window.location.search);

    // Find Querystring parameter names (because they're case sensitive)
    const seatingCodeParameterName = Array.from(urlParams.keys()).find((key) => key.toLowerCase() === 'seatingcode');
    const showListParameterName = Array.from(urlParams.keys()).find((key) => key.toLowerCase() === 'showlist');

    // Determine if the parameters present set or not
    const seatingCodeDefinedResult = seatingCodeParameterName !== undefined && seatingCodeParameterName !== null;
    const showListDefinedResult = showListParameterName !== undefined && showListParameterName !== null;

    // Get the parameter values (or use defaults if not set)
    const seatingCodeResult = seatingCodeDefinedResult ? urlParams.get(seatingCodeParameterName!) || '' : '';
    const showListResult = showListDefinedResult
      ? urlParams.get(showListParameterName!)?.toLowerCase() === 'true'
      : false;

    return {
      seatingCode: seatingCodeResult,
      showList: showListResult,
    };
  }

  private SelectBySeatingCode(seatingsArray: SeatingWrapper[], seatingCode: string) {
    let matchingSeatingData = null;
    for (const seating of seatingsArray) {
      if (seating.data?.length) {
        matchingSeatingData = seating.data.find((item) => item.seatingPlanId === seatingCode);
        // If a match is found, exit the for-loop.
        if (matchingSeatingData) {
          break;
        }
      }
    }

    // If a match is found, "click" it.
    if (matchingSeatingData) {
      this.onSeatingClick(null, matchingSeatingData);
    }
  }

  private sortList(array: SeatingUiModel[]) {
    const sortedArray = [] as SeatingUiModel[];

    for (const item of array) {
      if (sortedArray.length === 0) {
        sortedArray.push(item);
      } else {
        if (sortedArray.filter((x) => x.sortIndex > item.sortIndex).length === 0) {
          sortedArray.push(item);
        } else {
          const object = sortedArray.filter((x) => x.sortIndex > item.sortIndex)[0];
          const index = sortedArray.indexOf(object);

          sortedArray.splice(index, 0, item);
        }
      }
    }
    return sortedArray;
  }

  private async buildAvailibleDates(bookings: Booking[]) {
    if (this.selectedSeating) {
      const selectedCharacteristics = this.selectedSeating.characteristics;
      const firstDate = DateHelper.FromUtcDate(this.selectedSeating.date.toString() as UTCDateString);
      let start = DateHelper.getFirstDateOfMonth(firstDate);
      let end = DateHelper.getLastDateOfMonth(firstDate);

      let res: SeatingData[] | undefined;
      if (!this.onlySeating) {
        if (this.chosenDate$.value) {
          const calendarDate = DateHelper.convertMomentToDate(this.chosenDate$.value);
          start = calendarDate;
          end = calendarDate;
        }

        res = await this.hotelService.GetECommerceSeatings(
          this.applicationState.CurrentHotelCode,
          start,
          end,
          await this.localeService.getLocale(),
        );
      } else {
        const { hotelCode, arrival, stay } = this.basket.params;
        end = DateHelper.addDays(arrival, stay);
        start = arrival;
        res = await this.hotelService.GetECommerceSeatings(hotelCode, start, end, await this.localeService.getLocale());
      }

      if (res) {
        res = res.filter((x) => x.Rates !== null);
      }

      const seatings: SeatingTimesUiModel[] = [];
      try {
        if (res) {
          for (const specSeat of res.filter((x) => x.Characteristics === selectedCharacteristics)) {
            const seatingPortal = await this.hotelService.GetECommerceSeatingPortalInfo(
              this.applicationState.CurrentHotelCode,
              start,
              end,
              specSeat.ItemNumber,
              this.isBookingFlow,
              this.basket.bookings[0] ? this.basket.bookings[0].RateCode : '',
            );
            const seatInfo = seatingPortal.find((x) => x.ID === specSeat.ItemNumber);

            if (seatInfo) {
              for (const seat of seatInfo.SeatItems) {
                for (const item of specSeat.Rates) {
                  const seatingModel = {} as SeatingTimesUiModel;
                  const midDate = DateHelper.FromUtcDate(item.Arrival.toString() as UTCDateString);
                  const month = DateHelper.getTranslatedMonth(midDate.getMonth());
                  const time = DateHelper.getTimeFromUtcDate(item.Arrival.toString() as UTCDateTimeString);

                  seatingModel.day = midDate.getDate().toString();

                  if (seatings.filter((x) => x.arrival === item.Arrival).length === 0) {
                    seatingModel.month = month;
                    seatingModel.year = midDate.getFullYear().toString();
                  } else {
                    seatingModel.multipleDays = true;
                  }

                  seatingModel.arrival = item.Arrival;
                  seatingModel.price = seat.Price;
                  seatingModel.name = specSeat.Description;
                  seatingModel.vareNr = seat.VareNr;
                  seatingModel.tickets = this.buildAvailibleTickets(item.MaxPers);
                  seatingModel.ticketSelected = seatingModel.tickets[0];
                  seatingModel.AQC = specSeat.AQC;

                  if (seatingModel.tickets.length === 0) {
                    continue;
                  }

                  const seating = seatings.find(
                    (x) =>
                      x.day === midDate.getDate().toString() &&
                      x.month === month &&
                      x.year === midDate.getFullYear().toString(),
                  );

                  const currentSeat = seatings.find(
                    (x) => x.vareNr === seat.VareNr && x.day === midDate.getDate().toString(),
                  );

                  if (seating) {
                    if (currentSeat) {
                      const clockModel = {} as ClockUiModel;
                      clockModel.time = time;
                      clockModel.date = item.Arrival;
                      clockModel.maxAvailible = item.MaxPers;

                      const index = currentSeat.clock.filter(
                        (x) => x.time && x.date.toString() === item.Arrival.toString(),
                      );

                      if (index.length === 0) {
                        // Only push if no other has been pushed earlier (index.length is 0)
                        currentSeat.clock.push(clockModel);
                      }
                    } else {
                      if (seatingModel.clock === undefined) {
                        seatingModel.clock = [] as ClockUiModel[];
                      }

                      const clockModel = {} as ClockUiModel;

                      clockModel.time = time;
                      clockModel.date = item.Arrival;
                      clockModel.maxAvailible = item.MaxPers;

                      seatingModel.clock.push(clockModel);
                      seatings.push(seatingModel);
                    }
                  } else {
                    seatingModel.clock = [] as ClockUiModel[];
                    const clockModel = {} as ClockUiModel;

                    clockModel.time = time;
                    clockModel.date = item.Arrival;
                    clockModel.maxAvailible = item.MaxPers;

                    seatingModel.clock.push(clockModel);
                    seatings.push(seatingModel);
                  }
                }
              }
            }
          }
          if (
            this.seatingsAreChoosen ||
            (this.basket &&
              this.basket.bookings[0] &&
              this.basket.bookings[0].Seatings &&
              this.basket.bookings[0].Seatings.length !== 0)
          ) {
            for (const item of bookings) {
              if (item.Seatings) {
                this.buildSelectedSeatsFromBasket(item.Seatings, seatings);
              }
            }
          }
          const sortedDates = seatings.sort((a, b) => {
            const dateA = new Date(a.arrival);
            const dateB = new Date(b.arrival);
            return dateA.getDate() - dateB.getDate();
          });
          return sortedDates;
        }
        return [];
      } catch (err: any) {
        this.log.error(`RoomRoute failed to load seatings. ${err.message}`);
        return undefined;
      }
    }
    return [];
  }
}

export interface SeatingWrapper {
  month: string;
  data: SeatingUiModel[];
}

export interface SeatingUiModel {
  image: string;
  long: string;
  name: string;
  characteristics: string;
  date: Date;
  seatingPlanId: string;
  sortIndex: number;
  tableTickets: TableTickets[];
  tableDetails: SeatingTableDetails;
  selected: boolean;
  seatingTimesUiModel: SeatingTimesUiModel[] | SeatingRate[] | undefined;
  AQC: AQC;
}

export interface TableTickets {
  arrival: Date;
  maxPers: number;
}

export interface SeatingTableDetails {
  vareNr: string;
  price: number;
  rateCode: string;
}

export interface SeatingTimesUiModel {
  arrival: Date;
  month: string;
  day: string;
  year: string;
  clock: ClockUiModel[];
  price: number;
  name: string;
  tickets: number[];
  ticketSelected: number;
  totalPrice: number;
  vareNr: string;
  ID: number;
  SelectedseatId: string | undefined;
  SelectedName: string | undefined;
  SelectedDate: Date | undefined;
  multipleDays: boolean;
  AQC: AQC | undefined;
}

export interface ClockUiModel {
  date: Date;
  time: string | null;
  maxAvailible: number;
  selectedTime: string | undefined;
}

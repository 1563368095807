import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum Step {
  Search = 0,
  PaymentLink = 1,
  Package = 2,
  AddOn = 3,
  Customer = 4,
  Payment = 5,
  Complete = 6,
}

@Injectable()
export class StepList {
  step$ = new BehaviorSubject({ step: Step.Search, hasError: false });

  set(step: Step, isCompleted = true) {
    this.step$.next({ step, hasError: !isCompleted });
  }

  getStep() {
    return this.step$.value;
  }
}

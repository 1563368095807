import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AppQueryParams } from '../../../app.component';
import {
  ApplicationStateService,
  BasketQueryParams,
  BasketService,
  BookingGiftcard,
  ConfigService,
  GiftCardGuestInformation,
  HotelService,
  Loader,
  RateCodeString,
  RoomCodeString,
  SearchParams,
} from '../../../core';
import { NavigationHelper } from '../../../helpers/navigation-helper';
import { Messenger } from '../../../modules/messenger';
import { SearchComponent } from '../../search/search.component';

@Component({
  selector: 'app-giftcard',
  templateUrl: './giftcard.component.html',
})
export class GiftcardComponent implements OnInit, OnDestroy {
  @ViewChild('messageHotelTermsTempalte', { static: false })
  messageHotelTermsTempalte: TemplateRef<SelectedGiftcardModel> | undefined;

  isDataAvailable = false;
  data: GiftCardProductsForSale[] | undefined;
  basket = this.getBasket(this.route.snapshot.queryParams as AppQueryParams);
  giftcardToBasketArray: BookingGiftcard[] = [];
  continueActive = false;
  giftcardConfiguration: GiftCardConfiguration | undefined;

  constructor(
    private applicationState: ApplicationStateService,
    private http: HttpClient,
    private messenger: Messenger,
    private loader: Loader,
    private configService: ConfigService,
    private basketService: BasketService,
    private route: ActivatedRoute,
    private navigationHelper: NavigationHelper,
    private hotelService: HotelService,
  ) {}

  async ngOnInit() {
    await this.canUseModule();
    document.body.classList.remove('addBackGround');
    document.body.classList.remove('addBackGroundWithAnimation');
    document.body.style.backgroundImage = '';

    await this.getCardInfos()
      .then((x) => (this.data = x))
      .then(() => (this.isDataAvailable = true));

    await this.buildSelectedCards();
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async canUseModule() {
    await this.hotelService.BuildApplicationState();

    if (!this.applicationState.UseGiftcard) {
      await this.navigationHelper.navigateToPage('/landing-page');
    }
  }

  async buildSelectedCards() {
    if (
      this.basket.bookings.length !== 0 &&
      this.basket.bookings[0].Giftcards !== undefined &&
      this.basket.bookings[0].Giftcards.length !== 0 &&
      this.data !== undefined
    ) {
      for (const card of this.basket.bookings[0].Giftcards) {
        const item = this.data.find((x: GiftCardProductsForSale) => x.Id === card.Id);

        if (item !== undefined) {
          item.Selected = true;
          this.giftcardToBasketArray.push(card);
          this.continueActive = true;
        }
      }
      await this.prepareNavigation();
    }
  }

  async getCardInfos() {
    const cards = await this.loader.using(async () => {
      const config = await this.configService.getAppSettings();
      try {
        const data = await lastValueFrom(
          this.http.get<GiftCardProductsForSale[]>(
            `${config.SpectraApiUrl}/api/internal/giftcard/giftcardproductsforsale`,
            {},
          ),
        );

        return data;
      } catch (err) {
        return [];
      }
    }, 'LOA_Addons');

    return cards;
  }

  async clearBasket() {
    await this.goBack();
  }

  async getGiftConfiguration() {
    const config = await this.configService.getAppSettings();
    try {
      const data = await lastValueFrom(
        this.http.get<GiftCardConfiguration>(`${config.SpectraApiUrl}/api/internal/giftcard/config`, {}),
      );
      return data;
    } catch (err) {
      return undefined;
    }
  }

  async giftcardClicked(giftcard: GiftCardProductsForSale) {
    if (giftcard.Selected) {
      giftcard.Selected = false;
      if (this.basket.bookings[0].Giftcards) {
        const cards = this.basket.bookings[0].Giftcards.filter((x: BookingGiftcard) => x.Id !== giftcard.Id);

        this.basket.bookings[0].Giftcards = cards;

        if (this.basket.bookings[0].Giftcards.length === 0) {
          this.continueActive = false;
        }

        this.giftcardToBasketArray = this.basket.bookings[0].Giftcards;

        this.basketService.set(this.basket);
      }
    } else {
      if (this.messageHotelTermsTempalte !== undefined) {
        this.applicationState.stopScrolling(true);
        const message = this.messenger.show(
          this.messageHotelTermsTempalte,
          {
            count: 1,
            name: giftcard.Name,
            accept: async () => {
              if (this.data) {
                const item = this.data.filter((y) => y.Id === giftcard.Id)[0];

                if (item.Selected) {
                  item.Selected = false;
                  return;
                }

                item.Selected = true;
              }

              let giftcardCounter = 0;

              if (message.data.count < 1) {
                message.data.count = 1;
              }
              if (message.data.count > 50) {
                message.data.count = 50;
              }

              while (giftcardCounter < message.data.count) {
                await this.addGiftcardToBasket(giftcard);

                giftcardCounter = giftcardCounter + 1;
              }

              this.messenger.close(message);
              this.applicationState.stopScrolling(false);
            },
            close: () => {
              this.applicationState.stopScrolling(false);
              this.messenger.close(message);
            },
          },
          () => {
            this.applicationState.stopScrolling(false);
            message.data.close();
          },
        );
      }
    }
  }

  async addGiftcardToBasket(giftcard: GiftCardProductsForSale) {
    const guestInformation = {
      FromName: '',
      GiftcardMessage: '',
      ToName: '',
    } as GiftCardGuestInformation;

    const res = await this.getGiftConfiguration();

    const giftcardConfiguration = {} as GiftCardConfiguration;

    if (res !== undefined) {
      giftcardConfiguration.SendPDF = res.SendPDF;
      giftcardConfiguration.PdfTemplateContent = res.PdfTemplateContent;
      giftcardConfiguration.PdfTemplateStyling = res.PdfTemplateStyling;
    }

    const giftcardToBasket = {
      Id: giftcard.Id,
      Name: giftcard.Name,
      Price: giftcard.Price,
      TotalPrice: 0, // Not yet calculated
      Count: 1, // Count is allways 1
      GuestInformation: guestInformation,
      GiftConfiguration: giftcardConfiguration,
      CustomPrice: giftcard.Price === 0 ? true : false,
    } as BookingGiftcard;

    this.giftcardToBasketArray.push(giftcardToBasket);

    this.basket.bookings[0] = {
      HasPromotionForCustomer: false,
      RateCode: '' as RateCodeString,
      RateName: '',
      RoomCode: '' as RoomCodeString,
      RoomName: '',
      RateDescription: '',
      Giftcards: this.giftcardToBasketArray,
      ConferenceRateCode: '',
      ConferenceRoomCode: '',
      Price: undefined,
      OriginalPrice: undefined,
    };

    this.basketService.set(this.basket);
    this.continueActive = true;
    await this.prepareNavigation();
  }

  async prepareNavigation() {
    await this.navigationHelper.continueMobileNavigationbar(false, true, '/giftcard', '/details');
  }

  async next() {
    window.scroll(0, 0);
    await this.navigationHelper.continue('/giftcard', '/details');
  }

  async goBack() {
    await this.navigationHelper.goBack(true);
  }

  mouseEnter(card: GiftCardProductsForSale) {
    if (card.Description !== undefined && card.Description !== '') {
      card.ShowDescription = true;
    }
  }

  mouseLeave(card: GiftCardProductsForSale) {
    card.ShowDescription = false;
  }

  private getBasket(queryParams: Partial<BasketQueryParams>) {
    const hotelCode = SearchComponent.ParseHotelCode(queryParams);
    const arrival = new Date();
    const stay = 1;
    let rooms = SearchComponent.ParseRooms(queryParams);
    const single = SearchComponent.ParseSingle(queryParams);
    if (rooms === undefined) {
      rooms = [];
    }
    if (hotelCode && arrival && stay && rooms) {
      const params: SearchParams = {
        hotelCode,
        arrival,
        stay,
        rooms,
        single,
        ArrivalDate: 0,
        ArrivalFullYear: 0,
        ArrivalMonthName: '',
        DepartueDate: 0,
        DepartueFullYear: 0,
        DepartueMonthName: '',
        BookingFlow: 1,
        RateCode: '',
        ConferenceRoomCode: '',
      };
      return this.basketService.create(params);
    } else {
      const basket = this.basketService.get();
      if (basket) {
        return basket;
      } else {
        throw new Error('Basket must not be empty');
      }
    }
  }
}

export interface GiftCardProductsForSale {
  Id: number;
  Name: string;
  Description: string;
  ImageUrl: string;
  Date: string;
  Price: number;
  Selected: boolean;
  ShowDescription: boolean;
}

export interface GiftCardConfiguration {
  SendPDF: boolean;
  PdfTemplateStyling: string;
  PdfTemplateContent: string;
}

export interface SelectedGiftcardModel {
  count: number;
  name: string;
  accept: (model: SelectedGiftcardModel) => void;
  close: () => void;
}

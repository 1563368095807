import { Messages } from './index';

export const messages: Messages = {
  APA_CompanyLogin: `Innskráning fyrirtækis`,
  APA_Logout: `Að skrá þig út`,
  APA_Welcome: `Halló`,
  APA_ShowPromo: `Afsláttarkóði`,
  APA_PromoLabel: `Sláðu inn kynningarkóða`,
  APA_Promo: `Afsláttarkóði`,
  APA_PromoSubmit: `Notaðu`,
  APA_PromoChange: `breyta`,
  APA_ActivateHead: `Virkjaðu innskráningu þína`,
  APA_ActivateDesc: `Sláðu inn lykilorð til að virkja innskráningu fyrir`,
  APA_Password: `Lykilorð`,
  APA_ConfirmPassword: `Staðfesta lykilorð`,
  APA_PasswordHasUpper: `Lykilorð verður að innihalda að minnsta kosti einn hástaf (A-Z)`,
  APA_PasswordHasLower: `Lykilorð verður að innihalda að minnsta kosti einn lágstaf (a-z)`,
  APA_PasswordHasNumber: `Lykilorð verður að innihalda að minnsta kosti eina tölu (0-9)`,
  APA_PasswordLength8: `Lykilorð verður að innihalda að minnsta kosti 8 stafi`,
  APA_ConfirmPasswordEqualTo: `Lykilorð passa ekki saman`,
  APA_Activate: `Virkjaðu`,
  APA_CustomerNotFound: `Því miður getum við ekki fundið viðskiptavinaskrá í gagnagrunninum`,
  APA_ResetBasket: `Allt verður tekið úr körfunni. Ert þú viss?`,
  APA_Address: `Heimilisfang`,
  APA_Contact: `Hafðu samband`,
  APA_VatNumber: `VAT:`,
  APA_PromoMessageStart: `Afsláttarkóði`,
  APA_ValidTime: `Gildistími`,
  APA_PromoMessageEnd: `Kynningarkóði gildir á vörum merktum kóðanum`,
  APA_AQCDecription: `Aldurslýsing`,
  APA_Year: `ár`,
  APA_Book: `Bóka`,

  CPA_NothingAvailable_EmptyText: `Vinsamlegast veldu nýja dagsetningu hér að neðan, eða farðu til baka og breyttu upplýsingum þínum frá fyrsta skrefi`,
  CPA_NothingAvailable: `Það eru engin laus herbergi sem uppfylla skilyrðin þín`,
  CPA_SoldOut: `Uppselt`,
  CPA_CustomerInvalid: `Óþekkt viðskiptavinasnið`,
  CPA_PromoInvalid: `Kynningarkóði sem þú slóst inn er ógildur`,
  CPA_PromoBetween: `Kynningarkóði sem þú slóst inn gildir aðeins ef dvalartíminn er á milli:`,

  RPA_More: `Sjá meira`,
  RPA_Select: `Veldu`,
  RPA_Selected: `Valið`,
  RPA_From: `Verð frá`,
  RPA_Image: `Engin mynd`,
  RPA_Description: `Lýsing`,
  RPA_ChooseRoom: `Veldu herbergi`,
  RPA_ChoosePackage: `Veldu Pakki`,
  RPA_ChooseCabin: `Veldu skála`,
  RPA_ChooseHouse: `Veldu hús`,
  RPA_ChooseUnit: `Veldu einingu`,
  RPA_BackRate: `Veldu annan pakka`,
  RPA_MaxPersons: `Hámarksfjöldi fólks`,
  RPA_ChooseYourRoom: `Veldu herbergi`,
  RPA_ChooseYourCabin: `Veldu skála`,
  RPA_ChooseYourHouse: `Veldu hús`,
  RPA_ChooseYourUnit: `Veldu einingu`,
  RPA_ChooseYourPackage: `Veldu pakka`,

  APA_Services: `Þjónusta`,
  APA_Add: `Bæta við`,
  APA_Added: `Bætt við`,
  APA_Remove: `Fjarlægja`,
  APA_ContentDialog: `Veldu fjölda gesta og daga sem þú vilt velja þjónustuna. Á milli daganna`,
  APA_ChooseYourAddOns: `Veldu viðbætur`,
  APA_TotalPrice: `Veldu viðbætur`,
  APA_TotalInBasket: `stykki, samtals`,
  APA_TotalOf: `Veldu magn af`,

  CUP_Header: `Upplýsingar kaupenda`,
  CUP_Accept: `Samþykkja`,
  CUP_FName: `Nafn`,
  CUP_FNameReq: `Fornafn er áskilið`,
  CUP_FPhoneReq: `Nafn síma er krafist`,
  CUP_LName: `Eftirnafn`,
  CUP_LNameReq: `Eftirnafn er áskilið`,
  CUP_Address: `Heimilisfang`,
  CUP_AddressReq: `Heimilisfang er áskilið`,
  CUP_ZipCode: `Póstnúmer`,
  CUP_ZipCodeReq: `Póstnúmer er áskilið`,
  CUP_City: `Borg`,
  CUP_CityReq: `Borg er krafist`,
  CUP_Contry: `landi`,
  CUP_ContryReq: `Land er krafist`,
  CUP_Phone: `Farsímanúmer`,
  CUP_PhoneReq: `Farsími er nauðsynlegur`,
  CUP_Email: `Tölvupóstur`,
  CUP_EmailRepeat: `Endurtaktu tölvupóstfang`,
  CUP_EmailReq: `Tölvupóstur er nauðsynlegur`,
  CUP_EmailInvalid: `Tölvupóstur er ekki gildur`,
  CUP_Comment: `Athugasemd`,
  CUP_NeedSubscribe: `Gerast áskrifandi að fréttabréfi`,
  CUP_AcceptTerms: `Skilmálar og skilyrði`,
  CUP_BookNow: `Bókaðu núna!`,
  CUP_CancelPolicyHeader: `Forfallatrygging`,
  CUP_CancelPolicyDesc: `Þú hefur möguleika á að hætta við dvöl þína ef þú kemst ekki. Sjá nánari upplýsingar um forfallatryggingu í bókunarskilmálum okkar.`,
  CUP_CancelPolicyLabel: `Já, mig langar að kaupa forfallatryggingu fyrir`,
  CUP_GuestInfo: `Upplýsingar um gesti`,
  CUP_BookingOverview: `Bókunaryfirlit`,
  CUP_PayWithGiftcard: `Til að greiða með gjafakorti`,
  CUP_PayWithGiftcardClick: `Ýttu hér`,
  CUP_PayWithGiftcardDescription: `Sláðu inn kóðann af gjafakortinu þínu`,
  CUP_PayWithGiftcardError: `Gjafakort er ekki virkt eða er ekki til.`,
  CUP_PayWithGiftcardStatus: `Gjafakort virkt`,
  CUP_PayWithGiftcardDiscount: `Upphæð gjafakorts`,
  CUP_PayWithGiftcardHeader: `Gjafakort`,
  CUP_PayWithGiftcardInformation: `Viltu innleysa gjafakort?`,
  CUP_PayWithGiftcardPlaceholder: `Gjafakortskóði`,
  CUP_PayWithGiftcardRedeem: `Innleysa`,
  CUP_BookingOverviewToPay: `Að greiða`,

  PAL_GoToPayment: `Farðu í greiðslu`,
  PAL_PaymentComplete: `Þessari greiðslu er lokið`,
  PAL_PaymentNotAvailable: `Ekki er hægt að greiða fyrir þessa pöntun`,

  PAR_Payment: `Greiðsla`,
  PAR_SeekAuthorization: `Vinsamlega fylltu út kreditkortaupplýsingarnar þínar hér að neðan til að staðfesta greiðslu þína og ganga frá pöntun`,
  PAR_TotalLabel: `Verð fyrir pöntunina þína`,
  PAR_TotalPayLabel: `Verð til að greiða`,
  PAR_OBSLabel: `ATH: Vertu á síðunni þar til kvittunarsíðan birtist með bíleggingarnummar`,
  PAR_OBSLabelGiftcard: `ATH: Vertu á síðunni þar til kvittunarsíðan birtist með bíleggingarnummar`,
  PAR_FailedToPay: `Tókst ekki að borga. Athugaðu kortanúmerið þitt.`,
  PAR_CardInvalid: `Kortanúmer er ekki gilt`,
  PAR_CardUnknown: `Tegund korts ekki þekkt`,
  PAR_CardMonthInvalid: `Mánuður er ógildur`,
  PAR_CardYearInvalid: `Ár er ógilt`,
  PAR_CardCVCInvalid: `CVV kóði er ógildur`,
  PAR_FieldRequired: `Þessi reitur er nauðsynlegur`,
  PAR_Cancel: `Hætta við`,
  PAR_Pay: `Borga`,
  PAR_PaymentMethod: `Greiðslumáti`,
  PAR_Cardnumber: `Kortanúmer`,
  PAR_MonthPlaceholder: `MM`,
  PAR_YearPlaceholder: `Ó`,

  PFP_Header: `Hófur! Því miður hefur komið upp villa`,
  PFP_Info: `Og pöntunin þín hefur EKKI farið í gegn. Vinsamlegast reyndu aftur með því að smella á hnappinn hér að neðan. Engir peningar hafa verið teknir af reikningnum þínum.`,
  PFP_TryAgain: `Reyndu aftur`,

  REP_Header: `Takk fyrir pöntunina,`,
  REP_Comment: `asd`,
  REP_DateRooms: `ferðadagsetning`,
  REP_Return: `Aftur á heimasíðuna`,
  REP_OrderId: `Bíleggingarnummar`,
  REP_ReservationId: `Bókunarnúmer`,
  REP_CustomerInfo: `Persónuupplýsingar`,

  REP_Name: `Nafn`,
  REP_PostCity: `Póstnúmer og borg`,
  REP_Phone: `Símanúmer`,
  REP_Address: `Heimilisfang`,
  REP_Country: `Land`,
  REP_Email: `Tölvupóstur`,
  REP_Thanks: `Takk fyrir pöntunina`,
  REP_ThanksInfo: `Þú færð staðfestingu innan skamms tíma með tölvupósti.`,
  REP_ThanksPayment: `Greiðslu þinni er lokið`,

  LOA_Addons: `Hleður viðbótum`,
  LOA_CustomerInfo: `Hleður upplýsingum um viðskiptavini`,
  LOA_ActivatingCustomer: `Virkur viðskiptavinur`,
  LOA_Translations: `Hleður þýðingar`,
  LOA_Configuration: `Hleður uppsetningu`,
  LOA_Availability: `Hleðsla framboð`,
  LOA_CreatingReservation: `Búa til fyrirvara`,
  LOA_HotelInformation: `Hleður hótelstillingu`,
  LOA_Countries: `Hleður lönd`,
  LOA_PreparingPaymnent: `Undirbúa greiðslur`,
  LOA_ApplyingPromo: `Notar kynningarkóða`,
  LOA_Rooms: `Hleðsla herbergja`,
  LOA_Rates: `Hleðsluverð`,
  LOA_LoggingIn: `Innskráning`,
  LOA_SendingInformation: `Sendir upplýsingar`,

  COM_Continue: `Halda áfram`,
  COM_OK: `Allt í lagi`,
  COM_Persons: `Persónur`,
  COM_Guest_10: `Fullorðnir`,
  COM_Guest_9: `Unglingar`,
  COM_Guest_7: `Ungbörn`,
  COM_Guest_11: `Eldri fólk`,
  COM_Guest_8: `Börn`,
  COM_Adult: `Fullorðinn`,
  COM_Child: `Barn`,
  COM_Room: `Herbergi`,
  COM_Cabin: `Skáli`,
  COM_House: `Hús`,
  COM_Unit: `Eining`,
  COM_Arrival: `Koma`,
  COM_Departure: `Brottför`,
  COM_Promocode: `Kynningarkóði`,
  COM_Rooms: `Herbergi`,
  COM_AddOns: `Viðbætur`,
  COM_TotalGuests: `Samtals gestir`,
  COM_Price: `Verð`,
  COM_TotalPrice: `Heildarverð`,
  COM_TotalPriceWithTransactionId: `asd`,
  COM_TotalReservedWithTransactionId: `asd`,
  COM_Close: `Loka`,
  COM_Back: `Til baka`,
  COM_Error: `Upplýsingar`,
  COM_Info: `Upplýsingar`,
  COM_Confirm: `Staðfesta`,
  COM_Package: `Pakki`,
  COM_SoldOutError: `Það eru engin laus herbergi eða pakkar á þessari dagsetningu`,
  COM_SearchNew: `Ný leit`,
  COM_TableReservation: `Borðapantanir`,
  COM_TableNumberOfPersons: `Fjöldi fólks við borðið`,
  COM_Guests: `Gestir`,
  COM_TableChooseProduct: `Veldu dagsetningu og vörur`,
  COM_TablePlaceGuests: `Þú þarft að setja`,
  COM_TableChooseProducts: `Veldu vöru(r)`,
  COM_TableNumberOfGuestsForProduct: `Fjöldi gesta fyrir þessa vöru`,
  COM_TableBookingFlowAddProduct: `Viltu bæta við borðpöntun við dvölina?`,
  COM_TableIfNotPressContinue: `Ef ekki ýttu á halda áfram`,
  COM_AddToBasket: `Setja í körfu`,
  COM_AddedToBasket: `Bætt við í kurv`,
  COM_Type: `Gerð`,
  COM_SeatingSoldOut: `Ekkert í boði í augnablikinu`,
  COM_Main: `Heim`,
  COM_Conference: `Ráðstefna`,
  COM_Quantity: `Magn`,

  LFO_Email: `Tölvupóstur`,
  LFO_EmailRequried: `Tölvupóstur er nauðsynlegur`,
  LFO_EmailInvalid: `Tölvupóstur er ekki gildur`,
  LFO_Password: `Lykilorð`,
  LFO_PasswordRequried: `Lykilorð krafist`,
  LFO_ForgotPassword: `Geturðu ekki fengið aðgang að reikningnum þínum?`,
  LFO_Login: `Skrá inn`,
  LFO_BackToLogin: `Til baka í Innskráning`,
  LFO_Send: `Senda`,
  LFO_MessageSend: `Skilaboð send!`,
  LFO_LoginError: `Rangt netfang eða lykilorð`,
  LFO_RecoveryError: `Innskráningarupplýsingar ógildar`,

  SFO_ChooseHotel: `Hótelin okkar`,
  SFO_ChooseHolidayResort: `Orlofsdvalarstaðirnir okkar`,
  SFO_ChooseCenter: `Miðstöðvar okkar`,
  SFO_ChooseDepartment: `Veldu Deild`,
  SFO_ParamsHeader: `Veldu dagsetningu`,
  SFO_Guests: `Gestir í herbergi`,
  SFO_GuestsCabin: `Gestir í Cabin`,
  SFO_GuestsHouse: `Gestir í húsinu`,
  SFO_GuestsUnit: `Gestir í einingu`,
  SFO_AddRoom: `Fleiri herbergi`,
  SFO_AddCabin: `Fleiri skálar`,
  SFO_AddHouse: `Fleiri hús`,
  SFO_AddUnit: `Fleiri einingar`,
  SFO_BookStay: `vera`,
  SFO_BookEvent: `Event`,
  SFO_BookTakeAway: `Taka í burtu`,
  SFO_BookTableReservation: `Borðapantanir`,
  SFO_BookGiftcard: `Gjafakort`,
  SFO_GiftcardMetaData: `Lýsigögn fyrir gjafakortið þitt`,
  SFO_ChooseGiftcard: `Veldu Gjafakort`,
  SFO_NumberOfGiftcards: `Hversu mörg af völdum gjafakortum viltu kaupa?`,
  SFO_GiftcardAmount: `Sláðu inn upphæð`,
  SFO_GiftcardEnterDetails: `Sláðu inn upplýsingar um gjafakortin þín`,
  SFO_ChooseGiftcardDescription: `Kauptu gjafakort til einhvers sem þér þykir vænt um`,
  SFO_GiftcardDetails: `Upplýsingar um gjafakort`,
  SFO_GiftcardTo: `Fyrir hvern er gjafakortið (valfrjálst)`,
  SFO_GiftcardFrom: `Frá hverjum er gjafakortið (valfrjálst)`,
  SFO_GiftcardMessage: `Skrifaðu kveðju (Hámark 100 stafir) (valfrjálst)`,
  SFO_BookArrangement: `Fyrirkomulag`,
  SFO_BookConcert: `Tónleikar`,
  SFO_BookTableArangement: `Borð/fyrirkomulag`,
  SFO_BookTableEvent: `Tafla/viðburður`,
  SFO_BordTakeAway: `Borð / Take Away`,
  SFO_BookTicket: `Miði`,
  SFO_BookLecture: `Fyrirlestur`,
  SFO_BookTable: `Bókaborð`,
  SFO_BookSpa: `Spa`,
  SFO_BookExperience: `Viðburður`,
  SFO_ChooseMonth: `Veldu mánuð`,
  SFO_NoRooms: `Engin herbergi fundust á völdum tíma!`,
  SFO_MinLOS: `Valið tímabil krefst bókunar að lágmarki`,
  SFO_Nights: `nætur`,
  SFO_MaxLOS: `Á völdu tímabili er hægt að bóka að hámarki`,

  CAL_WeekStart: `1`,
  CAL_M01: `janúar`,
  CAL_M02: `febrúar`,
  CAL_M03: `mars`,
  CAL_M04: `apríl`,
  CAL_M05: `maí`,
  CAL_M06: `júní`,
  CAL_M07: `júlí`,
  CAL_M08: `ágúst`,
  CAL_M09: `september`,
  CAL_M10: `október`,
  CAL_M11: `nóvember`,
  CAL_M12: `desember`,
  CAL_D0: `sunnudag`,
  CAL_D1: `Mánudagur`,
  CAL_D2: `þriðjudag`,
  CAL_D3: `miðvikudag`,
  CAL_D4: `fimmtudag`,
  CAL_D5: `föstudag`,
  CAL_D6: `laugardag`,
  CAL_Selected: `Valið`,
  CAL_Occupied: `Uppselt/Lokað`,
  CAL_Arrival: `Lokað fyrir komu`,
  CAL_Departure: `Lokað vegna brottfarar`,
  CAL_Mon: `M`,
  CAL_Tirs: `þ`,
  CAL_Ons: `m`,
  CAL_Tors: `f`,
  CAL_Fre: `f`,
  CAL_Lor: `L`,
  CAL_Sun: `S`,
  CAL_Available: `Laus`,
  CAL_NotPossible: `Ekki mögulegt`,

  BIN_ChangeDate: `Breyta dagsetningu`,
  BIN_ChangeRoom: `Breyta`,
  BIN_ChangeAddOns: `Breyta viðbótum`,
  BIN_NoAddOns: `Engum þjónustu bætt við`,
  BIN_AddOns: `Þjónusta bætt við`,
  BIN_Reservation: `Bókun þín`,
  BIN_Details: `Grunnupplýsingar`,
  BIN_RoomCount: `Herbergi`,
  BIN_CabinCount: `Skálar`,
  BIN_HouseCount: `Hús`,
  BIN_UnitCount: `Einingar`,
  BIN_Roomtype: `Herbergistegund`,
  BIN_Cabintype: `Gerð klefa`,
  BIN_Housetype: `Húsgerð`,
  BIN_Unittype: `Tegund eininga`,
  BIN_Packagetype: `Tegund pakka`,
  BIN_BasketEmpty: `Karfan er tóm`,
  BIN_BasketGoToCheckout: `Farðu í kassa`,

  EXC_EmptyBasket: `Karfan má ekki vera tóm`,

  SEA_Date: `Dagsetning`,
  SEA_Time: `Tími`,
  SEA_Quantity: `Magn`,
  SEA_Skip: `Sleppa`,
  SEA_ChooseEvent: `Veldu Viðburður`,
  SEA_DescriptionStart: `Select`,
  SEA_DescriptionEnd: `below or date in the calendar`,
  SEA_SoldOut: `Allt er uppselt fyrir valinn mánuð`,
  SEA_SoldOutContinue: `Everything is sold out for the chosen month`,
  SEA_SeatingStandAloneTitle: `Veldu dagsetningu eða eina af þeim sem sýndar eru valkosti`,
  SEA_ResetCalendar: `Sýna allar`,

  MAT_CALENDAR_Calendar_Label: `S`,
  MAT_CALENDAR_Close_Calendar_Label: `S`,
  MAT_CALENDAR_End_Date_Label: `S`,
  MAT_CALENDAR_Next_Month_Label: `S`,
  MAT_CALENDAR_Next_Multi_Year_Label: `S`,
  MAT_CALENDAR_Next_Year_Label: `S`,
  MAT_CALENDAR_Open_Calendar_Label: `S`,
  MAT_CALENDAR_Prev_Month_Label: `S`,
  MAT_CALENDAR_Prev_Multi_Year_Label: `S`,
  MAT_CALENDAR_Prev_Year_Label: `S`,
  MAT_CALENDAR_Start_Date_Label: `S`,
  MAT_CALENDAR_Switch_To_Month_View_Label: `S`,
  MAT_CALENDAR_Switch_To_Multi_Year_View_Label: `S`,
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DateHelper, Guid, InMemoryCache } from 'src/app/helpers';
import { Locale } from 'src/i18n';
import { lastValueFrom } from 'rxjs';
import { LocaleService } from '..';
import { ApplicationStateService } from '../../services';
import { ConfigService, HotelCodeString } from '../config';
import { environment } from '../../../../environments/environment';
import { Rate, Room } from './models';
import { HotelType, RatePlanDataType, RatePlanType, RoomType, SeatingData } from './models/data.model';

@Injectable()
export class DataHotelService {
  private departmentCache = new InMemoryCache<HotelType>(15000); // 15 seconds cache strategy

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private applicationState: ApplicationStateService,
    private localeService: LocaleService,
    private meta: Meta,
    private title: Title,
  ) {}

  // New endpoints
  async initializeImportantDateToApplicationState() {
    const currentHotel = await this.GetECommerceDepartmentInformation(
      this.applicationState.CurrentHotelCode,
      Locale.GB,
    );
    if (currentHotel && currentHotel.Settings.ReceiptUrl) {
      this.applicationState.CustomReceiptRedirectURL = currentHotel.Settings.ReceiptUrl;
    } else {
      this.applicationState.CustomReceiptRedirectURL = '';
    }

    if (currentHotel && currentHotel.Settings.LogoUrl) {
      this.applicationState.CustomLogoRedirectURL = currentHotel.Settings.LogoUrl;
    } else {
      this.applicationState.CustomLogoRedirectURL = '';
    }

    if (currentHotel) {
      this.applicationState.AnimateBackground = currentHotel.Settings.AnimatedBackground === false ? 'false' : 'true';
      this.applicationState.MandatoryEmail = currentHotel.Settings.MandatoryEmail;
      this.applicationState.MandatoryPhone = currentHotel.Settings.MandatoryPhone;
      this.applicationState.MaxAQC = currentHotel.Settings.MaxAQC.toString();
      this.applicationState.BackGroundimage = currentHotel.Image;
      this.applicationState.ApplicationLogo = currentHotel.Logo;
      this.applicationState.UseGiftcard = currentHotel.Settings.UseGiftcard;
      this.applicationState.UseGiftcardFrom = currentHotel.Settings.UseGiftcardFrom;
      this.applicationState.UseGiftcardTo = currentHotel.Settings.UseGiftcardTo;
      this.applicationState.UseGiftcardMessage = currentHotel.Settings.UseGiftcardMessage;
      this.applicationState.UseTableReservation = currentHotel.Settings.UseTableReservation;
      this.applicationState.InclueTableReservationBookingFlow =
        currentHotel.Settings.IncludeTableReservationInBookingFlow;
      this.applicationState.InclueSeatingBookingFlow = currentHotel.Settings.IncludeSeatingsInBookingFlow;
      this.applicationState.UseSpectraOnlineBooking = currentHotel.Settings.UseSpectraOnlineBooking;
      this.applicationState.PayWithGiftcard = currentHotel.Settings.PayWithGiftcard;
      this.applicationState.UseSeatings = currentHotel.Settings.UseSeating;
      this.applicationState.UseNavbarLinks = currentHotel.Settings.ShowNavbarLinks;
      this.applicationState.DesignPackage = currentHotel.Settings.DesignPackage;
      this.applicationState.UseConferenceModule = currentHotel.Settings.UseConferenceModule;
      this.applicationState.ApplicationColor = currentHotel.Settings.CustomColor;
      this.applicationState.ApplicationColorTextIcon = currentHotel.Settings.CustomColorTextIcon;
      this.applicationState.UsePromotion = currentHotel.Settings.UsePromotion;
      this.applicationState.HeaderInjectText = currentHotel.Settings.HeaderInjectText;
      this.applicationState.UseExtraServices = currentHotel.UseExtraServices;
      this.applicationState.UseSpectraOnlineBookingMenuPoint = currentHotel.Settings.UseSpectraOnlineBookingMenuPoint;
      this.applicationState.UseSeatingMenuPoint = currentHotel.Settings.UseSeatingMenuPoint;
      this.applicationState.UseTableReservationMenuPoint = currentHotel.Settings.UseTableReservationMenuPoint;
      this.applicationState.UseGiftcardMenuPoint = currentHotel.Settings.UseGiftcardMenuPoint;
      this.applicationState.UseConferenceBookingMenuPoint = currentHotel.Settings.UseConferenceBookingMenuPoint;
      this.configService.applyFavicon(currentHotel.Favicon);
      this.buildLabels(currentHotel);
      this.buildApplicationFont(currentHotel);
      this.injectHeadTagFromCustomerPortal();
      this.title.setTitle(currentHotel.Settings.CustomPageTitle || `${currentHotel.Name} E-Commerce Platform`);
    }

    const response = await this.getHotelConfiguration();

    if (response) {
      this.applicationState.UseNetsEasy = response.Platform === 'Nets Easy' ? true : false;
      this.applicationState.UseNetAxept = response.Platform === 'NetAxept' ? true : false;
      this.applicationState.AcceptedCards = response.AcceptedCards;
      this.applicationState.HotelCurrency = response.Currency;
      this.applicationState.NetsPaymentEnabled =
        response.DibsState === Dibs.none ? false : response.DibsState !== Dibs.disabled;
    }
  }

  injectHeadTagFromCustomerPortal() {
    const toInject = this.applicationState.HeaderInjectText;

    if (toInject && toInject !== '' && toInject !== 'null') {
      const split = toInject.split('"', 4);

      if (1 in split && 3 in split) {
        this.meta.addTag({ name: split[1], content: split[3] });
      }
    }
  }

  async GetECommerceDepartmentInformation(hotelCode: string, lang: string) {
    return await this.departmentCache.getOrCreateAsync(hotelCode + '-' + lang, async () => {
      const config = await this.configService.getAppSettings();
      const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
      const data = await lastValueFrom(
        this.http.get<HotelType>(
          `${endpoint}/api/ECommerceData/GetECommerceDepartmentInformation/${hotelCode}/${lang}`,
        ),
      );

      return data;
    });
  }

  async GetECommerceDepartmentsInformation() {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const lang = await this.localeService.getLocale();
    const data = await lastValueFrom(
      this.http.get<HotelType[]>(`${endpoint}/api/ECommerceData/GetECommerceDepartments/${lang}`),
    );

    return data;
  }

  async GetECommerceRoomInfo(hotelCode: string, roomCode: string, lang: string) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const encodedRoomCode = encodeURIComponent(roomCode);
    const data = await lastValueFrom(
      this.http.get<Room>(
        `${endpoint}/api/ECommerceData/GetECommerceRoomInfo?hotelCode=${hotelCode}&roomCode=${encodedRoomCode}&lang=${lang}`,
      ),
    );

    return data;
  }

  async GetECommerceRateInfo(hotelCode: string, rateCode: string, lang: string) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const encodedRateCode = encodeURIComponent(rateCode);
    const data = await lastValueFrom(
      this.http.get<Rate>(
        `${endpoint}/api/ECommerceData/GetECommerceRateInfo?hotelCode=${hotelCode}&rateCode=${encodedRateCode}&lang=${lang}`,
      ),
    );

    return data;
  }

  async IsSeatingsAvailable(hotelCode: string) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const lang = await this.localeService.getLocale();
    const data = await lastValueFrom(
      this.http.get<boolean>(`${endpoint}/api/eCommerceData/seatingsAvailableNextYear/${hotelCode}/${lang}`),
    );

    return data;
  }

  async IsTablesAvailable(hotelCode: string) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const lang = await this.localeService.getLocale();
    const data = await lastValueFrom(
      this.http.get<boolean>(`${endpoint}/api/eCommerceData/tablesAvailableNextYear/${hotelCode}/${lang}`),
    );

    return data;
  }

  async GetStayAvailability(
    hotelCode: string,
    fromDate: Date,
    toDate: Date,
    rateCode: string | undefined,
    companyId: number | undefined,
  ) {
    let definedRateCode = '';

    if (rateCode) {
      definedRateCode = rateCode;
    }
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const serverFromDate = DateHelper.toServerDateFormatString(fromDate);
    const serverToDate = DateHelper.toServerDateFormatString(toDate);
    const data = await lastValueFrom(
      this.http.get<StayAvailabilityResponse[]>(
        `${endpoint}/api/eCommerceData/getStayAvailability/${hotelCode}/${serverFromDate}/${serverToDate}/${definedRateCode}/${companyId}`,
      ),
    );
    return data;
  }

  async GetECommerceSeatings(hotelCode: string, fromDate: Date, toDate: Date, lang: string, distinctByDate = false) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const serverFromDate = DateHelper.toServerDateFormatString(fromDate);
    const serverToDate = DateHelper.toServerDateFormatString(toDate);
    const data = await lastValueFrom(
      this.http.get<SeatingData[]>(
        `${endpoint}/api/eCommerceData/GetECommerceSeatings/${hotelCode}/${serverFromDate}/${serverToDate}/${lang}?distinctByDate=${distinctByDate}`,
      ),
    );
    return data;
  }

  async GetECommerceSeating(hotelCode: string, fromDate: Date, toDate: Date, lang: string, characteristics: string) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const serverFromDate = DateHelper.toServerDateFormatString(fromDate);
    const serverToDate = DateHelper.toServerDateFormatString(toDate);
    const data = await lastValueFrom(
      this.http.get<SeatingData[]>(
        `${endpoint}/api/eCommerceData/GetECommerceSeating/${hotelCode}/${serverFromDate}/${serverToDate}/${lang}/${characteristics}`,
      ),
    );
    return data;
  }

  async GetECommerceSeatingPortalInfo(
    hotelCode: string,
    fromDate: Date,
    toDate: Date,
    seatingCode: string,
    bookingflow: boolean,
    rateCode: string,
  ) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const serverFromDate = DateHelper.toServerDateFormatString(fromDate);
    const serverToDate = DateHelper.toServerDateFormatString(toDate);
    const lang = await this.localeService.getLocale();
    const data = await lastValueFrom(
      this.http.get<CustomerPortalSeatingInfo[]>(
        `${endpoint}/api/eCommerceData/GetECommercePortalSeatingInfo/${hotelCode}/${serverFromDate}/${serverToDate}/${lang}/${bookingflow}?seatingCode=${seatingCode}&rateCode=${rateCode}`,
      ),
    );

    return data;
  }

  async GetECommerceTablePortalInfo(
    hotelCode: string,
    fromDate: Date,
    toDate: Date,
    seatingCode: string,
    lang: string,
    bookingflow: boolean,
    rateCode: string,
  ) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const serverFromDate = DateHelper.toServerDateFormatString(fromDate);
    const serverToDate = DateHelper.toServerDateFormatString(toDate);
    const data = await lastValueFrom(
      this.http.get<CustomerPortalSeatingInfo[]>(
        `${endpoint}/api/eCommerceData/GetECommercePortalTableInfo/${hotelCode}/${serverFromDate}/${serverToDate}/${lang}/${bookingflow}?seatingCode=${seatingCode}&rateCode=${rateCode}`,
      ),
    );

    return data;
  }

  async GetECommerceConferenceRoomsInfo(hotelCode: string, lang: string) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const data = await lastValueFrom(
      this.http.get<ConferenceRoom[]>(`${endpoint}/api/ECommerceData/GetECommerceConferenceRooms/${hotelCode}/${lang}`),
    );

    return data;
  }

  async GetECommerceConferenceRoomInfo(hotelCode: string, lang: string, roomCode: string) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const data = await lastValueFrom(
      this.http.get<ConferenceRoom>(
        `${endpoint}/api/ECommerceData/GetECommerceConferenceRoom/${hotelCode}/${lang}/${roomCode}`,
      ),
    );

    return data;
  }

  async GetECommerceExtraServiceInfo(
    hotelCode: string,
    itemNumber: string,
    lang: string,
    ratePlan?: string,
    roomType?: string,
  ) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    let url = `${endpoint}/api/ECommerceData/GetECommerceExtraServices/${hotelCode}/${itemNumber}/${lang}`;
    if (ratePlan || roomType) {
      url += '?';
      if (ratePlan) {
        url += `ratePlan=${ratePlan}`;
      }
      if (roomType) {
        url += `&roomType=${roomType}`;
      }
    }
    const data = await lastValueFrom(this.http.get<ExtraServiceModel>(url));
    return data;
  }

  async GetECommerceExtraServices({
    hotelCode,
    ratePlan,
    fromDate,
    toDate,
    lang,
    adultCount,
    agecategory1count,
    agecategory2count,
    agecategory3count,
    agecategory4count,
    roomCode,
    seatingCode,
  }: {
    // Destructuring with type annotation:
    hotelCode: string;
    ratePlan: string | null;
    fromDate: string;
    toDate: string;
    lang: string;
    adultCount: number;
    agecategory1count: number;
    agecategory2count: number;
    agecategory3count: number;
    agecategory4count: number;
    roomCode: string;
    seatingCode: string | undefined;
  }) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const baseData = `${hotelCode}/${fromDate}/${toDate}/${lang}`;
    const aqcCounts = `${adultCount}/${agecategory1count}/${agecategory2count}/${agecategory3count}/${agecategory4count}`;
    const productCodes = `?roomCode=${roomCode}&ratePlan=${ratePlan}&seatingCode=${seatingCode}`;

    const data = await lastValueFrom(
      this.http.get<ExtraServiceModel[]>(
        `${endpoint}/api/ECommerceData/GetECommerceExtraServices/${baseData}/${aqcCounts}${productCodes}`,
      ),
    );

    return data;
  }

  async GetECommerceSeatingsAvailableMonths(hotelCode: string, fromDate: Date, toDate: Date, bookingflow: boolean) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const serverFromDate = DateHelper.toServerDateFormatString(fromDate);
    const serverToDate = DateHelper.toServerDateFormatString(toDate);
    const lang = await this.localeService.getLocale();
    const data = await lastValueFrom(
      this.http.get<Date[]>(
        `${endpoint}/api/ECommerceData/SeatingsAvailableMonths/${hotelCode}/${serverFromDate}/${serverToDate}/${bookingflow}/${lang}`,
      ),
    );

    return data;
  }

  async getTableAvailability(
    hotelCode: HotelCodeString,
    arrival: Date,
    departure: Date,
    guestCount: number,
    bookingFlow: boolean,
    rateCode: string,
  ) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const serverFromDate = DateHelper.toServerDateFormatString(arrival);
    const serverToDate = DateHelper.toServerDateFormatString(departure);
    const lang = await this.localeService.getLocale();
    const data = await lastValueFrom(
      this.http.get<Date[]>(
        `${endpoint}/api/ECommerceData/GetECommerceTableAvailability/${hotelCode}/${serverFromDate}/${serverToDate}/${guestCount}/${lang}/${bookingFlow}/${rateCode}`,
      ),
    );

    return data;
  }

  async payAmountWithGiftcard(model: GiftcardReservationPayment) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const data = await lastValueFrom(
      this.http.post<GiftcardPaymentResponse>(`${endpoint}/PayAmountWithGiftcard`, model),
    );

    return data;
  }

  async GetSpectraRoomInfo(
    hotelCode: string,
    fromdate: Date,
    todate: Date,
    rateplan: string,
    guests: GuestCombination[],
    language: string,
    companyId: number | undefined,
  ) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const data = await lastValueFrom(
      this.http.post<SpectraApiRoomResponseModel[]>(`${endpoint}/api/eCommerce/GetSpectraRooms/`, {
        HotelCode: hotelCode,
        FromDate: DateHelper.toServerDateFormatString(fromdate),
        ToDate: DateHelper.toServerDateFormatString(todate),
        RateCode: rateplan || 'undefined',
        RoomCode: 'undefined',
        Guests: guests,
        LanguageCode: language,
        CompanyId: companyId,
      } as RoomsAndRatesModel),
    );

    return data;
  }

  async GetSpectraRateInfo(
    hotelCode: string,
    fromdate: Date,
    todate: Date,
    roomCode: string,
    guests: GuestCombination[],
    language: string,
    companyId: number | undefined,
  ) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const data = await lastValueFrom(
      this.http.post<SpectraApiRateResponseModel[]>(`${endpoint}/api/eCommerce/GetSpectraRates/`, {
        HotelCode: hotelCode,
        FromDate: DateHelper.toServerDateFormatString(fromdate),
        ToDate: DateHelper.toServerDateFormatString(todate),
        RateCode: 'undefined',
        RoomCode: roomCode || 'undefined',
        Guests: guests,
        LanguageCode: language,
        CompanyId: companyId,
      } as RoomsAndRatesModel),
    );
    return data;
  }

  async GetSpectraCancellationprotection(hotelCode: string) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const data = await lastValueFrom(
      this.http.get<CancellationProtection>(`${endpoint}/api/eCommerce/GetCancellationprotection/${hotelCode}`),
    );

    return data;
  }

  async GetCompanyAgreementDiscount(hotelCode: string, companyId: number) {
    const config = await this.configService.getAppSettings();
    const endpoint = this.getBaseUrl(config.ECommerceDataProvider);
    const data = await lastValueFrom(
      this.http.get<CompanyAgreementDiscountResponseModel[]>(
        `${endpoint}/api/eCommerce/GetCompanyAgreements/${hotelCode}/${companyId}`,
      ),
    );

    return data;
  }

  private buildApplicationFont(hotel: HotelType) {
    const fontMap: { [key: number]: string } = {
      1: 'Arial',
      2: 'Serif',
      3: 'Helvetica',
      4: 'Courier New',
      5: 'Verdana',
      6: 'Courier',
      7: 'Candara',
      8: 'Calibri',
      9: 'Cambria',
      10: 'Perpetua',
      11: 'Brush Script MT',
      12: 'Lucida Bright',
      13: 'Segoe UI',
      14: 'Tahoma',
      15: 'Century Gothic',
    };

    const selectedFont = fontMap[hotel.Settings.UIFonts];
    if (selectedFont) {
      this.applicationState.ApplicationFont = selectedFont;
    }
  }

  private buildLabels(hotel: HotelType) {
    // Build dynamic hotellabel
    const hotelLabelMap: { [key: number]: string } = {
      1: 'SFO_ChooseHolidayResort',
      2: 'SFO_ChooseHotel',
      3: 'SFO_ChooseDepartment',
      4: 'SFO_ChooseCenter',
    };

    const selectedHotelLabel = hotelLabelMap[hotel.Settings.HotelLabel];
    if (selectedHotelLabel) {
      this.applicationState.HotelLabel = selectedHotelLabel;
    }

    // Build dynamic Eventlabel
    const eventLabelMap: { [key: number]: string } = {
      1: 'SFO_BookEvent',
      2: 'SFO_BookTakeAway',
      3: 'SFO_BookTableReservation',
      4: 'SFO_BookArrangement',
      5: 'SFO_BookConcert',
      6: 'SFO_BookTableArangement',
      7: 'SFO_BookTableEvent',
      8: 'SFO_BordTakeAway',
      9: 'SFO_BookTicket',
      10: 'SFO_BookLecture',
      11: 'SFO_BookTable',
      12: 'SFO_BookSpa',
      13: 'SFO_BookExperience',
    };

    const selectedEventLabel = eventLabelMap[hotel.Settings.EventLabel];
    if (selectedEventLabel) {
      this.applicationState.EventLabel = selectedEventLabel;
    }

    // Build Dynamic Roomlabels
    const roomLabelMap: {
      [key: number]: {
        RoomLabel: string;
        RoomsLabel: string;
        GuestInRoomLabel: string;
        ChooseYourRoom: string;
        MoreRoomLabel: string;
        ChooseRoomLabel: string;
        RoomTypeLabel: string;
      };
    } = {
      1: {
        RoomLabel: 'COM_Cabin',
        RoomsLabel: 'BIN_CabinCount',
        GuestInRoomLabel: 'SFO_GuestsCabin',
        ChooseYourRoom: 'RPA_ChooseYourCabin',
        MoreRoomLabel: 'SFO_AddCabin',
        ChooseRoomLabel: 'RPA_ChooseCabin',
        RoomTypeLabel: 'BIN_Cabintype',
      },
      2: {
        RoomLabel: 'COM_House',
        RoomsLabel: 'BIN_HouseCount',
        GuestInRoomLabel: 'SFO_GuestsHouse',
        ChooseYourRoom: 'RPA_ChooseYourHouse',
        MoreRoomLabel: 'SFO_AddHouse',
        ChooseRoomLabel: 'RPA_ChooseHouse',
        RoomTypeLabel: 'BIN_Housetype',
      },
      3: {
        RoomLabel: 'COM_Room',
        RoomsLabel: 'COM_Rooms',
        GuestInRoomLabel: 'SFO_Guests',
        ChooseYourRoom: 'RPA_ChooseYourRoom',
        MoreRoomLabel: 'SFO_AddRoom',
        ChooseRoomLabel: 'RPA_ChooseRoom',
        RoomTypeLabel: 'BIN_Roomtype',
      },
      4: {
        RoomLabel: 'COM_Unit',
        RoomsLabel: 'BIN_UnitCount',
        GuestInRoomLabel: 'SFO_GuestsUnit',
        ChooseYourRoom: 'RPA_ChooseYourUnit',
        MoreRoomLabel: 'SFO_AddUnit',
        ChooseRoomLabel: 'RPA_ChooseUnit',
        RoomTypeLabel: 'BIN_Unittype',
      },
    };

    const selectedRoomLabel = roomLabelMap[hotel.Settings.RoomLabel];
    if (selectedRoomLabel) {
      this.applicationState.RoomLabel = selectedRoomLabel.RoomLabel;
      this.applicationState.RoomsLabel = selectedRoomLabel.RoomsLabel;
      this.applicationState.GuestInRoomLabel = selectedRoomLabel.GuestInRoomLabel;
      this.applicationState.ChooseYourRoom = selectedRoomLabel.ChooseYourRoom;
      this.applicationState.MoreRoomLabel = selectedRoomLabel.MoreRoomLabel;
      this.applicationState.ChooseRoomLabel = selectedRoomLabel.ChooseRoomLabel;
      this.applicationState.RoomTypeLabel = selectedRoomLabel.RoomTypeLabel;
    }
  }

  private async getHotelConfiguration() {
    const [appSettings] = await Promise.all([this.configService.getAppSettings()]);

    const res = await lastValueFrom(
      this.http.get<HotelConfigurationModel>(
        `${this.getBaseUrl(appSettings.ECommerceDataProvider)}/Configuration?hotelCode=${
          this.applicationState.CurrentHotelCode
        }`,
      ),
    );

    return res;
  }

  private getBaseUrl(dataProvider: string): string {
    return environment.production ? dataProvider : 'https://localhost:44343';
  }
}

export interface CompanyAgreementDiscountResponseModel {
  RatePlanCode: string;
  DiscountPercentage: string;
  FromDate: Date;
  ToDate: Date;
}

export interface CustomerPortalSeatingInfo {
  ID: string;
  HotelCode: string;
  Image: string;
  Long: string;
  SeatItems: SeatItems[];
  Name: string;
  Short: string;
  SortIndex: number;
}

export interface SeatItems {
  Name: string;
  Price: number;
  VareNr: string;
}

export interface StayAvailabilityResponse {
  Date: Date;
  Reason: string;
  Description: string;
  NumberOfDays: number;
}

export interface RoomTypeInfo {
  RoomType: string;
  MaxPersons: number;
  Vacant: number;
  Price: number;
}

export interface ConferenceRoom {
  RoomTypeName: string;
  ImageOfRoom1: string;
  ImageOfRoom2: string;
  ImageOfRoom3: string;
  ShortDescription: string;
  LongDescription: string;
  Maxpers: number;
  ECommerceTableSetups: ECommerceTableSetup[];
}

export interface Dates {
  Date: string;
  selectedCount: number;
  maxCount: number;
}

export interface BookingExtraServiceModel {
  Name: string;
  ServiceId: string;
  Id: number;
  Amount: number;
  Count: number;
  Dates: Dates[];
}

export interface ExtraServiceModel extends BookingExtraServiceModel {
  Long: string;
  Image: string;
  SortIndex: number;
}

export interface ECommerceTableSetup {
  RoomNumber: string;
  Description: string;
  Persons: number;
  Image: string;
}

export interface RateCode {
  RateCode: string;
  RoomTypes: RoomTypeInfo[];
}

interface RateData extends RatePlanType {
  Promotion: RatePlanDataType | undefined;
  MinPers: number;
  MaxPers: number;
  InvBlockCode: string | null;
  Price: number | null;
  OriginalPrice: number | null;
  Quantity: number;
  minStay: number;
  maxStay: number;
}

export interface HotelRoomRateData {
  roomType: RoomType;
  Rates: RateData[];
}

export interface GiftcardReservationPayment {
  GiftCardNumber: string;
  ReservationNumber: number;
  Amount: number;
  resGuid: Guid;
  currency: string;
}

export interface GiftcardPaymentResponse {
  TransactionId: number;
  GiftcardNumber: string;
  GiftcardAccountNumber: string;
  TicketNumber: string;
}

interface HotelConfigurationModel {
  AcceptedCards: string;
  Platform: string;
  Currency: string;
  DibsState: Dibs;
}

export enum Dibs {
  none,
  disabled,
  test,
  production,
}

export interface SpectraApiRoomResponseModel {
  AvailableQuantity: number;
  Rateplans: RoomRates[];
  HotelCode: string;
  RoomType: string;
  MaxPersons: number;
}

export interface RoomRates {
  RatePlan: string;
  Description: string;
  Price: number;
  Name: string;
}

export interface SpectraApiRateResponseModel {
  RatePlan: string;
  Description: string;
  Name: string;
  RoomTypes: RatesRoom[];
}

export interface RatesRoom {
  AvailableQuantity: number;
  RoomType: string;
  MaxPersons: number;
  Price: number;
}

export interface RoomsAndRatesModel {
  HotelCode: string;
  FromDate: string;
  ToDate: string;
  RateCode: string;
  RoomCode: string;
  Guests: GuestCombination[];
  LanguageCode: string;
  CompanyId: number;
}

export interface GuestCombination {
  AdultCount: number;
  InfantCount: number;
  ChildCount: number;
  TeenagerCount: number;
  SeniorCount: number;
}

export interface CancellationProtection {
  Enabled: boolean;
  MinimumAmount: number;
  Percentage: number;
}

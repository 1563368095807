<div class="seating-date-calendar">
  <mat-calendar
    class="seating-date-calendar__picker"
    *ngIf="!updateComponent"
    [startAt]="startAt"
    [selected]="selectedDate$ | async"
    (selectedChange)="setCalendarDate($event)"
    [dateClass]="highlightedDates"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateFilter]="dateFilter"></mat-calendar>
  <hr style="border: 1.5px solid var(--primary-application-color)" />
  <div class="seating-date-calendar__information">
    <div class="seating-date-calendar__information-occupied">
      <span class="seating-date-calendar__information-occupied-dot"></span>{{ 'CAL_Occupied' | t | async }}
    </div>
    <span style="width: 10px"></span>
    <div class="seating-date-calendar__information-inbasket">
      <span class="seating-date-calendar__information-inbasket-dot"></span>{{ 'COM_AddedToBasket' | t | async }}
    </div>
  </div>
</div>

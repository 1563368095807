import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DataPromotionService } from './data-promotion.service';
import { PromotionService } from './promotion.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [DataPromotionService, PromotionService],
  exports: [],
})
export class PromotionModule {
  constructor(@Optional() @SkipSelf() parentModule: PromotionModule) {
    if (parentModule) {
      throw new Error('PromotionModule is already loaded. Import it in the CoreModule only');
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Messenger } from './messenger';
import { MessengerComponent } from './messenger.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MessengerComponent],
  providers: [Messenger],
  exports: [MessengerComponent],
})
export class MessengerModule {}

<div class="reciept-page fadeBody">
  <div class="container">
    <div class="row">
      <nav class="col s12 bg-secondary text-secondary" style="background-color: transparent">
        <div class="section text-center" *ngIf="onlineBookingOrigin">
          <br />
          <br />
          <h2>{{ 'REP_Thanks' | t | async }}</h2>
          <p>{{ 'REP_ThanksInfo' | t | async }}</p>
        </div>
        <div class="section text-center" *ngIf="!onlineBookingOrigin">
          <br />
          <br />
          <h2>{{ 'REP_ThanksPayment' | t | async }}</h2>
        </div>
      </nav>
    </div>
    <div *ngIf="reciept">
      <div class="section text-center">
        <h3 *ngIf="applicationState.Origin !== OriginEnum.GiftCard">
          {{ 'REP_ReservationId' | t | async }}:
          {{ reciept.reservation.reservationId }}
        </h3>
        <h3 *ngIf="applicationState.Origin === OriginEnum.GiftCard">
          {{ 'REP_OrderId' | t | async }}:
          {{ reciept.reservation.reservationId }}
        </h3>
        <br />
        <br />
      </div>
    </div>
  </div>
  <hr />
  <div class="overel-container">
    <div class="overel overel-center">
      <button type="button" class="btn btn-success btn-round m-0 with-shadow">
        <i class="fas fa-check"></i>
      </button>
    </div>
  </div>
  <div class="page-content" *ngIf="onlineBookingOrigin">
    <div class="container">
      <div class="section text-center">
        <a *ngIf="mainWebsiteUrl" class="btn btn-primary" (click)="redirectToPage()">{{ 'REP_Return' | t | async }}</a>
        <a *ngIf="!mainWebsiteUrl" class="btn btn-primary" [routerLink]="['/']">{{ 'REP_Return' | t | async }}</a>
      </div>
    </div>
  </div>
</div>

import dayjs from 'dayjs';

export class SpectraDate {
  static readonly DayInMs = 60 * 60 * 1000 * 24;
  Year: number;
  Month: number;
  Date: number;

  constructor(year?: number, month?: number, date?: number) {
    if (year === undefined) {
      const now = new Date();
      const d = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

      year = d.getUTCFullYear();
      month = d.getUTCMonth();
      date = d.getUTCDate();
    } else if (month === undefined) {
      month = 0;
      date = 1;
    } else if (date === undefined) {
      date = 1;
    }
    this.Year = year;
    this.Month = month;
    this.Date = date;
  }

  isEqual(date: SpectraDate) {
    return this.Year === date.Year && this.Month === date.Month && this.Date === date.Date;
  }

  getTime() {
    const date = Date.UTC(this.Year, this.Month, this.Date);
    return date;
  }

  addDays(days: number) {
    return SpectraDate.FromTime(this.getTime() + days * SpectraDate.DayInMs);
  }

  addMonth(month: number) {
    const date = this.toDayjs().set('date', 1).add(month, 'month').add(-1, 'day');
    return new SpectraDate(date.year(), date.month(), date.date());
  }

  format(format: string) {
    return dayjs(this.getTime()).format(format);
  }

  toDayjs() {
    return dayjs(this.getTime());
  }

  toString() {
    return this.toDayjs().format('YYYY-MM-DD') as UTCDateString;
  }

  toAspString(): AspDateString {
    return `/Date(${this.getTime()})/`;
  }

  toJSON() {
    return this.toString();
  }

  static FromTime(time: number) {
    const date = new Date(time);
    return new SpectraDate(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  }

  static FromAspDate(date: AspDateString) {
    return this.FromTime(+date.replace(/[^\d]+/g, ''));
  }

  static FromDKDate(date: DKDateString) {
    return this.FromTime(new Date(date.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1T00:00:00.000Z')).getTime());
  }

  static FromAspDateWithTimezone(date: AspDateWithTimeZoneString) {
    let time = 0;
    const matches = date.match(/\/Date\((\d+)(-)(\d{2})(\d{2})\)\//);
    if (matches) {
      time =
        (+matches[1] || 0) + (matches[2] === '+' ? 1 : -1) * ((+matches[3] || 0) * 60 + (+matches[4] || 0)) * 60000;
    }
    return this.FromTime(time);
  }

  static FromUtcDate(date: UTCDateString | UTCDateTimeString) {
    const dateParts = date.split('-');
    return new SpectraDate(+dateParts[0], +dateParts[1] - 1, +dateParts[2].substring(0, 2));
  }

  static FromValues(year: number, month: number, day: number) {
    return new SpectraDate(year, month, day);
  }
}

export type AspDateString = `/Date(${number})/`;
export type AspDateWithTimeZoneString = '/Date(UTC+0200)/';
export type LocalizedDateString = 'dd.mm.yyyy';
export type UTCDateString = 'yyyy-mm-dd';
export type UTCDateTimeString = 'yyyy-mm-ddTHH:MM:SS';
export type DKDateString = 'dd-mm-yyyy';
export type TimeString = '12:00:00';

import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationStateService } from 'src/app/core';
import { NumberPipe } from './number.pipe';

@Pipe({ name: 'price' })
export class PricePipe implements PipeTransform {
  constructor(private applicationState: ApplicationStateService) {}

  async transform(value: number | string | null | undefined) {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (typeof value === 'string') {
      value = parseFloat(value.replace(',', '.'));
    }
    return `${NumberPipe.Format(value)} ${this.applicationState.HotelCurrency}`;
  }
}

import {
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { LogService } from '@com/logging';

@Component({
  selector: 'app-expandable-media-list',
  templateUrl: './expandable-media-list.component.html',
})
export class ExpandableMediaListComponent<T> implements OnChanges {
  // eslint-disable-next-line no-use-before-define
  @Input() usePageContainer = true;
  @Input() insideDetails = false;
  @Input()
  set data(data: T[] | undefined) {
    this._data = data || [];
  }

  private _data: T[] = [];

  groupList: T[][] = [];

  @Input() selected: T | undefined;

  @ContentChild('itemTemplate', { static: false }) itemTemplate: TemplateRef<ElementRef> | undefined;
  @ContentChild('selectedItemTemplate', { static: false })
  selectedItemTemplate: TemplateRef<ElementRef> | undefined;

  constructor(private log: LogService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.groupList = ExpandableMediaListComponent.buildGroupList(
      this._data,
      window.innerWidth,
      this.usePageContainer !== true ? 2 : 3,
    );
  }

  @HostListener('window:resize')
  onResize() {
    this.log.trace('ExpandableMediaList onResize()');
    this.groupList = ExpandableMediaListComponent.buildGroupList(
      this._data,
      window.outerWidth,
      this.usePageContainer !== true ? 2 : 3,
    );
  }

  static buildGroupList<T>(list: T[], width: number, splitterLength: number) {
    const splitter = width >= 992 ? splitterLength : width >= 600 ? 2 : 1;
    const result: T[][] = [];
    let group: T[] = [];
    list.forEach((item) => {
      if (group.length < splitter) {
        group.push(item);
      } else {
        result.push(group);
        group = [item];
      }
    });
    result.push(group);
    return result;
  }
}

import { Component, OnDestroy } from '@angular/core';
import { Observable /*, Subscription*/ } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ApplicationStateService, OriginEnum } from 'src/app/core';
import { Step, StepList } from './step-list';

@Component({
  selector: 'app-step-list',
  templateUrl: './step-list.component.html',
})
export class StepListComponent implements OnDestroy {
  private Steps: number[] = [Step.Search, Step.Package, Step.AddOn, Step.Customer, Step.Payment, Step.Complete];
  // private subscription: Subscription;
  steps$ = new Observable<StepListStep[]>();

  constructor(private stepList: StepList, private applicationState: ApplicationStateService) {
    // this.subscription = this.applicationState.getOriginObservable().subscribe((origin) => {
    if (this.applicationState.Origin === OriginEnum.PaymentLink) {
      this.Steps = [Step.PaymentLink, Step.Payment, Step.Complete];
    } else {
      this.Steps = [Step.Search, Step.Package, Step.AddOn, Step.Customer, Step.Payment, Step.Complete];
    }
    // });

    this.steps$ = this.stepList.step$.pipe(
      delay(0),
      map(({ step, hasError }) => this.BuildSteps(step, hasError)),
    );
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }

  private BuildSteps(step: Step, hasError: boolean) {
    const completeIndex = this.Steps.indexOf(step);
    const steps: StepListStep[] = [];

    for (let i = 0; i < completeIndex; i++) {
      this.AddStep(steps, this.Steps[i], false, false, true);
    }
    if (completeIndex !== -1) {
      this.AddStep(steps, this.Steps[completeIndex], true, hasError);
    }
    for (let i = completeIndex + 1; i < this.Steps.length; i++) {
      this.AddStep(steps, this.Steps[i]);
    }
    return steps;
  }

  private AddStep(steps: StepListStep[], step: Step, active = false, error = false, complete = false) {
    if (step !== Step.Complete) {
      steps.push({
        title: (steps.length + 1).toString(),
        active,
        complete,
        error,
      });
    }
  }
}

interface StepListStep {
  title: string;
  active: boolean;
  complete: boolean;
  error: boolean;
}

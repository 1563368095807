<div class="payment-failed-page fadeBody">
  <div class="container">
    <div class="row">
      <!--<nav class="col s12 bg-secondary text-secondary">-->
      <div class="section col s12">
        <br />
        <br />
        <h2 class="text-danger">{{ 'PFP_Header' | t | async }}</h2>
        <p>{{ 'PFP_Info' | t | async }}</p>
        <br />
        <br />
      </div>
      <!--</nav>-->
    </div>
  </div>
  <hr />
  <div class="overel-container">
    <div class="overel overel-center">
      <button type="button" class="btn btn-danger btn-round m-0 with-shadow">
        <i class="fas fa-exclamation"></i>
      </button>
    </div>
  </div>
  <div class="page-content">
    <div class="container">
      <div class="section text-center">
        <a (click)="next()" class="btn btn-primary with-shadow">{{ 'PFP_TryAgain' | t | async }}</a>
      </div>
    </div>
  </div>
</div>

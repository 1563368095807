export class ActionStack<T> {
  private stack: Promise<T>;

  constructor(private init: T) {
    this.stack = Promise.resolve(init);
  }

  // will return previous result;
  async push(action?: () => Promise<T>) {
    const result = await this.waitForPrevAction();
    if (action) {
      this.stack = (async () => {
        return await action();
      })();
    }
    return result;
  }

  // will return latest result or current if passed;
  async pushAndWait(action?: () => Promise<T>) {
    await this.push(action);
    return await this.waitForPrevAction();
  }

  async waitForPrevAction() {
    try {
      return await this.stack;
    } catch (err) {
      this.stack = Promise.resolve(this.init);
      throw err;
    }
  }
}

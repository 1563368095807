export class InMemoryCache<T> {
  private cache = new Map<string, Promise<T>>();

  /**
   * @lifetime - clear cache every {lifetime} ms. 0 - never clear
   */
  constructor(private lifeTime = 0) {}

  async getOrCreateAsync(key: string, factory: () => Promise<T>) {
    let data = this.cache.get(key);
    if (!data) {
      data = (async () => {
        try {
          return await factory();
        } catch (err) {
          this.cache.delete(key); // should remove cached value on error
          throw err;
        }
      })();
      this.cache.set(key, data);
      if (this.lifeTime > 0) {
        // clear cache every {lifeTime} ms
        setTimeout(() => this.cache.delete(key), this.lifeTime);
      }
    }
    return await data;
  }

  clear() {
    this.cache.clear();
  }
}

<div class="page-content fadeBody">
  <div class="container">
    <a
      class="desktop-show mobile-hide applicationColor"
      style="position: absolute; font-size: 20px"
      (click)="clearBasket()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >
    <a class="desktop-show mobile-hide applicationColor" (click)="next()" style="font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>

    <p class="desktop-hide mobile-show" style="text-align: center; font-size: 40px; font-weight: 500">
      {{ 'COM_TableReservation' | t | async }}
    </p>
    <p class="desktop-show mobile-hide" style="text-align: center; font-size: 40px; font-weight: 500; margin-left: 9%">
      {{ 'COM_TableReservation' | t | async }}
    </p>

    <h2 style="text-align: center; margin-top: 20px">
      <span class="v-middle">{{ 'COM_TableNumberOfPersons' | t | async }}</span>
    </h2>

    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />

    <div class="zoom-content-effect">
      <div class="row counter">
        <div class="col md 2">
          <i
            class="fas fa-minus applicationColor"
            style="font-size: 50px; margin-top: 42px; cursor: pointer"
            (click)="removeGuest()"></i>
        </div>

        <div class="col md 8">
          <input
            type="text"
            style="text-align: center; font-size: 35px; margin-left: 2%"
            value="{{ guestCounter }} {{ 'COM_Guests' | t | async }}"
            class="applicationColor"
            readonly />
        </div>

        <div class="col md 2">
          <i
            class="fas fa-plus applicationColor"
            style="font-size: 50px; margin-top: 40px; margin-right: 10px; cursor: pointer"
            (click)="addGuest()"></i>
        </div>
      </div>
      <div class="container text-center" style="margin-bottom: 70px">
        <div class="section">
          <button type="button" class="btn btn-primary with-shadow" (click)="next()">
            {{ 'COM_Continue' | t | async }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-slide-down',
  templateUrl: './slide-down.component.html',
  styleUrls: ['./slide-down.component.less'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '200px',
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
        }),
      ),
      transition('expanded => collapsed', animate('400ms ease-in')),
      transition('collapsed => expanded', animate('400ms ease-in')),
    ]),
  ],
})
export class SlideDownComponent {
  openDescription: animationState = 'collapsed';
  showDescriptionBackdrop = true;
  @Input() content = '';
  @Input() showAnimation = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  animateOpenDescription(): void {
    if (this.openDescription === 'collapsed') {
      this.openDescription = 'expanded';
    } else {
      this.openDescription = 'collapsed';
    }

    // As there is no debounce or anything, changes can happen really fast, this makes sure its synced
    this.cdRef.detectChanges();
  }

  contentAnimationEvent(event: AnimationEvent) {
    if (event.triggerName === 'expandCollapse') {
      if (event.toState === 'expanded' && event.phaseName === 'done') {
        this.showDescriptionBackdrop = false;
      }

      if (event.toState === 'collapsed' && event.phaseName === 'start') {
        this.showDescriptionBackdrop = true;
      }
    }
  }
}

type animationState = 'collapsed' | 'expanded';

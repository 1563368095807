<ng-template [ngIf]="giftcardInformation === undefined && !hasGiftcard && applicationState.PayWithGiftcard">
  <h2 style="margin-top: 40px"><i class="fas fa-gifts"></i>{{ 'CUP_PayWithGiftcardHeader' | t | async }}</h2>
  <div class="content-section" style="padding-top: 19px">
    <div class="row">
      <div class="col">
        <h3>
          {{ 'CUP_PayWithGiftcardInformation' | t | async }}
          <a class="applicationColor" (click)="toggleGiftcardBox()">{{ 'CUP_PayWithGiftcardClick' | t | async }}</a>
        </h3>
      </div>
    </div>
  </div>
  <div class="form-control" style="padding-left: 10px; padding-top: 0" *ngIf="giftcardClicked">
    <h3 style="color: var(--color-dynamic)">
      {{ 'CUP_PayWithGiftcardDescription' | t | async }}
    </h3>
    <input
      type="text"
      [placeholder]="'CUP_PayWithGiftcardPlaceholder' | t | async"
      [(ngModel)]="giftcardCode"
      name="giftcardCode"
      value="{{ giftcardCode }}"
      autocomplete="off"
      style="max-width: 200px"
      (keydown.space)="$event.preventDefault()" />
    <button
      [ngStyle]="{
        'pointer-events': giftcardCode.length < giftcardLength ? 'none' : 'auto'
      }"
      [disabled]="giftcardCode.length < giftcardLength"
      style="min-width: 120px; margin-left: 5px"
      class="default btn btn-primary with-shadow mb-0"
      type="button"
      (click)="applyGiftcard()">
      {{ 'CUP_PayWithGiftcardRedeem' | t | async }}
    </button>
    <p style="color: red" *ngIf="giftcardNotFound">
      {{ 'CUP_PayWithGiftcardError' | t | async }}
    </p>
  </div>
</ng-template>

<ng-content select="[between]"></ng-content>

<ng-template [ngIf]="giftcardInformation">
  <h2 style="padding-top: 19px">
    <i class="fas fa-gifts"></i>
    <span class="v-middle">{{ 'SFO_BookGiftcard' | t | async }}</span>
  </h2>
  <div class="content-section" style="padding-top: 19px">
    <div class="row">
      <div class="col l4 s12">
        <dl>
          <dt>{{ 'COM_Type' | t | async }}</dt>
          <dd>{{ giftcardInformation.Name }}</dd>
        </dl>
      </div>
      <div class="col l4 s12">
        <dl><dt>Status</dt></dl>
        <p *ngIf="giftcardInformation.Valid" style="color: green">
          {{ 'CUP_PayWithGiftcardStatus' | t | async }}
        </p>
      </div>
      <div class="col l4 s12 text-right">
        <dl>
          <dt>{{ 'CUP_PayWithGiftcardDiscount' | t | async }}</dt>
          <dd class="text-primary">
            <dl class="applicationColor text-right">
              <dd *ngIf="currency === ''">
                {{ giftcardInformation.Amount | price | async }}
              </dd>
              <dd *ngIf="currency !== ''">{{ giftcardInformation.Amount }} {{ currency }}</dd>
            </dl>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</ng-template>

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LogService } from '@com/logging';
import {
  ApplicationStateService,
  BasketQueryParams,
  HotelCodeString,
  HotelService,
  HotelType,
  Loader,
  LocaleService,
} from '../../../core';
import { ConferenceRoom } from '../../../core/modules/hotel/data-hotel.service';
import { DateHelper } from '../../../helpers';
import { NavigationHelper } from '../../../helpers/navigation-helper';
import { Messenger } from '../../../modules/messenger';
import { DesignConfiguration } from '../../../shared/design/design-configuration';

@Component({
  selector: 'app-conference-search',
  templateUrl: './conference-search.component.html',
})
export class ConferenceSearchComponent implements OnInit, OnDestroy {
  @ViewChild('hotelChange', { static: false }) hotelChange: TemplateRef<ConferenceHotelPicker> | undefined;
  @ViewChild('personDropdown', { static: false }) personDropdown: TemplateRef<GuestPicker> | undefined;
  @ViewChild('conferenceCalendar', { static: false }) conferenceCalendar: TemplateRef<GuestPicker> | undefined;

  hotel: HotelType | undefined;
  hotels = [] as HotelType[];
  logo = '';
  showCalendar = false;
  calendarDates: CalendarModel | undefined;
  currentDate = new Date();
  startDate: Date | undefined;
  startMonth: string | undefined;
  endDate: Date | undefined;
  endMonth: string | undefined;
  guestCountValues = [] as number[];
  selectedGuestCount = 1;
  conferenceRooms: ConferenceRoom[] | undefined;

  constructor(
    private log: LogService,
    private applicationState: ApplicationStateService,
    private messenger: Messenger,
    private navigationHelper: NavigationHelper,
    private loader: Loader,
    private hotelService: HotelService,
    private localeSerive: LocaleService,
    private designconfig: DesignConfiguration,
  ) {}

  async ngOnInit() {
    return await this.loader.using(
      async () => {
        await this.canUseModule();

        await this.buildCalendarModel();

        this.buildAQCArray();

        await this.prepareNavigation();

        await this.designconfig.addPageBackground();

        this.hotels = await this.loadHotels();

        this.hotel = this.hotels.find((x) => x.Code === this.applicationState.CurrentHotelCode);

        this.conferenceRooms = await this.hotelService.GetECommerceConferenceRoomsInfo(
          this.applicationState.CurrentHotelCode,
          await this.localeSerive.getLocale(),
        );

        this.logo = this.applicationState.ApplicationLogo;
      },
      'LOA_HotelInformation',
      false,
    );
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  buildAQCArray() {
    const number = Number(this.applicationState.MaxAQC);
    let i = 0;

    while (i <= number) {
      this.guestCountValues.push(i);
      i++;
    }

    this.guestCountValues.splice(0, 1);
  }

  async prepareNavigation() {
    // await this.navigationHelper.continueMobileNavigationbar(false, true, '/guests', '/products', { queryParams: { HotelCode: this.applicationState.CurrentHotelCode, ArrivalDate: DateHelper.toServerDateFormatString(new Date()), LengthOfStay: 30, guest: this.guestCounter } });
  }

  async nextMonth() {
    this.currentDate = DateHelper.addMonth(this.currentDate, 2);
    await this.buildCalendarModel();
  }

  async prevMonth() {
    this.currentDate = DateHelper.addMonth(this.currentDate, 0);
    await this.buildCalendarModel();
  }

  async loadHotels() {
    return await this.loader.using(
      async () => {
        try {
          return await this.hotelService.GetECommerceDepartmentsInformation();
        } catch (err: any) {
          this.log.error(`SearchRoute failed to load hotels. ${err.message}`);
          throw err;
        }
      },
      'LOA_HotelInformation',
      false,
    );
  }

  async buildCalendarModel() {
    const lastDay = DateHelper.getLastDateOfMonth(this.currentDate);
    const firstDay = DateHelper.getFirstDateOfMonth(this.currentDate);

    let dayCounter = 0;
    const model = {} as CalendarModel;
    model.days = [] as Day[];
    model.currentMonth = DateHelper.getTranslatedMonth(firstDay.getMonth());
    model.currentYear = firstDay.getFullYear();

    let dummyDaysAdded = false;

    while (dayCounter < lastDay.getDate()) {
      const day = {} as Day;

      day.date = DateHelper.addDays(firstDay, dayCounter);
      day.status = this.calculateStatus(day.date, new Date());
      day.active = day.status === dayStatus.open ? true : false;

      // Days before current monthdays (grayed out)
      if (!dummyDaysAdded) {
        let weekDay = day.date.getDay();
        let daysToSubract = this.calculateDaysToSubstract(weekDay);
        const days = this.daysToReach(weekDay);

        while (weekDay < days) {
          const dummyday = {} as Day;

          dummyday.date = DateHelper.addDays(firstDay, daysToSubract);
          dummyday.active = false;
          dummyday.status = dayStatus.invalid;

          model.days.push(dummyday);

          weekDay = weekDay + 1;
          daysToSubract = daysToSubract + 1;
        }
        dummyDaysAdded = true;
      }
      model.days.push(day);
      dayCounter = dayCounter + 1;
    }

    this.calendarDates = model;
  }

  calculateDaysToSubstract(weekDay: number) {
    switch (weekDay) {
      case 0: {
        return -6;
      }
      case 1: {
        return -5;
      }
      case 2: {
        return -1;
      }
      case 3: {
        return -3;
      }
      case 4: {
        return -3;
      }
      case 5: {
        return -4;
      }
      case 6: {
        return -7;
      }
      default: {
        return 0;
      }
    }
  }

  daysToReach(weekDay: number) {
    switch (weekDay) {
      case 0: {
        return 6;
      }
      case 1: {
        return 1;
      }
      case 2: {
        return 3;
      }
      case 3: {
        return 5;
      }
      case 4: {
        return 7;
      }
      case 5: {
        return 9;
      }
      case 6: {
        return 11;
      }
      default: {
        return 0;
      }
    }
  }

  calculateStatus(date: Date, currentMonth: Date) {
    if (currentMonth.getMonth() > date.getMonth() && currentMonth.getFullYear() === date.getFullYear()) {
      return dayStatus.runOut;
    } else if (currentMonth.getMonth() === date.getMonth() && date.getDate() < new Date().getDate()) {
      return dayStatus.invalid;
    }
    return dayStatus.open;
  }

  async dateClick() {
    if (this.showCalendar) {
      this.showCalendar = false;
    } else if (!this.showCalendar) {
      this.startDate = undefined;
      this.endDate = undefined;
      await this.buildCalendarModel();
      this.showCalendar = true;
    }
  }

  async onHotelChange() {
    if (this.hotelChange) {
      const message = this.messenger.show(
        this.hotelChange,
        {
          name: '',
          accept: async (hotelCode: HotelCodeString) => {
            await this.applyHotelChange(hotelCode);
            this.applicationState.stopScrolling(false);
            this.messenger.close(message);
          },
          close: () => {
            this.applicationState.stopScrolling(false);
            this.messenger.close(message);
          },
        },
        () => {
          this.applicationState.stopScrolling(false);
          message.data.close();
        },
      );
    }
  }

  onGuestChange() {
    if (this.personDropdown) {
      const message = this.messenger.show(
        this.personDropdown,
        {
          count: this.guestCountValues,
          accept: async (guestCount: number) => {
            this.selectedGuestCount = guestCount;
            this.applicationState.stopScrolling(false);
            this.messenger.close(message);
          },
          close: () => {
            this.applicationState.stopScrolling(false);
            this.messenger.close(message);
          },
        },
        () => {
          this.applicationState.stopScrolling(false);
          message.data.close();
        },
      );
    }
  }

  async applyHotelChange(hotelCode: HotelCodeString) {
    this.applicationState.CurrentHotelCode = hotelCode;

    this.hotel = this.hotels.find((x) => x.Code === this.applicationState.CurrentHotelCode);
    await this.designconfig.applyTheme();
    await this.designconfig.addPageBackground();
    await this.designconfig.applyHotelChange(hotelCode);
    this.logo = this.applicationState.ApplicationLogo;
    this.conferenceRooms = await this.hotelService.GetECommerceConferenceRoomsInfo(
      this.applicationState.CurrentHotelCode,
      await this.localeSerive.getLocale(),
    );
  }

  async canUseModule() {
    await this.hotelService.BuildApplicationState();

    if (!this.applicationState.UseConferenceModule) {
      await this.navigationHelper.navigateToPage('/landing-page');
    }
  }

  async onFormSubmit() {
    await this.navigationHelper.continue('conference-rooms', 'conference-search');
  }

  resetAllSelectedDates() {
    if (this.calendarDates) {
      this.calendarDates.days.forEach((x) => {
        x.selected = false;
      });
    }
  }

  async clickedDate(day: Day) {
    day.selected = true;
    day.status = this.applicationState.ApplicationColor as dayStatus;
    if (this.startDate) {
      this.endDate = day.date;
      this.showCalendar = false;
      this.endMonth = DateHelper.getTranslatedMonth(day.date.getMonth());
    } else {
      this.startDate = day.date;
      this.startMonth = DateHelper.getTranslatedMonth(day.date.getMonth());
    }

    if (day.date < this.startDate) {
      this.resetAllSelectedDates();
      this.startDate = day.date;
    }

    if (day.date > this.startDate) {
      const daysBetween = DateHelper.daysBetween(this.startDate, day.date, 0);
      if (this.calendarDates) {
        let counter = 1;
        while (counter < daysBetween) {
          const date = DateHelper.addDays(this.startDate, counter);
          const dateToMark = this.calendarDates.days.find(
            (x) => x.date.getDate() === date.getDate() && x.date.getMonth() === date.getMonth(),
          );

          if (dateToMark) {
            dateToMark.selected = true;
            dateToMark.status = this.applicationState.ApplicationColor as dayStatus;
          }
          counter = counter + 1;
        }
      }
    }
  }

  async conferenceRoomClick(conferenceRoomCode: string) {
    await this.navigationHelper.continue('conference-search', 'conference-details', {
      queryParams: {
        HotelCode: this.applicationState.CurrentHotelCode,
        ConferenceRoomCode: conferenceRoomCode,
      },
    });
  }

  static ParseConferenceRoomCode(params: Partial<BasketQueryParams>) {
    const hotelCode = params.ConferenceRoomCode;
    return hotelCode;
  }
}

enum dayStatus {
  closed = '#F87E7D',
  open = 'white',
  invalid = '#a9a9a9',
  runOut = '#707070',
}

export interface CalendarModel {
  days: Day[];
  currentMonth: string;
  currentYear: number;
}

export interface Day {
  date: Date;
  active: boolean;
  status: dayStatus;
  selected: boolean;
}

interface ConferenceHotelPicker {
  name: string;
  accept: (hotelCode: HotelCodeString) => void;
  close: () => void;
}

interface GuestPicker {
  count: number[];
  accept: (guestCount: number) => void;
  close: () => void;
}

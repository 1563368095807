import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[appMaxHeight]',
})
export class MaxHeightDirective implements AfterViewInit {
  @Output() isMaxHeight = new BehaviorSubject(false);
  @Input() maxHeight = 200;

  constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.el.nativeElement) {
      const { height } = this.el.nativeElement.getBoundingClientRect();
      this.isMaxHeight.next(height > 200);
      this.cdRef.detectChanges();
    }
  }
}

<ng-template [ngIf]="messages$ | async" let-messages>
  <div *ngIf="messages.length > 0" class="messenger">
    <ng-template ngFor [ngForOf]="messages" let-message>
      <div class="overlay" (click)="onOverlayClick(message)"></div>
      <div [id]="message.customId" class="message">
        <ng-container *ngTemplateOutlet="message.template; context: { $implicit: message.data }"></ng-container>
      </div>
    </ng-template>
  </div>
</ng-template>

<div class="calendar-input" [ngClass]="{ show: show }">
  <div class="overlay" (click)="onOverlayClick()"></div>
  <div class="calendar-input-item with-toggle" [ngClass]="{ active: show }" (click)="onInputClick()">
    <div class="form-control" tabindex="0" (keyup)="$event.keyCode === 32 && onInputClick()">
      {{ (value | date | async) || placeholder }}
    </div>
    <i class="toggle fas fa-caret-down"></i>
  </div>
  <div class="calendar-input-calendar">
    <app-calendar
      [config]="{
        showMonths: showMonths,
        start: start,
        month: month,
        selected: selected,
        min: min,
        max: max,
        date: value,
        full: full,
        showLegend: true,
        available: available,
        stay: stay
      }"
      [loadingdData]="loadingdData"
      [isArrivalDate]="isArrivalDate"
      (dateChange)="onCalendarDateChange($event)"
      (monthChange)="onMonthClick($event)"></app-calendar>
  </div>
</div>

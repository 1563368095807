import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CountryService } from './country.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [CountryService],
  exports: [],
})
export class CountryModule {
  constructor(@Optional() @SkipSelf() parentModule: CountryModule) {
    if (parentModule) {
      throw new Error('CountryModule is already loaded. Import it in the CoreModule only');
    }
  }
}

<ng-template [ngIf]="data$ | async" let-data>
  <div id="basketBox" class="basket-message zoomTransformAnimation mobileBasket">
    <h2 style="margin-left: 10px; margin-top: 10px; margin-bottom: 10px; color: var(--color-dynamic)">
      {{ 'BIN_Reservation' | t | async }}
    </h2>
    <i
      style="float: right; position: absolute; top: 12px; right: 10px; color: red"
      class="fas fa-times"
      (click)="hideBasketClick()"></i>
    <hr class="applicationColor" />
    <div style="display: inline-flex">
      <h4 style="margin-left: 10px; margin-top: 10px">
        {{ 'BIN_Details' | t | async }}
      </h4>
      <a
        (click)="backToStartClicked()"
        routerLink="/search"
        [queryParams]="data.queryParams"
        [title]="'BIN_ChangeDate' | t | async"
        style="margin-top: 10px; margin-left: 10px; border-radius: 20px; text-align: center"
        class="grow">
        <i style="font-size: 15px; color: var(--color-dynamic)" class="fas fa-pencil-alt"></i>
      </a>
    </div>
    <div class="row" style="margin-top: 10px; margin-bottom: 10px">
      <div *ngIf="state.HotelBasketInfo !== undefined" class="col md 6" style="font-size: 12px; margin-left: 10px">
        <p class="wrap-text">{{ state.HotelBasketInfo.Name }}</p>
        <p>{{ state.HotelBasketInfo.Address }}</p>
        <p>
          {{ state.HotelBasketInfo.PostalCode }}
          {{ state.HotelBasketInfo.City }}
        </p>
      </div>
      <div class="col md 6" style="font-size: 12px" *ngIf="showDate || hasRooms">
        <p class="cut-text">
          {{ 'COM_Arrival' | t | async }}: {{ data.params.ArrivalDate }}.
          {{ data.params.ArrivalMonthName | t | async }}
          {{ data.params.ArrivalFullYear }}
        </p>
        <p class="cut-text">
          {{ 'COM_Departure' | t | async }}: {{ data.params.DepartueDate }}.
          {{ data.params.DepartueMonthName | t | async }}
          {{ data.params.DepartueFullYear }}
        </p>
        <p>{{ state.RoomsLabel | t | async }}: {{ data.params.rooms.length }}</p>
      </div>
    </div>
    <hr />

    <div *ngIf="data.bookings.length !== 0 && hasRooms">
      <h4 style="margin-left: 10px; margin-top: 10px">
        {{ state.RoomLabel | t | async }}/{{ 'COM_Package' | t | async }}
      </h4>
      <div *ngFor="let booking of data.bookings; let index = index">
        <div *ngIf="booking.RoomName !== ''">
          <div class="row" style="margin-top: 10px; margin-bottom: 10px">
            <div class="col md 12" style="font-size: 12px; margin-left: 10px">
              <div style="display: inline-flex; margin-top: 10px">
                <p style="font-weight: bold">{{ state.RoomLabel | t | async }} {{ index | char }}</p>
                <div *ngIf="state.BookingFlow === '1'" class="col md 1">
                  <a
                    [routerLink]="['/room', index + 1]"
                    [title]="'BIN_ChangeRoom' | t | async"
                    style="border-radius: 20px; text-align: center"
                    class="grow"
                    (click)="hideBasketClick()">
                    <i style="font-size: 15px; color: var(--color-dynamic)" class="fas fa-pencil-alt"></i>
                  </a>
                </div>
                <div *ngIf="state.BookingFlow === '2'" class="col md 1">
                  <a
                    [routerLink]="['/ratesRoom', index + 1]"
                    [title]="'BIN_ChangeRoom' | t | async"
                    style="border-radius: 20px; text-align: center"
                    class="grow"
                    (click)="hideBasketClick()">
                    <i style="font-size: 15px; color: var(--color-dynamic)" class="fas fa-pencil-alt"></i>
                  </a>
                </div>
              </div>
              <p>{{ state.RoomTypeLabel | t | async }}: {{ booking.RoomName }}</p>
              <p>
                {{ 'BIN_Packagetype' | t | async }}:
                <span [innerHTML]="booking.RateName"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>

    <div *ngIf="data.bookings.length !== 0 && hasSeating">
      <div *ngFor="let booking of data.bookings; let index = index">
        <div *ngIf="booking.Seatings">
          <h4 style="margin-left: 10px; margin-top: 10px">
            {{ state.EventLabel | t | async }}
          </h4>
          <div *ngFor="let seat of booking.Seatings">
            <div class="row" style="margin-top: 10px; margin-bottom: 10px">
              <div class="col md 11" style="font-size: 12px; margin-left: 10px">
                <div style="display: inline-flex; margin-top: 10px">
                  <p style="font-weight: bold">
                    {{ seat.day }}. {{ seat.month | t | async }} {{ seat.year }} - {{ seat.Time }}
                  </p>
                  <div class="basket-information-button col md 1">
                    <a
                      *ngIf="seat.type !== 'image.png'; else seatingStandalone"
                      [routerLink]="['/seating', index + 1]"
                      [title]="'BIN_ChangeRoom' | t | async"
                      style="border-radius: 20px; text-align: center"
                      class="grow"
                      (click)="hideBasketClick()">
                      <i style="font-size: 15px; color: var(--color-dynamic)" class="fas fa-pencil-alt"></i>
                    </a>
                    <ng-template #seatingStandalone>
                      <a
                        *ngIf="seat.type === 'SeatingStandalone'"
                        [routerLink]="[seat.returnUrl.url]"
                        [queryParams]="seat.returnUrl.queryParams"
                        [title]="'BIN_ChangeRoom' | t | async"
                        style="border-radius: 20px; text-align: center"
                        class="grow"
                        (click)="hideBasketClick()">
                        <i style="font-size: 15px; color: var(--color-dynamic)" class="fas fa-pencil-alt"></i>
                      </a>
                    </ng-template>
                  </div>
                </div>
                <p>{{ seat.TicketTotal }}x {{ 'SFO_BookEvent' | t | async }}: {{ seat.Name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>

    <div *ngIf="data.bookings.length !== 0 && hasAddons">
      <h4 style="margin-left: 10px; margin-top: 10px">
        {{ 'APA_Services' | t | async }}
      </h4>
      <ng-template ngFor let-booking [ngForOf]="data.bookings" let-index="index">
        <div class="row" style="margin-top: 10px; margin-bottom: 10px">
          <div class="col md 12" style="font-size: 12px; margin-left: 10px">
            <div style="display: inline-flex; margin-top: 10px">
              <p style="font-weight: bold">{{ state.RoomLabel | t | async }} {{ index | char }}</p>
              <a
                [routerLink]="['/add-on', index + 1]"
                [title]="'BIN_ChangeRoom' | t | async"
                style="margin-left: 10px; border-radius: 20px; text-align: center"
                class="grow"
                (click)="hideBasketClick()">
                <i style="font-size: 15px; color: var(--color-dynamic)" class="fas fa-pencil-alt"></i>
              </a>
            </div>
            <div *ngFor="let addon of booking.AddOns">
              <p>- {{ addon.Name }}</p>
            </div>
          </div>
        </div>
      </ng-template>
      <hr />
    </div>

    <div *ngIf="data.bookings.length !== 0 && hasTableReservation">
      <div *ngFor="let booking of data.bookings; let index = index">
        <div *ngIf="booking.TableSeatings">
          <h4 style="margin-left: 10px; margin-top: 10px">
            {{ 'SFO_BookTableReservation' | t | async }}
          </h4>
          <div *ngFor="let table of booking.TableSeatings">
            <div class="row" style="margin-top: 10px; margin-bottom: 10px">
              <div class="col md 11" style="font-size: 12px; margin-left: 10px">
                <div style="display: inline-flex; margin-top: 10px">
                  <p style="font-weight: bold">
                    {{ table.Day }}. {{ table.translatedMonth | t | async }} {{ table.Year }} - {{ table.Time }}
                  </p>
                  <div class="basket-information-button col md 1">
                    <a
                      [routerLink]="['/products']"
                      [title]="'BIN_ChangeRoom' | t | async"
                      style="border-radius: 20px; text-align: center"
                      class="grow"
                      (click)="hideBasketClick()">
                      <i style="font-size: 15px; color: var(--color-dynamic)" class="fas fa-pencil-alt"></i>
                    </a>
                  </div>
                </div>
                <p>{{ table.Name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="data.bookings.length !== 0 && hasGiftCard">
      <div *ngFor="let booking of data.bookings; let index = index">
        <div *ngIf="booking.Giftcards">
          <h4 style="margin-left: 10px; margin-top: 10px">
            {{ 'SFO_BookGiftcard' | t | async }}
          </h4>
          <div *ngFor="let card of booking.Giftcards">
            <div class="row" style="margin-top: 10px; margin-bottom: 10px">
              <div class="col md 11" style="font-size: 12px; margin-left: 10px">
                <p>{{ card.Name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!state.RemoveCheckoutButton && showCheckOut">
      <div class="desktop-show mobile-hide" style="padding: 5px">
        <button class="dynamicTextColor checkoutButton" (click)="CheckOut()">
          <span>{{ 'BIN_BasketGoToCheckout' | t | async }}</span>
        </button>
      </div>
      <div class="desktop-hide mobile-show" style="padding: 5px; margin-bottom: 10%">
        <button class="dynamicTextColor checkoutButton" (click)="CheckOut()">
          <span>{{ 'BIN_BasketGoToCheckout' | t | async }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div id="basketBox" class="basket-message zoomTransformAnimation">
  <h2 style="margin-left: 10px; margin-top: 10px; margin-bottom: 10px; color: var(--color-dynamic)">
    {{ 'BIN_Reservation' | t | async }}
  </h2>
  <i
    style="float: right; position: absolute; top: 12px; right: 10px; color: red"
    class="fas fa-times"
    (click)="hideBasketClick()"></i>
  <hr class="applicationColor" />
  <div style="text-align: center; height: 100px">
    <h4 style="margin-left: 10px; margin-top: 60px">
      {{ 'BIN_BasketEmpty' | t | async }}
    </h4>
  </div>
</div>

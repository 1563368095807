import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';

import { AppErrorHandler } from 'src/app/core/app-error.handler';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { LoaderModule } from 'src/app/modules/loader';
import { MessengerModule } from 'src/app/modules/messenger';
import { StepListModule } from 'src/app/modules/step-list';

import { AppComponent } from './app.component';
import { AddOnCardModalComponent } from './pages/add-on/add-on-card-modal.component';
import { AddOnCardComponent } from './pages/add-on/add-on-card.component';
import { AddOnComponent } from './pages/add-on/add-on.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { PaymentFailedComponent } from './pages/payment-failed/payment-failed.component';
import { PaymentLinkComponent } from './pages/payment-link/payment-link.component';
import { AcceptPaymentComponent } from './pages/payment/AcceptPayment.component';
import { FlexWinPaymentComponent } from './pages/payment/flex-win-payment.component';
import { RatesRoomComponent } from './pages/ratesRoom/ratesRoom.component';
import { RecieptComponent } from './pages/reciept/reciept.component';
import { RoomComponent } from './pages/room/room.component';
import { SearchFormComponent } from './pages/search/search-form.component';
import { SearchComponent } from './pages/search/search.component';
import { SeatingComponent } from './pages/seating/seating.component';
import { SeatingStandaloneComponent } from './pages/seating-standalone/seating-standalone.component';
import { SeatingStandaloneCardComponent } from './pages/seating-standalone/seating-standalone-card.component';
import { SeatingStandalonePageComponent } from './pages/seating-standalone/seating/seating-standalone-page.component';

import { GiftcardFormComponent } from './components/giftcard-form/giftcard-form.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { PromoFormComponent } from './components/promo-form/promo-form.component';
import { ApplicationStateService } from './core';
import { NavigationHelper } from './helpers/navigation-helper';
import { ConferenceDetailsComponent } from './pages/conference/conference-details/conference-details.component';
import { ConferenceRoomsComponent } from './pages/conference/conference-rooms/conference-rooms.component';
import { ConferenceSearchComponent } from './pages/conference/conference-search/conference-search.component';
import { GiftcardDetailsComponent } from './pages/giftcard/details/giftcarddetails.component';
import { GiftcardComponent } from './pages/giftcard/products/giftcard.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { CalendarGuidanceComponent } from './pages/search/calendar-guidance/calendar-guidance.component';
import { GuestsComponent } from './pages/table-reservation/guests/guests.component';
import { CalendarTileComponent } from './pages/table-reservation/products/calendar-tile.component';
import { ProductsComponent } from './pages/table-reservation/products/products.component';
import { BasketComponent } from './shared/components/basket/basket.component';
import { DesignConfiguration } from './shared/design/design-configuration';
import { MatDatepickerIntl } from '../i18n/material/DatePickeri18n';
import { SeatingDateCalendarComponent } from './pages/seating-standalone/seating-date-calendar.component';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    SearchFormComponent,
    RoomComponent,
    SeatingComponent,
    SeatingStandaloneComponent,
    SeatingStandaloneCardComponent,
    SeatingDateCalendarComponent,
    SeatingStandalonePageComponent,
    AddOnComponent,
    AddOnCardComponent,
    CustomerComponent,
    FlexWinPaymentComponent,
    PaymentFailedComponent,
    RecieptComponent,
    GiftcardFormComponent,
    LoginFormComponent,
    PromoFormComponent,
    PaymentLinkComponent,
    AcceptPaymentComponent,
    RatesRoomComponent,
    BasketComponent,
    LandingPageComponent,
    GiftcardComponent,
    GiftcardDetailsComponent,
    GuestsComponent,
    ProductsComponent,
    CalendarTileComponent,
    ConferenceSearchComponent,
    ConferenceRoomsComponent,
    ConferenceDetailsComponent,
    CalendarGuidanceComponent,
    AddOnCardModalComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    LoaderModule,
    StepListModule,
    MessengerModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    RouterModule.forRoot(
      [
        { path: 'search', component: SearchComponent },
        { path: 'landing-page', component: LandingPageComponent },
        { path: 'giftcard', component: GiftcardComponent },
        { path: 'products', component: ProductsComponent },
        { path: 'guests', component: GuestsComponent },
        { path: 'details', component: GiftcardDetailsComponent },
        { path: 'room/:index', component: RoomComponent },
        { path: 'ratesRoom/:index', component: RatesRoomComponent },
        { path: 'seating-standalone', component: SeatingStandaloneComponent },
        { path: 'seating-standalone/:characteristics', component: SeatingStandalonePageComponent },
        { path: 'seating/:index', component: SeatingComponent },
        { path: 'seating/:month/:seatingPlanId', component: SeatingComponent },
        { path: 'add-on', component: AddOnComponent },
        { path: 'add-on/:index', component: AddOnComponent },
        { path: 'customer', component: CustomerComponent },
        { path: 'payment', component: FlexWinPaymentComponent },
        { path: 'payment-failed', component: PaymentFailedComponent },
        { path: 'reciept', component: RecieptComponent },
        {
          path: 'payment-link/:reservationId/:language',
          component: PaymentLinkComponent,
        },
        {
          path: 'payment-link/:reservationId',
          component: PaymentLinkComponent,
        },
        { path: 'AcceptPayment/:type', component: AcceptPaymentComponent },
        {
          path: 'acceptpayment/:type/:orderid',
          component: AcceptPaymentComponent,
        },
        { path: 'conference-search', component: ConferenceSearchComponent },
        { path: 'conference-rooms', component: ConferenceRoomsComponent },
        { path: 'conference-details', component: ConferenceDetailsComponent },
        { path: 'calendar-guidance', component: CalendarGuidanceComponent },
        { path: '**', redirectTo: 'landing-page' },
      ],
      { relativeLinkResolution: 'legacy' },
    ),
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    ApplicationStateService,
    DesignConfiguration,
    NavigationHelper,
    DatePipe,
    { provide: MatDatepickerModule, useClass: MatDatepickerIntl },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

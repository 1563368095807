<ng-template [ngIf]="reservation" let-reservation>
  <div>
    <div
      class="page-content paymentlink-page wireframe2 fadeBody"
      style="background-color: white; padding: 5px; margin-left: 15%; margin-right: 15%">
      <div class="container" *ngIf="!paymentComplete">
        <div class="section">
          <div class="row">
            <div class="col l6">
              <h2>
                <i class="fas fa-user"></i>
                <span class="v-middle">{{ 'CUP_Header' | t | async }}</span>
              </h2>
              <div class="content-section">
                <br />
                <div class="row">
                  <div class="col l4 s12">
                    <dl>
                      <dd>{{ reservation.bookingAdmin.lastName }}</dd>
                      <dd></dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div class="col l6">
              <h2>
                <i class="fas fa-id-card-alt"></i>
                <span class="v-middle">{{ 'REP_ReservationId' | t | async }}</span>
              </h2>
              <div class="content-section">
                <span style="background-color: #eee; padding: 20px; margin: 20px 0; display: inline-block">{{
                  reservation.reservationId
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <div class="row">
            <div class="col l12">
              <giftcard-form
                (giftcardInformationEvent)="setGiftcardInformationModel($event)"
                [currency]="currency"></giftcard-form>
            </div>
          </div>
        </div>

        <div class="section">
          <br />
        </div>

        <div class="section">
          <div class="row">
            <div class="col l6">
              <h2>
                <i class="fas fa-credit-card"></i>
                <span class="v-middle">{{ 'COM_TotalPrice' | t | async }}</span>
              </h2>
              <div class="content-section">
                <br />
                <div class="row">
                  <div class="col l4 s12">
                    <dl>
                      <dd class="text-primary" *ngIf="!giftcardInformation">
                        {{ reservation.PaymentDue }} {{ currency }}
                      </dd>
                      <dd class="text-primary" *ngIf="giftcardInformation">
                        {{ math.max(0, reservation.PaymentDue - giftcardInformation.Amount) }} {{ currency }}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div class="col l6" *ngIf="!paymentNotAvailable">
              <form #f="ngForm" (ngSubmit)="onFormSubmit(f)">
                <div class="row">
                  <div class="col s12">
                    <app-checkbox [checked]="acceptTerms" label="Accept terms" (changed)="onAcceptTermsChange($event)">
                      <label>
                        <a href="#" (click)="onShowTermsClick(hotel?.Terms, $event)" class="text-underline">{{
                          'CUP_AcceptTerms' | t | async
                        }}</a>
                      </label>
                    </app-checkbox>

                    <div class="text-center2">
                      <button
                        *ngIf="acceptTerms; else disabledButton"
                        type="submit"
                        class="btn btn-primary with-shadow">
                        {{ 'PAL_GoToPayment' | t | async }}
                      </button>
                      <ng-template #disabledButton>
                        <a class="btn btn-primary disabled with-shadow">{{ 'PAL_GoToPayment' | t | async }}</a>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col l6" *ngIf="paymentNotAvailable">
              <div>
                <p style="text-transform: uppercase">
                  {{ 'PAL_PaymentNotAvailable' | t | async }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <br />
          <hr />
          <br />
        </div>

        <div class="section" *ngIf="reservation.rooms[0]">
          <h2>
            <i class="fas fa-calendar-alt"></i>
            <span class="v-middle">{{ 'REP_DateRooms' | t | async }}</span>
          </h2>
          <div class="content-section">
            <br />
            <div *ngIf="hasRooms" class="row">
              <div class="col l4 s12">
                <dl>
                  <dt>{{ 'COM_Arrival' | t | async }}</dt>
                  <dd>{{ reservation.rooms[0].arrival | date | async }}</dd>
                </dl>
              </div>
              <div class="col l4 s12">
                <dl>
                  <dt>{{ 'COM_Departure' | t | async }}</dt>
                  <dd>{{ reservation.rooms[0].departure | date | async }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div class="section" *ngIf="hasRooms">
          <h2>
            <i class="fas fa-bed"></i>
            <span class="v-middle">{{ 'COM_Rooms' | t | async }}</span>
          </h2>
        </div>

        <div *ngIf="hasRooms">
          <div *ngFor="let room of reservation.rooms; let index = index">
            <div class="section">
              <div class="content-section">
                <div>
                  <div class="row">
                    <div class="col l6 s12">
                      <dl>
                        <dt>
                          {{ 'COM_Room' | t | async }} <b>{{ index | char }}</b>
                        </dt>
                        <dd>{{ room.roomCode }}</dd>
                      </dl>
                    </div>
                    <div class="col l6 s12 text-right">
                      <dl>
                        <dt>{{ 'COM_Price' | t | async }}</dt>
                        <dd class="text-primary">{{ room.price }} {{ currency }}</dd>
                      </dl>
                    </div>
                  </div>
                  <div class="row" *ngIf="showRoomName">
                    <div class="col l12">
                      <dl>
                        <dt>{{ 'REP_Name' | t | async }}</dt>
                        <dd>{{ room.guestName }}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section" *ngIf="showServiceName && hasExtraservices">
          <h2>
            <i class="fas fa-gift"></i>
            <span class="v-middle">{{ 'APA_Services' | t | async }}</span>
          </h2>
        </div>

        <div class="section" *ngIf="reservation.rooms[0]">
          <div *ngFor="let room of reservation.rooms; let index = index">
            <div *ngFor="let service of room.services">
              <div class="content-section" *ngIf="service.serviceType === 'EXTRA'">
                <div>
                  <div class="row">
                    <div class="col l6 s12">
                      <dl>
                        <dd>{{ service.count }} x {{ service.serviceCode }}</dd>
                      </dl>
                    </div>
                    <div class="col l6 s12 text-right">
                      <dl>
                        <dt>{{ 'COM_Price' | t | async }}</dt>
                        <dd class="text-primary">{{ service.price }} {{ currency }}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section" *ngIf="!reservation.rooms[0]">
          <div *ngFor="let service of reservation.services; let index = index">
            <div class="content-section" *ngIf="service.serviceType === 'EXTRA'">
              <div>
                <div class="row">
                  <div class="col l6 s12">
                    <dl>
                      <dd>{{ service.count }} x {{ service.serviceCode }}</dd>
                    </dl>
                  </div>
                  <div class="col l6 s12 text-right">
                    <dl>
                      <dt>{{ 'COM_Price' | t | async }}</dt>
                      <dd class="text-primary">{{ service.price }} {{ currency }}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section" *ngIf="showServiceName && hasSeatings">
          <h2>
            <i class="fas fa-glass-cheers"></i>
            <span class="v-middle">{{ 'SFO_BookEvent' | t | async }}</span>
          </h2>
        </div>

        <div class="section" *ngIf="reservation.rooms[0]">
          <div *ngFor="let room of reservation.rooms; let index = index">
            <div *ngFor="let service of room.services">
              <div class="content-section" *ngIf="service.serviceType === 'SEATING'">
                <div>
                  <div class="row">
                    <div class="col l6 s12">
                      <dl>
                        <dd>
                          {{ service.count }} x {{ service.serviceCode }} -
                          {{ service.ServiceDate }}
                        </dd>
                      </dl>
                    </div>
                    <div class="col l6 s12 text-right">
                      <dl>
                        <dt>{{ 'COM_Price' | t | async }}</dt>
                        <dd class="text-primary">{{ service.price }} {{ currency }}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section" *ngIf="!reservation.rooms[0]">
          <div *ngFor="let service of reservation.services; let index = index">
            <div class="content-section" *ngIf="service.serviceType === 'SEATING'">
              <div>
                <div class="row">
                  <div class="col l6 s12">
                    <dl>
                      <dd>{{ service.count }} x {{ service.serviceCode }}</dd>
                    </dl>
                  </div>
                  <div class="col l6 s12 text-right">
                    <dl>
                      <dt>{{ 'COM_Price' | t | async }}</dt>
                      <dd class="text-primary">{{ service.price }} {{ currency }}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section" *ngIf="reservation.cancellationFee > 0">
          <h2>
            <i class="fas fa-times-circle"></i>
            <span class="v-middle">{{ 'CUP_CancelPolicyHeader' | t | async }}</span>
          </h2>
          <br />
          <div class="content-section">
            <div class="row">
              <div class="col l6 s12">
                <dl>
                  <dt>{{ 'CUP_CancelPolicyHeader' | t | async }}</dt>
                </dl>
              </div>
              <div class="col l6 s12 text-right">
                <dl>
                  <dt>{{ 'COM_Price' | t | async }}</dt>
                  <dd class="text-primary">{{ reservation.cancellationFee }} {{ currency }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div class="section" *ngIf="giftcardInformation">
          <giftcard-form [giftcardInformation]="giftcardInformation" [currency]="currency"></giftcard-form>
        </div>

        <div class="section">
          <div class="content-section">
            <br />
            <div class="row">
              <div class="col right text-right">
                <dl>
                  <dt>
                    <b>{{ 'COM_TotalPrice' | t | async }}</b>
                  </dt>
                  <dd class="text-primary" *ngIf="!giftcardInformation">{{ reservation.PaymentDue }} {{ currency }}</dd>
                  <dd class="text-primary" *ngIf="giftcardInformation">
                    {{ math.max(0, reservation.PaymentDue - giftcardInformation.Amount) }} {{ currency }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <!--<div *ngIf="!paymentNotAvailable">
          <div class="section" *ngIf="reservation.rooms.length > 1 || reservation.services.length > 0">
            <br />
            <hr />
            <br />

            <div class="row">
              <div class="col l6">

              </div>
              <div class="col l6">
                <form #f="ngForm" (ngSubmit)="onFormSubmit(f)">
                  <div class="row">
                    <div class="col s12">
                      <app-checkbox [checked]="acceptTerms" label="Accept terms" (change)="onAcceptTermsChange($event)">
                        <label>
                          <a href="#" (click)="onShowTermsClick(hotel?.Terms, $event)" class="text-underline">{{'CUP_AcceptTerms'|t|async}}</a>
                        </label>
                      </app-checkbox>

                      <div class="text-center2">
                        <button *ngIf="acceptTerms; else disabledButton" type="submit" class="btn btn-primary with-shadow">{{'PAL_GoToPayment'|t|async}}</button>
                        <ng-template #disabledButton>
                          <a class="btn btn-primary disabled with-shadow">{{'PAL_GoToPayment'|t|async}}</a>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>-->
      </div>

      <div class="container" *ngIf="paymentComplete">
        <div class="section no-payment-required-container">
          <div class="row text-info">
            <div class="col l12 text-center">
              <div class="text">{{ 'PAL_PaymentComplete' | t | async }} {{ hotelName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #messageHotelTermsTempalte let-message>
  <div class="dialog-container">
    <div class="dialog-header" [ngStyle]="{ 'background-color': contentDialogHeader }">
      <img [src]="logo" class="dialog-logo" />
    </div>
    <div class="dialog">
      <div class="dialog-body">
        <div class="small" [innerHTML]="message.html"></div>
      </div>
      <div class="text-center dialog-footer">
        <div>
          <button
            style="margin-right: 10px; min-width: 248px"
            class="btn btn-primary-outline with-shadow mb-0"
            type="button"
            (click)="message.close()">
            {{ 'COM_Close' | t | async }}
          </button>
          <button
            style="min-width: 248px"
            class="default btn btn-primary with-shadow mb-0"
            type="button"
            (click)="message.accept()">
            {{ 'CUP_Accept' | t | async }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

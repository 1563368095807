import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LogService } from '@com/logging';
import {
  ApplicationStateService,
  BasketService,
  HotelCodeString,
  Loader,
  PromoCodeString,
  PromotionService,
} from 'src/app/core';

@Component({
  selector: 'app-promo-form',
  templateUrl: './promo-form.component.html',
})
export class PromoFormComponent implements OnInit {
  @Input() hotelCode: HotelCodeString | undefined;
  promo = this.promotionService.getPromocode() || '';
  error = '';
  @Output() formSubmit = new EventEmitter();
  @Output() closeForm = new EventEmitter();

  constructor(
    private promotionService: PromotionService,
    private loader: Loader,
    private log: LogService,
    private applicationState: ApplicationStateService,
    private basketService: BasketService,
  ) {}

  async ngOnInit() {
    if (this.promo !== '') {
      await this.promotionService.getPromotionStatus(
        this.applicationState.CurrentHotelCode,
        this.promo as PromoCodeString,
      );
    }
  }

  onPromoChange(promo: string) {
    this.promo = promo.toUpperCase();
  }

  onCloseClick() {
    this.closeForm.emit();
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.error = '';
    if (this.promo) {
      const promo = this.promo as PromoCodeString;
      const hotelCode = this.hotelCode;
      await this.loader.using(async () => {
        try {
          if (!hotelCode) {
            throw new Error('Hotel must be selected before entering promocode');
          }
          const res = await this.promotionService.getPromotionStatus(hotelCode, promo);
          if (res == null) {
            throw new TypeError('According to Spectra the promotioncode is invalid');
          }

          this.promotionService.promocode$.next(promo);
          this.formSubmit.emit();
        } catch (err) {
          this.error = (err as Error).message;
          this.log.info(`PromoForm promo failed with error: ${this.error}`);
        }
      }, 'LOA_ApplyingPromo');
    } else {
      this.promotionService.promocode$.next(undefined);
      this.formSubmit.emit();

      const basketContent = this.basketService.get();
      if (basketContent) {
        for (const item of basketContent.bookings) {
          item.Price = item.OriginalPrice;
        }
      }
    }
  }
}

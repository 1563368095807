<div
  *ngIf="addOnItem as addOn"
  class="card p-0 d-flex flex-column fadeBody grow"
  [ngClass]="{ 'active pb-0': inBasket }">
  <div class="d-flex flex-column flex-grow-1" (click)="onClick(addOnItem)">
    <div class="wrap" *ngIf="inBasket">
      <div class="ribbon">
        <i class="fas fa-shopping-basket"></i>
      </div>
    </div>
    <div class="flex-grow-1">
      <div class="card-image">
        <div class="centered-image">
          <img *ngIf="addOn.Image" [src]="addOn.Image" draggable="false" />
          <div
            *ngIf="!addOn.Image"
            style="width: 100%; background-color: gray; height: 100%; text-align: center; line-height: 220px">
            <div
              style="
                font-size: 100px;
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                margin-right: 70px;
              ">
              <span class="fa fa-camera" style="font-size: 100px; color: #999999"></span>
              <h2 style="margin-left: 70px; color: #999999">
                {{ 'RPA_Image' | t | async }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" style="margin-top: 20px">
        <h3 class="text-primary" [innerHTML]="addOn.Name" title="{{ addOn.Name }} x{{ addOn.Count }}"></h3>
        <div class="card-body-long-description-container">
          <div
            class="card-body-long-description"
            [innerHTML]="addOn.Long"
            appMaxHeight
            maxHeight="200"
            (isMaxHeight)="setMaxHeight($event)"></div>
          <div class="card-body-long-description-overlay" *ngIf="isMaxHeight"></div>
        </div>
      </div>
    </div>
    <div class="card-body pb-0">
      <hr *ngIf="addOn.Long" style="border-width: 2px" />
      <div class="row">
        <div class="col">
          <div class="form-text text-primary">{{ 'RPA_From' | t | async }} {{ addOnItem.Amount | price | async }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-attachment" *ngIf="inBasket">
    <p>
      {{ basketInfo.guestCount }} {{ 'APA_TotalInBasket' | t | async }} +{{ basketInfo.totalAmount | price | async }}
    </p>
    <p class="card-attachment-icon" (click)="removeAll(addOnItem)">
      <i class="fas fa-trash-alt"></i> {{ 'APA_Remove' | t | async }}
    </p>
  </div>
</div>

<ng-template #messageAddOnDialog let-message>
  <app-add-on-card-modal
    [message]="message"
    [totalAddOnInformation$]="totalAddOnInformation$"
    [total]="addOnItem.Amount"></app-add-on-card-modal>
</ng-template>

import { Component } from '@angular/core';
import { Loader } from './loader';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent {
  message$ = this.loader.message$;

  constructor(private loader: Loader) {}
}

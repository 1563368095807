import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LogService } from '@com/logging';
import dayjs from 'dayjs';
import { ConfigHandler, /*SpectraDate,*/ DateHelper } from 'src/app/helpers';
import { StayAvailabilityResponse } from '../../../core/modules/hotel/data-hotel.service';
import { CalendarComponent, CalendarDateChangeEvent } from '../calendar/calendar.component';

export interface CalendarInputConfig {
  start: Date | null;
  end: Date | null;
  placeholder: string;
  showMonths: number;
  min: Date | null;
  max: Date | null;
  value: Date | null;
  month: dayjs.Dayjs;
  full: boolean;
  available: StayAvailabilityResponse[] | null;
  stay: number | undefined;
}

export interface CalendarInputDateChangeEvent {
  date: Date | null;
  month: dayjs.Dayjs;
}

@Component({
  selector: 'app-calendar-input',
  templateUrl: './calendar-input.component.html',
})
export class CalendarInputComponent implements CalendarInputConfig {
  @Input()
  set config(config: Partial<CalendarInputConfig> | undefined) {
    if (config) {
      const changes = ConfigHandler.Apply(this, config);

      if (this.full && this.min && this.max) {
        const newMonth = DateHelper.asDayjs(this.min).startOf('month');
        if (!newMonth.isSame(this.month)) {
          this.month = newMonth;
          changes.month = true;
        }
        const newShowMonths = DateHelper.asDayjs(this.max).diff(this.month, 'month', false) + 2;
        if (newShowMonths !== this.showMonths) {
          this.showMonths = newShowMonths;
          changes.showMonths = true;
        }
      } else {
        if (!changes.month && changes.start) {
          this.month = this.start ? DateHelper.asDayjs(this.start).startOf('month') : dayjs().startOf('month');
        }
      }
      this.selected = CalendarInputComponent.BuildSelected(this.start, this.end);
    }
  }

  stay: number | undefined;
  show = false;
  start = null as Date | null;
  end = null as Date | null;
  selected = [] as Date[];
  placeholder = '';
  showMonths = 1;
  full = false;
  min = null as Date | null;
  max = null as Date | null;
  available: StayAvailabilityResponse[] | null = [];
  value = DateHelper.today() as Date | null;
  month = this.start ? DateHelper.asDayjs(this.start).startOf('month') : dayjs().startOf('month');
  @ViewChild(CalendarComponent, { static: false }) calendar: CalendarComponent | undefined;
  @Output() dateChange = new EventEmitter<CalendarInputDateChangeEvent>();
  @Output() monthChange = new EventEmitter<Date>();
  @Input() loadingdData: boolean | undefined;
  @Input() isArrivalDate: boolean | undefined;

  constructor(private log: LogService) {}

  showCalendar() {
    this.log.debug('CalendarInput show()');
    this.setShow(true);
  }

  closeCalendar() {
    this.log.debug('CalendarInput show()');
    this.setShow(false);
  }

  onInputClick() {
    this.log.debug('CalendarInput onInputClick()');
    this.setShow(!this.show);
  }

  onCalendarDateChange(e: CalendarDateChangeEvent) {
    this.log.debug(`CalendarInput onCalendarChange(${JSON.stringify(e)})`);
    this.show = false;
    this.config = { value: e.date, month: e.month };
    this.dateChange.emit({
      date: this.value,
      month: this.month,
    });
  }

  onMonthClick(month: Date) {
    this.monthChange.emit(month);
  }

  onOverlayClick() {
    this.log.debug('CalendarInput onOverlayClick()');
    this.show = false;
  }

  private setShow(show: boolean) {
    this.log.trace(`CalendarInput show(${show})`);
    this.show = show;
    setTimeout(() => {
      if (this.show && this.calendar) {
        this.calendar.scrollToMonth();
      }
    }, 100);
  }

  private static BuildSelected(start: Date | null, end: Date | null) {
    if (start) {
      if (end) {
        let days = 1;

        const res = new Date().getTimezoneOffset();
        // Check if departure is spanding from summertime to wintertime and back.
        if (end.getTimezoneOffset() !== res) {
          // Negative timezone
          if (res <= 0) {
            days = 0;
          }
          if (end.getMonth() === 3) {
            days = 1;
          }
          if (res > 0) {
            days = 1;
          }

          if (end.getTimezoneOffset() !== start.getTimezoneOffset()) {
            // Positive timezone
            if (res > 0 && res !== 180 && res !== 240) {
              days = 0;
            }
            const country = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (
              country === 'America/Port-au-Prince' ||
              country === 'America/Havana' ||
              country === 'America/Indianapolis' ||
              country === 'America/Grand_Turk' ||
              country === 'America/Halifax'
            ) {
              days = 0;
            }
          }
          // If arrival and departure is in the same timezone but current date is in another timezone. (summertime or wintertime)
          if (end.getTimezoneOffset() === start.getTimezoneOffset()) {
            // Negative timezone
            if (res <= 0) {
              days = 1;
            }
            if (end.getMonth() === 3) {
              days = 0;
            }
          }
        }

        const stay = DateHelper.daysBetween(start, end, days);

        return Array.from(Array(stay), (x, index) => DateHelper.addDays(start, index));
      }
      return [start];
    }
    return [];
  }
}

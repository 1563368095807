<div class="page-content room-page fadeBody">
  <div class="container">
    <a class="desktop-show mobile-hide applicationColor" style="position: absolute; font-size: 20px" (click)="goBack()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >
    <!--<a class="desktop-show mobile-hide applicationColor" *ngIf="returnFromBasket" (click)="onMoveToNextClick()" style="font-size:20px; float:right;">{{'COM_Continue'|t|async}}<i style="margin-left:10px;" class="fas fa-arrow-right"></i></a>
    <a class="desktop-show mobile-hide" *ngIf="!returnFromBasket" style="pointer-events:none; color:gray; font-size:20px; float:right;">{{'COM_Continue'|t|async}}<i style="margin-left:10px;" class="fas fa-arrow-right"></i></a>-->

    <a (click)="onMoveToNextClick()" class="desktop-show mobile-hide" style="font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>

    <p
      class="desktop-show mobile-hide"
      style="text-align: center; font-size: 40px; font-weight: 500; margin-left: 8.5%">
      Vælg lokale
    </p>
    <p class="desktop-hide mobile-show" style="text-align: center; font-size: 40px; font-weight: 500">Vælg lokale</p>

    <h2 style="text-align: center; margin-top: 20px">
      <span class="v-middle">Vælg herunder det lokale du kunne tænke dig til din konference</span>
    </h2>

    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />
  </div>

  <app-expandable-media-list *ngIf="data" [data]="data" [selected]="selectedConference">
    <ng-template #itemTemplate let-room>
      <div
        class="card my-0 d-flex flex-column {{ room.selected ? 'active' : '' }} grow"
        (click)="$event.stopPropagation(); onRoomClick($event, room.item)"
        routerLink="/conference-details">
        <ng-template #roomPriceFromTempalte>
          {{ 'RPA_From' | t | async }}
          <mark class="right">{{ room.item.FromPrice | price | async }}</mark>
        </ng-template>
        <div class="flex-grow-1">
          <div class="card-image">
            <div class="centered-image">
              <img *ngIf="room.item.Image" [src]="room.item.Image" draggable="false" />
              <div
                *ngIf="!room.item.Image"
                style="width: 100%; background-color: gray; height: 100%; text-align: center; line-height: 220px">
                <div
                  style="
                    font-size: 100px;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: normal;
                    margin-right: 70px;
                  ">
                  <span class="fa fa-camera" style="font-size: 100px; color: #999999"></span>
                  <h2 style="margin-left: 70px; color: #999999">
                    {{ 'RPA_Image' | t | async }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="overel-container">
              <div class="overel overel-right">
                <button
                  *ngIf="!room.selected"
                  style="pointer-events: none"
                  type="button"
                  class="btn btn-primary btn-mini"
                  (click)="onRoomClick($event, room.item)">
                  {{ 'RPA_More' | t | async }}
                </button>
                <button
                  *ngIf="room.selected"
                  style="pointer-events: none"
                  type="button"
                  class="btn btn-success btn-mini"
                  (click)="onRoomClick($event, room.item)">
                  {{ 'RPA_Selected' | t | async }}
                </button>
              </div>
            </div>
          </div>
          <div class="card-header">
            <h3 class="text-primary" [innerHTML]="room.item.Name"></h3>
          </div>
        </div>
        <hr style="width: 90%; margin-left: 5%; margin-bottom: 20px" />
        <div class="card-body">
          <h3>
            <ng-container [ngTemplateOutlet]="roomPriceFromTempalte"></ng-container>
          </h3>
        </div>
      </div>
    </ng-template>
  </app-expandable-media-list>
</div>

import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker/calendar-body';
import moment, { Moment } from 'moment';
import { LocaleService } from '../../core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'seating-date-calendar',
  templateUrl: './seating-date-calendar.component.html',
})
export class SeatingDateCalendarComponent implements OnChanges {
  updateComponent = false;

  @Input() selectedDate$!: BehaviorSubject<Moment | null>;
  @Input() minDate: Moment;
  @Input() maxDate: Moment;
  @Input() highlightDates: HighlightDate[] = [];
  @Input() availableDates: Moment[] | null = null;
  @Input() startAt: Date | undefined;
  @Input() update = false;
  @Output() dateClicked = new EventEmitter<Moment>();

  constructor(Locale: LocaleService) {
    // Initialize minDate and maxDate that the user can choose
    this.minDate = moment(new Date()).startOf('day');
    this.maxDate = moment(new Date()).add(1, 'year').endOf('day');
    // For some reason, whenever the lang is changed, the calendar is not updated
    // We force update it to update the month description
    Locale.locale$.subscribe(() => {
      this.updateComponent = true;
      setTimeout(() => {
        this.updateComponent = false;
      }, 0);
    });
  }

  ngOnChanges() {
    this.update = false;

    this.updateComponent = true;
    setTimeout(() => {
      this.updateComponent = false;
    }, 0);
  }

  highlightedDates: MatCalendarCellClassFunction<Moment> = (cellDate, view) => {
    if (view === 'month') {
      const foundDate = this.highlightDates.find((d) => {
        return cellDate.isSame(d.date, 'day');
      });

      if (foundDate) {
        return foundDate.inBasket ? 'seating-date-calendar__inbasket' : 'seating-date-calendar__vacant';
      }

      return '';
    }

    if (view === 'year') {
      const foundYear = this.highlightDates.some((d) => {
        return cellDate.isSame(d.date, 'month') && !d.inBasket;
      });

      if (foundYear) {
        return 'seating-date-calendar__vacant';
      }

      return '';
    }

    if (view === 'multi-year') {
      const foundYear = this.highlightDates.some((d) => {
        return cellDate.isSame(d.date, 'year') && !d.inBasket;
      });

      if (foundYear) {
        return 'seating-date-calendar__vacant';
      }

      return '';
    }

    return '';
  };

  async setCalendarDate(date: Moment | null) {
    if (date) {
      this.selectedDate$.next(date);
      this.dateClicked.emit(date);
    }
  }

  // This needs to be an arrow function, otherwise the context of `this` is lost
  dateFilter = (date: Moment): boolean => {
    if (this.availableDates) {
      const available = this.availableDates.some((d) => d.isSame(date, 'day'));
      return date.isSameOrAfter(this.minDate) && date.isSameOrBefore(this.maxDate) && available;
    } else {
      return date.isSameOrAfter(this.minDate) && date.isSameOrBefore(this.maxDate);
    }
  };
}

export interface HighlightDate {
  date: Date;
  occupied: boolean;
  inBasket: boolean;
}

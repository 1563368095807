import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StepList } from './step-list';
import { StepListComponent } from './step-list.component';

@NgModule({
  imports: [CommonModule],
  providers: [StepList],
  declarations: [StepListComponent],
  exports: [StepListComponent],
})
export class StepListModule {}

import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export const hasNumberValidator = (): ValidatorFn => (control: AbstractControl) => {
  const appHasNumber = /[0-9]/.test(control.value);
  return appHasNumber ? null : { appHasNumber: { value: control.value } };
};

@Directive({
  selector: '[appHasNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: HasNumberValidatorDirective,
      multi: true,
    },
  ],
})
export class HasNumberValidatorDirective implements Validator {
  validate(control: AbstractControl) {
    return hasNumberValidator()(control);
  }
}

import { Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { LogService } from '@com/logging';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent<T> {
  show = false;
  listClassName = 'a-dropdown-list'; // required for fix for IE focus event
  @Output() changed = new EventEmitter<T>();

  @Input() data: T[] = [];
  @Input() value: T | undefined;
  @Input() flexsible: boolean | undefined;

  @ContentChild('itemTemplate', { static: false }) itemTemplate: TemplateRef<ElementRef> | undefined;
  @ContentChild('valueTemplate', { static: false }) valueTemplate: TemplateRef<ElementRef> | undefined;

  constructor(private log: LogService) {}

  onOverlayClick() {
    this.log.debug('Dropdown onOverlayClick()');
    this.show = false;
  }

  onFocusOut(e: MouseEvent) {
    this.log.debug('Dropdown onFocusOut()');
    // fix for IE focusout event
    if (e.relatedTarget && (e.relatedTarget as HTMLElement).className !== this.listClassName) {
      this.onOverlayClick();
    }
  }

  onItemClick(item: T | undefined) {
    this.log.debug('Dropdown onItemClick()', item);
    this.show = false;
    this.value = item;
    this.changed.emit(this.value);
  }

  onValueClick() {
    this.log.debug('Dropdown onValueClick()');
    this.show = !this.show;
  }

  onKeyUp(keyCode: number) {
    this.log.debug(`Dropdown onKeyUp(${keyCode})`);
    switch (keyCode) {
      case 32:
        this.onValueClick();
        break; // space
      // case 38: this.selectPrevItem(); break; // up
      // case 40: this.selectNextItem(); break; // down
    }
  }
}

import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ApplicationStateService, BasketService, LocaleService, RoomCodeString } from 'src/app/core';
import { DateHelper } from 'src/app/helpers';
import { NavigationHelper } from '../../../helpers/navigation-helper';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
})
export class BasketComponent {
  state: ApplicationStateService | undefined;
  hasRooms = false;
  hasAddons = false;
  hasSeating = false;
  showCheckOut = false;
  hasTableReservation = false;
  hasGiftCard = false;
  showDate = false;

  data$ = combineLatest([this.basketService.basket$, this.localeService.translations$]).pipe(
    debounceTime(0),
    map(([basket, translations]) => {
      if (basket) {
        basket.params.ArrivalDate = basket.params.arrival.getDate();
        basket.params.ArrivalMonthName = DateHelper.getTranslatedMonth(basket.params.arrival.getMonth());
        basket.params.ArrivalFullYear = basket.params.arrival.getFullYear();
        const departureDate = DateHelper.addDays(basket.params.arrival, basket.params.stay);
        basket.params.DepartueDate = departureDate.getDate();
        basket.params.DepartueMonthName = DateHelper.getTranslatedMonth(departureDate.getMonth());
        basket.params.DepartueFullYear = departureDate.getFullYear();
        const rooms = basket.params.rooms.length;

        this.showDate = this.navigationHelper.checkUrlForParamater('RoomCounts');

        this.hasRooms = basket.bookings.some((x) => x.RoomCode !== undefined && x.RoomCode !== ('' as RoomCodeString));
        this.hasAddons = basket.bookings.some((x) => x.AddOns !== undefined && x.AddOns.length !== 0);
        this.hasTableReservation = basket.bookings.some(
          (x) => x.TableSeatings !== undefined && x.TableSeatings.length !== 0,
        );
        this.hasGiftCard = basket.bookings.some((x) => x.Giftcards !== undefined && x.Giftcards.length !== 0);
        this.hasSeating = basket.bookings.some((x) => x.Seatings !== undefined && x.Seatings.length !== 0);

        this.showCheckOut =
          (rooms === basket.bookings.length && this.hasRooms) ||
          (basket.bookings.length !== 0 &&
            basket.bookings[0].Seatings !== undefined &&
            basket.bookings[0].Seatings.length !== 0);

        return {
          params: basket.params,
          bookings: basket.bookings,
          queryParams: BasketService.BuildQueryParams(basket.params),
          roomsLabel: `${rooms} ${rooms === 1 ? translations.COM_Room : translations.COM_Rooms}`,
          addOnsLabel: (count: number) =>
            count === 0 ? translations.BIN_NoAddOns : `${translations.BIN_AddOns} (${count})`,
        };
      } else {
        return undefined;
      }
    }),
  );
  mobile = {
    opened: false,
  };

  monthNames = [
    'CAL_M01',
    'CAL_M02',
    'CAL_M03',
    'CAL_M04',
    'CAL_M05',
    'CAL_M06',
    'CAL_M07',
    'CAL_M08',
    'CAL_M09',
    'CAL_M10',
    'CAL_M11',
    'CAL_M12',
  ];

  constructor(
    private basketService: BasketService,
    private localeService: LocaleService,
    private navigationHelper: NavigationHelper,
    applicationState: ApplicationStateService,
  ) {
    this.state = applicationState;
  }

  backToStartClicked() {
    this.basketService.basketBadgeNumberSet = '0';
  }

  hideBasketClick() {
    this.basketService.hideBasket();
  }

  async CheckOut() {
    await this.navigationHelper.navigateToPage('/customer');
  }
}

<div class="a-dropdown" [ngClass]="{ show: show }">
  <div class="overlay" (click)="onOverlayClick()"></div>
  <div class="a-dropdown-container" tabindex="0" (keyup)="onKeyUp($event.keyCode)" (focusout)="onFocusOut($event)">
    <div class="a-dropdown-value" (click)="onValueClick()">
      <ng-container
        *ngTemplateOutlet="valueTemplate; context: { $implicit: { opened: show, value: value } }"></ng-container>
    </div>
    <div class="list-group {{ listClassName }} with-shadow">
      <div
        *ngFor="let item of data"
        class="a-dropdown-item list-group-item action"
        [ngClass]="{ active: value === item }"
        (click)="onItemClick(item)">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              $implicit: { opened: show, value: item, active: value === item }
            }
          "></ng-container>
      </div>
    </div>
  </div>
</div>

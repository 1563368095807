import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';

export const equalToValidator = (value: AbstractControl | undefined): ValidatorFn => {
  const equalToControl: AbstractControl | undefined = value;
  return (control: AbstractControl) => {
    if (equalToControl) {
      const appEqualTo = equalToControl.value === control.value;
      return appEqualTo ? null : { appEqualTo: { value: equalToControl.value } };
    }
    return { appEqualTo: { value: undefined } };
  };
};

@Directive({
  selector: '[appEqualTo]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EqualToValidatorDirective,
      multi: true,
    },
  ],
})
export class EqualToValidatorDirective implements Validator, OnDestroy {
  private subscription: Subscription | undefined;
  private equalToControl: AbstractControl | undefined;
  private currentControl: AbstractControl | undefined;

  @Input() set appEqualTo(equalToControl: AbstractControl | undefined) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (equalToControl) {
      this.subscription = equalToControl.valueChanges.subscribe(() => {
        if (this.currentControl) {
          this.currentControl.updateValueAndValidity();
        }
      });
    }
    this.equalToControl = equalToControl;
  }

  validate(control: AbstractControl) {
    this.currentControl = control;
    return equalToValidator(this.equalToControl)(control);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

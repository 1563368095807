import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { LogService } from '@com/logging';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
})
export class PopupComponent {
  shouldShow = false;
  left = 0;
  @Input() align?: 'left' | 'right' | 'center';

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();

  @ContentChild('itemTemplate', { static: false }) itemTemplate: TemplateRef<ElementRef> | undefined;
  @ContentChild('contentTemplate', { static: false }) contentTemplate: TemplateRef<ElementRef> | undefined;
  @ViewChild('popupContent', { static: false }) popupContent: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('popupItem', { static: false }) popupItem: ElementRef<HTMLDivElement> | undefined;

  constructor(private log: LogService) {}

  @Input() set show(shouldShow: boolean) {
    this.applyShow(shouldShow);
  }

  onItemClick() {
    this.log.debug(`Popup[${this.shouldShow}] onItemClick()`);
    this.applyShow(!this.shouldShow);
  }

  onOverlayClick() {
    this.log.debug(`Popup[${this.shouldShow}] onOverlayClick()`);
    this.applyShow(false);
  }

  private applyShow(shouldShow: boolean) {
    if (this.shouldShow !== shouldShow) {
      this.left = this.calculatePosition();
      this.shouldShow = shouldShow;
      if (shouldShow) {
        this.opened.emit();
      } else {
        this.closed.emit();
      }
    }
  }

  private calculatePosition() {
    if (this.popupItem && this.popupItem.nativeElement) {
      const width = this.popupItem.nativeElement.clientWidth;
      switch (this.align) {
        case 'left':
          return 0;
        case 'right':
          return width;
        default:
          return 0.5 * width;
      }
    }
    return 0;
  }
}

import { Component, OnInit } from '@angular/core';
import { LogService } from '@com/logging';
import { ApplicationStateService, BookingService, ConfigService, OriginEnum, TrackingService } from 'src/app/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reciept',
  templateUrl: './reciept.component.html',
})
export class RecieptComponent implements OnInit {
  mainWebsiteUrl = document.referrer.startsWith(location.origin) ? '' : window.location.origin + '/booking'; // if current website is a referrer then use empty string for angular to naviage to root page
  reciept = this.bookingService.getReciept();
  onlineBookingOrigin =
    this.applicationState.Origin === OriginEnum.OnlineBooking || this.applicationState.Origin === OriginEnum.GiftCard;

  constructor(
    private log: LogService,
    private configService: ConfigService,
    private bookingService: BookingService,
    private trackingService: TrackingService,
    private applicationState: ApplicationStateService,
  ) {}

  async ngOnInit() {
    this.log.debug('RecieptRoute ngOnInit()');
    window.scrollTo(0, 0);
    this.bookingService.updateSessionGiftcard(null);
    const recieptReservation = this.reciept;

    if (this.onlineBookingOrigin) {
      if (
        this.applicationState.CustomReceiptRedirectURL !== undefined &&
        this.applicationState.CustomReceiptRedirectURL !== ''
      ) {
        this.mainWebsiteUrl = this.applicationState.CustomReceiptRedirectURL;
      } else {
        const mainWebsiteUrl = (await this.configService.getAppSettings()).MainWebSiteUrl;

        if (mainWebsiteUrl) {
          this.mainWebsiteUrl = mainWebsiteUrl;
        } else {
          this.log.info(
            "AppSettings/MainWebSiteUrl property is not provided. Application may not navigate user to customer's web site.",
          );
        }
      }
      if (this.applicationState.PaymentlinkCallBack === 'false') {
        // Do not track if callback from paymentlink
        await this.trackingService.ecommercePurchaseFromReservation(recieptReservation.reservation);
      }
    }
  }

  redirectToPage() {
    document.location.href = environment.production ? this.mainWebsiteUrl : '/booking';
  }

  readonly OriginEnum = OriginEnum;
}

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild, OnInit } from '@angular/core';
import { AddOnInformation, HotelAddonModel } from './add-on-card.component';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { ApplicationStateService, BasketService, RoomCodeString } from '../../core';

@Component({
  selector: 'app-add-on-card-modal',
  templateUrl: './add-on-card-modal.component.html',
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '200px',
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
        }),
      ),
      transition('expanded => collapsed', animate('400ms ease-in')),
      transition('collapsed => expanded', animate('400ms ease-in')),
    ]),
  ],
})
export class AddOnCardModalComponent implements AfterViewInit, OnInit {
  @Input() totalAddOnInformation$: BehaviorSubject<AddOnInformation> | undefined;
  @Input() message: HotelAddonModel | undefined;
  @Input() total = 0;

  showDescriptionBackdrop = true;
  openDescription: 'expanded' | 'collapsed' = 'collapsed';
  seatingFlow = false;

  messageLongDescriptionShowAnimation$ = new BehaviorSubject(false);
  @ViewChild('messageLongDescriptionRef') messageLongDescriptionRef: ElementRef | undefined;

  constructor(
    public applicationState: ApplicationStateService,
    private cdRef: ChangeDetectorRef,
    private basketService: BasketService,
  ) {}

  ngOnInit(): void {
    const basket = this.basketService.get();
    if (basket) {
      if (basket.bookings[0] && basket.bookings[0].RoomCode === ('' as RoomCodeString)) {
        this.seatingFlow = true;
      }
    }
  }

  ngAfterViewInit() {
    if (this.messageLongDescriptionRef?.nativeElement) {
      const { height } = this.messageLongDescriptionRef.nativeElement.getBoundingClientRect();
      this.messageLongDescriptionShowAnimation$.next(height > 200);
    }

    // This is intentional as we forcibly want to update a view after it has been checked
    this.cdRef.detectChanges();
  }

  animateOpenDescription(): void {
    if (this.openDescription === 'collapsed') {
      this.openDescription = 'expanded';
    } else {
      this.openDescription = 'collapsed';
    }

    // As there is no debounce or anything, changes can happen really fast, this makes sure its synced
    this.cdRef.detectChanges();
  }

  descriptionAnimationEvent(event: AnimationEvent) {
    if (event.triggerName === 'expandCollapse') {
      if (event.toState === 'expanded' && event.phaseName === 'done') {
        this.showDescriptionBackdrop = false;
      }

      if (event.toState === 'collapsed' && event.phaseName === 'start') {
        this.showDescriptionBackdrop = true;
      }
    }
  }
}

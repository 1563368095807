import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CustomerService } from './customer.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [CustomerService],
  exports: [],
})
export class CustomerModule {
  constructor(@Optional() @SkipSelf() parentModule: CustomerModule) {
    if (parentModule) {
      throw new Error('CustomerModule is already loaded. Import it in the CoreModule only');
    }
  }
}

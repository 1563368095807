import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfigService, HotelCodeString, PromoCodeString } from '../config';

export interface IPromotionService {
  getPromotionStatus(hotelCode: HotelCodeString, promocode: PromoCodeString): Promise<GetPromotionCodeStatus[] | null>;
}

@Injectable()
export class DataPromotionService implements IPromotionService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  async getPromotionStatus(hotelCode: HotelCodeString, promocode: PromoCodeString) {
    const config = await this.configService.getAppSettings();
    const data = await lastValueFrom(
      this.http.get<GetPromotionCodeStatus[]>(
        `${config.ECommerceDataProvider}/api/eCommerce/GetPromotionCodeStatus/${hotelCode}/${promocode}`,
        // .get<GetPromotionCodeStatus[]>(`https://localhost:44343/api/eCommerce/GetPromotionCodeStatus/` + hotelCode + '/' + promocode
      ),
    );

    return data;
  }
}

export interface GetPromotionCodeStatus {
  Id: number;
  PromotionCode: string;
  RatePlanCode: string;
  DiscountPercentage: number;
  StayFromDate: Date;
  StayToDate: Date;
  DateRestrictionType: number;
}

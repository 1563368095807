import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogService } from '@com/logging';
import { EmptyError, Subscription } from 'rxjs';
import { AppQueryParams } from 'src/app/app.component';
import {
  ApplicationStateService,
  BookingSeating,
  BookingTable,
  RateCodeString,
  RoomCodeString,
  SeatingData,
} from 'src/app/core';
import { BasketQueryParams, BasketService, HotelService, Loader, LocaleService, SearchParams } from 'src/app/core';
import { DateHelper, Guid, UTCDateString, UTCDateTimeString } from 'src/app/helpers';
import { SeatProfileType } from '../../../core/modules/payment';
import { NavigationHelper } from '../../../helpers/navigation-helper';
import { Messenger } from '../../../modules/messenger';
import { DesignConfiguration } from '../../../shared/design/design-configuration';
import { SearchComponent } from '../../search/search.component';
import { SeatingTableDetails, SeatingUiModel, SeatingWrapper, TableTickets } from '../../seating/seating.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
})
export class ProductsComponent implements OnInit, OnDestroy {
  guestCount = 2 as number;
  guestCountArray: number[] = [];

  calendarDates: CalendarModel | undefined;
  currentDate = new Date();
  calendarClick = false;
  currentDay: Day | undefined;
  status = false;
  data: SeatingWrapper[] | undefined;
  paramsSubscription: Subscription | undefined;
  tableToBasketArray: BookingTable[] = [];
  bookingFlow = false;

  basket = this.getBasket(this.route.snapshot.queryParams as AppQueryParams);

  ticketSelectedFromBasket: number | undefined;
  seatingSelected: Guid | undefined;
  sealectedSeatingDate: Date | undefined;
  seatingsToBasketArray: BookingSeating[] = [];
  state: ApplicationStateService | undefined;

  @ViewChild('messageHotelTermsTempalte', { static: false })
  messageHotelTermsTempalte: TemplateRef<SelectedTableModel> | undefined;

  constructor(
    private log: LogService,
    private route: ActivatedRoute,
    private loader: Loader,
    private basketService: BasketService,
    private hotelService: HotelService,
    private localeService: LocaleService,
    private applicationState: ApplicationStateService,
    private messenger: Messenger,
    private navigationHelper: NavigationHelper,
    private designConfig: DesignConfiguration,
  ) {}

  async ngOnInit() {
    this.catchUrlParams();

    if (this.basket.bookings.length !== 0 && this.basket.bookings[0].RoomName !== '') {
      this.bookingFlow = true;
      this.currentDate = new Date(this.basket.params.arrival);
    }

    await this.buildCalendarModel();
    this.buildGuestCountArray();

    this.state = this.applicationState;

    if (this.basket.bookings.length === 0) {
      this.basket.params.rooms = [];
    }

    // If returning to the module after selecting the table reservations, we build all the data from basket, by 'clicking' the clikedDate() method from the code
    if (
      this.basket.bookings.length !== 0 &&
      this.basket.bookings[0].TableSeatings &&
      this.basket.bookings[0].TableSeatings[0] !== undefined
    ) {
      await this.clickedDate({
        date: new Date(this.basket.bookings[0].TableSeatings[0].Date),
      } as Day);
    }

    document.body.classList.remove('addBackGround');
    document.body.style.backgroundImage = '';
    await this.prepareNavigation();
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async prepareNavigation() {
    if (this.bookingFlow) {
      if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
        await this.navigationHelper.continueMobileNavigationbar(false, true, '/products', '/seating/1');
      } else {
        await this.navigationHelper.continueMobileNavigationbar(false, true, '/products', '/add-on/1');
      }
    } else {
      await this.navigationHelper.continueMobileNavigationbar(false, true, '/products', '/customer', {
        queryParams: { guest: this.guestCount },
      });
    }
  }

  buildGuestCountArray() {
    let selectedGuests = 0;

    if (this.basket.bookings.length !== 0 && this.basket.bookings[0].TableSeatings) {
      for (const item of this.basket.bookings[0].TableSeatings) {
        for (const seat of item.SeatGuest) {
          selectedGuests = selectedGuests + +seat.Count;
        }
      }
    }

    this.guestCount = +this.guestCount;

    if (!this.bookingFlow) {
      if (selectedGuests !== this.guestCount) {
        this.guestCountArray = [] as number[];
        let counter = 1;

        while (counter <= this.guestCount) {
          this.guestCountArray.push(counter);
          counter = counter + 1;
        }
      }
    } else {
      let counter = 1;

      while (counter <= this.guestCount) {
        this.guestCountArray.push(counter);
        counter = counter + 1;
      }
    }
  }

  catchUrlParams() {
    const guest = this.route.snapshot.queryParams['guest'];
    if (guest !== undefined) {
      this.guestCount = guest;
    }
  }

  async nextMonth() {
    this.currentDate = DateHelper.addMonth(this.currentDate, 2);
    await this.buildCalendarModel();
  }

  async prevMonth() {
    this.currentDate = DateHelper.addMonth(this.currentDate, 0);
    await this.buildCalendarModel();
  }

  async checkSeatingAvailability(start: Date, end: Date) {
    return await this.loader.using(async () => {
      return await this.hotelService.getTableAvailability(
        this.applicationState.CurrentHotelCode,
        start,
        end,
        this.guestCount,
        this.bookingFlow,
        this.basket.bookings[0] ? this.basket.bookings[0].RateCode : '',
      );
    }, 'LOA_Configuration' as any);
  }

  async buildCalendarModel() {
    const lastDay = DateHelper.getLastDateOfMonth(this.currentDate);
    const firstDay = DateHelper.getFirstDateOfMonth(this.currentDate);

    const arrival = this.basket.params.arrival;
    let departure;

    if (this.basket.params.stay < 25) {
      departure = DateHelper.addDays(arrival, this.basket.params.stay);
    }

    const res = await this.checkSeatingAvailability(arrival, departure ? departure : DateHelper.addDays(lastDay, 1));

    if (departure && res.length === 0) {
      if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
        await this.navigationHelper.navigateToPage('/seating/1');
      } else {
        await this.navigationHelper.navigateToPage('/add-on/1');
      }
    }

    const dateCollection = [] as Date[];

    for (const item of res) {
      const date = DateHelper.FromUtcDate(item as unknown as UTCDateTimeString);
      if (departure && DateHelper.FromUtcDate(item.toString() as UTCDateTimeString).getTime() >= departure.getTime()) {
        continue;
      }

      dateCollection.push(date);
    }

    if (departure && dateCollection.length === 0) {
      if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
        await this.navigationHelper.navigateToPage('/seating/1');
      } else {
        await this.navigationHelper.navigateToPage('/add-on/1');
      }
    }

    let dayCounter = 0;
    const model = {} as CalendarModel;
    model.days = [] as Day[];
    model.currentMonth = DateHelper.getTranslatedMonth(firstDay.getMonth());
    model.currentYear = firstDay.getFullYear();

    let dummyDaysAdded = false;

    while (dayCounter < lastDay.getDate()) {
      const day = {} as Day;

      day.date = DateHelper.addDays(firstDay, dayCounter);
      const anySeatings =
        dateCollection.filter((x) => x.getDate() === day.date.getDate() && x.getMonth() === day.date.getMonth())
          .length !== 0
          ? true
          : false;
      day.status = this.calculateStatus(day.date, new Date(), anySeatings, departure);
      day.active = day.status === dayStatus.open ? true : false;

      // Days before current monthdays (grayed out)
      if (!dummyDaysAdded) {
        let weekDay = day.date.getDay();
        let daysToSubract = this.calculateDaysToSubstract(weekDay);
        const days = this.daysToReach(weekDay);

        while (weekDay < days) {
          const dummyday = {} as Day;

          dummyday.date = DateHelper.addDays(firstDay, daysToSubract);
          dummyday.active = false;
          dummyday.status = dayStatus.invalid;

          model.days.push(dummyday);

          weekDay = weekDay + 1;
          daysToSubract = daysToSubract + 1;
        }
        dummyDaysAdded = true;
      }

      model.days.push(day);
      dayCounter = dayCounter + 1;
    }

    this.calendarDates = model;
  }

  buildAvailableHours(tickets: SeatingUiModel) {
    const hours = [] as TableArrival[];
    let res = tickets.tableTickets.filter((x) => x.maxPers >= this.guestCount);

    res = res.sort((a: TableTickets, b: TableTickets) => new Date(a.arrival).getTime() - new Date(b.arrival).getTime());

    res.forEach((item) => {
      if (
        DateHelper.FromUtcDate(item.arrival as unknown as UTCDateTimeString).getDate() ===
        DateHelper.FromUtcDate(tickets.date as unknown as UTCDateTimeString).getDate()
      ) {
        const model = {
          realDate: item.arrival,
          clock: DateHelper.getTimeFromUtcDate(item.arrival.toString() as UTCDateTimeString),
        } as TableArrival;
        hours.push(model);
      }
    });

    return hours;
  }

  onCardClick(item: SeatingUiModel) {
    if (item.selected) {
      item.selected = false;
      this.removeTableReservationFromBasket(item);
      return;
    }

    if (this.messageHotelTermsTempalte) {
      this.applicationState.stopScrolling(true);
      const hours = this.buildAvailableHours(item);
      const message = this.messenger.show(
        this.messageHotelTermsTempalte,
        {
          time: hours,
          guestArrival: hours[0].clock,
          guestCount: this.guestCountArray.length,
          accept: async (x: SelectedTableModel) => {
            const selectedHour = hours.find((y) => y.clock === message.data.guestArrival);

            if (selectedHour) {
              item.selected = true;
              this.applicationState.stopScrolling(false);
              this.addTableReservationToBasket(item, selectedHour, x);
              this.messenger.close(message);

              if (!this.bookingFlow) {
                this.guestCountArray.splice(this.guestCountArray.length - message.data.guestCount);
              }

              if (this.guestCountArray.length === 0) {
                if (!this.bookingFlow) {
                  await this.navigationHelper.continue('/products', '/customer', {
                    queryParams: { guest: this.guestCount },
                  });
                }
              }
            } else {
              this.log.error(
                'The selected time on the current table reservation could not be choosen',
                message.data.guestArrival,
              );
            }
          },
          close: () => {
            this.applicationState.stopScrolling(false);
            this.messenger.close(message);
          },
        },
        () => {
          this.applicationState.stopScrolling(false);
          message.data.close();
        },
      );
    }
  }

  removeTableReservationFromBasket(item: SeatingUiModel) {
    if (this.basket.bookings.length !== 0 && this.basket.bookings[0].TableSeatings !== undefined) {
      const toRemove = this.basket.bookings[0].TableSeatings.findIndex(
        (x) => x.SeatGuest[0].SeatId === item.seatingPlanId,
      );
      const info = this.basket.bookings[0].TableSeatings.find((x) => x.SeatGuest[0].SeatId === item.seatingPlanId);

      if (info !== undefined) {
        if (!this.bookingFlow) {
          const count = info.SeatGuest[0].Count;
          let lastNumber = this.guestCountArray[this.guestCountArray.length - 1];

          let toAdd = 0;

          if (lastNumber) {
            toAdd = +count + +lastNumber;
          } else {
            toAdd = count;
            lastNumber = 0;
          }

          while (this.guestCountArray.length < toAdd) {
            lastNumber = lastNumber + 1;
            this.guestCountArray.push(lastNumber);
          }
        }

        this.basket.bookings[0].TableSeatings.splice(toRemove, 1);

        this.basketService.set(this.basket);
      }
    }
  }

  daysToReach(weekDay: number) {
    switch (weekDay) {
      case 0: {
        return 6;
      }
      case 1: {
        return 1;
      }
      case 2: {
        return 3;
      }
      case 3: {
        return 5;
      }
      case 4: {
        return 7;
      }
      case 5: {
        return 9;
      }
      case 6: {
        return 11;
      }
      default: {
        return 0;
      }
    }
  }

  calculateDaysToSubstract(weekDay: number) {
    switch (weekDay) {
      case 0: {
        return -6;
      }
      case 1: {
        return -5;
      }
      case 2: {
        return -1;
      }
      case 3: {
        return -3;
      }
      case 4: {
        return -3;
      }
      case 5: {
        return -4;
      }
      case 6: {
        return -7;
      }
      default: {
        return 0;
      }
    }
  }

  calculateStatus(date: Date, currentMonth: Date, anyseatings: boolean, departure?: Date) {
    if (departure && departure <= date) {
      return dayStatus.invalid;
    }
    if (departure && this.basket.params.arrival > date) {
      return dayStatus.invalid;
    }
    if (currentMonth.getMonth() > date.getMonth() && currentMonth.getFullYear() === date.getFullYear()) {
      return dayStatus.runOut;
    } else if (currentMonth.getMonth() === date.getMonth() && date.getDate() < new Date().getDate()) {
      return dayStatus.invalid;
    } else if (!anyseatings) {
      return dayStatus.closed;
    }
    return dayStatus.open;
  }

  async clickedDate(day: Day) {
    this.resetAllSelectedDates();
    this.currentDay = day;
    this.status = true;
    day.selected = true;
    await this.buildData(day.date, false);

    setTimeout(() => {
      const cards = document.getElementById('cards');

      if (cards !== null) {
        cards.classList.remove('hide');
        cards.classList.add('item1');
        cards.classList.add('fadeBody');
      }
      if (this.designConfig.addProductScrolling()) {
        window.scrollTo({ left: 0, top: 220, behavior: 'smooth' });
      }
    }, 700);
  }

  resetAllSelectedDates() {
    if (this.calendarDates) {
      this.calendarDates.days.forEach((x) => {
        x.selected = false;
      });
    }
  }

  async buildData(date: Date = new Date(), calculateMonth = true) {
    this.data = await this.buildSeatingData(date, calculateMonth);
  }

  addTableReservationToBasket(seating: SeatingUiModel, arrival: TableArrival, model: SelectedTableModel) {
    const seatGuestArray: SeatProfileType[] = [] as SeatProfileType[];
    const modiDate = DateHelper.FromUtcDate(arrival.realDate.toString() as UTCDateString);
    const seatGuestProfile = {
      Count: model.guestCount,
      SeatId: seating.seatingPlanId.toString(),
      Name: seating.characteristics,
      Price: seating.tableDetails.price,
      VareNr: seating.tableDetails.vareNr,
      Date: DateHelper.toServerDateFormatString(modiDate) as UTCDateString,
      Time: arrival.clock,
    } as SeatProfileType;
    seatGuestArray.push(seatGuestProfile);

    const tableToBasket = {
      Id: seating.seatingPlanId,
      HotelCode: this.applicationState.CurrentHotelCode,
      Name: seating.name,
      Date: arrival.realDate,
      translatedMonth: DateHelper.getTranslatedMonth(
        DateHelper.FromUtcDate(arrival.realDate.toString() as UTCDateTimeString).getMonth(),
      ),
      Time: model.guestArrival,
      SeatCode: 'DELT',
      SeatRate: seating.characteristics,
      Total: model.guestCount * seating.tableDetails.price,
      SeatGuest: seatGuestArray,
      Day: DateHelper.FromUtcDate(arrival.realDate.toString() as UTCDateTimeString).getDate(),
      Year: DateHelper.FromUtcDate(arrival.realDate.toString() as UTCDateTimeString).getFullYear(),
      SeatingPlanName: seating.name,
    } as BookingTable;

    if (this.bookingFlow) {
      // If bookingflow, we want to preserve basket content
      this.basket.bookings[0].TableSeatings = this.tableToBasketArray;
    }

    if (this.bookingFlow) {
      // If bookingflow, we want to preserve basket content
      if (this.basket.bookings.length !== 0 && this.basket.bookings[0].TableSeatings) {
        this.tableToBasketArray = this.basket.bookings[0].TableSeatings;
        this.tableToBasketArray.push(tableToBasket);
      }
      this.basket.bookings[0].TableSeatings = this.tableToBasketArray;
    } else {
      // If only table reservation, we want the basket to only contain the table reservation
      this.tableToBasketArray.push(tableToBasket);
      this.basket.bookings[0] = {
        HasPromotionForCustomer: false,
        RateCode: '' as RateCodeString,
        RateName: '',
        RoomCode: '' as RoomCodeString,
        RoomName: '',
        RateDescription: '',
        TableSeatings: this.tableToBasketArray,
        ConferenceRateCode: '',
        ConferenceRoomCode: '',
        Price: undefined,
        OriginalPrice: undefined,
      };
    }

    this.basketService.set(this.basket);
  }

  async goBack() {
    await this.navigationHelper.goBack();
  }

  async onMoveToNextClick() {
    if (this.bookingFlow) {
      if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
        await this.navigationHelper.continue('/products', '/seating/1', {
          queryParams: { guest: this.guestCount },
        });
      } else {
        await this.navigationHelper.continue('/products', '/add-on/1', {
          queryParams: { guest: this.guestCount },
        });
      }
    } else {
      await this.navigationHelper.continue('/products', '/customer', {
        queryParams: { guest: this.guestCount },
      });
    }
  }

  isTableSelected(seatingData: SeatingData, date: Date) {
    if (this.basket.bookings.length !== 0 && this.basket.bookings[0].TableSeatings) {
      if (
        this.basket.bookings[0].TableSeatings.filter(
          (x) =>
            x.Id === seatingData.ItemNumber &&
            new Date(x.Date).getDate() === date.getDate() &&
            new Date(x.Date).getMonth() === date.getMonth() &&
            new Date(x.Date).getFullYear() === date.getFullYear(),
        ).length !== 0
      ) {
        return true;
      }
    }
    return false;
  }

  private getBasket(queryParams: Partial<BasketQueryParams>) {
    const basket = this.basketService.get();

    if (basket) {
      return basket;
    } else {
      const hotelCode = SearchComponent.ParseHotelCode(queryParams);
      const arrival = SearchComponent.ParseArrival(queryParams);
      const stay = SearchComponent.ParseStay(queryParams);
      let rooms = SearchComponent.ParseRooms(queryParams);
      const single = SearchComponent.ParseSingle(queryParams);
      if (rooms === undefined) {
        rooms = [];
      }
      if (hotelCode && arrival && stay && rooms) {
        const params: SearchParams = {
          hotelCode,
          arrival,
          stay,
          rooms,
          single,
          ArrivalDate: 0,
          ArrivalFullYear: 0,
          ArrivalMonthName: '',
          DepartueDate: 0,
          DepartueFullYear: 0,
          DepartueMonthName: '',
          BookingFlow: 1,
          RateCode: '',
          ConferenceRoomCode: '',
        };
        return this.basketService.create(params);
      } else {
        throw new Error('Basket must not be empty');
      }
    }
  }

  private async buildSeatingData(selectedDate: Date, checkMonth = false) {
    return await this.loader.using(async () => {
      const seatingSpectra = await this.hotelService.GetECommerceSeatings(
        this.applicationState.CurrentHotelCode,
        selectedDate,
        selectedDate,
        await this.localeService.getLocale(),
      );

      const seatingWrapperArray = [] as SeatingWrapper[];
      const seatingArray = [] as SeatingUiModel[];

      let start = new Date();
      let end = new Date();

      if (checkMonth) {
        start = DateHelper.getFirstDateOfMonth(selectedDate);
        end = DateHelper.getLastDateOfMonth(selectedDate);
      } else {
        start = selectedDate;
        end = DateHelper.addDays(selectedDate, 0);
      }

      if (seatingSpectra && seatingSpectra.length !== 0) {
        const seatings = seatingSpectra.filter((x) => x.Rates !== null);

        for (const item of seatings) {
          const seatingPortal = await this.hotelService.GetECommerceTablePortalInfo(
            this.applicationState.CurrentHotelCode,
            start,
            end,
            item.ItemNumber,
            await this.localeService.getLocale(),
            this.bookingFlow,
            this.basket.bookings[0] ? this.basket.bookings[0].RateCode : '',
          );
          const info = seatingPortal.find((x) => x.ID === item.ItemNumber);

          if (info) {
            const seatingUiModel = {} as SeatingUiModel;

            const arrivals = [] as TableTickets[];

            for (const seat of item.Rates) {
              const model = {
                arrival: seat.Arrival,
                maxPers: seat.MaxPers,
              } as TableTickets;
              arrivals.push(model);
            }

            seatingUiModel.image = info.Image;
            seatingUiModel.characteristics = item.Characteristics;
            seatingUiModel.long = info.Long;
            seatingUiModel.sortIndex = info.SortIndex;
            seatingUiModel.seatingPlanId = info.ID;
            seatingUiModel.date = item.Rates[0].Arrival;
            seatingUiModel.tableTickets = arrivals;
            seatingUiModel.name = info.Name;
            seatingUiModel.selected = this.isTableSelected(item, selectedDate);

            seatingUiModel.tableDetails = {} as SeatingTableDetails;
            seatingUiModel.tableDetails.vareNr = info.SeatItems[0].VareNr;
            seatingUiModel.tableDetails.price = info.SeatItems[0].Price;
            seatingUiModel.tableDetails.rateCode = item.ItemNumber;

            seatingArray.push(seatingUiModel);
          }
        }

        for (const seating of seatingArray) {
          const midDate = DateHelper.FromUtcDate(seating.date.toString() as UTCDateString);
          const month = DateHelper.getTranslatedMonth(midDate.getMonth());
          const exist = seatingWrapperArray.find((x) => x.month === month) !== undefined;
          if (exist) {
            const wrapper = seatingWrapperArray.find((x) => x.month === month);

            if (wrapper !== undefined) {
              const skip = wrapper.data.find((x) => x.seatingPlanId === seating.seatingPlanId) === undefined;

              if (skip) {
                wrapper.data.push(seating);
              }
            }
          } else {
            const wrapper = {} as SeatingWrapper;
            if (month) {
              wrapper.month = month;
              wrapper.data = [] as SeatingUiModel[];
              wrapper.data.push(seating);

              seatingWrapperArray.push(wrapper);
            }
          }
        }
      }

      if (seatingArray.length === 0) {
        throw new EmptyError();
      }

      for (const item of seatingWrapperArray) {
        item.data = this.sortList(item.data);
      }

      return seatingWrapperArray;
    }, 'LOA_Configuration' as any);
  }

  private sortList(array: SeatingUiModel[]) {
    const sortedArray = [] as SeatingUiModel[];

    for (const item of array) {
      if (sortedArray.length === 0) {
        sortedArray.push(item);
      } else {
        if (sortedArray.filter((x) => x.sortIndex > item.sortIndex).length === 0) {
          sortedArray.push(item);
        } else {
          const object = sortedArray.filter((x) => x.sortIndex > item.sortIndex)[0];
          const index = sortedArray.indexOf(object);

          sortedArray.splice(index, 0, item);
        }
      }
    }

    return sortedArray;
  }
}

export interface SelectedTableModel {
  time: TableArrival[];
  guestArrival: string;
  guestCount: number;
  accept: (model: SelectedTableModel) => void;
  close: () => void;
}

export interface TableArrival {
  realDate: Date;
  clock: string;
}

enum dayStatus {
  closed = '#F87E7D',
  open = 'white',
  invalid = '#a9a9a9',
  runOut = '#707070',
}

export interface CalendarModel {
  days: Day[];
  currentMonth: string;
  currentYear: number;
}

export interface Day {
  date: Date;
  active: boolean;
  status: dayStatus;
  selected: boolean;
}

<div class="landing-content">
  <div class="container hotelSelector zoom-content-effect" *ngIf="picker && picker.length > 1">
    <div style="text-align: center; margin-bottom: 0.5%">
      <h2>{{ applicationState.HotelLabel | t | async }}</h2>
    </div>
    <div class="row mobile-hide desktop-show">
      <div style="display: flex; justify-content: center; width: 100%; height: 8.5rem">
        <div style="display: flex; overflow: auto; overflow-y: hidden; overflow-x: visible" class="hotelSpacing">
          <div *ngFor="let hotel of picker">
            <div style="text-align: center">
              <div
                *ngIf="hotel.hotelImage"
                class="{{ hotel.selected ? 'hotel-active' : '' }} picker-tile grow applicationColor"
                [ngStyle]="{
                  'background-image': 'url(' + hotel.hotelImage + ')'
                }"
                style="position: relative; background-repeat: no-repeat; background-size: cover"
                (click)="chooseHotel(hotel)">
                <div
                  style="
                    max-width: 7rem;
                    font-size: 13px;
                    position: absolute;
                    bottom: 0;
                    background-color: rgba(238, 238, 238, 0.85);
                    width: 100%;
                  ">
                  <p class="applicationColor">{{ hotel.name }}</p>
                </div>
              </div>
              <div
                *ngIf="!hotel.hotelImage"
                class="{{ hotel.selected ? 'hotel-active' : '' }} picker-tile grow applicationColor"
                style="position: relative"
                (click)="chooseHotel(hotel)">
                <span class="fas fa-hotel" style="font-size: 45px; margin-top: 30%; margin-right: 18%"></span>
                <div
                  style="
                    max-width: 7rem;
                    font-size: 13px;
                    position: absolute;
                    bottom: 0;
                    background-color: rgba(238, 238, 238, 0.85);
                    width: 100%;
                  ">
                  <p class="applicationColor">{{ hotel.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mobile-show desktop-hide">
      <div style="display: flex; justify-content: center; width: 100%; height: 9.5rem">
        <div style="display: flex; overflow: auto; overflow-y: hidden; overflow-x: scroll" class="hotelSpacing">
          <div *ngFor="let hotel of picker">
            <div style="text-align: center">
              <div
                *ngIf="hotel.hotelImage"
                class="{{ hotel.selected ? 'hotel-active' : '' }} picker-tile grow applicationColor"
                [ngStyle]="{
                  'background-image': 'url(' + hotel.hotelImage + ')'
                }"
                style="position: relative; background-repeat: no-repeat; background-size: cover"
                (click)="chooseHotel(hotel)">
                <div
                  style="
                    max-width: 7rem;
                    font-size: 13px;
                    position: absolute;
                    bottom: 0;
                    background-color: rgba(238, 238, 238, 0.85);
                    width: 100%;
                  ">
                  <p class="applicationColor">{{ hotel.name }}</p>
                </div>
              </div>
              <div
                *ngIf="!hotel.hotelImage"
                class="{{ hotel.selected ? 'hotel-active' : '' }} picker-tile grow applicationColor"
                style="position: relative"
                (click)="chooseHotel(hotel)">
                <span class="fas fa-hotel" style="font-size: 45px; margin-top: 30%; margin-right: 18%"></span>
                <div
                  style="
                    max-width: 7rem;
                    font-size: 13px;
                    position: absolute;
                    bottom: 0;
                    background-color: rgba(238, 238, 238, 0.85);
                    width: 100%;
                  ">
                  <p class="applicationColor">{{ hotel.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="{{ picker && picker.length > 1 ? 'landing-content-multi' : 'landing-content-single' }}">
    <div class="container">
      <div *ngIf="tiles">
        <div class="row">
          <div *ngFor="let tile of tiles">
            <div *ngIf="tile.active" class="col s6 m4 l4 desktop-hide" style="margin-bottom: 3%">
              <div class="navigationTile grow applicationColorTextIcon fadeBody glass-effect" (click)="navigate(tile)">
                <i *ngIf="tile.tileID === '21be5c1e-3b0c-4d90-b0a1-62d4ba9733a0'" class="fas fa-gifts"></i>

                <i
                  *ngIf="tile.tileID === '0a3e2328-af86-4ac8-b66d-1e8ec0e888d6'"
                  class="fas fa-utensils"
                  style="margin-left: 15px"></i>

                <i *ngIf="tile.tileID === 'd850acd6-cde8-41a9-8a86-3cf46417e74a'" class="fas fa-bed"></i>

                <i *ngIf="tile.tileID === '04a2116c-7dcd-45aa-b4ed-ec40aeaaae55'" class="fas fa-bed"></i>

                <i *ngIf="tile.tileID === 'c449fd68-481a-47c4-a381-271345f9cb32'" class="fas fa-users"></i>

                <i *ngIf="tile.tileID === 'cf613ac1-73eb-47cc-9d74-83d15e18aba9'" class="fas fa-theater-masks"></i>

                <p>{{ tile.lable | t | async }}</p>
              </div>
            </div>
            <div *ngIf="tile.active" class="col mobile-hide" style="margin-bottom: 3%">
              <div class="navigationTile grow applicationColorTextIcon fadeBody glass-effect" (click)="navigate(tile)">
                <i *ngIf="tile.tileID === '21be5c1e-3b0c-4d90-b0a1-62d4ba9733a0'" class="fas fa-gifts"></i>

                <i
                  *ngIf="tile.tileID === '0a3e2328-af86-4ac8-b66d-1e8ec0e888d6'"
                  class="fas fa-utensils"
                  style="margin-left: 15px"></i>

                <i *ngIf="tile.tileID === 'd850acd6-cde8-41a9-8a86-3cf46417e74a'" class="fas fa-bed"></i>

                <i *ngIf="tile.tileID === '04a2116c-7dcd-45aa-b4ed-ec40aeaaae55'" class="fas fa-bed"></i>

                <i *ngIf="tile.tileID === 'c449fd68-481a-47c4-a381-271345f9cb32'" class="fas fa-users"></i>

                <i *ngIf="tile.tileID === 'cf613ac1-73eb-47cc-9d74-83d15e18aba9'" class="fas fa-theater-masks"></i>

                <p>{{ tile.lable | t | async }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Messages } from './index';

export const messages: Messages = {
  APA_CompanyLogin: `Company login`,
  APA_Logout: `Log out`,
  APA_Welcome: `Hello`,
  APA_ShowPromo: `Promotion code`,
  APA_PromoLabel: `Enter promotion code`,
  APA_Promo: `Promotion code`,
  APA_PromoSubmit: `Use`,
  APA_PromoChange: `change`,
  APA_ActivateHead: `Activate your login`,
  APA_ActivateDesc: `Type in password to activate login for`,
  APA_Password: `Password`,
  APA_ConfirmPassword: `Confirm Password`,
  APA_PasswordHasUpper: `Password must contain at least one uppercase letter (A-Z)`,
  APA_PasswordHasLower: `Password must contain at least one lowercase letter (a-z)`,
  APA_PasswordHasNumber: `Password must contain at least one number (0-9)`,
  APA_PasswordLength8: `Password must contain at least 8 characters`,
  APA_ConfirmPasswordEqualTo: `Passwords not match`,
  APA_Activate: `Activate`,
  APA_CustomerNotFound: `Sorry we can not find customer record in out database`,
  APA_ResetBasket: `Everything will be removed from basket. Are you sure?`,
  APA_Address: `Address`,
  APA_Contact: `Contact`,
  APA_VatNumber: `VAT:`,
  APA_PromoMessageStart: `Promotioncode`,
  APA_ValidTime: `Validity`,
  APA_PromoMessageEnd: `Promotioncode is valid on products marked with the code`,
  APA_AQCDecription: `Age description`,
  APA_Year: `years`,
  APA_Book: `Book`,

  CPA_NothingAvailable_EmptyText: `Please select a new date below, or go back and change your information from the first step`,
  CPA_NothingAvailable: `There are no available rooms which satisfy your criteria`,
  CPA_SoldOut: `Sold out`,
  CPA_CustomerInvalid: `Customer is not valid`,
  CPA_PromoInvalid: `The promotion code you entered is invalid.`,
  CPA_PromoBetween: `The promotion code you entered is only valid if your stay period is between:`,

  RPA_More: `Show more`,
  RPA_Select: `Select`,
  RPA_Selected: `Selected`,
  RPA_From: `Starting from`,
  RPA_Image: `No image`,
  RPA_Description: `Description`,
  RPA_ChooseRoom: `Choose room`,
  RPA_ChoosePackage: `Choose Package`,
  RPA_ChooseCabin: `Choose cabin`,
  RPA_ChooseHouse: `Choose house`,
  RPA_ChooseUnit: `Choose unit`,
  RPA_BackRate: `Choose another package`,
  RPA_MaxPersons: `Maximum number of people`,
  RPA_ChooseYourRoom: `Choose room`,
  RPA_ChooseYourCabin: `Choose cabin`,
  RPA_ChooseYourHouse: `Choose house`,
  RPA_ChooseYourUnit: `Choose unit`,
  RPA_ChooseYourPackage: `Choose package`,

  APA_Services: `Services`,
  APA_Add: `Add`,
  APA_Added: `Added`,
  APA_Remove: `Remove`,
  APA_ContentDialog: `Choose the number of guests and days you want the selected service. Between the dates`,
  APA_ChooseYourAddOns: `Choose add-ons`,
  APA_TotalPrice: `Total cost`,
  APA_TotalInBasket: `pc, total`,
  APA_TotalOf: `Choose quantity of`,

  CUP_Header: `Buyers informations`,
  CUP_Accept: `Accept`,
  CUP_FName: `Name`,
  CUP_FNameReq: `First name is required`,
  CUP_FPhoneReq: `Phone name is required`,
  CUP_LName: `Surname`,
  CUP_LNameReq: `Last name is required`,
  CUP_Address: `Address`,
  CUP_AddressReq: `Address is required`,
  CUP_ZipCode: `Postal code`,
  CUP_ZipCodeReq: `Postal code is required`,
  CUP_City: `City`,
  CUP_CityReq: `City is required`,
  CUP_Contry: `Country`,
  CUP_ContryReq: `Country is required`,
  CUP_Phone: `Mobile phone number`,
  CUP_PhoneReq: `Mobile phone is required`,
  CUP_Email: `Email`,
  CUP_EmailRepeat: `Repeat Email`,
  CUP_EmailReq: `Email is required`,
  CUP_EmailInvalid: `Email is not valid`,
  CUP_Comment: `Comment`,
  CUP_NeedSubscribe: `Subscribe to newsletter`,
  CUP_AcceptTerms: `Terms and Conditions`,
  CUP_BookNow: `Book now!`,
  CUP_CancelPolicyHeader: `Cancellation insurance`,
  CUP_CancelPolicyDesc: `You have the option to cancel your stay if you can't make it. See additional information regarding cancellation insurance in our booking conditions.`,
  CUP_CancelPolicyLabel: `Yes, I would like to buy cancellation insurance for`,
  CUP_GuestInfo: `Guest information`,
  CUP_BookingOverview: `Booking overview`,
  CUP_PayWithGiftcard: `To pay with gift card`,
  CUP_PayWithGiftcardClick: `Click here`,
  CUP_PayWithGiftcardDescription: `Enter the code from your giftcard`,
  CUP_PayWithGiftcardError: `Gift card is not active or dosen't exists.`,
  CUP_PayWithGiftcardStatus: `Gift card active`,
  CUP_PayWithGiftcardDiscount: `Gift card amount`,
  CUP_PayWithGiftcardHeader: `Gift card`,
  CUP_PayWithGiftcardInformation: `Want to use a gift card?`,
  CUP_PayWithGiftcardPlaceholder: `Gift card code`,
  CUP_PayWithGiftcardRedeem: `Use`,
  CUP_BookingOverviewToPay: `To pay`,

  PAL_GoToPayment: `Go to payment`,
  PAL_PaymentComplete: `The link has expired. Please contact`,
  PAL_PaymentNotAvailable: `Payment on this reservation is not available`,

  PAR_Payment: `Payment`,
  PAR_SeekAuthorization: `Please fill in your credit card information below in order to confirm your payment and complete your reservation`,
  PAR_TotalLabel: `Price for your order`,
  PAR_TotalPayLabel: `Price to pay`,
  PAR_OBSLabel: `IMPORTANT: Do not close this window until the receipt page appears, where you can see your order / reservation number!`,
  PAR_OBSLabelGiftcard: `IMPORTANT: Stay on the page until receipt appears with a order number!`,
  PAR_FailedToPay: `Failed to pay. Check your card number.`,
  PAR_CardInvalid: `Card number is not valid`,
  PAR_CardUnknown: `Card type in not known`,
  PAR_CardMonthInvalid: `Month is invalid`,
  PAR_CardYearInvalid: `Year is invalid`,
  PAR_CardCVCInvalid: `CVV code is invalid`,
  PAR_FieldRequired: `This field is required`,
  PAR_Cancel: `Cancel`,
  PAR_Pay: `Pay`,
  PAR_PaymentMethod: `Payment Methods`,
  PAR_Cardnumber: `Card number`,
  PAR_MonthPlaceholder: `MM`,
  PAR_YearPlaceholder: `YY`,

  PFP_Header: `Hoof! Unfortunately an error has occurred`,
  PFP_Info: `And your order has NOT gone through. Please try again by clicking the button below. No money has been withdrawn from your account.`,
  PFP_TryAgain: `Try again`,

  REP_Header: `Thank you for your order,`,
  REP_Comment: `below you will find your receipt for your order.`,
  REP_DateRooms: `Travel date`,
  REP_Return: `Return to website`,
  REP_OrderId: `Your Order Number`,
  REP_ReservationId: `Order number`,
  REP_CustomerInfo: `Personal information`,

  REP_Name: `Name`,
  REP_PostCity: `ZipCode & City`,
  REP_Phone: `Phone`,
  REP_Address: `Address`,
  REP_Country: `Country`,
  REP_Email: `Email`,
  REP_Thanks: `Thank you for your order`,
  REP_ThanksInfo: `You will receive a confirmation shortly by mail.`,
  REP_ThanksPayment: `Your payment is complete`,

  LOA_Addons: `Loading add-ons`,
  LOA_CustomerInfo: `Loading customer information`,
  LOA_ActivatingCustomer: `Activating customer`,
  LOA_Translations: `Loading translations`,
  LOA_Configuration: `Loading configuration`,
  LOA_Availability: `Loading availability`,
  LOA_CreatingReservation: `Creating reservation`,
  LOA_HotelInformation: `Loading hotel information`,
  LOA_Countries: `Loading countries`,
  LOA_PreparingPaymnent: `Preparing payment`,
  LOA_ApplyingPromo: `Applying promotion code`,
  LOA_Rooms: `Loading rooms`,
  LOA_Rates: `Loading rates`,
  LOA_LoggingIn: `Logging in`,
  LOA_SendingInformation: `Sending information`,

  COM_Continue: `Continue`,
  COM_OK: `OK`,
  COM_Persons: `Persons`,
  COM_Guest_10: `Adults`,
  COM_Guest_9: `Teenagers`,
  COM_Guest_7: `Infants`,
  COM_Guest_11: `Seniors`,
  COM_Guest_8: `Children`,
  COM_Adult: `Adult`,
  COM_Child: `Child`,
  COM_Room: `Room`,
  COM_Cabin: `Cabin`,
  COM_House: `House`,
  COM_Unit: `Unit`,
  COM_Arrival: `Arrival`,
  COM_Departure: `Departure`,
  COM_Promocode: `Promocode`,
  COM_Rooms: `Rooms`,
  COM_AddOns: `Add-ons`,
  COM_TotalGuests: `Total guests`,
  COM_Price: `Price`,
  COM_TotalPrice: `Total price`,
  COM_TotalPriceWithTransactionId: `Total paid with transactionID`,
  COM_TotalReservedWithTransactionId: `Total reserved with transactionID`,
  COM_Close: `Close`,
  COM_Back: `Back`,
  COM_Error: `Info`,
  COM_Info: `Information`,
  COM_Confirm: `Confirm`,
  COM_Package: `Package`,
  COM_SoldOutError: `There is no available rooms or packages on this date`,
  COM_SearchNew: `New Search`,
  COM_TableReservation: `Table Reservation`,
  COM_TableNumberOfPersons: `Number of people at the table`,
  COM_Guests: `Guests`,
  COM_TableChooseProduct: `Choose date and products`,
  COM_TablePlaceGuests: `You need to place`,
  COM_TableChooseProducts: `Choose product(s)`,
  COM_TableNumberOfGuestsForProduct: `Number of guests for this products`,
  COM_TableBookingFlowAddProduct: `Do you wish to add a table reservation to your stay?`,
  COM_TableIfNotPressContinue: `If not press continue`,
  COM_AddToBasket: `Add to basket`,
  COM_AddedToBasket: `Added to basket`,
  COM_Type: `Type`,
  COM_SeatingSoldOut: `Nothing available at the moment`,
  COM_Main: `Home`,
  COM_Conference: `Conference`,
  COM_Quantity: `Quantity`,

  LFO_Email: `Email`,
  LFO_EmailRequried: `Email is required`,
  LFO_EmailInvalid: `Email is not valid`,
  LFO_Password: `Password`,
  LFO_PasswordRequried: `Password required`,
  LFO_ForgotPassword: `Can't access your account?`,
  LFO_Login: `Log in`,
  LFO_BackToLogin: `Back to Sign in`,
  LFO_Send: `Send`,
  LFO_MessageSend: `Message send!`,
  LFO_LoginError: `Wrong email or password`,
  LFO_RecoveryError: `Login information invalid`,

  SFO_ChooseHotel: `Our hotels`,
  SFO_ChooseHolidayResort: `Our Holiday Resorts`,
  SFO_ChooseCenter: `Our Centers`,
  SFO_ChooseDepartment: `Choose Department`,
  SFO_ParamsHeader: `Select date`,
  SFO_Guests: `Guests in room`,
  SFO_GuestsCabin: `Guests in Cabin`,
  SFO_GuestsHouse: `Guests in House`,
  SFO_GuestsUnit: `Guests in Unit`,
  SFO_AddRoom: `More rooms`,
  SFO_AddCabin: `More cabins`,
  SFO_AddHouse: `More houses`,
  SFO_AddUnit: `More units`,
  SFO_BookStay: `stay`,
  SFO_BookEvent: `Event`,
  SFO_BookTakeAway: `Take Away`,
  SFO_BookTableReservation: `Table Reservation`,
  SFO_BookGiftcard: `Giftcard`,
  SFO_GiftcardMetaData: `Meta data for your giftcard`,
  SFO_ChooseGiftcard: `Choose Giftcard`,
  SFO_NumberOfGiftcards: `How many of the selected giftcard do you want to buy?`,
  SFO_GiftcardAmount: `Enter amount`,
  SFO_GiftcardEnterDetails: `Enter details for you giftcards`,
  SFO_ChooseGiftcardDescription: `Buy a giftcard to someone you care about`,
  SFO_GiftcardDetails: `Giftcard details`,
  SFO_GiftcardTo: `Who is the giftcard for (optional)`,
  SFO_GiftcardFrom: `Who is the giftcard from (optional)`,
  SFO_GiftcardMessage: `Write a greeting (Max 100 characters) (optional)`,
  SFO_BookArrangement: `Arrangement`,
  SFO_BookConcert: `Concert`,
  SFO_BookTableArangement: `Table/arrangement`,
  SFO_BookTableEvent: `Table/Event`,
  SFO_BordTakeAway: `Table/Take Away`,
  SFO_BookTicket: `Ticket`,
  SFO_BookLecture: `Lecture`,
  SFO_BookTable: `Book Table`,
  SFO_BookSpa: `Spa`,
  SFO_BookExperience: `Experience`,
  SFO_ChooseMonth: `Choose Month`,
  SFO_NoRooms: `No rooms were found in the selected timespan!`,
  SFO_MinLOS: `The selected period requires a booking of a minimum of`,
  SFO_Nights: `nights`,
  SFO_MaxLOS: `During the selected period it is possible to book a maximum of`,

  CAL_WeekStart: `0`,
  CAL_M01: `January`,
  CAL_M02: `February`,
  CAL_M03: `March`,
  CAL_M04: `April`,
  CAL_M05: `May`,
  CAL_M06: `June`,
  CAL_M07: `July`,
  CAL_M08: `August`,
  CAL_M09: `September`,
  CAL_M10: `October`,
  CAL_M11: `November`,
  CAL_M12: `December`,
  CAL_D0: `Sunday`,
  CAL_D1: `Monday`,
  CAL_D2: `Tuesday`,
  CAL_D3: `Wednesday`,
  CAL_D4: `Thursday`,
  CAL_D5: `Friday`,
  CAL_D6: `Saturday`,
  CAL_Selected: `Selected`,
  CAL_Occupied: `Closed/Sold out`,
  CAL_Arrival: `Closed for arrival`,
  CAL_Departure: `Closed for departure`,
  CAL_Mon: `M`,
  CAL_Tirs: `T`,
  CAL_Ons: `W`,
  CAL_Tors: `T`,
  CAL_Fre: `F`,
  CAL_Lor: `S`,
  CAL_Sun: `S`,
  CAL_Available: `Available`,
  CAL_NotPossible: `Not possible`,

  BIN_ChangeDate: `Change date`,
  BIN_ChangeRoom: `Change`,
  BIN_ChangeAddOns: `Change add-ons`,
  BIN_NoAddOns: `No services added`,
  BIN_AddOns: `Services added`,
  BIN_Reservation: `Your Reservation`,
  BIN_Details: `Basic Details`,
  BIN_RoomCount: `Rooms`,
  BIN_CabinCount: `Cabins`,
  BIN_HouseCount: `Houses`,
  BIN_UnitCount: `Units`,
  BIN_Roomtype: `Room type`,
  BIN_Cabintype: `Cabin type`,
  BIN_Housetype: `House type`,
  BIN_Unittype: `Unit type`,
  BIN_Packagetype: `Package type`,
  BIN_BasketEmpty: `Basket is empty`,
  BIN_BasketGoToCheckout: `Go to checkout`,

  EXC_EmptyBasket: `Basket must not be empty`,

  SEA_Date: `Date`,
  SEA_Time: `Time`,
  SEA_Quantity: `Quantity`,
  SEA_Skip: `Skip`,
  SEA_ChooseEvent: `Choose Event`,
  SEA_DescriptionStart: `Select`,
  SEA_DescriptionEnd: `below or date in the calendar`,
  SEA_SoldOut: `Everything is sold out for the choosen month`,
  SEA_SoldOutContinue: `Click Continue to jump to the next step`,
  SEA_SeatingStandAloneTitle: `Select date of choose one of the displayed options`,
  SEA_ResetCalendar: `Show all`,

  MAT_CALENDAR_Calendar_Label: `S`,
  MAT_CALENDAR_Close_Calendar_Label: `S`,
  MAT_CALENDAR_End_Date_Label: `S`,
  MAT_CALENDAR_Next_Month_Label: `S`,
  MAT_CALENDAR_Next_Multi_Year_Label: `S`,
  MAT_CALENDAR_Next_Year_Label: `S`,
  MAT_CALENDAR_Open_Calendar_Label: `S`,
  MAT_CALENDAR_Prev_Month_Label: `S`,
  MAT_CALENDAR_Prev_Multi_Year_Label: `S`,
  MAT_CALENDAR_Prev_Year_Label: `S`,
  MAT_CALENDAR_Start_Date_Label: `S`,
  MAT_CALENDAR_Switch_To_Month_View_Label: `S`,
  MAT_CALENDAR_Switch_To_Multi_Year_View_Label: `S`,
};

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BasketQueryParams, BasketService } from '../core';
import { StorageClient } from '.';

@Injectable()
export class NavigationHelper {
  page = new StorageClient(sessionStorage, 'navigationArray', null as NavigationModel[] | null);

  nextPage = new StorageClient(sessionStorage, 'navigationNextPage', null as NavigationModel | null);

  searchParams = new StorageClient(sessionStorage, 'searchParams', null as BasketQueryParams | null);

  paramsSubscription: Subscription | undefined;

  constructor(private router: Router, private basketService: BasketService, private acRoute: ActivatedRoute) {}

  async continue(page: string, route: string, params?: any, storeSearchParams = false) {
    if (storeSearchParams) {
      this.searchParams.set(params as BasketQueryParams);
    }

    let prev = this.page.get();
    if (prev) {
      const foundIndex = prev.findIndex((x) => x.page === page);
      if (foundIndex === -1) {
        prev.push({ page, params } as NavigationModel);
      } else {
        prev[foundIndex] = {
          page,
          params,
        } as NavigationModel;
      }
    } else {
      prev = [] as NavigationModel[];
      prev.push({ page, params } as NavigationModel);
    }

    this.page.set(prev);

    if (params) {
      await this.router.navigate([route], params);
    } else {
      await this.router.navigate([route]);
    }
  }

  collectSearchParams() {
    return this.searchParams.get();
  }

  goToUrl(url: string) {
    if (url === '') url = '/booking';
    document.location.href = url;
  }

  async goBack(clearBasket = false) {
    if (clearBasket) {
      this.basketService.basketBadgeNumberSet = '0';
    }

    const navigate = this.page.get();

    if (navigate) {
      const obejct = navigate[navigate.length - 1];

      if (obejct) {
        if (obejct.params) {
          await this.router.navigate([obejct.page], obejct.params);
        } else {
          await this.router.navigate([obejct.page]);
        }
        navigate.splice(navigate.length - 1, 1);
        this.page.set(navigate);
      }
    }
  }

  reset() {
    this.page.clear();
  }

  async navigateToPage(page: string, params?: NavigationExtras) {
    await this.router.navigate([page], params);
  }

  checkUrlForParamater(param: string) {
    if (this.router.url.includes(param)) {
      return true;
    } else {
      return false;
    }
  }

  // Used for navationbar at bottom on mobile
  async continueMobileNavigationbar(navigate: boolean, prepare: boolean, page?: string, route?: string, params?: any) {
    if (navigate) {
      const nav = this.nextPage.get();
      if (nav) {
        await this.continue(nav.page, nav.nextMobilePage, nav.params);
      }
    } else if (prepare) {
      this.nextPage.set({
        page,
        nextMobilePage: route,
        params,
      } as NavigationModel);
    }
  }

  // Used for navigationbar at bottom on mobile
  resetNextPage() {
    this.nextPage.clear();
  }

  isNextpageEmpty() {
    const res = this.nextPage.get();

    if (res) {
      return false;
    } else {
      return true;
    }
  }

  getRouteParam(paramValue: string) {
    let requestedValue = '';

    this.paramsSubscription = this.acRoute.queryParams.subscribe((params) => {
      requestedValue = params[paramValue] as string;
    });

    this.paramsSubscription.unsubscribe();

    return requestedValue;
  }
}

interface NavigationModel {
  page: string;
  nextMobilePage: string;
  params: any;
}

import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { Guid, StorageClient } from 'src/app/helpers';
import { Customer, CustomerInfo, HotelType } from '../modules';
import { PromoCodeString } from '../modules/config';
import { GiftcardReservationPayment } from '../modules/hotel/data-hotel.service';
import { Locale } from '../modules/locale';
import {
  BookingInformation,
  BookingInformationGiftcard,
  PaymentFlowEnum,
  PaymentModel,
  ReservationService,
  ReservationType,
} from '../modules/payment';
import { ApplicationStateService } from './application-state.service';
import { Basket, BasketObj } from './basket.service';

@Injectable()
export class BookingService {
  private reciept = new StorageClient(sessionStorage, 'Reciept', null as Reciept | null);
  private reservation = new StorageClient(sessionStorage, 'Reservation', null as ReservationType | null);
  private usedGiftcard = new StorageClient(sessionStorage, 'usedGiftcard', null as GiftcardReservationPayment | null);
  bookingInformation = new StorageClient(sessionStorage, 'bookingInformation', null as BookingInformation | null);
  giftcardEasyInfo = new StorageClient(sessionStorage, 'giftcardEasyInfo', null as PaymentModel | null);

  constructor(
    private log: LogService,
    private reservationService: ReservationService,
    private applicationState: ApplicationStateService,
  ) {}

  getReciept() {
    const reciept = this.reciept.get();
    if (!reciept) {
      throw new Error(`Reciept must be defined`);
    }
    return reciept;
  }

  getReservationOrFail() {
    const reservation = this.reservation.get();
    if (!reservation) {
      throw new Error(`Reservation must be defined`);
    }
    return reservation;
  }

  async createReservation(
    locale: Locale,
    hotel: HotelType,
    promocode: PromoCodeString | undefined,
    customer: Customer,
    information: BookingInformation,
    needSubscribe: boolean,
    useCancelationInsurance: boolean,
    comment: string,
    isAgent: boolean,
    giftcardPayment: number,
  ) {
    this.log.debug(`BookingService createReservation(${locale}, ${hotel.Code}, ${promocode}, ${customer.Info.Email})`);
    this.reciept.set(null);
    const reservation = await this.reservationService.createReservation(
      locale,
      hotel,
      promocode,
      customer,
      information,
      needSubscribe,
      useCancelationInsurance,
      comment,
      isAgent,
      giftcardPayment,
    );
    this.reservation.set(reservation);
    return reservation;
  }

  updateSessionReservation(reservation: ReservationType) {
    this.reservation.set(reservation);
  }

  updateSessionGiftcard(giftcardInfo: GiftcardReservationPayment | null) {
    this.usedGiftcard.set(giftcardInfo);
  }

  getSessionGiftcard() {
    return this.usedGiftcard.get();
  }

  async getReservation(reservationId: string, locale: Locale) {
    const reservation = await this.reservationService.GetReservation(reservationId, locale);
    this.reservation.set(reservation);

    return reservation;
  }

  async CancelReservation(reservationId: string) {
    await this.reservationService.CancelReservation(reservationId);
  }

  async completePayment(reservation: ReservationType) {
    this.log.debug(`BookingService payment complete()`, reservation);
    const reciept: Reciept = {
      reservation,
    };
    this.reciept.set(reciept);
    this.reservation.set(null);
    return reciept;
  }

  async createGiftcardReservation(giftcards: BookingInformationGiftcard[] | undefined, customer: CustomerInfo) {
    if (giftcards && this.applicationState.UseNetAxept === true) {
      const res = await this.reservationService.PlaceGiftcardOrderAxept(giftcards, customer);
      return res.PaymentUrl;
    }

    if (giftcards && this.applicationState.UseNetsEasy === true) {
      const res = await this.reservationService.PlaceGiftcardOrderEasy(giftcards, customer);
      this.giftcardEasyInfo.set(res);
      return res;
    }

    this.log.fatal(
      'Trying to buy giftcard without any paymentplatform! Please specify a payment platform in Spectra to use Giftcard module!',
    );

    return undefined;
  }

  async RegisterGiftcardPayment(orderId: number, transactionId: Guid) {
    await this.reservationService.RegisterGiftcardPayment(orderId, transactionId);
  }

  async getGiftcardInformation(giftCardCode: string) {
    return await this.reservationService.GetGiftcardInformation(giftCardCode);
  }

  cancelPayment() {
    this.log.debug(`BookingService payment cancelPayment()`);
    this.reservation.set(null);
  }

  failPayment() {
    this.log.debug(`BookingService payment failPayment()`);
    this.reservation.set(null);
  }

  buildReservationTypeForGiftcard(basket: Basket, paidAmount: number, orderId: string) {
    return this.reservationService.BuildReservationTypeForGiftcard(basket, paidAmount, orderId);
  }

  async registerPayment(hotelCode: string, resGuid: Guid, paymentId: Guid, paymentFlow: PaymentFlowEnum) {
    this.log.debug(`Register payment on resGuid: ` + resGuid.toString());
    await this.reservationService.RegisterPayment(hotelCode, resGuid, paymentId, paymentFlow);
  }

  async sendNotification(
    reservation: BookingInformation | null,
    langCode: string,
    paymentEnabled = true,
    prepare = false,
  ) {
    this.log.debug(`Send notification for: `, reservation);
    const res = this.reservation.get();
    if (reservation && res) {
      await this.reservationService.SendNotification(
        reservation,
        res.resGuid!,
        langCode,
        paymentEnabled ? res.PaymentFlowToUse : 1,
        prepare,
      );
    }

    if (!prepare) {
      this.bookingInformation.set(null);
    }
  }
}

interface RecieptBase {
  id: string;
  customer: Customer;
  bookingCode: string;
  reservation: ReservationType;
  PaymentFlowToUse: PaymentFlowEnum;
  PaymentDue: number;
}

export interface RecieptObj extends RecieptBase {
  basketObj: BasketObj;
}

export interface Reciept {
  reservation: ReservationType;
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { LogService } from '@com/logging';
import {
  ApplicationStateService,
  BasketService,
  CustomerService,
  HotelType,
  LocaleService,
  SearchParams,
} from 'src/app/core';
import { HotelCodeString, HotelService, Loader } from 'src/app/core';
import { GuestCombination, StayAvailabilityResponse } from 'src/app/core/modules/hotel/data-hotel.service';
import { ConfigHandler, DateHelper, GuestType, RoomGuests, UTCDateTimeString } from 'src/app/helpers';
import {
  CalendarInputComponent,
  CalendarInputDateChangeEvent,
} from 'src/app/shared/components/calendar-input/calendar-input.component';
import { DesignConfiguration } from '../../shared/design/design-configuration';

export interface SearchFormConfig {
  single: boolean;
  hotels: HotelType[];
  hotelCode: HotelCodeString;
  arrival: Date;
  stay: number | null;
  rooms: RoomGuests[];
  maxNights: number;
  maxMonth: number;
  maxRooms: number;
  defaultStay: number;
  BookingFlow: number;
  RateCode: string;
}

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
})
export class SearchFormComponent implements SearchFormConfig, OnInit {
  @Input()
  set config(config: Partial<SearchFormConfig> | undefined) {
    if (config) {
      if (config.arrival === undefined) {
        this.arrivalNotSpecified = true;
        // this.continue = false;
      }
      const changes = ConfigHandler.Apply(this, config);
      if (changes.maxMonth) {
        this.lastAvailableDate = DateHelper.addMonth(this.firstAvailableDate, this.maxMonth);
      }
      if (changes.defaultStay && !changes.stay) {
        this.stay = this.defaultStay;
        changes.stay = true;
      }
      if (changes.arrival || changes.stay) {
        this.departure = this.stay ? DateHelper.addDays(this.arrival, this.stay) : null;
      }
      const now = DateHelper.today();
      if (this.arrival.getTime() < now.getTime()) {
        this.log.info(
          `SearchForm - Setting arrival to ${this.arrival} failed. Arrival is before now(${now}). Setting ${now}.`,
        );
        this.arrival = now;
      }
      if (changes.arrival && this.departure && this.arrival.getTime() >= this.departure.getTime()) {
        const departure = DateHelper.addDays(this.arrival, this.defaultStay);
        this.log.info(
          `SearchForm - Setting arrival to ${this.arrival} failed. Arrival is after departure(${this.departure}). Setting departure to ${departure}.`,
        );
        this.departure = departure;
      }
      if (changes.stay && this.departure && this.arrival.getTime() >= this.departure.getTime()) {
        const arrival = DateHelper.addDays(this.departure, -this.defaultStay);
        this.log.info(
          `SearchForm - Setting departure to ${this.departure} failed. Departure is before arrival(${this.arrival}). Setting arrival to ${arrival}.`,
        );
        this.arrival = arrival;
      }
      if (changes.hotels || changes.hotelCode || changes.rooms) {
        let hotel = this.hotels.find((x) => x.Code === this.hotelCode);

        if (!hotel && this.hotels.length > 0) {
          hotel = this.hotels[0];
        }
        if (hotel !== this.hotel) {
          this.hotel = hotel;
          this.hotelCode = this.hotel ? this.hotel.Code : ('' as any as HotelCodeString);
          this.hotelChange.emit(this.hotel);
        }

        this.rooms = SearchFormComponent.FilterRoomsGuests(this.rooms, this.hotel);

        if (this.rooms === undefined || this.applicationState.ReturnFromOnlySeating === 'true') {
          this.rooms = [new RoomGuests([2, 0, 0, 0, 0, 0])];
        }
      }

      this.MaxRoomsReached();
    }
    void this.modifyToFitTab();

    void this.designconfig.addPageBackground();
  }

  @Output() submitted = new EventEmitter<SearchParams>();
  @Output() hotelChange = new EventEmitter<HotelType | undefined>();
  @ViewChild('departureCalendar', { static: false }) departureCalendar: CalendarInputComponent | undefined;
  @ViewChild('departureCalendarMobile', { static: false })
  departureCalendarMobile: CalendarInputComponent | undefined;
  single = true;
  hotels = [] as HotelType[];
  hotelCode = '' as any as HotelCodeString;
  arrival = DateHelper.today();
  defaultStay = 0;
  stay = this.defaultStay as number | null;
  departure = this.stay ? DateHelper.addDays(this.arrival, this.stay) : null;
  rooms = [new RoomGuests([2, 0, 0, 0, 0, 0])];
  maxNights = 2;
  maxMonth = 12;
  maxRooms = 1;
  maxRoomsReached = false;
  adultGuestType = GuestType.Adult;
  adultCountValues = [] as number[];
  guestCountValues = [] as number[];
  firstAvailableDate = DateHelper.today();
  lastAvailableDate = DateHelper.addMonth(this.firstAvailableDate, this.maxMonth);
  hotel: HotelType | undefined;
  BookingFlow = 1;
  RateCode = '';
  continue = true;
  showMessage = false;
  messageType = 'noneRooms';
  restrictionDays = '0';
  dateSpan = '';
  arrivalNotSpecified = true;
  logo = '';
  loadingdData = false;
  isArrivalDate = true;
  lastTabArrival = new Date();
  deeplinkRateCode: string | undefined;
  designPack = 'Default';

  mobile = {
    currentIndex: 0 as number | undefined,
  };

  available = [] as StayAvailabilityResponse[];
  spectraAvailability: StayAvailabilityResponse | undefined;
  guestToolTip = [] as GuestDescriptionUI[];

  constructor(
    private log: LogService,
    private customerService: CustomerService,
    private localeService: LocaleService,
    private loader: Loader,
    private basketService: BasketService,
    private activatedRoute: ActivatedRoute,
    private designconfig: DesignConfiguration,
    private hotelService: HotelService,
    public applicationState: ApplicationStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), 0)); // wait for router to initialize

    if (this.config && this.config.arrival) {
      this.arrival = this.config.arrival;
    }

    if (this.hotelCode === ('' as HotelCodeString) && this.applicationState.CurrentHotelCode) {
      this.hotelCode = this.applicationState.CurrentHotelCode;
    }

    await this.buildCalendarData(this.arrival, true, false);

    this.basketService.basketBadgeNumberSet = '0';
    this.buildAQCArray();
    this.logo = this.applicationState.ApplicationLogo;
  }

  buildAQCArray() {
    const number = Number(this.applicationState.MaxAQC);
    let i = 0;

    while (i <= number) {
      this.guestCountValues.push(i);
      this.adultCountValues.push(i);
      i++;
    }
    this.adultCountValues.splice(0, 1);
  }

  async buildCalendarData(startMonth: Date, useloader: boolean, resetAvailability: boolean) {
    if (resetAvailability === true) {
      this.available = [];
    }

    this.loadingdData = true;

    let stay = this.stay !== null && this.stay !== 0 ? this.stay : 2;
    const depart = DateHelper.addMonth(startMonth, 3);
    let result = [] as StayAvailabilityResponse[];
    // If the site should load from scratch, we show loadscreen and push the resultdates to a array, and show it. The months we load depends if Arrivaldate is given in the url
    if (useloader) {
      let departure = depart;
      let arrivalDate = startMonth;
      // If ArrivalDate is defined in url
      this.activatedRoute.queryParams.subscribe((params) => {
        const arrival = params['ArrivalDate'];
        const rateCode = params['RateCode'];
        const lengthOfStay = params['LengthOfStay'];
        if (lengthOfStay) {
          stay = lengthOfStay;
        }
        this.deeplinkRateCode = rateCode;
        if (arrival) {
          this.arrivalNotSpecified = false;
          const toDate = DateHelper.ConvertToUTCDate(arrival);
          arrivalDate = toDate;
          departure = DateHelper.addMonth(arrivalDate, 3);
        }
      });

      result = await this.loadAvailability(
        this.hotelCode,
        DateHelper.addMonth(arrivalDate, -1),
        departure,
        stay,
        this.rooms,
        undefined,
        useloader,
        this.deeplinkRateCode,
      );
    }
    // If the site is allready loaded, and the next button in the calendar is clicked. We load the next couple of month
    else {
      const existsEnd = this.available.find(
        (x) =>
          DateHelper.FromUtcDate(x.Date.toString() as UTCDateTimeString).getMonth() === depart.getMonth() &&
          DateHelper.FromUtcDate(x.Date.toString() as UTCDateTimeString).getFullYear() === depart.getFullYear(),
      );
      const existsStart = this.available.find(
        (x) => DateHelper.FromUtcDate(x.Date.toString() as UTCDateTimeString).getMonth() === startMonth.getMonth() - 1,
      );
      if (existsEnd === undefined) {
        result = await this.loadAvailability(
          this.hotelCode,
          startMonth,
          depart,
          stay,
          this.rooms,
          undefined,
          useloader,
          this.deeplinkRateCode,
        );
      }
      // If the site is allready loaded, and the prev button in the calendar is clicked. We load the prev couple of month
      else if (existsStart === undefined) {
        result = await this.loadAvailability(
          this.hotelCode,
          DateHelper.addMonth(startMonth, -1),
          DateHelper.addMonth(depart, -1),
          stay,
          this.rooms,
          undefined,
          useloader,
          this.deeplinkRateCode,
        );
      }
    }

    for (const item of result) {
      if (!(this.available.indexOf(item) > -1)) {
        this.available.push(item);
      }
    }

    for (const item of result) {
      if (!(this.available.indexOf(item) > -1)) {
        this.available.push(item);
      }
    }
    await this.checkForAvailability();

    this.loadingdData = false;
  }

  async modifyToFitTab() {
    if (this.stay !== null && this.stay > 25) {
      this.stay = this.defaultStay;
      this.arrival = this.lastTabArrival;
    }

    if (this.stay === null) {
      this.stay = this.defaultStay;
    }
    this.departure = DateHelper.addDays(this.arrival, this.stay);
    this.applicationState.DepartureDate = this.departure;
  }

  async monthChange(month: Date) {
    await this.buildCalendarData(month, false, false);
  }

  async loadHotels() {
    return await this.loader.using(
      async () => {
        try {
          return await this.hotelService.GetECommerceDepartmentsInformation();
        } catch (err: any) {
          this.log.error(`SearchRoute failed to load hotels. ${err.message}`);
          throw err;
        }
      },
      'LOA_HotelInformation',
      false,
    );
  }

  async onAddRoomClick() {
    this.log.debug('SearchForm onAddRoomClick()');

    let guests = new RoomGuests();

    if (this.rooms.length > 0) {
      const guestCountNumber = this.rooms[0].get(GuestType.Adult);
      guests = new RoomGuests([guestCountNumber, 0]);
    } else {
      guests = new RoomGuests([2, 0]);
    }

    this.mobile.currentIndex = this.rooms.push(guests) - 1;
    this.MaxRoomsReached();
    await this.checkForAvailability();
  }

  MaxRoomsReached() {
    if (
      this.hotel !== undefined &&
      this.hotel !== null &&
      this.hotel.Settings.BookMaxRooms !== undefined &&
      this.hotel.Settings.BookMaxRooms !== null
    ) {
      this.maxRooms = this.hotel.Settings.BookMaxRooms - 1;
    } else {
      this.maxRooms = 1;
    }
    if (this.rooms.length > this.maxRooms) {
      this.maxRoomsReached = true;
    } else {
      this.maxRoomsReached = false;
    }
  }

  async onRemovePersonClick(guest: RoomGuests) {
    this.log.debug(`SearchForm onRemovePersonClick(${guest})`);
    this.rooms = this.rooms.filter((g) => g !== guest);
    this.mobile.currentIndex = this.rooms.length - 1;
    this.MaxRoomsReached();
    await this.checkForAvailability();
  }

  async onGuestQuantityChange(index: number, type: GuestType, value: number, values: number[]) {
    this.log.debug(`SearchForm onGuestQuantityChange(${index}, ${type}, ${value}, ${values})`);
    if (values.indexOf(value) !== -1) {
      this.rooms[index] = new RoomGuests(this.rooms[index].asArray());
      this.rooms[index].set(type, value);
    } else {
      this.log.debug('SearchForm onGuestQuantityChange(). Unsupported value selected.');
    }
    await this.checkForAvailability();
  }

  async onHotelChange(hotelCode: HotelCodeString) {
    this.log.debug(`SearchForm onHotelChange(${hotelCode})`);
    this.config = { hotelCode };

    this.stay = this.stay !== null ? this.stay : 2;

    this.applicationState.DepartureDate = DateHelper.addDays(this.arrival, this.stay);

    await this.buildCalendarData(this.arrival, true, true);
    this.designconfig.applyTheme().then().catch(undefined);
    this.logo = this.applicationState.ApplicationLogo;
    this.buildAQCArray();
  }

  async onArrivalChange(e: CalendarInputDateChangeEvent) {
    this.isArrivalDate = false;
    this.log.debug(`SearchForm onArrivalChange(${JSON.stringify(e)})`);
    const arrival = e.date || new Date();
    this.stay = this.stay !== null ? this.stay : 2;
    this.lastTabArrival = arrival;

    this.config = { arrival };
    if (this.departureCalendar) {
      this.departureCalendar.showCalendar();
    }
    if (this.departureCalendarMobile) {
      this.departureCalendarMobile.showCalendar();
    }
    this.applicationState.DepartureDate = DateHelper.addDays(arrival, this.stay);

    this.arrivalNotSpecified = false;
    await this.checkForAvailability();
    this.isArrivalDate = false;
  }

  async onDepartureChange(e: CalendarInputDateChangeEvent) {
    this.log.debug(`SearchForm onDepartureChange(${JSON.stringify(e)})`);
    const departure = e.date;

    let stay = departure ? DateHelper.daysBetween(this.arrival, departure, 0) : null;

    const res = this.arrival.getTimezoneOffset();
    // Check if departure is spanding from summertime to wintertime and back.
    if (stay && departure && departure.getTimezoneOffset() > res) {
      this.stay = stay - 1;
      stay = this.stay;
    } else {
      this.stay = stay;
      stay = this.stay;
    }

    this.config = { stay };
    this.arrivalNotSpecified = false;
    await this.checkForAvailability();

    if (this.departureCalendar) {
      this.departureCalendar.closeCalendar();
    }
    if (this.departureCalendarMobile) {
      this.departureCalendarMobile.closeCalendar();
    }
    this.isArrivalDate = true;
  }

  async onFormSubmit(e: Event) {
    this.log.debug('SearchForm onFormSubmit()');

    e.preventDefault();
    e.stopPropagation();
    if (this.hotel) {
      this.submitted.next({
        hotelCode: this.hotel.Code,
        arrival: this.arrival,
        stay: this.stay || 1,
        rooms: this.rooms,
        single: this.single,
        ArrivalDate: 0,
        ArrivalFullYear: 0,
        ArrivalMonthName: '',
        DepartueDate: 0,
        DepartueFullYear: 0,
        DepartueMonthName: '',
        BookingFlow: this.BookingFlow,
        RateCode: this.RateCode,
        ConferenceRoomCode: '',
      });
    }

    this.basketService.hideBasket();
  }

  private async loadAvailability(
    hotelCode: HotelCodeString,
    start: Date,
    end: Date,
    stay: number,
    rooms: RoomGuests[],
    customerId: number | undefined,
    useLoader: boolean,
    rateCode: string | undefined,
  ) {
    this.log.debug(
      `CalendarRoute loadAvailability(${hotelCode}, ${start}, ${end}, ${stay}, ${rooms}, ${customerId}, ${rateCode})`,
    );

    let guestCount = 0;

    rooms.forEach((x) => {
      guestCount = guestCount + x.getPersons();
    });

    const company = this.customerService.getCustomer().Company;

    if (useLoader) {
      return await this.loader.using(
        async () => {
          try {
            const res = await this.hotelService.GetRoomAvailability(
              hotelCode,
              start,
              end,
              rateCode,
              company ? company.Id : undefined,
            );
            return res;
          } catch (err) {
            return [] as StayAvailabilityResponse[];
          }
        },
        'LOA_Availability',
        false,
      );
    } else {
      try {
        const res = await this.hotelService.GetRoomAvailability(
          hotelCode,
          start,
          end,
          rateCode,
          company ? company.Id : undefined,
        );
        return res;
      } catch (err) {
        return [] as StayAvailabilityResponse[];
      }
    }
  }

  private async checkForAvailability() {
    if (this.hotelCode && this.hotelCode && this.applicationState.DepartureDate) {
      const guests = [] as GuestCombination[];

      for (const item of this.rooms) {
        const guest = {
          AdultCount: item.get(GuestType.Adult),
          ChildCount: item.get(GuestType.Child),
          InfantCount: item.get(GuestType.Infant),
          SeniorCount: item.get(GuestType.Senior),
          TeenagerCount: item.get(GuestType.UnknownType1),
        } as GuestCombination;

        guests.push(guest);
      }

      const company = this.customerService.getCustomer().Company;

      const availableRoomsAndRates = await this.hotelService.GetSpectraRoomInfo(
        this.applicationState.CurrentHotelCode,
        this.arrival,
        this.applicationState.DepartureDate,
        this.RateCode,
        guests,
        await this.localeService.getLocale(),
        company ? company.Id : undefined,
      );

      this.continue = availableRoomsAndRates.length !== 0;
      if (!this.arrivalNotSpecified) {
        this.showMessage = availableRoomsAndRates.length === 0;
      }
    }
  }

  private static FilterRoomsGuests(roomGuestsList: RoomGuests[], hotel: HotelType | undefined) {
    return hotel
      ? roomGuestsList.map((roomGuests) => {
          const newRoomGuests = new RoomGuests();
          hotel.Aqcs.forEach((aqc) => newRoomGuests.set(aqc.AQC, roomGuests.get(aqc.AQC)));
          return newRoomGuests;
        })
      : roomGuestsList;
  }
}

export interface GuestDescriptionUI {
  guestType: string;
  ageFromTo: string;
  translation: string;
}

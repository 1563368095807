import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogClient, LogEntry, LogLevel } from '@com/logging';
import dayjs from 'dayjs';
import { ActionStack, GuidHelper, StorageClient } from 'src/app/helpers';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from '../modules';

export enum SpectraLogLevel {
  Debug = 2048,
  Trace = 1024,
  Info = 256,
  Notice = 128,
  Warn = 64,
  Error = 16,
  Fatal = 4,
}

@Injectable()
export class ServerLogClientService implements LogClient {
  private static ClientLogSize = 200;
  private sessionId = new StorageClient(sessionStorage, 'Log-session-id', GuidHelper.NewGuid());
  private clientLog: LogEntry[] = [];
  private allowedLogLevel: LogLevel;
  private logLevel = LogLevel.Error;
  private minLogLevel = LogLevel.Debug;
  private stack = new ActionStack<void>(undefined);

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.allowedLogLevel = Math.min(this.minLogLevel, this.logLevel);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      this.setLogLevel((await this.configService.getLoggingParams()).LogLevel);
    })();
  }

  // used for testing
  clear() {
    this.sessionId.set(GuidHelper.NewGuid());
    this.clientLog = [];
  }

  log(entry: LogEntry) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.stack.push(async () => {
      if (entry.level >= this.allowedLogLevel) {
        await this.addEntry(entry);
      }
    });
  }

  private setLogLevel(logLevel: SpectraLogLevel) {
    this.logLevel = ServerLogClientService.FromSpectraLogLevel(logLevel);
    this.allowedLogLevel = Math.min(this.minLogLevel, this.logLevel);
  }

  private async addEntry(entry: LogEntry) {
    const entries = this.clientLog;
    this.clientLog = [...entries.slice(entries.length - ServerLogClientService.ClientLogSize), entry];

    if (entry.level >= this.logLevel) {
      await this.publish();
    }
  }

  // stored logs will be cleared after each successful publish
  private async publish() {
    try {
      const appName = location.host.substr(0, 4) === 'www.' ? location.host.substr(4) : location.host;
      const config = await this.configService.getLoggingParams();
      const eventLog = this.clientLog;
      const lastEvent = eventLog[eventLog.length - 1];
      const sessionId = this.sessionId.get();
      const n: Navigator & { cpuClass?: string } = navigator;
      await lastValueFrom(
        this.http.post(
          config.LoggingServiceUrl,
          new HttpParams()
            .set('name', `Spectra Online Booking - ${sessionId}`)
            .set('title', lastEvent.msg)
            .set('level', ServerLogClientService.ToSpectraLogLevel(lastEvent.level).toString())
            .set('sessionId', sessionId)
            .set('appName', appName)
            .set('spc_codeName', n.appCodeName)
            .set('spc_appName', n.appName)
            .set('spc_appVersion', n.appVersion)
            .set('spc_language', n.language)
            .set('spc_platform', n.platform)
            .set('spc_userAgent', n.userAgent)
            .set('spc_javaEnabled', n.javaEnabled().toString())
            .set('spc_cookieEnabled', n.cookieEnabled.toString())
            .set('spc_cpuClass', n.cpuClass || '')
            .set('spc_onLine', n.onLine.toString())
            .set(
              'spc_eventLog',
              JSON.stringify(
                eventLog.map((item) => {
                  const msg = `${dayjs(item.date).format('YYYY-MM-DD HH:mm:ssZZ')} [${LogLevel[item.level]}] ${
                    item.msg
                  }`;
                  return item.data.length === 0 ? msg : { [msg]: item.data };
                }),
              ),
            )
            .toString(),
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }),
            responseType: 'text',
          },
        ),
      );
      this.clientLog = [];
    } catch (err) {
      console.log(err);
    }
  }

  private static ToSpectraLogLevel(level: LogLevel) {
    // Spectra Debug log level does not match with standart Debug log level. It is more like Trace, so Debug and Trace are
    switch (level) {
      case LogLevel.Trace:
        return SpectraLogLevel.Debug;
      case LogLevel.Debug:
        return SpectraLogLevel.Trace;
      case LogLevel.Information:
        return SpectraLogLevel.Notice;
      case LogLevel.Warning:
        return SpectraLogLevel.Warn;
      case LogLevel.Error:
        return SpectraLogLevel.Error;
      case LogLevel.Critical:
        return SpectraLogLevel.Fatal;
    }
    return SpectraLogLevel.Trace;
  }

  private static FromSpectraLogLevel(level: SpectraLogLevel) {
    // Spectra Debug log level does not match with standart Debug log level. It is more like Trace, so Debug and Trace log levels are swapped
    switch (level) {
      case SpectraLogLevel.Trace:
        return LogLevel.Debug;
      case SpectraLogLevel.Debug:
        return LogLevel.Trace;
      case SpectraLogLevel.Notice:
      case SpectraLogLevel.Info:
        return LogLevel.Information;
      case SpectraLogLevel.Warn:
        return LogLevel.Warning;
      case SpectraLogLevel.Error:
        return LogLevel.Error;
      case SpectraLogLevel.Fatal:
        return LogLevel.Critical;
    }
    return LogLevel.Trace;
  }
}

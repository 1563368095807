import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { /* SpectraDate,*/ DateHelper } from 'src/app/helpers';
import { ConfigService } from '../config';
import { HotelService } from '../hotel';
import { ReservationType } from '../payment';
import { TrackingClient } from './tracking-client.interface';

@Injectable()
export class GoogleHotelAdsClientService implements TrackingClient {
  constructor(private log: LogService, private hotelService: HotelService) {}

  // See for more info.: https://spectra-systems.visualstudio.com/Spectra/_wiki/wikis/Spectra.wiki?wikiVersion=GBwikiMaster&pagePath=%2FOnline%20Booking%2FFeatures%2FGoogle%20Hotel%20Ads
  async revenue(reservation: ReservationType) {
    if (reservation.rooms.length !== 0) {
      const hotelCode = reservation.rooms[0].hotelCode;
      const hotel = await this.hotelService.GetECommerceDepartmentInformation(hotelCode, reservation.lang);
      if (hotel.Settings.IsGoogleHotelAdsEnabled) {
        const GoogleHotelAdsId = hotel.Settings.GoogleHotelAdsId;
        if (GoogleHotelAdsId) {
          const axel = Math.random();
          const random = axel * 1000000000000000000;
          const arrivalDate = DateHelper.FromAspDate(reservation.rooms[0].arrival);
          const departureDate = DateHelper.FromAspDate(reservation.rooms[0].departure);

          const arrivalDateString = DateHelper.format(arrivalDate, 'YYYY-MM-DD'); // .toString();
          const departureDateString = DateHelper.format(departureDate, 'YYYY-MM-DD'); // departureDate.toString();

          //   const arrivalDateTime = arrivalDate;.getTime();
          // const departureDateTime = departureDate.getTime();
          const lengthOfStay = DateHelper.daysBetween(departureDate, arrivalDate, 0); /* Math.ceil(
          Math.abs(departureDateTime - arrivalDateTime) / (1000 * 3600 * 24),
        );*/

          // const imageSourceString =
          //  'https://www.googletraveladservices.com/travel/clk/pagead/conversion/' +
          //  GoogleHotelAdsId +
          //  '/?label=HPA&guid=ON&script=0' +
          //  `&ord=${random}&data=hct_partner_hotel_id%3D` +
          //  GoogleHotelAdsId +
          //  // + "%3Bhct_base_price%3D$$base_price$$" Required for CPA strategy, but as we cannot provide prices before taxes, this wont be possible.
          //  `%3Bhct_total_price%3D${reservation.total}` +
          //  `%3Bhct_currency_code%3D${reservation.payment.currency}` +
          //  `%3Bhct_checkin_date%3D${arrivalDateString}` +
          //  `%3Bhct_checkout_date%3D${departureDateString}` +
          //  `%3Bhct_length_of_stay%3D${lengthOfStay}` +
          //  '%3Bhct_date_format%3D' +
          //  'yy-mm-dd' +
          //  // + "%3Bhct_booking_xref%3D$$booking_xref$$" this requires obfuscation or encryption to use. (Google)
          //  '%3Bhct_ver%3D1.0.j';

          const imageSourceString =
            'https://www.googletraveladservices.com/travel/clk/pagead/conversion/' +
            GoogleHotelAdsId +
            '/?label=HPA&guid=ON&script=0' +
            `&ord=${random}&data=hct_partner_hotel_id%3D` +
            GoogleHotelAdsId +
            // + "%3Bhct_base_price%3D$$base_price$$" Required for CPA strategy, but as we cannot provide prices before taxes, this wont be possible.
            `%3Bhct_total_price%3D${reservation.total}` +
            `%3Bhct_currency_code%3D${reservation.payment.currency}` +
            `%3Bhct_checkin_date%3D${arrivalDateString}` +
            `%3Bhct_checkout_date%3D${departureDateString}` +
            `%3Bhct_length_of_stay%3D${lengthOfStay}` +
            '%3Bhct_date_format%3D' +
            '%Y-%m-%d' +
            // + "%3Bhct_booking_xref%3D$$booking_xref$$" this requires obfuscation or encryption to use. (Google)
            '%3Bhct_ver%3D1.0.i';

          ConfigService.AddPixel(imageSourceString);
          this.log.debug(`GoogleHotelAdsService revenue() with url: ${imageSourceString}`);
        } else {
          // throw new Error(`Google Hotel Ads is is not set but usage is enabled`)
          this.log.info(`Google Hotel Ads for ${hotel.Code} is is not set but usage was enabled`);
        }
      } else {
        this.log.debug('GoogleHotelAdsService revenue() usage of Google Hotel Ads is disabled in BOW');
      }
    }
  }
}

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ConfigService } from './config.service';
import { DataConfigService } from './data-config.service';
// import { MockDataConfigService } from "./mock-data-config";

@NgModule({
  imports: [],
  declarations: [],
  providers: [{ provide: DataConfigService, useClass: DataConfigService }, ConfigService],
  exports: [],
})
export class ConfigModule {
  constructor(@Optional() @SkipSelf() parentModule: ConfigModule) {
    if (parentModule) {
      throw new Error('ConfigModule is already loaded. Import it in the CoreModule only');
    }
  }
}

import { messages } from './base';

export enum Locale {
  GB = 'en-GB',
  DK = 'da-DK',
  SE = 'sv-SE',
  DE = 'de-DE',
  NO = 'nn-NO',
  FO = 'fo-FO',
  GL = 'kl-GL',
  IS = 'is-IS',
}

export const SupportedLocales = [
  Locale.GB,
  Locale.DK,
  Locale.SE,
  Locale.DE,
  Locale.NO,
  Locale.FO,
  Locale.GL,
  Locale.IS,
];

const IsoLocaleMap: { [K in Locale]: { locale: string } } = {
  [Locale.GB]: { locale: 'en-gb' },
  [Locale.DK]: { locale: 'da' },
  [Locale.SE]: { locale: 'sv' },
  [Locale.DE]: { locale: 'de' },
  [Locale.NO]: { locale: 'nb' },
  [Locale.FO]: { locale: 'da' },
  [Locale.GL]: { locale: 'da' },
  [Locale.IS]: { locale: 'is' },
};

export const getIsoFromLocale = (locale: Locale) => {
  return IsoLocaleMap[locale].locale;
};

export type Messages = typeof messages;
export type Message = keyof Messages;

import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { LogClient, LogEntry, LogLevel } from './log-client.interface';

@Injectable()
export class AdvancedConsoleLogClientService implements LogClient {
  private isNextFrame = false;
  private frameIndex = 0;
  private logLevel = LogLevel.Debug;
  private showStack = false;

  log(entry: LogEntry) {
    if (entry.level >= this.logLevel) {
      this.renderFrame();
      const stack = this.getStack();
      const msg = `Frame[${this.frameIndex}] [${LogLevel[entry.level]}] ${dayjs(entry.date).format(
        'YYYY-MM-DD HH:mm:ss',
      )} ${entry.msg} ${entry.data.map((item) => JSON.stringify(item, null, 2)).join('\n')} ${
        this.showStack ? `\n${stack.join('\n')}` : `\n  at ${(stack[0] || '').replace(/.*\(/, '(')}`
      }`;
      switch (entry.level) {
        case LogLevel.Critical:
        case LogLevel.Error:
          // eslint-disable-next-line no-console
          console.error(msg);
          break;
        case LogLevel.Warning:
          // eslint-disable-next-line no-console
          console.warn(msg);
          break;
        case LogLevel.Information:
          // eslint-disable-next-line no-console
          console.info(msg);
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(msg);
      }
    }
  }

  private getStack() {
    return (new Error().stack || '').split('\n').slice(7);
  }

  private renderFrame() {
    if (!this.isNextFrame) {
      this.frameIndex++;
      // eslint-disable-next-line no-console
      // console.log(`Frame[${this.frameIndex}] Started:`);
      this.isNextFrame = true;
      setTimeout(() => {
        this.isNextFrame = false;
        // eslint-disable-next-line no-console
        // console.log(`Frame[${this.frameIndex}] Ended.`);
      }, 0);
    }
  }
}

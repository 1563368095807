import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { ConsoleLogClientService, LoggingModule, LogService } from '@com/logging';
import { CountryModule } from 'src/app/modules/country';
import { ConfigModule } from './modules/config/config.module';
import { CustomerModule } from './modules/customer/customer.module';
import { HotelModule } from './modules/hotel/hotel.module';
import { LocaleModule } from './modules/locale/locale.module';
import { PaymentModule } from './modules/payment/payment.module';
import { PromotionModule } from './modules/promotion/promotion.module';
import { TrackingModule } from './modules/tracking/tracking.module';
import { ApplicationStateService, BasketService, BookingService, Loader, ServerLogClientService } from './services';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule,
    CountryModule,
    HotelModule,
    LoggingModule,
    LocaleModule,
    PaymentModule,
    PromotionModule,
    CustomerModule,
    TrackingModule,
  ],
  providers: [
    ApplicationStateService,
    BasketService,
    BookingService,
    ConsoleLogClientService,
    ServerLogClientService,
    Loader,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    logService: LogService,
    consoleLogClientService: ConsoleLogClientService,
    serverLogClientService: ServerLogClientService,
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
    logService.addClient(consoleLogClientService);
    logService.addClient(serverLogClientService);
  }
}

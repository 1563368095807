import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationHelper } from '../../../helpers/navigation-helper';

@Component({
  selector: 'app-conference-rooms',
  templateUrl: './conference-rooms.component.html',
})
export class ConferenceRoomsComponent implements OnInit, OnDestroy {
  data: ConferenceUIModel[] | undefined;
  rateData: ConferenceRateUIModel[] | undefined;
  selectedConference: ConferenceUIModel | undefined;

  constructor(private navigationHelper: NavigationHelper) {}

  async ngOnInit() {
    document.body.classList.remove('addBackGround');
    document.body.classList.remove('addBackGroundWithAnimation');
    document.body.style.backgroundImage = '';
    await this.prepareNavigation();
    this.buildConferenceUIModel();
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async prepareNavigation() {
    // await this.navigationHelper.continueMobileNavigationbar(false, true, '/guests', '/products', { queryParams: { HotelCode: this.applicationState.CurrentHotelCode, ArrivalDate: DateHelper.toServerDateFormatString(new Date()), LengthOfStay: 30, guest: this.guestCounter } });
  }

  buildConferenceUIModel() {
    this.data = [] as ConferenceUIModel[];
    const galone = {} as GalleryModel;

    this.data.push({
      FromPrice: 4995,
      Name: 'Konference lokale A',
      Gallery: galone,
      Image: 'https://www.icgconstruction.com/sites/www.icgconstruction.com/files/assets/1484_06.jpg',
      Selected: false,
      MaxPers: 40,
    } as ConferenceUIModel);
    this.data.push({
      FromPrice: 1995,
      Name: 'Konference lokale B',
      Gallery: galone,
      Image: 'https://fasolutions.com/wp-content/uploads/2015/09/conference-room-768441_1920-e1443101700466.jpg',
      Selected: false,
      MaxPers: 10,
    } as ConferenceUIModel);
    this.data.push({
      FromPrice: 995,
      Name: 'Konference lokale C',
      Gallery: galone,
      Image:
        'https://d24z4d3zypmncx.cloudfront.net/BlogPosts/basic-conference-room-design-a-webinar/images/basic-conference-room-design-a-webinar_header.png',
      Selected: false,
      MaxPers: 5,
    } as ConferenceUIModel);
    this.data.push({
      FromPrice: 7955,
      Name: 'Konference lokale D',
      Gallery: galone,
      Image: 'https://airexperience.dk/wp-content/uploads/2020/01/conference-room-indoor-skydiving.jpeg',
      Selected: false,
      MaxPers: 100,
    } as ConferenceUIModel);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRoomClick() {}

  async goBack() {
    await this.navigationHelper.goBack();
  }

  async onMoveToNextClick() {
    await this.navigationHelper.continue('/conference-rooms', '/conference-rates');
  }

  buildConferenceRateUIModel() {
    this.rateData = [] as ConferenceRateUIModel[];
    const galone = {} as GalleryModel;

    this.rateData.push({
      Price: 4995,
      Name: 'Morgen mad',
      Gallery: galone,
      Image: 'https://food-fan.dk/wp-content/uploads/2018/09/4-nemme-opskrifter.jpg',
      Selected: false,
      Description: 'Forkæl jer selv med en overdådig morgen buffet til deres konference',
    } as ConferenceRateUIModel);
    this.rateData.push({
      Price: 1995,
      Name: 'Eftermiddageskaffe med kage',
      Gallery: galone,
      Image: 'https://rockpaperdresses.dk/wp-content/uploads/2017/05/sonderjyskkaffebord15-1.jpg',
      Selected: false,
      Description: 'Få serveret kaffe og hjemmebagt lagkage om eftermiddagen',
    } as ConferenceRateUIModel);
    this.rateData.push({
      Price: 1995,
      Name: 'Stor frokost buffet',
      Gallery: galone,
      Image: 'https://buffetkompagniet.dk/wp-content/uploads/2020/04/buffet.jpg',
      Selected: false,
      Description: 'Konference med den helt store frokost buffet, lavet af vores kendte kok',
    } as ConferenceRateUIModel);
  }
}

interface ConferenceUIModel {
  Name: string;
  FromPrice: number;
  Image: string;
  Selected: boolean;
  MaxPers: number;
}

interface ConferenceRateUIModel {
  Name: string;
  Price: number;
  Image: string;
  Selected: boolean;
  Description: string;
}

interface GalleryModel {
  accept: () => void;
  close: () => void;
  nextImage: (image: string) => void;
  prevImage: (image: string) => void;
  selectedImage: string;
  imageArray: string[];
}

import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LogService } from '@com/logging';
import { Step, StepList } from 'src/app/modules/step-list';

import { ApplicationStateService, BasketService, BookingService, Loader, OriginEnum } from 'src/app/core';
import { PaymentService, ReservationType } from 'src/app/core/modules/payment';
import { Guid } from '../../helpers';
import { NavigationHelper } from '../../helpers/navigation-helper';

@Component({
  selector: 'app-payment',
  templateUrl: './flex-win-payment.component.html',
})
export class FlexWinPaymentComponent implements OnInit {
  reservation: ReservationType | undefined;
  url: SafeResourceUrl | undefined;
  useNetsEasy = true;
  timer: any;
  appState!: ApplicationStateService;
  netsPaymentId: Guid | undefined;
  netsPaymentIdGiftcard: Guid | undefined;

  constructor(
    private log: LogService,
    private bookingService: BookingService,
    private paymentService: PaymentService,
    private applicationState: ApplicationStateService,
    private basketService: BasketService,
    private stepList: StepList,
    private navigationHelper: NavigationHelper,
    private loader: Loader,
  ) {}

  async ngOnInit() {
    this.log.debug('PaymentRoute ngOnInit()');
    window.scrollTo(0, 0);
    this.stepList.set(Step.Payment);

    this.appState = this.applicationState;

    if (this.applicationState.UseNetsEasy && this.applicationState.Origin !== OriginEnum.GiftCard) {
      await this.loader.using(async () => {
        try {
          // If the basketservice.value is null, we should send the customer back to payment-link window, as we then know that the customer was send from where.
          // For the basketservice not to be null, the customer must have been going through the onlinebooking steps.
          const basket = this.basketService.get();
          if (basket === null) {
            this.applicationState.Origin = OriginEnum.PaymentLink;
            if (this.applicationState.ReservationId && this.applicationState.ReservationId !== '') {
              this.applicationState.ReservationGuid = this.applicationState.ReservationId as Guid;
            }
          }

          const giftcardNumber = this.navigationHelper.getRouteParam('giftcardNumber');

          const p = await this.paymentService.makeNetsEasyPaymentUrl(
            this.applicationState.Origin,
            this.applicationState.ReservationGuid,
            this.bookingService.getReservationOrFail(),
            giftcardNumber,
          );

          if (p) {
            window.open(p.HostedPaymentPageUrl, '_self');
          }
        } catch (ex) {
          if ((ex as Error).message.includes('Reservation must be defined')) {
            await this.navigationHelper.navigateToPage('search');
          } else {
            await this.navigationHelper.navigateToPage('payment-failed');
            this.log.error('Payment responded with error', ex);
          }
        }
      });
    } else if (this.applicationState.UseNetsEasy && this.applicationState.Origin === OriginEnum.GiftCard) {
      await this.loader.using(async () => {
        // If the basketservice.value is null, we should send the customer back to payment-link window, as we then know that the customer was send from where.
        // For the basketservice not to be null, the customer must have been going through the onlinebooking steps.
        const basket = this.basketService.get();
        if (basket === null) {
          this.applicationState.Origin = OriginEnum.PaymentLink;
          if (this.applicationState.ReservationId && this.applicationState.ReservationId !== '') {
            this.applicationState.ReservationGuid = this.applicationState.ReservationId as Guid;
          }
        }

        const p = this.bookingService.giftcardEasyInfo.get();

        if (p) {
          window.open(p.HostedPaymentPageUrl, '_self');
        }
      });
    } else if (this.applicationState.UseNetAxept && this.applicationState.Origin !== OriginEnum.GiftCard) {
      await this.loader.using(async () => {
        try {
          // If the basketservice.value is null, we should send the customer back to payment-link window, as we then know that the customer was send from where.
          // For the basketservice not to be null, the customer must have been going through the onlinebooking steps.
          const basket = this.basketService.get();
          if (basket === null) {
            this.applicationState.Origin = OriginEnum.PaymentLink;
            if (this.applicationState.ReservationId && this.applicationState.ReservationId !== '') {
              this.applicationState.ReservationGuid = this.applicationState.ReservationId as Guid;
            }
          }

          this.reservation = this.bookingService.getReservationOrFail();
          const giftcardNumber = this.navigationHelper.getRouteParam('giftcardNumber');

          const p = await this.paymentService.makeNetAxeptPaymentUrl(
            this.applicationState.Origin,
            this.reservation,
            giftcardNumber,
          );
          window.open(p.PaymentUrl, '_self');
        } catch (ex) {
          await this.navigationHelper.navigateToPage('payment-failed');
          this.log.error('Payment responded with error', ex);
        }
      });
    } else if (this.applicationState.UseNetAxept && this.applicationState.Origin === OriginEnum.GiftCard) {
      await this.loader.using(async () => {
        window.open(this.applicationState.GiftcardPayment, '_self');
      });
    }
  }

  readonly OriginEnum = OriginEnum;
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationStateService, BasketQueryParams, HotelCodeString, HotelService, Loader } from '../../../core';
import { StayAvailabilityResponse } from '../../../core/modules/hotel/data-hotel.service';
import { DateHelper, RoomGuests, UTCDateString, UTCDateTimeString } from '../../../helpers';
import { NavigationHelper } from '../../../helpers/navigation-helper';

@Component({
  selector: 'app-calendar-guidance',
  templateUrl: './calendar-guidance.component.html',
})
export class CalendarGuidanceComponent implements OnInit {
  arrival: Date | undefined;
  lengthOfStay: number | undefined;
  rateCode: string | undefined;
  dates: CalendarModel | undefined;

  constructor(
    private navigationHelper: NavigationHelper,
    private route: ActivatedRoute,
    private loader: Loader,
    private hotelService: HotelService,
    private applicationState: ApplicationStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.catchUrlParams();
    await this.buildCalendar();
  }

  async goBack() {
    await this.navigationHelper.goBack();
  }

  catchUrlParams() {
    const arrival = this.route.snapshot.queryParams['ArrivalDate'];
    if (arrival) {
      this.arrival = arrival;
    }

    const lengthOfStay = this.route.snapshot.queryParams['LengthOfStay'];
    if (lengthOfStay) {
      this.lengthOfStay = Number(lengthOfStay);
    }

    const rateCode = this.route.snapshot.queryParams['rateCode'];
    if (rateCode) {
      this.rateCode = rateCode;
    }
  }

  async buildCalendar(isReBuild = false) {
    if (this.arrival && this.lengthOfStay) {
      const arrival = new Date(this.arrival);
      const weekday = new Date(this.arrival).getDay();

      let firstDate = DateHelper.addDays(arrival, -weekday + 1 - 7);
      const lastDate = DateHelper.addDays(firstDate, 21);
      const daysBetween = DateHelper.daysBetween(firstDate, lastDate, 0);
      const daysToAdd = 21;

      if (this.calculateStatus(dayStatus.open, firstDate) === dayStatus.runOut) {
        firstDate = DateHelper.addDays(arrival, -weekday + 1);
      }

      const initAvailability = await this.loadAvailability(
        this.applicationState.CurrentHotelCode,
        firstDate,
        DateHelper.addDays(new Date(this.arrival), daysToAdd),
        undefined,
        isReBuild,
        this.rateCode,
      );

      let counter = 0;
      this.dates = {} as CalendarModel;
      this.dates.days = [] as Day[];
      while (counter < daysBetween) {
        const weekNumber = this.getWeekNumber(DateHelper.addDays(firstDate, counter));

        let day = {} as Day;

        day = {
          active: true,
          selected: this.selected(DateHelper.addDays(firstDate, counter)),
          status: this.calculateStatus(dayStatus.open, DateHelper.addDays(firstDate, counter)),
          date: DateHelper.addDays(firstDate, counter),
          weekNumber: undefined,
        } as Day;
        this.dates.days.push(day);

        if (day.date.getDay() === 0) {
          day = {
            active: true,
            selected: false,
            status: dayStatus.open,
            date: DateHelper.addDays(firstDate, counter),
            weekNumber,
          } as Day;
          this.dates.days.push(day);
        }

        counter++;
      }

      for (const item of initAvailability) {
        const date = DateHelper.FromUtcDate(item.Date.toString() as UTCDateTimeString);

        const object = this.dates.days.find(
          (x) =>
            x.date.getDate() === date.getDate() &&
            x.date.getMonth() === date.getMonth() &&
            x.date.getFullYear() === date.getFullYear() &&
            !x.weekNumber,
        );
        if (object) {
          object.status = this.calculateStatus(item.Reason, new Date());
          if (object.status !== dayStatus.open) {
            object.active = false;
          }
        }
      }
    } else {
      await this.navigationHelper.goBack();
    }
  }

  async changeArrival(day: Day) {
    if (day.active) {
      this.arrival = day.date;

      await this.buildCalendar(true);
    }
  }

  async moveNext() {
    const searhParams = this.navigationHelper.collectSearchParams() as BasketQueryParams;
    if (searhParams && this.arrival) {
      searhParams.ArrivalDate = DateHelper.format(this.arrival, 'YYYY-MM-DD') as UTCDateString;
      if (this.applicationState.BookingFlow === '1') {
        await this.navigationHelper.continue('/search', '/room/1', {
          queryParams: searhParams,
        });
      } else if (this.applicationState.BookingFlow === '2') {
        await this.navigationHelper.continue('/search', '/ratesRoom/1', {
          queryParams: searhParams,
        });
      }
    }
  }

  private getWeekNumber(d: Date): number {
    // Copy date so don't modify original
    d = new Date(+d);
    d.setHours(0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    const yearStart = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(((d.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }

  private async loadAvailability(
    hotelCode: HotelCodeString,
    start: Date,
    end: Date,
    rooms: RoomGuests[] | undefined,
    useLoader: boolean,
    rateCode: string | undefined,
  ) {
    return await this.loader.using(
      async () => {
        try {
          const res = await this.hotelService.GetRoomAvailability(hotelCode, start, end, rateCode, undefined);
          return res;
        } catch (err) {
          return [] as StayAvailabilityResponse[];
        }
      },
      'LOA_Availability',
      useLoader,
    );
  }

  private calculateStatus(reason: string, currentMonth: Date) {
    if (
      reason === 'HOTELCLOSED' ||
      reason === 'HOTELCLOSEDDAYCOLOR' ||
      reason === 'NORATECODES' ||
      reason === 'NOTHINGVACANT' ||
      reason === 'CL'
    ) {
      return dayStatus.closed;
    } else if (reason === 'CFA') {
      return dayStatus.arrival;
    } else if (reason === 'CFD') {
      return dayStatus.departure;
    } else if (
      currentMonth.getMonth() < new Date().getMonth() &&
      currentMonth.getFullYear() === new Date().getFullYear()
    ) {
      return dayStatus.runOut;
    } else if (
      currentMonth.getMonth() === new Date().getMonth() &&
      currentMonth.getDate() < new Date().getDate() &&
      currentMonth.getFullYear() === new Date().getFullYear()
    ) {
      return dayStatus.runOut;
    } else {
      return dayStatus.open;
    }
  }

  private selected(date: Date) {
    if (this.arrival && this.lengthOfStay) {
      const arrival = new Date(this.arrival);
      const arr = new Date(arrival.getFullYear(), arrival.getMonth(), arrival.getDate());

      const departure = DateHelper.addDays(arr, this.lengthOfStay);
      const dep = new Date(departure.getFullYear(), departure.getMonth(), departure.getDate());

      const current = new Date(date.getFullYear(), date.getMonth(), date.getDate());

      return current.getTime() >= arr.getTime() && date.getTime() <= dep.getTime();
    }
    return false;
  }
}

interface CalendarModel {
  days: Day[];
  currentMonth: string;
  currentYear: number;
}

interface Day {
  date: Date;
  active: boolean;
  status: dayStatus;
  selected: boolean;
  weekNumber: number | undefined;
}

enum dayStatus {
  closed = '#F87E7D',
  open = 'white',
  arrival = '#ffb759',
  departure = '#729ef7',
  runOut = '#707070',
}

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FacebookPixelClientService } from './facebook-pixel-client.service';
import { GoogleAnalyticsClientService } from './google-analytics-client.service';
import { GoogleHotelAdsClientService } from './google-hotel-ads-client.service';
import { GoogleTagManagerClientService } from './google-tag-manager-client.service';
import { SpectraAdsClientService } from './spectra-ads-client.service';
import { TrackingService } from './tracking.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    GoogleTagManagerClientService,
    GoogleAnalyticsClientService,
    FacebookPixelClientService,
    GoogleHotelAdsClientService,
    SpectraAdsClientService,
    TrackingService,
  ],
  exports: [],
})
export class TrackingModule {
  constructor(
    @Optional() @SkipSelf() parentModule: TrackingModule,
    trackingService: TrackingService,
    googleTagManagerClient: GoogleTagManagerClientService,
    googleAnalyticsClient: GoogleAnalyticsClientService,
    facebookPixelClient: FacebookPixelClientService,
    googleHotelAdsClient: GoogleHotelAdsClientService,
    spectraAdsClient: SpectraAdsClientService,
  ) {
    if (parentModule) {
      throw new Error('TrackingModule is already loaded. Import it in the CoreModule only');
    }
    trackingService.addClient(googleTagManagerClient);
    //trackingService.addClient(googleAnalyticsClient);
    //trackingService.addClient(facebookPixelClient);
    //trackingService.addClient(googleHotelAdsClient);
    trackingService.addClient(spectraAdsClient);
  }
}

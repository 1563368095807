import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogService } from '@com/logging';
import moment from 'moment';
import { combineLatest, from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  ApplicationStateService,
  Basket,
  BasketService,
  CustomerService,
  Locale,
  LocaleService,
  RoomCodeString,
  TrackingService,
} from 'src/app/core';
import { Loader } from 'src/app/core';
import {
  BookingExtraServiceModel,
  ExtraServiceModel,
  DataHotelService,
} from '../../core/modules/hotel/data-hotel.service';
import { DateHelper, GuestType } from '../../helpers';
import { NavigationHelper } from '../../helpers/navigation-helper';
import { AddOnUpdate } from './add-on-card.component';

@Component({
  selector: 'app-add-on',
  templateUrl: './add-on.component.html',
})
export class AddOnComponent implements OnInit, OnDestroy {
  basket: Basket | undefined;
  addOns$: Observable<ExtraServiceWithRoomsInformation[]> | undefined;
  bookingFlow = 1;
  seatingFlow = false;

  constructor(
    private log: LogService,
    private loader: Loader,
    private basketService: BasketService,
    private dataHotelService: DataHotelService,
    private localeService: LocaleService,
    private customerService: CustomerService,
    private trackingService: TrackingService,
    private applicationState: ApplicationStateService,
    private navigationHelper: NavigationHelper,
  ) {}

  async ngOnInit() {
    this.applicationState.stopScrolling(false);
    this.basket = await this.getBasketOrFail();

    if (this.basket.bookings[0] && this.basket.bookings[0].RoomCode === ('' as RoomCodeString)) {
      this.seatingFlow = true;
    }

    if (!this.applicationState.UseExtraServices && !this.seatingFlow) {
      await this.navigationHelper.navigateToPage(`/customer`);
    }

    this.bookingFlow = Number(this.applicationState.BookingFlow);
    window.scrollTo(0, 0);
    this.trackingService.pageView(`/booking/${this.basket.params.hotelCode}/add-on`);

    this.addOns$ = combineLatest([this.localeService.locale$, this.customerService.customerId$]).pipe(
      switchMap(([locale, customerId]) =>
        from(this.loadAddOns(locale, customerId, this.seatingFlow)).pipe(
          tap(async (addOns) => {
            if (addOns.length === 0) {
              await this.onMoveToNextClick(false);
              await this.prepareNavigation(false);
            }
          }),
        ),
      ),
    );

    await this.prepareNavigation();
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async prepareNavigation(addOnsAvailable = true) {
    const basket = this.getBasket();
    if (addOnsAvailable) {
      this.applyBasket(basket);
    }
    await this.navigationHelper.continueMobileNavigationbar(false, true, '/add-on', `/customer`, {
      replaceUrl: !addOnsAvailable,
    });
  }

  async onItemSelect(addOnUpdates: AddOnUpdate[]) {
    const basket = this.getBasket();

    addOnUpdates.forEach((addOnUpdate) => {
      const { room, addOn } = addOnUpdate;

      const booking = basket.bookings[room.roomId <= basket.bookings.length - 1 ? room.roomId : 0];
      if (room.roomId >= basket.bookings.length) {
        this.log.debug(`Warning: basket.booking[${room.roomId}] not found, using first booking in basket instead.`);
      }

      if (!booking.AddOns) {
        booking.AddOns = [] as BookingExtraServiceModel[];
      }

      // If the addOn we got back has no selected dates, remove all selected
      if (addOn.Dates.length === 0) {
        const newBookings = booking.AddOns.filter((b) => b.Id !== addOn.Id);
        booking.AddOns = [...newBookings];
        return;
      }

      const addOnIndex = booking.AddOns.findIndex((x) => x.Id === addOn.Id);

      // When not in basket already
      if (addOnIndex === -1) {
        booking.AddOns.push(addOn);
        return;
      }

      // When in basket already
      booking.AddOns[addOnIndex] = addOn;
    });

    this.applyBasket(basket);
  }

  async onMoveToNextClick(addOnsAvailable = true) {
    const basket = this.getBasket();
    if (addOnsAvailable) {
      this.applyBasket(basket);
    }

    if (!addOnsAvailable) {
      await this.navigationHelper.navigateToPage(`/customer`, {
        replaceUrl: !addOnsAvailable,
      });
    } else {
      await this.navigationHelper.continue('/add-on', `/customer`, {
        replaceUrl: !addOnsAvailable,
      });
    }
  }

  async goBack() {
    await this.navigationHelper.goBack();
  }

  private applyBasket(basket: Basket) {
    this.basketService.set(basket);
  }

  private getBasket() {
    const basket = this.basketService.get();
    if (!basket) {
      throw new Error('Basket must exist');
    }
    return basket;
  }

  private getAqcCounts(item: any) {
    // Get count for all types except Room (Room is irrelevant for seatings)
    const counts = {
      adults: this.countByGuestType(item, 'Adult'),
      children: this.countByGuestType(item, 'Child'),
      infants: this.countByGuestType(item, 'Infant'),
      seniors: this.countByGuestType(item, 'Senior'),
      teenagers: this.countByGuestType(item, 'Teenager'),
    } as AqcCounts;
    return counts;
  }

  private getAqcCountsBasketSeatings(basketSeatings: any) {
    const counts: AqcCounts = {
      adults: 0,
      children: 0,
      infants: 0,
      seniors: 0,
      teenagers: 0,
    };

    for (const item of basketSeatings) {
      const { adults, children, infants, seniors, teenagers } = this.getAqcCounts(item);
      counts.adults += adults;
      counts.children += children;
      counts.infants += infants;
      counts.seniors += seniors;
      counts.teenagers += teenagers;
    }

    return counts;
  }

  private countByGuestType(item: any, type: string): number {
    return item.SeatGuest.reduce((total: number, guest: any) => {
      if (guest.AQC[type] === true) {
        return total + Number(guest.Count);
      }
      return total;
    }, 0);
  }

  private async getBasketOrFail() {
    try {
      const basket = this.basketService.get();
      if (!basket) {
        const translation = await LocaleService.LoadTranslations(await this.localeService.getLocale());
        throw new Error(translation.EXC_EmptyBasket);
      }
      if (basket.bookings.length === 0) {
        const translation = await LocaleService.LoadTranslations(await this.localeService.getLocale());
        throw new Error(translation.EXC_EmptyBasket);
      }
      return basket;
    } catch (err) {
      this.log.error(`AddOnRoute getBasket() failed: ${(err as any).message}`);
      await this.navigationHelper.continue('/add-on', '/search');
      throw err;
    }
  }

  private async loadAddOns(
    locale: Locale,
    customerId: number | undefined,
    seatingFlow: boolean,
  ): Promise<ExtraServiceWithRoomsInformation[]> {
    const basket = this.getBasket();
    const { hotelCode, arrival, stay, rooms } = basket.params;

    let addonsArray = [] as ExtraServiceModel[][];

    if (seatingFlow) {
      // --- SeatingFlow

      const basketSeatings = basket.bookings[0].Seatings;

      if (basketSeatings) {
        // Get total AQC count of all seatings in basket.
        // This is so each item can get the correct number of ExtraServices, because each ExtraService might be available for multiple AQC's.
        const aqcCountsBasketSeatings = this.getAqcCountsBasketSeatings(basketSeatings);

        await this.loader.using(async () => {
          for (const item of basketSeatings) {
            const info = await this.dataHotelService.GetECommerceExtraServices({
              hotelCode: hotelCode,
              ratePlan: null,
              fromDate: DateHelper.toServerDateFormatString(moment(item.Date).toDate()),
              toDate: DateHelper.toServerDateFormatString(moment(item.Date).toDate()),
              lang: await this.localeService.getLocale(),
              adultCount: aqcCountsBasketSeatings.adults,
              agecategory1count: aqcCountsBasketSeatings.teenagers,
              agecategory2count: aqcCountsBasketSeatings.children,
              agecategory3count: aqcCountsBasketSeatings.infants,
              agecategory4count: aqcCountsBasketSeatings.seniors,
              roomCode: '',
              seatingCode: item.SeatGuest[0].SeatId,
            });

            if (info) {
              addonsArray.push(info);
            }
          }
        }, 'LOA_Addons');
      }
    } else {
      // --- Not SeatingFlow

      // Load addons for all rooms
      addonsArray = await Promise.all(
        Array(basket.bookings.length)
          .fill(0)
          .map(async (v, idx) => {
            const guests = rooms[idx];
            const booking = basket.bookings[idx];

            return await this.loader.using(async () => {
              try {
                const adults = guests.get(GuestType.Adult);
                const seniors = guests.get(GuestType.Senior);
                const cat1 = guests.get(GuestType.UnknownType1);
                const cat2 = guests.get(GuestType.Child);
                const cat3 = guests.get(GuestType.Infant);
                const info = await this.dataHotelService.GetECommerceExtraServices({
                  hotelCode: hotelCode,
                  ratePlan: booking.RateCode,
                  fromDate: DateHelper.toServerDateFormatString(moment(arrival).toDate()),
                  toDate: DateHelper.toServerDateFormatString(DateHelper.addDays(arrival, stay)),
                  lang: await this.localeService.getLocale(),
                  adultCount: adults,
                  agecategory1count: cat1,
                  agecategory2count: cat2,
                  agecategory3count: cat3,
                  agecategory4count: seniors,
                  roomCode: booking.RoomCode,
                  seatingCode: undefined,
                });

                return info;
              } catch (err) {
                this.log.error(`AddOnRoute failed to load addons. ${(err as any).message}`);
                return [] as ExtraServiceModel[];
              }
            }, 'LOA_Addons');
          }),
      );
    }

    // Flatten and filter addons array and add available booking rooms/indexes with their respective count
    const extraServiceInfos: ExtraServiceWithRoomsInformation[] = [];
    addonsArray.forEach((addonArray, bookingIndex) => {
      addonArray.forEach((addon) => {
        const existingAddon = extraServiceInfos.find((a) => a.Id === addon.Id);
        if (!existingAddon) {
          const extraServiceInfo: ExtraServiceWithRoomsInformation = {
            ...addon,
            rooms: [{ id: bookingIndex, maxGuestCount: addon.Count }],
          };

          extraServiceInfos.push(extraServiceInfo);
        } else {
          if (!seatingFlow) {
            existingAddon.rooms.push({
              id: bookingIndex,
              maxGuestCount: addon.Count,
            });
          } else {
            existingAddon.Dates.push(...addon.Dates);
          }
          //existingAddon.Dates.forEach((date) => {
          //  date.maxCount = addon.Count;
          //});
        }
      });
    });

    extraServiceInfos.sort((a, b) => a.SortIndex - b.SortIndex);
    return extraServiceInfos;
  }
}

interface AqcCounts {
  adults: number;
  teenagers: number;
  children: number;
  infants: number;
  seniors: number;
}

export interface ExtraServiceWithRoomsInformation extends ExtraServiceModel {
  rooms: [
    {
      id: number;
      maxGuestCount: number;
    },
  ];
}

export interface LogClient {
  log(entry: LogEntry): void;
}

export enum LogLevel {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
}

export type LogObject = any;

export interface LogEntry {
  date: Date;
  msg: string;
  level: LogLevel;
  data: LogObject[];
}

import { Messages } from './index';

export const messages: Messages = {
  APA_CompanyLogin: `Firma login`,
  APA_Logout: `Log ud`,
  APA_Welcome: `Velkommen`,
  APA_ShowPromo: `Rabatkode`,
  APA_PromoLabel: `Indtast rabatkode`,
  APA_Promo: `Rabatkode`,
  APA_PromoSubmit: `Benyt`,
  APA_PromoChange: `ændre`,
  APA_ActivateHead: `Aktiver dit login`,
  APA_ActivateDesc: `Angiv adgangskode for at aktivere login for`,
  APA_Password: `Adgangskode`,
  APA_ConfirmPassword: `Bekræft adgangskode`,
  APA_PasswordHasUpper: `Adgangskode skal indeholde mindst et stort bogstav (A-Z)`,
  APA_PasswordHasLower: `Adgangskode skal indeholde mindst et lille bogstav (a-z)`,
  APA_PasswordHasNumber: `Adgangskode skal indeholde mindst et tal (0-9)`,
  APA_PasswordLength8: `Adgangskode skal indeholde mindst 8 karakterer`,
  APA_ConfirmPasswordEqualTo: `Forkert adgangskode`,
  APA_Activate: `Aktiver`,
  APA_CustomerNotFound: `Kunde ikke fundet`,
  APA_ResetBasket: `Alt vil blive fjernet fra kurven. Er du sikker?`,
  APA_Address: `Adresse`,
  APA_Contact: `Kontakt`,
  APA_VatNumber: `CVR:`,
  APA_PromoMessageStart: `Kampagne koden`,
  APA_ValidTime: `Gyldigheds periode`,
  APA_PromoMessageEnd: `Koden er gyldig på de produkter der er markeret med en procentvis rabat`,
  APA_AQCDecription: `Aldersbeskrivelse`,
  APA_Year: `år`,
  APA_Book: `Book`,

  CPA_NothingAvailable_EmptyText: `Vælg venligst en ny dato herunder, eller gå tilbage og ændre dine oplysninger fra første step`,
  CPA_NothingAvailable: `Der er ingen ledige værelser, der opfylder dine kriterier`,
  CPA_SoldOut: `Udsolgt`,
  CPA_CustomerInvalid: `Ukendt kundeprofil`,
  CPA_PromoInvalid: `Den indtastede rabatkode er ugyldig.`,
  CPA_PromoBetween: `Den indtastede rabatkode er kun gyldig hvis opholdet er i perioden:`,

  RPA_More: `Se mere`,
  RPA_Select: `Vælg`,
  RPA_Selected: `Valgt`,
  RPA_From: `Starter fra`,
  RPA_Image: `Intet billede`,
  RPA_Description: `Beskrivelse`,
  RPA_ChooseRoom: `Vælg værelse`,
  RPA_ChoosePackage: `Vælg ophold`,
  RPA_ChooseCabin: `Vælg Hytte`,
  RPA_ChooseHouse: `Vælg Hus`,
  RPA_ChooseUnit: `Vælg enhed`,
  RPA_BackRate: `Vælg en anden pakke`,
  RPA_MaxPersons: `Maks antal personer`,
  RPA_ChooseYourRoom: `Vælg værelse`,
  RPA_ChooseYourCabin: `Vælg hytte`,
  RPA_ChooseYourHouse: `Vælg hus`,
  RPA_ChooseYourUnit: `Vælg enhed`,
  RPA_ChooseYourPackage: `Vælg ophold`,

  APA_Services: `Ekstra tilkøb`,
  APA_Add: `Tilføj`,
  APA_Added: `Tilføjet`,
  APA_Remove: `Fjern`,
  APA_ContentDialog: `Vælg det antal personer og dage du ønsker den valgte service. Mellem datoerne`,
  APA_ChooseYourAddOns: `Vælg ekstra tilkøb`,
  APA_TotalPrice: `Samlet pris`,
  APA_TotalInBasket: `stk, i alt`,
  APA_TotalOf: `Vælg antal à`,

  CUP_Header: `Købers oplysninger`,
  CUP_Accept: `Accepter betingelser`,
  CUP_FName: `Fornavn`,
  CUP_FNameReq: `Fornavn skal oplyses`,
  CUP_FPhoneReq: `Mobil skal oplyses`,
  CUP_LName: `Efternavn`,
  CUP_LNameReq: `Efternavn skal oplyses`,
  CUP_Address: `Adresse`,
  CUP_AddressReq: `Adressen skal oplyses`,
  CUP_ZipCode: `Postnummer`,
  CUP_ZipCodeReq: `Postnr. skal oplyses`,
  CUP_City: `By`,
  CUP_CityReq: `By skal oplyses`,
  CUP_Contry: `Land`,
  CUP_ContryReq: `Land er påkrævet`,
  CUP_Phone: `Mobil`,
  CUP_PhoneReq: `Mobilnr. skal oplyses`,
  CUP_Email: `E-mail`,
  CUP_EmailRepeat: `Gentag E-mail`,
  CUP_EmailReq: `E-mail skal oplyses`,
  CUP_EmailInvalid: `E-mail er ikke korrekt`,
  CUP_Comment: `Kommentarer`,
  CUP_NeedSubscribe: `Jeg vil gerne modtage nyheder pr. e-mail.`,
  CUP_AcceptTerms: `Jeg har læst og accepterer betingelserne.`,
  CUP_BookNow: `Book`,
  CUP_CancelPolicyHeader: `Afbestillingsforsikring`,
  CUP_CancelPolicyDesc: `Du har mulighed for at annullere dit ophold hvis du skulle blive forhindret i at ankomme. Se yderligere oplysninger om vilkår for afbestillingsbeskyttelsen i vores betingelser.`,
  CUP_CancelPolicyLabel: `Ja, jeg vil gerne tilkøbe afbestillingsforsikring til`,
  CUP_GuestInfo: `Gæsteoplysninger`,
  CUP_BookingOverview: `Booking oversigt`,
  CUP_PayWithGiftcard: `For at betale med gavekort`,
  CUP_PayWithGiftcardClick: `Klik her`,
  CUP_PayWithGiftcardDescription: `Indtast koden fra dit gavekort`,
  CUP_PayWithGiftcardError: `Gavekort er ikke aktivt eller kunne ikke findes i systemet.`,
  CUP_PayWithGiftcardStatus: `Gavekort aktivt`,
  CUP_PayWithGiftcardDiscount: `Gavekort beløb`,
  CUP_PayWithGiftcardHeader: `Gavekort`,
  CUP_PayWithGiftcardInformation: `Vil du indløse et gavekort?`,
  CUP_PayWithGiftcardPlaceholder: `Gavekortskode`,
  CUP_PayWithGiftcardRedeem: `Indløs`,
  CUP_BookingOverviewToPay: `At betale`,

  PAL_GoToPayment: `Gå til betaling`,
  PAL_PaymentComplete: `Linket er udløbet. Kontakt venligst`,
  PAL_PaymentNotAvailable: `Betaling på denne reservation er ikke tilgængelig`,

  PAR_Payment: `Betaling`,
  PAR_SeekAuthorization: `Dine kreditkortoplysninger vil blive brugt som betaling, eller alternativt som sikkerhed for købet.`,
  PAR_TotalLabel: `Pris på din bestilling`,
  PAR_TotalPayLabel: `Pris at betale`,
  PAR_OBSLabel: `VIGTIGT: Luk ikke dette vindue, før kvitteringssiden vises, hvorpå du kan se dit ordre-/reservationsnummer!`,
  PAR_OBSLabelGiftcard: `OBS: Luk ikke dette vindue før kvitteringsside vises med et ordrenummer!`,
  PAR_FailedToPay: `Det lykkedes ikke at betale. Tjek dit kortnummer.`,
  PAR_CardInvalid: `Kortnummer er ikke gyldigt`,
  PAR_CardUnknown: `Korttype ikke kendt`,
  PAR_CardMonthInvalid: `Måned er ugyldig`,
  PAR_CardYearInvalid: `År er ugyldig`,
  PAR_CardCVCInvalid: `CVV-kode er ugyldig`,
  PAR_FieldRequired: `Skal udfyldes`,
  PAR_Cancel: `Annuller`,
  PAR_Pay: `Betal`,
  PAR_PaymentMethod: `Betalingsmetoder`,
  PAR_Cardnumber: `Kortnummer`,
  PAR_MonthPlaceholder: `MM`,
  PAR_YearPlaceholder: `ÅÅ`,

  PFP_Header: `Hov! Der er desværre sket en fejl`,
  PFP_Info: `Og din ordre er IKKE gået igennem. Prøv venligst igen ved at klikke på knappen herunder. Der er ikke blevet trukket penge på din konto.`,
  PFP_TryAgain: `Prøv igen`,

  REP_Header: `Tak for din bestilling,`,
  REP_Comment: `herunder finder du din kvittering for din ordre.`,
  REP_DateRooms: `Rejsedato`,
  REP_Return: `Tilbage til hjemmeside`,
  REP_OrderId: `Ordrenummer`,
  REP_ReservationId: `Ordrenummer`,
  REP_CustomerInfo: `Personlige oplysninger`,

  REP_Name: `Navn`,
  REP_PostCity: `Postnr & By`,
  REP_Phone: `Telefon`,
  REP_Address: `Adresse`,
  REP_Country: `Land`,
  REP_Email: `Email`,
  REP_Thanks: `Tak for din ordre`,
  REP_ThanksInfo: `Du vil modtage en bekræftelse indenfor kort tid på mail.`,
  REP_ThanksPayment: `Din betaling er gennemført`,

  LOA_Addons: `Henter ekstra ydelser`,
  LOA_CustomerInfo: `Henter kunde informationer`,
  LOA_ActivatingCustomer: `Aktiver kunde`,
  LOA_Translations: `Henter oversættelser`,
  LOA_Configuration: `Indlæser konfiguration`,
  LOA_Availability: `Henter ledighed`,
  LOA_CreatingReservation: `Opretter reservation`,
  LOA_HotelInformation: `Indlæser hotel information`,
  LOA_Countries: `Henter lande`,
  LOA_PreparingPaymnent: `Forbereder betaling`,
  LOA_ApplyingPromo: `Indlæser rabatkode`,
  LOA_Rooms: `Indlæser værelser`,
  LOA_Rates: `Indlæser priser`,
  LOA_LoggingIn: `Logger ind`,
  LOA_SendingInformation: `Sender information`,

  COM_Continue: `Fortsæt`,
  COM_OK: `OK`,
  COM_Persons: `Personer`,
  COM_Guest_10: `Voksne`,
  COM_Guest_9: `Teenagere`,
  COM_Guest_7: `Baby`,
  COM_Guest_11: `Seniorer`,
  COM_Guest_8: `Børn`,
  COM_Adult: `Voksen`,
  COM_Child: `Barn`,
  COM_Room: `Værelse`,
  COM_Cabin: `Hytte`,
  COM_House: `Hus`,
  COM_Unit: `Enhed`,
  COM_Arrival: `Ankomst`,
  COM_Departure: `Afrejse`,
  COM_Promocode: `Rabatkode`,
  COM_Rooms: `Værelser`,
  COM_AddOns: `Ekstra ydelser`,
  COM_TotalGuests: `Antal gæster`,
  COM_Price: `Pris`,
  COM_TotalPrice: `Pris total`,
  COM_TotalPriceWithTransactionId: `I alt betalt med transaktionsnr.`,
  COM_TotalReservedWithTransactionId: `I alt er reserveret med transactionId`,
  COM_Close: `Luk`,
  COM_Back: `Tilbage`,
  COM_Error: `Info`,
  COM_Info: `Information`,
  COM_Confirm: `Bekræft`,
  COM_Package: `Pakke`,
  COM_SoldOutError: `Der er ikke nogle ledige værelser eller pakker på denne dag`,
  COM_SearchNew: `Ny søgning`,
  COM_TableReservation: `Bord Reservation`,
  COM_TableNumberOfPersons: `Antal personer ved bordet`,
  COM_Guests: `Gæster`,
  COM_TableChooseProduct: `Vælg dato og produkt`,
  COM_TablePlaceGuests: `Du mangler at placere`,
  COM_TableChooseProducts: `Vælg produkt(er)`,
  COM_TableNumberOfGuestsForProduct: `Antal gæster til dette produkt`,
  COM_TableBookingFlowAddProduct: `Ønsker du at lave en bord reservation på dit ophold?`,
  COM_TableIfNotPressContinue: `Ellers tryk fortsæt`,
  COM_AddToBasket: `Læg i kurv`,
  COM_AddedToBasket: `Tilføjet til kurv`,
  COM_Type: `Type`,
  COM_SeatingSoldOut: `Der er ikke noget ledigt på nuværende tidspunkt`,
  COM_Main: `Startside`,
  COM_Conference: `Konference`,
  COM_Quantity: `Antal`,

  LFO_Email: `E-mail`,
  LFO_EmailRequried: `E-mail skal oplyses`,
  LFO_EmailInvalid: `E-mail er ikke korrekt`,
  LFO_Password: `Adgangskode`,
  LFO_PasswordRequried: `Adgangskode påkrævet`,
  LFO_ForgotPassword: `Kan du ikke tilgå din konto ?`,
  LFO_Login: `Log ind`,
  LFO_BackToLogin: `Tilbage til indlogning`,
  LFO_Send: `Send`,
  LFO_MessageSend: `Besked afsendt!`,
  LFO_LoginError: `Forkert e-mail eller adgangskode`,
  LFO_RecoveryError: `Loginoplysninger ugyldige`,

  SFO_ChooseHotel: `Vores hoteller`,
  SFO_ChooseHolidayResort: `Vores Feriecentere`,
  SFO_ChooseCenter: `Vores Centre`,
  SFO_ChooseDepartment: `Vælg Afdeling`,
  SFO_ParamsHeader: `Vælg rejsedato`,
  SFO_Guests: `Gæster i værelse`,
  SFO_GuestsCabin: `Gæster i Hytte`,
  SFO_GuestsHouse: `Gæster i Hus`,
  SFO_GuestsUnit: `Gæster i Enhed`,
  SFO_AddRoom: `Flere værelser`,
  SFO_AddCabin: `Flere hytter`,
  SFO_AddHouse: `Flere huse`,
  SFO_AddUnit: `Flere enheder`,
  SFO_BookStay: `ophold`,
  SFO_BookEvent: `Event`,
  SFO_BookTakeAway: `Take Away`,
  SFO_BookTableReservation: `Bord Reservation`,
  SFO_BookGiftcard: `Gavekort`,
  SFO_GiftcardMetaData: `Grunddata for dine gavekort`,
  SFO_ChooseGiftcard: `Vælg Gavekort`,
  SFO_NumberOfGiftcards: `Hvor mange af de valgte gavekort vil du købe?`,
  SFO_GiftcardAmount: `Indtast beløb`,
  SFO_GiftcardEnterDetails: `Udfyld detaljer for dine gavekort`,
  SFO_ChooseGiftcardDescription: `Køb et gavekort til en du holder af`,
  SFO_GiftcardDetails: `Gavekort detaljer`,
  SFO_GiftcardTo: `Hvem er gavekortet til (valgfrit)`,
  SFO_GiftcardFrom: `Hvem er gavekortet fra (valgfrit)`,
  SFO_GiftcardMessage: `Skriv en hilsen (Maks 100 tegn) (valgfrit)`,
  SFO_BookArrangement: `Arrangement`,
  SFO_BookConcert: `Koncert`,
  SFO_BookTableArangement: `Bord/Arrangement`,
  SFO_BookTableEvent: `Bord/Event`,
  SFO_BordTakeAway: `Bord/take away`,
  SFO_BookTicket: `Billet`,
  SFO_BookLecture: `Foredrag`,
  SFO_BookTable: `Bestil Bord`,
  SFO_BookSpa: `Spa`,
  SFO_BookExperience: `Oplevelse`,
  SFO_ChooseMonth: `Vælg Måned`,
  SFO_NoRooms: `Der blev ikke fundet nogle ledige værelser i det valgte tidsrum!`,
  SFO_MinLOS: `Den valgte periode kræver booking af minimum`,
  SFO_Nights: `nætter`,
  SFO_MaxLOS: `I den valgte periode er det maksimalt muligt at booke`,

  CAL_WeekStart: `1`,
  CAL_M01: `Januar`,
  CAL_M02: `Februar`,
  CAL_M03: `Marts`,
  CAL_M04: `April`,
  CAL_M05: `Maj`,
  CAL_M06: `Juni`,
  CAL_M07: `Juli`,
  CAL_M08: `August`,
  CAL_M09: `September`,
  CAL_M10: `Oktober`,
  CAL_M11: `November`,
  CAL_M12: `December`,
  CAL_D0: `Søndag`,
  CAL_D1: `Mandag`,
  CAL_D2: `Tirsdag`,
  CAL_D3: `Onsdag`,
  CAL_D4: `Torsdag`,
  CAL_D5: `Fredag`,
  CAL_D6: `Lørdag`,
  CAL_Selected: `Valgt`,
  CAL_Occupied: `Lukket/udsolgt`,
  CAL_Arrival: `Lukket for ankomst`,
  CAL_Departure: `Lukket for afrejse`,
  CAL_Mon: `M`,
  CAL_Tirs: `T`,
  CAL_Ons: `O`,
  CAL_Tors: `T`,
  CAL_Fre: `F`,
  CAL_Lor: `L`,
  CAL_Sun: `S`,
  CAL_Available: `Ledigt`,
  CAL_NotPossible: `Ikke muligt`,

  BIN_ChangeDate: `Ret dato`,
  BIN_ChangeRoom: `Ret`,
  BIN_ChangeAddOns: `Ændre ekstra ydelser`,
  BIN_NoAddOns: `Ingen ekstra ydelser tilføjet`,
  BIN_AddOns: `Ekstra ydelser tilføjet`,
  BIN_Reservation: `Din Reservation`,
  BIN_Details: `Basis Detaljer`,
  BIN_RoomCount: `Værelser`,
  BIN_CabinCount: `Hytter`,
  BIN_HouseCount: `Huse`,
  BIN_UnitCount: `Enheder`,
  BIN_Roomtype: `Værelsestype`,
  BIN_Cabintype: `Hytte type`,
  BIN_Housetype: `Hustype`,
  BIN_Unittype: `Enheds type`,
  BIN_Packagetype: `Pakketype`,
  BIN_BasketEmpty: `Kurven er tom`,
  BIN_BasketGoToCheckout: `Gå til kassen`,

  EXC_EmptyBasket: `Kurven må ikke være tom`,

  SEA_Date: `Dato`,
  SEA_Time: `Tid`,
  SEA_Quantity: `Antal`,
  SEA_Skip: `Spring over`,
  SEA_ChooseEvent: `Vælg en oplevelse`,
  SEA_DescriptionStart: `Vælg`,
  SEA_DescriptionEnd: `nedenfor eller dato i kalenderen`,
  SEA_SoldOut: `Alt er udsolgt for den valgte måned`,
  SEA_SoldOutContinue: `Tryk på Fortsæt for at springe over`,
  SEA_SeatingStandAloneTitle: `Vælg en dato eller en af de viste valgmuligheder`,
  SEA_ResetCalendar: `Vis alle`,

  MAT_CALENDAR_Calendar_Label: `S`,
  MAT_CALENDAR_Close_Calendar_Label: `S`,
  MAT_CALENDAR_End_Date_Label: `S`,
  MAT_CALENDAR_Next_Month_Label: `S`,
  MAT_CALENDAR_Next_Multi_Year_Label: `S`,
  MAT_CALENDAR_Next_Year_Label: `S`,
  MAT_CALENDAR_Open_Calendar_Label: `S`,
  MAT_CALENDAR_Prev_Month_Label: `S`,
  MAT_CALENDAR_Prev_Multi_Year_Label: `S`,
  MAT_CALENDAR_Prev_Year_Label: `S`,
  MAT_CALENDAR_Start_Date_Label: `S`,
  MAT_CALENDAR_Switch_To_Month_View_Label: `S`,
  MAT_CALENDAR_Switch_To_Multi_Year_View_Label: `S`,
};

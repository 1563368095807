import { Messages } from './index';

export const messages: Messages = {
  APA_CompanyLogin: `INLOGGNING FÖRETAG`,
  APA_Logout: `Logga ut`,
  APA_Welcome: `Hej`,
  APA_ShowPromo: `Kampanjkod`,
  APA_PromoLabel: `Ange kampanjkod`,
  APA_Promo: `Kampanjkod`,
  APA_PromoSubmit: `Använd`,
  APA_PromoChange: `Ändra`,
  APA_ActivateHead: `Aktivera inloggning`,
  APA_ActivateDesc: `Skriv in lösenord för att aktivera inloggning för`,
  APA_Password: `Lösenord`,
  APA_ConfirmPassword: `Bekräfta lösenord`,
  APA_PasswordHasUpper: `Lösenordet måste innehålla minst en stor bokstav (A-Z).`,
  APA_PasswordHasLower: `Lösenordet måste innehålla minst en liten bokstav (a-z).`,
  APA_PasswordHasNumber: `Lösenord måste innehålla minst en siffra (0-9).`,
  APA_PasswordLength8: `Lösenord måste innehålla minst 8 tecken.`,
  APA_ConfirmPasswordEqualTo: `Lösenordet är felaktigt`,
  APA_Activate: `Aktivera`,
  APA_CustomerNotFound: `Kunden hittas ej`,
  APA_ResetBasket: `Kundkorgen kommer att tömmas`,
  APA_Address: `Adress`,
  APA_Contact: `Kontakta`,
  APA_VatNumber: `Org.nr:`,
  APA_PromoMessageStart: `Kampanjkod`,
  APA_ValidTime: `Giltig period`,
  APA_PromoMessageEnd: `Koden gäller på de produkter markerade med en procentuell rabatt`,
  APA_AQCDecription: `Åldersbeskrivning`,
  APA_Year: `år`,
  APA_Book: `Boka`,

  CPA_NothingAvailable_EmptyText: `Vänligen välj datum nedan`,
  CPA_NothingAvailable: `Det finns inga lediga rum som uppfyller dina kriterier`,
  CPA_SoldOut: `Utsålt`,
  CPA_CustomerInvalid: `Okänd kundprofil`,
  CPA_PromoInvalid: `Kampanjkoden är inte giltig`,
  CPA_PromoBetween: `Kampanjkoden du angav är endast giltig för vistelse mellan:`,

  RPA_More: `Visa mer`,
  RPA_Select: `Välj`,
  RPA_Selected: `Vald`,
  RPA_From: `Från`,
  RPA_Image: `Ingen bild`,
  RPA_Description: `Beskrivning`,
  RPA_ChooseRoom: `Välj rum`,
  RPA_ChoosePackage: `Välj Paket`,
  RPA_ChooseCabin: `Välj stuga`,
  RPA_ChooseHouse: `Välj hus`,
  RPA_ChooseUnit: `Välj enhet`,
  RPA_BackRate: `Välj ett annat paket`,
  RPA_MaxPersons: `Max antal personer`,
  RPA_ChooseYourRoom: `Välj rum`,
  RPA_ChooseYourCabin: `Välj stuga`,
  RPA_ChooseYourHouse: `Välj hus`,
  RPA_ChooseYourUnit: `Välj enhet`,
  RPA_ChooseYourPackage: `Välj paket`,

  APA_Services: `Tillägg`,
  APA_Add: `Lägg till`,
  APA_Added: `Tillagt`,
  APA_Remove: `Ta bort`,
  APA_ContentDialog: `Välj antal personer och de dagar du önskar tillägget. Mellan datumen`,
  APA_ChooseYourAddOns: `Tillägg`,
  APA_TotalPrice: `Total kostnad`,
  APA_TotalInBasket: `1 st, totalt`,
  APA_TotalOf: `Välj antal av`,

  CUP_Header: `Köparens information`,
  CUP_Accept: `Acceptera`,
  CUP_FName: `Namn`,
  CUP_FNameReq: `Ange förnamn`,
  CUP_FPhoneReq: `Ange mobilnummer`,
  CUP_LName: `Efternamn`,
  CUP_LNameReq: `Ange efternamn`,
  CUP_Address: `Adress`,
  CUP_AddressReq: `Ange adress`,
  CUP_ZipCode: `Postnummer`,
  CUP_ZipCodeReq: `Ange postnummer`,
  CUP_City: `Ort`,
  CUP_CityReq: `Ange ort`,
  CUP_Contry: `Land`,
  CUP_ContryReq: `Land gav ett fel, vänligen kontrollera`,
  CUP_Phone: `Mobilnummer`,
  CUP_PhoneReq: `Ange mobilnummer`,
  CUP_Email: `E-post`,
  CUP_EmailRepeat: `Upprepa E-post`,
  CUP_EmailReq: `Ange e-post`,
  CUP_EmailInvalid: `E-post ogiltig`,
  CUP_Comment: `Notering`,
  CUP_NeedSubscribe: `Prenumerera på nyhetsbrev`,
  CUP_AcceptTerms: `Jag har läst och godkänner villkoren`,
  CUP_BookNow: `Boka nu`,
  CUP_CancelPolicyHeader: `Avbokningsskydd`,
  CUP_CancelPolicyDesc: `Du har möjlighet att avboka din vistelse om du får förhinder. Se ytterligare upplysningar gällande avbokningsskydd i våra bokningsvillkor.`,
  CUP_CancelPolicyLabel: `Ja, jag vill gärna lägga till avbokningsskydd för`,
  CUP_GuestInfo: `Gästinformation`,
  CUP_BookingOverview: `Bokningsöversikt`,
  CUP_PayWithGiftcard: `För att betala med presentkort`,
  CUP_PayWithGiftcardClick: `Klicka här`,
  CUP_PayWithGiftcardDescription: `Ange din kod från ditt presentkort`,
  CUP_PayWithGiftcardError: `Presentkort är inte aktiva eller kunde inte hittas i systemet.`,
  CUP_PayWithGiftcardStatus: `Presentkort aktivt`,
  CUP_PayWithGiftcardDiscount: `Presentkortbelopp`,
  CUP_PayWithGiftcardHeader: `Presentkort`,
  CUP_PayWithGiftcardInformation: `Vill du lösa in ett presentkort?`,
  CUP_PayWithGiftcardPlaceholder: `Presentkortskod`,
  CUP_PayWithGiftcardRedeem: `Lös in`,
  CUP_BookingOverviewToPay: `Att betala`,

  PAL_GoToPayment: `Gå till betalning`,
  PAL_PaymentComplete: `Länken har upphört att gälla. Vänligen kontakta`,
  PAL_PaymentNotAvailable: `Betaling på denne reservation er ikke tilgængelig`,

  PAR_Payment: `Betalning`,
  PAR_SeekAuthorization: `Din kreditkortsinformation kommer att användas som betalning, alternativt som säkerhet för köp.`,
  PAR_TotalLabel: `Pris för din beställning`,
  PAR_TotalPayLabel: `Pris att betala`,
  PAR_OBSLabel: `VIKTIGT: Stäng inte detta fönster förrän kvittosidan dyker upp, där du kan se ditt beställnings-/reservationsnummer!`,
  PAR_OBSLabelGiftcard: `OBS: stanna kvar på sidan tills kvittosidan visas med ett beställningsnummer!`,
  PAR_FailedToPay: `Det gick inte att betala. Kontrollera ditt kortnummer.`,
  PAR_CardInvalid: `Kortnummer är ogiltigt`,
  PAR_CardUnknown: `Korttyp i inte känd`,
  PAR_CardMonthInvalid: `Månad är ogiltig`,
  PAR_CardYearInvalid: `År är ogiltig`,
  PAR_CardCVCInvalid: `CVV-kod är ogiltig`,
  PAR_FieldRequired: `Detta fält är tvingande`,
  PAR_Cancel: `Annullera`,
  PAR_Pay: `Betala`,
  PAR_PaymentMethod: `Betalningsmetoder`,
  PAR_Cardnumber: `Kortnummer`,
  PAR_MonthPlaceholder: `MM`,
  PAR_YearPlaceholder: `ÅÅ`,

  PFP_Header: `Oj! Det har inträffat ett fel`,
  PFP_Info: `Och din beställning inte har gått igenom. Försök igen genom att klicka på knappen nedan. Inga pengar har dragits från ditt konto.`,
  PFP_TryAgain: `Försök igen`,

  REP_Header: `Tack för din beställning,`,
  REP_Comment: `nedan hittar du ditt kvitto på din beställning.`,
  REP_DateRooms: `Ankomstdag`,
  REP_Return: `Tillbaka till hemsidan`,
  REP_OrderId: `Beställningsnummer`,
  REP_ReservationId: `Beställningsnummer`,
  REP_CustomerInfo: `Personuppgifter`,

  REP_Name: `Namn`,
  REP_PostCity: `Postnummer ort`,
  REP_Phone: `Telefon`,
  REP_Address: `Adress`,
  REP_Country: `Land`,
  REP_Email: `E-post`,
  REP_Thanks: `Tack för din beställning`,
  REP_ThanksInfo: `Du kommer att få en bekräftelse via e-post inom kort.`,
  REP_ThanksPayment: `Vår betalning är klar`,

  LOA_Addons: `Hämtar tillval`,
  LOA_CustomerInfo: `Hämtar kundinformation`,
  LOA_ActivatingCustomer: `Aktivera kund`,
  LOA_Translations: `Hämtar översättningar`,
  LOA_Configuration: `Läser in konfiguration`,
  LOA_Availability: `Hämtar ledighet`,
  LOA_CreatingReservation: `Skapar reservation`,
  LOA_HotelInformation: `Läser in hotellinformation`,
  LOA_Countries: `Laddar länder`,
  LOA_PreparingPaymnent: `Förbereder betalning`,
  LOA_ApplyingPromo: `Läser in rabattkod`,
  LOA_Rooms: `Laddar rum`,
  LOA_Rates: `Laddar priser`,
  LOA_LoggingIn: `Loggar in`,
  LOA_SendingInformation: `Skickar information`,

  COM_Continue: `Fortsätt`,
  COM_OK: `OK`,
  COM_Persons: `Personer`,
  COM_Guest_10: `Vuxna`,
  COM_Guest_9: `Ungdom`,
  COM_Guest_7: `Spädbarn`,
  COM_Guest_11: `Senior`,
  COM_Guest_8: `Barn`,
  COM_Adult: `vuxen`,
  COM_Child: `Barn`,
  COM_Room: `Rum`,
  COM_Cabin: `Stuga`,
  COM_House: `Hus`,
  COM_Unit: `Enhet`,
  COM_Arrival: `Ankomst`,
  COM_Departure: `Avresa`,
  COM_Promocode: `Kampanjkod`,
  COM_Rooms: `Rum`,
  COM_AddOns: `Tillval`,
  COM_TotalGuests: `Antal gäster`,
  COM_Price: `Pris`,
  COM_TotalPrice: `Totalbelopp`,
  COM_TotalPriceWithTransactionId: `Totalt betalt med transaktionsnr.`,
  COM_TotalReservedWithTransactionId: `Totalt reserverat med transaktionsnr.`,
  COM_Close: `Stäng`,
  COM_Back: `Tillbaka`,
  COM_Error: `Meddelande`,
  COM_Info: `Information`,
  COM_Confirm: `Bekräfta`,
  COM_Package: `Paket`,
  COM_SoldOutError: `Det finns inga tillgängliga rum eller paket för denna dag`,
  COM_SearchNew: `Ny sökning`,
  COM_TableReservation: `Bordsreservation`,
  COM_TableNumberOfPersons: `Antal personer`,
  COM_Guests: `Gäster`,
  COM_TableChooseProduct: `Välj datum och produkt`,
  COM_TablePlaceGuests: `&#8205;`,
  COM_TableChooseProducts: `Välj produkt/produkter`,
  COM_TableNumberOfGuestsForProduct: `Antal gäster`,
  COM_TableBookingFlowAddProduct: `Vill du boka bord för din vistelse?`,
  COM_TableIfNotPressContinue: `Annars välj fortsätt`,
  COM_AddToBasket: `Lägg till`,
  COM_AddedToBasket: `Tillagt`,
  COM_Type: `Typ`,
  COM_SeatingSoldOut: `Inget tillgängligt just nu`,
  COM_Main: `Hem`,
  COM_Conference: `Konference`,
  COM_Quantity: `Kvantitet`,

  LFO_Email: `E-post`,
  LFO_EmailRequried: `Ange e-post`,
  LFO_EmailInvalid: `E-post ogiltig`,
  LFO_Password: `Lösenord`,
  LFO_PasswordRequried: `Lösenord krävs`,
  LFO_ForgotPassword: `Kommer du inte åt ditt konto?`,
  LFO_Login: `Logga in`,
  LFO_BackToLogin: `Tillbaka till inloggning`,
  LFO_Send: `Skicka`,
  LFO_MessageSend: `Meddelande sänt!`,
  LFO_LoginError: `Felaktig e-post eller lösenord`,
  LFO_RecoveryError: `Inloggningsuppgifter ogiltiga`,

  SFO_ChooseHotel: `Våra hotell`,
  SFO_ChooseHolidayResort: `Våra Semesterort`,
  SFO_ChooseCenter: `Våra Centrum`,
  SFO_ChooseDepartment: `Välj Avdelning`,
  SFO_ParamsHeader: `Välj datum`,
  SFO_Guests: `Antal gäster i rum`,
  SFO_GuestsCabin: `Antal gäster i stuga`,
  SFO_GuestsHouse: `Antal gäster i Hus`,
  SFO_GuestsUnit: `Antal gäster i enhet`,
  SFO_AddRoom: `Boka fler rum`,
  SFO_AddCabin: `Boka fler stugor`,
  SFO_AddHouse: `Boka fler hus`,
  SFO_AddUnit: `Boka fler enheter`,
  SFO_BookStay: `vistelse`,
  SFO_BookEvent: `Event`,
  SFO_BookTakeAway: `Take Away`,
  SFO_BookTableReservation: `Bordsreservation`,
  SFO_BookGiftcard: `Presentkort`,
  SFO_GiftcardMetaData: `Grunddata för dina presentkort`,
  SFO_ChooseGiftcard: `Välj Presentkort`,
  SFO_NumberOfGiftcards: `Hur många av de valda presentkorten vill du köpa?`,
  SFO_GiftcardAmount: `Ange belopp`,
  SFO_GiftcardEnterDetails: `Fyll i detaljer för dina presentkort`,
  SFO_ChooseGiftcardDescription: `Köp ett presentkort till någon du bryr dig om`,
  SFO_GiftcardDetails: `Beskrivning av presentkortet`,
  SFO_GiftcardTo: `Vem är presentkortet till? (Valfritt)`,
  SFO_GiftcardFrom: `Vem är presentkortet från? (Valfritt)`,
  SFO_GiftcardMessage: `Skriv en hälsning (Max 100 tecken - Valfritt)`,
  SFO_BookArrangement: `Arrangemang`,
  SFO_BookConcert: `Konsert`,
  SFO_BookTableArangement: `Bord/Arrangemang`,
  SFO_BookTableEvent: `Bord/Upplevelser`,
  SFO_BordTakeAway: `Bord / Take away`,
  SFO_BookTicket: `Biljetter`,
  SFO_BookLecture: `Föreläsning`,
  SFO_BookTable: `Boka bord`,
  SFO_BookSpa: `Spa`,
  SFO_BookExperience: `Upplevelse`,
  SFO_ChooseMonth: `Välj månad`,
  SFO_NoRooms: `Inga tillgängliga rum hittades för den valda tidsperioden!`,
  SFO_MinLOS: `Den valda perioden kräver en bokning av minst`,
  SFO_Nights: `nätter`,
  SFO_MaxLOS: `Under den valda perioden är det möjligt att boka maximalt`,

  CAL_WeekStart: `1`,
  CAL_M01: `Januari`,
  CAL_M02: `Februari`,
  CAL_M03: `Mars`,
  CAL_M04: `April`,
  CAL_M05: `Maj`,
  CAL_M06: `Juni`,
  CAL_M07: `Juli`,
  CAL_M08: `Augusti`,
  CAL_M09: `September`,
  CAL_M10: `Oktober`,
  CAL_M11: `November`,
  CAL_M12: `December`,
  CAL_D0: `Söndag`,
  CAL_D1: `Måndag`,
  CAL_D2: `Tisdag`,
  CAL_D3: `Onsdag`,
  CAL_D4: `Torsdag`,
  CAL_D5: `Fredag`,
  CAL_D6: `Lördag`,
  CAL_Selected: `Valt datum`,
  CAL_Occupied: `Fullbokat/Stängt`,
  CAL_Arrival: `Stängt för ankomst`,
  CAL_Departure: `Stängt för avresa`,
  CAL_Mon: `M`,
  CAL_Tirs: `T`,
  CAL_Ons: `O`,
  CAL_Tors: `T`,
  CAL_Fre: `F`,
  CAL_Lor: `L`,
  CAL_Sun: `S`,
  CAL_Available: `Tillgängliga`,
  CAL_NotPossible: `Inte möjligt`,

  BIN_ChangeDate: `Byt datum`,
  BIN_ChangeRoom: `Ändra`,
  BIN_ChangeAddOns: `Ändra tillval`,
  BIN_NoAddOns: `Inga tillval är tillagda`,
  BIN_AddOns: `Tillval är tillagt`,
  BIN_Reservation: `Din bokning`,
  BIN_Details: `Din bokning`,
  BIN_RoomCount: `Rum`,
  BIN_CabinCount: `Hytter`,
  BIN_HouseCount: `Hus`,
  BIN_UnitCount: `Enheter`,
  BIN_Roomtype: `Rumstyp`,
  BIN_Cabintype: `Hyttyp`,
  BIN_Housetype: `Yyp av hus`,
  BIN_Unittype: `Enhetstyp`,
  BIN_Packagetype: `Paket typ`,
  BIN_BasketEmpty: `Korgen är tom`,
  BIN_BasketGoToCheckout: `Gå till kassan`,

  EXC_EmptyBasket: `Kundkorgen är tom`,

  SEA_Date: `datum`,
  SEA_Time: `tid`,
  SEA_Quantity: `Antal`,
  SEA_Skip: `Skip`,
  SEA_ChooseEvent: `Välj en upplevelse`,
  SEA_DescriptionStart: `Välj`,
  SEA_DescriptionEnd: `nedan eller datum i kalendern`,
  SEA_SoldOut: `Allt är tyvärr slutsålt den valda månaden`,
  SEA_SoldOutContinue: `Tryck på fortsätt för att hoppa över`,
  SEA_SeatingStandAloneTitle: `Välj ett datum eller ett av de som visas alternativ`,
  SEA_ResetCalendar: `Visa alla`,

  MAT_CALENDAR_Calendar_Label: `S`,
  MAT_CALENDAR_Close_Calendar_Label: `S`,
  MAT_CALENDAR_End_Date_Label: `S`,
  MAT_CALENDAR_Next_Month_Label: `S`,
  MAT_CALENDAR_Next_Multi_Year_Label: `S`,
  MAT_CALENDAR_Next_Year_Label: `S`,
  MAT_CALENDAR_Open_Calendar_Label: `S`,
  MAT_CALENDAR_Prev_Month_Label: `S`,
  MAT_CALENDAR_Prev_Multi_Year_Label: `S`,
  MAT_CALENDAR_Prev_Year_Label: `S`,
  MAT_CALENDAR_Start_Date_Label: `S`,
  MAT_CALENDAR_Switch_To_Month_View_Label: `S`,
  MAT_CALENDAR_Switch_To_Multi_Year_View_Label: `S`,
};

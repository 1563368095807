import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { LogService } from '@com/logging';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  ApplicationStateService,
  BookingService,
  HotelCodeString,
  HotelService,
  HotelType,
  Loader,
  LocaleService,
  OriginEnum,
  RoomCodeString,
} from 'src/app/core';
import { Messenger } from 'src/app/modules/messenger';
import { Step, StepList } from 'src/app/modules/step-list';
import { PaymentFlowEnum, PaymentService, ReservationType } from '../../core/modules/payment';
import { GiftcardInformationModel } from '../../components/giftcard-form/giftcard-form.component';
import { GiftcardReservationPayment } from '../../core/modules/hotel/data-hotel.service';
import { NavigationHelper } from '../../helpers/navigation-helper';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
})
export class PaymentLinkComponent implements OnInit {
  logo: string | undefined;
  contentDialogHeader: string | undefined;
  acceptTerms = false;
  hotel: HotelType | undefined;
  reservation: ReservationType | undefined;
  showRoomName = false;
  showServiceName = false;
  hasRooms = false;
  paymentComplete = false;
  paymentNotAvailable = false;
  hasSeatings = false;
  hasExtraservices = false;
  currency = '';
  giftcardInformation: GiftcardInformationModel | undefined;
  math = Math;
  hotelName: string | undefined;

  @ViewChild('messageHotelTermsTempalte', { static: false })
  messageHotelTermsTempalte: TemplateRef<HotelTermsMessage> | undefined;

  constructor(
    private log: LogService,
    private router: Router,
    private loader: Loader,
    private stepList: StepList,
    private route: ActivatedRoute,
    private localeService: LocaleService,
    private bookingService: BookingService,
    private messenger: Messenger,
    private hotelService: HotelService,
    private applicationState: ApplicationStateService,
    private navigationHelper: NavigationHelper,
    private paymentService: PaymentService,
  ) {}

  async ngOnInit() {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), 0)); // wait for router to initialize

    if (window.top?.location) {
      this.redicrectToRecieptParent(window.location, window.top.location);
    }

    this.applicationState.PaymentlinkCallBack = 'true';

    this.log.debug('PaymentLinkRoute ngOnInit()');
    window.scrollTo(0, 0);

    this.applicationState.Origin = OriginEnum.PaymentLink;

    // language param
    this.route.paramMap.pipe(switchMap((params: ParamMap) => of(params.get('language')))).subscribe(async (lang) => {
      if (lang) {
        await this.localeService.setLocale(LocaleService.ToLocale(lang as string));
      }
    });

    // language param
    this.route.paramMap.pipe(switchMap((params: ParamMap) => of(params.get('language')))).subscribe(async (lang) => {
      if (lang) {
        await this.localeService.setLocale(LocaleService.ToLocale(lang as string));
      }
    });

    // reservarId param
    this.route.paramMap.pipe(switchMap((params: ParamMap) => of(params.get('reservationId')))).subscribe((resId) => {
      this.applicationState.ReservationId = resId as string;
      this.applicationState.PaymentLinkGuid = resId as string;
    });

    await this.loadReservation(this.applicationState.ReservationId);

    const hotelInfo = await this.hotelService.GetECommerceDepartmentInformation(
      this.applicationState.CurrentHotelCode,
      await this.localeService.getLocale(),
    );

    if (hotelInfo) {
      this.hotelName = hotelInfo.Name;
    }
  }

  redicrectToRecieptParent(location: any, topLocation: any) {
    if (location !== topLocation) {
      if (window.top?.location) {
        window.top.location = location;
      }
    }
  }

  async loadReservation(reservationId: string) {
    try {
      this.stepList.set(Step.PaymentLink);
      this.log.debug(`PaymentLinkRoute loadReservation() start loading`);
      this.reservation = await this.bookingService.getReservation(reservationId, await this.localeService.getLocale());

      this.hotel = this.hotel = await this.loadHotel(this.reservation.HotelCode as HotelCodeString);
      this.applicationState.CurrentHotelCode = this.hotel.Code;

      const queryParams: Params = {
        HotelCode: this.applicationState.CurrentHotelCode,
      };

      await this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });

      await this.hotelService.BuildApplicationState();
      this.currency = this.applicationState.HotelCurrency;
      if (this.reservation.rooms[0]) {
        this.reservation.rooms.forEach((item) => {
          if (!this.hasExtraservices) {
            this.hasExtraservices = item.services.filter((x) => x.serviceType === 'EXTRA').length !== 0 ? true : false;
          }

          if (!this.hasSeatings) {
            this.hasSeatings = item.services.filter((x) => x.serviceType === 'SEATING').length !== 0 ? true : false;
          }

          if (item.roomCode === ('Deltagere' as RoomCodeString)) {
            this.hasRooms = false;
          } else {
            this.hasRooms = true;
          }
        });
      } else {
        if (this.reservation.services) {
          this.reservation.services.forEach((item) => {
            if (!this.hasSeatings) {
              this.hasSeatings = item.serviceType === 'SEATING' ? true : false;
            }
            if (!this.hasExtraservices) {
              this.hasExtraservices = item.serviceType === 'EXTRA' ? true : false;
            }
          });
        }
      }

      this.log.debug(`PaymentLinkRoute loadReservation() loaded`);
      this.showRoomName = this.showRoomNameIfNotSameAsBookerName(this.reservation);
      this.showServiceName = this.showServiceNameIfServicesAdded(this.reservation);
      this.paymentComplete = this.reservation.PaymentDue === 0;
      this.paymentNotAvailable = this.reservation.PaymentFlowToUse === PaymentFlowEnum.NoPayment;
      if (this.hotel.Settings.CustomColor) {
        this.applicationState.ApplicationColor = this.hotel.Settings.CustomColor;
      }
      const tempLogo = this.applicationState.ApplicationLogo;

      if (tempLogo) {
        this.logo = tempLogo;
      }
      this.contentDialogHeader = this.applicationState.ApplicationColor;
    } catch (e) {
      this.log.error('The following exception has been thrown', e);
      await this.router.navigate(['/payment-failed']);
    }
  }

  showRoomNameIfNotSameAsBookerName(res?: ReservationType) {
    if (res) {
      return res.rooms.some((room) => {
        if (room.guestName !== null) {
          return room.guestName.toLowerCase() !== res.bookingAdmin.lastName.toLowerCase();
        }
        return false;
      });
    }
    return false;
  }

  showServiceNameIfServicesAdded(res: ReservationType) {
    return (
      (res.services != null && res.services.length > 0) ||
      res.rooms.some((room) => {
        return room.services != null && room.services.length > 0;
      })
    );
  }

  onAcceptTermsChange(acceptTerms: boolean) {
    this.log.debug(`PaymentLinkRoute onAcceptTermsChange(${acceptTerms})`);
    this.acceptTerms = acceptTerms;
  }

  onShowTermsClick(terms: string, e: Event) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.messageHotelTermsTempalte) {
      throw new Error('messageHotelTermsTempalte must be defined');
    }
    const message = this.messenger.show(
      this.messageHotelTermsTempalte,
      {
        html: terms,
        accept: () => {
          this.acceptTerms = true;
          message.data.close();
        },
        close: () => {
          this.messenger.close(message);
        },
      },
      () => {
        message.data.close();
      },
    );
  }

  async onFormSubmit(f: NgForm) {
    this.log.debug('PaymentLinkRoute onFormSubmit()');
    let isValid = true;
    Object.keys(f.form.controls).forEach((key) => {
      const control = f.form.get(key);
      if (control) {
        if (!control.valid && !control.disabled) {
          control.markAsTouched();
          isValid = false;
        }
      }
    });

    if (isValid && this.reservation) {
      if (this.giftcardInformation) {
        this.reservation = this.subtractGiftcard(this.reservation, this.giftcardInformation);

        if (this.reservation.PaymentDue > 0) {
          await this.paymentService.startupPayment(this.giftcardInformation.Number);
        } else {
          await this.payAmountWithGiftcard(this.reservation);
          await this.bookingService.completePayment(this.reservation);
          await this.navigationHelper.continue('/customer', '/reciept');
        }
      } else {
        await this.paymentService.startupPayment();
      }
    }
  }

  async setGiftcardInformationModel(giftcardInformation: GiftcardInformationModel) {
    this.giftcardInformation = giftcardInformation;
  }

  subtractGiftcard(reservation: ReservationType, giftcardInformation: GiftcardInformationModel) {
    if (giftcardInformation && giftcardInformation.Amount > 0) {
      let amount;
      if (giftcardInformation.Amount < reservation.PaymentDue) {
        amount = reservation.PaymentDue - giftcardInformation.Amount;
        reservation.paidWithGiftcard = giftcardInformation.Amount;
      } else {
        amount = 0;
        reservation.paidWithGiftcard = reservation.PaymentDue;
      }

      reservation.PaymentDue = amount;

      const id = Number(reservation.reservationId);
      this.bookingService.updateSessionReservation(reservation);
      const model = {
        resGuid: reservation.resGuid,
        Amount: reservation.paidWithGiftcard,
        GiftCardNumber: giftcardInformation.Number,
        ReservationNumber: id,
      } as GiftcardReservationPayment;
      this.bookingService.updateSessionGiftcard(model);
    }

    return reservation;
  }

  async payAmountWithGiftcard(reservation: ReservationType) {
    if (this.giftcardInformation && reservation) {
      const id = Number(reservation.reservationId);

      const model = {
        Amount: reservation.paidWithGiftcard,
        GiftCardNumber: this.giftcardInformation.Number,
        ReservationNumber: id,
        resGuid: reservation.resGuid,
        currency: reservation.payment.currency,
      } as GiftcardReservationPayment;
      return await this.hotelService.payAmountWithGiftcard(model);
    }

    return undefined;
  }

  private async loadHotel(hotelCode: HotelCodeString) {
    this.log.debug(`PaymentLinkRoute loadHotel(${hotelCode})`);
    return await this.loader.using(async () => {
      return await this.hotelService.GetECommerceDepartmentInformation(hotelCode, await this.localeService.getLocale());
    }, 'LOA_HotelInformation');
  }
}

interface HotelTermsMessage {
  html: string;
  accept: () => void;
  close: () => void;
}

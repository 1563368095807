import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppQueryParams } from '../../../app.component';
import {
  ApplicationStateService,
  BasketQueryParams,
  BasketService,
  HotelService,
  LocaleService,
  SearchParams,
} from '../../../core';
import { ConferenceRoom } from '../../../core/modules/hotel/data-hotel.service';
import { DateHelper, RoomGuests } from '../../../helpers';
import { NavigationHelper } from '../../../helpers/navigation-helper';
import { SearchComponent } from '../../search/search.component';
import { CalendarModel, ConferenceSearchComponent, Day } from '../conference-search/conference-search.component';

@Component({
  selector: 'app-conference-details',
  templateUrl: './conference-details.component.html',
})
export class ConferenceDetailsComponent implements OnInit, OnDestroy {
  conferenceRoomInfo: ConferenceRoom | undefined;
  selectedImage: string | undefined;
  calendarDates: CalendarModel | undefined;
  currentDate = new Date();
  startDate: Date | undefined;
  startMonth: string | undefined;
  endDate: Date | undefined;
  endMonth: string | undefined;
  showCalendar = false;

  basket = this.getBasket(this.route.snapshot.queryParams as AppQueryParams);

  constructor(
    private navigationHelper: NavigationHelper,
    private basketService: BasketService,
    private route: ActivatedRoute,
    private hotelService: HotelService,
    private applicationState: ApplicationStateService,
    private localeService: LocaleService,
  ) {}

  async ngOnInit() {
    document.body.classList.remove('addBackGround');
    document.body.classList.remove('addBackGroundWithAnimation');
    document.body.style.backgroundImage = '';

    if (this.basket.params.ConferenceRoomCode) {
      this.conferenceRoomInfo = await this.hotelService.GetECommerceConferenceRoomInfo(
        this.applicationState.CurrentHotelCode,
        await this.localeService.getLocale(),
        this.basket.params.ConferenceRoomCode,
      );
      this.selectedImage = this.conferenceRoomInfo.ImageOfRoom1;
    }
    await this.buildCalendarModel();
    await this.prepareNavigation();
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async prepareNavigation() {
    // await this.navigationHelper.continueMobileNavigationbar(false, true, '/guests', '/products', { queryParams: { HotelCode: this.applicationState.CurrentHotelCode, ArrivalDate: DateHelper.toServerDateFormatString(new Date()), LengthOfStay: 30, guest: this.guestCounter } });
  }

  async goBack() {
    await this.navigationHelper.goBack();
  }

  async onMoveToNextClick() {
    await this.navigationHelper.continue('/conference-rooms', '/conference-details');
  }

  newImageClick(image: string) {
    this.selectedImage = image;
  }

  async nextMonth() {
    this.currentDate = DateHelper.addMonth(this.currentDate, 2);
    await this.buildCalendarModel();
  }

  async prevMonth() {
    this.currentDate = DateHelper.addMonth(this.currentDate, 0);
    await this.buildCalendarModel();
  }

  async buildCalendarModel() {
    const lastDay = DateHelper.getLastDateOfMonth(this.currentDate);
    const firstDay = DateHelper.getFirstDateOfMonth(this.currentDate);

    let dayCounter = 0;
    const model = {} as CalendarModel;
    model.days = [] as Day[];
    model.currentMonth = DateHelper.getTranslatedMonth(firstDay.getMonth());
    model.currentYear = firstDay.getFullYear();

    let dummyDaysAdded = false;

    while (dayCounter < lastDay.getDate()) {
      const day = {} as Day;

      day.date = DateHelper.addDays(firstDay, dayCounter);
      day.status = this.calculateStatus(day.date, new Date());
      day.active = day.status === dayStatus.open ? true : false;

      // Days before current monthdays (grayed out)
      if (!dummyDaysAdded) {
        let weekDay = day.date.getDay();
        let daysToSubract = this.calculateDaysToSubstract(weekDay);
        const days = this.daysToReach(weekDay);

        while (weekDay < days) {
          const dummyday = {} as Day;

          dummyday.date = DateHelper.addDays(firstDay, daysToSubract);
          dummyday.active = false;
          dummyday.status = dayStatus.invalid;

          model.days.push(dummyday);

          weekDay = weekDay + 1;
          daysToSubract = daysToSubract + 1;
        }
        dummyDaysAdded = true;
      }
      model.days.push(day);
      dayCounter = dayCounter + 1;
    }

    this.calendarDates = model;
  }

  calculateDaysToSubstract(weekDay: number) {
    switch (weekDay) {
      case 0: {
        return -6;
      }
      case 1: {
        return -5;
      }
      case 2: {
        return -1;
      }
      case 3: {
        return -3;
      }
      case 4: {
        return -3;
      }
      case 5: {
        return -4;
      }
      case 6: {
        return -7;
      }
      default: {
        return 0;
      }
    }
  }

  daysToReach(weekDay: number) {
    switch (weekDay) {
      case 0: {
        return 6;
      }
      case 1: {
        return 1;
      }
      case 2: {
        return 3;
      }
      case 3: {
        return 5;
      }
      case 4: {
        return 7;
      }
      case 5: {
        return 9;
      }
      case 6: {
        return 11;
      }
      default: {
        return 0;
      }
    }
  }

  calculateStatus(date: Date, currentMonth: Date) {
    if (currentMonth.getMonth() > date.getMonth() && currentMonth.getFullYear() === date.getFullYear()) {
      return dayStatus.runOut;
    } else if (currentMonth.getMonth() === date.getMonth() && date.getDate() < new Date().getDate()) {
      return dayStatus.invalid;
    }
    return dayStatus.open;
  }

  async dateClick() {
    if (this.showCalendar) {
      this.showCalendar = false;
    } else if (!this.showCalendar) {
      this.startDate = undefined;
      this.endDate = undefined;
      await this.buildCalendarModel();
      this.showCalendar = true;
    }
  }

  private getBasket(queryParams: Partial<BasketQueryParams>) {
    const hotelCode = SearchComponent.ParseHotelCode(queryParams);
    const conferenceRoomCode = ConferenceSearchComponent.ParseConferenceRoomCode(queryParams);

    if (hotelCode) {
      const params: SearchParams = {
        hotelCode,
        arrival: new Date(),
        stay: 0,
        rooms: [] as RoomGuests[],
        single: false,
        ArrivalDate: 0,
        ArrivalFullYear: 0,
        ArrivalMonthName: '',
        DepartueDate: 0,
        DepartueFullYear: 0,
        DepartueMonthName: '',
        BookingFlow: 1,
        RateCode: '',
        ConferenceRoomCode: conferenceRoomCode,
      };
      return this.basketService.create(params);
    } else {
      const basket = this.basketService.get();
      if (basket) {
        return basket;
      } else {
        throw new Error('Basket must not be empty');
      }
    }
  }
}

enum dayStatus {
  closed = '#F87E7D',
  open = 'white',
  invalid = '#a9a9a9',
  runOut = '#707070',
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent {
  isChecked = false;
  @Output() changed = new EventEmitter<boolean>();

  @Input()
  set checked(checked: boolean) {
    this.isChecked = checked;
  }

  constructor() {}

  onClick() {
    this.isChecked = !this.isChecked;
    this.changed.emit(this.isChecked);
  }
}

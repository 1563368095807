import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogService } from '@com/logging';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppQueryParams } from 'src/app/app.component';
import {
  ApplicationStateService,
  BasketQueryParams,
  BasketService,
  ConfigService,
  HotelService,
  HotelType,
  Loader,
  Locale,
  LocaleService,
  OriginEnum,
  PromotionService,
  SearchParams,
} from 'src/app/core';
import { DateHelper } from 'src/app/helpers';
import { Step, StepList } from 'src/app/modules/step-list';
import { NavigationHelper } from '../../helpers/navigation-helper';
import { DesignConfiguration } from '../../shared/design/design-configuration';
import { SearchFormConfig } from './search-form.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  private hotels$ = this.localeService.locale$.pipe(switchMap((locale) => from(this.loadHotels(locale))));

  config$ = combineLatest([
    this.route.queryParams as Observable<AppQueryParams>,
    from(this.loadConfigurations()),
    this.hotels$,
  ]).pipe(
    map(([queryParams, configuration, hotels]) => {
      const config: Partial<SearchFormConfig> = {
        single: SearchComponent.ParseSingle(queryParams),
        hotels,
        hotelCode: SearchComponent.ParseHotelCode(queryParams),
        arrival: SearchComponent.ParseArrival(queryParams),
        stay: SearchComponent.ParseStay(queryParams),
        rooms: SearchComponent.ParseRooms(queryParams),
        maxNights: configuration.MaxNights,
        maxMonth: configuration.MaxMonth,
        maxRooms: configuration.MaxRoomBook,
        defaultStay: configuration.stay,
        BookingFlow: SearchComponent.ParseBookingType(queryParams),
        RateCode: SearchComponent.ParseRateCode(queryParams),
      };
      if (config.BookingFlow) {
        this.applicationState.BookingFlow = config.BookingFlow.toString();
      }
      return config;
    }),
    tap((config) => {
      this.basketService.setHotelCode(config.hotelCode || null);
    }),
  );

  constructor(
    private log: LogService,
    private route: ActivatedRoute,
    private hotelService: HotelService,
    private loader: Loader,
    private basketService: BasketService,
    private localeService: LocaleService,
    private config: ConfigService,
    private stepList: StepList,
    private applicationState: ApplicationStateService,
    private designconfig: DesignConfiguration,
    private navigationHelper: NavigationHelper,
    private promotionService: PromotionService,
  ) {}

  async ngOnInit() {
    await this.loader.using(
      async () => {
        await this.hotelService.BuildApplicationState();
      },
      'LOA_Configuration',
      false,
    );

    await this.designconfig.addPageBackground();

    window.scrollTo(0, 0);
    this.applicationState.Origin = OriginEnum.OnlineBooking;
    this.stepList.set(Step.Search);
    this.basketService.clear();
  }

  async onSearchFormSubmit(params: SearchParams) {
    if (params.BookingFlow === 1) {
      this.applicationState.BookingFlow = params.BookingFlow.toString();
      await this.navigationHelper.continue(
        '/search',
        '/room/1',
        { queryParams: BasketService.BuildQueryParams(params) },
        true,
      );
    } else if (params.BookingFlow === 2) {
      this.applicationState.BookingFlow = params.BookingFlow.toString();
      await this.navigationHelper.continue(
        '/search',
        '/ratesRoom/1',
        { queryParams: BasketService.BuildQueryParams(params) },
        true,
      );
    } else {
      throw new TypeError('BookingFlow: ' + params.BookingFlow.toString() + ' is not supported!');
    }

    document.body.classList.remove('addBackGround');
    document.body.classList.remove('addBackGroundWithAnimation');
    document.body.style.backgroundImage = '';
  }

  async onHotelChange(hotel: HotelType) {
    this.log.debug(`SearchRoute onHotelChange(${hotel.Code})`);

    if (hotel.Code !== this.applicationState.CurrentHotelCode) {
      this.promotionService.promocode$.next(undefined);
    }
    await this.designconfig.applyHotelChange(hotel.Code);
  }

  private async loadConfigurations() {
    this.log.debug('SearchRoute loadConfigurations()');
    return await this.loader.using(
      async () => {
        try {
          const [{ MaxMonth, MaxNights, MaxRoomBook }, { stay }] = await Promise.all([
            this.config.getSearchParams(),
            this.config.getInitialSearchParams(),
          ]);

          return { MaxMonth, MaxNights, MaxRoomBook, stay };
        } catch (err: any) {
          this.log.error(`SearchRoute failed to load hotels. ${err.message}`);
          throw err;
        }
      },
      'LOA_Configuration',
      false,
    );
  }

  private async loadHotels(lang: Locale) {
    this.log.debug(`SearchRoute loadHotels(${lang})`);
    return await this.loader.using(
      async () => {
        try {
          return await this.hotelService.GetECommerceDepartmentsInformation();
        } catch (err: any) {
          this.log.error(`SearchRoute failed to load hotels. ${err.message}`);
          throw err;
        }
      },
      'LOA_HotelInformation',
      false,
    );
  }

  static ParseSingle(params: Partial<BasketQueryParams>) {
    return params.Single ? BasketService.ParseSingle(params.Single) : false;
  }

  static ParseHotelCode(params: Partial<BasketQueryParams>) {
    const hotelCode = params.HotelCode || (params as any).hotelCode; // Legacy hotelCode used - for customer activation
    return hotelCode ? BasketService.ParseHotelCode(hotelCode) : undefined;
  }

  static ParseArrival(params: Partial<BasketQueryParams>) {
    return params.ArrivalDate ? DateHelper.FromUtcDate(params.ArrivalDate) : undefined;
  }

  static ParseBookingType(params: Partial<BasketQueryParams>) {
    return params.BookingFlow ? +params.BookingFlow : undefined;
  }

  static ParseRateCode(params: Partial<BasketQueryParams>) {
    return params.RateCode ? params.RateCode : undefined;
  }

  static ParseStay(params: Partial<BasketQueryParams>) {
    return params.LengthOfStay !== undefined ? BasketService.ParseStay(params.LengthOfStay) : undefined;
  }

  static ParseRooms(params: Partial<BasketQueryParams>) {
    return params.RoomCounts ? BasketService.ParseRooms(params.RoomCounts) : undefined;
  }
}

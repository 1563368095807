import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DataHotelService } from './data-hotel.service';
import { HotelService } from './hotel.service';
// import { MockDataHotelService } from "./mock-data-hotel";

@NgModule({
  imports: [],
  declarations: [],
  providers: [{ provide: DataHotelService, useClass: DataHotelService }, HotelService],
  exports: [],
})
export class HotelModule {
  constructor(@Optional() @SkipSelf() parentModule: HotelModule) {
    if (parentModule) {
      throw new Error('HotelModule is already loaded. Import it in the CoreModule only');
    }
  }
}

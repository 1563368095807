import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationStateService, BasketService, BookingGiftcard } from '../../../core';
import { NavigationHelper } from '../../../helpers/navigation-helper';

@Component({
  selector: 'app-giftcarddetails',
  templateUrl: './giftcarddetails.component.html',
})
export class GiftcardDetailsComponent implements OnInit, OnDestroy {
  giftcards: BookingGiftcard[] | undefined;
  useFrom = false;
  useTo = false;
  useMessage = false;
  invalidValues = false;

  constructor(
    private basketService: BasketService,
    private navigationHelper: NavigationHelper,
    private applicationState: ApplicationStateService,
  ) {}

  async ngOnInit() {
    this.extractGiftcardsFromBasket();

    this.useFrom = this.applicationState.UseGiftcardFrom;
    this.useTo = this.applicationState.UseGiftcardTo;
    this.useMessage = this.applicationState.UseGiftcardMessage;
    await this.prepareNavigation();
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async moveNextClicked() {
    const basket = this.basketService.get();
    if (basket) {
      // We allways save giftcards on index 0 in the basket on the bookingarray, just like seatings.
      this.giftcards = basket.bookings[0].Giftcards;

      if (this.giftcards) {
        for (const item of this.giftcards) {
          item.TotalPrice = item.Count * item.Price;
        }
      }

      this.basketService.set(basket);

      await this.navigationHelper.continue('/details', '/customer');
    }
  }

  priceChanged(item: BookingGiftcard) {
    if (this.giftcards) {
      const card = this.giftcards.find((x) => x.Id === item.Id);
      if (card && item.Price <= 0) {
        card.Price = 1;
        this.invalidValues = true;
      } else {
        this.invalidValues = false;
      }
    }
  }

  extractGiftcardsFromBasket() {
    const basket = this.basketService.get();
    if (basket) {
      // We allways save giftcards on index 0 in the basket on the bookingarray, just like seatings.
      this.giftcards = basket.bookings[0].Giftcards;
    }
  }

  async prepareNavigation() {
    await this.navigationHelper.continueMobileNavigationbar(false, true, '/details', '/customer');
  }

  async goBack() {
    await this.navigationHelper.goBack();
  }
}

import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { ConfigService } from '../config';
import { ReservationType } from '../payment';
import { TrackingClient } from './tracking-client.interface';

@Injectable()
export class FacebookPixelClientService implements TrackingClient {
  private facebookPixel = this.initFacebookPixel();
  private page = '';

  constructor(private log: LogService, private configService: ConfigService) {}

  async pageView(page: string) {
    await this.withFacebookPixel((facebookPixel) => {
      if (this.page !== page) {
        this.page = page;
        facebookPixel('track', 'PageView');
        this.log.debug(`FacebookPixelService pageView(${page})`);
      }
    });
  }

  async addToCart(category: string, entityType: string, name: string, count: number) {
    await this.withFacebookPixel((facebookPixel) => {
      const item = {
        content_ids: [name],
        content_type: 'product',
        contents: [
          {
            id: name,
            quantity: count,
          },
        ],
      };
      facebookPixel('track', 'AddToCart', item);
      this.log.debug('FacebookPixelService track AddToCart', item);
    });
  }

  async revenue(reservation: ReservationType) {
    if (reservation && !reservation.payment) {
      return;
    }
    await this.withFacebookPixel((facebookPixel) => {
      const contents: FacebookPixelProductItem[] = [];
      reservation.rooms.map((room) => {
        contents.push({
          id: `${room.roomCode}-${room.ratePlan}`,
          quantity: 1,
          item_price: room.price,
        });
        room.services.map((service) => {
          contents.push({
            id: `${room.roomCode}-${room.ratePlan}-${service.serviceCode}`,
            quantity: service.count,
            item_price: service.price,
          });
        });
      });
      const transaction = {
        contents,
        currency: reservation.payment.currency,
        value: reservation.total,
        content_type: 'product',
      };
      facebookPixel('track', 'Purchase', transaction);
      this.log.debug('FacebookPixelService revenue() track Purchase', transaction);
    });
  }

  private async withFacebookPixel(handler: (facebookPixel: FacebookPixel) => Promise<void> | void) {
    const facebookPixel = await this.facebookPixel;
    if (facebookPixel) {
      if (handler instanceof Promise) {
        await handler(facebookPixel);
      } else {
        void handler(facebookPixel);
      }
    }
  }

  private async initFacebookPixel() {
    const config = await this.configService.getGaParams();
    const facebookPixelAccountCode = config.FacebookPixelAccountCode;
    if (facebookPixelAccountCode) {
      const googleAnalytics = FacebookPixelClientService.InitFacebookPixelScript(facebookPixelAccountCode);
      this.log.debug(`FacebookPixel is registered for '${facebookPixelAccountCode}' account`);
      return googleAnalytics;
    } else {
      return undefined;
    }
  }

  /*
<script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', 'your-pixel-id-goes-here');
  fbq('track', 'PageView');
</script>
    */
  private static InitFacebookPixelScript(accountCode: string) {
    const w = window as unknown as Window & { _fbq: FacebookPixel; fbq: FacebookPixel };
    if (!w.fbq) {
      // tslint:disable-next-line:only-arrow-functions
      const n: any = (w.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      });
      if (!w._fbq) {
        w._fbq = n;
      }
      n.push = n;
      n.loaded = false;
      n.version = '2.0';
      n.queue = [];
      const node = document.createElement('script');
      node.async = true;
      node.src = 'https://connect.facebook.net/en_US/fbevents.js';
      const firstScript = document.getElementsByTagName('script')[0];
      (firstScript.parentNode as Node).insertBefore(node, firstScript);
    }
    w.fbq('init', accountCode);
    w.fbq('track', 'PageView');
    return w.fbq;
  }
}

type FacebookPixel = (action: string, type: string, data?: any) => void;

interface FacebookPixelProductItem {
  id: string;
  quantity: number;
  item_price: number;
}

import { GuestType } from './guest-type.enum';

export class RoomGuests {
  static Categories = [
    GuestType.Adult,
    GuestType.UnknownType1,
    GuestType.Child,
    GuestType.Infant,
    GuestType.UnknownType2,
    GuestType.Senior,
  ];

  private aqcs = new Map<GuestType, number>();

  constructor(aqcs: number[] = []) {
    aqcs.forEach((count, index) => {
      this.set(RoomGuests.Categories[index], count);
    });
  }

  set(type: GuestType, count: number) {
    this.aqcs.set(type, count);
  }

  get(type: GuestType) {
    return this.aqcs.get(type) || 0;
  }

  getPersons() {
    return RoomGuests.Categories.reduce((sum, aqc) => sum + this.get(aqc), 0);
  }

  asArray() {
    return RoomGuests.Categories.map((category) => this.get(category));
  }

  toString() {
    return this.asArray().join(',');
  }

  toJSON() {
    return this.toString();
  }
}

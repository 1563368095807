<div class="page-content room-page fadeBody">
  <div class="container">
    <a class="desktop-show mobile-hide applicationColor" style="position: absolute; font-size: 20px" (click)="goBack()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >

    <p *ngIf="state" class="desktop-show mobile-hide" style="text-align: center; font-size: 40px; font-weight: 500">
      {{ state.EventLabel | t | async }}
    </p>
    <h2 *ngIf="state" style="text-align: center; margin-top: 20px">
      <span class="v-middle">{{ 'SEA_SeatingStandAloneTitle' | t | async }}</span>
    </h2>

    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />
  </div>
</div>

<div class="fadeBody seating-standalone">
  <div class="container">
    <div class="row" *ngIf="seatingData$ | async as data">
      <div class="col l4 no-gutters">
        <div class="seating-standalone__calendar">
          <seating-date-calendar
            [minDate]="minDate"
            [maxDate]="maxDate"
            [selectedDate$]="chosenDate$"
            [highlightDates]="data.highlightedDates"
            [availableDates]="data.availableDates"></seating-date-calendar>
          <!-- TODO: Translate -->
          <span *ngIf="data.chosenDate" class="seating-standalone__calendar-reset" (click)="resetDate()"
            >Nulstil valgte dato</span
          >
        </div>
      </div>
      <div class="col l8 m8 s12 no-gutters">
        <div class="seating-standalone__seatings">
          <div class="seatings__content">
            <ng-container *ngFor="let seating of data.seatings">
              <app-seating-standalone-card
                *ngIf="seating.show"
                [date]="data.chosenDate"
                [characteristics]="seating.characteristics"
                [header]="seating.header"
                [description]="seating.description"
                [imageSrc]="seating.image"
                [seatingData]="seating.seatingData"></app-seating-standalone-card>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

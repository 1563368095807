import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { Observable, Subject } from 'rxjs';
import { HotelCodeString } from '..';
import { Guid } from '../../helpers';
import { HotelType } from '../modules';
import { ReservationType } from '../modules/payment';

export enum OriginEnum {
  OnlineBooking = 1,
  PaymentLink = 2,
  GiftCard = 3,
}

@Injectable()
export class ApplicationStateService {
  get Origin(): OriginEnum {
    return this._origin;
  }
  set Origin(orig: OriginEnum) {
    this.log.debug(`AppStateService Origin = (${orig})`);
    this._origin = orig;
    this.subject.next(this._origin);
  }

  /**
   * This is the guid of the payment link that was used to find the reservation.
   */
  set PaymentLinkGuid(guid: string) {
    this.log.debug(`AppStateService PaymentLinkGuid = (${guid})`);
    sessionStorage.setItem('PaymentLinkGuid', guid);
  }
  /**
   * This is the guid of the payment link that was used to find the reservation.
   * we need this to be able to know if current reservation is the same as the paymentlink.
   * reservation, so we dont cancel the reservation by mistake.
   */
  get PaymentLinkGuid(): string {
    const guid = sessionStorage.getItem('PaymentLinkGuid');
    return guid !== null ? guid : '';
  }
  /**
   * We compare the ApplicationState.PaymentLinkGuid with the reservationGuid to see if the reservation should be handled as a paymentlink reservation.
   */
  IsReservationFromPaymentLink(reservation: ReservationType): boolean {
    const reservationGuid = reservation.resGuid?.toString();
    if (reservationGuid === undefined || reservationGuid === null || reservationGuid === '') {
      this.log.debug(
        `AppStateService IsReservationFromPaymentLink(${reservationGuid}) = (false) - reservationGuid is null or empty`,
      );
      return false;
    }
    const normalizedPaymentLinkGuid = this.PaymentLinkGuid.replace(/-/g, '').toUpperCase();
    const normalizedReservationGuid = reservationGuid.replace(/-/g, '').toUpperCase();
    const isReservationFromPaymentLink = normalizedPaymentLinkGuid === normalizedReservationGuid;
    this.log.debug(
      `AppStateService IsReservationFromPaymentLink(${reservationGuid}) = (${isReservationFromPaymentLink})`,
    );
    return normalizedPaymentLinkGuid === normalizedReservationGuid;
  }
  get ReservationId(): string {
    return this._reservationId;
  }
  set ReservationId(id: string) {
    this.log.debug(`AppStateService ReservationId = (${id})`);
    this._reservationId = id;
  }

  get DefaultMonthForCalendar(): number {
    return this.defaultMonthForCalendar;
  }

  get DepartureDate(): Date | null {
    return this.departureDate;
  }
  set DepartureDate(date: Date | null) {
    this.departureDate = date;
  }

  get PaymentlinkCallBack(): string {
    const res = localStorage.getItem('PLC');
    return res !== null ? res : 'false';
  }
  set PaymentlinkCallBack(PLC: string) {
    localStorage.setItem('PLC', PLC);
  }

  get ReservationGuid(): Guid {
    const resGuid = localStorage.getItem('ReservationGuid');
    return resGuid as Guid;
  }
  set ReservationGuid(reservationGuid: Guid) {
    localStorage.setItem('ReservationGuid', reservationGuid);
  }

  get ApplicationColor(): string {
    const color = localStorage.getItem('primaryDepartmentColor');
    return color !== null ? color : '';
  }
  set ApplicationColor(applicationColor: string) {
    localStorage.setItem('primaryDepartmentColor', applicationColor);
  }

  get ApplicationColorTextIcon(): string {
    const color = localStorage.getItem('primaryDepartmentColorTextIcon');
    return color !== null ? color : '';
  }
  set ApplicationColorTextIcon(applicationColorTextIcon: string) {
    localStorage.setItem('primaryDepartmentColorTextIcon', applicationColorTextIcon);
  }

  get ApplicationLogo(): string {
    const logo = localStorage.getItem('ApplicationLogo');
    return logo !== null ? logo : '';
  }
  set ApplicationLogo(applicationLogo: string) {
    localStorage.setItem('ApplicationLogo', applicationLogo);
  }

  get UseTableReservation(): boolean {
    return this.getBooleanValueFromLocalStorage('UseTableReservation');
  }
  set UseTableReservation(value: boolean) {
    localStorage.setItem('UseTableReservation', String(value));
  }

  get CustomLogoRedirectURL(): string {
    const code = localStorage.getItem('logoUrl') as string;
    return code == null ? '' : code;
  }
  set CustomLogoRedirectURL(logoUrl: string) {
    localStorage.setItem('logoUrl', logoUrl as string);
  }

  get InclueTableReservationBookingFlow(): boolean {
    return this.getBooleanValueFromLocalStorage('InclueTableReservationBookingFlow');
  }
  set InclueTableReservationBookingFlow(value: boolean) {
    localStorage.setItem('InclueTableReservationBookingFlow', String(value));
  }

  get InclueSeatingBookingFlow(): boolean {
    return this.getBooleanValueFromLocalStorage('InclueSeatingBookingFlow');
  }
  set InclueSeatingBookingFlow(value: boolean) {
    localStorage.setItem('InclueSeatingBookingFlow', String(value));
  }

  get UseSeatings(): boolean {
    return this.getBooleanValueFromLocalStorage('UseSeatings');
  }
  set UseSeatings(value: boolean) {
    localStorage.setItem('UseSeatings', String(value));
  }

  get DesignPackage(): string {
    const value = localStorage.getItem('DesignPackage') as string;
    return value;
  }
  set DesignPackage(designPackage: string) {
    localStorage.setItem('DesignPackage', designPackage);
  }

  get UseNavbarLinks(): boolean {
    return this.getBooleanValueFromLocalStorage('UseNavbarLinks');
  }
  set UseNavbarLinks(value: boolean) {
    localStorage.setItem('UseNavbarLinks', String(value));
  }

  get UseNetsEasy(): boolean {
    return this.getBooleanValueFromLocalStorage('UseNetsEasy');
  }
  set UseNetsEasy(value: boolean) {
    localStorage.setItem('UseNetsEasy', String(value));
  }

  get UseNetAxept(): boolean {
    return this.getBooleanValueFromLocalStorage('UseNetAxept');
  }
  set UseNetAxept(value: boolean) {
    localStorage.setItem('UseNetAxept', String(value));
  }

  get AcceptedCards(): string {
    const code = localStorage.getItem('AcceptedCards') as string;
    return code;
  }
  set AcceptedCards(animateBackground: string) {
    localStorage.setItem('AcceptedCards', animateBackground);
  }

  get UseSpectraOnlineBooking(): boolean {
    return this.getBooleanValueFromLocalStorage('UseSpectraOnlineBooking');
  }
  set UseSpectraOnlineBooking(value: boolean) {
    localStorage.setItem('UseSpectraOnlineBooking', String(value));
  }

  get UseConferenceModule(): boolean {
    return this.getBooleanValueFromLocalStorage('UseConferenceModule');
  }
  set UseConferenceModule(value: boolean) {
    localStorage.setItem('UseConferenceModule', String(value));
  }

  get UseGiftcardFrom(): boolean {
    return this.getBooleanValueFromLocalStorage('UseGiftcardFrom');
  }
  set UseGiftcardFrom(value: boolean) {
    localStorage.setItem('UseGiftcardFrom', String(value));
  }

  get UseGiftcardTo(): boolean {
    return this.getBooleanValueFromLocalStorage('UseGiftcardTo');
  }
  set UseGiftcardTo(value: boolean) {
    localStorage.setItem('UseGiftcardTo', String(value));
  }

  get UseGiftcardMessage(): boolean {
    return this.getBooleanValueFromLocalStorage('UseGiftcardMessage');
  }
  set UseGiftcardMessage(value: boolean) {
    localStorage.setItem('UseGiftcardMessage', String(value));
  }

  get UseGiftcard(): boolean {
    return this.getBooleanValueFromLocalStorage('UseGiftcard');
  }
  set UseGiftcard(value: boolean) {
    localStorage.setItem('UseGiftcard', String(value));
  }

  get PayWithGiftcard(): boolean {
    return this.getBooleanValueFromLocalStorage('PayWithGiftcard');
  }
  set PayWithGiftcard(value: boolean) {
    localStorage.setItem('PayWithGiftcard', String(value));
  }

  get ApplicationTextColor(): string {
    const color = localStorage.getItem('primaryTextColor');
    return color !== null ? color : '';
  }
  set ApplicationTextColor(applicationTextColor: string) {
    localStorage.setItem('primaryTextColor', applicationTextColor);
  }

  get CurrentHotelCode(): HotelCodeString {
    const code = localStorage.getItem('hotelcode') as HotelCodeString;
    return code;
  }
  set CurrentHotelCode(currentHotelCode: HotelCodeString) {
    localStorage.setItem('hotelcode', currentHotelCode as HotelCodeString);
  }

  get CustomReceiptRedirectURL(): string {
    const code = localStorage.getItem('redirectUrl') as string;
    return code == null ? '' : code;
  }
  set CustomReceiptRedirectURL(redirectUrl: string) {
    localStorage.setItem('redirectUrl', redirectUrl as string);
  }

  get AnimateBackground(): string {
    const code = localStorage.getItem('AnimateBackground') as string;
    return code;
  }
  set AnimateBackground(animateBackground: string) {
    localStorage.setItem('AnimateBackground', animateBackground);
  }

  get UseExtraServices(): boolean {
    return this.getBooleanValueFromLocalStorage('UseExtraServices');
  }
  set UseExtraServices(value: boolean) {
    localStorage.setItem('UseExtraServices', String(value));
  }

  get HotelCurrency(): string {
    const code = localStorage.getItem('HotelCurrency') as string;
    return code;
  }
  set HotelCurrency(value: string) {
    localStorage.setItem('HotelCurrency', value);
  }

  get NetsPaymentEnabled(): boolean {
    return this.getBooleanValueFromLocalStorage('NetsEasyPaymentEnabled');
  }
  set NetsPaymentEnabled(value: boolean) {
    localStorage.setItem('NetsEasyPaymentEnabled', value.toString());
  }

  get MaxAQC(): string {
    const value = localStorage.getItem('MaxAQC') as string;
    return value;
  }
  set MaxAQC(maxAQC: string) {
    localStorage.setItem('MaxAQC', maxAQC);
  }

  get BackGroundimage(): string {
    const value = localStorage.getItem('BackGroundimage') as string;
    return value === 'null' ? 'no-image' : value;
  }
  set BackGroundimage(backGroundimage: string) {
    localStorage.setItem('BackGroundimage', backGroundimage);
  }

  get ApplicationFont(): string {
    const value = localStorage.getItem('ApplicationFont') as string;
    return value;
  }
  set ApplicationFont(applicationFont: string) {
    localStorage.setItem('ApplicationFont', applicationFont);
  }

  get HotelLabel(): string {
    const value = localStorage.getItem('HotelLabel') as string;
    return value;
  }
  set HotelLabel(hotelLabel: string) {
    localStorage.setItem('HotelLabel', hotelLabel);
  }

  get RoomLabel(): string {
    const value = localStorage.getItem('RoomLabel') as string;
    return value;
  }
  set RoomLabel(roomLabel: string) {
    localStorage.setItem('RoomLabel', roomLabel);
  }

  get MoreRoomLabel(): string {
    const value = localStorage.getItem('MoreRoomLabel') as string;
    return value;
  }
  set MoreRoomLabel(moreRoomLabel: string) {
    localStorage.setItem('MoreRoomLabel', moreRoomLabel);
  }

  get ChooseRoomLabel(): string {
    const value = localStorage.getItem('ChooseRoomLabel') as string;
    return value;
  }
  set ChooseRoomLabel(chooseRoomLabel: string) {
    localStorage.setItem('ChooseRoomLabel', chooseRoomLabel);
  }

  get RoomTypeLabel(): string {
    const value = localStorage.getItem('RoomTypeLabel') as string;
    return value;
  }
  set RoomTypeLabel(roomTypeLabel: string) {
    localStorage.setItem('RoomTypeLabel', roomTypeLabel);
  }

  get RoomsLabel(): string {
    const value = localStorage.getItem('RoomsLabel') as string;
    return value;
  }
  set RoomsLabel(roomsLabel: string) {
    localStorage.setItem('RoomsLabel', roomsLabel);
  }

  get GuestInRoomLabel(): string {
    const value = localStorage.getItem('GuestInRoomLabel') as string;
    return value;
  }
  set GuestInRoomLabel(guestInRoomLabel: string) {
    localStorage.setItem('GuestInRoomLabel', guestInRoomLabel);
  }

  get ChooseYourRoom(): string {
    const value = localStorage.getItem('ChooseYourRoom') as string;
    return value;
  }
  set ChooseYourRoom(chooseYourRoom: string) {
    localStorage.setItem('ChooseYourRoom', chooseYourRoom);
  }

  get EventLabel(): string {
    const value = localStorage.getItem('EventLabel') as string;
    return value;
  }
  set EventLabel(eventLabel: string) {
    localStorage.setItem('EventLabel', eventLabel);
  }

  get BookingFlow(): string {
    const code = localStorage.getItem('bookingFlow');
    return code !== null ? code : '1';
  }
  set BookingFlow(currentBookingFlow: string) {
    localStorage.setItem('bookingFlow', currentBookingFlow);
  }

  get ReturnFromOnlySeating(): string {
    const code = localStorage.getItem('ReturnFromOnlySeating');
    return code !== null ? code : 'false';
  }
  set ReturnFromOnlySeating(onlySeating: string) {
    localStorage.setItem('ReturnFromOnlySeating', onlySeating);
  }

  get CalendarDateChange(): Observable<Date> {
    return this.calendarDateChange;
  }

  set CalendarDateChange(date: Observable<Date>) {
    this.calendarDateChange = date;
  }

  get HotelBasketInfo(): HotelType | undefined {
    return this._hotelBasketInfo;
  }
  set HotelBasketInfo(data: HotelType | undefined) {
    this._hotelBasketInfo = data;
  }

  get MandatoryEmail(): boolean {
    return this.getBooleanValueFromLocalStorage('MandatoryEmail');
  }
  set MandatoryEmail(value: boolean) {
    localStorage.setItem('MandatoryEmail', String(value));
  }

  get MandatoryPhone(): boolean {
    return this.getBooleanValueFromLocalStorage('MandatoryPhone');
  }
  set MandatoryPhone(value: boolean) {
    localStorage.setItem('MandatoryPhone', String(value));
  }

  get HeaderInjectText(): string {
    const value = localStorage.getItem('HeaderInjectText') as string;
    return value;
  }
  set HeaderInjectText(value: string) {
    localStorage.setItem('HeaderInjectText', String(value));
  }

  get UsePromotion(): boolean {
    return this.getBooleanValueFromLocalStorage('UsePromotion');
  }
  set UsePromotion(value: boolean) {
    localStorage.setItem('UsePromotion', String(value));
  }

  get GiftcardPayment(): string {
    return this._paymentUrl;
  }
  set GiftcardPayment(value: string) {
    this._paymentUrl = value;
  }

  get RemoveCheckoutButton(): boolean {
    return this.removeCheckoutButton;
  }
  set RemoveCheckoutButton(value: boolean) {
    this.removeCheckoutButton = value;
  }

  get UseSpectraOnlineBookingMenuPoint(): boolean {
    return this.getBooleanValueFromLocalStorage('UseSpectraOnlineBookingMenuPoint');
  }
  set UseSpectraOnlineBookingMenuPoint(value: boolean) {
    localStorage.setItem('UseSpectraOnlineBookingMenuPoint', String(value));
  }

  get UseSeatingMenuPoint(): boolean {
    return this.getBooleanValueFromLocalStorage('UseSeatingMenuPoint');
  }
  set UseSeatingMenuPoint(value: boolean) {
    localStorage.setItem('UseSeatingMenuPoint', String(value));
  }

  get UseTableReservationMenuPoint(): boolean {
    return this.getBooleanValueFromLocalStorage('UseTableReservationMenuPoint');
  }
  set UseTableReservationMenuPoint(value: boolean) {
    localStorage.setItem('UseTableReservationMenuPoint', String(value));
  }

  get UseGiftcardMenuPoint(): boolean {
    return this.getBooleanValueFromLocalStorage('UseGiftcardMenuPoint');
  }
  set UseGiftcardMenuPoint(value: boolean) {
    localStorage.setItem('UseGiftcardMenuPoint', String(value));
  }

  get UseConferenceBookingMenuPoint(): boolean {
    return this.getBooleanValueFromLocalStorage('UseConferenceBookingMenuPoint');
  }
  set UseConferenceBookingMenuPoint(value: boolean) {
    localStorage.setItem('UseConferenceBookingMenuPoint', String(value));
  }

  private getBooleanValueFromLocalStorage(key: string): boolean {
    return localStorage.getItem(key) !== 'false';
  }

  private subject = new Subject<any>();
  private _origin: OriginEnum = OriginEnum.OnlineBooking;
  private _reservationId = '';
  private _hotelBasketInfo: HotelType | undefined;
  private departureDate!: Date | null;
  private defaultMonthForCalendar = 2;
  private removeCheckoutButton = false;

  private _paymentUrl = '';

  calendarDateChange = new Observable<Date>();

  constructor(private log: LogService) {}

  getOriginObservable(): Observable<any> {
    return this.subject.asObservable();
  }

  stopScrolling(stop: boolean) {
    if (stop) {
      document.body.classList.add('disableScroll');
    } else {
      document.body.classList.remove('disableScroll');
    }
  }
}

import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { BehaviorSubject } from 'rxjs';
import { DateHelper, RoomGuests, StorageClient, UTCDateString } from 'src/app/helpers';
import { AQC } from 'src/app/core';
import { GiftCardConfiguration } from '../../pages/giftcard/products/giftcard.component';
import { HotelCodeString, RateCodeString, RoomCodeString } from '../modules';
import { BookingExtraServiceModel } from '../modules/hotel/data-hotel.service';
import { SeatProfileType } from '../modules/payment';

@Injectable()
export class BasketService {
  get basketBadgeNumberGet(): string {
    const basketBadgeNumber = localStorage.getItem('basketBadgeNumber');
    return basketBadgeNumber !== null ? basketBadgeNumber : '0';
  }
  set basketBadgeNumberSet(number: string) {
    localStorage.setItem('basketBadgeNumber', number);
  }

  private basket = new StorageClient(sessionStorage, 'Basket', null as BasketObj | null);
  basket$ = new BehaviorSubject(this.getBasketObj());
  hotelCode$ = new BehaviorSubject(null as HotelCodeString | null);

  basketBadgeNumber = '';

  constructor(private log: LogService) {
    this.basket$.subscribe((b) => {
      this.basket.set(b ? BasketService.PackBasket(b) : null);
      this.setHotelCode(b ? b.params.hotelCode : this.hotelCode$.value);
    });
  }

  getBasketObj(): Basket | null {
    const basket = this.basket.get();

    if (basket) {
      return BasketService.UnpackBasket(basket);
    }

    return null;
  }

  setHotelCode(hotelCode: HotelCodeString | null) {
    if (this.hotelCode$.value !== hotelCode) {
      this.hotelCode$.next(hotelCode);
    }
  }

  create(params: SearchParams) {
    const basket: Basket = { params, bookings: [] };
    this.basket$.next(basket);
    return basket;
  }

  set(basket: Basket) {
    this.basket$.next(basket);
    this.calculateBadgeNumber();
  }

  get() {
    return this.basket$.getValue();
  }

  clear() {
    this.log.debug('BasketService clear()');
    this.basket$.next(null);
  }

  showOrHideBasket() {
    const box = document.getElementById('basketBox') as HTMLElement;
    if (box !== null && !box.classList.contains('hideMessage')) {
      box.classList.add('hideMessage');
    } else {
      if (box !== null && box !== undefined) {
        box.classList.remove('hideMessage');
      }
    }
  }

  hideBasket() {
    const box = document.getElementById('basketBox') as HTMLElement;
    if (box !== null && box.classList.contains('hideMessage')) {
      box.classList.remove('hideMessage');
    }
  }

  calculateBadgeNumber() {
    const basket = this.basket.get();
    if (basket !== null) {
      let count = basket.bookings.length;
      for (const item of basket.bookings) {
        if (item.AddOns !== undefined) {
          count = count + item.AddOns.length;
        }
        if (item.Seatings !== undefined) {
          if (count === 1 && item.RateCode === ('' as RateCodeString) && item.RoomCode === ('' as RoomCodeString)) {
            count = item.Seatings.length;
          } else {
            count = count + item.Seatings.length;
          }
        }
        if (item.TableSeatings !== undefined) {
          if (count === 1 && item.RateCode === ('' as RateCodeString) && item.RoomCode === ('' as RoomCodeString)) {
            count = item.TableSeatings.length;
          } else {
            count = count + item.TableSeatings.length;
          }
        }
        if (item.Giftcards !== undefined) {
          if (count === 1 && item.RateCode === ('' as RateCodeString) && item.RoomCode === ('' as RoomCodeString)) {
            count = item.Giftcards.length;
          } else {
            count = count + item.Giftcards.length;
          }
        }
        this.basketBadgeNumberSet = count.toString();
      }
    } else {
      this.basketBadgeNumberSet = '0';
    }
  }

  static UnpackBasket(basket: BasketObj): Basket | null {
    try {
      return {
        params: BasketService.ParseQueryParams(basket.params),
        bookings: basket.bookings,
      };
    } catch (err) {
      return null;
    }
  }

  static PackBasket(basket: Basket): BasketObj {
    return {
      params: BasketService.BuildQueryParams(basket.params),
      bookings: BasketService.BuildBookings(basket.bookings),
    };
  }

  static ParseBookings(bookings: Booking[]): Booking[] {
    return bookings.map((booking) => {
      return booking;
    });
  }

  static ParseQueryParams(queryParams: BasketQueryParams) {
    const searchParams: SearchParams = {
      single: queryParams.Single ? this.ParseSingle(queryParams.Single) : false,
      hotelCode: this.ParseHotelCode(queryParams.HotelCode),
      arrival: DateHelper.FromUtcDate(queryParams.ArrivalDate),
      stay: this.ParseStay(queryParams.LengthOfStay),
      rooms: this.ParseRooms(queryParams.RoomCounts),
      ArrivalDate: 0,
      ArrivalFullYear: 0,
      ArrivalMonthName: '',
      DepartueDate: 0,
      DepartueFullYear: 0,
      DepartueMonthName: '',
      BookingFlow: queryParams.BookingFlow,
      RateCode: queryParams.RateCode,
      ConferenceRoomCode: '',
    };
    return searchParams;
  }

  static BuildBookings(bookings: Booking[]) {
    return bookings.map((booking) => {
      if (booking.AddOns) {
        booking.AddOns = booking.AddOns.map((addOn) => {
          addOn.Dates = addOn.Dates.map((date) => {
            // @ts-ignore - hack to store date in localstorage
            date.Date = date.Date.toString();
            return date;
          });
          return addOn;
        });
      }
      return booking;
    });
  }

  static BuildQueryParams(searchParams: SearchParams) {
    const queryParams: BasketQueryParams = {
      HotelCode: searchParams.hotelCode,
      ArrivalDate: DateHelper.format(searchParams.arrival, 'YYYY-MM-DD') as UTCDateString,
      LengthOfStay: searchParams.stay.toString(),
      RoomCounts: this.StringifyRooms(searchParams.rooms),
      Single: searchParams.single ? 'true' : undefined,
      BookingFlow: searchParams.BookingFlow,
      RateCode: searchParams.RateCode,
    };
    return queryParams;
  }

  static ParseSingle(single: string) {
    return single === 'true';
  }

  static ParseHotelCode(hotelCode: HotelCodeString) {
    return hotelCode;
  }

  static ParseStay(stay: string) {
    const res = +stay || 1;
    return res;
  }

  static ParseRooms(rooms: string) {
    return rooms.split(':').map((guests) => new RoomGuests(guests.split(',').map((x) => +x)));
  }

  private static StringifyRooms(rooms: RoomGuests[]) {
    return rooms.map((g) => g.toString()).join(':');
  }
}

export interface BasketObj {
  params: BasketQueryParams;
  bookings: Booking[];
}

export interface Basket {
  params: SearchParams;
  bookings: Booking[];
}

export interface BookingSeating {
  Id: string;
  Name: string;
  SinglePrice: number;
  TicketTotal: number | undefined;
  Date: Date;
  SeatCode: string;
  Time: string | null;
  SeatRate: string;
  SeatingPlanName: string;
  SeatGuest: SeatProfileType[];
  VareNr: string;
  day: string;
  month: string;
  year: string;
  seatId: number;
  type: BookingSeatingType;
  returnUrl?: {
    url?: string;
    queryParams?: object;
  };
  AQC: AQC;
}

type BookingSeatingType = 'SeatingBooking' | 'SeatingStandalone';

export interface BookingTable {
  Id: string;
  Date: Date;
  HotelCode: string;
  Time: string;
  translatedMonth: string;
  Total: number;
  SeatCode: string;
  SeatGuest: SeatProfileType[];
  SeatRate: string;
  Name: string;
  Day: number;
  Year: number;
  SeatingPlanName: string;
}

export interface Booking {
  RoomName: string;
  RateName: string;
  RoomCode: RoomCodeString;
  RateCode: RateCodeString;
  RateDescription: string;
  HasPromotionForCustomer?: boolean;
  Price: number | undefined;
  OriginalPrice: number | undefined;
  AddOns?: BookingExtraServiceModel[];
  Seatings?: BookingSeating[];
  TableSeatings?: BookingTable[];
  Giftcards?: BookingGiftcard[];
  ConferenceRoomCode: string;
  ConferenceRateCode: string;
}

export interface BookingGiftcard {
  Name: string;
  Price: number;
  TotalPrice: number;
  Id: number;
  Count: number;
  CustomPrice: boolean;
  GuestInformation: GiftCardGuestInformation;
  GiftConfiguration: GiftCardConfiguration;
}

export interface GiftCardGuestInformation {
  FromName: string;
  ToName: string;
  GiftcardMessage: string;
}

export interface BasketQueryParams {
  Single?: string;
  HotelCode: HotelCodeString;
  ArrivalDate: UTCDateString;
  LengthOfStay: string;
  RoomCounts: string;
  BookingFlow: number;
  RateCode?: string;
  ConferenceRoomCode?: string;
}

export interface SearchParams {
  single: boolean;
  hotelCode: HotelCodeString;
  arrival: Date;
  stay: number;
  rooms: RoomGuests[];
  ArrivalDate: number;
  ArrivalMonthName: string;
  ArrivalFullYear: number;
  DepartueDate: number;
  DepartueMonthName: string;
  DepartueFullYear: number;
  BookingFlow: number;
  RateCode: string | undefined;
  ConferenceRoomCode: string | undefined;
}

<div class="page-content fadeBody">
  <div class="container">
    <a class="desktop-show mobile-hide applicationColor" style="position: absolute; font-size: 20px" (click)="goBack()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >
    <a
      class="desktop-show mobile-hide applicationColor"
      *ngIf="!invalidValues"
      (click)="moveNextClicked()"
      style="font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>
    <a
      class="desktop-show mobile-hide"
      *ngIf="invalidValues"
      style="pointer-events: none; color: gray; font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>

    <p class="desktop-hide mobile-show" style="text-align: center; font-size: 40px; font-weight: 500">
      {{ 'SFO_GiftcardDetails' | t | async }}
    </p>
    <p class="desktop-show mobile-hide" style="text-align: center; font-size: 40px; font-weight: 500; margin-left: 9%">
      {{ 'SFO_GiftcardDetails' | t | async }}
    </p>

    <h2 style="text-align: center; margin-top: 20px">
      <span class="v-middle">{{ 'SFO_GiftcardEnterDetails' | t | async }}</span>
    </h2>

    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />
  </div>
  <div class="container applicationColor">
    <details open>
      <summary>
        <h1>{{ 'SFO_GiftcardMetaData' | t | async }}</h1>
      </summary>
      <div class="row">
        <div *ngFor="let item of giftcards">
          <div class="col m6">
            <h3 class="details-container">{{ 'COM_Type' | t | async }}: {{ item.Name }}</h3>
            <div class="details-container">
              <div *ngIf="!item.CustomPrice">
                <p>{{ 'COM_Price' | t | async }}</p>
                <input type="text" disabled value="{{ item.Price * item.Count | price | async }}" />
              </div>
              <div *ngIf="item.CustomPrice">
                <p>{{ 'SFO_GiftcardAmount' | t | async }}</p>
                <input
                  id="customPriceInput"
                  name="customPriceInput"
                  required
                  type="number"
                  min="1"
                  [ngModel]="item.Price"
                  (ngModelChange)="item.Price = $event"
                  (input)="priceChanged(item)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </details>

    <details *ngIf="useFrom || useTo || useMessage" open>
      <summary>
        <h1>{{ 'SFO_GiftcardEnterDetails' | t | async }}</h1>
      </summary>
      <div class="row">
        <div *ngFor="let item of giftcards">
          <div class="col m6">
            <h3 class="details-container">{{ 'COM_Type' | t | async }}: {{ item.Name }}</h3>
            <div class="details-container">
              <div *ngIf="useTo">
                <p>{{ 'SFO_GiftcardTo' | t | async }}</p>
                <input
                  type="text"
                  [ngModel]="item.GuestInformation.ToName"
                  (ngModelChange)="item.GuestInformation.ToName = $event" />
              </div>
              <div *ngIf="useFrom">
                <p>{{ 'SFO_GiftcardFrom' | t | async }}</p>
                <input
                  type="text"
                  [ngModel]="item.GuestInformation.FromName"
                  (ngModelChange)="item.GuestInformation.FromName = $event" />
              </div>
              <div *ngIf="useMessage">
                <p>{{ 'SFO_GiftcardMessage' | t | async }}</p>
                <textarea
                  style="height: 200px"
                  [ngModel]="item.GuestInformation.GiftcardMessage"
                  (ngModelChange)="item.GuestInformation.GiftcardMessage = $event"
                  maxlength="100"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </details>

    <div class="container text-center" style="margin-bottom: 40px">
      <div class="section">
        <button
          type="button"
          class="btn btn-primary with-shadow"
          (click)="moveNextClicked()"
          [disabled]="invalidValues">
          {{ 'COM_Continue' | t | async }}
        </button>
      </div>
    </div>
  </div>
</div>

import { Messages } from './index';

export const messages: Messages = {
  APA_CompanyLogin: `Unternehmen Login`,
  APA_Logout: `Abmelden`,
  APA_Welcome: `Wilkommen`,
  APA_ShowPromo: `Aktionscode`,
  APA_PromoLabel: `Aktionscode eintragen`,
  APA_Promo: `Aktionscode`,
  APA_PromoSubmit: `Benutzen`,
  APA_PromoChange: `Veränderung`,
  APA_ActivateHead: `Aktivieren Sie Ihr Login`,
  APA_ActivateDesc: `Geben Sie das Passwort ein, um den Login zu aktivieren`,
  APA_Password: `Passwort`,
  APA_ConfirmPassword: `Passwort bestätigen`,
  APA_PasswordHasUpper: `Das Passwort muss mindestens einen Großbuchstaben enthalten (A-Z)`,
  APA_PasswordHasLower: `Passwort muss mindestens einen Kleinbuchstaben enthalten (a-z)`,
  APA_PasswordHasNumber: `Passwort muss mindestens eine Nummer enthalten (0-9)`,
  APA_PasswordLength8: `Das Passwort muss mindestens 8 Zeichen enthalten`,
  APA_ConfirmPasswordEqualTo: `Falsches Passwort`,
  APA_Activate: `Aktiveren`,
  APA_CustomerNotFound: `Kunde nicht verfügbar`,
  APA_ResetBasket: `Alles wird aus dem Warenkorb entfernt werden. Bist du sicher?`,
  APA_Address: `Adresse`,
  APA_Contact: `Kontakt`,
  APA_VatNumber: `CVR:`,
  APA_PromoMessageStart: `Kampagne Kode`,
  APA_ValidTime: `Gültigkeitsperiode`,
  APA_PromoMessageEnd: `Der Kode gilt für alle Produkte die mit einem prozentualen Rabatt markiert sind.`,
  APA_AQCDecription: `Altersbeschreibung`,
  APA_Year: `Jahre`,
  APA_Book: `Buchen`,

  CPA_NothingAvailable_EmptyText: `Bitte wählen Sie ein neues Datum unten, oder gehen Sie zurück und Ihre Informationen aus dem ersten Schritt ändern`,
  CPA_NothingAvailable: `Es gibt keine Zimmer, die Ihren Kriterien entsprechen`,
  CPA_SoldOut: `Ausverkauft`,
  CPA_CustomerInvalid: `Der Kunde ist nicht gültig`,
  CPA_PromoInvalid: `Aktionscode ist ungültig`,
  CPA_PromoBetween: `Der eingegebene Aktionscode ist nur gültig, wenn sich der Aufenthalt im diesem Zeitraum befindet:`,

  RPA_More: `Mehr`,
  RPA_Select: `Wählen`,
  RPA_Selected: `Ausgewählt`,
  RPA_From: `Beginnend ab`,
  RPA_Image: `Kein bild`,
  RPA_Description: `Beschreibung`,
  RPA_ChooseRoom: `Zimmer Wählen`,
  RPA_ChoosePackage: `Paket Wählen`,
  RPA_ChooseCabin: `Kabine Wählen`,
  RPA_ChooseHouse: `Haus Wählen`,
  RPA_ChooseUnit: `Einheit Wählen`,
  RPA_BackRate: `Wählen Sie ein anderes Paket`,
  RPA_MaxPersons: `Maximale Anzahl von Personen`,
  RPA_ChooseYourRoom: `Wählen Sie Ihr Zimmer`,
  RPA_ChooseYourCabin: `Wählen Sie Ihre Kabine`,
  RPA_ChooseYourHouse: `Wähle dein Haus`,
  RPA_ChooseYourUnit: `Wählen sie ihren Gerätetyp`,
  RPA_ChooseYourPackage: `Wählen Sie Ihr Paket`,

  APA_Services: `Dienstleistungen`,
  APA_Add: `Hinzufügen`,
  APA_Added: `Hinzugefügt`,
  APA_Remove: `Löschen`,
  APA_ContentDialog: `Wählen Sie die Anzahl der Personen und Tage aus an denen der Dienst ausgewählt werden soll. Zwischen den Daten`,
  APA_ChooseYourAddOns: `Wählen Sie Zusätzliche Leistungen`,
  APA_TotalPrice: `Gesamtkosten`,
  APA_TotalInBasket: `Stück, insgesamt`,
  APA_TotalOf: `Wählen Sie eine Menge von`,

  CUP_Header: `Gästedetails`,
  CUP_Accept: `Akzeptieren`,
  CUP_FName: `Vorname`,
  CUP_FNameReq: `Vorname ist erforderlich`,
  CUP_FPhoneReq: `Handynummer ist erforderlich`,
  CUP_LName: `Nachname`,
  CUP_LNameReq: `Nachname ist erforderlich`,
  CUP_Address: `Adresse`,
  CUP_AddressReq: `Adresse ist erforderlich`,
  CUP_ZipCode: `Postleitzahl`,
  CUP_ZipCodeReq: `PLZ erforderlich`,
  CUP_City: `Stadt`,
  CUP_CityReq: `Stadt ist erforderlich`,
  CUP_Contry: `Land`,
  CUP_ContryReq: `Land erforderlich`,
  CUP_Phone: `Handynummer`,
  CUP_PhoneReq: `Handynummer ist erforderlich`,
  CUP_Email: `E-mail`,
  CUP_EmailRepeat: `Wiederholen E-mail`,
  CUP_EmailReq: `Email ist erforderlich`,
  CUP_EmailInvalid: `E-Mail nicht gültig`,
  CUP_Comment: `Kommentar`,
  CUP_NeedSubscribe: `Newsletter abonnieren`,
  CUP_AcceptTerms: `Ich habe die Bedingungen gelesen und akzeptier`,
  CUP_BookNow: `Jetzt buchen`,
  CUP_CancelPolicyHeader: `Reiserücktrittsversicherung`,
  CUP_CancelPolicyDesc: `Sie haben die Möglichkeit Ihren Aufenthalt zu stornieren, wenn Sie es nicht machen können. Sehen Sie weitere Informationen in Bezug auf Rücktrittsversicherung in unseren Buchungsbedingungen.`,
  CUP_CancelPolicyLabel: `Ja, ich möchte Rücktrittsversicherung kaufen für`,
  CUP_GuestInfo: `Gästeinformation`,
  CUP_BookingOverview: `Buchungsübersicht`,
  CUP_PayWithGiftcard: `Mit Geschenkkarte bezahlen`,
  CUP_PayWithGiftcardClick: `Klicke hier`,
  CUP_PayWithGiftcardDescription: `Geben Sie Ihren Code von Ihrer Geschenkkarte ein`,
  CUP_PayWithGiftcardError: `Geschenkkarten sind nicht aktiv oder konnten nicht im System gefunden werden.`,
  CUP_PayWithGiftcardStatus: `Geschenkkarte aktiv`,
  CUP_PayWithGiftcardDiscount: `Geschenkkartenbetrag`,
  CUP_PayWithGiftcardHeader: `Geschenkkarte`,
  CUP_PayWithGiftcardInformation: `Sie möchten einen Geschenkgutschein einlösen?`,
  CUP_PayWithGiftcardPlaceholder: `Geschenkgutscheincode`,
  CUP_PayWithGiftcardRedeem: `Einlösen`,
  CUP_BookingOverviewToPay: `Bezahlen`,

  PAL_GoToPayment: `Zur Zahlung gehen`,
  PAL_PaymentComplete: `Der Link ist abgelaufen. Bitte kontaktieren`,
  PAL_PaymentNotAvailable: `Payment on this reservation is not available`,

  PAR_Payment: `Zahlung`,
  PAR_SeekAuthorization: `Ihre Kreditkartendaten werden als Zahlungsmittel, oder als Garantie für den Kauf verwendet.`,
  PAR_TotalLabel: `Preis für Ihre Bestellung`,
  PAR_TotalPayLabel: `Zu zahlender Preis`,
  PAR_OBSLabel: `WICHTIG: Schließen Sie dieses Fenster nicht, bis die Quittungsseite erscheint, auf der Sie Ihre Bestell- / Reservierungsnummer sehen können!`,
  PAR_OBSLabelGiftcard: `HINWEIS: Bleiben Sie auf der Seite bis die Quittungsseite mit einer bestellnummer angezeigt wird`,
  PAR_FailedToPay: `Fehlgeschlagen zu zahlen. Überprüfen Sie Ihre Kartennummer.`,
  PAR_CardInvalid: `Kartennummer ist ungültig`,
  PAR_CardUnknown: `Speicherkarten-Typ nicht bekannt`,
  PAR_CardMonthInvalid: `Monat ist ungültig`,
  PAR_CardYearInvalid: `Jahr ist ungültig`,
  PAR_CardCVCInvalid: `CVV-Code ist ungültig`,
  PAR_FieldRequired: `Dieses Feld wird benötigt`,
  PAR_Cancel: `Schliessen`,
  PAR_Pay: `Zahlen`,
  PAR_PaymentMethod: `Zahlungsmethode`,
  PAR_Cardnumber: `Kartennummer`,
  PAR_MonthPlaceholder: `MM`,
  PAR_YearPlaceholder: `JJ`,

  PFP_Header: `Huf! Leider ist ein Fehler aufgetreten`,
  PFP_Info: `Und Ihr Auftrag durch nicht gegangen. Bitte versuchen Sie es erneut, indem Sie den Button klicken. Kein Geld wurde von Ihrem Konto abgezogen.`,
  PFP_TryAgain: `Versuchen Sie es nochmal`,

  REP_Header: `Vielen Dank für Ihre Bestellung,`,
  REP_Comment: `Weiter unten finden Sie Ihre Quittung für Ihre Bestellung.`,
  REP_DateRooms: `Reisedatum`,
  REP_Return: `Zurück zur Website`,
  REP_OrderId: `Bestellnummer`,
  REP_ReservationId: `Bestellnummer`,
  REP_CustomerInfo: `Persönliche Angaben`,

  REP_Name: `Name`,
  REP_PostCity: `Postleitzahl Stadt`,
  REP_Phone: `Telefon`,
  REP_Address: `Adresse`,
  REP_Country: `Land`,
  REP_Email: `Email`,
  REP_Thanks: `Vielen Dank für Ihre Bestellung`,
  REP_ThanksInfo: `Innerhalb kurzer Zeit bekommen Sie einen Bestätigung auf Email.`,
  REP_ThanksPayment: `Unsere Zahlung ist abgeschlossen`,

  LOA_Addons: `Laden Add-ons`,
  LOA_CustomerInfo: `Laden Kundeninformationen`,
  LOA_ActivatingCustomer: `aktivieren Kunden`,
  LOA_Translations: `Laden Übersetzungen`,
  LOA_Configuration: `Laden Konfiguration`,
  LOA_Availability: `Laden Verfügbarkeit`,
  LOA_CreatingReservation: `Erstellen Reservierung`,
  LOA_HotelInformation: `Laden Hotelinformationen`,
  LOA_Countries: `Laden Länder`,
  LOA_PreparingPaymnent: `Vorbereitung Zahlung`,
  LOA_ApplyingPromo: `Anwenden von Promotion-Code`,
  LOA_Rooms: `Laden Zimmer`,
  LOA_Rates: `Loading Preise`,
  LOA_LoggingIn: `Einloggen`,
  LOA_SendingInformation: `Senden von Informationen`,

  COM_Continue: `Fortsetzen`,
  COM_OK: `OK`,
  COM_Persons: `Personen`,
  COM_Guest_10: `Erwachsene`,
  COM_Guest_9: `Teens`,
  COM_Guest_7: `Babys`,
  COM_Guest_11: `Senioren`,
  COM_Guest_8: `Kinder`,
  COM_Adult: `Erwachsene`,
  COM_Child: `Kind`,
  COM_Room: `Zimmer`,
  COM_Cabin: `Kabine`,
  COM_House: `Haus`,
  COM_Unit: `Einheit`,
  COM_Arrival: `Ankunft`,
  COM_Departure: `Abreise`,
  COM_Promocode: `Werbe-Code`,
  COM_Rooms: `Zimmer`,
  COM_AddOns: `Add-ons`,
  COM_TotalGuests: `Gäste insgesamt`,
  COM_Price: `Preis`,
  COM_TotalPrice: `Gesamtpreis`,
  COM_TotalPriceWithTransactionId: `Mit transactionID Insgesamt gezahlt`,
  COM_TotalReservedWithTransactionId: `Insgesamt reserviert mit transactionID`,
  COM_Close: `Schließen`,
  COM_Back: `Zurück`,
  COM_Error: `Info`,
  COM_Info: `Information`,
  COM_Confirm: `Bestätigen`,
  COM_Package: `Pakete`,
  COM_SoldOutError: `Für diesen Tag sind keine Zimmer oder Pakete verfügbar`,
  COM_SearchNew: `Neue Suche`,
  COM_TableReservation: `Tischreservierung`,
  COM_TableNumberOfPersons: `Anzahl der Personen am Tisch`,
  COM_Guests: `Gäste`,
  COM_TableChooseProduct: `Datum und Produkt auswählen`,
  COM_TablePlaceGuests: `Sie müssen platzieren`,
  COM_TableChooseProducts: `Produkt(e) auswählen`,
  COM_TableNumberOfGuestsForProduct: `Anzahl der Gäste für dieses Produkt`,
  COM_TableBookingFlowAddProduct: `Möchten Sie einen Tisch für Ihren Aufenthalt reservieren?`,
  COM_TableIfNotPressContinue: `Andernfalls drücken Sie Weiter`,
  COM_AddToBasket: `Anzeige in den Warenkorb`,
  COM_AddedToBasket: `In den Warenkorb gelegt`,
  COM_Type: `Art`,
  COM_SeatingSoldOut: `Momentan nichts verfügbar`,
  COM_Main: `Startseite`,
  COM_Conference: `Konference`,
  COM_Quantity: `Menge`,

  LFO_Email: `E-mail`,
  LFO_EmailRequried: `Email ist erforderlich`,
  LFO_EmailInvalid: `E-Mail nicht gültig`,
  LFO_Password: `Passwort`,
  LFO_PasswordRequried: `Passwort erforderlich`,
  LFO_ForgotPassword: `Können Sie Ihre Konto nicht angehen`,
  LFO_Login: `Einloggen`,
  LFO_BackToLogin: `Einloggen`,
  LFO_Send: `Senden`,
  LFO_MessageSend: `Nachrichten gesendet!`,
  LFO_LoginError: `Falsche E-Mail oder Passwort`,
  LFO_RecoveryError: `Login-Informationen ungültig`,

  SFO_ChooseHotel: `Unsere Hotels`,
  SFO_ChooseHolidayResort: `Unsere Feriensiedlung`,
  SFO_ChooseCenter: `Unsere Center`,
  SFO_ChooseDepartment: `Abteilung wählen`,
  SFO_ParamsHeader: `Datum Wähle`,
  SFO_Guests: `Gäste im Zimmer`,
  SFO_GuestsCabin: `Gäste im Kabine`,
  SFO_GuestsHouse: `Gäste im Haus`,
  SFO_GuestsUnit: `Gäste im einheit`,
  SFO_AddRoom: `Zimmer hinzufügen`,
  SFO_AddCabin: `Kabinen hinzufügen`,
  SFO_AddHouse: `Häuser hinzufügen`,
  SFO_AddUnit: `einheiten hinzufügen`,
  SFO_BookStay: `Aufenthalt`,
  SFO_BookEvent: `Event`,
  SFO_BookTakeAway: `Wegbringen`,
  SFO_BookTableReservation: `Tischreservierung`,
  SFO_BookGiftcard: `Geschenkkarte`,
  SFO_GiftcardMetaData: `Grunddaten für Ihre Geschenkkarten`,
  SFO_ChooseGiftcard: `Wählen Sie eine Geschenkkarte`,
  SFO_NumberOfGiftcards: `Wie viele der ausgewählten Geschenkkarten möchten Sie kaufen?`,
  SFO_GiftcardAmount: `Menge eingeben`,
  SFO_GiftcardEnterDetails: `Geben Sie Details für Ihre Geschenkkarten ein`,
  SFO_ChooseGiftcardDescription: `Kaufen Sie eine Geschenkkarte für jemanden den Sie interessieren`,
  SFO_GiftcardDetails: `Geschenkkartendetails`,
  SFO_GiftcardTo: `Für wen ist die Geschenkkarte (optional)`,
  SFO_GiftcardFrom: `Von wen ist die Geschenkkarte (optional)`,
  SFO_GiftcardMessage: `Schreiben Sie eine Begrüßung (max. 100 Zeichen) (optional)`,
  SFO_BookArrangement: `Anordnung`,
  SFO_BookConcert: `Konzert`,
  SFO_BookTableArangement: `Tisch / Anordnung`,
  SFO_BookTableEvent: `Tabelle / Ereignis`,
  SFO_BordTakeAway: `Bord / mitnehmen`,
  SFO_BookTicket: `Fahrkarte`,
  SFO_BookLecture: `Vorlesung`,
  SFO_BookTable: `Einen Tisch reservieren`,
  SFO_BookSpa: `Spa`,
  SFO_BookExperience: `Lebnis`,
  SFO_ChooseMonth: `Wählen Sie Monat`,
  SFO_NoRooms: `Für den ausgewählten Zeitraum wurden keine verfügbaren Zimmer gefunden!`,
  SFO_MinLOS: `Der ausgewählte Zeitraum erfordert eine Buchung von mindestens`,
  SFO_Nights: `nächte`,
  SFO_MaxLOS: `Während des ausgewählten Zeitraums ist eine Buchung maximal möglich`,

  CAL_WeekStart: `1`,
  CAL_M01: `Januar`,
  CAL_M02: `Februar`,
  CAL_M03: `März`,
  CAL_M04: `April`,
  CAL_M05: `Mai`,
  CAL_M06: `Juni`,
  CAL_M07: `Juli`,
  CAL_M08: `August`,
  CAL_M09: `September`,
  CAL_M10: `Oktober`,
  CAL_M11: `November`,
  CAL_M12: `Dezember`,
  CAL_D0: `Sonntag`,
  CAL_D1: `Montag`,
  CAL_D2: `Dienstag`,
  CAL_D3: `Mittwoch`,
  CAL_D4: `Donnerstag`,
  CAL_D5: `Freitag`,
  CAL_D6: `Samstag`,
  CAL_Selected: `Ausgewählt`,
  CAL_Occupied: `Geschlossen/Ausverkauft`,
  CAL_Arrival: `wegen Ankunft geschlossen`,
  CAL_Departure: `wegen Abreise geschlossen`,
  CAL_Mon: `M`,
  CAL_Tirs: `D`,
  CAL_Ons: `M`,
  CAL_Tors: `D`,
  CAL_Fre: `F`,
  CAL_Lor: `S`,
  CAL_Sun: `S`,
  CAL_Available: `Verfügbar`,
  CAL_NotPossible: `Nicht möglich`,

  BIN_ChangeDate: `Datum ändern`,
  BIN_ChangeRoom: `Ändern`,
  BIN_ChangeAddOns: `Ändern Sie Add-ons`,
  BIN_NoAddOns: `Keine Dienste hinzugefügt`,
  BIN_AddOns: `Dienstleistungen hinzugefügt`,
  BIN_Reservation: `Deine Reservierung`,
  BIN_Details: `Grundlegende Details`,
  BIN_RoomCount: `Räume`,
  BIN_CabinCount: `Kabinen`,
  BIN_HouseCount: `Hâuser`,
  BIN_UnitCount: `Einheiten`,
  BIN_Roomtype: `Zimmertyp`,
  BIN_Cabintype: `Kabinentyp`,
  BIN_Housetype: `Art eines Hauses`,
  BIN_Unittype: `Gerätetyp`,
  BIN_Packagetype: `Pakettyp`,
  BIN_BasketEmpty: `Der Korb ist leer`,
  BIN_BasketGoToCheckout: `Zum Checkout gehen`,

  EXC_EmptyBasket: `Basket must not be empty`,

  SEA_Date: `datum`,
  SEA_Time: `Zeit`,
  SEA_Quantity: `Anzahl`,
  SEA_Skip: `Überspringen`,
  SEA_ChooseEvent: `Wähle eine Erfahrung`,
  SEA_DescriptionStart: `Wählen`,
  SEA_DescriptionEnd: `unten oder Datum im Kalender`,
  SEA_SoldOut: `Für den ausgewählten Monat ist alles ausverkauft`,
  SEA_SoldOutContinue: `Drücken Sie Weiter um zu überspringen`,
  SEA_SeatingStandAloneTitle: ` Wählen Sie ein Datum oder eines der angezeigten aus Optionen`,
  SEA_ResetCalendar: `Anzeigen alle`,

  MAT_CALENDAR_Calendar_Label: `S`,
  MAT_CALENDAR_Close_Calendar_Label: `S`,
  MAT_CALENDAR_End_Date_Label: `S`,
  MAT_CALENDAR_Next_Month_Label: `S`,
  MAT_CALENDAR_Next_Multi_Year_Label: `S`,
  MAT_CALENDAR_Next_Year_Label: `S`,
  MAT_CALENDAR_Open_Calendar_Label: `S`,
  MAT_CALENDAR_Prev_Month_Label: `S`,
  MAT_CALENDAR_Prev_Multi_Year_Label: `S`,
  MAT_CALENDAR_Prev_Year_Label: `S`,
  MAT_CALENDAR_Start_Date_Label: `S`,
  MAT_CALENDAR_Switch_To_Month_View_Label: `S`,
  MAT_CALENDAR_Switch_To_Multi_Year_View_Label: `S`,
};

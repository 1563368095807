import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogService } from '@com/logging';
import { BehaviorSubject, combineLatest, from } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AppQueryParams } from 'src/app/app.component';
import { ApplicationStateService, CustomerService, PromotionService, RateCodeString } from 'src/app/core';
import {
  BasketQueryParams,
  BasketService,
  Loader,
  LocaleService,
  Rate,
  Room,
  SearchParams,
  TrackingService,
} from 'src/app/core';
import {
  CompanyAgreementDiscountResponseModel,
  GuestCombination,
  SpectraApiRoomResponseModel,
} from '../../core/modules/hotel/data-hotel.service';
import { HotelService } from '../../core/modules/hotel/hotel.service';
import { GetPromotionCodeStatus } from '../../core/modules/promotion/data-promotion.service';
import { DateHelper, GuestType, UTCDateTimeString } from '../../helpers';
import { NavigationHelper } from '../../helpers/navigation-helper';
import { Messenger } from '../../modules/messenger';
import { DesignConfiguration } from '../../shared/design/design-configuration';
import { SearchComponent } from '../search/search.component';
import { RoomLookupError, RoomsNotAvailableReason, RoomsSoldOutReason } from './room-lookup-error';

interface RoomRouteParams {
  index?: string;
}

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
})
export class RoomComponent implements OnInit, OnDestroy {
  private bookingIndex$ = this.route.params.pipe(
    map((params: RoomRouteParams) => +(params.index || '1') - 1),
    distinctUntilChanged(),
    tap(() => {
      document.body.scrollTop = 0;
    }),
  );

  private selected$ = new BehaviorSubject(undefined as Room | undefined);
  arrivaldate = '';
  lengthOfStay = 2;
  promotionCodeUI: string | undefined;
  basket = this.getBasket(this.route.snapshot.queryParams as AppQueryParams);
  returnFromBasket = false;
  roomsAndRates: SpectraApiRoomResponseModel[] | undefined;

  @ViewChild('messageRoomGalleryTempalte', { static: false })
  messageRoomGalleryTempalte: TemplateRef<GalleryModel> | undefined;
  @ViewChild('messageRateGalleryTempalte', { static: false })
  messageRateGalleryTempalte: TemplateRef<GalleryModel> | undefined;

  data$ = combineLatest([
    this.localeService.locale$,
    this.bookingIndex$,
    this.promotionService.promocode$,
    this.customerService.customerId$,
    this.customerService.companyId$,
  ]).pipe(
    switchMap(([, bookingIndex, , , companyId]) =>
      from(this.getRooms(bookingIndex, companyId)).pipe(
        tap((rooms) => {
          if (this.basket.bookings[bookingIndex]) {
            this.selected$.next(rooms.find((x) => x.Code === this.basket.bookings[bookingIndex].RoomCode));
          }
        }),
        switchMap((rooms) =>
          this.selected$.pipe(
            switchMap((room) =>
              from(room ? this.GetRatesForRooms(room.Code, companyId) : [[]]).pipe(
                map((rates) => {
                  return {
                    bookingIndex,
                    rooms,
                    room,
                    rates,
                  };
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private log: LogService,
    private route: ActivatedRoute,
    private loader: Loader,
    private basketService: BasketService,
    private hotelService: HotelService,
    private localeService: LocaleService,
    private customerService: CustomerService,
    private promotionService: PromotionService,
    private trackingService: TrackingService,
    private applicationState: ApplicationStateService,
    private messenger: Messenger,
    private navigationHelper: NavigationHelper,
    private designConfig: DesignConfiguration,
  ) {}

  async ngOnInit() {
    this.applicationState.stopScrolling(false);
    this.lengthOfStay = this.basket.params.stay;
    this.arrivaldate = DateHelper.toServerDateFormatString(this.basket.params.arrival);

    this.applicationState.ReturnFromOnlySeating = 'false';

    document.body.classList.remove('addBackGround');
    document.body.classList.remove('addBackGroundWithAnimation');
    document.body.style.backgroundImage = '';

    if (this.basket.bookings.length !== 0 && this.basket.bookings.length === this.basket.params.rooms.length) {
      this.returnFromBasket = true;
    }
    await this.prepareNavigation();
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async prepareNavigation() {
    if (this.applicationState.UseTableReservation && this.applicationState.InclueTableReservationBookingFlow) {
      await this.navigationHelper.continueMobileNavigationbar(false, true, '/room/1', '/products', {
        queryParams: {
          HotelCode: this.applicationState.CurrentHotelCode,
          ArrivalDate: this.arrivaldate,
          LengthOfStay: this.lengthOfStay,
          guest: this.calculateGuestCount(),
        },
      });
    } else if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
      await this.navigationHelper.continueMobileNavigationbar(false, true, '/room/1', '/seating/1');
    } else {
      await this.navigationHelper.continueMobileNavigationbar(false, true, '/room/1', '/add-on/1');
    }
  }

  async onBookClick(bookingIndex: number, room: Room, rate: Rate) {
    const bookingToRemove = this.basket.bookings[bookingIndex];
    if (bookingToRemove) {
      this.trackingService.ecommerceRemoveFromCart({
        item_name: `${bookingToRemove.RoomName}/${bookingToRemove.RateCode}`,
        item_id: `${bookingToRemove.RoomCode}/${bookingToRemove.RateCode}`,
        price: bookingToRemove.Price,
        item_category: 'Room',
        quantity: 1,
      });
    }
    this.basket.bookings[bookingIndex] = {
      RoomName: room.Name,
      RateName: rate.Name,
      Price: rate.Price,
      OriginalPrice: rate.OriginalPrice,
      RateCode: rate.Code,
      RoomCode: room.Code,
      RateDescription: rate.Long ? rate.Long : '',
      HasPromotionForCustomer: rate.HasPromotionForCustomer,
      ConferenceRateCode: '',
      ConferenceRoomCode: '',
    };
    this.trackingService.ecommerceAddToCart({
      item_name: `${room.Name}/${rate.Code}`,
      item_id: `${room.Code}/${rate.Code}`,
      price: rate.Price,
      item_category: 'Room',
      quantity: 1,
    });
    this.basketService.set(this.basket);
    const roomCount = this.basket.params.rooms ? this.basket.params.rooms.length : 0;

    if (this.basket.bookings.length < roomCount) {
      await this.navigationHelper.continue('/room/1', `/room/${this.basket.bookings.length + 1}`);
    } else {
      if (this.applicationState.UseTableReservation && this.applicationState.InclueTableReservationBookingFlow) {
        await this.navigationHelper.continue('/room/1', '/products', {
          queryParams: {
            HotelCode: this.applicationState.CurrentHotelCode,
            ArrivalDate: this.arrivaldate,
            LengthOfStay: this.lengthOfStay,
            guest: this.calculateGuestCount(),
          },
        });
      } else if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
        await this.navigationHelper.continue('/room/1', '/seating/1');
      } else {
        await this.navigationHelper.continue('/room/1', '/add-on/1');
      }
    }
  }

  async onRateSelect(bookingIndex: number, room: Room, rate: Rate) {
    this.applicationState.stopScrolling(true);
    this.basketService.hideBasket();
    if (this.messageRateGalleryTempalte !== undefined) {
      let array = [] as string[];
      if (rate.Image !== '' || rate.Image1 !== '' || rate.Image2 !== '') {
        array = [rate.Image, rate.Image1, rate.Image2];
      }
      this.trackingService.ecommerceViewItem({
        item_name: `${room.Name}/${rate.Code}`,
        item_id: `${room.Code}/${rate.Code}`,
        price: rate.Price,
        item_category: 'Room',
        quantity: rate.Quantity,
      });
      const message = this.messenger.show(
        this.messageRateGalleryTempalte,
        {
          roomData: room,
          imageArray: array.filter((x) => x !== ''),
          selectedImage: array[0],
          rateData: rate,
          accept: async () => {
            this.applicationState.stopScrolling(false);

            const bookingToRemove = this.basket.bookings[bookingIndex];
            if (bookingToRemove) {
              this.trackingService.ecommerceRemoveFromCart({
                item_name: `${bookingToRemove.RoomName}/${bookingToRemove.RateCode}`,
                item_id: `${bookingToRemove.RoomCode}/${bookingToRemove.RateCode}`,
                price: bookingToRemove.Price,
                item_category: 'Room',
                quantity: 1,
              });
            }

            this.basket.bookings[bookingIndex] = {
              RoomName: room.Name,
              RateName: rate.Code,
              Price: rate.Price,
              OriginalPrice: rate.OriginalPrice,
              RateCode: rate.Code,
              RoomCode: room.Code,
              RateDescription: rate.Long ? rate.Long : '',
              HasPromotionForCustomer: rate.HasPromotionForCustomer,
              ConferenceRateCode: '',
              ConferenceRoomCode: '',
            };
            this.trackingService.ecommerceAddToCart({
              item_name: `${room.Name}/${rate.Code}`,
              item_id: `${room.Code}/${rate.Code}`,
              price: rate.Price,
              item_category: 'Room',
              quantity: 1,
            });
            this.basketService.set(this.basket);
            const roomCount = this.basket.params.rooms ? this.basket.params.rooms.length : 0;
            if (this.basket.bookings.length < roomCount) {
              await this.navigationHelper.continue('/room/1', `/room/${this.basket.bookings.length + 1}`);
            } else {
              if (
                this.applicationState.UseTableReservation &&
                this.applicationState.InclueTableReservationBookingFlow
              ) {
                await this.navigationHelper.continue('/room/1', '/products', {
                  queryParams: {
                    HotelCode: this.applicationState.CurrentHotelCode,
                    ArrivalDate: this.arrivaldate,
                    LengthOfStay: this.lengthOfStay,
                    guest: this.calculateGuestCount(),
                  },
                });
              } else if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
                await this.navigationHelper.continue('/room/1', '/seating/1');
              } else {
                await this.navigationHelper.continue('/room/1', '/add-on/1');
              }
            }
            this.messenger.close(message);
          },
          close: () => {
            this.applicationState.stopScrolling(false);
            this.messenger.close(message);
          },
          nextImage: (img: string) => {
            const index = array.findIndex((x) => x === img);
            if (array.length - 1 === index) {
              message.data.selectedImage = array[0];
            } else {
              message.data.selectedImage = array[index + 1];
            }
          },
          prevImage: (img: string) => {
            const index = array.findIndex((x) => x === img);
            if (index === 0) {
              message.data.selectedImage = array[array.length - 1];
            } else {
              message.data.selectedImage = array[index - 1];
            }
          },
        },
        () => {
          this.applicationState.stopScrolling(false);
          message.data.close();
        },
      );
    }
  }

  onRoomClick(e: Event, room: Room) {
    this.applicationState.stopScrolling(true);
    this.log.debug(`RoomRoute onRoomClick(${room.Code})`);
    e.stopPropagation();
    if (this.messageRoomGalleryTempalte !== undefined && this.selected$.value !== room) {
      this.basketService.hideBasket();
      let array = [] as string[];
      if (room.Image !== '' || room.Image1 !== '' || room.Image2 !== '') {
        array = [room.Image, room.Image1, room.Image2];
      }
      const message = this.messenger.show(
        this.messageRoomGalleryTempalte,
        {
          roomData: room,
          imageArray: array.filter((x) => x !== ''),
          selectedImage: array[0],
          rateData: undefined,
          accept: () => {
            this.applicationState.stopScrolling(false);
            this.selected$.next(this.selected$.value === room ? undefined : room);
            this.messenger.close(message);
            if (this.designConfig.addProductScrolling()) {
              const res = document.documentElement.scrollTop || document.body.scrollTop;
              window.scrollTo({
                left: 0,
                top: res === 0 ? +200 : res + 100,
                behavior: 'smooth',
              });
            }
          },
          close: () => {
            this.applicationState.stopScrolling(false);
            this.messenger.close(message);
          },
          nextImage: (img: string) => {
            const index = array.findIndex((x) => x === img);
            if (array.length - 1 === index) {
              message.data.selectedImage = array[0];
            } else {
              const next = array[index + 1];
              if (next !== '') {
                message.data.selectedImage = array[index + 1];
              } else {
                message.data.selectedImage = array[0];
              }
            }
          },
          prevImage: (img: string) => {
            const index = array.findIndex((x) => x === img);
            if (index === 0) {
              message.data.selectedImage = array[array.length - 1];
            } else {
              const prev = array[index - 1];
              if (prev !== '') {
                message.data.selectedImage = array[index - 1];
              } else {
                message.data.selectedImage = array[0];
              }
            }
          },
        },
        () => {
          this.applicationState.stopScrolling(false);
          message.data.close();
        },
      );
    } else {
      this.applicationState.stopScrolling(false);
      this.selected$.next(undefined);
    }
  }

  changeImage(url: string, model: GalleryModel) {
    model.selectedImage = url;
  }

  onSelectedRateClick(bookingIndex: number, room: Room, rate: Rate) {
    const bookingToRemove = this.basket.bookings[bookingIndex];
    if (bookingToRemove) {
      this.trackingService.ecommerceRemoveFromCart({
        item_name: `${bookingToRemove.RoomName}/${bookingToRemove.RateCode}`,
        price: bookingToRemove.Price,
        item_category: 'Room',
        quantity: 1,
      });
    }

    this.basket.bookings.splice(bookingIndex);
    this.basketService.set(this.basket);
  }

  async onMoveToNextClick() {
    if (this.applicationState.UseTableReservation && this.applicationState.InclueTableReservationBookingFlow) {
      await this.navigationHelper.continue('/room/1', '/products', {
        queryParams: {
          HotelCode: this.applicationState.CurrentHotelCode,
          ArrivalDate: this.arrivaldate,
          LengthOfStay: this.lengthOfStay,
          guest: this.calculateGuestCount(),
        },
      });
    } else if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
      await this.navigationHelper.continue('/room/1', '/seating/1');
    } else {
      await this.navigationHelper.continue('/room/1', '/add-on/1');
    }
  }

  calculateGuestCount() {
    if (this.basket) {
      let count = 0;

      for (const item of this.basket.params.rooms) {
        count = count + item.getPersons();
      }
      return count;
    }
    return 2;
  }

  async goBack() {
    await this.navigationHelper.goBack();
  }

  private getBasket(queryParams: Partial<BasketQueryParams>) {
    const hotelCode = SearchComponent.ParseHotelCode(queryParams);
    const arrival = SearchComponent.ParseArrival(queryParams);
    const stay = SearchComponent.ParseStay(queryParams);
    const rooms = SearchComponent.ParseRooms(queryParams);
    const single = SearchComponent.ParseSingle(queryParams);
    if (hotelCode && arrival && stay && rooms) {
      const params: SearchParams = {
        hotelCode,
        arrival,
        stay,
        rooms,
        single,
        ArrivalDate: 0,
        ArrivalFullYear: 0,
        ArrivalMonthName: '',
        DepartueDate: 0,
        DepartueFullYear: 0,
        DepartueMonthName: '',
        BookingFlow: 1,
        RateCode: '',
        ConferenceRoomCode: '',
      };
      return this.basketService.create(params);
    } else {
      const basket = this.basketService.get();
      if (basket) {
        return basket;
      } else {
        throw new Error('Basket must not be empty');
      }
    }
  }

  private async getRooms(bookingIndex: number, customerId: number | undefined) {
    const loadedRooms = await this.loader.using(async () => {
      const rooms = [] as Room[];
      try {
        const guests = [] as GuestCombination[];

        const item = this.basket.params.rooms[bookingIndex];
        const guest = {
          AdultCount: item.get(GuestType.Adult),
          ChildCount: item.get(GuestType.Child),
          InfantCount: item.get(GuestType.Infant),
          SeniorCount: item.get(GuestType.Senior),
          TeenagerCount: item.get(GuestType.UnknownType1),
        } as GuestCombination;
        guests.push(guest);

        const availableRoomsAndRates = await this.hotelService.GetSpectraRoomInfo(
          this.applicationState.CurrentHotelCode,
          this.basket.params.arrival,
          DateHelper.addDays(this.basket.params.arrival, this.lengthOfStay),
          this.basket.params.RateCode ? this.basket.params.RateCode : '',
          guests,
          await this.localeService.getLocale(),
          customerId,
        );
        if (availableRoomsAndRates) {
          this.roomsAndRates = availableRoomsAndRates;
          for (const room of availableRoomsAndRates) {
            for (const rate of room.Rateplans) {
              if (rooms.length === 0) {
                const roomInfo = await this.hotelService.GetECommerceRoomInfo(
                  this.applicationState.CurrentHotelCode,
                  room.RoomType,
                  await this.localeService.getLocale(),
                );
                roomInfo.FromPrice = rate.Price;
                if (roomInfo.MinPers <= this.basket.params.rooms[bookingIndex].getPersons()) {
                  rooms.push(roomInfo);
                }
              } else if (rooms && rooms.filter((x) => x.Code === room.RoomType).length === 0) {
                const roomInfo = await this.hotelService.GetECommerceRoomInfo(
                  this.applicationState.CurrentHotelCode,
                  room.RoomType,
                  await this.localeService.getLocale(),
                );
                roomInfo.FromPrice = rate.Price;

                if (roomInfo.MinPers <= this.basket.params.rooms[bookingIndex].getPersons()) {
                  rooms.push(roomInfo);
                }
              } else {
                const existingRoom = rooms.find((x) => x.Code === room.RoomType);

                if (existingRoom && existingRoom.FromPrice > rate.Price) {
                  existingRoom.FromPrice = rate.Price;
                }
              }
            }
          }
        }
        return rooms.sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));
      } catch (err) {
        return rooms;
      }
    }, 'LOA_Rooms');

    if (!loadedRooms) {
      throw new RoomLookupError(new RoomsNotAvailableReason());
    } else if (loadedRooms.every((item) => !item.FromPrice)) {
      throw new RoomLookupError(new RoomsSoldOutReason());
    }
    return loadedRooms;
  }

  private async GetRatesForRooms(roomCode: string, companyId: number | undefined) {
    return await this.loader.using(async () => {
      try {
        const rates = [] as Rate[];

        const usingPromotionCode = this.promotionService.getPromocode();
        let ratesWithPromotion = [] as GetPromotionCodeStatus[];
        let ratesWithCompanyAgreement = [] as CompanyAgreementDiscountResponseModel[];

        if (companyId) {
          ratesWithCompanyAgreement = await this.hotelService.GetCompanyAgreementDiscount(
            this.applicationState.CurrentHotelCode,
            companyId,
          );
        }

        if (usingPromotionCode) {
          ratesWithPromotion = await this.promotionService.getPromotionStatus(
            this.applicationState.CurrentHotelCode,
            usingPromotionCode,
          );

          let fromDate = DateHelper.addDays(new Date(), 365);
          let toDate = new Date();

          for (const promo of ratesWithPromotion) {
            if (DateHelper.FromUtcDate(promo.StayFromDate.toString() as UTCDateTimeString) < fromDate) {
              fromDate = DateHelper.FromUtcDate(promo.StayFromDate.toString() as UTCDateTimeString);
            }
            if (DateHelper.FromUtcDate(promo.StayToDate.toString() as UTCDateTimeString) > toDate) {
              toDate = DateHelper.FromUtcDate(promo.StayToDate.toString() as UTCDateTimeString);
            }
          }

          if (
            ratesWithPromotion.length !== 0 &&
            fromDate.getTime() >= this.basket.params.arrival.getTime() &&
            toDate.getTime() >= this.basket.params.arrival.getTime()
          ) {
            if (fromDate.getTime() !== this.basket.params.arrival.getTime()) {
              ratesWithPromotion = [] as GetPromotionCodeStatus[];
            }
          }

          if (
            ratesWithPromotion.length !== 0 &&
            fromDate.getTime() <= DateHelper.addDays(this.basket.params.arrival, this.basket.params.stay).getTime() &&
            toDate.getTime() <= DateHelper.addDays(this.basket.params.arrival, this.basket.params.stay).getTime()
          ) {
            if (
              toDate.getTime() !== DateHelper.addDays(this.basket.params.arrival, this.basket.params.stay).getTime()
            ) {
              ratesWithPromotion = [] as GetPromotionCodeStatus[];
            }
          }
        }

        if (this.roomsAndRates) {
          for (const item of this.roomsAndRates.filter((x) => x.RoomType === roomCode)) {
            for (const rate of item.Rateplans) {
              const rateInfo = await this.hotelService.GetECommerceRateInfo(
                this.applicationState.CurrentHotelCode,
                rate.RatePlan,
                await this.localeService.getLocale(),
              );
              rateInfo.Long = rate.Description;
              rateInfo.OriginalPrice = rate.Price;
              rateInfo.Price = rate.Price;
              rateInfo.Code = rate.RatePlan as RateCodeString;
              rateInfo.Name = rate.Name;

              if (ratesWithPromotion && ratesWithPromotion.length !== 0) {
                const possiblePromotionOnRate = ratesWithPromotion.find((x) => x.RatePlanCode === rateInfo.Code);
                if (possiblePromotionOnRate) {
                  rateInfo.Price =
                    rateInfo.OriginalPrice - rate.Price * (possiblePromotionOnRate.DiscountPercentage / 100);
                  rateInfo.PromotionDisCount = possiblePromotionOnRate.DiscountPercentage;
                }
              }

              if (ratesWithCompanyAgreement && ratesWithCompanyAgreement.length !== 0) {
                const possibleAgreementOnRate = ratesWithCompanyAgreement.find((x) => x.RatePlanCode === rateInfo.Code);
                if (possibleAgreementOnRate) {
                  rateInfo.Price =
                    rateInfo.OriginalPrice -
                    rate.Price * (parseFloat(possibleAgreementOnRate.DiscountPercentage) / 100);
                  rateInfo.PromotionDisCount = parseFloat(possibleAgreementOnRate.DiscountPercentage);
                }
              }

              rates.push(rateInfo);
            }
          }
        }
        return rates.sort((a, b) => (a.Sortindex > b.Sortindex ? 1 : -1));
      } catch (err) {
        return undefined;
      }
    }, 'LOA_Rooms');
  }
}

interface GalleryModel {
  accept: () => void;
  close: () => void;
  nextImage: (image: string) => void;
  prevImage: (image: string) => void;
  roomData: Room;
  rateData: Rate | undefined;
  selectedImage: string;
  imageArray: string[];
}

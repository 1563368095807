import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { AspDateString, DateHelper, Guid, RoomGuests, TimeString, UTCDateString } from 'src/app/helpers';
import { AxeptPaymentResponseModel, PaymentModel } from '.';
import { ApplicationStateService, Basket, GiftCardGuestInformation } from '../../services';
import { ConfigService, HotelCodeString, PromoCodeString, RateCodeString, RoomCodeString } from '../config';
import { Customer, CustomerInfo } from '../customer';
import { HotelType } from '../hotel';
import { BookingExtraServiceModel, CompanyAgreementDiscountResponseModel } from '../hotel/data-hotel.service';
import { HotelService } from '../hotel/hotel.service';
import { Locale, LocaleService } from '../locale';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AQC } from 'src/app/core';

export interface IReservationService {
  createReservation(
    locale: Locale,
    hotel: HotelType,
    promocode: PromoCodeString | undefined,
    customer: Customer,
    information: BookingInformation,
    needSubscribe: boolean,
    useCancelationInsurance: boolean,
    comment: string,
    isAgent: boolean,
    fullGiftcardPayment: number,
  ): Promise<ReservationType>;
}

export enum PaymentFlowEnum {
  NoPayment = 1,
  FullPayment = 2,
  DepositPayment = 3,
  Ticket = 4,
}

@Injectable()
export class ReservationService implements IReservationService {
  companyAgreements = [] as CompanyAgreementDiscountResponseModel[];
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private log: LogService,
    private hotelService: HotelService,
    private applicationState: ApplicationStateService,
    private localeService: LocaleService,
  ) {}

  async createReservation(
    locale: Locale,
    hotel: HotelType,
    promocode: PromoCodeString | undefined,
    customer: Customer,
    information: BookingInformation,
    needSubscribe: boolean,
    useCancelationInsurance: boolean,
    comment: string,
    isAgent: boolean,
    giftcardPayment: number,
  ) {
    this.log.debug(
      `ReservationService createReservation(${locale}, ${hotel.Code}, ${promocode ? promocode : '*no promocode*'}, ${
        customer.Info.Email
      })`,
      information,
    );
    const [appSettings, initialSearchParams] = await Promise.all([
      this.config.getAppSettings(),
      this.config.getInitialSearchParams(),
    ]);
    const needUpdate = false;
    const customerId = customer.Id;

    // Collect all company agreements if logged in, to check if customerpromotion
    if (customer.Company) {
      this.companyAgreements = await this.hotelService.GetCompanyAgreementDiscount(hotel.Code, customer.Company.Id);
    }

    let isPaymentEnabled = this.applicationState.NetsPaymentEnabled;

    if (isPaymentEnabled && customer.Id !== null) {
      // if payment is enabled and customer exists
      isPaymentEnabled = customer.PaymentRequired; // then set payment to customer status
    }

    const seatings: SeatType[] = [];
    if (information.Bookings[0].Seating) {
      for (const booking of information.Bookings) {
        if (booking.Seating) {
          for (const item of booking.Seating) {
            const modiDate = DateHelper.FromUtcDate(item.Date.toString() as UTCDateString);
            const seating: SeatType = {
              hotelCode: information.HotelCode,
              total: item.TotalPrice,
              date: DateHelper.toServerDateFormatString(modiDate) as UTCDateString,
              seatCode: item.SeatCode,
              time: item.Time,
              seatrate: item.SeatRate,
              seatGuests: item.SeatGuest,
              services: information.Bookings[0].AddOns.reduce<ServiceType[]>((data, addOn) => {
                const services: ServiceType[] = [];

                for (const serv of addOn.Dates) {
                  const date = DateHelper.ConvertToServerDate(new Date(serv.Date));
                  const service: ServiceType = {
                    count: serv.selectedCount,
                    price: addOn.Amount / (serv.selectedCount * addOn.Dates.length),
                    from: DateHelper.toAspString(date),
                    to: DateHelper.toAspString(date),
                    timeFrom: null,
                    timeTo: null,
                    serviceCode: addOn.ServiceId,
                    serviceId: addOn.Id.toString(),
                  };
                  services.push(service);
                }
                data.push(...services);
                return data;
              }, []),
            };
            seatings.push(seating);
          }
        }
      }
    }

    const request: ReservationTypeRequest = {
      giftcards: [],
      seats: seatings,
      rooms: information.Bookings.map((booking) => {
        const hasPromotion = booking.Price !== booking.OriginalPrice;
        const hasPromocodePromotion = booking.HasPromotionForCustomer ? false : hasPromotion;
        const room: RoomType = {
          guestName: null,
          arrival: DateHelper.toAspString(DateHelper.ConvertToServerDate(DateHelper.FromUtcDate(information.Arrival))),
          departure: DateHelper.toAspString(
            DateHelper.ConvertToServerDate(
              DateHelper.addDays(DateHelper.FromUtcDate(information.Arrival), information.Stay),
            ),
          ),
          aqcs: RoomGuests.Categories.reduce<AqcType[]>((arr, aqc) => {
            if (booking.Persons !== undefined) {
              const count = booking.Persons.get(aqc);
              if (count > 0) {
                arr.push({
                  aqc: aqc.toString(),
                  count,
                  type: 0, // TODO: try to remove as it's useless
                });
              }
            }
            return arr;
          }, []),
          guests: [this.GetGuest(booking.Guest, customer.Info, isAgent)],
          roomCode: booking.RoomCode,
          hotelCode: information.HotelCode,
          invBlockCode: '',
          useCancelPolicy: useCancelationInsurance,
          ratePlan: booking.RateCode,
          price: booking.Price,
          services: booking.AddOns.reduce<ServiceType[]>((data, addOn) => {
            const services: ServiceType[] = [];

            for (const serv of addOn.Dates) {
              const date = DateHelper.ConvertToServerDate(new Date(serv.Date));
              const service: ServiceType = {
                count: serv.selectedCount,
                price: addOn.Amount / (serv.selectedCount * addOn.Dates.length),
                from: DateHelper.toAspString(date),
                to: DateHelper.toAspString(date),
                timeFrom: null,
                timeTo: null,
                serviceCode: addOn.ServiceId,
                serviceId: addOn.Id.toString(),
              };
              services.push(service);
            }
            data.push(...services);
            return data;
          }, []),
          promotionData: {
            promotionCode: (hasPromocodePromotion ? promocode : undefined) || null,
            discount: hasPromocodePromotion ? 1 - booking.Price / booking.OriginalPrice : 0,
          },
          customerRateId:
            this.companyAgreements.filter((x) => x.RatePlanCode === booking.RateCode).length > 0 ? customerId : null,
        };
        return room;
      }),
      services: null,
      isCustomer: true,
      bookingAdmin: {
        address: customer.Info.Address1,
        city: customer.Info.City,
        country: customer.Info.Country,
        email: customer.Info.Email,
        firstName: customer.Info.FirstName,
        lastName: customer.Info.LastName,
        mobilePhone: customer.Info.Phone,
        phone: '',
        postal: customer.Info.ZipCode,
        NeedUpdate: needUpdate,
        CustomerId: customerId ? customerId.toString() : null,
        userData: customer.Company
          ? {
              CompanyAddress1: customer.Company.Address1,
              CompanyAddress2: customer.Company.Address2,
              CompanyCity: customer.Company.City,
              CompanyCountryCode: customer.Company.CountryCode,
              CompanyCountryName: customer.Company.CountryName,
              CompanyName: customer.Company.Name,
              CompanyPhone: customer.Company.Phone,
              CompanyZipCode: customer.Company.ZipCode,
              CustomerContactId: customerId ? customerId.toString() : undefined,
              Email: customer.Info.Email,
              FirstName: customer.Info.FirstName,
              LastName: customer.Info.LastName,
              PaymentRequired: customer.PaymentRequired,
              Phone: customer.Info.Phone,
              CustomerId: customer.Company.Id.toString(),
            }
          : undefined,
      },
      needSubscribe,
      comment,
      lang: locale,
      total:
        this.GetTotal(information.Bookings) + ((useCancelationInsurance ? information.CancelationInsurance : 0) || 0),
      isHotelDepartment: hotel.IsHotelDepartment,
      payment: {
        merchant: hotel.Settings.MerchantID || appSettings.MerchantId.toString(),
        currency: initialSearchParams.Currency,
        isPaymentEnabled,
        transactionId: '',
      },
    };
    let isOnlySeating = false;
    for (const item of request.rooms) {
      if (item.roomCode === ('' as RoomCodeString) && item.ratePlan === ('' as RateCodeString)) {
        isOnlySeating = true;
      } else {
        isOnlySeating = false;
      }
    }

    if (isOnlySeating) {
      request.rooms = [] as RoomType[];
    }

    const endpoint = `${appSettings.BookingServiceUrl}/PlaceReservation?netsEasy=${this.applicationState.UseNetsEasy}&giftcardAmount=${giftcardPayment}`;
    this.log.debug(
      `ReservationService creating reservation for ${request.rooms.length} rooms. Endpoint: ${endpoint}`,
      request,
    );

    const response = await lastValueFrom(this.http.post<PlaceReservationResponse>(endpoint, request));

    this.log.debug(`ReservationService reservation created with response`, response);
    if (!response.Reservation || response.ErrorText || !response.Success) {
      this.log.debug(`Invalid create reservation response recieved`);
      throw new Error(`${response.ErrorText}`);
    }
    if (Math.abs(response.Reservation.total - request.total) > 0.001) {
      this.log.fatal(
        `Price from front-end(${request.total}) and back-end(${response.Reservation.total}) does not match. Price from back-end will be used.`,
      );
    }
    if (!response.Reservation.reservationId || !response.Reservation.bookingCode) {
      throw new Error(`Failed to complete reservation. 'reservationId' and 'bookingCode' should be set.`);
    }

    const result: ReservationType = {
      ...request,
      HotelCode: this.applicationState.CurrentHotelCode,
      PaymentDue: response.Reservation.PaymentDue,
      PaymentFlowToUse: response.Reservation.PaymentFlowToUse,
      bookingCode: response.Reservation.bookingCode,
      cancellationFee: response.Reservation.cancellationFee,
      paid: response.Reservation.paid,
      reservationId: response.Reservation.reservationId,
      resGuid: response.Reservation.resGuid,
      paidWithGiftcard: 0,
      total:
        response.Reservation.PaymentFlowToUse === PaymentFlowEnum.Ticket ? request.total : response.Reservation.total,
    };
    this.applicationState.ReservationGuid = response.Reservation.resGuid;
    return result;
  }

  GetGuest(guest: CustomerInfo, bookingAdmin: CustomerInfo, isAgent: boolean) {
    if (isAgent) {
      return {
        firstName: guest.FirstName,
        lastName: guest.LastName,
        email: guest.Email,
        mobilePhone: guest.Phone,
        country: guest.Country,
      };
    }

    return {
      firstName: bookingAdmin.FirstName,
      lastName: bookingAdmin.LastName,
      email: bookingAdmin.Email,
      mobilePhone: bookingAdmin.Phone,
      country: bookingAdmin.Country,
    };
  }

  async GetReservation(reservationId: string, locale: Locale) {
    const [appSettings, initialSearchParams] = await Promise.all([
      this.config.getAppSettings(),
      this.config.getInitialSearchParams(),
    ]);

    const response = await lastValueFrom(
      this.http.get<ReservationLinkResponse>(
        `${appSettings.SpectraApiUrl}/api/internal/payment/deposit/${reservationId}`,
      ),
    );

    const hotel = await this.hotelService.GetECommerceDepartmentInformation(response.HotelCode, locale);

    // map response to OB reservation reservation structure.
    const reservation: ReservationType = {
      resGuid: response.ReservationGuid as Guid,
      PaymentDue: response.PaymentDue,
      PaymentFlowToUse: response.PaymentFlow,
      bookingCode: response.ReservationGuid,
      cancellationFee: response.CancellationProtectionAmount,
      paid: response.AlreadyPaidAmount,
      reservationId: response.ReservationNumber,
      paidWithGiftcard: 0,
      giftcards: [],
      HotelCode: response.HotelCode,
      rooms: response.Roomstays.map((stay) => {
        const room: RoomType = {
          arrival: DateHelper.toAspString(
            DateHelper.ConvertToServerDate(
              DateHelper.FromValues(stay.Arrival.Year, stay.Arrival.Month, stay.Arrival.Day),
            ),
          ),
          departure: DateHelper.toAspString(
            DateHelper.ConvertToServerDate(
              DateHelper.FromValues(stay.Departure.Year, stay.Departure.Month, stay.Departure.Day),
            ),
          ),
          price: stay.Amount,
          roomCode: stay.RoomTypeName as RoomCodeString,
          ratePlan: '' as RateCodeString,
          invBlockCode: null,
          hotelCode: response.HotelCode,
          guestName: stay.GuestName,
          useCancelPolicy:
            response.CancellationProtectionAmount !== undefined && response.CancellationProtectionAmount > 0,
          services: stay.ExtraServices.map((es) => {
            const ser: ServiceType = {
              count: es.Quantity,
              price: es.TotalAmount,
              serviceCode: es.ServiceName,
              from: '' as AspDateString,
              to: '' as AspDateString,
              timeFrom: null,
              timeTo: null,
              serviceId: '',
              priceIncluded: true,
              serviceType: es.ServiceType,
              ServiceDate: es.ServiceDate,
            };
            return ser;
          }),
          guests: [],
          aqcs: null,
          promotionData: null,
          customerRateId: null,
        };

        return room;
      }),
      seats: [],
      isCustomer: true,
      needSubscribe: false,
      comment: '',
      bookingAdmin: {
        customerNumber: -1,
        firstName: '',
        lastName: response.ReservationName,
        email: '',
        country: '',
        mobilePhone: '',
        phone: '',
        address: '',
        city: '',
        postal: '',
        NeedUpdate: false,
        CustomerId: null,
        userData: undefined,
      },
      total: 0,
      isHotelDepartment: hotel.IsHotelDepartment,
      lang: locale,
      services: response.ExtraServices.map((es) => {
        const ser: ServiceType = {
          count: es.Quantity,
          price: es.TotalAmount,
          serviceCode: es.ServiceName,
          from: '' as AspDateString,
          to: '' as AspDateString,
          timeFrom: null,
          timeTo: null,
          serviceId: '',
          priceIncluded: true,
          serviceType: es.ServiceType,
          ServiceDate: es.ServiceDate,
        };
        return ser;
      }),
      payment: {
        merchant: hotel.Settings.MerchantID || appSettings.MerchantId.toString(),
        currency: initialSearchParams.Currency,
        isPaymentEnabled: true,
        transactionId: '',
      },
    };

    return reservation;
  }

  async CancelReservation(reservationId: string) {
    const [appSettings] = await Promise.all([this.config.getAppSettings()]);

    return await lastValueFrom(
      this.http.delete<PlaceReservationResponse>(
        `${appSettings.BookingServiceUrl}/CancelReservation?reservationId=${reservationId}`,
      ),
    );
  }

  async PlaceGiftcardOrderAxept(giftcards: BookingInformationGiftcard[], customer: CustomerInfo) {
    const giftcardCommandModel = await this.BuildGiftcardCommandModel(giftcards, customer);

    const [appSettings] = await Promise.all([this.config.getAppSettings()]);

    const response = await lastValueFrom(
      await this.http.post<AxeptPaymentResponseModel>(
        `${
          environment.production ? appSettings.ECommerceDataProvider : 'https://localhost:44343'
        }/HandleAxeptBuyGiftcardPayment?hotelCode=${this.applicationState.CurrentHotelCode}`,
        giftcardCommandModel,
      ),
    );

    return response;
  }

  async PlaceGiftcardOrderEasy(giftcards: BookingInformationGiftcard[], customer: CustomerInfo) {
    const giftcardCommandModel = await this.BuildGiftcardCommandModel(giftcards, customer);

    const [appSettings] = await Promise.all([this.config.getAppSettings()]);

    const response = await lastValueFrom(
      this.http.post<PaymentModel>(
        `${
          environment.production ? appSettings.ECommerceDataProvider : 'https://localhost:44343'
        }/HandleEasyBuyGiftcardPayment?hotelCode=` +
          this.applicationState.CurrentHotelCode +
          '&langCode=' +
          (await this.localeService.getLocale()),
        giftcardCommandModel,
      ),
    );

    return response;
  }

  async GetGiftcardInformation(giftcardCode: string) {
    const [appSettings] = await Promise.all([this.config.getAppSettings()]);
    const response = await lastValueFrom(
      this.http.get<string>(
        `${
          environment.production ? appSettings.ECommerceDataProvider : 'https://localhost:44343'
        }/GetGiftcardInfo?giftcardCode=${giftcardCode}`,
      ),
    ).catch(() => undefined);

    if (response) {
      const res: GetGiftcardInfoResult = JSON.parse(response);

      return res;
    } else {
      return {} as GetGiftcardInfoResult;
    }
  }

  async RegisterGiftcardPayment(orderId: number, transactionId: Guid) {
    const [appSettings] = await Promise.all([this.config.getAppSettings()]);
    const response = await lastValueFrom(
      this.http.get<any>(
        `${
          environment.production ? appSettings.ECommerceDataProvider : 'https://localhost:44343'
        }/RegisterGiftcardPayment?orderId=${orderId}&paymentId=${transactionId}`,
      ),
    );

    return response;
  }

  BuildReservationTypeForGiftcard(basket: Basket, paidAmount: number, orderId: string) {
    const reservationType = {
      rooms: [] as RoomType[],
      seats: [] as SeatType[],
      giftcards: [] as GiftcardType[],
    } as ReservationTypeRequest;

    if (basket.bookings[0].Giftcards) {
      for (const item of basket.bookings[0].Giftcards) {
        const giftcard = {
          cardName: item.Name,
          hotelCode: this.applicationState.CurrentHotelCode,
          total: item.TotalPrice,
        } as GiftcardType;

        reservationType.giftcards.push(giftcard);
      }
    }
    reservationType.total = paidAmount;

    const result: ReservationType = {
      ...reservationType,
      PaymentDue: reservationType.total,
      PaymentFlowToUse: PaymentFlowEnum.FullPayment,
      bookingCode: '',
      cancellationFee: 0,
      paid: paidAmount,
      reservationId: orderId,
      total: reservationType.total,
      paidWithGiftcard: 0,
      HotelCode: this.applicationState.CurrentHotelCode,
    };

    return result;
  }

  async RegisterPayment(hotelCode: string, resGuid: Guid, paymentId: Guid, paymentFlow: PaymentFlowEnum) {
    const [appSettings] = await Promise.all([this.config.getAppSettings()]);
    return await lastValueFrom(
      this.http.get(
        `${
          environment.production ? appSettings.ECommerceDataProvider : 'https://localhost:44343'
        }/RegisterPayment?hotelCode=${hotelCode}&resGuid=${resGuid}&paymentId=${paymentId}&paymentFlow=${paymentFlow}`,
      ),
    );
  }

  async SendNotification(
    reservation: BookingInformation,
    resGuid: Guid,
    langCode: string,
    paymentFlow: number,
    prepare = false,
  ) {
    const [appSettings] = await Promise.all([this.config.getAppSettings()]);

    return await lastValueFrom(
      this.http.post(
        `${
          environment.production ? appSettings.ECommerceDataProvider : 'https://localhost:44343'
        }/sendNotification?resGuid=${resGuid}&langCode=${langCode}&paymentFlow=${paymentFlow}&prepare=${prepare}`,
        reservation,
      ),
    ).catch((x) => this.log.error('It was not possible to send a Notification to resGuid: ' + resGuid, x));
  }

  GetTotal(bookings: BookingInformationBooking[], includeServices = true) {
    if (bookings.length !== 0 && bookings[0].GiftCard !== undefined && bookings[0].GiftCard.length !== 0) {
      return this.calculateTotalPriceForGiftcards(bookings[0].GiftCard);
    }

    const result = bookings.reduce(
      (sum, booking) =>
        booking.AddOns.reduce(
          (addOnsSum, addOn) => addOnsSum + (includeServices ? addOn.Amount : 0),
          booking.Seating
            ? sum + (booking.Price ? booking.Price : 0) + this.calculateTotalPriceForSeating(booking.Seating)
            : sum + (booking.Price ? booking.Price : 0),
        ),
      0,
    );

    return result;
  }

  private async BuildGiftcardCommandModel(giftcards: BookingInformationGiftcard[], customer: CustomerInfo) {
    const mainWebSiteOrigin = location.origin + '/booking';
    const languageCode = await this.localeService.getLocale();

    const [appSettings] = await Promise.all([this.config.getAppSettings()]);

    const order = {
      AcceptUrl: mainWebSiteOrigin + '/AcceptPayment/bookingpayment/{orderid}',
      CancelUrl: appSettings.PaymentCancelUrl,
      PreferredCulture: languageCode,
    } as PlaceGiftCardProductsOrderCommand;
    order.Buyer = {
      City: customer.City,
      Email: customer.Email,
      FirstName: customer.FirstName,
      PhoneNo: customer.Phone,
      Surname: customer.LastName,
      ZipCode: customer.ZipCode,
      Address: customer.Address1,
    } as BuyerDTO;

    const recipient = {
      Adress: customer.Address1,
      City: customer.City,
      Email: customer.Email,
      FirstName: customer.FirstName,
      Message: '',
      Surname: customer.LastName,
      ZipCode: customer.ZipCode,
    } as RecipientDTO;

    const giftcardOrderItems = [] as GiftCardOrderItemDTO[];

    const res = [] as PDFGiftCardDTO[];

    for (const item of giftcards) {
      const giftcard = {} as GiftCardOrderItemDTO;
      giftcard.Id = item.Id;
      giftcard.Amount = item.PricePerPiece;
      giftcard.Name = item.Name;

      const card = {
        txtTo: item.GuestInformation.ToName,
        txtFrm: item.GuestInformation.FromName,
        txtMessage: item.GuestInformation.GiftcardMessage,
        showPrice: true,
      } as PDFGiftCardDTO;
      res.push(card);

      giftcardOrderItems.push(giftcard);
    }
    order.OrderItems = giftcardOrderItems;
    order.pdfCards = res;
    order.Recipient = recipient;

    return order;
  }

  private calculateTotalPriceForSeating(seatings: BookingInformationSeating[]) {
    let amount = 0;

    for (const seat of seatings) {
      amount = amount + seat.TotalPrice;
    }
    return amount;
  }

  private calculateTotalPriceForGiftcards(giftcards: BookingInformationGiftcard[]) {
    let amount = 0;

    for (const card of giftcards) {
      amount = amount + card.TotalPrice;
    }
    return amount;
  }
}

export interface GetGiftcardInfoResult {
  IsActive: boolean;
  Name: string;
  Number: string;
  RemainingAmount: number;
}

export interface GiftcardPaymentModel {
  OrderId: string;
  PaidAmount: string;
  CurrencyCode: string;
}

interface PlaceGiftCardProductsOrderCommand {
  AcceptUrl: string;
  CancelUrl: string;
  PreferredCulture: string;
  Buyer: BuyerDTO;
  OrderItems: GiftCardOrderItemDTO[];
  pdfCards: PDFGiftCardDTO[];
  Recipient: RecipientDTO;
}

interface BuyerDTO {
  FirstName: string;
  Surname: string;
  ZipCode: string;
  City: string;
  PhoneNo: string;
  Email: string;
  Address: string;
}

interface GiftCardOrderItemDTO {
  Name: string;
  Amount: number;
  Id: number;
}

interface PDFGiftCardDTO {
  txtTo: string;
  txtFrm: string;
  txtMessage: string;
  showPrice: boolean;
}

interface RecipientDTO {
  FirstName: string;
  Surname: string;
  Adress: string;
  ZipCode: string;
  City: string;
  Message: string;
  Email: string;
}

export interface BookingInformation {
  Bookings: BookingInformationBooking[];
  HotelCode: HotelCodeString;
  Arrival: UTCDateString;
  Stay: number;
  CancelationInsurance: number | null;
  Total: number;
}

export interface BookingInformationBooking {
  RoomName: string;
  RateName: string;
  RoomCode: RoomCodeString;
  RateCode: RateCodeString;
  RateDescription: string;
  HasPromotionForCustomer: boolean;
  Price: number;
  Persons: RoomGuests;
  Guest: CustomerInfo;
  OriginalPrice: number;
  AddOns: BookingExtraServiceModel[];
  Seating: BookingInformationSeating[] | undefined;
  GiftCard: BookingInformationGiftcard[] | undefined;
}

export interface BookingInformationGiftcard {
  Name: string;
  PricePerPiece: number;
  TotalPrice: number;
  Count: number | undefined;
  Id: number;
  GuestInformation: GiftCardGuestInformation;
}

export interface BookingInformationSeating {
  Name: string;
  PricePerPiece: number;
  TotalPrice: number;
  Count: number | undefined;
  Date: Date;
  SeatCode: string;
  Time: string;
  SeatRate: string;
  SeatingPlanName: string;
  SeatGuest: SeatProfileType[];
}

interface ServiceType {
  count: number;
  price: number;
  from: AspDateString;
  to: AspDateString;
  timeFrom: TimeString | null;
  timeTo: TimeString | null;
  serviceCode: string;
  serviceId: string;
  priceIncluded?: boolean;
  serviceType?: string;
  ServiceDate?: string;
}

interface GuestProfileType {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  mobilePhone: string;
}

interface AqcType {
  count: number;
  type: number;
  aqc: string;
}

interface PromotionType {
  discount: number;
  promotionCode: PromoCodeString | null;
}

export interface RoomType {
  arrival: AspDateString;
  departure: AspDateString;
  price: number;
  roomCode: RoomCodeString;
  ratePlan: RateCodeString;
  invBlockCode: string | null;
  hotelCode: HotelCodeString;
  useCancelPolicy: boolean;
  services: ServiceType[];
  guests: GuestProfileType[];
  guestName: string | null;
  aqcs: AqcType[] | null;
  promotionData: PromotionType | null;
  customerRateId: number | null;
}

export interface SeatProfileType {
  SeatId: string;
  VareNr: string;
  Name: string;
  Count: number;
  Price: number;
  Date: UTCDateString;
  Time: string;
  AQC: AQC | undefined;
}

export interface SeatType {
  date: UTCDateString;
  hotelCode: HotelCodeString;
  time: string;
  total: number;
  seatCode: string;
  seatGuests: SeatProfileType[];
  seatrate: string;
  services: ServiceType[];
}

interface UserType {
  CompanyAddress1?: string;
  CompanyAddress2?: string;
  CompanyCity?: string;
  CompanyCountryCode?: string;
  CompanyCountryName?: string;
  CompanyName?: string;
  CompanyPhone?: string;
  CompanyZipCode?: string;
  CustomerContactId?: string;
  Email?: string;
  FirstName?: string;
  LastName?: string;
  PaymentRequired?: boolean;
  Phone?: string;
  CustomerId?: string;
}

interface ProfileType {
  customerNumber?: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  mobilePhone: string;
  phone: string;
  address: string;
  city: string;
  postal: string;
  NeedUpdate: boolean;
  CustomerId: string | null;
  userData?: UserType;
}

interface PaymentType {
  merchant: string;
  currency: string;
  isPaymentEnabled: boolean;
  transactionId: string;
}

export interface GiftcardType {
  hotelCode: HotelCodeString;
  total: number;
  cardName: string;
}

export interface ReservationTypeRequest {
  rooms: RoomType[];
  seats: SeatType[];
  giftcards: GiftcardType[];
  isCustomer: boolean;
  needSubscribe: boolean;
  comment: string;
  bookingAdmin: ProfileType;
  isHotelDepartment: boolean;
  lang: Locale;
  payment: PaymentType;
  total: number;
  services: ServiceType[] | null;
}

interface ReservationTypeResponse {
  PaymentDue: number;
  PaymentFlowToUse: PaymentFlowEnum;
  bookingCode: string;
  cancellationFee: number;
  isCustomer: boolean;
  isHotelDepartment: boolean;
  needSubscribe: boolean;
  paid: number;
  reservationId: string;
  total: number;
  resGuid: Guid;
}

export interface ReservationType extends ReservationTypeRequest {
  PaymentDue: number;
  PaymentFlowToUse: PaymentFlowEnum;
  bookingCode: string;
  cancellationFee: number;
  paid: number;
  paidWithGiftcard: number;
  reservationId: string;
  total: number;
  resGuid?: Guid;
  HotelCode: string;
}

export interface PlaceReservationResponse {
  Success: boolean;
  ErrorText: string;
  Reservation: ReservationTypeResponse | null;
}

export interface ReservationLinkResponse {
  ReservationGuid: string;
  ReservationNumber: string;
  ReservationName: string;
  CancellationProtectionAmount: number;
  GrandTotalAmount: number;
  AlreadyPaidAmount: number;
  PaymentDue: number;
  Roomstays: RoomStayType[];
  ExtraServices: ExtraServiceType[];
  HotelCode: HotelCodeString;
  PaymentFlow: PaymentFlowEnum;
}

interface ExtraServiceType {
  ServiceType: string;
  ServiceDate: string;
  ServiceName: string;
  Quantity: number;
  TotalAmount: number;
}

export interface RoomStayType {
  RoomTypeName: string;
  GuestName: string;
  Arrival: YearMonthDayType;
  Departure: YearMonthDayType;
  Amount: number;
  ExtraServices: ExtraServiceType[];
}

export interface YearMonthDayType {
  Year: number;
  Month: number;
  Day: number;
}

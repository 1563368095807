import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { Loader as BaseLoader, LoaderMessage } from 'src/app/modules/loader';
import { Messages } from 'src/i18n';
import { lastValueFrom } from 'rxjs';
import { LocaleService } from '../modules';

@Injectable()
export class Loader {
  constructor(private loader: BaseLoader, private ls: LocaleService) {}

  async using<T>(action: (loader: LoaderMessage) => Promise<T>, msg?: keyof Messages, transparent = true) {
    return await this.loader.using(
      async (loader) => {
        loader.msg = msg ? (await lastValueFrom(this.ls.translations$.pipe(first())))[msg] : undefined;
        return await action(loader);
      },
      undefined,
      transparent,
    );
  }

  hideAll() {
    this.loader.hideAll();
  }
}

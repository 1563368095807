import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { LogClient, LogEntry, LogLevel } from './log-client.interface';

@Injectable()
export class ConsoleLogClientService implements LogClient {
  private logLevel = LogLevel.Debug;

  log(entry: LogEntry) {
    if (entry.level >= this.logLevel) {
      const msg = `[${LogLevel[entry.level]}] ${dayjs(entry.date).format('YYYY-MM-DD HH:mm:ss')} ${
        entry.msg
      } ${entry.data.map((item) => JSON.stringify(item, null, 2)).join('\n')}`;
      switch (entry.level) {
        case LogLevel.Critical:
        case LogLevel.Error:
          // eslint-disable-next-line no-console
          console.error(msg);
          break;
        case LogLevel.Warning:
          // eslint-disable-next-line no-console
          console.warn(msg);
          break;
        case LogLevel.Information:
          // eslint-disable-next-line no-console
          console.info(msg);
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(msg);
      }
    }
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NavigationHelper } from '../../../helpers/navigation-helper';
import { BehaviorSubject, combineLatest, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import {
  ApplicationStateService,
  Basket,
  BasketService,
  BookingSeating,
  HotelService,
  Loader,
  LocaleService,
  RateCodeString,
  RoomCodeString,
  SearchParams,
  TrackingService,
} from '../../../core';
import { first, last, range, sortBy, uniqBy } from 'lodash-es';
import { HighlightDate } from '../seating-date-calendar.component';
import { SearchComponent } from '../../search/search.component';
import { DateHelper, UTCDateString } from 'src/app/helpers';
import { SeatProfileType } from 'src/app/core/modules/payment/reservation.service';

@Component({
  selector: 'app-seating-standalone-page',
  templateUrl: './seating-standalone-page.component.html',
})
export class SeatingStandalonePageComponent {
  showAnimation = false;
  state: ApplicationStateService | undefined;
  chosenDate$ = new BehaviorSubject<Moment | null>(null);
  basketInfo$ = new BehaviorSubject<SeatingStandaloneBasketInfo[]>([]);
  basket!: Basket;
  seatingsToBasketArray: BookingSeating[] = [];
  paramDateFormat = 'DD-MM-YYYY';
  characteristics = '';
  canContinue = false;
  seatingParams: SeatingParams = {
    date: '',
    characteristics: '',
    hotelCode: '',
  };

  data$ = combineLatest([this.route.queryParamMap, this.route.paramMap, this.chosenDate$, this.locale.locale$]).pipe(
    map(([queryParams, params, chosenDate]) => {
      this.basketInfo$.next([]);
      const date = queryParams.get('date') ?? new Date();
      const hotelCode = queryParams.get('hotelCode') ?? this.applicationState.CurrentHotelCode;
      const characteristics = params.get('characteristics') ?? '';

      return {
        chosenDate: chosenDate ?? moment(date, this.paramDateFormat),
        characteristics: characteristics,
        hotelCode: hotelCode,
      };
    }),
    switchMap((data) =>
      from(this.getSeating(data.hotelCode, data.characteristics, data.chosenDate)).pipe(
        map((seatingData) => {
          this.seatingParams = {
            date: data.chosenDate.format(this.paramDateFormat).toString(),
            characteristics: data.characteristics,
            hotelCode: data.hotelCode,
          };

          if (this.chosenDate$.getValue() === null) {
            this.chosenDate$.next(data.chosenDate);
          }

          if (seatingData) {
            if (!data.chosenDate.isSame(seatingData.chosenDate, 'd')) {
              this.seatingParams.date = seatingData.chosenDate.format(this.paramDateFormat).toString();
              this.chosenDate$.next(seatingData.chosenDate);
            }
            seatingData.minDate = first(seatingData.availableDates)?.clone().startOf('day') ?? seatingData.minDate;
            seatingData.maxDate = last(seatingData.availableDates)?.clone().endOf('day') ?? seatingData.maxDate;
            this.basket = this.rebuildBasket(this.route.snapshot.queryParams, seatingData.chosenDate);
            seatingData.seating = this.rebuildSeatingUi(seatingData.seating);
          }

          this.navigationHelper.continue(
            `seating-standalone/${this.seatingParams.characteristics}`,
            `seating-standalone/${this.seatingParams.characteristics}`,
            {
              queryParams: {
                hotelCode: this.seatingParams.hotelCode,
                date: this.seatingParams.date,
              },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            },
          );

          return {
            seatingUi: seatingData?.seating,
            minDate: seatingData?.minDate,
            maxDate: seatingData?.maxDate,
            availableDates: seatingData?.availableDates,
            chosenDate: seatingData?.chosenDate,
          };
        }),
      ),
    ),
  );

  priceInformation$ = combineLatest([this.basketInfo$]).pipe(
    map(([basketInfo]) => {
      const price = basketInfo.reduce((prevVal, curVal) => prevVal + curVal.price * curVal.numberOfTickets, 0);

      return {
        price,
      };
    }),
  );

  constructor(
    private route: ActivatedRoute,
    private navigationHelper: NavigationHelper,
    private hotelService: HotelService,
    private applicationState: ApplicationStateService,
    private locale: LocaleService,
    private loader: Loader,
    private basketService: BasketService,
    private trackingService: TrackingService,
  ) {
    this.state = this.applicationState;
  }

  setShowAnimation(isMaxHeight: boolean) {
    this.showAnimation = isMaxHeight;
  }

  async onNavigateBack() {
    await this.navigationHelper.goBack();
  }

  async onNavigateToCheckout() {
    if (!this.basket.bookings.some((b) => b.Seatings && b.Seatings.length > 0)) {
      return;
    }
    await this.navigationHelper.continue(`seating-standalone/${this.seatingParams.characteristics}`, '/customer', {
      queryParams: {
        date: this.seatingParams.date,
        hotelCode: this.seatingParams.hotelCode,
      },
    });
  }

  async getSeating(hotelCode: string, characteristics: string, date: Moment): Promise<SeatingStandaloneInfo | null> {
    return await this.loader.using(async () => {
      // Initialize minDate and maxDate that the user can choose
      const minDate = moment(new Date()).startOf('day');
      const maxDate = moment(new Date()).add(1, 'year').endOf('day');

      const seatingSpectra = await this.hotelService.GetEcommerceSeating(
        hotelCode,
        minDate.toDate(),
        maxDate.toDate(),
        await this.locale.getLocale(),
        characteristics,
      );

      if (seatingSpectra.length !== 0) {
        const seatingsData = seatingSpectra.filter((x) => x.Rates !== null);

        let seating: SeatingStandaloneUIModel | null = null;
        for (const seatingData of seatingsData) {
          const seatingPortalInfo = await this.hotelService.GetECommerceSeatingPortalInfo(
            hotelCode,
            minDate.toDate(),
            maxDate.toDate(),
            seatingData.ItemNumber,
            false,
            '',
          );
          const info = seatingPortalInfo.find((x) => x.ID === seatingData.ItemNumber);

          if (info) {
            let availableDates: HighlightDate[] = [];
            const seatItem = seatingData.Rates.reduce<SeatingStandaloneSeatItem>(
              (prevVal, curVal, currentIndex) => {
                const price = info.SeatItems[currentIndex].Price;

                if (!seating) {
                  availableDates.push({
                    date: curVal.Arrival,
                    occupied: curVal.MaxPers === 0,
                    inBasket: false,
                  });
                }

                const seatingRate: SeatingStandaloneSeatItem = {
                  Description: seatingData.Description,
                  SKU: seatingData.ItemNumber,
                  price: price,
                  maxTickets: range(curVal.MaxPers + 1),
                  timeSlots: [],
                  chosenTicketAmount: 0,
                  chosenTimeslot: null,
                };

                return seatingRate;
              },
              {
                Description: '',
                SKU: '',
                price: 0,
                maxTickets: [],
                timeSlots: [],
                chosenTicketAmount: 0,
                chosenTimeslot: null,
              },
            );

            if (seating) {
              seating.seatItems = [...seating.seatItems, seatItem];
              continue;
            }

            availableDates = sortBy(
              uniqBy(availableDates, (date) => `${date.date.toString()}_${date.occupied}`),
              (d) => d.date,
            );

            seating = {
              header: info.Name,
              image: info.Image,
              description: info.Long,
              characteristics: seatingData.Characteristics,
              highlightedDates: availableDates,
              seatItems: [seatItem],
            };
          }
        }

        if (seating !== null) {
          // TODO: No available dates - should we display something?
          // Calculate all available dates from if they are occupied
          const availableDates = seating.highlightedDates.reduce<Moment[]>((prevVal, curVal) => {
            if (!curVal.occupied) {
              return [...prevVal, moment(curVal.date)];
            }

            return [...prevVal];
          }, []);

          // Check if the date chosen is available
          const isDateAvailable = availableDates.some((d) => d.isSame(date, 'd'));
          // Decide whether the date should change
          const pickedDate = !isDateAvailable && availableDates[0] ? availableDates[0] : date;

          // Generate all available timeslots for the specific day
          const timeSlots = availableDates.filter((d) => d.isSame(pickedDate, 'd'));

          seating.seatItems = seating.seatItems.map((s) => {
            s.chosenTimeslot = timeSlots[0].toISOString();
            s.timeSlots = timeSlots;
            return s;
          });

          return {
            minDate: minDate,
            maxDate: maxDate,
            seating,
            availableDates: availableDates,
            chosenDate: pickedDate,
          };
        }
      }

      return null;
    });
  }

  updateAmountBasket(type: string, seating: SeatingStandaloneUIModel, seatItem: SeatingStandaloneSeatItem) {
    const existingSeating = this.seatingsToBasketArray.find(
      (x) =>
        x.VareNr == seatItem.SKU && x.Date.toString() == moment(seatItem.chosenTimeslot).format('YYYY-MM-DDTHH:mm:ss'),
    );

    if (type === 'increase' && seatItem.maxTickets.length > seatItem.chosenTicketAmount) {
      this.trackingService.ecommerceAddToCart({
        item_id: seatItem.SKU,
        item_name: seating.characteristics,
        item_category: 'Seating_Standalone',
        price: seatItem.price,
        quantity: 1,
      });
      seatItem.chosenTicketAmount = seatItem.chosenTicketAmount + 1;
    } else if (type === 'decrease' && seatItem.chosenTicketAmount > 0) {
      this.trackingService.ecommerceRemoveFromCart({
        item_id: seatItem.SKU,
        item_name: seating.characteristics,
        item_category: 'Seating_Standalone',
        price: seatItem.price,
        quantity: 1,
      });
      seatItem.chosenTicketAmount = seatItem.chosenTicketAmount - 1;
    } else return;

    this.persistBasket(seating, seatItem, existingSeating);
    if (this.seatingsToBasketArray.length === 0) this.canContinue = false;
  }

  updateTimeBasket($event: Event, seating: SeatingStandaloneUIModel, seatItem: SeatingStandaloneSeatItem) {
    const existingSeating = this.seatingsToBasketArray.find(
      (x) =>
        x.VareNr == seatItem.SKU && x.Date.toString() == moment(seatItem.chosenTimeslot).format('YYYY-MM-DDTHH:mm:ss'),
    );

    this.persistBasket(seating, seatItem, existingSeating);
  }

  persistBasket(
    seating: SeatingStandaloneUIModel,
    seatItem: SeatingStandaloneSeatItem,
    existingSeating: BookingSeating | undefined,
  ) {
    const dateObject = seatItem.timeSlots[0].toDate();

    if (existingSeating) {
      if (seatItem.chosenTicketAmount === 0) {
        const objectIndex = this.seatingsToBasketArray.indexOf(existingSeating, 0);
        this.seatingsToBasketArray.splice(objectIndex, 1);
      } else {
        existingSeating.TicketTotal = seatItem.chosenTicketAmount;
        const seatGuests = [] as SeatProfileType[];
        const guest: SeatProfileType = {
          Count: seatItem.chosenTicketAmount,
          Date:
            dateObject !== undefined
              ? (moment(seatItem.chosenTimeslot).format('YYYY-MM-DD') as UTCDateString)
              : (DateHelper.toServerDateFormatString(new Date()) as UTCDateString),
          Name: seatItem.Description,
          Price: seatItem.price,
          Time: moment(seatItem.chosenTimeslot).format('HH:mm'),
          SeatId: seatItem.SKU,
          VareNr: seatItem.SKU,
          AQC: undefined,
        };
        seatGuests.push(guest);

        existingSeating.SeatGuest = seatGuests;
      }
    } else if (seatItem.chosenTicketAmount !== 0) {
      this.canContinue = true;
      const seatGuests = [] as SeatProfileType[];
      const guest: SeatProfileType = {
        Count: seatItem.chosenTicketAmount,
        Date:
          dateObject !== undefined
            ? (DateHelper.toServerDateFormatString(moment(seatItem.chosenTimeslot).toDate()) as UTCDateString)
            : (DateHelper.toServerDateFormatString(new Date()) as UTCDateString),
        Name: seatItem.Description,
        Price: seatItem.price,
        Time: moment(seatItem.chosenTimeslot).format('HH:mm'),
        SeatId: seatItem.SKU,
        VareNr: seatItem.SKU,
        AQC: undefined,
      };
      seatGuests.push(guest);

      const seatingToBasket: BookingSeating = {
        SeatCode: 'DELT',
        Name: seatItem.Description,
        Id: seatItem.Description,
        seatId: 0,
        VareNr: seatItem.SKU,
        type: 'SeatingBooking',
        SinglePrice: seatItem.price,
        SeatRate: seating.characteristics,
        //@ts-ignore
        Date: moment(seatItem.chosenTimeslot).format('YYYY-MM-DDTHH:mm:ss'),
        day: dateObject.getDate().toString(),
        month: DateHelper.getTranslatedMonth(dateObject.getMonth()),
        year: dateObject.getFullYear().toString(),
        Time: moment(seatItem.chosenTimeslot).format('HH:mm'),
        SeatGuest: seatGuests,
        TicketTotal: seatItem.chosenTicketAmount,
      };

      this.seatingsToBasketArray.push(seatingToBasket);
    }

    const basket = this.basketService.get();

    if (basket) {
      basket.bookings[0] = {
        Seatings: this.seatingsToBasketArray,
        HasPromotionForCustomer: false,
        RateCode: '' as RateCodeString,
        RateName: '',
        RoomCode: '' as RoomCodeString,
        RoomName: '',
        RateDescription: '',
        ConferenceRateCode: '',
        ConferenceRoomCode: '',
        Price: undefined,
        OriginalPrice: undefined,
      };

      this.basketService.set(basket);
    }
  }

  private rebuildSeatingUi(seating: SeatingStandaloneUIModel) {
    seating.seatItems.forEach((seatItem) => {
      const basketInfo = this.basketInfo$.getValue();
      const basketSeating = basketInfo.find(
        (b) => b.timeSlot.isSame(seatItem.chosenTimeslot) && b.SKU === seatItem.SKU,
      );

      if (basketSeating) {
        seatItem.chosenTimeslot = basketSeating.timeSlot.toISOString();
        seatItem.chosenTicketAmount = basketSeating.numberOfTickets;
      }
    });

    return seating;
  }

  private rebuildBasket(queryParams: Params, chosenDate: Moment) {
    const hotelCode = SearchComponent.ParseHotelCode(queryParams);
    const arrival = chosenDate.toDate();
    const basket = this.basketService.get();

    if (basket) {
      const basketInfo: SeatingStandaloneBasketInfo[] = [];
      for (const booking of basket.bookings) {
        if (!booking.Seatings) continue;
        for (const seating of booking.Seatings) {
          for (const seatGuest of seating.SeatGuest) {
            const timeSlot = moment(seatGuest.Date, 'YYYY-MM-DD');
            timeSlot.add(seatGuest.Time, 'h');

            const info: SeatingStandaloneBasketInfo = {
              SKU: seatGuest.VareNr,
              price: seatGuest.Price,
              timeSlot: timeSlot,
              numberOfTickets: seatGuest.Count,
            };
            basketInfo.push(info);
          }
        }
      }
      this.basketInfo$.next(basketInfo);
      return basket;
    }

    if (hotelCode && arrival) {
      const params: SearchParams = {
        hotelCode,
        arrival,
        stay: 30,
        rooms: [],
        single: false,
        ArrivalDate: 0,
        ArrivalFullYear: 0,
        ArrivalMonthName: '',
        DepartueDate: 0,
        DepartueFullYear: 0,
        DepartueMonthName: '',
        BookingFlow: 1,
        RateCode: '',
        ConferenceRoomCode: '',
      };
      this.basketService.basketBadgeNumberSet = '0';
      return this.basketService.create(params);
    }
    throw new Error('Basket must not be empty');
  }
}

interface SeatingStandaloneUIModel {
  header: string;
  image: string;
  description: string;
  characteristics: string;
  highlightedDates: HighlightDate[];
  seatItems: SeatingStandaloneSeatItem[];
}

interface SeatingStandaloneSeatItem {
  Description: string;
  SKU: string;
  price: number;
  maxTickets: number[];
  timeSlots: Moment[];
  chosenTimeslot: string | null;
  chosenTicketAmount: number;
}

interface SeatingStandaloneInfo {
  minDate: Moment;
  maxDate: Moment;
  seating: SeatingStandaloneUIModel;
  availableDates: Moment[];
  chosenDate: Moment;
}

interface SeatingStandaloneBasketInfo {
  SKU: string;
  price: number;
  numberOfTickets: number;
  timeSlot: Moment;
}

interface SeatingParams {
  date: string;
  hotelCode: string;
  characteristics: string;
}

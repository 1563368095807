import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MatDatepickerIntl {
  /**
   * Stream that emits whenever the labels here are changed. Use this to notify
   * components if the labels have changed after initialization.
   */
  readonly changes: Subject<void> = new Subject<void>();

  /** A label for the calendar popup (used by screen readers). */
  calendarLabel = 'Kalender';

  /** A label for the button used to open the calendar popup (used by screen readers). */
  openCalendarLabel = 'Åben kalender';

  /** Label for the button used to close the calendar popup. */
  closeCalendarLabel = 'Luk kalender';

  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel = 'Forrige månede';

  /** A label for the next month button (used by screen readers). */
  nextMonthLabel = 'Næste månede';

  /** A label for the previous year button (used by screen readers). */
  prevYearLabel = 'Forrige år';

  /** A label for the next year button (used by screen readers). */
  nextYearLabel = 'Næste år';

  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel = 'Forrige 24 år';

  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel = 'Næste 24 år';

  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel = 'Vælg dato';

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel = 'Vælg måned og år';

  /** A label for the first date of a range of dates (used by screen readers). */
  startDateLabel = 'Start dato';

  /** A label for the last date of a range of dates (used by screen readers). */
  endDateLabel = 'Slut dato';

  /** Formats a range of years (used for visuals). */
  formatYearRange(start: string, end: string): string {
    return `${start} \u2013 ${end}`;
  }

  /** Formats a label for a range of years (used by screen readers). */
  formatYearRangeLabel(start: string, end: string): string {
    return `${start} til ${end}`;
  }
}

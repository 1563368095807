import { Country } from './country';

export const COUNTRIES: Country[] = [
  { AreaCode: '+45', Code: 'DK', Name: 'Denmark' },
  { AreaCode: '+46', Code: 'SE', Name: 'Sweden' },
  { AreaCode: '+47', Code: 'NO', Name: 'Norway' },
  { AreaCode: '+1', Code: 'US', Name: 'Canada' },
  { AreaCode: '+39', Code: 'IT', Name: 'Italy' },
  { AreaCode: '+1', Code: 'US', Name: 'United States of America' },
  { AreaCode: '+93', Code: 'AF', Name: 'Afghanistan' },
  { AreaCode: '+355', Code: 'AL', Name: 'Albania' },
  { AreaCode: '+213', Code: 'DZ', Name: 'Algeria' },
  { AreaCode: '+376', Code: 'AD', Name: 'Andorra' },
  { AreaCode: '+244', Code: 'AO', Name: 'Angola' },
  { AreaCode: '+672', Code: 'AQ', Name: 'Antarctica' },
  { AreaCode: '+54', Code: 'AR', Name: 'Argentina' },
  { AreaCode: '+297', Code: 'AW', Name: 'Aruba' },
  { AreaCode: '+61', Code: 'AU', Name: 'Australia' },
  { AreaCode: '+43', Code: 'AT', Name: 'Austria' },
  { AreaCode: '+994', Code: 'AZ', Name: 'Azerbaijan' },
  { AreaCode: '+973', Code: 'BH', Name: 'Bahrain' },
  { AreaCode: '+880', Code: 'BD', Name: 'Bangladesh' },
  { AreaCode: '+375', Code: 'BY', Name: 'Belarus' },
  { AreaCode: '+32', Code: 'BE', Name: 'Belgium' },
  { AreaCode: '+501', Code: 'BZ', Name: 'Belize' },
  { AreaCode: '+229', Code: 'BJ', Name: 'Benin' },
  { AreaCode: '+975', Code: 'BT', Name: 'Bhutan' },
  { AreaCode: '+591', Code: 'BO', Name: 'Bolivia' },
  { AreaCode: '+387', Code: 'BA', Name: 'Bosnia & Herzegovina' },
  { AreaCode: '+267', Code: 'BW', Name: 'Botswana' },
  { AreaCode: '+55', Code: 'BR', Name: 'Brazil' },
  { AreaCode: '+673', Code: 'BN', Name: 'Brunei Darussalam' },
  { AreaCode: '+359', Code: 'BG', Name: 'Bulgaria' },
  { AreaCode: '+226', Code: 'BF', Name: 'Burkina Faso' },
  { AreaCode: '+257', Code: 'BI', Name: 'Burundi' },
  { AreaCode: '+855', Code: 'KH', Name: 'Cambodia' },
  { AreaCode: '+237', Code: 'CM', Name: 'Cameroon' },
  { AreaCode: '+238', Code: 'CV', Name: 'Cape Verde Islands' },
  { AreaCode: '+236', Code: 'CF', Name: 'Central African Republic' },
  { AreaCode: '+235', Code: 'TD', Name: 'Chad' },
  { AreaCode: '+64', Code: 'NZ', Name: 'Chatham Island (New Zealand)' },
  { AreaCode: '+56', Code: 'CL', Name: 'Chile' },
  { AreaCode: '+86', Code: 'CN', Name: 'China (PRC)' },
  { AreaCode: '+61', Code: 'AU', Name: 'Cocos-Keeling Islands' },
  { AreaCode: '+57', Code: 'CO', Name: 'Colombia' },
  { AreaCode: '+269', Code: 'YT', Name: 'Comoros' },
  { AreaCode: '+242', Code: 'CG', Name: 'Congo' },
  { AreaCode: '+243', Code: 'CD', Name: 'Congo, Dem. Rep. of? (former Zaire)' },
  { AreaCode: '+682', Code: 'CK', Name: 'Cook Islands' },
  { AreaCode: '+506', Code: 'CR', Name: 'Costa Rica' },
  { AreaCode: '+225', Code: 'CI', Name: "Cote d'Ivoire (Ivory Coast)" },
  { AreaCode: '+385', Code: 'HR', Name: 'Croatia' },
  { AreaCode: '+53', Code: 'CU', Name: 'Cuba' },
  { AreaCode: '+599', Code: 'SX', Name: 'Curacao' },
  { AreaCode: '+357', Code: 'CY', Name: 'Cyprus' },
  { AreaCode: '+420', Code: 'CZ', Name: 'Czech Republic' },
  { AreaCode: '+253', Code: 'DJ', Name: 'Djibouti' },
  { AreaCode: '+670', Code: 'TL', Name: 'East Timor' },
  { AreaCode: '+56', Code: 'CL', Name: 'Easter Island' },
  { AreaCode: '+593', Code: 'EC', Name: 'Ecuador' },
  { AreaCode: '+20', Code: 'EG', Name: 'Egypt' },
  { AreaCode: '+503', Code: 'SV', Name: 'El Salvador' },
  { AreaCode: '+240', Code: 'GQ', Name: 'Equatorial Guinea' },
  { AreaCode: '+291', Code: 'ER', Name: 'Eritrea' },
  { AreaCode: '+372', Code: 'EE', Name: 'Estonia' },
  { AreaCode: '+251', Code: 'ET', Name: 'Ethiopia' },
  { AreaCode: '+500', Code: 'FK', Name: 'Falkland Islands (Malvinas)' },
  { AreaCode: '+298', Code: 'FO', Name: 'Faroe Islands' },
  { AreaCode: '+679', Code: 'FJ', Name: 'Fiji Islands' },
  { AreaCode: '+358', Code: 'FI', Name: 'Finland' },
  { AreaCode: '+33', Code: 'FR', Name: 'France' },
  { AreaCode: '+596', Code: 'MQ', Name: 'French Antilles' },
  { AreaCode: '+594', Code: 'GF', Name: 'French Guiana' },
  { AreaCode: '+689', Code: 'PF', Name: 'French Polynesia' },
  { AreaCode: '+241', Code: 'GA', Name: 'Gabonese Republic' },
  { AreaCode: '+220', Code: 'GM', Name: 'Gambia' },
  { AreaCode: '+49', Code: 'DE', Name: 'Germany' },
  { AreaCode: '+233', Code: 'GH', Name: 'Ghana' },
  { AreaCode: '+350', Code: 'GI', Name: 'Gibraltar' },
  { AreaCode: '+30', Code: 'GR', Name: 'Greece' },
  { AreaCode: '+299', Code: 'GL', Name: 'Greenland' },
  { AreaCode: '+590', Code: 'GP', Name: 'Guadeloupe' },
  { AreaCode: '+502', Code: 'GT', Name: 'Guatemala' },
  { AreaCode: '+224', Code: 'GN', Name: 'Guinea' },
  { AreaCode: '+245', Code: 'GW', Name: 'Guinea-Bissau' },
  { AreaCode: '+592', Code: 'GY', Name: 'Guyana' },
  { AreaCode: '+509', Code: 'HT', Name: 'Haiti' },
  { AreaCode: '+504', Code: 'HN', Name: 'Honduras' },
  { AreaCode: '+852', Code: 'HK', Name: 'Hong Kong' },
  { AreaCode: '+36', Code: 'HU', Name: 'Hungary' },
  { AreaCode: '+354', Code: 'IS', Name: 'Iceland' },
  { AreaCode: '+91', Code: 'IN', Name: 'India' },
  { AreaCode: '+62', Code: 'ID', Name: 'Indonesia' },
  { AreaCode: '+98', Code: 'IR', Name: 'Iran' },
  { AreaCode: '+964', Code: 'IQ', Name: 'Iraq' },
  { AreaCode: '+353', Code: 'IE', Name: 'Ireland' },
  { AreaCode: '+972', Code: 'IL', Name: 'Israel' },
  { AreaCode: '+81', Code: 'JP', Name: 'Japan' },
  { AreaCode: '+962', Code: 'JO', Name: 'Jordan' },
  { AreaCode: '+7', Code: 'RU', Name: 'Kazakhstan' },
  { AreaCode: '+254', Code: 'KE', Name: 'Kenya' },
  { AreaCode: '+686', Code: 'KI', Name: 'Kiribati' },
  { AreaCode: '+850', Code: 'KP', Name: 'Korea (North)' },
  { AreaCode: '+82', Code: 'KR', Name: 'Korea (South)' },
  { AreaCode: '+965', Code: 'KW', Name: 'Kuwait' },
  { AreaCode: '+996', Code: 'KG', Name: 'Kyrgyz Republic' },
  { AreaCode: '+856', Code: 'LA', Name: 'Laos' },
  { AreaCode: '+371', Code: 'LV', Name: 'Latvia' },
  { AreaCode: '+961', Code: 'LB', Name: 'Lebanon' },
  { AreaCode: '+266', Code: 'LS', Name: 'Lesotho' },
  { AreaCode: '+231', Code: 'LR', Name: 'Liberia' },
  { AreaCode: '+218', Code: 'LY', Name: 'Libya' },
  { AreaCode: '+423', Code: 'LI', Name: 'Liechtenstein' },
  { AreaCode: '+370', Code: 'LT', Name: 'Lithuania' },
  { AreaCode: '+352', Code: 'LU', Name: 'Luxembourg' },
  { AreaCode: '+853', Code: 'MO', Name: 'Macao' },
  { AreaCode: '+389', Code: 'MK', Name: 'Macedonia (Former Yugoslav Rep of.)' },
  { AreaCode: '+261', Code: 'MG', Name: 'Madagascar' },
  { AreaCode: '+265', Code: 'MW', Name: 'Malawi' },
  { AreaCode: '+60', Code: 'MY', Name: 'Malaysia' },
  { AreaCode: '+960', Code: 'MV', Name: 'Maldives' },
  { AreaCode: '+223', Code: 'ML', Name: 'Mali Republic' },
  { AreaCode: '+356', Code: 'MT', Name: 'Malta' },
  { AreaCode: '+692', Code: 'MH', Name: 'Marshall Islands' },
  { AreaCode: '+596', Code: 'MQ', Name: 'Martinique' },
  { AreaCode: '+222', Code: 'MR', Name: 'Mauritania' },
  { AreaCode: '+230', Code: 'MU', Name: 'Mauritius' },
  { AreaCode: '+269', Code: 'YT', Name: 'Mayotte Island' },
  { AreaCode: '+52', Code: 'MX', Name: 'Mexico' },
  { AreaCode: '+691', Code: 'FM', Name: 'Micronesia, (Federal States of)' },
  { AreaCode: '+373', Code: 'MD', Name: 'Moldova' },
  { AreaCode: '+377', Code: 'MC', Name: 'Monaco' },
  { AreaCode: '+976', Code: 'MN', Name: 'Mongolia' },
  { AreaCode: '+382', Code: 'ME', Name: 'Montenegro' },
  { AreaCode: '+212', Code: 'MA', Name: 'Morocco' },
  { AreaCode: '+258', Code: 'MZ', Name: 'Mozambique' },
  { AreaCode: '+95', Code: 'MM', Name: 'Myanmar' },
  { AreaCode: '+264', Code: 'NA', Name: 'Namibia' },
  { AreaCode: '+674', Code: 'NR', Name: 'Nauru' },
  { AreaCode: '+977', Code: 'NP', Name: 'Nepal' },
  { AreaCode: '+31', Code: 'NL', Name: 'Netherlands' },
  { AreaCode: '+599', Code: 'SX', Name: 'Netherlands Antilles' },
  { AreaCode: '+687', Code: 'NC', Name: 'New Caledonia' },
  { AreaCode: '+64', Code: 'NZ', Name: 'New Zealand' },
  { AreaCode: '+505', Code: 'NI', Name: 'Nicaragua' },
  { AreaCode: '+227', Code: 'NE', Name: 'Niger' },
  { AreaCode: '+234', Code: 'NG', Name: 'Nigeria' },
  { AreaCode: '+683', Code: 'NU', Name: 'Niue' },
  { AreaCode: '+968', Code: 'OM', Name: 'Oman' },
  { AreaCode: '+92', Code: 'PK', Name: 'Pakistan' },
  { AreaCode: '+680', Code: 'PW', Name: 'Palau' },
  { AreaCode: '+970', Code: 'PS', Name: 'Palestinian Settlements' },
  { AreaCode: '+507', Code: 'PA', Name: 'Panama' },
  { AreaCode: '+675', Code: 'PG', Name: 'Papua New Guinea' },
  { AreaCode: '+595', Code: 'PY', Name: 'Paraguay' },
  { AreaCode: '+51', Code: 'PE', Name: 'Peru' },
  { AreaCode: '+63', Code: 'PH', Name: 'Philippines' },
  { AreaCode: '+48', Code: 'PL', Name: 'Poland' },
  { AreaCode: '+351', Code: 'PT', Name: 'Portugal' },
  { AreaCode: '+974', Code: 'QA', Name: 'Qatar' },
  { AreaCode: '+262', Code: 'RE', Name: 'R?union Island' },
  { AreaCode: '+40', Code: 'RO', Name: 'Romania' },
  { AreaCode: '+7', Code: 'RU', Name: 'Russia' },
  { AreaCode: '+250', Code: 'RW', Name: 'Rwandese Republic' },
  { AreaCode: '+239', Code: 'ST', Name: 'S?o Tom? and Principe' },
  { AreaCode: '+685', Code: 'WS', Name: 'Samoa' },
  { AreaCode: '+378', Code: 'SM', Name: 'San Marino' },
  { AreaCode: '+966', Code: 'SA', Name: 'Saudi Arabia' },
  { AreaCode: '+221', Code: 'SN', Name: 'Senegal' },
  { AreaCode: '+381', Code: 'RS', Name: 'Serbia' },
  { AreaCode: '+248', Code: 'SC', Name: 'Seychelles Republic' },
  { AreaCode: '+232', Code: 'SL', Name: 'Sierra Leone' },
  { AreaCode: '+65', Code: 'SG', Name: 'Singapore' },
  { AreaCode: '+421', Code: 'SK', Name: 'Slovak Republic' },
  { AreaCode: '+386', Code: 'SI', Name: 'Slovenia' },
  { AreaCode: '+677', Code: 'SB', Name: 'Solomon Islands' },
  { AreaCode: '+252', Code: 'SO', Name: 'Somali Democratic Republic' },
  { AreaCode: '+27', Code: 'ZA', Name: 'South Africa' },
  { AreaCode: '+34', Code: 'ES', Name: 'Spain' },
  { AreaCode: '+94', Code: 'LK', Name: 'Sri Lanka' },
  { AreaCode: '+290', Code: 'SH', Name: 'St. Helena' },
  { AreaCode: '+508', Code: 'PM', Name: 'St. Pierre & Miquelon' },
  { AreaCode: '+249', Code: 'SD', Name: 'Sudan' },
  { AreaCode: '+597', Code: 'SR', Name: 'Suriname' },
  { AreaCode: '+268', Code: 'SZ', Name: 'Swaziland' },
  { AreaCode: '+41', Code: 'CH', Name: 'Switzerland' },
  { AreaCode: '+963', Code: 'SY', Name: 'Syria' },
  { AreaCode: '+886', Code: 'TW', Name: 'Taiwan' },
  { AreaCode: '+992', Code: 'TJ', Name: 'Tajikistan' },
  { AreaCode: '+255', Code: 'TZ', Name: 'Tanzania' },
  { AreaCode: '+66', Code: 'TH', Name: 'Thailand' },
  { AreaCode: '+670', Code: 'TL', Name: 'Timor Leste' },
  { AreaCode: '+228', Code: 'TG', Name: 'Togolese Republic' },
  { AreaCode: '+690', Code: 'TK', Name: 'Tokelau' },
  { AreaCode: '+676', Code: 'TO', Name: 'Tonga Islands' },
  { AreaCode: '+216', Code: 'TN', Name: 'Tunisia' },
  { AreaCode: '+90', Code: 'TR', Name: 'Turkey' },
  { AreaCode: '+993', Code: 'TM', Name: 'Turkmenistan' },
  { AreaCode: '+688', Code: 'TV', Name: 'Tuvalu' },
  { AreaCode: '+256', Code: 'UG', Name: 'Uganda' },
  { AreaCode: '+380', Code: 'UA', Name: 'Ukraine' },
  { AreaCode: '+971', Code: 'AE', Name: 'United Arab Emirates' },
  { AreaCode: '+44', Code: 'GB', Name: 'United Kingdom' },
  { AreaCode: '+598', Code: 'UY', Name: 'Uruguay' },
  { AreaCode: '+998', Code: 'UZ', Name: 'Uzbekistan' },
  { AreaCode: '+678', Code: 'VU', Name: 'Vanuatu' },
  { AreaCode: '+39', Code: 'IT', Name: 'Vatican City' },
  { AreaCode: '+58', Code: 'VE', Name: 'Venezuela' },
  { AreaCode: '+84', Code: 'VN', Name: 'Vietnam' },
  { AreaCode: '+681', Code: 'WF', Name: 'Wallis and Futuna Islands' },
  { AreaCode: '+967', Code: 'YE', Name: 'Yemen' },
  { AreaCode: '+260', Code: 'ZM', Name: 'Zambia' },
  { AreaCode: '+255', Code: 'TZ', Name: 'Zanzibar' },
  { AreaCode: '+263', Code: 'ZW', Name: 'Zimbabwe' },
];

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LogService } from '@com/logging';
import { combineLatest, from, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ApplicationStateService, Loader, OriginEnum, TrackingService } from 'src/app/core';
import { Basket, BasketService, BookingService } from 'src/app/core';
import { DateHelper, UTCDateString, UTCDateTimeString } from 'src/app/helpers';
import { CountryService } from 'src/app/modules/country';
import { Messenger } from 'src/app/modules/messenger';
import { ConfigService, HotelCodeString } from '../../core/modules/config';
import { Customer, CustomerInfo } from '../../core/modules/customer/customer';
import { CustomerService } from '../../core/modules/customer/customer.service';
import {
  BookingExtraServiceModel,
  CancellationProtection,
  GiftcardReservationPayment,
} from '../../core/modules/hotel/data-hotel.service';
import { HotelService } from '../../core/modules/hotel/hotel.service';
import { HotelType, Rate } from '../../core/modules/hotel/models';
import { Locale, LocaleService } from '../../core/modules/locale';
import {
  BookingInformation,
  BookingInformationBooking,
  BookingInformationGiftcard,
  BookingInformationSeating,
  PaymentFlowEnum,
  PaymentService,
  ReservationService,
  ReservationType,
} from '../../core/modules/payment';
import { PromotionService } from '../../core/modules/promotion';
import { NavigationHelper } from '../../helpers/navigation-helper';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
})
export class CustomerComponent implements OnInit, OnDestroy {
  private isSubmitting = false;
  logo: string | undefined;
  contentDialogHeader: string | undefined;
  countries = this.loadCountries();
  basket: Basket | undefined;
  hasAddons = false;
  hasSeatings = false;
  hasTableReservation = false;
  hasGiftCard = false;
  hasRooms = true;
  hotel: HotelType | undefined;
  customer = ((customer: Customer) => ({
    isKnownCustomer: !!customer.Id,
    info: customer.Info,
  }))(this.customerService.getCustomer());
  isCustomerInfoEditable = true;
  needSubscribe = false;
  showSubscribeCheckbox = false;
  comment = '';
  defaultCountryCode = '';
  information$: Observable<BookingInformation> | undefined;
  acceptTerms = false;
  showGuestCountry = false;
  promocode = this.promotionService.getPromocode();
  useCancelationInsurance = false;
  giftcardInformation: GiftcardInformationModel | undefined;
  repeatEmailvalid = true;
  cancellationprotection: CancellationProtection | undefined;
  calculatedCancellationFee: number | undefined;

  @ViewChild('messageHotelTermsTempalte', { static: false })
  messageHotelTermsTempalte: TemplateRef<HotelTermsMessage> | undefined;

  constructor(
    private log: LogService,
    private loader: Loader,
    private localeService: LocaleService,
    private countryService: CountryService,
    private customerService: CustomerService,
    private basketService: BasketService,
    private hotelService: HotelService,
    private messenger: Messenger,
    private config: ConfigService,
    private promotionService: PromotionService,
    private bookingService: BookingService,
    private applicationState: ApplicationStateService,
    private navigationHelper: NavigationHelper,
    private reservationService: ReservationService,
    private paymentService: PaymentService,
    private trackingService: TrackingService,
  ) {}

  async ngOnInit() {
    await this.getCancellationprotection();
    this.applicationState.PaymentlinkCallBack = 'false';
    window.scrollTo(0, 0);
    this.basket = await this.getBasketOrFail();
    const basket = this.basket;

    this.showSubscribeCheckbox = (await this.config.getCustomerParams()).ShowSubscribeCheckbox;

    const hotel = (this.hotel = await this.loadHotel(this.basket.params.hotelCode));
    this.isCustomerInfoEditable = !hotel.Settings.UseLogin || !this.customer.isKnownCustomer;
    this.hasAddons = this.basket.bookings.reduce(
      (has, booking) => has || !!(booking.AddOns && booking.AddOns.length > 0),
      false as boolean,
    );
    this.hasRooms = this.basket.bookings[0].RoomName !== '';
    this.hasSeatings = this.basket.bookings[0].Seatings !== undefined;

    this.hasTableReservation = this.basket.bookings[0].TableSeatings !== undefined;
    this.hasGiftCard = this.basket.bookings[0].Giftcards !== undefined;

    if (this.hasGiftCard) {
      this.repeatEmailvalid = false;
    }

    if (this.hasSeatings && !this.hasRooms) {
      this.applicationState.ReturnFromOnlySeating = 'true';
      this.basket.params.rooms = [];
    }
    this.showGuestCountry = (await this.config.getCustomerParams()).ShowGuestCountry;

    this.information$ = combineLatest([
      this.localeService.locale$.pipe(
        tap((locale) => {
          if (!this.customer.isKnownCustomer) {
            this.defaultCountryCode = locale.substring(3, 5);
          }
        }),
      ),
      this.promotionService.promocode$,
      this.customerService.customer$.pipe(
        tap((customer) => {
          this.customer = {
            isKnownCustomer: !!customer.Id,
            info: { ...customer.Info },
          };
          this.isCustomerInfoEditable = !hotel.Settings.UseLogin || !this.customer.isKnownCustomer;
        }),
        map((customer) => customer.Id || undefined),
        distinctUntilChanged(),
      ),
    ]).pipe(
      switchMap(([locale, promocode]) => {
        this.promocode = promocode;
        return from(this.calculatePrices(locale, basket));
      }),
    );

    if (this.hotel && this.hotel.Settings.DefaultCountryField) {
      if (!this.customer.isKnownCustomer) {
        this.customer.info.Country = '';
      }
    } else {
      if (!this.customer.isKnownCustomer) {
        this.customer.info.Country = (await this.localeService.getLocale()).substring(3, 5);
      }
    }

    const seats = this.basket.bookings[0].Seatings;
    if (seats) {
      for (const item of seats) {
        const midDate = DateHelper.FromUtcDate(item.Date.toString() as UTCDateString);
        const month = DateHelper.getTranslatedMonth(midDate.getMonth());
        item.day = midDate.getDate().toString();
        item.month = month;
        item.year = midDate.getFullYear().toString();
        item.Time = DateHelper.getTimeFromUtcDate(item.Date.toString() as UTCDateTimeString);
      }
    }

    const tempLogo = this.applicationState.ApplicationLogo;

    if (tempLogo) {
      this.logo = tempLogo;
    }

    this.contentDialogHeader = this.applicationState.ApplicationColor;

    this.applicationState.RemoveCheckoutButton = true;
  }

  ngOnDestroy(): void {
    this.applicationState.RemoveCheckoutButton = false;
  }

  async onFormSubmit(f: NgForm, info: CustomerInfo, information: BookingInformation) {
    try {
      if (this.calculatedCancellationFee && this.useCancelationInsurance) {
        information.CancelationInsurance = this.calculatedCancellationFee;
      }
      let isValid = true;
      Object.keys(f.form.controls).forEach((key) => {
        const control = f.form.get(key);
        if (control) {
          if (!control.valid && !control.disabled) {
            control.markAsTouched();
            isValid = false;
          }
        }
      });
      if (isValid) {
        if (!this.isSubmitting) {
          const basket = this.getBasket();
          if (!this.customer.isKnownCustomer) {
            this.customerService.updateInfo(info);
          }
          this.basketService.set({ ...basket });
          this.isSubmitting = true;
          await this.loader.using(
            async () => {
              try {
                const isGiftcardReservation = basket.bookings[0].Giftcards && basket.bookings[0].Giftcards.length !== 0;

                if (isGiftcardReservation) {
                  const response = await this.bookingService.createGiftcardReservation(
                    information.Bookings[0].GiftCard,
                    info,
                  );

                  if (response && this.applicationState.UseNetAxept) {
                    this.applicationState.GiftcardPayment = response as string;
                    this.applicationState.Origin = OriginEnum.GiftCard;
                    await this.paymentService.startupPayment();
                    return;
                  } else if (response && this.applicationState.UseNetsEasy) {
                    this.applicationState.Origin = OriginEnum.GiftCard;
                    await this.paymentService.startupPayment();
                    return;
                  } else {
                    throw new Error(`Payment could not be created`);
                  }
                }

                const locale = await this.localeService.getLocale();
                const hotel = await this.hotelService.GetECommerceDepartmentInformation(
                  basket.params.hotelCode,
                  locale,
                );

                let giftcardPayment = 0;
                if (this.giftcardInformation) {
                  giftcardPayment = this.giftcardInformation.Amount;
                }
                let reservation = await this.bookingService.createReservation(
                  locale,
                  hotel,
                  this.promocode,
                  { ...this.customerService.getCustomer(), Info: info },
                  information,
                  this.needSubscribe,
                  this.useCancelationInsurance,
                  this.comment,
                  this.customer.isKnownCustomer,
                  giftcardPayment,
                );
                await this.trackingService.ecommerceBeginCheckoutFromReservation(reservation);
                if (this.giftcardInformation) {
                  reservation = this.subtractGiftcard(reservation);
                }
                if (
                  reservation &&
                  reservation.payment.isPaymentEnabled &&
                  reservation.PaymentFlowToUse !== PaymentFlowEnum.NoPayment &&
                  reservation.PaymentDue > 0
                ) {
                  this.bookingService.bookingInformation.set(information);
                  if (this.giftcardInformation) {
                    await this.paymentService.startupPayment(this.giftcardInformation.Number);
                  } else {
                    // this will not send a notification, but prepare the notification when payment completes
                    // this is so Spectra.API can send the notification if callback is not called
                    await this.bookingService.sendNotification(information, locale, true, true);
                    await this.paymentService.startupPayment();
                  }
                } else {

                  if (this.giftcardInformation) {
                    await this.payAmountWithGiftcard();
                  }
                  await this.bookingService.sendNotification(information, locale);

                  await this.bookingService.completePayment(reservation);
                  this.basketService.clear();
                  this.basketService.basketBadgeNumberSet = '0';
                  await this.navigationHelper.continue('/customer', '/reciept');
                }
              } finally {
                this.isSubmitting = false;
              }
            },
            'LOA_CreatingReservation',
            true,
          );
        } else {
          this.log.warn(`Form submitting was prevented as CreateReservation request is not completed yet.`);
        }
      }
    } catch (e: any) {
      this.log.error('Error when creating reservation', e);
      this.navigationHelper.reset();
      this.navigationHelper.navigateToPage('/payment-failed');
    }
  }

  subtractGiftcard(reservation: ReservationType) {
    if (this.giftcardInformation && this.giftcardInformation.Amount > 0) {
      let amount;
      if (this.giftcardInformation.Amount < reservation.PaymentDue) {
        amount = reservation.PaymentDue - this.giftcardInformation.Amount;
        reservation.paidWithGiftcard = this.giftcardInformation.Amount;
      } else {
        amount = 0;
        reservation.paidWithGiftcard = reservation.PaymentDue;
      }

      reservation.PaymentDue = amount;
      this.bookingService.updateSessionReservation(reservation);
      const id = Number(reservation.reservationId);
      const model = {
        Amount: reservation.paidWithGiftcard,
        GiftCardNumber: this.giftcardInformation.Number,
        ReservationNumber: id,
      } as GiftcardReservationPayment;
      this.bookingService.updateSessionGiftcard(model);
    }

    return reservation;
  }

  async payAmountWithGiftcard() {
    const reservation = this.bookingService.getReservationOrFail();

    if (this.giftcardInformation && reservation) {
      const id = Number(reservation.reservationId);

      const model = {
        Amount: reservation.paidWithGiftcard,
        GiftCardNumber: this.giftcardInformation.Number,
        ReservationNumber: id,
        resGuid: reservation.resGuid,
        currency: reservation.payment.currency,
      } as GiftcardReservationPayment;
      return await this.hotelService.payAmountWithGiftcard(model);
    }

    return undefined;
  }

  onUseCancelationInsuranceChange(useCancelationInsurance: boolean) {
    this.useCancelationInsurance = useCancelationInsurance;
  }

  onNeedSubscribeChange(needSubscribe: boolean) {
    this.needSubscribe = needSubscribe;
  }

  onAcceptTermsChange(acceptTerms: boolean) {
    this.acceptTerms = acceptTerms;
  }

  onCommentChange(comment: string) {
    this.comment = comment;
  }

  onRepeatEmailChange(email: string) {
    if (this.customer.info.Email === email) {
      this.repeatEmailvalid = true;
    } else {
      this.repeatEmailvalid = false;
    }
  }

  onShowTermsClick(terms: string, e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.messageHotelTermsTempalte) {
      throw new Error(`messageHotelTermsTempalte must be defined`);
    }
    this.applicationState.stopScrolling(true);
    const message = this.messenger.show(
      this.messageHotelTermsTempalte,
      {
        html: terms,
        accept: () => {
          this.applicationState.stopScrolling(false);
          this.acceptTerms = true;
          message.data.close();
        },
        close: () => {
          this.applicationState.stopScrolling(false);
          this.messenger.close(message);
        },
      },
      () => {
        this.applicationState.stopScrolling(false);
        message.data.close();
      },
    );
  }

  async getGiftcardInformation(code: string) {
    const res = await this.bookingService.getGiftcardInformation(code);

    return res;
  }

  async goBack() {
    await this.navigationHelper.goBack();
  }

  async setGiftcardInformationModel(giftcardInformation: GiftcardInformationModel) {
    this.giftcardInformation = giftcardInformation;
  }

  private getBasket() {
    if (!this.basket) {
      throw new Error('Basket must exist');
    }
    return this.basket;
  }

  private async getBasketOrFail() {
    try {
      const basket = this.basketService.get();
      if (!basket) {
        const translation = await LocaleService.LoadTranslations(await this.localeService.getLocale());
        throw new Error(translation.EXC_EmptyBasket);
      }
      if (basket.bookings.length === 0) {
        throw new Error(`Basket must have at least one room`);
      }
      return basket;
    } catch (err) {
      await this.navigationHelper.continue('/customer', '/search');
      throw err;
    }
  }

  private async calculatePrices(locale: Locale, basket: Basket) {
    return await this.loader.using(async () => {
      const bookings = await Promise.all(
        basket.bookings.map(async (booking, bookingIndex) => {
          const rate = {} as Rate;
          if (booking.Price && booking.OriginalPrice) {
            rate.Price = booking.Price;
            rate.OriginalPrice = booking.OriginalPrice;
          }
          const addOns: BookingExtraServiceModel[] = booking.AddOns ? [...booking.AddOns] : [];

          const guest: CustomerInfo = {
            Address1: '',
            Address2: '',
            City: '',
            Country: '',
            Email: '',
            FirstName: '',
            LastName: '',
            Phone: '',
            ZipCode: '',
          };

          const seatings: BookingInformationSeating[] = [];

          if (booking.Seatings) {
            for (const item of booking.Seatings) {
              const seating: BookingInformationSeating = {
                Count: item.TicketTotal,
                Name: item.Name,
                PricePerPiece: item.SinglePrice,
                TotalPrice: item.TicketTotal !== undefined ? item.TicketTotal * item.SinglePrice : 0,
                Date: item.Date,
                SeatCode: item.SeatCode,
                Time: item.Time !== null ? item.Time : '',
                SeatRate: item.SeatRate,
                SeatGuest: item.SeatGuest,
                SeatingPlanName: item.SeatingPlanName,
              };

              seatings.push(seating);
            }
          }

          if (booking.TableSeatings) {
            for (const item of booking.TableSeatings) {
              const table: BookingInformationSeating = {
                Count: item.SeatGuest[0].Count,
                Name: item.Name,
                PricePerPiece: item.SeatGuest[0].Price,
                TotalPrice: item.Total,
                Date: item.Date,
                SeatCode: item.SeatCode,
                Time: item.Time !== null ? item.Time : '',
                SeatRate: item.SeatRate,
                SeatGuest: item.SeatGuest,
                SeatingPlanName: item.SeatingPlanName,
              };

              seatings.push(table);
            }
          }

          const giftcards: BookingInformationGiftcard[] = [];

          if (booking.Giftcards) {
            for (const item of booking.Giftcards) {
              const giftCard: BookingInformationGiftcard = {
                Count: item.Count,
                Name: item.Name,
                PricePerPiece: item.Price,
                TotalPrice: item.TotalPrice,
                Id: item.Id,
                GuestInformation: item.GuestInformation,
              };
              giftcards.push(giftCard);
            }
          }
          const b: BookingInformationBooking = {
            RoomName: booking.RoomName,
            RateName: booking.RateName,
            RoomCode: booking.RoomCode,
            RateCode: booking.RateCode,
            RateDescription: booking.RateDescription,
            HasPromotionForCustomer: rate !== undefined ? rate.HasPromotionForCustomer : false,
            Price: rate ? rate.Price : 0,
            Persons: basket.params.rooms[bookingIndex],
            Guest: guest,
            OriginalPrice: rate ? rate.OriginalPrice : 0,
            AddOns: addOns,
            Seating: booking.Seatings || booking.TableSeatings ? seatings : undefined,
            GiftCard: booking.Giftcards ? giftcards : undefined,
          };
          return b;
        }),
      );
      const hotel = await this.hotelService.GetECommerceDepartmentInformation(basket.params.hotelCode, locale);

      const cancellationPolicyFee = Math.max(
        (this.cancellationprotection ? this.cancellationprotection.Percentage / 100 : 0) *
          this.reservationService.GetTotal(bookings, hotel.CancellationPolicyServicesIncluded),
        this.cancellationprotection ? this.cancellationprotection.MinimumAmount : 0 * bookings.length,
      );
      const information: BookingInformation = {
        Bookings: bookings,
        HotelCode: basket.params.hotelCode,
        Arrival: DateHelper.toServerDateFormatString(basket.params.arrival) as UTCDateString,
        Stay: basket.params.stay,
        CancelationInsurance: hotel.UseCancelPolicy ? cancellationPolicyFee : null,
        Total: this.reservationService.GetTotal(bookings),
      };
      return information;
    }, `LOA_Rates`);
  }

  private async loadHotel(hotelCode: HotelCodeString) {
    this.log.debug(`CustomerRoute loadHotel(${hotelCode})`);
    return await this.loader.using(async () => {
      return await this.hotelService.GetECommerceDepartmentInformation(hotelCode, await this.localeService.getLocale());
    }, `LOA_HotelInformation`);
  }

  private async loadCountries() {
    try {
      return await this.loader.using(async () => {
        return await this.countryService.getCountries();
      }, `LOA_Countries`);
    } catch (err) {
      return [];
    }
  }

  private async getCancellationprotection() {
    const res = await this.hotelService.GetCancellationProtection(this.applicationState.CurrentHotelCode);
    if (res) {
      this.cancellationprotection = res;
    }
  }
}

interface HotelTermsMessage {
  html: string;
  accept: () => void;
  close: () => void;
}

interface GiftcardInformationModel {
  Amount: number;
  Name: string;
  Valid: boolean;
  Number: string;
}

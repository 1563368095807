import { AfterViewChecked, Component, ElementRef } from '@angular/core';
import { tap } from 'rxjs/operators';

import { Message, Messenger } from './messenger';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
})
export class MessengerComponent implements AfterViewChecked {
  private focus = false;

  messages$ = this.messenger.messages$.pipe(tap(() => (this.focus = true)));

  constructor(private messenger: Messenger, private el: ElementRef) {}

  ngAfterViewChecked() {
    if (this.focus) {
      this.focus = false;
      this.focusFirstMessage();
    }
  }

  onOverlayClick<T>(message: Message<T>) {
    if (message.onOverlayClick) {
      message.onOverlayClick();
    }
  }

  private focusFirstMessage() {
    const el = (this.el.nativeElement as HTMLDivElement).querySelector(
      '.message:last-child .default',
    ) as HTMLElement | null;
    if (el) {
      el.focus({
        preventScroll: true,
      });
    }
  }
}

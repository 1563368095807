<form *ngIf="!recovery" #f="ngForm" (ngSubmit)="onLoginFormSubmit(f)">
  <div class="card-login with-shadow">
    <div class="card-body">
      <h2>{{ 'LFO_Login' | t | async }}</h2>
      <input
        type="email"
        autocomplete="email"
        name="email"
        [(ngModel)]="form.email"
        #email="ngModel"
        [placeholder]="'LFO_Email' | t | async"
        required
        email />
      <small *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
        <div *ngIf="email?.errors.required">
          {{ 'LFO_EmailRequried' | t | async }}
        </div>
        <div *ngIf="email?.errors.email">
          {{ 'LFO_EmailInvalid' | t | async }}
        </div>
      </small>
      <input
        type="password"
        autocomplete="current-password"
        name="password"
        [(ngModel)]="form.password"
        #password="ngModel"
        [placeholder]="'LFO_Password' | t | async"
        required />
      <small *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
        <div *ngIf="password?.errors.required">
          {{ 'LFO_PasswordRequried' | t | async }}
        </div>
      </small>
      <p *ngIf="hasError" class="text-danger">{{ 'LFO_LoginError' | t | async }}<br /><br /></p>
      <button type="button" class="link text-right applicationColor" (click)="onRecoverClick()">
        {{ 'LFO_ForgotPassword' | t | async }}
      </button>
      <div class="text-center">
        <div class="d-inline-flex flex-column mw-100">
          <button type="submit" class="btn btn-primary with-shadow mb-0">
            {{ 'LFO_Login' | t | async }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<form *ngIf="recovery" #f="ngForm" (ngSubmit)="onRecoveryFormSubmit(f)">
  <div class="card">
    <div class="card-body">
      <h2 style="margin-top: 29px; font-size: 15px">
        {{ 'LFO_ForgotPassword' | t | async }}
      </h2>
      <input
        type="email"
        autocomplete="email"
        name="email"
        [(ngModel)]="form.email"
        #email="ngModel"
        [placeholder]="'LFO_Email' | t | async"
        required
        email />
      <small *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
        <div *ngIf="email?.errors.required">
          {{ 'LFO_EmailRequried' | t | async }}
        </div>
        <div *ngIf="email?.errors.email">
          {{ 'LFO_EmailInvalid' | t | async }}
        </div>
      </small>
      <p *ngIf="hasError" class="text-danger">{{ 'LFO_RecoveryError' | t | async }}<br /><br /></p>
      <button type="button" class="link text-right applicationColor" (click)="onBackToLoginClick()">
        {{ 'LFO_BackToLogin' | t | async }}
      </button>
      <div class="text-center">
        <div class="d-inline-flex flex-column mw-100">
          <button type="submit" class="btn btn-primary with-shadow mb-0">
            {{ 'LFO_Send' | t | async }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #messageSendTemplate let-message>
  <div class="dialog-container-soldout">
    <div class="dialog-header-soldout" style="color: white">
      <h1>{{ 'COM_Info' | t | async }}</h1>
    </div>
    <div class="dialog-body-soldout">
      <p class="section">{{ 'LFO_MessageSend' | t | async }}</p>
    </div>
    <div class="text-center dialog-footer-soldout">
      <button class="default btn btn-secondary with-shadow m-0" type="button" (click)="message.close()">
        {{ 'COM_Close' | t | async }}
      </button>
    </div>
  </div>
</ng-template>

<div class="page-content fadeBody" *ngIf="calendarDates">
  <div class="container">
    <a class="desktop-show mobile-hide applicationColor" style="position: absolute; font-size: 20px" (click)="goBack()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >
    <a
      class="desktop-show mobile-hide applicationColor"
      *ngIf="guestCountArray.length === 0 || bookingFlow"
      (click)="onMoveToNextClick()"
      style="font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>

    <p class="desktop-hide mobile-show" style="text-align: center; font-size: 40px; font-weight: 500">
      {{ 'COM_TableReservation' | t | async }}
    </p>
    <p
      class="desktop-show mobile-hide"
      style="text-align: center; font-size: 40px; font-weight: 500"
      [ngStyle]="{
        'margin-left': bookingFlow === true || guestCountArray.length === 0 ? '9%' : '0'
      }">
      {{ 'COM_TableReservation' | t | async }}
    </p>

    <h2 style="text-align: center; margin-top: 20px" *ngIf="!bookingFlow">
      <span *ngIf="!status" class="v-middle"
        >{{ 'COM_TableChooseProduct' | t | async }} ({{ guestCount }} {{ 'COM_Guests' | t | async }})</span
      >
      <span *ngIf="status" style="color: red" class="v-middle"
        ><span [innerHTML]="'COM_TablePlaceGuests' | t | async"></span> {{ guestCountArray.length }}
        {{ 'COM_Guests' | t | async }}, {{ 'COM_TableChooseProducts' | t | async }}</span
      >
    </h2>

    <h2 style="text-align: center; margin-top: 20px" *ngIf="bookingFlow">
      <span class="v-middle">{{ 'COM_TableBookingFlowAddProduct' | t | async }}</span
      ><br />
      <span>{{ 'COM_TableIfNotPressContinue' | t | async }}</span>
    </h2>

    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 20px;
        margin-bottom: 20px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />

    <div class="split zoom-content-effect">
      <div
        [ngClass]="
          status ||
          (basket.bookings.length !== 0 &&
            basket.bookings[0].TableSeatings &&
            basket.bookings[0].TableSeatings.length !== 0)
            ? 'item-scale activeDate'
            : 'item'
        ">
        <div class="month">
          <ul>
            <li
              class="fas fa-arrow-left prev"
              style="margin-top: 15px; margin-right: 40px; cursor: pointer"
              (click)="prevMonth()"></li>
            <li
              class="fas fa-arrow-right next"
              style="margin-top: 15px; margin-left: 40px; cursor: pointer"
              (click)="nextMonth()"></li>
            <li *ngIf="calendarDates !== undefined">
              <p>{{ calendarDates.currentMonth | t | async }}</p>
              <span style="font-size: 18px">{{ calendarDates.currentYear }}</span>
            </li>
          </ul>
        </div>

        <ul class="weekdays">
          <li>{{ 'CAL_Mon' | t | async }}</li>
          <li>{{ 'CAL_Tirs' | t | async }}</li>
          <li>{{ 'CAL_Ons' | t | async }}</li>
          <li>{{ 'CAL_Tors' | t | async }}</li>
          <li>{{ 'CAL_Fre' | t | async }}</li>
          <li>{{ 'CAL_Lor' | t | async }}</li>
          <li>{{ 'CAL_Sun' | t | async }}</li>
        </ul>

        <ul class="days">
          <app-calendar-tile
            *ngFor="let item of calendarDates.days"
            (clickedDay)="clickedDate($event)"
            [date]="item"
            [componentRequestor]="'tableReservation'"></app-calendar-tile>
        </ul>
        <div style="margin-top: 25px; display: flex">
          <div style="width: 25px; height: 25px; background-color: #f87e7d; margin-right: 5px"></div>
          <p>{{ 'CAL_Occupied' | t | async }}</p>
          <div style="width: 25px; height: 25px; background-color: white; margin-left: 10px; margin-right: 5px"></div>
          <p>{{ 'CAL_Available' | t | async }}</p>
          <div style="width: 25px; height: 25px; background-color: #a9a9a9; margin-left: 10px; margin-right: 5px"></div>
          <p>{{ 'CAL_NotPossible' | t | async }}</p>
        </div>
      </div>

      <div id="cards" class="hide">
        <div *ngFor="let content of data" style="margin-bottom: 10vh">
          <app-expandable-media-list [usePageContainer]="false" [data]="content.data">
            <ng-template #itemTemplate let-room>
              <div
                class="card my-0 d-flex flex-column {{ room.item.selected ? 'active' : '' }} grow"
                (click)="onCardClick(room.item)">
                <ng-template #roomPriceFromTempalte>
                  {{ 'COM_Price' | t | async }}
                  <mark class="right">{{ room.item.tableDetails.price | price | async }}</mark>
                </ng-template>
                <div class="flex-grow-1">
                  <div class="card-image-Table">
                    <div class="centered-image-Table">
                      <img *ngIf="room.item.image" [src]="room.item.image" draggable="false" />
                      <div
                        *ngIf="!room.item.image"
                        style="
                          width: 100%;
                          background-color: gray;
                          height: 100%;
                          text-align: center;
                          line-height: 220px;
                        ">
                        <div
                          style="
                            font-size: 100px;
                            display: inline-block;
                            vertical-align: middle;
                            line-height: normal;
                            margin-right: 70px;
                          ">
                          <span class="fa fa-camera" style="font-size: 100px; color: #999999"></span>
                          <h2 style="margin-left: 70px; color: #999999">
                            {{ 'RPA_Image' | t | async }}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="overel-container">
                      <div class="overel overel-right">
                        <button
                          *ngIf="!room.item.selected"
                          style="pointer-events: none"
                          type="button"
                          class="btn btn-primary btn-mini">
                          {{ 'COM_AddToBasket' | t | async }}
                        </button>
                        <button
                          *ngIf="room.item.selected"
                          style="pointer-events: none"
                          type="button"
                          class="btn btn-success btn-mini">
                          {{ 'COM_AddedToBasket' | t | async }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-header">
                    <h3 class="text-primary">{{ room.item.name }}</h3>
                  </div>
                </div>
                <hr style="width: 90%; margin-left: 5%; margin-bottom: 20px; border-width: 2px" />
                <div class="card-body">
                  <h3>
                    <ng-container [ngTemplateOutlet]="roomPriceFromTempalte"></ng-container>
                  </h3>
                </div>
              </div>
            </ng-template>
          </app-expandable-media-list>
        </div>
      </div>
    </div>
  </div>
  <div class="container text-center" *ngIf="bookingFlow" style="margin-bottom: 100px">
    <div class="section">
      <button type="button" class="btn btn-primary with-shadow" (click)="onMoveToNextClick()">
        {{ 'COM_Continue' | t | async }}
      </button>
    </div>
  </div>
</div>

<ng-template #messageHotelTermsTempalte let-message>
  <div class="dialog-container-table">
    <div class="dialog-header-table">
      {{ 'SEA_Time' | t | async }}
    </div>
    <div class="dialog-table">
      <div class="dialog-body-table">
        <p>{{ 'SEA_Time' | t | async }}</p>
        <div class="with-toggle focusable">
          <i class="toggle fas fa-caret-down"></i>
          <select [(ngModel)]="message.guestArrival">
            <option *ngFor="let item of message.time">{{ item.clock }}</option>
          </select>
        </div>
        <p>{{ 'COM_TableNumberOfGuestsForProduct' | t | async }}</p>
        <div class="with-toggle focusable">
          <i class="toggle fas fa-caret-down"></i>
          <select [(ngModel)]="message.guestCount">
            <option *ngFor="let count of guestCountArray">{{ count }}</option>
          </select>
        </div>
      </div>
      <div class="text-center dialog-footer-table">
        <button
          style="min-width: 150px"
          class="default btn btn-primary with-shadow mb-0"
          type="button"
          (click)="message.close()">
          {{ 'PAR_Cancel' | t | async }}
        </button>
        <button
          style="min-width: 150px; margin-left: 5px"
          class="default btn btn-primary with-shadow mb-0"
          type="button"
          (click)="message.accept(message)">
          {{ 'COM_Continue' | t | async }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div class="container" *ngIf="applicationState.DesignPackage === 'Default'">
  <div *ngIf="logo" class="mobileLogo mobileLogo-remove">
    <img [src]="logo" />
  </div>
  <div class="section1">
    <div class="section-center">
      <div class="container">
        <div class="row">
          <div class="booking-form">
            <div class="booking-bg mobile-hide">
              <div class="form-header">
                <div *ngIf="hotel">
                  <h2 style="color: var(--color-dynamic); line-height: 29px">
                    {{ hotel.Name }}
                  </h2>
                  <p [innerHTML]="hotel.Description"></p>
                </div>
              </div>
            </div>
            <form (submit)="onFormSubmit($event)">
              <div class="form-group" *ngIf="hotels.length > 1 && !single">
                <h3 class="applicationColor">
                  <i class="fas fa-hotel"></i>
                  <span class="v-middle">{{ applicationState.HotelLabel | t | async }}</span>
                </h3>
                <app-dropdown
                  class="applicationColor"
                  [data]="hotels"
                  [value]="hotel"
                  (changed)="onHotelChange($event.Code)">
                  <ng-template #valueTemplate let-item>
                    <div class="with-toggle" [ngClass]="{ active: item.opened }">
                      <div class="form-control">
                        {{ item.value && item.value.Name }}
                      </div>
                      <i class="toggle fas fa-caret-down"></i>
                    </div>
                  </ng-template>
                  <ng-template #itemTemplate let-item>
                    {{ item.value.Name }}
                  </ng-template>
                </app-dropdown>
                <span class="select-arrow"></span>
              </div>
              <h2>
                <i class="fas fa-calendar-alt"></i>
                <span class="v-middle">{{ 'SFO_ParamsHeader' | t | async }}</span>
              </h2>
              <div class="row">
                <!--Desktop/laptop calendar-->
                <div class="hide-m hide-s" *ngIf="available.length !== 0">
                  <div class="col s6 m6 l6 arrival-calendar applicationColor">
                    <app-calendar-input
                      [config]="{
                        showMonths: 1,
                        placeholder: 'COM_Arrival' | t | async,
                        min: firstAvailableDate,
                        max: lastAvailableDate,
                        start: arrivalNotSpecified === true ? null : arrival,
                        end: arrivalNotSpecified === true ? null : departure,
                        value: arrivalNotSpecified === true ? null : arrival,
                        available: available,
                        stay: arrivalNotSpecified === true ? null : stay
                      }"
                      [isArrivalDate]="isArrivalDate"
                      [loadingdData]="loadingdData"
                      (dateChange)="onArrivalChange($event)"
                      (monthChange)="monthChange($event)">
                    </app-calendar-input>
                  </div>
                  <div class="col l6 s6 m6 applicationColor">
                    <app-calendar-input
                      #departureCalendar
                      [config]="{
                        showMonths: 2,
                        placeholder: 'COM_Departure' | t | async,
                        min: arrival,
                        max: lastAvailableDate,
                        start: arrivalNotSpecified === true ? null : arrival,
                        end: arrivalNotSpecified === true ? null : applicationState.DepartureDate,
                        value: arrivalNotSpecified === true ? null : applicationState.DepartureDate,
                        available: available,
                        stay: arrivalNotSpecified === true ? null : stay
                      }"
                      [isArrivalDate]="isArrivalDate"
                      [loadingdData]="loadingdData"
                      (dateChange)="onDepartureChange($event)"
                      (monthChange)="monthChange($event)">
                    </app-calendar-input>
                  </div>
                </div>
                <!--Mobile calendar-->
                <div class="hide-l" *ngIf="available.length !== 0">
                  <div class="col s12 arrival-calendar applicationColor">
                    <app-calendar-input
                      [config]="{
                        full: true,
                        placeholder: 'COM_Arrival' | t | async,
                        min: firstAvailableDate,
                        max: lastAvailableDate,
                        start: arrivalNotSpecified === true ? null : arrival,
                        end: arrivalNotSpecified === true ? null : departure,
                        value: arrivalNotSpecified === true ? null : arrival,
                        available: available,
                        stay: arrivalNotSpecified === true ? null : stay
                      }"
                      [isArrivalDate]="isArrivalDate"
                      [loadingdData]="loadingdData"
                      (dateChange)="onArrivalChange($event)"
                      (monthChange)="monthChange($event)">
                    </app-calendar-input>
                  </div>
                  <div class="col s12 applicationColor">
                    <app-calendar-input
                      #departureCalendarMobile
                      [config]="{
                        showMonths: 2,
                        placeholder: 'COM_Departure' | t | async,
                        min: arrival,
                        max: lastAvailableDate,
                        start: arrivalNotSpecified === true ? null : arrival,
                        end: arrivalNotSpecified === true ? null : applicationState.DepartureDate,
                        value: arrivalNotSpecified === true ? null : applicationState.DepartureDate,
                        available: available,
                        stay: arrivalNotSpecified === true ? null : stay
                      }"
                      [isArrivalDate]="isArrivalDate"
                      [loadingdData]="loadingdData"
                      (dateChange)="onDepartureChange($event)"
                      (monthChange)="monthChange($event)">
                    </app-calendar-input>
                  </div>
                </div>
                <div class="col s12" *ngIf="showMessage">
                  <p style="color: red; font-size: 12px; font-style: italic">
                    {{ 'SFO_NoRooms' | t | async }}
                  </p>
                </div>
              </div>
              <ng-template ngFor let-room [ngForOf]="rooms" let-index="index" let-isFirst="first">
                <hr *ngIf="!isFirst" />
                <div class="subsection row has-absolute-children" [ngClass]="{ borderAroundContent: rooms.length > 1 }">
                  <div class="col s12" style="margin-bottom: 15px">
                    <h2>
                      <i class="fas fa-users"></i>
                      <span style="margin-left: 5px" class="v-middle"
                        >{{ applicationState.GuestInRoomLabel | t | async }}
                        <mark *ngIf="rooms.length > 1">{{ index | char }}</mark></span
                      >
                    </h2>
                  </div>
                  <div class="col l12 s12 applicationColor">
                    <div class="row hide-l">
                      <div
                        class="col s9"
                        [ngClass]="{
                          s12: rooms.length <= 1,
                          s9: rooms.length > 1
                        }">
                        <div class="with-toggle" [ngClass]="{ active: index === mobile.currentIndex }">
                          <i class="toggle fas fa-caret-down"></i>
                          <input
                            type="text"
                            readonly="readonly"
                            [value]="room | guests | async"
                            (click)="mobile.currentIndex = index === mobile.currentIndex ? undefined : index" />
                        </div>
                      </div>
                      <div *ngIf="rooms.length > 1" class="col pl-0 right text-right">
                        <button class="btn btn-danger btn-round" type="button" (click)="onRemovePersonClick(room)">
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        *ngFor="let guestType of hotel?.Aqcs"
                        class="col m12 s12 l3"
                        [ngClass]="{
                          'hide-m hide-s': mobile.currentIndex !== index
                        }">
                        <div
                          *ngIf="{
                            countValues: guestType.AQC === adultGuestType ? adultCountValues : guestCountValues,
                            count: room.get(guestType.AQC)
                          } as data">
                          <div class="hide-m hide-s applicationColor">
                            <div *ngIf="hotel?.Aqcs.length > 1">
                              <label class="text-secondary hide-s" style="color: var(--primary-application-color)">
                                {{ guestType.Label || ('COM_Guest_' + guestType.AQC | t | async) }}
                              </label>
                              <p class="applicationColor" style="font-size: 9px; margin-top: -15px">
                                ({{ guestType.From }}<span *ngIf="guestType.AQC !== 10">-{{ guestType.To }}</span>
                                <span *ngIf="guestType.AQC === 10">+</span>
                                {{ 'APA_Year' | t | async }})
                              </p>
                            </div>
                            <div *ngIf="hotel?.Aqcs.length === 1">
                              <label class="text-secondary hide-s" style="color: var(--primary-application-color)">
                                {{ 'COM_Persons' | t | async }}
                              </label>
                            </div>
                            <div class="with-toggle" style="width: 90px">
                              <i class="toggle fas fa-caret-down"></i>
                              <select
                                (change)="
                                  onGuestQuantityChange(index, guestType.AQC, +$event.target.value, data.countValues)
                                "
                                [value]="data.count"
                                style="width: 100%">
                                <option
                                  *ngFor="let count of data.countValues"
                                  [value]="count"
                                  [selected]="count === room.get(guestType.AQC)">
                                  {{ count }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="hide-l row">
                            <div class="col">
                              <label class="form-text text-secondary applicationColor">
                                {{ guestType.Label || ('COM_Guest_' + guestType.AQC | t | async) }}
                              </label>
                              <p style="font-size: 11px; margin-top: -15px" class="applicationColor">
                                ({{ guestType.From }}<span *ngIf="guestType.AQC !== 10">-{{ guestType.To }}</span>
                                <span *ngIf="guestType.AQC === 10">+</span>
                                {{ 'APA_Year' | t | async }})
                              </p>
                            </div>
                            <div class="col m4 s6 right">
                              <div
                                class="row m-0"
                                *ngIf="{
                                  less: data.countValues.includes(data.count - 1) ? data.count - 1 : undefined,
                                  more: data.countValues.includes(data.count + 1) ? data.count + 1 : undefined
                                } as countData">
                                <div class="col s4 p-0 text-left">
                                  <button
                                    type="button"
                                    class="btn btn-primary-outline btn-round"
                                    (click)="
                                      onGuestQuantityChange(index, guestType.AQC, countData.less, data.countValues)
                                    "
                                    [disabled]="countData.less === undefined">
                                    <i class="fas fa-minus"></i>
                                  </button>
                                </div>
                                <div class="col s4 p-0 text-center">
                                  <div class="form-text">{{ data.count }}</div>
                                </div>
                                <div class="col s4 p-0 text-right">
                                  <button
                                    type="button"
                                    class="btn btn-primary-outline btn-round"
                                    (click)="
                                      onGuestQuantityChange(index, guestType.AQC, countData.more, data.countValues)
                                    "
                                    [disabled]="countData.more === undefined">
                                    <i class="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="rooms.length > 1" class="outside-absolute-right hide-m hide-s">
                    <label>&nbsp;</label>
                    <button class="btn btn-danger btn-round m-0" type="button" (click)="onRemovePersonClick(room)">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </ng-template>
              <div class="form-btn">
                <button
                  *ngIf="!maxRoomsReached"
                  type="button"
                  class="btn btn-primary-outline with-shadow"
                  (click)="onAddRoomClick()">
                  {{ applicationState.MoreRoomLabel | t | async }}
                </button>
                <button
                  type="submit"
                  style="margin-left: 20px"
                  class="btn btn-primary with-shadow"
                  id="stayBooking"
                  [disabled]="!continue">
                  {{ 'COM_Continue' | t | async }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="applicationState.DesignPackage === 'Minimalistic'">
  <div *ngIf="logo" class="mobileLogo mobileLogo-remove">
    <img [src]="logo" />
  </div>
  <div class="section1">
    <div class="section-center">
      <div class="row">
        <div class="booking-form-design2">
          <form (submit)="onFormSubmit($event)">
            <div class="form-group" *ngIf="hotels.length > 1 && !single">
              <h3 class="applicationColor">
                <i class="fas fa-hotel"></i>
                <span class="v-middle">{{ applicationState.HotelLabel | t | async }}</span>
              </h3>
              <app-dropdown
                class="applicationColor"
                [data]="hotels"
                [value]="hotel"
                (changed)="onHotelChange($event.Code)">
                <ng-template #valueTemplate let-item>
                  <div class="with-toggle" [ngClass]="{ active: item.opened }">
                    <div class="form-control">
                      {{ item.value && item.value.Name }}
                    </div>
                    <i class="toggle fas fa-caret-down"></i>
                  </div>
                </ng-template>
                <ng-template #itemTemplate let-item>
                  {{ item.value.Name }}
                </ng-template>
              </app-dropdown>
              <span class="select-arrow"></span>
            </div>
            <h2>
              <i class="fas fa-calendar-alt"></i>
              <span class="v-middle">{{ 'SFO_ParamsHeader' | t | async }}</span>
            </h2>
            <div class="row">
              <!--Desktop/laptop calendar-->
              <div class="hide-m hide-s" *ngIf="available.length !== 0">
                <div class="col s6 m6 l6 arrival-calendar applicationColor">
                  <app-calendar-input
                    [config]="{
                      showMonths: 1,
                      placeholder: 'COM_Arrival' | t | async,
                      min: firstAvailableDate,
                      max: lastAvailableDate,
                      start: arrivalNotSpecified === true ? null : arrival,
                      end: arrivalNotSpecified === true ? null : departure,
                      value: arrivalNotSpecified === true ? null : arrival,
                      available: available,
                      stay: arrivalNotSpecified === true ? null : stay
                    }"
                    [isArrivalDate]="isArrivalDate"
                    [loadingdData]="loadingdData"
                    (dateChange)="onArrivalChange($event)"
                    (monthChange)="monthChange($event)">
                  </app-calendar-input>
                </div>
                <div class="col l6 s6 m6 applicationColor">
                  <app-calendar-input
                    #departureCalendar
                    [config]="{
                      showMonths: 2,
                      placeholder: 'COM_Departure' | t | async,
                      min: arrival,
                      max: lastAvailableDate,
                      start: arrivalNotSpecified === true ? null : arrival,
                      end: arrivalNotSpecified === true ? null : applicationState.DepartureDate,
                      value: arrivalNotSpecified === true ? null : applicationState.DepartureDate,
                      available: available,
                      stay: arrivalNotSpecified === true ? null : stay
                    }"
                    [isArrivalDate]="isArrivalDate"
                    [loadingdData]="loadingdData"
                    (dateChange)="onDepartureChange($event)"
                    (monthChange)="monthChange($event)">
                  </app-calendar-input>
                </div>
              </div>

              <!--Mobile calendar-->
              <div class="hide-l" *ngIf="available.length !== 0">
                <div class="col s12 arrival-calendar applicationColor">
                  <app-calendar-input
                    [config]="{
                      full: true,
                      placeholder: 'COM_Arrival' | t | async,
                      min: firstAvailableDate,
                      max: lastAvailableDate,
                      start: arrivalNotSpecified === true ? null : arrival,
                      end: arrivalNotSpecified === true ? null : departure,
                      value: arrivalNotSpecified === true ? null : arrival,
                      available: available,
                      stay: arrivalNotSpecified === true ? null : stay
                    }"
                    [isArrivalDate]="isArrivalDate"
                    [loadingdData]="loadingdData"
                    (dateChange)="onArrivalChange($event)"
                    (monthChange)="monthChange($event)">
                  </app-calendar-input>
                </div>
                <div class="col s12 applicationColor">
                  <app-calendar-input
                    #departureCalendarMobile
                    [config]="{
                      showMonths: 2,
                      placeholder: 'COM_Departure' | t | async,
                      min: arrival,
                      max: lastAvailableDate,
                      start: arrivalNotSpecified === true ? null : arrival,
                      end: arrivalNotSpecified === true ? null : applicationState.DepartureDate,
                      value: arrivalNotSpecified === true ? null : applicationState.DepartureDate,
                      available: available,
                      stay: arrivalNotSpecified === true ? null : stay
                    }"
                    [isArrivalDate]="isArrivalDate"
                    [loadingdData]="loadingdData"
                    (dateChange)="onDepartureChange($event)"
                    (monthChange)="monthChange($event)">
                  </app-calendar-input>
                </div>
              </div>
              <div class="col s12" *ngIf="showMessage && !arrivalNotSpecified">
                <p *ngIf="messageType === 'minLOS'" style="color: red; font-size: 12px; font-style: italic">
                  {{ 'SFO_MinLOS' | t | async }} {{ restrictionDays }}
                  {{ 'SFO_Nights' | t | async }}
                </p>
                <p *ngIf="messageType === 'maxLOS'" style="color: red; font-size: 12px; font-style: italic">
                  {{ 'SFO_MaxLOS' | t | async }} {{ restrictionDays }}
                  {{ 'SFO_Nights' | t | async }}
                </p>
                <p
                  *ngIf="messageType === 'minLOS' || messageType === 'maxLOS'"
                  style="color: red; font-size: 12px; font-style: italic">
                  ({{ dateSpan }})
                </p>
                <p *ngIf="messageType === 'noneRooms'" style="color: red; font-size: 12px; font-style: italic">
                  {{ 'SFO_NoRooms' | t | async }}
                </p>
              </div>
            </div>
            <ng-template ngFor let-room [ngForOf]="rooms" let-index="index" let-isFirst="first">
              <hr *ngIf="!isFirst" />
              <div class="subsection row has-absolute-children" [ngClass]="{ borderAroundContent: rooms.length > 1 }">
                <div class="col s12" style="margin-bottom: 15px">
                  <h2>
                    <i class="fas fa-users"></i>
                    <span style="margin-left: 5px" class="v-middle"
                      >{{ applicationState.GuestInRoomLabel | t | async }}
                      <mark *ngIf="rooms.length > 1">{{ index | char }}</mark></span
                    >
                  </h2>
                </div>
                <div class="col l12 s12 applicationColor">
                  <div class="row hide-l">
                    <div
                      class="col s9"
                      [ngClass]="{
                        s12: rooms.length <= 1,
                        s9: rooms.length > 1
                      }">
                      <div class="with-toggle" [ngClass]="{ active: index === mobile.currentIndex }">
                        <i class="toggle fas fa-caret-down"></i>
                        <input
                          type="text"
                          readonly="readonly"
                          [value]="room | guests | async"
                          (click)="mobile.currentIndex = index === mobile.currentIndex ? undefined : index" />
                      </div>
                    </div>
                    <div *ngIf="rooms.length > 1" class="col pl-0 right text-right">
                      <button class="btn btn-danger btn-round" type="button" (click)="onRemovePersonClick(room)">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      *ngFor="let guestType of hotel?.Aqcs"
                      class="col m12 s12 l3"
                      [ngClass]="{
                        'hide-m hide-s': mobile.currentIndex !== index
                      }">
                      <div
                        *ngIf="{
                          countValues: guestType.AQC === adultGuestType ? adultCountValues : guestCountValues,
                          count: room.get(guestType.AQC)
                        } as data">
                        <div class="hide-m hide-s applicationColor">
                          <div *ngIf="hotel?.Aqcs.length > 1">
                            <label class="text-secondary hide-s" style="color: var(--primary-application-color)">
                              {{ guestType.Label || ('COM_Guest_' + guestType.AQC | t | async) }}
                            </label>
                            <p class="applicationColor" style="font-size: 9px; margin-top: -15px">
                              ({{ guestType.From }}<span *ngIf="guestType.AQC !== 10">-{{ guestType.To }}</span>
                              <span *ngIf="guestType.AQC === 10">+</span>
                              {{ 'APA_Year' | t | async }})
                            </p>
                          </div>
                          <div *ngIf="hotel?.Aqcs.length === 1">
                            <label class="text-secondary hide-s" style="color: var(--primary-application-color)">
                              {{ 'COM_Persons' | t | async }}
                            </label>
                          </div>
                          <div class="with-toggle" style="width: 87px">
                            <i class="toggle fas fa-caret-down"></i>
                            <select
                              (change)="
                                onGuestQuantityChange(index, guestType.AQC, +$event.target.value, data.countValues)
                              "
                              [value]="data.count"
                              style="width: 100%">
                              <option
                                *ngFor="let count of data.countValues"
                                [value]="count"
                                [selected]="count === room.get(guestType.AQC)">
                                {{ count }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="hide-l row">
                          <div class="col">
                            <label class="form-text text-secondary applicationColor">
                              {{ guestType.Label || ('COM_Guest_' + guestType.AQC | t | async) }}
                            </label>
                            <p style="font-size: 11px; margin-top: -15px" class="applicationColor">
                              ({{ guestType.From }}<span *ngIf="guestType.AQC !== 10">-{{ guestType.To }}</span>
                              <span *ngIf="guestType.AQC === 10">+</span>
                              {{ 'APA_Year' | t | async }})
                            </p>
                          </div>
                          <div class="col m4 s6 right">
                            <div
                              class="row m-0"
                              *ngIf="{
                                less: data.countValues.includes(data.count - 1) ? data.count - 1 : undefined,
                                more: data.countValues.includes(data.count + 1) ? data.count + 1 : undefined
                              } as countData">
                              <div class="col s4 p-0 text-left">
                                <button
                                  type="button"
                                  class="btn btn-primary-outline btn-round"
                                  (click)="
                                    onGuestQuantityChange(index, guestType.AQC, countData.less, data.countValues)
                                  "
                                  [disabled]="countData.less === undefined">
                                  <i class="fas fa-minus"></i>
                                </button>
                              </div>
                              <div class="col s4 p-0 text-center">
                                <div class="form-text">{{ data.count }}</div>
                              </div>
                              <div class="col s4 p-0 text-right">
                                <button
                                  type="button"
                                  class="btn btn-primary-outline btn-round"
                                  (click)="
                                    onGuestQuantityChange(index, guestType.AQC, countData.more, data.countValues)
                                  "
                                  [disabled]="countData.more === undefined">
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="rooms.length > 1" class="outside-absolute-right hide-m hide-s">
                  <label>&nbsp;</label>
                  <button class="btn btn-danger btn-round m-0" type="button" (click)="onRemovePersonClick(room)">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <div class="form-btn">
              <button
                *ngIf="!maxRoomsReached"
                type="button"
                class="btn btn-primary-outline with-shadow"
                (click)="onAddRoomClick()">
                {{ applicationState.MoreRoomLabel | t | async }}
              </button>
              <button
                type="submit"
                style="margin-left: 20px"
                class="btn btn-primary with-shadow"
                id="stayBooking"
                [disabled]="!continue">
                {{ 'COM_Continue' | t | async }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

import { AspDateString, AspDateWithTimeZoneString, DKDateString, GuestType, Guid, TimeString } from 'src/app/helpers';
import { HotelCodeString, PromoCodeString, RateCodeString, RoomCodeString } from '../../config';

export interface BookedRoom {
  room: RoomCodeString;
  rate: RateCodeString;
}

interface RoomGroup {
  ID: number;
  Name: string;
}

export interface SeatItem {
  ID: number;
  Name: string;
  Price: number;
  VareNr: string;
}

export interface Seat {
  ID: Guid;
  Name: string;
  Long: string;
  Short: string;
  Image: string;
  SortIndex: number;
  SeatItems: SeatItem[];
}

export enum WidgetPriceType {
  AvgPrice = 1,
  TotalPrice = 2,
}

export enum DibsPaymentStatusEnum {
  OnlineProduction = 1,
  OnlineTest = 2,
  Disabled = 3,
  OfflineTest = 4,
}

export interface HotelAqc {
  CategoryNumber: number;
  CategoryType: number;
  Name: string;
  Label: string;
  Description: string;
  AQC: GuestType;
  From: number;
  To: number;
  SortIndex: number;
}

interface HotelSettings {
  MerchantID: string | null;
  PriceType: WidgetPriceType;
  DibsCcFee: boolean;
  DibsPaymentStatus: DibsPaymentStatusEnum;
  VacantRoomSearchMaxMonth: number;
  BookMaxNights: number;
  BookMaxRooms: number;
  UseCalendar: boolean;
  UsePromotion: boolean;
  UseLogin: boolean;
  UseSeating: boolean;
  JustActivityBooking: boolean;
  IsGoogleHotelAdsEnabled: boolean;
  GoogleHotelAdsId: string;
  DefaultCountryField: boolean;
  CustomColor: string;
  CustomColorTextIcon: string;
  CustomPageTitle: string;
  ReceiptUrl: string;
  AnimatedBackground: boolean;
  MaxAQC: number;
  HotelLabel: number;
  RoomLabel: number;
  EventLabel: number;
  UIFonts: number;
  MandatoryEmail: boolean;
  MandatoryPhone: boolean;
  UseTableReservation: boolean;
  UseSpectraOnlineBooking: boolean;
  UseGiftcard: boolean;
  PayWithGiftcard: boolean;
  UseGiftcardFrom: boolean;
  UseGiftcardTo: boolean;
  UseGiftcardMessage: boolean;
  IncludeTableReservationInBookingFlow: boolean;
  LogoUrl: string;
  ShowNavbarLinks: boolean;
  IncludeSeatingsInBookingFlow: boolean;
  DesignPackage: string;
  UseConferenceModule: boolean;
  HeaderInjectText: string;
  SortIndexTableReservation: number;
  SortIndexSpectraOnlineBooking: number;
  SortIndexGiftCard: number;
  SortIndexSeating: number;
  UseSpectraOnlineBookingMenuPoint: boolean;
  UseSeatingMenuPoint: boolean;
  UseTableReservationMenuPoint: boolean;
  UseGiftcardMenuPoint: boolean;
  UseConferenceBookingMenuPoint: boolean;
}

export interface RoomType {
  Code: RoomCodeString;
  RatePlans: RateCodeString[];
  Name: string;
  Short: string;
  Long: string;
  Image: string;
  Image1: string;
  Image2: string;
  RoomTypeGroupID: number | null;
  MaxPers: number;
}

export interface ExtraGroup {
  Name: string;
  ExtraServices: ServiceType[];
}

export interface ServiceType {
  Id: string;
  ServiceId: string;
  Name: string;
  ShortDescription: string;
  LongDescription: string;
  Image: string;
}

export interface RatePlanType {
  Code: RateCodeString;
  ShortDescription: string;
  LongDescription: string | null;
  Url: string;
  Image: string;
  Image1: string;
  Image2: string;
  Status: string;
}

export interface HotelType {
  Code: HotelCodeString;
  Description: string;
  Image: string;
  Logo: string;
  Favicon: string;
  Name: string;
  VATNumber: string;
  PostalCode: string;
  Country: string;
  Address: string;
  Email: string;
  Phone: string;
  City: string;
  Terms: string;
  RoomTypeGroups: RoomGroup[];
  Rooms: RoomType[];
  IsHotelDepartment: boolean;
  UseCancelPolicy: boolean;
  UseExtraServices: boolean;
  CancelPolicyPercentage: number;
  CancellationPolicyAmount: number;
  ExtraServices: ExtraGroup[];
  CancellationPolicyServicesIncluded: boolean;
  Seats: Seat[];
  Settings: HotelSettings;
  Rates: RatePlanType[];
  Aqcs: HotelAqc[];
}

export interface HotelRegion {
  Hotels: HotelType[];
  Code: string;
}

export interface GetWidgetDataResponse {
  GetWidgetDataResult: {
    HotelRegions: HotelRegion[];
  };
}

export interface PromotionStatus {
  Status: string;
  Start: AspDateWithTimeZoneString | null;
  End: AspDateWithTimeZoneString | null;
}

interface PromotionType {
  Code: PromoCodeString;
  Discount: number;
  Image: string | null;
  LongDescription: string | null;
  ShortDescription: string | null;
}

interface CustomerPromotionType {
  CustomerId: number;
  TargetCustomerId: number;
  Status: string;
  Discount: number;
}

export interface RatePlanDataType {
  PromotionStatus: PromotionStatus | null;
  Code: RateCodeString | null;
  Promotion: PromotionType | null;
  CustomerPromotion: CustomerPromotionType | null;
}

export interface GetHotelStayAvailableRatesResult {
  EndDate: Date;
  Rates: RatesRoom[];
  StartDate: Date;
}

export interface RatesRoom {
  Key: string;
  Value: RateType[];
}

export interface RateType {
  Rate: number;
  RoomCode: string;
  RoomStayAvailabilityCount: number;
}

export interface ArrivalRoomType {
  Price: string | null;
  OriginalPrice: string | null;
  Discount: string | null;
  DiscountPrice: string | null;
  DiscountPercent: string | null;
  ArrivalDate: AspDateString;
  Quantity: number;
  RateTier: number | null;
  MinStay: number;
  MaxStay: number;
  Status: string;
  MinPers: number;
  MaxPers: number;
}

export interface SeatingsDataType {
  Details: string | null;
  Status: number | 0;
  StatusName: string | undefined;
  Data: SeatingData[];
}

export interface SeatingData {
  ItemNumber: string;
  Rates: SeatingRate[];
  Characteristics: string;
  Description: string;
  AQC: AQC;
}

export interface SeatingRate {
  Arrival: Date;
  MaxPers: number;
}

export interface RoomDataType {
  Arrivals: ArrivalRoomType[] | null;
  Plan: RateCodeString;
  Code: RoomCodeString;
  IsDiscount: boolean;
  IsZeroDiscount: boolean;
  MaxStay: number;
  MinStay: number;
  CustomerPromotion: CustomerPromotionType;
  InvBlockCode: string;
}

interface ExtraServiceArrivalType {
  Price: string;
  ArrivalDateS: DKDateString;
  TimeFromS: TimeString | null;
  TimeToS: TimeString | null;
}

export interface ServicePriceType {
  Id: string;
  ServiceId: string;
  Arrivals: ExtraServiceArrivalType[];
  Price: string;
  Type: string;
  Count: number;
}

export interface AQC {
  Room: boolean;
  Adult: boolean;
  Child: boolean;
  Infant: boolean;
  Teenager: boolean;
  Senior: boolean;
}

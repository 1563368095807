import { Injectable } from '@angular/core';

import { AsyncSubject, lastValueFrom } from 'rxjs';
import { LogClient, LogEntry, LogLevel, LogObject } from './log-client.interface';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private static Instance = new AsyncSubject<LogService>();

  private clients: LogClient[] = [];
  constructor() {
    LogService.Instance.next(this);
    this.info(`Starting application at ${encodeURI(window.location.href)}`);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static Using(action: (logger: LogService) => void) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const logger = await lastValueFrom(await LogService.Instance);
      action(logger);
    })();
  }

  addClient(client: LogClient) {
    this.clients.push(client);
  }

  /** Used for the most detailed log messages, typically only valuable to a developer debugging an issue. */
  trace(msg: string, ...data: LogObject[]) {
    this.log(LogLevel.Trace, msg, data);
  }

  /** These messages have short-term usefulness during development. */
  debug(msg: string, ...data: LogObject[]) {
    this.log(LogLevel.Debug, msg, data);
  }

  /** These messages are used to track the general flow of the application. */
  info(msg: string, ...data: LogObject[]) {
    this.log(LogLevel.Information, msg, data);
  }

  /** he Warning level should be used for abnormal or unexpected events in the application flow. These may include errors or other conditions that do not cause the application to stop, but which may need to be investigated in the future. Handled exceptions are a common place to use the Warning log level. */
  warn(msg: string, ...data: LogObject[]) {
    this.log(LogLevel.Warning, msg, data);
  }

  /** An error should be logged when the current flow of the application must stop due to some failure, such as an exception that cannot be handled or recovered from. These messages should indicate a failure in the current activity or operation (such as the current HTTP request), not an application-wide failure. */
  error(msg: string, ...data: LogObject[]) {
    this.log(LogLevel.Error, msg, data);
  }

  /** A critical log level should be reserved for unrecoverable application or system crashes, or catastrophic failure that requires immediate attention. */
  fatal(msg: string, ...data: LogObject[]) {
    this.log(LogLevel.Critical, msg, data);
  }

  private log(level: LogLevel, msg: string, data: LogObject[]) {
    const entry: LogEntry = {
      date: new Date(),
      level,
      msg,
      data: JSON.parse(JSON.stringify(data)),
    }; // do full copy of data to prevent old values there.
    this.clients.forEach((client) => client.log(entry));
  }
}

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PaymentService } from './payment.service';
import { ReservationService } from './reservation.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [ReservationService, PaymentService],
  exports: [],
})
export class PaymentModule {
  constructor(@Optional() @SkipSelf() parentModule: PaymentModule) {
    if (parentModule) {
      throw new Error('PaymentModule is already loaded. Import it in the CoreModule only');
    }
  }
}

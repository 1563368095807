import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { ApplicationStateService } from '../..';
import { ConfigService } from '../config';
import { HotelService } from '../hotel';
import { ReservationType } from '../payment';
import { TrackingClient } from './tracking-client.interface';
import { GoogleTagManger } from './google-tag-manager-client.service';

@Injectable()
export class GoogleAnalyticsClientService implements TrackingClient {
  private googleAnalytics = this.initGoogleAnalytics();
  private page = '';

  constructor(
    private log: LogService,
    private configService: ConfigService,
    private hotelService: HotelService,
    private applicationstate: ApplicationStateService,
  ) {}

  async pageView(page: string) {
    await this.withGoogleAnalytics(() => {
      if (this.page !== page) {
        this.page = page;
        gtag('event', 'page_view', {
          page_location: this.page,
        });
        this.log.debug(`GoogleAnalyticsService pageView(${page})`);
      }
    });
  }

  async addToCart(category: string, entityType: string, name: string, count: number) {
    await this.withGoogleAnalytics(() => {
      gtag('event', 'add_to_cart', {
        currency: this.applicationstate.HotelCurrency,
        value: 0,
        items: [
          {
            item_name: name,
            quantity: count,
          },
        ],
      });
      this.log.debug(`GoogleAnalyticsService event(${category}, Add ${entityType}, ${name}, ${count})`);
    });
  }

  async removeFromCart(category: string, entityType: string, name: string, count: number) {
    await this.withGoogleAnalytics(() => {
      gtag('event', 'remove_from_cart', {
        currency: this.applicationstate.HotelCurrency,
        value: 0,
        items: [
          {
            item_name: name,
            quantity: count,
          },
        ],
      });
      this.log.debug(`GoogleAnalyticsService event(${category}, Remove ${entityType}, ${name}, ${count})`);
    });
  }

  async revenue(reservation: ReservationType) {
    if (reservation.rooms.length !== 0) {
      const hotelCode = reservation.rooms[0].hotelCode;

      await this.withGoogleAnalytics(async () => {
        await Promise.all(
          reservation.rooms.map(async (room) => {
            const { Name: roomName } = await this.hotelService.GetECommerceRoomInfo(
              room.hotelCode,
              room.roomCode,
              reservation.lang,
            );
            const { Code: rateName } = await this.hotelService.GetECommerceRateInfo(
              room.hotelCode,
              room.ratePlan,
              reservation.lang,
            );
            gtag('event', 'purchase', {
              currency: this.applicationstate.HotelCurrency,
              transaction_id: reservation.reservationId,
              items: [
                {
                  affiliation: hotelCode,
                  item_id: `${room.roomCode}-${room.ratePlan}`,
                  item_name: `${roomName}/${rateName}/${room.hotelCode}`,
                  item_category: 'Room',
                  price: room.price.toFixed(2),
                  quantity: 1,
                },
              ],
            });
          }),
        );
        this.log.debug('GoogleAnalyticsService revenue() ecommerce:send');

        await this.RevenueForExtraservices(reservation);
      });
    } else if (reservation.seats.length !== 0) {
      const hotelCode = this.applicationstate.CurrentHotelCode;

      await this.withGoogleAnalytics(async () => {
        gtag('event', 'purchase', {
          transaction_id: reservation.reservationId,
          items: [
            {
              affiliation: hotelCode,
              item_id: `${reservation.seats[0].seatrate}`,
              item_name: `${reservation.seats[0].seatCode}/${reservation.seats[0].seatrate}/${hotelCode}`,
              item_category: 'Seating',
              price: reservation.seats[0].total.toFixed(2),
              quantity: 1,
            },
          ],
        });
        this.log.debug('GoogleAnalyticsService revenue() ecommerce:send');
      });
    } else if (reservation.giftcards.length !== 0) {
      const hotelCode = this.applicationstate.CurrentHotelCode;

      await this.withGoogleAnalytics(async () => {
        gtag('event', 'purchase', {
          transaction_id: reservation.reservationId,
          items: [
            {
              item_id: `${reservation.giftcards[0].cardName}`,
              item_name: `${reservation.giftcards[0].cardName}/${hotelCode}`,
              affiliation: hotelCode,
              item_category: 'GiftCard',
              price: reservation.giftcards[0].total.toFixed(2),
              quantity: 1,
            },
          ],
        });
        this.log.debug('GoogleAnalyticsService revenue() ecommerce:send');
      });
    }
  }

  private async RevenueForExtraservices(reservation: ReservationType) {
    const hotelCode = reservation.rooms[0].hotelCode;

    await this.withGoogleAnalytics(async () => {
      await Promise.all(
        reservation.rooms.map(async (room) => {
          const { Name: roomName } = await this.hotelService.GetECommerceRoomInfo(
            room.hotelCode,
            room.roomCode,
            reservation.lang,
          );
          const { Code: rateName } = await this.hotelService.GetECommerceRateInfo(
            room.hotelCode,
            room.ratePlan,
            reservation.lang,
          );
          await Promise.all(
            room.services.map(async (service) => {
              const { Name: serviceName } = await this.hotelService.GetECommerceExtraServiceInfo(
                room.hotelCode,
                service.serviceCode,
                reservation.lang,
              );
              gtag('event', 'purchase', {
                transaction_id: reservation.reservationId,
                currency: this.applicationstate.HotelCurrency,
                value: service.price.toFixed(2),
                items: [
                  {
                    affiliation: hotelCode,
                    item_id: `${room.roomCode}-${room.ratePlan}-${service.serviceCode}`,
                    item_name: `${roomName}/${rateName}/${serviceName}/${room.hotelCode}`,
                    item_category: 'ExtraService',
                    price: service.price.toFixed(2),
                    quantity: service.count,
                  },
                ],
              });
            }),
          );
        }),
      );
      this.log.debug('GoogleAnalyticsService revenue for extra services() ecommerce:send');
    });
  }

  private async withGoogleAnalytics(handler: (googleAnalytics: Gtag.Gtag) => Promise<void> | void) {
    const googleAnalytics = await this.googleAnalytics;
    if (googleAnalytics) {
      if (handler instanceof Promise) {
        await handler(googleAnalytics);
      } else {
        void handler(googleAnalytics);
      }
    }
  }

  private async initGoogleAnalytics() {
    const config = await this.configService.getGaParams();
    const googleAnalyticsAccountCode = config.GoogleAnalyticsAccountCode;
    if (googleAnalyticsAccountCode) {
      const googleAnalytics = GoogleAnalyticsClientService.InitGoogleAnalyticsScript(googleAnalyticsAccountCode);
      this.log.debug(`Google Analytics is registered for '${googleAnalyticsAccountCode}' account`);
      return googleAnalytics;
    } else {
      return undefined;
    }
  }

  private static InitGoogleAnalyticsScript(accountCode: string) {
    const w = window as unknown as Window & {
      GoogleAnalyticsObject: string;
      gtag: Gtag.Gtag;
      dataLayer: GoogleTagManger;
    };
    w.GoogleAnalyticsObject = 'gtag';
    w.dataLayer = w.dataLayer || [];
    w.gtag =
      w.gtag ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        w.dataLayer.push(arguments);
      };
    const node = document.createElement('script');
    node.async = true;
    node.src = '//www.googletagmanager.com/gtag/js?id=' + accountCode;
    document.getElementsByTagName('head')[0].append(node);
    w.gtag('js', new Date());
    w.gtag('config', accountCode);
    return w.gtag;
  }
}

<ng-template [ngIf]="basket" let-basket>
  <div class="page-content">
    <div class="container">
      <a
        class="desktop-show mobile-hide applicationColor"
        style="position: absolute; font-size: 20px"
        (click)="goBack()"
        ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
      >

      <p style="text-align: center; font-size: 40px; font-weight: 500; margin-top: 20px">
        {{ 'CUP_BookingOverview' | t | async }}
      </p>
      <hr
        style="
          height: 2.5px;
          border: none;
          margin-top: 40px;
          margin-bottom: 40px;
          background: -webkit-gradient(
            linear,
            0 0,
            100% 0,
            from(transparent),
            to(transparent),
            color-stop(50%, var(--primary-application-color))
          );
        " />
    </div>
    <ng-template [ngIf]="information$" let-information$>
      <div
        *ngIf="{
          countries: countries | async,
          information: information$ | async
        } as data"
        class="page-content customer-page zoom-content-effect">
        <div
          *ngIf="data.information as information"
          class="container"
          style="background: rgba(255, 255, 255, 0.5); padding: 15px">
          <div class="section">
            <ng-template [ngIf]="hasRooms">
              <h2>
                <i class="fas fa-calendar-alt"></i>
                <span class="v-middle">{{ 'REP_DateRooms' | t | async }}</span>
              </h2>
              <div class="content-section" style="padding-top: 19px">
                <div class="row">
                  <div class="col l4 s12">
                    <dl>
                      <dt>{{ 'COM_Arrival' | t | async }}</dt>
                      <dd>
                        {{ basket.params.ArrivalDate }}.
                        {{ basket.params.ArrivalMonthName | t | async }}
                        {{ basket.params.ArrivalFullYear }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col l4 s12">
                    <dl>
                      <dt>{{ 'COM_Departure' | t | async }}</dt>
                      <dd>
                        {{ basket.params.DepartueDate }}.
                        {{ basket.params.DepartueMonthName | t | async }}
                        {{ basket.params.DepartueFullYear }}
                      </dd>
                    </dl>
                  </div>
                  <div *ngIf="promocode" class="col l4 s12">
                    <dl>
                      <dt>{{ 'COM_Promocode' | t | async }}</dt>
                      <dd>{{ promocode }}</dd>
                    </dl>
                  </div>
                </div>
              </div>

              <h2 style="padding-top: 19px">
                <i class="fas fa-bed"></i>
                <span class="v-middle">{{ applicationState.RoomsLabel | t | async }}</span>
              </h2>
              <div class="content-section" style="padding-top: 19px">
                <div *ngFor="let booking of information.Bookings; let index = index">
                  <div class="row">
                    <div class="col l4 s12">
                      <dl>
                        <dt>
                          {{ applicationState.RoomLabel | t | async }}
                          <b class="text-primary2">{{ index | char }}</b>
                        </dt>
                        <dd>{{ booking.RoomName }}</dd>
                        <dd [innerHTML]="booking.RateName"></dd>
                      </dl>
                    </div>
                    <div class="col l4 s12">
                      <dl>
                        <dt>{{ 'COM_TotalGuests' | t | async }}</dt>
                        <dd>
                          {{ basket.params.rooms[index] | guests | async }}
                        </dd>
                      </dl>
                    </div>
                    <div class="col l4 s12 text-right">
                      <dl>
                        <dt>{{ 'COM_Price' | t | async }}</dt>
                        <dd class="text-primary">
                          <ng-template [ngIf]="booking.OriginalPrice !== booking.Price"
                            ><del>{{ booking.OriginalPrice | price | async }}</del></ng-template
                          >
                          <span style="padding-top: 19px">{{ booking.Price | price | async }}</span>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="hasSeatings">
              <h2 style="padding-top: 19px">
                <i class="fas fa-glass-cheers"></i>
                <span class="v-middle">{{ applicationState.EventLabel | t | async }}</span>
              </h2>
              <div class="content-section" style="padding-top: 19px">
                <div *ngFor="let booking of basket.bookings">
                  <div *ngFor="let seat of booking.Seatings">
                    <div class="row" *ngIf="booking.Seatings !== undefined">
                      <div class="col l4 s12">
                        <dl>
                          <dt>
                            <b class="text-primary2"
                              >{{ seat.day }}. {{ seat.month | t | async }} {{ seat.year }} - {{ seat.Time }}</b
                            >
                          </dt>
                          <dd>{{ seat.Name }}</dd>
                        </dl>
                      </div>
                      <div class="col l4 s12">
                        <dl>
                          <dt>{{ 'COM_TotalGuests' | t | async }}</dt>
                          <dd>{{ seat.TicketTotal }}</dd>
                        </dl>
                      </div>
                      <div class="col l4 s12 text-right">
                        <dl>
                          <dt>{{ 'COM_Price' | t | async }}</dt>
                          <dd class="text-primary">
                            {{ seat.SinglePrice * seat.TicketTotal | price | async }}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="hasTableReservation">
              <h2 style="padding-top: 19px">
                <i class="fas fa-utensils"></i>
                <span class="v-middle">{{ 'SFO_BookTableReservation' | t | async }}</span>
              </h2>
              <div class="content-section" style="padding-top: 19px">
                <div *ngFor="let booking of basket.bookings">
                  <div class="row" *ngIf="booking.TableSeatings !== undefined">
                    <div *ngFor="let table of booking.TableSeatings">
                      <div class="col l4 s12">
                        <dl>
                          <dt>
                            <b class="text-primary2">
                              {{ table.Day }}.
                              {{ table.translatedMonth | t | async }}
                              {{ table.Year }} - {{ table.Time }}
                            </b>
                          </dt>
                          <dd>{{ table.Name }}</dd>
                        </dl>
                      </div>
                      <div class="col l4 s12">
                        <dl>
                          <dt>{{ 'COM_TotalGuests' | t | async }}</dt>
                          <dd>{{ table.SeatGuest[0].Count }}</dd>
                        </dl>
                      </div>
                      <div class="col l4 s12 text-right">
                        <dl>
                          <dt>{{ 'COM_Price' | t | async }}</dt>
                          <dd class="text-primary">
                            {{ table.Total | price | async }}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="hasAddons">
              <h2 style="padding-top: 19px">
                <i class="fas fa-gift"></i>
                <span class="v-middle">{{ 'APA_Services' | t | async }}</span>
              </h2>
              <div class="content-section" style="padding-top: 19px">
                <div *ngFor="let booking of information.Bookings; let bookingIndex = index">
                  <div *ngFor="let addOn of booking.AddOns; let index = index" class="row">
                    <div class="col l4 s12">
                      <dl>
                        <dt *ngIf="hasRooms">
                          {{ applicationState.RoomLabel | t | async }}
                          <b class="text-primary2">{{ bookingIndex | char }}</b>
                        </dt>
                        <dd>{{ addOn.Name }}</dd>
                      </dl>
                    </div>
                    <div class="col l4 s12">
                      <dl>
                        <dt>{{ 'COM_Quantity' | t | async }}</dt>
                        <dd>{{ addOn.Count }}</dd>
                      </dl>
                    </div>
                    <div class="col l4 s12 text-right">
                      <dl>
                        <dt>{{ 'COM_Price' | t | async }}</dt>
                        <dd class="text-primary">{{ addOn.Amount | price | async }}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="hasGiftCard">
              <h2 style="padding-top: 19px">
                <i class="fas fa-gifts"></i>
                <span class="v-middle">{{ 'SFO_BookGiftcard' | t | async }}</span>
              </h2>
              <div class="content-section" style="padding-top: 19px">
                <div *ngFor="let booking of basket.bookings">
                  <div class="row" *ngIf="booking.Giftcards !== undefined">
                    <div *ngFor="let gift of booking.Giftcards">
                      <div class="col l4 s12">
                        <dl>
                          <dt></dt>
                          <dd>{{ gift.Name }}</dd>
                        </dl>
                      </div>
                      <div class="col l4 s12">
                        <dl>
                          <dt>{{ 'SFO_BookGiftcard' | t | async }}</dt>
                          <dd>{{ gift.Count }}</dd>
                        </dl>
                      </div>
                      <div class="col l4 s12 text-right">
                        <dl>
                          <dt>{{ 'COM_Price' | t | async }}</dt>
                          <dd class="text-primary">
                            {{ gift.TotalPrice | price | async }}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="hotel.UseCancelPolicy && !hasGiftCard && cancellationprotection">
              <h2 style="padding-top: 19px">
                <i class="fas fa-times-circle"></i>
                <span class="v-middle">{{ 'CUP_CancelPolicyHeader' | t | async }}</span>
              </h2>
              <div class="content-section" style="padding-top: 19px">
                <p>{{ 'CUP_CancelPolicyDesc' | t | async }}</p>
              </div>
              <app-checkbox
                [checked]="useCancelationInsurance"
                label="Use cancelation insurance"
                (changed)="onUseCancelationInsuranceChange($event)">
                <label>
                  <span>{{ 'CUP_CancelPolicyLabel' | t | async }}</span>
                  <span style="padding-left: 8px" class="text-primary text-nowrap">
                    {{ information.CancelationInsurance | price | async }}
                  </span>
                </label>
              </app-checkbox>
              <div *ngIf="useCancelationInsurance" class="text-right">
                <dl>
                  <dt>{{ 'COM_Price' | t | async }}</dt>
                  <dd class="text-primary">
                    {{ information.CancelationInsurance | price | async }}
                  </dd>
                </dl>
              </div>
            </ng-template>

            <giftcard-form [hasGiftcard]="hasGiftCard" (giftcardInformationEvent)="setGiftcardInformationModel($event)">
              <div class="content-section" between>
                <div class="row" style="padding-top: 19px">
                  <!--Total price of the choosen goods-->
                  <div class="col right text-right">
                    <dl>
                      <dt>
                        <b>{{ 'COM_TotalPrice' | t | async }}</b>
                      </dt>
                      <dd style="color: darkgreen">
                        {{
                          information.Total + (useCancelationInsurance ? information.CancelationInsurance : 0)
                            | price
                            | async
                        }}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </giftcard-form>

            <div class="content-section" style="padding-top: 19px">
              <div class="row">
                <!--If the giftcard amount is below the totalprice-->
                <div
                  class="col right text-right"
                  *ngIf="
                    giftcardInformation &&
                    giftcardInformation.Amount <
                      information.Total + (useCancelationInsurance ? information.CancelationInsurance : 0)
                  ">
                  <dl>
                    <dt>
                      <b>{{ 'CUP_BookingOverviewToPay' | t | async }}</b>
                    </dt>
                    <dd style="color: darkgreen">
                      {{
                        information.Total +
                          (useCancelationInsurance ? information.CancelationInsurance : 0) -
                          (giftcardInformation ? giftcardInformation.Amount : 0)
                          | price
                          | async
                      }}
                    </dd>
                  </dl>
                </div>
                <!--If the giftcard amount is more than the totalprice-->
                <div
                  class="col right text-right"
                  *ngIf="
                    giftcardInformation &&
                    giftcardInformation.Amount >
                      information.Total + (useCancelationInsurance ? information.CancelationInsurance : 0)
                  ">
                  <dl>
                    <dt>
                      <b>{{ 'CUP_BookingOverviewToPay' | t | async }}</b>
                    </dt>
                    <dd style="color: darkgreen">{{ 0 | price | async }}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div class="section" style="padding-top: 19px">
            <h2>
              <i class="fas fa-user"></i>
              <span class="v-middle">{{ 'CUP_Header' | t | async }}</span>
            </h2>
            <form #f="ngForm" (ngSubmit)="onFormSubmit(f, customer.info, information)">
              <div class="row">
                <div class="col m6 s12">
                  <input
                    name="FirstName"
                    [(ngModel)]="customer.info.FirstName"
                    #FirstName="ngModel"
                    [placeholder]="'CUP_FName' | t | async"
                    [disabled]="!isCustomerInfoEditable"
                    autocomplete="given-name"
                    required />
                  <small *ngIf="FirstName.invalid && (FirstName.dirty || FirstName.touched)" class="text-danger">
                    <div *ngIf="FirstName?.errors.required">
                      {{ 'CUP_FNameReq' | t | async }}
                    </div>
                  </small>
                </div>
                <div class="col m6 s12">
                  <input
                    name="LastName"
                    [(ngModel)]="customer.info.LastName"
                    #LastName="ngModel"
                    [placeholder]="'CUP_LName' | t | async"
                    [disabled]="!isCustomerInfoEditable"
                    autocomplete="family-name"
                    required />
                  <small *ngIf="LastName.invalid && (LastName.dirty || LastName.touched)" class="text-danger">
                    <div *ngIf="LastName?.errors.required">
                      {{ 'CUP_LNameReq' | t | async }}
                    </div>
                  </small>
                </div>
              </div>
              <input
                name="Address1"
                [(ngModel)]="customer.info.Address1"
                #Address="ngModel"
                [placeholder]="'CUP_Address' | t | async"
                [disabled]="!isCustomerInfoEditable"
                autocomplete="address-line1"
                required />
              <small *ngIf="Address.invalid && (Address.dirty || Address.touched)" class="text-danger">
                <div *ngIf="Address?.errors.required">
                  {{ 'CUP_AddressReq' | t | async }}
                </div>
              </small>
              <div class="row">
                <div class="col m4 s12">
                  <input
                    name="ZipCode"
                    [(ngModel)]="customer.info.ZipCode"
                    #ZipCode="ngModel"
                    [placeholder]="'CUP_ZipCode' | t | async"
                    [disabled]="!isCustomerInfoEditable"
                    autocomplete="postal-code"
                    required />
                  <small *ngIf="ZipCode.invalid && (ZipCode.dirty || ZipCode.touched)" class="text-danger">
                    <div *ngIf="ZipCode?.errors.required">
                      {{ 'CUP_ZipCodeReq' | t | async }}
                    </div>
                  </small>
                </div>
                <div class="col m4 s12">
                  <input
                    name="City"
                    [(ngModel)]="customer.info.City"
                    #City="ngModel"
                    [placeholder]="'CUP_City' | t | async"
                    [disabled]="!isCustomerInfoEditable"
                    autocomplete="address-level2"
                    required />
                  <small *ngIf="City.invalid && (City.dirty || City.touched)" class="text-danger">
                    <div *ngIf="City?.errors.required">
                      {{ 'CUP_CityReq' | t | async }}
                    </div>
                  </small>
                </div>
                <div class="col m4 s12">
                  <div class="with-toggle focusable">
                    <i class="toggle fas fa-chevron-up"></i>
                    <select
                      name="Country"
                      [(ngModel)]="customer.info.Country"
                      [value]="defaultCountryCode"
                      #Country="ngModel"
                      [disabled]="!isCustomerInfoEditable"
                      autocomplete="country"
                      required>
                      <option default hidden value="">
                        {{ 'CUP_Contry' | t | async }}
                      </option>
                      <option *ngFor="let country of data.countries" [value]="country.Code">
                        {{ country.Name }}
                      </option>
                    </select>
                  </div>
                  <small *ngIf="Country.invalid && (Country.dirty || Country.touched)" class="text-danger">
                    <div *ngIf="Country?.errors.required">
                      {{ 'CUP_ContryReq' | t | async }}
                    </div>
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col m6 s12">
                  <input
                    name="Phone"
                    [(ngModel)]="customer.info.Phone"
                    #Phone="ngModel"
                    [placeholder]="'CUP_Phone' | t | async"
                    [disabled]="!isCustomerInfoEditable"
                    autocomplete="tel"
                    required />
                  <small *ngIf="Phone.invalid && (Phone.dirty || Phone.touched)" class="text-danger">
                    <div *ngIf="Phone?.errors.required">
                      {{ 'CUP_PhoneReq' | t | async }}
                    </div>
                  </small>
                </div>
                <div class="col m6 s12">
                  <input
                    name="Email"
                    [(ngModel)]="customer.info.Email"
                    #Email="ngModel"
                    [placeholder]="'CUP_Email' | t | async"
                    [disabled]="!isCustomerInfoEditable"
                    autocomplete="email"
                    required
                    email />
                  <small *ngIf="Email.invalid && (Email.dirty || Email.touched)" class="text-danger">
                    <div *ngIf="Email?.errors.required">
                      {{ 'CUP_EmailReq' | t | async }}
                    </div>
                    <div *ngIf="Email?.errors.email">
                      {{ 'CUP_EmailInvalid' | t | async }}
                    </div>
                  </small>
                  <input
                    *ngIf="hasGiftCard"
                    name="RepeatEmail"
                    [placeholder]="'CUP_EmailRepeat' | t | async"
                    required
                    email
                    (change)="onRepeatEmailChange($event.target.value)" />
                  <p *ngIf="hasGiftCard && !repeatEmailvalid" class="text-danger">
                    {{ 'CUP_EmailInvalid' | t | async }}
                  </p>
                </div>
              </div>
              <textarea
                [value]="comment"
                rows="5"
                [placeholder]="'CUP_Comment' | t | async"
                (change)="onCommentChange($event.target.value)"></textarea>
              <ng-template [ngIf]="customer.isKnownCustomer && !hasGiftCard">
                <!-- show guest inputs only if customer is known -->
                <h2>
                  <i class="fas fa-user-friends fa-lg"></i>
                  <span class="v-middle"> {{ 'CUP_GuestInfo' | t | async }}</span>
                </h2>
                <div *ngFor="let booking of information.Bookings; let bookingIndex = index">
                  <dl class="content-section">
                    <dt>{{ 'COM_Room' | t | async }} {{ bookingIndex | char }}</dt>
                  </dl>
                  <div class="row">
                    <!-- each input in this part is using autocomplete="new-password" to prevent autocomplete -->
                    <div class="col l2 m6 s12">
                      <input
                        name="GuestFirstName[{{ bookingIndex }}]"
                        [(ngModel)]="booking.Guest.FirstName"
                        #GuestFirstName="ngModel"
                        [placeholder]="'CUP_FName' | t | async"
                        autocomplete="new-password"
                        required />
                      <small
                        *ngIf="GuestFirstName.invalid && (GuestFirstName.dirty || GuestFirstName.touched)"
                        class="text-danger">
                        <div *ngIf="GuestFirstName?.errors.required">
                          {{ 'CUP_FNameReq' | t | async }}
                        </div>
                      </small>
                    </div>
                    <div class="col l2 m6 s12">
                      <input
                        name="GuestLastName[{{ bookingIndex }}]"
                        [(ngModel)]="booking.Guest.LastName"
                        #GuestLastName="ngModel"
                        [placeholder]="'CUP_LName' | t | async"
                        autocomplete="new-password"
                        required />
                      <small
                        *ngIf="GuestLastName.invalid && (GuestLastName.dirty || GuestLastName.touched)"
                        class="text-danger">
                        <div *ngIf="GuestLastName?.errors.required">
                          {{ 'CUP_LNameReq' | t | async }}
                        </div>
                      </small>
                    </div>
                    <div class="col l3 m4 s12" *ngIf="!applicationState.MandatoryPhone">
                      <input
                        name="GuestPhone[{{ bookingIndex }}]"
                        [(ngModel)]="booking.Guest.Phone"
                        #GuestPhone="ngModel"
                        [placeholder]="'CUP_Phone' | t | async"
                        autocomplete="new-password" />
                    </div>
                    <div class="col l3 m4 s12" *ngIf="applicationState.MandatoryPhone">
                      <input
                        required
                        name="GuestPhone[{{ bookingIndex }}]"
                        [(ngModel)]="booking.Guest.Phone"
                        #GuestPhone="ngModel"
                        [placeholder]="'CUP_Phone' | t | async"
                        autocomplete="new-password" />
                      <small *ngIf="GuestPhone.invalid && (GuestPhone.dirty || GuestPhone.touched)" class="text-danger">
                        <div *ngIf="GuestPhone?.errors.required">
                          {{ 'CUP_FPhoneReq' | t | async }}
                        </div>
                      </small>
                    </div>
                    <div class="col l3 m4 s12" *ngIf="applicationState.MandatoryEmail">
                      <input
                        required
                        name="GuestEmail[{{ bookingIndex }}]"
                        [(ngModel)]="booking.Guest.Email"
                        #GuestEmail="ngModel"
                        [placeholder]="'CUP_Email' | t | async"
                        autocomplete="new-password"
                        email />
                      <small *ngIf="GuestEmail.invalid && (GuestEmail.dirty || GuestEmail.touched)" class="text-danger">
                        <div *ngIf="GuestEmail?.errors.required">
                          {{ 'CUP_EmailInvalid' | t | async }}
                        </div>
                        <div *ngIf="GuestEmail?.errors.email">
                          {{ 'CUP_EmailInvalid' | t | async }}
                        </div>
                      </small>
                    </div>
                    <div class="col l3 m4 s12" *ngIf="!applicationState.MandatoryEmail">
                      <input
                        name="GuestEmail[{{ bookingIndex }}]"
                        [(ngModel)]="booking.Guest.Email"
                        #GuestEmail="ngModel"
                        [placeholder]="'CUP_Email' | t | async"
                        autocomplete="new-password"
                        email />
                    </div>
                    <div class="col l2 m4 s12">
                      <div class="with-toggle focusable">
                        <i class="toggle fas fa-chevron-up fa-lg"></i>
                        <select
                          name="GuestCountry[{{ bookingIndex }}]"
                          [(ngModel)]="booking.Guest.Country"
                          [value]="defaultCountryCode"
                          #GuestCountry="ngModel"
                          autocomplete="new-password"
                          required>
                          <option default hidden value="">
                            {{ 'CUP_Contry' | t | async }}
                          </option>
                          <option *ngFor="let country of data.countries" [value]="country.Code">
                            {{ country.Name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <div class="row" *ngIf="showSubscribeCheckbox">
                <div class="col s12">
                  <app-checkbox [checked]="needSubscribe" (changed)="onNeedSubscribeChange($event)">
                    <label
                      ><span>{{ 'CUP_NeedSubscribe' | t | async }}</span></label
                    >
                  </app-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <app-checkbox [checked]="acceptTerms" label="Accept terms" (changed)="onAcceptTermsChange($event)">
                    <label>
                      <a href="#" (click)="onShowTermsClick(hotel?.Terms, $event)" class="text-underline">{{
                        'CUP_AcceptTerms' | t | async
                      }}</a>
                    </label>
                  </app-checkbox>
                </div>
              </div>
              <div class="section">
                <div class="text-center">
                  <button
                    *ngIf="acceptTerms && repeatEmailvalid; else disabledButton"
                    type="submit"
                    class="btn btn-primary with-shadow">
                    {{ 'CUP_BookNow' | t | async }}
                  </button>
                  <ng-template #disabledButton>
                    <a class="btn btn-primary disabled with-shadow">{{ 'CUP_BookNow' | t | async }}</a>
                  </ng-template>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #messageHotelTermsTempalte let-message>
  <div class="dialog-container">
    <div class="dialog-header" [ngStyle]="{ 'background-color': contentDialogHeader }">
      <img [src]="logo" class="dialog-logo" />
    </div>
    <div class="dialog">
      <div class="dialog-body">
        <div class="small" [innerHTML]="message.html"></div>
      </div>
      <div class="text-center dialog-footer">
        <button
          style="margin-right: 10px; min-width: 248px"
          class="btn btn-primary-outline with-shadow mb-0"
          type="button"
          (click)="message.close()">
          {{ 'COM_Close' | t | async }}
        </button>
        <button
          style="min-width: 248px"
          class="default btn btn-primary with-shadow mb-0"
          type="button"
          (click)="message.accept()">
          {{ 'CUP_Accept' | t | async }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<app-messenger></app-messenger>

<app-loader></app-loader>

<div class="main-container">
  <header #header>
    <ng-template [ngIf]="data$ | async" let-data>
      <div *ngIf="applicationState.ApplicationLogo" class="logo-nav removeLogo">
        <img
          [src]="applicationState.ApplicationLogo"
          style="max-height: 90%; max-width: 90%; cursor: pointer"
          (click)="logoClicked()" />
      </div>
      <!--Desktop navbar-->
      <nav class="head-line mobile-hide">
        <div class="nav-wrapper dynamicTextColor" style="font-weight: 600">
          <a class="brand-logo" routerLink="/">Booking v{{ version }}</a>
          <div class="navbar-left" *ngIf="showLinkbar && this.applicationState.UseNavbarLinks">
            <div class="row">
              <div class="col s3" *ngIf="applicationState.UseSpectraOnlineBookingMenuPoint">
                <button type="button" class="link text-upper" (click)="ratesClick()">
                  {{ 'COM_Package' | t | async }}
                </button>
              </div>
              <div class="col s3" *ngIf="applicationState.UseSeatingMenuPoint">
                <button type="button" class="link text-upper" (click)="seatingLink()">
                  {{ applicationState.EventLabel | t | async }}
                </button>
              </div>
              <div class="col s3" *ngIf="applicationState.UseGiftcardMenuPoint">
                <button type="button" class="link text-upper" (click)="giftcardLink()">
                  {{ 'SFO_BookGiftcard' | t | async }}
                </button>
              </div>
              <div class="col s3" *ngIf="applicationState.UseSpectraOnlineBookingMenuPoint">
                <button type="button" class="link text-upper" (click)="roomClick()">
                  {{ 'COM_Rooms' | t | async }}
                </button>
              </div>
              <div class="col s3" *ngIf="applicationState.UseTableReservationMenuPoint">
                <button type="button" class="link text-upper" (click)="tableReservationLink()">
                  {{ 'COM_TableReservation' | t | async }}
                </button>
              </div>
              <div class="col s3" *ngIf="applicationState.UseConferenceBookingMenuPoint">
                <button type="button" class="link text-upper" (click)="conferenceBookingLink()">
                  {{ 'COM_Conference' | t | async }}
                </button>
              </div>
            </div>
          </div>
          <div class="navbar">
            <div class="row">
              <ng-template [ngIf]="data.hotel | async" let-hotel>
                <div class="col s4 text-left" *ngIf="showLoginPromotion">
                  <ng-template [ngIf]="hotel.Settings.UseLogin">
                    <div *ngIf="data.customer as customer" class="nav-item">
                      <div *ngIf="customer.Id">
                        {{ 'APA_Welcome' | t | async }}
                        {{ customer.Info.FirstName }}
                        <button
                          *ngIf="data.canChangeCustomer"
                          type="button"
                          class="link text-upper"
                          (click)="onLogoutClick()">
                          <span class="small">({{ 'APA_Logout' | t | async }})</span>
                        </button>
                      </div>
                      <div *ngIf="!customer.Id && data.canChangeCustomer">
                        <app-popup [show]="showLoginPopup" (closed)="onShowLoginPopupClose()">
                          <ng-template #itemTemplate>
                            <button type="button" class="link text-upper" (click)="onShowLoginPopupClick()">
                              {{ 'APA_CompanyLogin' | t | async }}
                            </button>
                          </ng-template>
                          <ng-template #contentTemplate>
                            <app-login-form (formSubmit)="onShowLoginPopupClose()"></app-login-form>
                          </ng-template>
                        </app-popup>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="col s4 text-center" *ngIf="showLoginPromotion">
                  <ng-template [ngIf]="hotel.Settings.UsePromotion">
                    <ng-template [ngIf]="{ code: promocode$ | async }" let-promo>
                      <div class="nav-item">
                        <app-popup [show]="showPromoPopup" (closed)="onClosePromoPopup()">
                          <ng-template #itemTemplate>
                            <ng-template [ngIf]="data.canChangePromo">
                              <button
                                *ngIf="promo.code"
                                type="button"
                                class="link text-upper"
                                (click)="onShowPromoPopupClick()">
                                {{ promo.code }}
                                <span class="small">({{ 'APA_PromoChange' | t | async }})</span>
                              </button>
                              <button
                                *ngIf="!promo.code"
                                type="button"
                                class="link text-upper"
                                (click)="onShowPromoPopupClick()">
                                {{ 'APA_ShowPromo' | t | async }}
                              </button>
                            </ng-template>
                          </ng-template>
                          <ng-template #contentTemplate>
                            <app-promo-form
                              [hotelCode]="hotel.Code"
                              (formSubmit)="onPromoSubmit()"
                              (closeForm)="onClosePromoPopup()"></app-promo-form>
                          </ng-template>
                        </app-popup>
                      </div>
                    </ng-template>
                  </ng-template>
                  <p style="color: red" *ngIf="promotionInvalid">
                    {{ 'CPA_PromoInvalid' | t | async }}
                  </p>
                </div>
              </ng-template>
              <div class="col s4 text-right">
                <div
                  *ngIf="{
                    show: false,
                    culture: culture$ | async
                  } as localePopupState"
                  class="nav-item">
                  <app-popup [show]="localePopupState.show" align="right" (closed)="localePopupState.show = false">
                    <ng-template #itemTemplate>
                      <div class="text-upper" (click)="localePopupState.show = true">
                        <i class="imgicon {{ localePopupState.culture?.CssClass }}"></i>
                        <span class="hide-s"> {{ localePopupState.culture?.Name }}</span>
                      </div>
                    </ng-template>
                    <ng-template #contentTemplate>
                      <div class="list-group text-left">
                        <div
                          *ngFor="let culture of cultures"
                          class="list-group-item action"
                          [ngClass]="{
                            active: culture === localePopupState.culture
                          }"
                          (click)="onLocaleSelect(culture.Code)">
                          <div class="text-wrap">
                            <i class="imgicon {{ culture.CssClass }} v-middle"></i>
                            <span class="v-middle"> {{ culture.Name }}</span>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </app-popup>
                </div>
              </div>
              <div class="icon-bar" *ngIf="showBasket">
                <div>
                  <a (click)="BasketClick()" class="basket" id="basket"
                    ><i class="fas fa-shopping-basket" style="color: var(--color-dynamic)"></i
                  ></a>
                  <div class="badge-wrapper">
                    <span id="basketBadge" class="badge">{{ basketService.basketBadgeNumberGet }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <!--Mobile-->
      <nav class="head-line desktop-hide">
        <div class="nav-wrapper">
          <div class="navbar">
            <div class="row">
              <div class="col l4 s4 m4">
                <a (click)="BasketClick()" class="basket" id="basket"
                  ><i class="fas fa-shopping-basket" style="color: var(--color-dynamic)"></i
                ></a>
                <div class="badge-wrapper">
                  <span id="basketBadge" class="badge">{{ basketService.basketBadgeNumberGet }}</span>
                </div>
              </div>
              <div class="col l4 s4 m4">
                <div
                  style="margin-top: -2px"
                  (click)="clickLocale()"
                  *ngIf="{
                    show: false,
                    culture: culture$ | async
                  } as localePopupState">
                  <app-popup [show]="localePopupState.show" (closed)="localePopupState.show = false">
                    <ng-template #itemTemplate>
                      <div class="text-upper" (click)="localePopupState.show = true">
                        <i class="imgicon {{ localePopupState.culture?.CssClass }}"></i>
                        <span class="hide-s"> {{ localePopupState.culture?.Name }}</span>
                      </div>
                    </ng-template>
                    <ng-template #contentTemplate>
                      <div class="list-group text-left">
                        <div
                          *ngFor="let culture of cultures"
                          class="list-group-item action"
                          [ngClass]="{
                            active: culture === localePopupState.culture
                          }"
                          (click)="onLocaleSelect(culture.Code)">
                          <div class="text-wrap">
                            <i class="imgicon {{ culture.CssClass }} v-middle"></i>
                            <span class="v-middle"> {{ culture.Name }}</span>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </app-popup>
                </div>
              </div>
              <div class="col l4 s4 m4">
                <i style="font-size: 25px" class="fas fa-bars" (click)="menuClick()"></i>
              </div>
            </div>
            <div class="dropmenu" *ngIf="showMobileMenu">
              <div
                class="nav-item"
                *ngIf="applicationState.UseSpectraOnlineBookingMenuPoint && this.applicationState.UseNavbarLinks">
                <button type="button" class="link text-upper" (click)="ratesClick()">
                  {{ 'COM_Package' | t | async }}
                </button>
              </div>
              <div
                class="nav-item"
                *ngIf="applicationState.UseSeatingMenuPoint && this.applicationState.UseNavbarLinks">
                <button type="button" class="link text-upper" (click)="seatingLink()">
                  {{ applicationState.EventLabel | t | async }}
                </button>
              </div>
              <div
                class="nav-item"
                *ngIf="applicationState.UseGiftcardMenuPoint && this.applicationState.UseNavbarLinks">
                <button type="button" class="link text-upper" (click)="giftcardLink()">
                  {{ 'SFO_BookGiftcard' | t | async }}
                </button>
              </div>
              <div
                class="nav-item"
                *ngIf="applicationState.UseSpectraOnlineBookingMenuPoint && this.applicationState.UseNavbarLinks">
                <button type="button" class="link text-upper" (click)="roomClick()">
                  {{ 'COM_Rooms' | t | async }}
                </button>
              </div>
              <div
                class="nav-item"
                *ngIf="applicationState.UseTableReservationMenuPoint && this.applicationState.UseNavbarLinks">
                <button type="button" class="link text-upper" (click)="tableReservationLink()">
                  {{ 'COM_TableReservation' | t | async }}
                </button>
              </div>
              <div
                class="nav-item"
                *ngIf="applicationState.UseConferenceBookingMenuPoint && this.applicationState.UseNavbarLinks">
                <button type="button" class="link text-upper" (click)="conferenceBookingLink()">
                  {{ 'COM_Conference' | t | async }}
                </button>
              </div>

              <ng-template [ngIf]="data.hotel | async" let-hotel>
                <div *ngIf="!removeloginPromotion">
                  <ng-template [ngIf]="hotel.Settings.UseLogin">
                    <div *ngIf="data.customer as customer" class="nav-item">
                      <div *ngIf="customer.Id">
                        {{ 'APA_Welcome' | t | async }}
                        {{ customer.Info.FirstName }}
                        <button
                          *ngIf="data.canChangeCustomer"
                          type="button"
                          class="link text-upper"
                          (click)="onLogoutClick()">
                          <span class="small">({{ 'APA_Logout' | t | async }})</span>
                        </button>
                      </div>
                      <div *ngIf="!customer.Id && data.canChangeCustomer">
                        <app-popup [show]="showLoginPopup" (closed)="onShowLoginPopupClose()">
                          <ng-template #itemTemplate>
                            <button type="button" class="link text-upper" (click)="onShowLoginPopupClick()">
                              {{ 'APA_CompanyLogin' | t | async }}
                            </button>
                          </ng-template>
                          <ng-template #contentTemplate>
                            <app-login-form (formSubmit)="onShowLoginPopupClose()"></app-login-form>
                          </ng-template>
                        </app-popup>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div *ngIf="!removeloginPromotion">
                  <ng-template [ngIf]="hotel.Settings.UsePromotion">
                    <ng-template [ngIf]="{ code: promocode$ | async }" let-promo>
                      <div class="nav-item">
                        <app-popup [show]="showPromoPopup" (closed)="onClosePromoPopup()">
                          <ng-template #itemTemplate>
                            <ng-template [ngIf]="data.canChangePromo">
                              <button
                                *ngIf="promo.code"
                                type="button"
                                class="link text-upper"
                                (click)="onShowPromoPopupClick()">
                                {{ promo.code }}
                                <span class="small">({{ 'APA_PromoChange' | t | async }})</span>
                              </button>
                              <button
                                *ngIf="!promo.code"
                                type="button"
                                class="link text-upper"
                                (click)="onShowPromoPopupClick()">
                                {{ 'APA_ShowPromo' | t | async }}
                              </button>
                            </ng-template>
                          </ng-template>
                          <ng-template #contentTemplate>
                            <app-promo-form
                              [hotelCode]="hotel.Code"
                              (formSubmit)="onPromoSubmit()"
                              (closeForm)="onClosePromoPopup()"></app-promo-form>
                          </ng-template>
                        </app-popup>
                      </div>
                    </ng-template>
                  </ng-template>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </nav>
      <app-basket></app-basket>
    </ng-template>
  </header>
  <router-outlet></router-outlet>
  <footer>
    <div class="footer">
      <div *ngIf="FooterInfo" class="row">
        <div class="{{ applicationState.UseNetsEasy ? 'col l3 s12 ' : 'col l4 s12 ' }} text-center">
          <div class="text-left">
            {{ FooterInfo.Name }}<br />
            {{ FooterInfo.Address }},<br />
            {{ FooterInfo.PostalCode }} {{ FooterInfo.City }}
          </div>
        </div>
        <div class="{{ applicationState.UseNetsEasy ? 'col l3 s12 ' : 'col l4 s12 ' }} text-center">
          <div class="text-left">
            {{ FooterInfo.Phone }}<br />
            <a href="mailto:{{ FooterInfo.Email }}">{{ FooterInfo.Email }}</a
            ><br />
            <span
              *ngIf="FooterInfo.VATNumber !== undefined && FooterInfo.VATNumber !== null && FooterInfo.VATNumber !== ''"
              >{{ 'APA_VatNumber' | t | async }} {{ FooterInfo.VATNumber }}</span
            >
          </div>
        </div>
        <div
          *ngIf="applicationState.UseNetsEasy"
          class="{{ applicationState.UseNetsEasy ? 'col l3 s12 ' : 'col l4 s12 ' }}">
          <p class="mobile-hide">{{ 'PAR_PaymentMethod' | t | async }}</p>
          <div [innerHTML]="applicationState.AcceptedCards"></div>
        </div>
        <div class="{{ applicationState.UseNetsEasy ? 'col l3 s12 ' : 'col l4 s12 ' }}">
          <img
            *ngIf="applicationState.ApplicationTextColor === '#FFFFFF'"
            src="assets/Group 171.svg"
            style="height: 3.8vh" />
          <img
            *ngIf="applicationState.ApplicationTextColor === '#000000'"
            src="assets/Group 172.svg"
            style="height: 3.8vh" />
        </div>
      </div>
    </div>
    <div
      class="mobile-show desktop-hide applicationColor"
      style="width: 100%; height: 7%; background-color: #eee; position: fixed; bottom: 0; z-index: 2">
      <div class="row" style="margin-top: 1.5%">
        <div class="column" style="margin-top: 1%; text-align: center" (click)="goBack()">
          <i class="fas fa-arrow-left"></i><br />
          <button type="button" class="link text-upper" style="font-size: 10px">
            {{ 'COM_Back' | t | async }}
          </button>
        </div>
        <div class="column" style="margin-top: 1%; text-align: center" (click)="goHome()">
          <i class="fas fa-home"></i><br />
          <button type="button" class="link text-upper" style="font-size: 10px">
            {{ 'COM_Main' | t | async }}
          </button>
        </div>
        <div
          class="column"
          style="margin-top: 1%; text-align: center"
          (click)="next()"
          *ngIf="!navigationHelper.isNextpageEmpty() && showMobileContinue">
          <i class="fas fa-arrow-right"></i><br />
          <button type="button" class="link text-upper" style="font-size: 10px">
            {{ 'COM_Continue' | t | async }}
          </button>
        </div>
      </div>
    </div>
  </footer>
</div>

<ng-template #messageErrorTempalte let-message>
  <div class="dialog-container-soldout">
    <div class="dialog-header-soldout">
      <p style="margin-top: 20px" class="dynamicTextColor">
        {{ 'COM_Error' | t | async }}
      </p>
    </div>
    <div class="dialog-body-soldout">
      <p *ngIf="isSoldOutError" class="section">
        {{ message.text | t | async }}
      </p>
    </div>
    <div class="text-center dialog-footer-soldout">
      <button
        *ngIf="isSoldOutError"
        class="default btn btn-secondary with-shadow m-0"
        type="button"
        (click)="message.close()">
        {{ 'COM_SearchNew' | t | async }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #messageConfirmResetTemplate let-message>
  <div class="card">
    <div class="card-body">
      <h1>{{ 'COM_Info' | t | async }}</h1>
      <p class="section">{{ 'APA_ResetBasket' | t | async }}</p>
      <div class="text-center">
        <div class="d-inline-flex flex-column mw-100">
          <button class="default btn btn-primary with-shadow m-0" type="button" (click)="message.confirm()">
            {{ 'COM_Confirm' | t | async }}
          </button>
          <button class="btn btn-secondary with-shadow mb-0" type="button" (click)="message.cancel()">
            {{ 'COM_Close' | t | async }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #messageActiveAccount let-message>
  <form #f="ngForm" (ngSubmit)="message.activate(f)">
    <div class="dialog-container-activate">
      <div class="dialog-header-activate">
        <h1>{{ 'APA_ActivateHead' | t | async }}</h1>
      </div>
      <div class="dialog-activate">
        <div class="dialog-body-activate">
          <p class="section mb-0">
            {{ 'APA_ActivateDesc' | t | async }}
            <mark>{{ message.email }}</mark>
          </p>
          <input
            class="mb-0"
            type="password"
            autocomplete="new-password"
            name="password"
            [(ngModel)]="message.password"
            #password="ngModel"
            [placeholder]="'APA_Password' | t | async"
            minlength="8"
            appHasNumber
            appHasLower
            appHasUpper />
          <small *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
            <div *ngIf="password?.errors.appHasUpper">
              {{ 'APA_PasswordHasUpper' | t | async }}
            </div>
            <div *ngIf="password?.errors.appHasLower">
              {{ 'APA_PasswordHasLower' | t | async }}
            </div>
            <div *ngIf="password?.errors.appHasNumber">
              {{ 'APA_PasswordHasNumber' | t | async }}
            </div>
            <div *ngIf="password?.errors.minlength">
              {{ 'APA_PasswordLength8' | t | async }}
            </div>
          </small>
          <input
            class="mb-0"
            type="password"
            autocomplete="new-password"
            name="confirmPassword"
            [(ngModel)]="message.confirmPassword"
            #confirmPassword="ngModel"
            [placeholder]="'APA_ConfirmPassword' | t | async"
            [appEqualTo]="password" />
          <small
            *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
            class="text-danger">
            <div *ngIf="confirmPassword?.errors.appEqualTo">
              {{ 'APA_ConfirmPasswordEqualTo' | t | async }}
            </div>
          </small>
          <p *ngIf="message.error" class="section mb-0 text-danger">
            {{ message.error }}
          </p>
          <div class="text-center dialog-footer">
            <div class="d-inline-flex flex-column mw-100">
              <button class="default btn btn-primary with-shadow mb-0" type="submit">
                {{ 'APA_Activate' | t | async }}
              </button>
              <button class="btn btn-secondary with-shadow mb-0" type="button" (click)="message.cancel()">
                {{ 'COM_Close' | t | async }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #messageSuccess let-message>
  <div class="dialog-container-Message">
    <div class="dialog-Message">
      <div class="dialog-body-Message">
        <i
          style="font-size: 90px; color: darkolivegreen; margin-top: 15%; margin-right: 55px"
          class="fas fa-check-circle"></i>
        <p class="applicationColor" style="padding: 40px; font-weight: 500">
          {{ 'APA_PromoMessageStart' | t | async }}
          <span class="applicationColor">'{{ promocode$.value }}'</span>. {{ 'APA_ValidTime' | t | async }}:
          {{ promoCodeStartDate.getDate() }}.
          {{ promoCodeStartMonth | t | async }}
          {{ promoCodeStartDate.getFullYear() }} - {{ promoCodeEndDate.getDate() }}.
          {{ promoCodeEndMonth | t | async }} {{ promoCodeEndDate.getFullYear() }}.<br />
          {{ 'APA_PromoMessageEnd' | t | async }}
        </p>
      </div>
      <div class="text-center dialog-footer-Message">
        <button
          style="min-width: 150px; margin-left: 5px; margin-top: 30px"
          class="btn btn-primary-outline with-shadow mb-0"
          type="button"
          (click)="message.close()">
          {{ 'COM_OK' | t | async }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

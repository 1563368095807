import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'char' })
export class CharPipe implements PipeTransform {
  static ConvertToChar(index: number) {
    return String.fromCharCode(index + 65 /*A*/);
  }

  transform(index: number | undefined) {
    return typeof index === 'number' ? CharPipe.ConvertToChar(index) : '';
  }
}

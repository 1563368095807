import { Component, Input } from '@angular/core';
import { NavigationHelper } from '../../helpers/navigation-helper';
import { Moment } from 'moment';
import { ApplicationStateService, SeatingData } from '../../core';

@Component({
  selector: 'app-seating-standalone-card',
  templateUrl: './seating-standalone-card.component.html',
})
export class SeatingStandaloneCardComponent {
  isMaxHeight = false;

  @Input() imageSrc = '';
  @Input() header = '';
  @Input() description = '';
  @Input() characteristics = '';
  @Input() date!: Moment;
  @Input() seatingData!: SeatingData;

  constructor(private navigationHelper: NavigationHelper, private applicationState: ApplicationStateService) {}

  setMaxHeight(isMaxHeight: boolean) {
    this.isMaxHeight = isMaxHeight;
  }

  async onNavigate() {
    if (this.characteristics === null) {
      return;
    }

    const queryParams = {
      date: this.date ? this.date.format('DD-MM-YYYY') : null,
      hotelCode: this.applicationState.CurrentHotelCode,
    };

    // TODO: Check if available - perhaps check parent component if its even rendered in the first place

    await this.navigationHelper.continue('seating-standalone', `seating-standalone/${this.characteristics}`, {
      queryParams: queryParams,
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InMemoryCache } from 'src/app/helpers';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { Configuration } from './configuration';

export interface IConfigService {
  getConfiguration(): Promise<Configuration>;
}

@Injectable()
export class DataConfigService implements IConfigService {
  private configCache = new InMemoryCache<Configuration>();
  private configUrl = environment.configUrl;

  constructor(private http: HttpClient) {}

  async getConfiguration() {
    return await this.configCache.getOrCreateAsync('config', async () => {
      try {
        return await lastValueFrom(this.http.get<Configuration>(this.configUrl));
      } catch (err) {
        throw new Error('Failed to load configurations');
      }
    });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationStateService, BasketService, HotelService } from '../../../core';
import { DateHelper } from '../../../helpers';
import { NavigationHelper } from '../../../helpers/navigation-helper';

@Component({
  selector: 'app-guests',
  templateUrl: './guests.component.html',
})
export class GuestsComponent implements OnInit, OnDestroy {
  guestCounter = 2;

  constructor(
    private applicationState: ApplicationStateService,
    private basketService: BasketService,
    private navigationHelper: NavigationHelper,
    private hotelService: HotelService,
  ) {}

  async ngOnInit() {
    await this.canUseModule();

    document.body.classList.remove('addBackGround');
    document.body.classList.remove('addBackGroundWithAnimation');
    document.body.style.backgroundImage = '';
    await this.prepareNavigation();
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async prepareNavigation() {
    await this.navigationHelper.continueMobileNavigationbar(false, true, '/guests', '/products', {
      queryParams: {
        HotelCode: this.applicationState.CurrentHotelCode,
        ArrivalDate: DateHelper.toServerDateFormatString(new Date()),
        LengthOfStay: 30,
        guest: this.guestCounter,
      },
    });
  }

  async canUseModule() {
    await this.hotelService.BuildApplicationState();

    if (!this.applicationState.UseTableReservation) {
      await this.navigationHelper.navigateToPage('/landing-page');
    }
  }

  async clearBasket() {
    this.basketService.basketBadgeNumberSet = '0';
    await this.goBack();
  }

  async addGuest() {
    this.guestCounter = this.guestCounter + 1;
    await this.prepareNavigation();
  }

  async next() {
    await this.navigationHelper.continue('/guests', '/products', {
      queryParams: {
        HotelCode: this.applicationState.CurrentHotelCode,
        ArrivalDate: DateHelper.toServerDateFormatString(new Date()),
        LengthOfStay: 30,
        guest: this.guestCounter,
      },
    });
  }

  async goBack() {
    await this.navigationHelper.goBack();
  }

  removeGuest() {
    if (this.guestCounter > 1) {
      this.guestCounter = this.guestCounter - 1;
    }
  }
}

export type Guid = '00000000-0000-0000-0000-000000000000';

export class GuidHelper {
  static Empty = '00000000-0000-0000-0000-000000000000' as Guid;

  static NewGuid() {
    let result = '';
    for (let j = 0; j < 32; j++) {
      if (j === 8 || j === 12 || j === 16 || j === 20) {
        result = result + '-';
      }
      result += Math.floor(Math.random() * 16)
        .toString(16)
        .toUpperCase();
    }
    return result as Guid;
  }
}

export abstract class CustomError extends Error {
  abstract type?: string;
}

export class InvalidPromocodeError extends CustomError {
  static Type = 'InvalidPromocodeError';
  type = InvalidPromocodeError.Type;

  constructor() {
    super('Promocode is invalid');
  }
}

export class InvalidCustomerError extends CustomError {
  static Type = 'InvalidCustomerError';
  type = InvalidCustomerError.Type;

  constructor() {
    super('Customer is invalid');
  }
}

export class PromocodeIsOutsideRangeError extends CustomError {
  static Type = 'PromocodeIsOutsideRangeError';
  type = PromocodeIsOutsideRangeError.Type;

  constructor(public start: string, public end: string) {
    super('Promocode is outside allowed range');
  }
}

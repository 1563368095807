<div class="page-content room-page fadeBody" *ngIf="state">
  <div class="container">
    <a class="desktop-show mobile-hide applicationColor" style="position: absolute; font-size: 20px" (click)="goBack()">
      <i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >
    <a
      class="desktop-show mobile-hide applicationColor"
      (click)="onMoveToNextClick()"
      *ngIf="onlySeating || seatingSelected || canContinue"
      style="font-size: 20px; float: right">
      {{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>

    <p
      *ngIf="(canContinue || seatingSelected || onlySeating) && state"
      class="desktop-show mobile-hide"
      style="text-align: center; font-size: 40px; font-weight: 500; margin-left: 8.5%">
      {{ state.EventLabel | t | async }}
    </p>
    <p
      *ngIf="!canContinue && !onlySeating && !seatingSelected && state"
      class="desktop-show mobile-hide"
      style="text-align: center; font-size: 40px; font-weight: 500">
      {{ state.EventLabel | t | async }}
    </p>
    <p *ngIf="state" class="desktop-hide mobile-show" style="text-align: center; font-size: 40px; font-weight: 500">
      {{ state.EventLabel | t | async }}
    </p>
    <h2 style="text-align: center; margin-top: 20px" *ngIf="!onlySeating">
      <span class="v-middle"
        >{{ 'SEA_DescriptionStart' | t | async }} {{ state.EventLabel | t | async }}
        {{ 'SEA_DescriptionEnd' | t | async }}</span
      >
    </h2>

    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />
  </div>

  <div class="container">
    <div class="row">
      <div class="col l4 m4 s12 no-gutters" *ngIf="startAt">
        <div class="seating-standalone__calendar">
          <seating-date-calendar
            [selectedDate$]="chosenDate$"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [highlightDates]="highlightedDates"
            [availableDates]="availableDates"
            [startAt]="startAt"
            [update]="updateCalendar"
            (dateClicked)="calendarOutput($event)">
          </seating-date-calendar>
          <span
            class="seating-standalone__calendar-reset dynamicTextColor"
            style="background-color: var(--primary-application-color)"
            (click)="resetDate()"
            >{{ 'SEA_ResetCalendar' | t | async }}
            <span style="text-transform: lowercase; margin-left: 3px"> {{ state.EventLabel | t | async }}</span></span
          >
        </div>
      </div>
      <div [ngClass]="[startAt ? 'col l8 m8 s12' : 'col l12 m12 s12']">
        <div *ngFor="let content of seatingWrapper">
          <app-expandable-media-list
            [data]="content.data"
            [selected]="selectedSeating"
            [usePageContainer]="!startAt"
            [insideDetails]="!startAt">
            <ng-template #itemTemplate let-room>
              <div
                class="card my-0 d-flex flex-column {{ room.item.selected ? 'active' : '' }} grow"
                (click)="onSeatingClick($event, room.item)"
                #seatingItem
                [id]="'seatingItem-' + room.item.seatingPlanId">
                <div class="flex-grow-1">
                  <div class="card-image">
                    <div class="centered-image">
                      <img *ngIf="room.item.image" [src]="room.item.image" draggable="false" />
                      <div
                        *ngIf="!room.item.image"
                        style="
                          width: 100%;
                          background-color: gray;
                          height: 100%;
                          text-align: center;
                          line-height: 220px;
                        ">
                        <div
                          style="
                            font-size: 100px;
                            display: inline-block;
                            vertical-align: middle;
                            line-height: normal;
                            margin-right: 70px;
                          ">
                          <span class="fa fa-camera" style="font-size: 100px; color: #999999"></span>
                          <h2 style="margin-left: 70px; color: #999999">
                            {{ 'RPA_Image' | t | async }}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="overel-container">
                      <div class="overel overel-right">
                        <button style="pointer-events: none" type="button" class="btn btn-primary btn-mini">
                          {{ 'RPA_More' | t | async }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-header">
                    <h3 class="text-primary">{{ room.item.name }}</h3>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #selectedItemTemplate let-item>
              <div class="fadeBody" style="padding: 20px" #seatingSelectedItem>
                <div class="row">
                  <div class="col m12">
                    <h4 *ngIf="item.long" [innerHTML]="item.long" class="section"></h4>
                  </div>
                </div>
                <ng-template ngFor let-date [ngForOf]="item.seatingTimesUiModel">
                  <div class="row">
                    <div class="col m3 s6">
                      <h2 *ngIf="!date.multipleDays">
                        {{ 'SEA_Date' | t | async }}
                      </h2>
                      <p *ngIf="date.day && date.month && date.year" style="color: green; margin-top: 20px">
                        {{ date.day }}. {{ date.month | t | async }}
                        {{ date.year }}
                      </p>
                      <p *ngIf="!date.multipleDays" style="font-size: 12px">
                        {{ date.name }}
                      </p>
                      <p *ngIf="date.multipleDays" style="font-size: 14px; margin-top: 15%">
                        {{ date.name }}
                      </p>
                      <hr />
                    </div>
                    <div class="col m4 s6">
                      <h2 *ngIf="!date.multipleDays">
                        {{ 'SEA_Time' | t | async }}
                      </h2>
                      <div class="with-toggle focusable">
                        <i class="toggle fas fa-caret-down" style="color: black"></i>
                        <select
                          (change)="onSeatingDateChange($event.target.value, date)"
                          style="font-size: 17px; min-height: 40px">
                          <option *ngFor="let count of date.clock" [ngValue]="count" [selected]="count.selectedTime">
                            {{ count.time }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="col m5 s12"
                      *ngIf="{
                        selected: date.SelectedseatId && date.SelectedName === date.name
                      } as status">
                      <h2 *ngIf="!date.multipleDays">
                        {{ 'SEA_Quantity' | t | async }} / {{ 'COM_Price' | t | async }}
                      </h2>
                      <div
                        class="with-toggle focusable"
                        [ngStyle]="{ 'background-color': status.selected ? 'rgb(186, 255, 186)' : 'white' }">
                        <i class="toggle fas fa-caret-down" style="color: black"></i>
                        <select
                          [(ngModel)]="date.ticketSelected"
                          style="font-size: 17px; min-height: 40px"
                          (ngModelChange)="
                            date.SelectedseatId && date.SelectedName === date.name
                              ? onSelectedRateClick(date)
                              : onRateSelect(item, date)
                          ">
                          <option *ngFor="let count of date.tickets" [value]="count">
                            <span *ngIf="count !== 0">{{ count }} x ({{ count * date.price | price | async }})</span>
                            <span *ngIf="count === 0">{{ 'RPA_Select' | t | async }}</span>
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </ng-template>
          </app-expandable-media-list>
        </div>
      </div>
    </div>
  </div>
  <div class="container text-center" *ngIf="onlySeating || seatingSelected || canContinue" style="margin-bottom: 100px">
    <div class="section">
      <button type="button" class="btn btn-primary with-shadow" (click)="onMoveToNextClick()">
        {{ 'COM_Continue' | t | async }}
      </button>
    </div>
  </div>
</div>

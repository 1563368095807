import { Pipe, PipeTransform } from '@angular/core';

import { map } from 'rxjs/operators';
import { LocaleService } from 'src/app/core';
import { GuestType, RoomGuests } from 'src/app/helpers';

@Pipe({ name: 'guests' })
export class GuestsPipe implements PipeTransform {
  constructor(private ls: LocaleService) {}

  transform(guests: RoomGuests | undefined) {
    return this.ls.translations$.pipe(
      map((translations) => {
        if (guests instanceof RoomGuests) {
          const adults = guests.get(GuestType.Adult);
          let text = `${adults} ${adults === 1 ? translations.COM_Adult : translations.COM_Guest_10}`;
          const seniors = guests.get(GuestType.Senior);
          if (seniors > 0) {
            text += `, ${seniors} ${translations.COM_Guest_11}`;
          }
          const children = guests.get(GuestType.Child);
          if (children > 0) {
            text += `, ${children} ${children === 1 ? translations.COM_Child : translations.COM_Guest_8}`;
          }
          const infants = guests.get(GuestType.Infant);
          if (infants > 0) {
            text += `, ${infants} ${translations.COM_Guest_7}`;
          }
          const teens = guests.get(GuestType.UnknownType1);
          if (teens > 0) {
            text += `, ${teens} ${translations.COM_Guest_9}`;
          }
          return text;
        }
        return '';
      }),
    );
  }
}

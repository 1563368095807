import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export const hasUpperValidator = (): ValidatorFn => (control: AbstractControl) => {
  const appHasUpper = /[A-Z]/.test(control.value);
  return appHasUpper ? null : { appHasUpper: { value: control.value } };
};

@Directive({
  selector: '[appHasUpper]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: HasUpperValidatorDirective,
      multi: true,
    },
  ],
})
export class HasUpperValidatorDirective implements Validator {
  validate(control: AbstractControl) {
    return hasUpperValidator()(control);
  }
}

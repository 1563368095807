<div class="page-content room-page fadeBody" *ngIf="conferenceRoomInfo">
  <div class="container">
    <a class="desktop-show mobile-hide applicationColor" style="position: absolute; font-size: 20px" (click)="goBack()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >

    <a (click)="onMoveToNextClick()" class="desktop-show mobile-hide" style="font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>

    <p
      class="desktop-show mobile-hide"
      style="text-align: center; font-size: 40px; font-weight: 500; margin-left: 8.5%">
      {{ conferenceRoomInfo.RoomTypeName }}
    </p>
    <p class="desktop-hide mobile-show" style="text-align: center; font-size: 40px; font-weight: 500">
      {{ conferenceRoomInfo.RoomTypeName }}
    </p>

    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />
  </div>

  <div class="container">
    <div class="row">
      <div class="col m6">
        <div style="width: 100%; height: 350px">
          <img *ngIf="selectedImage" style="width: 100%; height: 350px" src="{{ selectedImage }}" />
          <div
            *ngIf="!selectedImage"
            style="width: 100%; background-color: gray; height: 100%; text-align: center; line-height: 220px">
            <div
              style="
                font-size: 100px;
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                margin-right: 70px;
              ">
              <span class="fa fa-camera" style="font-size: 100px; color: #999999"></span>
              <h2 style="margin-left: 70px; color: #999999">
                {{ 'RPA_Image' | t | async }}
              </h2>
            </div>
          </div>
        </div>
        <div style="display: inline-flex">
          <div
            *ngIf="conferenceRoomInfo.ImageOfRoom1"
            style="width: 120px; height: 80px; margin-top: 10px; cursor: pointer"
            (click)="newImageClick(conferenceRoomInfo.ImageOfRoom1)">
            <img src="{{ conferenceRoomInfo.ImageOfRoom1 }}" />
          </div>
          <div
            *ngIf="conferenceRoomInfo.ImageOfRoom2"
            style="width: 120px; height: 80px; margin-top: 10px; margin-left: 10px; cursor: pointer"
            class="grow"
            (click)="newImageClick(conferenceRoomInfo.ImageOfRoom2)">
            <img src="{{ conferenceRoomInfo.ImageOfRoom2 }}" />
          </div>
          <div
            *ngIf="conferenceRoomInfo.ImageOfRoom3"
            style="width: 120px; height: 80px; margin-top: 10px; margin-left: 10px; cursor: pointer"
            (click)="newImageClick(conferenceRoomInfo.ImageOfRoom3)">
            <img src="{{ conferenceRoomInfo.ImageOfRoom3 }}" />
          </div>
        </div>

        <div style="margin-top: 50px">
          <h1>Vælg Bordopstilling</h1>
          <hr style="border-color: black; width: 100%" />
          <div
            style="margin-top: 20px; display: inline-flex; cursor: pointer; margin-right: 10px"
            *ngFor="let table of conferenceRoomInfo.ECommerceTableSetups"
            class="grow">
            <div style="width: 7rem; height: 7rem; text-align: center">
              <img style="height: 80%; width: 80%" [src]="table.Image" />
              <h4>{{ table.Description }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col m6">
        <div
          style="
            width: 100%;
            height: 350px;
            background-color: var(--primary-application-color);
            padding: 20px;
            color: white;
            overflow: auto;
            overflow-x: hidden;
          ">
          <h1 [innerHTML]="conferenceRoomInfo.ShortDescription"></h1>
          <hr style="margin-top: 10px; border-width: 2px" />
          <h4 style="padding-top: 15px" [innerHTML]="conferenceRoomInfo.LongDescription"></h4>
        </div>
        <div style="margin-top: 50px">
          <h1>Vælg Pakke</h1>
          <hr style="border-color: black; width: 100%" />
          <div style="margin-top: 20px; width: 100%; height: 450px">
            <div
              style="
                width: 100%;
                height: 100px;
                background-color: var(--primary-application-color);
                display: inline-flex;
                padding-left: 20px;
                padding-top: 35px;
                color: white;
              ">
              <div style="width: 15%">
                <h1>Dag</h1>
              </div>
              <div style="width: 50%">
                <h1>Pakke</h1>
              </div>
              <div style="width: 20%">
                <h1>Pris</h1>
              </div>
            </div>
            <div style="width: 100%; overflow: auto; height: 100%">
              <div
                style="
                  width: 100%;
                  height: 100px;
                  background-color: white;
                  display: inline-flex;
                  padding: 20px;
                  padding-top: 35px;
                  color: black;
                ">
                <div style="width: 15%">
                  <h1>29 Jun</h1>
                </div>
                <div style="width: 50%">
                  <p>Lokale leje uden forplejning</p>
                </div>
                <div style="width: 20%">
                  <p>1.000 DKK</p>
                </div>
                <div style="width: 15%; margin-bottom: 35px">
                  <a class="form-control">Book</a>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 100px;
                  background-color: white;
                  display: inline-flex;
                  padding: 20px;
                  padding-top: 35px;
                  color: black;
                ">
                <div style="width: 15%">
                  <h1>29 Jun</h1>
                </div>
                <div style="width: 50%">
                  <p>Lokale leje med konference pakke</p>
                </div>
                <div style="width: 20%">
                  <p>1.000 DKK</p>
                </div>
                <div style="width: 15%; margin-bottom: 35px">
                  <a class="form-control">Book</a>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 100px;
                  background-color: white;
                  display: inline-flex;
                  padding: 20px;
                  padding-top: 35px;
                  color: black;
                ">
                <div style="width: 15%">
                  <h1>30 Jun</h1>
                </div>
                <div style="width: 50%">
                  <p>Lokale leje uden forplejning</p>
                </div>
                <div style="width: 20%">
                  <p>1.000 DKK</p>
                </div>
                <div style="width: 15%; margin-bottom: 35px">
                  <a class="form-control">Book</a>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 100px;
                  background-color: white;
                  display: inline-flex;
                  padding: 20px;
                  padding-top: 35px;
                  color: black;
                ">
                <div style="width: 15%">
                  <h1>30 Jun</h1>
                </div>
                <div style="width: 50%">
                  <p>Lokale leje med konference pakke</p>
                </div>
                <div style="width: 20%">
                  <p>1.000 DKK</p>
                </div>
                <div style="width: 15%; margin-bottom: 35px">
                  <a class="form-control">Book</a>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 100px;
                  background-color: white;
                  display: inline-flex;
                  padding: 20px;
                  padding-top: 35px;
                  color: black;
                ">
                <div style="width: 15%">
                  <h1>1 Jul</h1>
                </div>
                <div style="width: 50%">
                  <p>Lokale leje med konference pakke</p>
                </div>
                <div style="width: 20%">
                  <p>1.000 DKK</p>
                </div>
                <div style="width: 15%; margin-bottom: 35px">
                  <a class="form-control">Book</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dialog-calendar" *ngIf="showCalendar && calendarDates">
  <div class="month">
    <ul>
      <li
        class="fas fa-arrow-left prev"
        style="margin-top: 15px; margin-right: 40px; cursor: pointer"
        (click)="prevMonth()"></li>
      <li
        class="fas fa-arrow-right next"
        style="margin-top: 15px; margin-left: 40px; cursor: pointer"
        (click)="nextMonth()"></li>
      <li>
        <p>{{ calendarDates.currentMonth | t | async }}</p>
        <span style="font-size: 18px">{{ calendarDates.currentYear }}</span>
      </li>
    </ul>
  </div>
  <ul class="weekdays">
    <li>{{ 'CAL_Mon' | t | async }}</li>
    <li>{{ 'CAL_Tirs' | t | async }}</li>
    <li>{{ 'CAL_Ons' | t | async }}</li>
    <li>{{ 'CAL_Tors' | t | async }}</li>
    <li>{{ 'CAL_Fre' | t | async }}</li>
    <li>{{ 'CAL_Lor' | t | async }}</li>
    <li>{{ 'CAL_Sun' | t | async }}</li>
  </ul>
  <ul class="days">
    <app-calendar-tile
      *ngFor="let item of calendarDates.days"
      (clickedDay)="clickedDate($event)"
      [date]="item"
      [componentRequestor]="'conference'"></app-calendar-tile>
  </ul>
  <div style="display: flex; background-color: #eeeeee; padding: 10px">
    <div style="width: 25px; height: 25px; background-color: #f87e7d; margin-right: 5px"></div>
    <p>{{ 'CAL_Occupied' | t | async }}</p>
    <div style="width: 25px; height: 25px; background-color: white; margin-left: 10px; margin-right: 5px"></div>
    <p>{{ 'CAL_Available' | t | async }}</p>
    <div style="width: 25px; height: 25px; background-color: #a9a9a9; margin-left: 10px; margin-right: 5px"></div>
    <p>{{ 'CAL_NotPossible' | t | async }}</p>
  </div>
</div>

<div class="popup-selected-item" #popupItem>
  <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
</div>
<div
  class="popup"
  [ngClass]="{
    show: shouldShow,
    'align-left': align === 'left',
    'align-right': align === 'right'
  }"
  [style.marginLeft]="left + 'px'">
  <div class="overlay" (click)="onOverlayClick()"></div>
  <div class="popup-content" #popupContent>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateHelper } from 'src/app/helpers';
import {
  ApplicationStateService,
  BasketService,
  HotelCodeString,
  HotelService,
  Loader,
  LocaleService,
  PromotionService,
} from '../../core';
import { NavigationHelper } from '../../helpers/navigation-helper';
import { DesignConfiguration } from '../../shared/design/design-configuration';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
})
export class LandingPageComponent implements OnInit, OnDestroy {
  tiles: LandingPageModel[] | undefined;
  paramsSubscription: Subscription | undefined;
  picker: HotelPicker[] | undefined;

  constructor(
    private navigationHelper: NavigationHelper,
    private applicationState: ApplicationStateService,
    private loader: Loader,
    private locale: LocaleService,
    private basketService: BasketService,
    private activatedRoute: ActivatedRoute,
    private hotelService: HotelService,
    private designconfig: DesignConfiguration,
    private promotionService: PromotionService,
  ) {}

  async ngOnInit() {
    this.navigationHelper.reset();

    this.basketService.clear();
    this.basketService.basketBadgeNumberSet = '0';

    this.catchUrlParams();

    await this.buildHotelPickerData();
    await this.designconfig.addPageBackground();
    await this.buildLandingpageTiles();
  }

  ngOnDestroy(): void {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  catchUrlParams() {
    this.paramsSubscription = this.activatedRoute.params.subscribe((params) => {
      const hotelCode = params['HotelCode'];
      if (hotelCode) {
        this.applicationState.CurrentHotelCode = hotelCode;
      }
    });
  }

  async buildLandingpageTiles() {
    await this.loader.using(
      async () => {
        this.tiles = [] as LandingPageModel[];
        const res = await this.hotelService.GetECommerceDepartmentInformation(
          this.applicationState.CurrentHotelCode,
          await this.locale.getLocale(),
        );

        if (res && res.Settings.UseSpectraOnlineBooking) {
          const rooms = {
            tileID: moduleType.bookRooms,
            active: true,
            lable: 'COM_Rooms',
            sortIndex: res.Settings.SortIndexSpectraOnlineBooking,
          } as LandingPageModel;
          const packages = {
            tileID: moduleType.bookPackages,
            active: true,
            lable: 'COM_Package',
            sortIndex: res.Settings.SortIndexSpectraOnlineBooking,
          } as LandingPageModel;

          this.tiles.push(rooms);
          this.tiles.push(packages);
        }

        if (res.Settings.UseSeating) {
          // Add seating tile if any is available and if seatings are active on the platform
          const anySeatings = await this.hotelService.IsSeatingsAvailable(this.applicationState.CurrentHotelCode);
          if (anySeatings) {
            const seatings = {
              tileID: moduleType.seatings,
              active: true,
              lable: this.applicationState.EventLabel,
              sortIndex: res.Settings.SortIndexSeating,
            } as LandingPageModel;
            this.tiles.push(seatings);
          }
        }

        if (res.Settings.UseTableReservation) {
          // Add seating tile if any is available and if seatings are active on the platform
          const anySeatings = await this.hotelService.IsTablesAvailable(this.applicationState.CurrentHotelCode);
          if (anySeatings) {
            const tableReservation = {
              tileID: moduleType.tableReservation,
              active: true,
              lable: 'COM_TableReservation',
              sortIndex: res.Settings.SortIndexTableReservation,
            } as LandingPageModel;
            this.tiles.push(tableReservation);
          }
        }

        if (res && res.Settings.UseGiftcard) {
          const giftCard = {
            tileID: moduleType.giftcard,
            active: true,
            lable: 'SFO_BookGiftcard',
            sortIndex: res.Settings.SortIndexGiftCard,
          } as LandingPageModel;
          this.tiles.push(giftCard);
        }

        // if (res && res.Settings.UseConferenceModule) {
        //  const conference = { tileID: moduleType.bookConference, active: true, lable: 'COM_Conference' } as LandingPageModel;
        //  this.tiles.push(conference);
        // }

        this.tiles.sort((a, b) => a.sortIndex - b.sortIndex);
      },
      'LOA_HotelInformation',
      false,
    );
  }

  async navigate(tile: LandingPageModel) {
    document.body.classList.remove('addBackGround');
    document.body.classList.remove('addBackGroundWithAnimation');
    document.body.style.backgroundImage = '';

    switch (tile.tileID) {
      case moduleType.giftcard: {
        await this.navigationHelper.continue('/landing-page', '/giftcard', {
          queryParams: { HotelCode: this.applicationState.CurrentHotelCode },
        });
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        break;
      }
      // case moduleType.bookConference: {
      //  await this.navigationHelper.continue('/landing-page', '/conference-search');
      //  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      //  break;
      // }
      case moduleType.bookPackages: {
        await this.navigationHelper.continue('/landing-page', '/search', {
          queryParams: {
            HotelCode: this.applicationState.CurrentHotelCode,
            BookingFlow: 2,
            Single: true,
          },
        });
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        break;
      }
      case moduleType.bookRooms: {
        await this.navigationHelper.continue('/landing-page', '/search', {
          queryParams: {
            HotelCode: this.applicationState.CurrentHotelCode,
            BookingFlow: 1,
            Single: true,
          },
        });
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        break;
      }
      case moduleType.seatings: {
        await this.navigationHelper.continue('/landing-page', '/seating/1', {
          queryParams: {
            HotelCode: this.applicationState.CurrentHotelCode,
            ArrivalDate: DateHelper.toServerDateFormatString(new Date()),
            LengthOfStay: 30,
          },
        });
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        break;
      }
      case moduleType.tableReservation: {
        await this.navigationHelper.continue('/landing-page', '/guests');
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        break;
      }
    }
  }

  async buildHotelPickerData() {
    await this.loader.using(
      async () => {
        const hotels = await this.hotelService.GetECommerceDepartmentsInformation();

        this.picker = [] as HotelPicker[];
        for (const hotel of hotels) {
          let selected = false;
          if (!this.applicationState.CurrentHotelCode) {
            this.applicationState.CurrentHotelCode = hotel.Code;
          }

          if (hotel.Code === this.applicationState.CurrentHotelCode) {
            selected = true;
          }

          const res = {
            hotelImage: hotel.Image === null ? '' : hotel.Image,
            name: hotel.Name,
            selected,
            hotelCode: hotel.Code,
          } as HotelPicker;
          this.picker.push(res);
        }
      },
      'LOA_HotelInformation',
      false,
    );
  }

  async chooseHotel(model: HotelPicker) {
    if (model.hotelCode === this.applicationState.CurrentHotelCode) {
      return;
    }

    this.promotionService.promocode$.next(undefined);

    await this.loader.using(
      async () => {
        this.applicationState.CurrentHotelCode = model.hotelCode as HotelCodeString;
        await this.designconfig.applyTheme();
        await this.designconfig.addPageBackground();
        this.deselectAllHotels();
        model.selected = true;
        await this.buildLandingpageTiles();
        await this.designconfig.applyHotelChange(this.applicationState.CurrentHotelCode);
      },
      'LOA_HotelInformation',
      true,
    );
  }

  deselectAllHotels() {
    if (this.picker !== undefined) {
      this.picker
        .filter((x) => x.selected)
        .forEach((x) => {
          x.selected = false;
        });
    }
  }
}

enum moduleType {
  giftcard = '21be5c1e-3b0c-4d90-b0a1-62d4ba9733a0',
  bookRooms = 'd850acd6-cde8-41a9-8a86-3cf46417e74a',
  bookPackages = '04a2116c-7dcd-45aa-b4ed-ec40aeaaae55',
  tableReservation = '0a3e2328-af86-4ac8-b66d-1e8ec0e888d6',
  bookConference = 'c449fd68-481a-47c4-a381-271345f9cb32',
  seatings = 'cf613ac1-73eb-47cc-9d74-83d15e18aba9',
}

export interface LandingPageModel {
  tileID: moduleType;
  active: boolean;
  lable: string;
  sortIndex: number;
}

export interface HotelPicker {
  hotelImage: string;
  name: string;
  selected: boolean;
  hotelCode: string;
}

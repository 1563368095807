<div class="page-content room-page fadeBody">
  <div class="container">
    <a
      class="desktop-show mobile-hide applicationColor"
      style="position: absolute; font-size: 20px"
      (click)="onNavigateBack()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >
    <a
      class="desktop-show mobile-hide applicationColor"
      *ngIf="canContinue"
      style="font-size: 20px; float: right"
      (click)="onNavigateToCheckout()"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>
    <a
      class="desktop-show mobile-hide"
      *ngIf="!canContinue"
      style="pointer-events: none; color: gray; font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>

    <p
      *ngIf="state"
      class="desktop-show mobile-hide"
      style="text-align: center; font-size: 40px; font-weight: 500; margin-left: 8.5%">
      {{ state.EventLabel | t | async }}
    </p>

    <hr
      class="mobile-hide"
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />
  </div>

  <div class="fadeBody seating-standalone-page">
    <div class="container">
      <div class="row" *ngIf="data$ | async as data">
        <div class="col l4 m4 s12 no-gutters">
          <div class="seating-standalone-page__calendar">
            <seating-date-calendar
              [selectedDate$]="chosenDate$"
              [minDate]="data.minDate"
              [maxDate]="data.maxDate"
              [availableDates]="data.availableDates"
              [highlightDates]="data.seatingUi.highlightedDates"></seating-date-calendar>
          </div>
          <div class="seating-standalone-page__sidebar mobile-hide" *ngIf="priceInformation$ | async as basketData">
            <div class="seating-standalone-page__total">
              {{ basketData.price | price | async }}
              <button
                class="btn btn-primary-outline with-shadow"
                [disabled]="!canContinue"
                (click)="onNavigateToCheckout()">
                {{ 'BIN_BasketGoToCheckout' | t | async }}
              </button>
            </div>
          </div>
        </div>
        <div class="col l8 m8 s12 no-gutters" *ngIf="data.seatingUi">
          <div class="seating-standalone-page__content">
            <h1 class="seating-standalone-page__content-header">{{ data.seatingUi.header }}</h1>
            <div class="seating-standalone-page__content-divider"></div>
            <div class="seating-standalone-page__content-image" *ngIf="data.seatingUi.image">
              <div
                class="seating-standalone-page__content-image-background"
                [ngStyle]="{ 'background-image': 'url(' + data.seatingUi.image + ')' }"></div>
              <img class="seating-standalone-page__content-image-src" [src]="data.seatingUi.image" alt="" />
            </div>
            <app-slide-down [showAnimation]="showAnimation" [content]="data.seatingUi.description">
              <div
                [innerHTML]="data.seatingUi.description"
                appMaxHeight
                (isMaxHeight)="setShowAnimation($event)"
                maxHeight="200"
                style="color: var(--primary-application-color)"></div>
            </app-slide-down>
            <ng-container *ngFor="let seatItem of data.seatingUi.seatItems">
              <div class="row">
                <div class="col l12 m12 s12">
                  <div class="body-seating-title">
                    {{ seatItem.Description }}
                  </div>
                </div>
              </div>
              <div class="seating-standalone-page-wrapper">
                <div class="row">
                  <div class="col l4 m4 s4">
                    <select
                      class="seating-standalone-page__rate-select"
                      (change)="updateTimeBasket($event, data.seatingUi, seatItem)"
                      [(ngModel)]="seatItem.chosenTimeslot"
                      name="time">
                      <option *ngFor="let timeSlot of seatItem.timeSlots" [value]="timeSlot.toISOString()">
                        {{ timeSlot.format('HH:mm') }}
                      </option>
                    </select>
                  </div>
                  <div class="col l4 m4 s4">
                    <div class="seating-dialog-add-button-container">
                      <button
                        class="seating-dialog-add-button seating-dialog-add-button--left"
                        (click)="updateAmountBasket('decrease', data.seatingUi, seatItem)">
                        <i class="fas fa-minus applicationColor"></i>
                      </button>
                      <span class="dialog-add-guest-count"
                        >{{ seatItem.chosenTicketAmount }} / {{ seatItem.maxTickets.length }}</span
                      >
                      <button
                        class="seating-dialog-add-button seating-dialog-add-button--right"
                        (click)="updateAmountBasket('increase', data.seatingUi, seatItem)">
                        <i class="fas fa-plus applicationColor"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col l4 m4 s4">
                    <div style="text-align: right; margin-top: 5%">+ {{ seatItem.price | price | async }}</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

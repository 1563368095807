import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { ConfigService } from '../config';
import { ReservationType } from '../payment';
import { TrackingClient } from './tracking-client.interface';

@Injectable()
export class SpectraAdsClientService implements TrackingClient {
  constructor(private log: LogService, private configService: ConfigService) {}

  async revenue(reservation: ReservationType) {
    const config = await this.configService.getGaParams();
    if (config.UseSpectraAdwordsScript) {
      const url = `//www.googleadservices.com/pagead/conversion/1011102417/?value=${reservation.total}&label=YnYbCI_moAcQ0eWQ4gM&guid=ON&script=0`;
      ConfigService.AddPixel(url);
      this.log.debug(`SpectraAdsService revenue() with url: ${url}`);
    } else {
      this.log.debug('SpectraAdsService revenue() usage of Spectra Adword script is disabled in BOW');
    }
  }
}

import { Messages } from './index';

export const messages: Messages = {
  APA_CompanyLogin: `Innritan til fyritøkur`,
  APA_Logout: `Log ud`,
  APA_Welcome: `Vælkomin`,
  APA_ShowPromo: `Avsláttarfrámerki`,
  APA_PromoLabel: `Upplýs avsláttarkotu`,
  APA_Promo: `Avsláttarkotan`,
  APA_PromoSubmit: `Nýt`,
  APA_PromoChange: `Rætta`,
  APA_ActivateHead: `Aktiver dit login`,
  APA_ActivateDesc: `Angiv adgangskode for at aktivere login for`,
  APA_Password: `Adgangskode`,
  APA_ConfirmPassword: `Váttað passorð`,
  APA_PasswordHasUpper: `Adgangskode skal indeholde mindst et stort bogstav (A-Z)`,
  APA_PasswordHasLower: `Adgangskode skal indeholde mindst et lille bogstav (a-z)`,
  APA_PasswordHasNumber: `Adgangskode skal indeholde mindst et tal (0-9)`,
  APA_PasswordLength8: `Adgangskode skal indeholde mindst 8 karakterer`,
  APA_ConfirmPasswordEqualTo: `Skeivt loyniorð`,
  APA_Activate: `Virkja`,
  APA_CustomerNotFound: `Kundin er ikki funnin`,
  APA_ResetBasket: `Alt vil blive fjernet fra kurven. Er du sikker?`,
  APA_Address: `Bústaður`,
  APA_Contact: `Samband`,
  APA_VatNumber: `CVR:`,
  APA_PromoMessageStart: `Frámerki`,
  APA_ValidTime: `Galdandi tíðarskeið`,
  APA_PromoMessageEnd: `Frámerki er galdandi á teimum vørum ið eru merktar við prosentvísum avslátturi`,
  APA_AQCDecription: `Aldersbeskrivelse`,
  APA_Year: `ár`,
  APA_Book: `Book`,

  CPA_NothingAvailable_EmptyText: `Vinaliga vel ein annan dag,`,
  CPA_NothingAvailable: `Der er ingen ledige værelser, der opfylder dine kriterier`,
  CPA_SoldOut: `Útselt`,
  CPA_CustomerInvalid: `Ukendt kundeprofil`,
  CPA_PromoInvalid: `Frámerkið er óvirki`,
  CPA_PromoBetween: `Den indtastede rabatkode er kun gyldig hvis opholdet er i perioden:`,

  RPA_More: `Sí meira`,
  RPA_Select: `Vel`,
  RPA_Selected: `Valt`,
  RPA_From: `Úr`,
  RPA_Image: `No image`,
  RPA_Description: `Beskrivelse`,
  RPA_ChooseRoom: `Vel Kamar`,
  RPA_ChoosePackage: `Vel bílegging`,
  RPA_ChooseCabin: `Vælg værelse`,
  RPA_ChooseHouse: `Vælg værelse`,
  RPA_ChooseUnit: `Vælg værelse`,
  RPA_BackRate: `Vælg en anden pakke`,
  RPA_MaxPersons: `Max gestir á kamari`,
  RPA_ChooseYourRoom: `Vel Kamar`,
  RPA_ChooseYourCabin: `Vælg værelse`,
  RPA_ChooseYourHouse: `Vælg Værelse`,
  RPA_ChooseYourUnit: `Vælg værelse`,
  RPA_ChooseYourPackage: `Vel bílegging`,

  APA_Services: `Tænastur`,
  APA_Add: `LEGG AFTURAT`,
  APA_Added: `Tænastur lagdar afturat`,
  APA_Remove: `Tak burtur`,
  APA_ContentDialog: `Vel tal av persónum og døgum tú ynskir nevndu tænastu dagarnar millum`,
  APA_ChooseYourAddOns: `Vel eyka keyp`,
  APA_TotalPrice: `kostnaður íalt`,
  APA_TotalInBasket: `kpl, yhteensä`,
  APA_TotalOf: `Vel stk. á`,

  CUP_Header: `Gestur upplýsing`,
  CUP_Accept: `Góðkenn`,
  CUP_FName: `Fornavn`,
  CUP_FNameReq: `Fornavn skal upplýsast`,
  CUP_FPhoneReq: `Mobil skal upplýsast`,
  CUP_LName: `Eftirnavn`,
  CUP_LNameReq: `Eftirnavn er kravt`,
  CUP_Address: `Bústaður`,
  CUP_AddressReq: `Adressa skal upplýsast`,
  CUP_ZipCode: `Postnummar`,
  CUP_ZipCodeReq: `Postnummar skal upplýsast`,
  CUP_City: `Bygd`,
  CUP_CityReq: `Býur skal upplýsast`,
  CUP_Contry: `Land`,
  CUP_ContryReq: `Land er påkrævet`,
  CUP_Phone: `Fartelefon nummar`,
  CUP_PhoneReq: `Fartelefon skal upplýsast`,
  CUP_Email: `Teldupostur`,
  CUP_EmailRepeat: `Teldupostur`,
  CUP_EmailReq: `Teldupostur skal upplýsast`,
  CUP_EmailInvalid: `Teldupostur ikki rættur`,
  CUP_Comment: `Viðmerkingar`,
  CUP_NeedSubscribe: `Eg vil fegin fáa tíðindi við telduposti`,
  CUP_AcceptTerms: `Eg havi lisið og góðkenni treytirnar`,
  CUP_BookNow: `Book`,
  CUP_CancelPolicyHeader: `Ógildingartrygging`,
  CUP_CancelPolicyDesc: `Tú hevur møguleika at ógilda bíleggingina um tú ikki fært komið. Fleiri upplýsningar viðv. ógildingartrygging eru í bíleggingartreytunum.`,
  CUP_CancelPolicyLabel: `Ja, eg vil fegin keypa ógildingartrygging fyri`,
  CUP_GuestInfo: `Kunning um gest`,
  CUP_BookingOverview: `Bíleggingar yvirlit`,
  CUP_PayWithGiftcard: `Fyri at gjalda við gávukorti`,
  CUP_PayWithGiftcardClick: `Trýst her`,
  CUP_PayWithGiftcardDescription: `Rita inn gávukortnummar`,
  CUP_PayWithGiftcardError: `Gavekort er ikke aktivt eller kunne ikke findes i systemet.`,
  CUP_PayWithGiftcardStatus: `Gavekort aktivt`,
  CUP_PayWithGiftcardDiscount: `Gavekort beløb`,
  CUP_PayWithGiftcardHeader: `Gávukort`,
  CUP_PayWithGiftcardInformation: `Ynskir tü at nyta Gávukort`,
  CUP_PayWithGiftcardPlaceholder: `Gavekortskode`,
  CUP_PayWithGiftcardRedeem: `Indløs`,
  CUP_BookingOverviewToPay: `At betale`,

  PAL_GoToPayment: `Go to payment`,
  PAL_PaymentComplete: `Leinkjan er útgingin. Vinarliga set teg í samband við`,
  PAL_PaymentNotAvailable: `Payment on this reservation is not available`,

  PAR_Payment: `Gjalding`,
  PAR_SeekAuthorization: `Tínir kreditkortupplýsingar verða brúktir til gjald, ella verða settir sum trygd fyri keypið.`,
  PAR_TotalLabel: `Samlaður kostnaður`,
  PAR_TotalPayLabel: `Kostnaður at gjalda`,
  PAR_OBSLabel: `OBS: bliv på siden indtil kvitteringsside vises med et reservationsnummer`,
  PAR_OBSLabelGiftcard: `OBS: bliv på siden indtil kvitteringsside vises med et ordrenummer`,
  PAR_FailedToPay: `Villa við gjalding. Vinaliga kanna kortnummarið.`,
  PAR_CardInvalid: `Kortnummarið er ógildigt`,
  PAR_CardUnknown: `Ókent slag av korti`,
  PAR_CardMonthInvalid: `Mánaðurin er ógildigur`,
  PAR_CardYearInvalid: `Árið er ógildigt`,
  PAR_CardCVCInvalid: `CVV-kotan er ógildig`,
  PAR_FieldRequired: `Hesin rúturin er kravdur`,
  PAR_Cancel: `Ógilda`,
  PAR_Pay: `Gjald`,
  PAR_PaymentMethod: `Payment Methods`,
  PAR_Cardnumber: `Kortnummer`,
  PAR_MonthPlaceholder: `MM`,
  PAR_YearPlaceholder: `ÅÅ`,

  PFP_Header: `Ups! ein villa er íkomin`,
  PFP_Info: `Bíleggingin er ikki gjøgnumførd. Vinaliga royn aftur við at trýsta á knøttin niðanfyri. Eingin pengar eru tiknir av kortinum.`,
  PFP_TryAgain: `Royn aftur`,

  REP_Header: `Takk fyri bíleggingina,`,
  REP_Comment: `herunder finder du din kvittering for din ordre.`,
  REP_DateRooms: `Ferðadagar`,
  REP_Return: `Aftur til heimasíðuna`,
  REP_OrderId: `Bíleggingarnummar`,
  REP_ReservationId: `Bíleggingarnummar`,
  REP_CustomerInfo: `Personlige oplysninger`,

  REP_Name: `Navn`,
  REP_PostCity: `Postnr & By`,
  REP_Phone: `Telefon`,
  REP_Address: `Bústaður`,
  REP_Country: `Land`,
  REP_Email: `Email`,
  REP_Thanks: `Takk fyri bíleggingina`,
  REP_ThanksInfo: `Ein váttan verður send til tín við telduposti`,
  REP_ThanksPayment: `Your payment is complete`,

  LOA_Addons: `Henter ekstra ydelser`,
  LOA_CustomerInfo: `Henter kunde informationer`,
  LOA_ActivatingCustomer: `Aktiver kunde`,
  LOA_Translations: `Henter oversættelser`,
  LOA_Configuration: `Indlæser konfiguration`,
  LOA_Availability: `Henter ledighed`,
  LOA_CreatingReservation: `Opretter reservation`,
  LOA_HotelInformation: `Indlæser hotel information`,
  LOA_Countries: `Henter lande`,
  LOA_PreparingPaymnent: `Forbereder betaling`,
  LOA_ApplyingPromo: `Indlæser rabatkode`,
  LOA_Rooms: `Indlæser værelser`,
  LOA_Rates: `Indlæser priser`,
  LOA_LoggingIn: `Logger ind`,
  LOA_SendingInformation: `Sender information`,

  COM_Continue: `Víðari`,
  COM_OK: `OK`,
  COM_Persons: `Personer`,
  COM_Guest_10: `vaksin`,
  COM_Guest_9: `Tannáringur`,
  COM_Guest_7: `Pinkubarn`,
  COM_Guest_11: `Eldri fólk`,
  COM_Guest_8: `Børn`,
  COM_Adult: `vaksin`,
  COM_Child: `Barn`,
  COM_Room: `Kamar`,
  COM_Cabin: `Smátta`,
  COM_House: `Hús`,
  COM_Unit: `Eind`,
  COM_Arrival: `Koma`,
  COM_Departure: `Fráferð`,
  COM_Promocode: `Avsláttarkotan`,
  COM_Rooms: `Kømur`,
  COM_AddOns: `Tænastur`,
  COM_TotalGuests: `Tal av gestum`,
  COM_Price: `Prísur`,
  COM_TotalPrice: `Samlaður prísur`,
  COM_TotalPriceWithTransactionId: `I alt betalt med transaktionsnr.`,
  COM_TotalReservedWithTransactionId: `I alt er reserveret med transactionId`,
  COM_Close: `Lat aftur`,
  COM_Back: `Aftur`,
  COM_Error: `Info`,
  COM_Info: `Kunning`,
  COM_Confirm: `vinarliga vátta`,
  COM_Package: `Pakki`,
  COM_SoldOutError: `Der er ikke nogle ledige værelser eller pakker på denne dag`,
  COM_SearchNew: `Ny søgning`,
  COM_TableReservation: `BorðBílegging`,
  COM_TableNumberOfPersons: `Tal av gestum við borðið`,
  COM_Guests: `Gestir`,
  COM_TableChooseProduct: `Vel dato og vøru`,
  COM_TablePlaceGuests: `Tær manglar at`,
  COM_TableChooseProducts: `Vel vøru(r)`,
  COM_TableNumberOfGuestsForProduct: `Tal av gestum til hesa vøru`,
  COM_TableBookingFlowAddProduct: `Ynskir tú at gera eina borðbílegging?`,
  COM_TableIfNotPressContinue: `Um ikki so trýst á víðari`,
  COM_AddToBasket: `Legg í kurvina`,
  COM_AddedToBasket: `Lagt í kurvina`,
  COM_Type: `Type`,
  COM_SeatingSoldOut: `Nothing available at the moment`,
  COM_Main: `Home`,
  COM_Conference: `Konference`,
  COM_Quantity: `Stk.`,

  LFO_Email: `Teldupostur`,
  LFO_EmailRequried: `Upplýs teldupost`,
  LFO_EmailInvalid: `Teldupostur ikki rættur`,
  LFO_Password: `Loyniorð`,
  LFO_PasswordRequried: `Upplýs loyniorð`,
  LFO_ForgotPassword: `Fæst ikki atgongd til kontuna?`,
  LFO_Login: `Rita inn`,
  LFO_BackToLogin: `Aftur til innritan`,
  LFO_Send: `Send`,
  LFO_MessageSend: `Boðið er sent!`,
  LFO_LoginError: `Skeivur teldupostur ella skeivt loyniorð`,
  LFO_RecoveryError: `Loginoplysninger ugyldige`,

  SFO_ChooseHotel: `Okkara hotel`,
  SFO_ChooseHolidayResort: `Frítíðardepil`,
  SFO_ChooseCenter: `Depil`,
  SFO_ChooseDepartment: `Deild`,
  SFO_ParamsHeader: `Vel ferðadagar`,
  SFO_Guests: `Gestir á kamari`,
  SFO_GuestsCabin: `Gestir á kamari`,
  SFO_GuestsHouse: `Gestir á kamari`,
  SFO_GuestsUnit: `Gestir á kamari`,
  SFO_AddRoom: `Legg kamar afturat`,
  SFO_AddCabin: `Legg kamar afturat`,
  SFO_AddHouse: `Legg kamar afturat`,
  SFO_AddUnit: `Legg kamar afturat`,
  SFO_BookStay: `uppihald`,
  SFO_BookEvent: `Event`,
  SFO_BookTakeAway: `ÚtVið`,
  SFO_BookTableReservation: `Borðbílegging`,
  SFO_BookGiftcard: `Gávukort`,
  SFO_GiftcardMetaData: `Kunning um tíni gávukort`,
  SFO_ChooseGiftcard: `Vel gávukort`,
  SFO_NumberOfGiftcards: `Hvussu mong gávukort ynskir tú at keypa?`,
  SFO_GiftcardAmount: `Tasta inn upphædd`,
  SFO_GiftcardEnterDetails: `Nágreinilig kunning um tíni gávukort`,
  SFO_ChooseGiftcardDescription: `Keyp eitt gávukort til onkran tú hevur kæran`,
  SFO_GiftcardDetails: `Kunning um gávukort`,
  SFO_GiftcardTo: `Hvønn er gávukortið til (valfrítt)`,
  SFO_GiftcardFrom: `Hvørjum er gávukortið frá (valfrítt)`,
  SFO_GiftcardMessage: `Skriva eina heilsan (í mesta lagi 100 tekin) (valfrítt)`,
  SFO_BookArrangement: `Tiltak`,
  SFO_BookConcert: `Konsert`,
  SFO_BookTableArangement: `BorðTiltak`,
  SFO_BookTableEvent: `BorðTiltak`,
  SFO_BordTakeAway: `BorðÚtVið`,
  SFO_BookTicket: `Atgongumerki`,
  SFO_BookLecture: `Fyrilestur`,
  SFO_BookTable: `Borð`,
  SFO_BookSpa: `Kurstað`,
  SFO_BookExperience: `Uppliving`,
  SFO_ChooseMonth: `Vel Måned`,
  SFO_NoRooms: `Eingi kømur eru tøk í valda tíðarskeiðnum`,
  SFO_MinLOS: `Den valgte periode kræver booking af minimum`,
  SFO_Nights: `nætter`,
  SFO_MaxLOS: `I den valgte periode er det maksimalt muligt at booke`,

  CAL_WeekStart: `1`,
  CAL_M01: `Januar`,
  CAL_M02: `Februar`,
  CAL_M03: `Mars`,
  CAL_M04: `April`,
  CAL_M05: `Mai`,
  CAL_M06: `Juni`,
  CAL_M07: `Juli`,
  CAL_M08: `August`,
  CAL_M09: `September`,
  CAL_M10: `Oktober`,
  CAL_M11: `November`,
  CAL_M12: `Desember`,
  CAL_D0: `Sunnudagur`,
  CAL_D1: `Mánadagur`,
  CAL_D2: `Týsdagur`,
  CAL_D3: `Mikudagur`,
  CAL_D4: `Hósdagur`,
  CAL_D5: `Fríggjadagur`,
  CAL_D6: `Leygardagur`,
  CAL_Selected: `Valgt`,
  CAL_Occupied: `Stongt/Útselt`,
  CAL_Arrival: `Ongin koma`,
  CAL_Departure: `Ongin fráferð`,
  CAL_Mon: `M`,
  CAL_Tirs: `T`,
  CAL_Ons: `O`,
  CAL_Tors: `T`,
  CAL_Fre: `F`,
  CAL_Lor: `L`,
  CAL_Sun: `S`,
  CAL_Available: `Tøkt`,
  CAL_NotPossible: `Ikki møguligt`,

  BIN_ChangeDate: `Rætta dagar`,
  BIN_ChangeRoom: `Rætta`,
  BIN_ChangeAddOns: `Broyt eyka tænastur`,
  BIN_NoAddOns: `Eingin tænasta løgd afturat`,
  BIN_AddOns: `Ekstra ydelser tilføjet`,
  BIN_Reservation: `Tín bílegging`,
  BIN_Details: `Grundleggjandi staklutir`,
  BIN_RoomCount: `Værelser`,
  BIN_CabinCount: `Hytter`,
  BIN_HouseCount: `Huse`,
  BIN_UnitCount: `Enheder`,
  BIN_Roomtype: `Værelsestype`,
  BIN_Cabintype: `Værelsestype`,
  BIN_Housetype: `Værelsestype`,
  BIN_Unittype: `Værelsestype`,
  BIN_Packagetype: `Pakketype`,
  BIN_BasketEmpty: `Kurvin er tóm`,
  BIN_BasketGoToCheckout: `Far til kassan`,

  EXC_EmptyBasket: `Basket must not be empty`,

  SEA_Date: `Dagur`,
  SEA_Time: `Tíð`,
  SEA_Quantity: `Tal`,
  SEA_Skip: `Spring over`,
  SEA_ChooseEvent: `Vælg en oplevelse`,
  SEA_DescriptionStart: `Vel`,
  SEA_DescriptionEnd: `below or date in the calendar`,
  SEA_SoldOut: `Alt er udsolgt for den valgte måned`,
  SEA_SoldOutContinue: `Tryk på Fortsæt for at springe over`,
  SEA_SeatingStandAloneTitle: `Vælg en dato eller en af de viste`,
  SEA_ResetCalendar: `Vís øll`,

  MAT_CALENDAR_Calendar_Label: `S`,
  MAT_CALENDAR_Close_Calendar_Label: `S`,
  MAT_CALENDAR_End_Date_Label: `S`,
  MAT_CALENDAR_Next_Month_Label: `S`,
  MAT_CALENDAR_Next_Multi_Year_Label: `S`,
  MAT_CALENDAR_Next_Year_Label: `S`,
  MAT_CALENDAR_Open_Calendar_Label: `S`,
  MAT_CALENDAR_Prev_Month_Label: `S`,
  MAT_CALENDAR_Prev_Multi_Year_Label: `S`,
  MAT_CALENDAR_Prev_Year_Label: `S`,
  MAT_CALENDAR_Start_Date_Label: `S`,
  MAT_CALENDAR_Switch_To_Month_View_Label: `S`,
  MAT_CALENDAR_Switch_To_Multi_Year_View_Label: `S`,
};

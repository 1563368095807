<div class="component__slide-down-container" *ngIf="content && showAnimation">
  <div
    class="component__slide-down-description"
    [innerHTML]="content"
    [@expandCollapse]="openDescription"
    (@expandCollapse.start)="contentAnimationEvent($event)"
    (@expandCollapse.done)="contentAnimationEvent($event)"></div>
  <div
    class="component__slide-down-description-animate-overlay"
    *ngIf="showDescriptionBackdrop === true"
    (click)="animateOpenDescription()"></div>
  <div class="component__slide-down-description-animate-icon" (click)="animateOpenDescription()">
    <i
      [ngClass]="{
        'component__slide-down-description-animate-icon-fas-active': openDescription === 'expanded'
      }"
      class="component__slide-down-description-animate-icon-fas fas fa-angle-down"></i>
  </div>
</div>

<div *ngIf="content && !showAnimation">
  <ng-content></ng-content>
</div>

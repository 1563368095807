import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export const hasLowerValidator = (): ValidatorFn => (control: AbstractControl) => {
  const appHasLower = /[a-z]/.test(control.value);
  return appHasLower ? null : { appHasLower: { value: control.value } };
};

@Directive({
  selector: '[appHasLower]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: HasLowerValidatorDirective,
      multi: true,
    },
  ],
})
export class HasLowerValidatorDirective implements Validator {
  validate(control: AbstractControl) {
    return hasLowerValidator()(control);
  }
}

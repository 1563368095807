<div class="row h-100" *ngIf="message">
  <div class="col l12 m12 s12 h-100">
    <div class="dialog-container-services h-100">
      <div class="dialog-header-services">
        <button
          class="dialog-header-button-close default btn btn-primary m-0"
          style="background-color: white"
          type="button"
          (click)="message.close()">
          <i class="dialog-header-button-close-icon fas fa-times" style="color: black"></i>
        </button>
        <div class="dialog-header-image-container" *ngIf="message.addOn.Image">
          <div
            class="dialog-header-background-container"
            [ngStyle]="{ 'background-image': 'url(' + message.addOn.Image + ')' }"></div>
          <img class="dialog-header-image" [src]="message.addOn.Image" draggable="false" />
        </div>
        <p class="dynamicTextColor">
          {{ message.addOn.Name }}
        </p>
      </div>
      <div class="dialog-services">
        <div class="dialog-body-services">
          <div
            class="dialog-body-long-description-container"
            *ngIf="message.addOn.Long && messageLongDescriptionShowAnimation$.value">
            <div
              class="dialog-body-long-description-animate-overlay"
              *ngIf="showDescriptionBackdrop === true"
              (click)="animateOpenDescription()"></div>
            <div class="dialog-body-long-description-animate-icon" (click)="animateOpenDescription()">
              <i
                [ngClass]="{ 'dialog-body-long-description-animate-icon-fas-active': openDescription === 'expanded' }"
                class="dialog-body-long-description-animate-icon-fas fas fa-angle-down"></i>
            </div>
          </div>
          <div
            class="dialog-body-long-description-container"
            *ngIf="message.addOn.Long && !messageLongDescriptionShowAnimation$.value">
            <div class="dialog-body-long-description" [innerHTML]="message.addOn.Long" #messageLongDescriptionRef></div>
          </div>
          <div *ngFor="let room of message.rooms">
            <h1 class="dialog-body-room-title" *ngIf="!seatingFlow">
              {{ applicationState.RoomLabel | t | async }}
              {{ room.roomId | char }}
            </h1>
            <h1 class="dialog-body-room-title" *ngIf="seatingFlow">
              {{ 'APA_Services' | t | async }}
              {{ room.roomId | char }}
            </h1>
            <ng-container *ngFor="let day of room.days">
              <div class="dialog-add-service-day-wrapper">
                <div class="row">
                  <div class="col m4 s4 l4">
                    <div style="text-align: left">
                      <h5>{{ day.weekDayName | t | async }} {{ day.date | date | async }}</h5>
                      <p style="font-size: 11px" class="mobile-hide">
                        {{ 'APA_TotalOf' | t | async }} {{ total | price | async }}
                      </p>
                    </div>
                  </div>
                  <div class="col m4 s4 l4">
                    <div class="dialog-add-service-day-inline-box">
                      <button
                        [ngClass]="{ 'dialog-add-button--disabled': day.guestSelectCount === 0 }"
                        class="dialog-add-button dialog-add-button--left"
                        (click)="message.guestDecrement(day, total)">
                        <i style="color: var(--primary-application-color)" class="fas fa-minus"></i>
                      </button>
                      <span class="dialog-add-guest-count">{{ day.guestSelectCount }} / {{ day.maxGuestCount }}</span>
                      <button
                        [ngClass]="{ 'dialog-add-button--disabled': day.guestSelectCount === day.maxGuestCount }"
                        class="dialog-add-button dialog-add-button--right"
                        (click)="message.guestIncrement(day, total)">
                        <i style="color: var(--primary-application-color)" class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col m4 s4 l4">
                    <div style="text-align: right; margin-top: 5%">
                      + {{ day.guestSelectCount * total | price | async }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="dialog-footer-services">
        <h3 class="dialog-footer-total">
          {{ 'APA_TotalPrice' | t | async }}
          {{ totalAddOnInformation$.getValue().totalAmount | price | async }}
        </h3>
        <div class="dialog-footer-button-section">
          <button
            class="dialog-footer-button default btn btn-primary with-shadow m-0"
            type="button"
            (click)="message.accept()">
            {{ 'COM_Confirm' | t | async }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

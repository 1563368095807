import dayjs from 'dayjs';
import { Moment } from 'moment';
import {
  AspDateString,
  AspDateWithTimeZoneString,
  DKDateString,
  SpectraDate,
  UTCDateString,
  UTCDateTimeString,
} from './spectra-date';

export class DateHelper {
  static readonly DayInMs = 60 * 60 * 1000 * 24;
  static format(date: Date | SpectraDate, format: string) {
    return dayjs(date.getTime()).format(format);
  }

  static getTranslatedMonth(monthIndex: number) {
    const monthNames = [
      'CAL_M01',
      'CAL_M02',
      'CAL_M03',
      'CAL_M04',
      'CAL_M05',
      'CAL_M06',
      'CAL_M07',
      'CAL_M08',
      'CAL_M09',
      'CAL_M10',
      'CAL_M11',
      'CAL_M12',
    ];

    return monthNames[monthIndex];
  }

  static GetTranslatedWeekDay(dayIndex: number) {
    if (dayIndex === -1) {
      dayIndex = 6;
    }
    const weekDayNames = ['CAL_D1', 'CAL_D2', 'CAL_D3', 'CAL_D4', 'CAL_D5', 'CAL_D6', 'CAL_D0'];
    return weekDayNames[dayIndex];
  }

  static convertMomentToDate(input: Moment): Date {
    return new Date(
      input.year(),
      input.month(),
      input.date(),
      input.hour(),
      input.minute(),
      input.second(),
      input.millisecond(),
    );
  }

  static addDays(date: Date, days: number) {
    const result = new Date(date.getTime());
    result.setDate(result.getDate() + days);
    return new Date(result.getFullYear(), result.getMonth(), result.getDate());
  }

  static addMonth(date: Date, month: number) {
    const _dayjs = dayjs(date.getTime());

    const _date = _dayjs.set('date', 1).add(month, 'month').add(-1, 'day');
    return new Date(_date.year(), _date.month(), _date.date());
  }

  static daysBetween(date1: Date, date2: Date, days: number) {
    const secondDate = date2.getTime();
    const firstDate = date1.getTime();

    return Math.ceil(Math.abs((secondDate - firstDate) / DateHelper.DayInMs)) + days;
  }

  static FromAspDate(date: AspDateString) {
    const result = new Date(+date.replace(/[^\d]+/g, ''));
    result.setMinutes(result.getMinutes() + result.getTimezoneOffset());
    return result;
  }

  static toAspString(date: Date): AspDateString {
    return `/Date(${date.getTime()})/`;
  }

  static FromUtcDate(date: UTCDateString | UTCDateTimeString) {
    const dateParts = date.split('-');
    return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2].substring(0, 2));
  }

  static getTimeFromUtcDate(date: UTCDateTimeString) {
    const timePart = date.split('T');
    const time = timePart[1].split(':');
    return time[0] + ':' + time[1];
  }

  static ConvertToUTCDate(epochTimeStamp: number) {
    return new Date(epochTimeStamp);
  }

  static getMonthsBetweenDates(monthNumber1: number, monthNumber2: number) {
    return monthNumber2 - monthNumber1;
  }

  static ConvertToServerDate(date: Date) {
    const serverDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    serverDate.setMinutes(serverDate.getMinutes() - serverDate.getTimezoneOffset());
    return serverDate;
  }

  static asDayjs(date: Date) {
    return dayjs(date.getTime());
  }

  static isEqual(date: Date, date2: Date) {
    return (
      date.getFullYear() === date2.getFullYear() &&
      date.getMonth() === date2.getMonth() &&
      date.getDate() === date2.getDate()
    );
  }

  static isLess(date: Date | null, date2: Date) {
    return (
      (date != null && date.getFullYear()) < date2.getFullYear() &&
      (date != null && date.getMonth()) < date2.getMonth() &&
      (date != null && date.getDate()) < date2.getDate()
    );
  }

  static isGreater(date: Date, date2: Date) {
    return date.valueOf() > date2.valueOf();
  }

  static toServerDateFormatString(date: Date) {
    return this.asDayjs(date).format('YYYY-MM-DD') as string;
  }

  static getFirstDateOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  static getLastDateOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  static FromTime(time: number) {
    const date = new Date(time);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static FromDKDate(date: DKDateString) {
    return this.FromTime(new Date(date.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1T00:00:00.000Z')).getTime());
  }

  static GetMonthsAndYearFromToday(date: Date, locale: string, monthsToCount: number) {
    const monthYearArray = [] as ViewDate[];

    for (let step = 0; step < monthsToCount; step++) {
      const viewDataObject = {} as ViewDate;

      viewDataObject.ViewMonth = this.getTranslatedMonth(date.getMonth());
      viewDataObject.ViewYear = date.getFullYear().toString();
      viewDataObject.RealDate = date;

      monthYearArray.push(viewDataObject);
      date = DateHelper.addMonth(date, 2);
    }
    return monthYearArray;
  }

  static today() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  static FromValues(year: number, month: number, day: number) {
    // The code is made this way, because in some cases the code before, calculated the right date, but 1 month ahead, do to browser bug in some browers.
    const buildDate = year.toString() + '-' + month.toString() + '-' + day.toString();
    const dateToUse = buildDate.match(/(\d+)/g);
    if (dateToUse != null) {
      const num = parseInt(dateToUse[1], 10);
      return new Date(parseInt(dateToUse[0], 10), num - 1, parseInt(dateToUse[2], 10));
    }
    return new Date(year, month, day);
  }

  static FromAspDateWithTimezone(date: AspDateWithTimeZoneString) {
    let time = 0;
    const matches = date.match(/\/Date\((\d+)(-)(\d{2})(\d{2})\)\//);
    if (matches) {
      time =
        (+matches[1] || 0) + (matches[2] === '+' ? 1 : -1) * ((+matches[3] || 0) * 60 + (+matches[4] || 0)) * 60000;
    }
    return this.FromTime(time);
  }
}

export interface ViewDate {
  ViewMonth: string;
  ViewYear: string;
  RealDate: Date;
}

import { Injectable } from '@angular/core';
import { GiftcardType, ReservationType, RoomType, SeatType } from '../payment';
import {
  TrackingClient,
  TrackingClientCartItem,
  TrackingClientPurchase,
  TrackingClientViewCart,
} from './tracking-client.interface';
import { HotelService } from '../hotel';
import { GoogleAnalyticsPurchaseItem } from './Models/google-analytics-purchase.item';

@Injectable()
export class TrackingService {
  private clients: TrackingClient[] = [];
  constructor(private hotelService: HotelService) {}
  addClient(client: TrackingClient) {
    this.clients.push(client);
  }

  async pageView(page: string) {
    this.clients.map(async (client) => {
      await client.pageView?.(page);
    });
  }
  async ecommerceAddToCart(item: TrackingClientCartItem): Promise<void> {
    this.clients.map(async (client) => {
      await client.ecommerceAddToCart?.(item);
    });
  }
  async ecommerceRemoveFromCart(item: TrackingClientCartItem): Promise<void> {
    this.clients.map(async (client) => {
      await client.ecommerceRemoveFromCart?.(item);
    });
  }
  async addToCart(category: string, entityType: string, name: string, count: number): Promise<void> {
    this.clients.map(async (client) => {
      await client.addToCart?.(category, entityType, name, count);
    });
  }
  async removeFromCart(category: string, entityType: string, name: string, count: number) {
    this.clients.map(async (client) => {
      await client.removeFromCart?.(category, entityType, name, count);
    });
  }
  async ecommercePurchase(purchase: TrackingClientPurchase) {
    this.clients.map(async (client) => {
      await client.ecommercePurchase?.(purchase);
    });
  }
  async ecommerceViewItem(item: TrackingClientCartItem) {
    this.clients.map(async (client) => {
      await client.ecommerceViewItem?.(item);
    });
  }
  async ecommerceViewCart?(item: TrackingClientViewCart) {
    this.clients.map(async (client) => {
      await client.ecommerceViewCart?.(item);
    });
  }
  async ecommerceBeginCheckout(cart: TrackingClientViewCart) {
    this.clients.map(async (client) => {
      await client.ecommerceBeginCheckout?.(cart);
    });
  }
  private MapRoomsToPurchaseItems(rooms: RoomType[], lang: string): Promise<GoogleAnalyticsPurchaseItem[]> {
    return Promise.all(
      rooms.map<Promise<GoogleAnalyticsPurchaseItem>>(async (room) => {
        const { Name: roomName } = await this.hotelService.GetECommerceRoomInfo(room.hotelCode, room.roomCode, lang);
        const { Code: rateName } = await this.hotelService.GetECommerceRateInfo(room.hotelCode, room.ratePlan, lang);
        return {
          affiliation: room.hotelCode,
          item_id: `${room.roomCode}/${room.ratePlan}`,
          item_name: `${roomName}/${rateName}`,
          item_category: 'Room',
          price: room.price,
          quantity: 1,
        };
      }),
    );
  }
  private MapServicesToPurchaseItems(rooms: RoomType[], lang: string): Promise<GoogleAnalyticsPurchaseItem[]> {
    return Promise.all(
      rooms.flatMap<Promise<GoogleAnalyticsPurchaseItem>>((room) =>
        room.services.map(async (service) => {
          const { Name: serviceName } = await this.hotelService.GetECommerceExtraServiceInfo(
            room.hotelCode,
            service.serviceCode,
            lang,
            room.ratePlan,
            room.roomCode,
          );
          return {
            affiliation: room.hotelCode,
            item_id: `${room.roomCode}/${room.ratePlan}/${service.serviceCode}`,
            item_name: `${serviceName}`,
            item_category: 'Service',
            price: service.price,
            quantity: 1,
          };
        }),
      ),
    );
  }
  private MapSeatsToPurchaseItems(seats: SeatType[], lang: string): GoogleAnalyticsPurchaseItem[] {
    return seats.map((seat) => {
      return {
        item_id: `${seat.seatrate}`,
        item_name: `${seat.seatrate}`,
        item_category: 'Seating',
        price: seat.total,
        quantity: seat.seatGuests.length,
      };
    });
  }
  private MapGiftCardsToPurchaseItems(giftcards: GiftcardType[], lang: string): GoogleAnalyticsPurchaseItem[] {
    return giftcards.map((card) => {
      return {
        item_id: `${card.cardName}`,
        item_name: `${card.cardName}`,
        item_category: 'Giftcard',
        price: card.total,
        quantity: 1,
      };
    });
  }
  private async MapFromReservation(reservation: ReservationType): Promise<TrackingClientPurchase> {
    const transaction_id = reservation.reservationId;
    const value = reservation.total;
    let items: GoogleAnalyticsPurchaseItem[] = [];
    if (reservation.rooms.length !== 0) {
      const products = await this.MapRoomsToPurchaseItems(reservation.rooms, reservation.lang);
      const services = await this.MapServicesToPurchaseItems(reservation.rooms, reservation.lang);
      items = items.concat(products, services);
    }
    if (reservation.seats.length !== 0) {
      const products = this.MapSeatsToPurchaseItems(reservation.seats, reservation.lang);
      items = items.concat(products);
    }
    if (reservation.giftcards.length !== 0) {
      const products = this.MapGiftCardsToPurchaseItems(reservation.giftcards, reservation.lang);
      items = items.concat(products);
    }
    const model: TrackingClientPurchase = { transaction_id, value, items };
    return model;
  }
  async ecommerceBeginCheckoutFromReservation(reservation: ReservationType) {
    const model = await this.MapFromReservation(reservation);
    await this.ecommerceBeginCheckout(model);
  }
  async ecommercePurchaseFromReservation(reservation: ReservationType): Promise<void> {
    const model = await this.MapFromReservation(reservation);
    await this.ecommercePurchase(model);
  }
}

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { LocaleService } from './locale.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [LocaleService],
  exports: [],
})
export class LocaleModule {
  constructor(@Optional() @SkipSelf() parentModule: LocaleModule) {
    if (parentModule) {
      throw new Error('LocaleModule is already loaded. Import it in the CoreModule only');
    }
  }
}

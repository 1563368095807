<form (submit)="onSubmit($event)">
  <div class="card-login with-shadow">
    <button
      type="button"
      class="link btn-close applicationColor"
      style="position: relative; margin-left: 270px; margin-top: -20px"
      (click)="onCloseClick()">
      <i class="fas fa-times-circle"></i>
    </button>
    <div class="card-body">
      <span class="applicationColor" style="font-size: 19px; font-weight: 700">{{ 'APA_PromoLabel' | t | async }}</span>
      <input
        type="text"
        autofocus
        [placeholder]="'APA_Promo' | t | async"
        [value]="promo"
        (change)="onPromoChange($event.target.value)" />
      <p *ngIf="error" class="text-danger">
        {{ 'CPA_PromoInvalid' | t | async }}
      </p>
      <div class="text-center">
        <div class="d-inline-flex flex-column mw-100">
          <button type="submit" class="btn btn-primary with-shadow m-0">
            {{ 'APA_PromoSubmit' | t | async }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationStateService, BookingService } from '../../core';

@Component({
  selector: 'giftcard-form',
  templateUrl: './giftcard-form.component.html',
})
export class GiftcardFormComponent {
  giftcardCode = '';
  giftcardLength = 8;
  giftcardClicked = false;
  giftcardNotFound = false;

  // It's an input so we can conditionally use it to render giftcard information aswell
  @Input() giftcardInformation: GiftcardInformationModel | undefined;

  // Output to tell parent component new giftcard information
  @Output() giftcardInformationEvent = new EventEmitter<GiftcardInformationModel>();

  // If giftcard is already in basket
  @Input() hasGiftcard = false;

  @Input() currency = '';

  // @ts-ignore - applicationstate is used in the template
  constructor(private bookingService: BookingService, public applicationState: ApplicationStateService) {}

  async toggleGiftcardBox() {
    this.giftcardNotFound = false;
    this.giftcardClicked = !this.giftcardClicked;
    this.giftcardCode = '';
  }

  async applyGiftcard() {
    const res = await this.bookingService.getGiftcardInformation(this.giftcardCode);
    if (res && res.RemainingAmount !== 0 && res.IsActive) {
      this.giftcardInformation = {
        Amount: res.RemainingAmount,
        Name: res.Name,
        Valid: res.IsActive,
        Number: this.giftcardCode,
      };

      this.giftcardInformationEvent.emit(this.giftcardInformation);
    } else {
      this.giftcardNotFound = true;
      this.giftcardCode = '';
    }
  }
}

export interface GiftcardInformationModel {
  Amount: number;
  Name: string;
  Valid: boolean;
  Number: string;
}

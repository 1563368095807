import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/modules/config';
import { StorageClient } from 'src/app/helpers';
import { CountryService } from 'src/app/modules/country';
import { Customer, CustomerInfo } from './customer';

@Injectable()
export class CustomerService {
  private static defaultCustomer: Customer = {
    Id: null,
    PaymentRequired: true,
    Info: {
      FirstName: '',
      LastName: '',
      ZipCode: '',
      City: '',
      Country: CountryService.Default.Code,
      Phone: '',
      Address1: '',
      Address2: '',
      Email: '',
    },
    Company: null,
  };

  private customer = new StorageClient(localStorage, 'Customer', CustomerService.defaultCustomer);
  customer$ = new BehaviorSubject(this.customer.get());
  customerId$ = this.customer$.pipe(map((customer) => (customer.Id ? customer.Id : undefined || undefined)));
  companyId$ = this.customer$.pipe(
    map((customer) => (customer.Company ? customer.Company.Id : undefined || undefined)),
  );

  constructor(private http: HttpClient, private config: ConfigService, private log: LogService) {
    this.customer$.subscribe((customer) => {
      this.customer.set(customer);
    });
  }

  updateInfo(Info: CustomerInfo) {
    const customer = this.customer$.value;
    if (customer.Id) {
      throw new Error("Can't update already known customer");
    }
    this.customer$.next({
      ...this.customer$.value,
      Info,
    });
  }

  getCustomer() {
    return this.customer$.value;
  }

  async login(email: string, password: string) {
    this.log.debug(`CustomerService login(${email})`);
    const config = await this.config.getAppSettings();
    const response = await lastValueFrom(
      this.http.get<CustomerInfoContract>(`${config.ECommerceDataProvider}/api/auth/login/${email}/${password}`),
    );
    if (response) {
      const customer = this.mapCustomer(response);
      this.customer$.next(customer);
      return customer;
    } else {
      throw new Error('No Access');
    }
  }

  logout() {
    this.log.debug('CustomerService logout()');
    this.customer$.next({
      ...this.customer$.value,
      Id: null,
      PaymentRequired: false,
      Company: null,
    });
  }

  async getCustomerByToken(token: string) {
    const config = await this.config.getAppSettings();
    const response = await lastValueFrom(
      this.http.get<string>(`${config.ECommerceDataProvider}/api/auth/getEmailFromToken/${token}`),
    );

    if (response) {
      return response;
    } else {
      throw new Error(response || 'Token is not valid');
    }
  }

  async activate(token: string, password: string) {
    const config = await this.config.getAppSettings();
    const response = await lastValueFrom(
      this.http.post<CustomerInfoContract>(
        `${config.ECommerceDataProvider}/api/auth/activateAccount`,
        // `https://localhost:44343/api/auth/activateAccount`
        {
          token,
          password,
        },
      ),
    );
    if (response) {
      const customer = this.mapCustomer(response);
      this.customer$.next(customer);
      return customer;
    } else {
      throw new Error(response);
    }
  }

  async resetCustomerByEmail(email: string, hotelCode: string, langCode: string) {
    const config = await this.config.getAppSettings();

    const response = await lastValueFrom(
      this.http.post<CustomerInfoContract>(
        `${config.ECommerceDataProvider}/forgotPassword`,
        // `https://localhost:44343/forgotPassword`
        {
          email,
          hotelCode,
          langCode,
        },
      ),
    );
    if (response) {
      throw new Error(!response || 'Failed to reset customer password by email');
    }
  }

  private mapCustomer(r: CustomerInfoContract) {
    const customer: Customer = {
      Id: r.CustomerId,
      PaymentRequired: r.Mustpayonline,
      Info: {
        FirstName: r.Name.split(' ')[0],
        LastName: r.Name.split(' ')[1],
        Email: r.EMail,
        ZipCode: r.ZipCode,
        Address1: r.Address1,
        Address2: r.Address2,
        City: r.City,
        Country: 'DK',
        Phone: r.Phone,
      },
      Company: {
        Id: r.CompanyId,
        Name: r.CompanyName,
        ZipCode: r.ZipCode,
        Address1: r.Address1,
        Address2: r.Address2,
        City: r.City,
        CountryCode: 'DK',
        CountryName: r.Country,
        Phone: r.Phone,
      },
    };
    return customer;
  }
}

interface CustomerInfoContract {
  EMail: string;
  Name: string;
  CustomerId: number;
  Activated: boolean;
  CompanyId: number;
  CompanyName: string;
  Address1: string;
  Address2: string;
  ZipCode: string;
  Phone: string;
  Country: string;
  City: string;
  Mustpayonline: boolean;
}

export interface ActivateCustomerResponse {
  ActivateCustomerResult?: CustomerInfoContract;
  ErrorCode?: string;
  Messsage?: string;
}

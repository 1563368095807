import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogService } from '@com/logging';
import { BehaviorSubject, combineLatest, from } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AppQueryParams } from 'src/app/app.component';
import {
  ApplicationStateService,
  BasketQueryParams,
  BasketService,
  CustomerService,
  HotelService,
  Loader,
  LocaleService,
  PromotionService,
  Rate,
  Room,
  RoomType,
  SearchParams,
  TrackingService,
} from 'src/app/core';
import {
  CompanyAgreementDiscountResponseModel,
  GuestCombination,
  HotelRoomRateData,
  SpectraApiRateResponseModel,
} from 'src/app/core/modules/hotel/data-hotel.service';
import { GetPromotionCodeStatus } from '../../core/modules/promotion/data-promotion.service';
import { DateHelper, GuestType, UTCDateTimeString } from '../../helpers';
import { NavigationHelper } from '../../helpers/navigation-helper';
import { Messenger } from '../../modules/messenger';
import { DesignConfiguration } from '../../shared/design/design-configuration';
import { RoomLookupError, RoomsNotAvailableReason, RoomsSoldOutReason } from '../room/room-lookup-error';
import { SearchComponent } from '../search/search.component';

interface RoomRouteParams {
  index?: string;
}

@Component({
  selector: 'app-room',
  templateUrl: './ratesRoom.component.html',
})
export class RatesRoomComponent implements OnInit, OnDestroy {
  private bookingIndex$ = this.route.params.pipe(
    map((params: RoomRouteParams) => +(params.index || '1') - 1),
    distinctUntilChanged(),
    tap(() => {
      document.body.scrollTop = 0;
    }),
  );
  private selected$ = new BehaviorSubject(undefined as RatesUI | undefined);
  arrivaldate = '';
  lengthOfStay = 2;
  basket = this.getBasket(this.route.snapshot.queryParams as AppQueryParams);
  rooms: RoomType[] | undefined;
  roomRateData: HotelRoomRateData[] | undefined;
  rateSelected = false;
  selectedRate: RatesUI | undefined;
  presentationsRooms: Room[] | undefined;
  returnFromBasket = false;
  roomsAndRates: SpectraApiRateResponseModel[] | undefined;

  @ViewChild('messageRoomGalleryTempalte', { static: false })
  messageRoomGalleryTempalte: TemplateRef<GalleryModel> | undefined;
  @ViewChild('messageRateGalleryTempalte', { static: false })
  messageRateGalleryTempalte: TemplateRef<GalleryModel> | undefined;

  data$ = combineLatest([
    this.localeService.locale$,
    this.bookingIndex$,
    this.promotionService.promocode$,
    this.customerService.customerId$,
    this.customerService.companyId$,
  ]).pipe(
    switchMap(([, bookingIndex, , , companyId]) =>
      from(this.buildRatesUIModel(bookingIndex, companyId)).pipe(
        tap((rooms) => {
          if (this.basket.bookings[bookingIndex]) {
            this.selected$.next(rooms.find((x) => x.Code === this.basket.bookings[bookingIndex].RateCode));
            this.selectedRate = this.selected$.value;
            this.rateSelected = true;
          }
        }),
        switchMap((rooms) =>
          this.selected$.pipe(
            switchMap((room) =>
              from(this.buildRoomsForRate(bookingIndex, companyId)).pipe(
                map((rates) => {
                  return {
                    bookingIndex,
                    rooms,
                    room,
                    rates,
                  };
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private log: LogService,
    private route: ActivatedRoute,
    private basketService: BasketService,
    private hotelService: HotelService,
    private localeService: LocaleService,
    private trackingService: TrackingService,
    private customerService: CustomerService,
    private promotionService: PromotionService,
    private loader: Loader,
    private applicationState: ApplicationStateService,
    private messenger: Messenger,
    private navigationHelper: NavigationHelper,
    private designConfig: DesignConfiguration,
  ) {}

  async ngOnInit() {
    this.applicationState.stopScrolling(false);
    this.lengthOfStay = this.basket.params.stay;
    this.arrivaldate = DateHelper.toServerDateFormatString(this.basket.params.arrival);
    document.body.classList.remove('addBackGround');
    document.body.classList.remove('addBackGroundWithAnimation');
    document.body.style.backgroundImage = '';

    this.applicationState.ReturnFromOnlySeating = 'false';

    if (this.basket.bookings.length !== 0 && this.basket.bookings.length === this.basket.params.rooms.length) {
      this.returnFromBasket = true;
    }

    await this.prepareNavigation();
  }

  ngOnDestroy(): void {
    this.navigationHelper.resetNextPage();
  }

  async prepareNavigation() {
    if (this.applicationState.UseTableReservation && this.applicationState.InclueTableReservationBookingFlow) {
      await this.navigationHelper.continueMobileNavigationbar(false, true, '/ratesRoom/1', '/products', {
        queryParams: {
          HotelCode: this.applicationState.CurrentHotelCode,
          ArrivalDate: this.arrivaldate,
          LengthOfStay: this.lengthOfStay,
          guest: this.calculateGuestCount(),
        },
      });
    } else if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
      await this.navigationHelper.continueMobileNavigationbar(false, true, '/ratesRoom/1', '/seating/1');
    } else {
      await this.navigationHelper.continueMobileNavigationbar(false, true, '/ratesRoom/1', '/add-on/1');
    }
  }

  async onRateClick(e: Event, rate: RatesUI) {
    e.stopPropagation();

    if (this.messageRoomGalleryTempalte !== undefined && this.selected$.value !== rate) {
      this.applicationState.stopScrolling(true);
      this.basketService.hideBasket();
      let array = [] as string[];
      if (rate.Image !== '' || rate.Image1 !== '' || rate.Image2 !== '') {
        array = [rate.Image, rate.Image1, rate.Image2];
      }
      const message = this.messenger.show(
        this.messageRoomGalleryTempalte,
        {
          roomData: undefined,
          imageArray: array.filter((x) => x !== ''),
          selectedImage: array[0],
          rateDataUI: rate,
          rateData: undefined,
          accept: () => {
            this.selectedRate = rate;
            this.applicationState.stopScrolling(false);
            this.selected$.next(this.selected$.value === rate ? undefined : rate);
            this.messenger.close(message);
            if (this.designConfig.addProductScrolling()) {
              const res = document.documentElement.scrollTop || document.body.scrollTop;
              window.scrollTo({
                left: 0,
                top: res === 0 ? +200 : res + 100,
                behavior: 'smooth',
              });
            }
          },
          close: () => {
            this.applicationState.stopScrolling(false);
            this.messenger.close(message);
          },
          nextImage: (img: string) => {
            const index = array.findIndex((x) => x === img);
            if (array.length - 1 === index) {
              message.data.selectedImage = array[0];
            } else {
              message.data.selectedImage = array[index + 1];
            }
          },
          prevImage: (img: string) => {
            const index = array.findIndex((x) => x === img);
            if (index === 0) {
              message.data.selectedImage = array[array.length - 1];
            } else {
              message.data.selectedImage = array[index - 1];
            }
          },
        },
        () => {
          this.applicationState.stopScrolling(false);
          message.data.close();
        },
      );
    } else {
      this.applicationState.stopScrolling(false);
      this.selected$.next(undefined);
    }

    this.rateSelected = true;
  }

  chooseAnotherRate() {
    this.rateSelected = false;
  }

  async onBookClick(bookingIndex: number, rate: Rate, room: Room) {
    const bookingToRemove = this.basket.bookings[bookingIndex];
    if (bookingToRemove) {
      this.trackingService.ecommerceRemoveFromCart({
        item_name: `${bookingToRemove.RoomName}/${bookingToRemove.RateCode}`,
        item_id: `${bookingToRemove.RoomCode}/${bookingToRemove.RateCode}`,
        price: bookingToRemove.Price,
        item_category: 'Room',
        quantity: 1,
      });
    }

    this.basket.bookings[bookingIndex] = {
      RoomName: room.Name,
      RateName: this.selectedRate !== undefined ? this.selectedRate.ShortDescription : rate.Code,
      Price: room.Price,
      RateCode: rate.Code,
      RateDescription: rate.LongDescription ? rate.LongDescription : '',
      RoomCode: room.Code,
      HasPromotionForCustomer: rate.HasPromotionForCustomer,
      ConferenceRateCode: '',
      ConferenceRoomCode: '',
      OriginalPrice: room.OriginalPrice,
    };
    this.trackingService.ecommerceAddToCart({
      item_name: `${room.Name}/${rate.Code}`,
      item_id: `${room.Code}/${rate.Code}`,
      price: room.Price,
      item_category: 'Room',
      quantity: 1,
    });
    this.basketService.set(this.basket);
    const roomCount = this.basket.params.rooms ? this.basket.params.rooms.length : 0;
    if (this.basket.bookings.length < roomCount) {
      await this.navigationHelper.continue('/ratesRoom/1', `/ratesRoom/${this.basket.bookings.length + 1}`);
    } else if (this.applicationState.UseTableReservation && this.applicationState.InclueTableReservationBookingFlow) {
      await this.navigationHelper.continue('/ratesRoom/1', '/products', {
        queryParams: {
          HotelCode: this.applicationState.CurrentHotelCode,
          ArrivalDate: this.arrivaldate,
          LengthOfStay: this.lengthOfStay,
          guest: this.calculateGuestCount(),
        },
      });
    } else if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
      await this.navigationHelper.continue('/ratesRoom/1', '/seating/1');
    } else {
      await this.navigationHelper.continue('/ratesRoom/1', '/add-on/1');
    }
  }

  calculateGuestCount() {
    if (this.basket) {
      let count = 0;

      for (const item of this.basket.params.rooms) {
        count = count + item.getPersons();
      }
      return count;
    }
    return 2;
  }
  async onRateSelect(bookingIndex: number, rate: Rate, room: Room) {
    if (this.messageRateGalleryTempalte !== undefined && this.selectedRate !== undefined) {
      this.applicationState.stopScrolling(true);
      let array = [] as string[];
      if (room.Image !== '' || room.Image1 !== '' || room.Image2 !== '') {
        array = [room.Image, room.Image1, room.Image2];
      }
      this.trackingService.ecommerceViewItem({
        item_name: `${room.Name}/${rate.Code}`,
        item_id: `${room.Code}/${rate.Code}`,
        price: rate.Price,
        item_category: 'Room',
        quantity: rate.Quantity,
      });
      const message = this.messenger.show(
        this.messageRateGalleryTempalte,
        {
          roomData: room,
          imageArray: array.filter((x) => x !== ''),
          selectedImage: array[0],
          rateData: rate,
          rateDataUI: undefined,
          accept: async () => {
            this.applicationState.stopScrolling(false);

            const bookingToRemove = this.basket.bookings[bookingIndex];
            if (bookingToRemove) {
              this.trackingService.ecommerceRemoveFromCart({
                item_name: `${bookingToRemove.RoomName}/${bookingToRemove.RateCode}`,
                item_id: `${bookingToRemove.RoomCode}/${bookingToRemove.RateCode}`,
                price: bookingToRemove.Price,
                item_category: 'Room',
                quantity: 1,
              });
            }

            this.basket.bookings[bookingIndex] = {
              RoomName: room.Name,
              RateName: this.selectedRate ? this.selectedRate.ShortDescription : rate.Code,
              Price: room.Price,
              RateCode: rate.Code,
              RateDescription: room.Long ? room.Long : '',
              RoomCode: room.Code,
              HasPromotionForCustomer: rate.HasPromotionForCustomer,
              ConferenceRateCode: '',
              ConferenceRoomCode: '',
              OriginalPrice: room.OriginalPrice,
            };

            this.trackingService.ecommerceAddToCart({
              item_name: `${room.Name}/${rate.Code}`,
              item_id: `${room.Code}/${rate.Code}`,
              price: room.Price,
              item_category: 'Room',
              quantity: 1,
            });
            this.basketService.set(this.basket);
            const roomCount = this.basket.params.rooms ? this.basket.params.rooms.length : 0;
            if (this.basket.bookings.length < roomCount) {
              await this.navigationHelper.continue('/ratesRoom/1', `/ratesRoom/${this.basket.bookings.length + 1}`);
            } else if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
              await this.navigationHelper.continue('/ratesRoom/1', '/seating/1');
            } else {
              await this.navigationHelper.continue('/ratesRoom/1', '/add-on/1');
            }
            this.messenger.close(message);
          },
          close: () => {
            this.applicationState.stopScrolling(false);
            this.messenger.close(message);
          },
          nextImage: (img: string) => {
            const index = array.findIndex((x) => x === img);
            if (array.length - 1 === index) {
              message.data.selectedImage = array[0];
            } else {
              message.data.selectedImage = array[index + 1];
            }
          },
          prevImage: (img: string) => {
            const index = array.findIndex((x) => x === img);
            if (index === 0) {
              message.data.selectedImage = array[array.length - 1];
            } else {
              message.data.selectedImage = array[index - 1];
            }
          },
        },
        () => {
          this.applicationState.stopScrolling(false);
          message.data.close();
        },
      );
    }
  }

  async onMoveToNextClick() {
    if (this.applicationState.UseTableReservation && this.applicationState.InclueTableReservationBookingFlow) {
      await this.navigationHelper.continue('/ratesRoom/1', '/products', {
        queryParams: {
          HotelCode: this.applicationState.CurrentHotelCode,
          ArrivalDate: this.arrivaldate,
          LengthOfStay: this.lengthOfStay,
          guest: this.calculateGuestCount(),
        },
      });
    } else if (this.applicationState.UseSeatings && this.applicationState.InclueSeatingBookingFlow) {
      await this.navigationHelper.continue('/ratesRoom/1', '/seating/1');
    } else {
      await this.navigationHelper.continue('/ratesRoom/1', '/add-on/1');
    }
  }

  onSelectedRateClick(bookingIndex: number, room: Room, rate: Rate) {
    this.log.debug(`RoomRoute onSelectedRateClick(${room.Code})`, rate);

    const bookingToRemove = this.basket.bookings[bookingIndex];
    this.trackingService.ecommerceRemoveFromCart({
      item_name: `${bookingToRemove.RoomName}/${bookingToRemove.RateCode}`,
      item_id: `${bookingToRemove.RoomCode}/${bookingToRemove.RateCode}`,
      price: bookingToRemove.Price,
      item_category: 'Room',
      quantity: 1,
    });

    this.basket.bookings.splice(bookingIndex);
    this.basketService.set(this.basket);
  }

  changeImage(url: string, model: GalleryModel) {
    model.selectedImage = url;
  }

  resetBasketBadge() {
    this.basketService.basketBadgeNumberSet = '0';
  }

  async goBack() {
    await this.navigationHelper.goBack();
  }

  private async buildRatesUIModel(bookingIndex: number, companyId: number | undefined) {
    let loadedRates = await this.loader.using(async () => {
      const guests = [] as GuestCombination[];
      const item = this.basket.params.rooms[bookingIndex];
      const guest = {
        AdultCount: item.get(GuestType.Adult),
        ChildCount: item.get(GuestType.Child),
        InfantCount: item.get(GuestType.Infant),
        SeniorCount: item.get(GuestType.Senior),
        TeenagerCount: item.get(GuestType.UnknownType1),
      } as GuestCombination;
      guests.push(guest);

      const availableRatesAndRooms = await this.hotelService.GetSpectraRateInfo(
        this.applicationState.CurrentHotelCode,
        this.basket.params.arrival,
        DateHelper.addDays(this.basket.params.arrival, this.lengthOfStay),
        '',
        guests,
        await this.localeService.getLocale(),
        companyId,
      );

      const rateModels: RatesUI[] = [];

      if (availableRatesAndRooms) {
        this.roomsAndRates = availableRatesAndRooms;
        const usingPromotionCode = this.promotionService.getPromocode();
        let ratesWithPromotion = [] as GetPromotionCodeStatus[];
        let ratesWithCompanyAgreement = [] as CompanyAgreementDiscountResponseModel[];

        if (companyId) {
          ratesWithCompanyAgreement = await this.hotelService.GetCompanyAgreementDiscount(
            this.applicationState.CurrentHotelCode,
            companyId,
          );
        }

        if (usingPromotionCode) {
          ratesWithPromotion = await this.promotionService.getPromotionStatus(
            this.applicationState.CurrentHotelCode,
            usingPromotionCode,
          );

          let fromDate = DateHelper.addDays(new Date(), 365);
          let toDate = new Date();

          for (const promo of ratesWithPromotion) {
            if (DateHelper.FromUtcDate(promo.StayFromDate.toString() as UTCDateTimeString) < fromDate) {
              fromDate = DateHelper.FromUtcDate(promo.StayFromDate.toString() as UTCDateTimeString);
            }
            if (DateHelper.FromUtcDate(promo.StayToDate.toString() as UTCDateTimeString) > toDate) {
              toDate = DateHelper.FromUtcDate(promo.StayToDate.toString() as UTCDateTimeString);
            }
          }

          if (
            ratesWithPromotion.length !== 0 &&
            fromDate.getTime() >= this.basket.params.arrival.getTime() &&
            toDate.getTime() >= this.basket.params.arrival.getTime()
          ) {
            if (fromDate.getTime() !== this.basket.params.arrival.getTime()) {
              ratesWithPromotion = [] as GetPromotionCodeStatus[];
            }
          }

          if (
            ratesWithPromotion.length !== 0 &&
            fromDate.getTime() <= DateHelper.addDays(this.basket.params.arrival, this.basket.params.stay).getTime() &&
            toDate.getTime() <= DateHelper.addDays(this.basket.params.arrival, this.basket.params.stay).getTime()
          ) {
            if (
              toDate.getTime() !== DateHelper.addDays(this.basket.params.arrival, this.basket.params.stay).getTime()
            ) {
              ratesWithPromotion = [] as GetPromotionCodeStatus[];
            }
          }
        }

        for (const data of availableRatesAndRooms) {
          const info = await this.hotelService.GetECommerceRateInfo(
            this.applicationState.CurrentHotelCode,
            data.RatePlan,
            await this.localeService.getLocale(),
          );
          const rateModel = {
            FromPrice: 0,
            PromotionPrice: 0,
            Code: info.Code,
          } as RatesUI;

          for (const room of data.RoomTypes) {
            if ((rateModel.FromPrice && rateModel.FromPrice > room.Price) || rateModel.FromPrice === 0) {
              rateModel.FromPrice = room.Price;
            }
            rateModel.OriginalPrice = room.Price;
            rateModel.Price = room.Price;
          }

          if (ratesWithPromotion && ratesWithPromotion.length !== 0) {
            const possiblePromotionOnRate = ratesWithPromotion.find((x) => x.RatePlanCode === data.RatePlan);
            if (possiblePromotionOnRate) {
              const ratePrice = rateModel.FromPrice as number;

              rateModel.Price = ratePrice - ratePrice * (possiblePromotionOnRate.DiscountPercentage / 100);
              rateModel.PromotionCode = possiblePromotionOnRate.DiscountPercentage;

              if (
                (rateModel.PromotionPrice && rateModel.PromotionPrice > ratePrice) ||
                rateModel.PromotionPrice === 0
              ) {
                rateModel.PromotionPrice =
                  (rateModel.FromPrice as number) - ratePrice * (possiblePromotionOnRate.DiscountPercentage / 100);
              }
            }
          }

          if (ratesWithCompanyAgreement && ratesWithCompanyAgreement.length !== 0) {
            const possibleAgreementOnRate = ratesWithCompanyAgreement.find((x) => x.RatePlanCode === rateModel.Code);
            if (possibleAgreementOnRate) {
              const ratePrice = rateModel.FromPrice as number;

              rateModel.Price = ratePrice - ratePrice * (parseFloat(possibleAgreementOnRate.DiscountPercentage) / 100);
              rateModel.PromotionPrice =
                ratePrice - ratePrice * (parseFloat(possibleAgreementOnRate.DiscountPercentage) / 100);
              rateModel.PromotionCode = parseFloat(possibleAgreementOnRate.DiscountPercentage);
            }
          }

          rateModel.Image = info.Image;
          rateModel.Image1 = info.Image1;
          rateModel.Image2 = info.Image2;
          rateModel.ShortDescription = data.Name;
          rateModel.LongDescription = data.Description;
          rateModel.Sortindex = info.Sortindex;

          if (rateModels.findIndex((x) => x.Code === info.Code) < 0) {
            rateModels.push(rateModel);
          }
        }
      }
      return rateModels;
    }, 'LOA_Rooms');

    if (this.basket.params.RateCode !== undefined && this.basket.params.RateCode !== '') {
      loadedRates = loadedRates.filter(
        (x) => x.Code.toLowerCase() === (this.basket.params.RateCode as string).toLowerCase(),
      );
    }

    if (!loadedRates) {
      throw new RoomLookupError(new RoomsNotAvailableReason());
    } else if (loadedRates.every((item) => !item.Code)) {
      throw new RoomLookupError(new RoomsSoldOutReason());
    }

    return loadedRates.filter((x) => x.Price !== 0).sort((a, b) => (a.Sortindex > b.Sortindex ? 1 : -1));
  }

  private async buildRoomsForRate(bookingIndex: number, companyId: number | undefined) {
    if (this.selected$.value) {
      const rateCode = this.selected$.value.Code;
      const rooms: Room[] = [];

      if (this.roomsAndRates) {
        const data = this.roomsAndRates;

        const usingPromotionCode = this.promotionService.getPromocode();
        let ratesWithPromotion = [] as GetPromotionCodeStatus[];
        let ratesWithCompanyAgreement = [] as CompanyAgreementDiscountResponseModel[];

        if (companyId) {
          ratesWithCompanyAgreement = await this.hotelService.GetCompanyAgreementDiscount(
            this.applicationState.CurrentHotelCode,
            companyId,
          );
        }

        if (usingPromotionCode) {
          ratesWithPromotion = await this.promotionService.getPromotionStatus(
            this.applicationState.CurrentHotelCode,
            usingPromotionCode,
          );

          let fromDate = DateHelper.addDays(new Date(), 365);
          let toDate = new Date();

          for (const promo of ratesWithPromotion) {
            if (DateHelper.FromUtcDate(promo.StayFromDate.toString() as UTCDateTimeString) < fromDate) {
              fromDate = DateHelper.FromUtcDate(promo.StayFromDate.toString() as UTCDateTimeString);
            }
            if (DateHelper.FromUtcDate(promo.StayToDate.toString() as UTCDateTimeString) > toDate) {
              toDate = DateHelper.FromUtcDate(promo.StayToDate.toString() as UTCDateTimeString);
            }
          }

          if (
            ratesWithPromotion.length !== 0 &&
            fromDate.getTime() >= this.basket.params.arrival.getTime() &&
            toDate.getTime() >= this.basket.params.arrival.getTime()
          ) {
            if (fromDate.getTime() !== this.basket.params.arrival.getTime()) {
              ratesWithPromotion = [] as GetPromotionCodeStatus[];
            }
          }

          if (
            ratesWithPromotion.length !== 0 &&
            fromDate.getTime() <= DateHelper.addDays(this.basket.params.arrival, this.basket.params.stay).getTime() &&
            toDate.getTime() <= DateHelper.addDays(this.basket.params.arrival, this.basket.params.stay).getTime()
          ) {
            if (
              toDate.getTime() !== DateHelper.addDays(this.basket.params.arrival, this.basket.params.stay).getTime()
            ) {
              ratesWithPromotion = [] as GetPromotionCodeStatus[];
            }
          }
        }

        return await this.loader.using(async () => {
          try {
            const rate = data.find((x) => x.RatePlan === rateCode);
            if (rate) {
              for (const room of rate.RoomTypes) {
                if (rooms.filter((x) => x.Code === room.RoomType).length === 0) {
                  const newRoom = {} as Room;

                  const info = await this.hotelService.GetECommerceRoomInfo(
                    this.applicationState.CurrentHotelCode,
                    room.RoomType,
                    await this.localeService.getLocale(),
                  );

                  newRoom.Code = info.Code;
                  newRoom.Image = info.Image;
                  newRoom.Image1 = info.Image1;
                  newRoom.Image2 = info.Image2;
                  newRoom.Long = info.Long;
                  newRoom.MaxPers = info.MaxPers;
                  newRoom.MinPers = info.MinPers;
                  newRoom.Name = info.Name;
                  newRoom.Short = info.Short;
                  newRoom.OriginalPrice = room.Price;
                  newRoom.FromPrice = room.Price;
                  newRoom.SortIndex = info.SortIndex;

                  const possiblePromotionOnRate = ratesWithPromotion.find((x) => x.RatePlanCode === rateCode);

                  const possibleAgreementOnRate = ratesWithCompanyAgreement.find((x) => x.RatePlanCode === rateCode);

                  if (ratesWithPromotion.length !== 0 && possiblePromotionOnRate) {
                    newRoom.Price = room.Price - room.Price * (possiblePromotionOnRate.DiscountPercentage / 100);
                    newRoom.PromotionCode = possiblePromotionOnRate.DiscountPercentage.toString();
                  } else if (possibleAgreementOnRate) {
                    newRoom.Price =
                      room.Price - room.Price * (parseFloat(possibleAgreementOnRate.DiscountPercentage) / 100);
                    newRoom.PromotionCode = possibleAgreementOnRate.DiscountPercentage.toString();
                  } else {
                    newRoom.Price = room.Price;
                  }

                  if (info.MinPers <= this.basket.params.rooms[bookingIndex].getPersons()) {
                    rooms.push(newRoom);
                  }
                }
              }
            }
            this.presentationsRooms = rooms;
            return rooms.sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));
          } catch (err) {
            return undefined;
          }
        }, 'LOA_Rates');
      }
    }

    return [];
  }

  private getBasket(queryParams: Partial<BasketQueryParams>) {
    const hotelCode = SearchComponent.ParseHotelCode(queryParams);
    const arrival = SearchComponent.ParseArrival(queryParams);
    const stay = SearchComponent.ParseStay(queryParams);
    const rooms = SearchComponent.ParseRooms(queryParams);
    const single = SearchComponent.ParseSingle(queryParams);
    const rateCode = SearchComponent.ParseRateCode(queryParams);
    if (hotelCode && arrival && stay && rooms) {
      const params: SearchParams = {
        hotelCode,
        arrival,
        stay,
        rooms,
        single,
        ArrivalDate: 0,
        ArrivalFullYear: 0,
        ArrivalMonthName: '',
        DepartueDate: 0,
        DepartueFullYear: 0,
        DepartueMonthName: '',
        BookingFlow: 2,
        RateCode: rateCode,
        ConferenceRoomCode: '',
      };
      return this.basketService.create(params);
    } else {
      const basket = this.basketService.get();
      if (basket) {
        return basket;
      } else {
        throw new Error('Basket must not be empty');
      }
    }
  }
}

export interface RatesUI {
  Code: string;
  Image: string;
  Image1: string;
  Image2: string;
  Rate: number;
  ShortDescription: string;
  LongDescription: string | null;
  RoomStayAvailabilityCount: number;
  Price: number;
  FromPrice: number | null;
  PromotionPrice: number | null;
  PromotionCode: number | undefined;
  OriginalPrice: number | null;
  Sortindex: number;
}

interface GalleryModel {
  accept: () => void;
  close: () => void;
  nextImage: (image: string) => void;
  prevImage: (image: string) => void;
  roomData: Room | undefined;
  rateDataUI: RatesUI | undefined;
  rateData: Rate | undefined;
  selectedImage: string;
  imageArray: string[];
}

export class StorageClient<T> {
  private static UsedKeys: string[] = [];

  private key: string;

  /**
   *
   * @param storage localStorage or sessionStorage
   * @param name unique string name
   * @param data object to be placed
   * @param expiresAfter default 30 days. 1 day is 86400000
   * @param version default: 1. on update - old data will be removed
   * @param allowMultiple default: false. For testing purpose. Allow multiple instatnces of storage with same name.
   */
  constructor(
    private storage: StorageType,
    name: string,
    private data: T,
    private expiresAfter = 30 /*days*/ * 86400000,
    private version = 1,
    allowMultiple = false,
  ) {
    this.key = `Storage_${name}`;
    if (!allowMultiple) {
      StorageClient.RegisterKey(this.key);
    }
    try {
      const item = this.storage.getItem(this.key);
      if (item !== null) {
        const storedItem = JSON.parse(item) as StoredItem<T>;
        if (storedItem.v !== this.version) {
          throw new Error('StoredItem version mismatch');
        }
        if (storedItem.expires < new Date().getTime()) {
          throw new Error('StoredItem expired');
        }
      }
    } catch {
      this.clear();
    }
  }

  set(data: T) {
    const item: StoredItem<T> = {
      data,
      v: this.version,
      expires: new Date().getTime() + this.expiresAfter,
    };
    this.storage.setItem(this.key, JSON.stringify(item));
  }

  get() {
    const item = this.storage.getItem(this.key);
    return item !== null ? (JSON.parse(item) as StoredItem<T>).data : this.data;
  }

  clear() {
    this.storage.removeItem(this.key);
  }

  private static RegisterKey(key: string) {
    if (StorageClient.UsedKeys.indexOf(key) === -1) {
      StorageClient.UsedKeys.push(key);
      return key;
    } else {
      throw new Error(`Key named '${key}' is already registered.`);
    }
  }
}

interface StoredItem<T> {
  data: T;
  v: number;
  expires: number;
}

export interface StorageType {
  setItem(key: string, value: string): void;
  getItem(key: string): string | null;
  removeItem(key: string): void;
}

import { Component, ElementRef, ErrorHandler, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, lastValueFrom } from 'rxjs';
import { first, map } from 'rxjs/operators';

import {
  ApplicationStateService,
  BasketQueryParams,
  BasketService,
  Booking,
  ConfigService,
  Culture,
  CustomerService,
  HotelCodeString,
  HotelService,
  HotelType,
  Loader,
  Locale,
  LocaleService,
  PromoCodeString,
  PromotionService,
  SearchParams,
  TrackingService,
} from 'src/app/core';
import { AppErrorHandler } from 'src/app/core/app-error.handler';

import { LogService } from '@com/logging';
import { Messenger } from 'src/app/modules/messenger';
import { Step, StepList } from 'src/app/modules/step-list';
import { RoomGuests, UTCDateTimeString } from './helpers';
import { DateHelper } from './helpers';
import { NavigationHelper } from './helpers/navigation-helper';
import { LandingPageModel } from './pages/landing-page/landing-page.component';
import { DesignConfiguration } from './shared/design/design-configuration';

export interface AppQueryParams extends Partial<BasketQueryParams> {
  PrimaryLangID?: string;
  reason?: string;
  PromotionCode?: PromoCodeString;
  customerToken?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private logo = '';
  isSoldOutError = false;
  promotionInvalid = false;
  FooterInfo: HotelType | undefined;
  data$ = combineLatest([
    this.customerService.customer$,
    this.stepList.step$,
    this.localeService.locale$,
    this.basketService.hotelCode$,
  ]).pipe(
    map(([customer, step, locale, hotelCode]) => ({
      logo: step.step === Step.Search && !step.hasError ? this.logo : Promise.resolve(undefined),
      customer,
      canChangePromo:
        step.step === Step.Search ||
        step.step === Step.Package ||
        step.step === Step.AddOn ||
        step.step === Step.Customer,
      canChangeCustomer:
        step.step === Step.Search ||
        step.step === Step.Package ||
        step.step === Step.AddOn ||
        step.step === Step.Customer,
      showBasket:
        step.step === Step.Search ||
        step.step === Step.Package ||
        step.step === Step.AddOn ||
        step.step === Step.Customer,
      locale,
      hotel: (async () => {
        if (hotelCode) {
          return await this.hotelService.GetECommerceDepartmentInformation(hotelCode, locale);
        }
        return undefined;
      })(),
    })),
  );
  title = 'Spectra-E-Commerce-Platform';
  cultures: Culture[] = [];
  culture$ = this.localeService.locale$.pipe(map((locale) => this.cultures.find((culture) => culture.Code === locale)));
  version = this.config.getVersion();
  showLoginPopup = false;
  showPromoPopup = false;
  promocode$ = this.promotionService.promocode$;
  promoCodeStartDate: Date | undefined;
  promoCodeStartMonth: string | undefined;
  promoCodeEndDate: Date | undefined;
  promoCodeEndMonth: string | undefined;

  // Link and icon show/hide management
  showLinkbar = true;
  showBasket = true;
  showLoginPromotion = true;

  showMobileMenu = false;
  showMobileNavigationFooter = true;
  showMobileContinue = true;
  isPaymentLink = false;

  activeProducts: LandingPageModel[] | null | undefined;

  @ViewChild('header', { static: false }) header: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('messageErrorTempalte', { static: false }) messageErrorTempalte: TemplateRef<ErrorMessage> | undefined;
  @ViewChild('messageConfirmResetTemplate', { static: false })
  messageConfirmResetTemplate: TemplateRef<ConfirmResetMessage> | undefined;
  @ViewChild('messageActiveAccount', { static: false }) messageActiveAccount:
    | TemplateRef<ActivateAccountMessage>
    | undefined;
  @ViewChild('messageSuccess', { static: false }) messageSuccess: TemplateRef<GuestMessage> | undefined;

  constructor(
    private config: ConfigService,
    private router: Router,
    public navigationHelper: NavigationHelper,
    private route: ActivatedRoute,
    private log: LogService,
    private hotelService: HotelService,
    private basketService: BasketService,
    private localeService: LocaleService,
    private promotionService: PromotionService,
    private loader: Loader,
    private customerService: CustomerService,
    private stepList: StepList,
    private messenger: Messenger,
    private errorHandler: ErrorHandler,
    private activatedRoute: ActivatedRoute,
    private designConfig: DesignConfiguration,
    public applicationState: ApplicationStateService,
    protected trackingService: TrackingService, // ensure that this service is initialized with application(to send correct data to google)
  ) {
    this.promotionInvalid = false;
    if (errorHandler instanceof AppErrorHandler) {
      (this.errorHandler as AppErrorHandler).errors.subscribe((err) => {
        if (this.messageErrorTempalte) {
          if (err.message === '{"t":"SoldOut"}') {
            this.isSoldOutError = true;
            const message = this.messenger.show(this.messageErrorTempalte, {
              text: 'COM_SoldOutError',
              close: async () => {
                this.messenger.close(message);
                this.navigationHelper.goToUrl(applicationState.CustomLogoRedirectURL);
              },
            });
          } else if (err.message === 'SeatingSoldOut') {
            this.isSoldOutError = true;
            const message = this.messenger.show(this.messageErrorTempalte, {
              text: 'COM_SeatingSoldOut',
              close: async () => {
                this.messenger.close(message);
                this.navigationHelper.goToUrl(applicationState.CustomLogoRedirectURL);
              },
            });
          } else if (err.message === 'Promotion code is invalid') {
            this.promotionInvalid = true;
          }
          this.loader.hideAll();
        } else {
          this.log.fatal(`messageErrorTempalte must be defined`);
        }
      });
    }

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.trackingService.pageView(`/booking/` + val.urlAfterRedirects);
      }
    });
  }

  async ngOnInit() {
    this.ListenToRouteChange();
    await new Promise<void>((resolve) => setTimeout(() => resolve(), 0)); // wait for router to initialize
    await this.getSearchParameters();
    const queryParams = this.route.snapshot.queryParams as AppQueryParams;
    await this.applyLanguage(queryParams.PrimaryLangID);
    await this.designConfig.applyTheme();
    this.applyPromocode(queryParams.PromotionCode);
    await this.checkForCustomerToken(queryParams.customerToken);
    this.logo = this.applicationState.ApplicationLogo;
    await this.loadNetsScript();
  }

  async getSearchParameters() {
    const searchParams = {} as SearchParams;
    this.activatedRoute.queryParams.subscribe(async (params) => {
      searchParams.hotelCode = params['HotelCode'];

      const arrival = params['ArrivalDate'];
      if (arrival) {
        searchParams.arrival = DateHelper.FromUtcDate(arrival);
      }
      searchParams.stay = params['LengthOfStay'];
      searchParams.rooms = [params['RoomCounts']] as unknown as RoomGuests[];
      searchParams.BookingFlow = params['BookingFlow'];
      searchParams.RateCode = params['RateCode'];

      if (searchParams.hotelCode) {
        this.applicationState.CurrentHotelCode = searchParams.hotelCode as HotelCodeString;

        await this.loader.using(
          async () => {
            await this.hotelService.BuildApplicationState();
          },
          'LOA_Configuration',
          false,
        );
      } else {
        if (!this.applicationState.CurrentHotelCode && !searchParams.hotelCode) {
          await this.loader.using(
            async () => {
              if (!this.isPaymentLink) {
                const hotels = await this.hotelService.GetECommerceDepartmentsInformation();

                this.applicationState.CurrentHotelCode = hotels[0].Code as HotelCodeString;
                searchParams.hotelCode = this.applicationState.CurrentHotelCode;
                await this.hotelService.BuildApplicationState();
              }

              await this.designConfig.applyTheme();
            },
            'LOA_Configuration',
            false,
          );
        }
      }

      await this.loader.using(
        async () => {
          this.FooterInfo = await this.hotelService.GetECommerceDepartmentInformation(
            this.applicationState.CurrentHotelCode,
            await this.localeService.getLocale(),
          );
          this.applicationState.HotelBasketInfo = this.FooterInfo;
        },
        'LOA_Configuration',
        false,
      );
    });
  }

  async loadNetsScript() {
    const node = document.createElement('script');
    const config = await this.config.getAppSettings();
    node.src = config.NetsCheckoutScript;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';

    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ListenToRouteChange() {
    this.router.events.subscribe((val) => {
      const basket = this.basketService.get();
      const ttt = val as NavigationEnd;
      if (ttt instanceof NavigationEnd) {
        this.showMobileNavigationFooter = true;
        if (ttt.url.includes('/search') || ttt.url.includes('/conference-search')) {
          this.showLinkbar = true;
          this.showBasket = false;
          this.showLoginPromotion = true;
        } else if (ttt.url.includes('/landing-page') || ttt.urlAfterRedirects.includes('/landing-page')) {
          this.showLinkbar = false;
          this.showBasket = false;
          this.showLoginPromotion = false;
          this.showMobileNavigationFooter = false;
        } else if (ttt.url.includes('/add-on')) {
          this.showMobileContinue = true;
        } else if (ttt.url.includes('/room') || ttt.url.includes('/ratesRoom')) {
          this.showLinkbar = true;
          this.showBasket = true;
          if (basket) {
            this.showMobileContinue = basket.bookings.length !== 0;
          }
        } else if (ttt.url.includes('/reciept')) {
          this.showBasket = false;
          this.showLinkbar = false;
        } else if (ttt.url.includes('/guests')) {
          this.showLinkbar = true;
          this.showMobileContinue = true;
        } else if (ttt.url.includes('/giftcard')) {
          this.showLinkbar = true;
          this.showBasket = true;
          this.showMobileContinue = true;
        } else if (ttt.url.includes('/seating')) {
          this.showLinkbar = true;
          this.showBasket = true;
          if (basket) {
            this.showMobileContinue = basket.bookings.length !== 0;
          }
        } else if (ttt.url.includes('/payment-link') || ttt.url.includes('/payment')) {
          this.showLinkbar = false;

          if (ttt.url.includes('/payment-link')) {
            this.isPaymentLink = true;
          }
        } else {
          this.showLinkbar = true;
          this.showBasket = true;
          this.isPaymentLink = false;
        }
      }
    });
  }

  async getCustomerEmailByToken(token: string) {
    this.log.trace(`App getCustomerEmailByToken(${token})`);
    return await this.loader.using(async () => {
      try {
        return await this.customerService.getCustomerByToken(token);
      } catch (err) {
        throw new Error(
          await lastValueFrom(
            this.localeService.translations$.pipe(
              first(),
              map((t) => t.APA_CustomerNotFound),
            ),
          ),
        );
      }
    }, 'LOA_CustomerInfo');
  }

  async activateCustomer(token: string, password: string) {
    this.log.debug(`App activateCustomer(${token}, ***)`);
    await this.loader.using(async () => {
      await this.customerService.activate(token, password);
    }, 'LOA_ActivatingCustomer');
  }

  async onLocaleSelect(locale: Locale) {
    this.log.debug(`App onLocaleSelect(${locale})`);
    await this.loader.using(async () => {
      try {
        await this.localeService.setLocale(locale);
      } catch (err) {
        this.log.error(`App onLocaleSelect(${locale}) failed. ${(err as Error).message}`);
        throw new Error(`There are no translations for ${locale} language.`);
      }
    }, 'LOA_Translations');
  }

  async onPromoSubmit() {
    this.log.debug(`App onPromoSubmit()`);
    this.showPromoPopup = false;
    if (this.promocode$.value && this.applicationState.CurrentHotelCode) {
      const promoInfo = await this.promotionService.getPromotionStatus(
        this.applicationState.CurrentHotelCode as HotelCodeString,
        this.promocode$.value,
      );
      if (promoInfo) {
        this.promoCodeStartDate = DateHelper.FromUtcDate(promoInfo[0].StayFromDate.toString() as UTCDateTimeString);
        this.promoCodeEndDate = DateHelper.FromUtcDate(promoInfo[0].StayToDate.toString() as UTCDateTimeString);
        this.promoCodeStartMonth = DateHelper.getTranslatedMonth(this.promoCodeStartDate.getMonth());
        this.promoCodeEndMonth = DateHelper.getTranslatedMonth(this.promoCodeEndDate.getMonth());
      }
    }

    if (this.messageSuccess && this.promocode$.value) {
      const message = this.messenger.show(this.messageSuccess, {
        close: () => {
          this.messenger.close(message);
        },
      });
    }
  }

  onShowPromoPopupClick() {
    this.log.trace(`App onShowPromoPopupClick()`);
    this.showPromoPopup = true;
  }

  BasketClick() {
    this.hideMobileMenu();
    this.basketService.showOrHideBasket();
  }

  onClosePromoPopup() {
    this.log.trace(`App onClosePromoPopup()`);
    this.showPromoPopup = false;
    this.hideMobileMenu();
  }

  onShowLoginPopupClick() {
    this.log.trace(`App onShowLoginPopupClick()`);
    this.confirmResetAndRunAction(() => {
      this.showLoginPopup = true;
    });
  }

  onShowLoginPopupClose() {
    this.log.trace(`App onShowLoginPopupClose()`);
    this.showLoginPopup = false;
    this.hideMobileMenu();
  }

  onLogoutClick() {
    this.log.debug(`App onLogoutClick()`);
    this.confirmResetAndRunAction(() => {
      this.customerService.logout();
    });
  }

  async seatingLink() {
    this.hideMobileMenu();
    await this.navigationHelper.continue('/search', '/seating/1', {
      queryParams: {
        HotelCode: this.applicationState.CurrentHotelCode,
        ArrivalDate: DateHelper.toServerDateFormatString(new Date()),
        LengthOfStay: 30,
      },
    });
  }

  async giftcardLink() {
    this.hideMobileMenu();
    await this.navigationHelper.continue('/search', '/giftcard', {
      queryParams: {
        HotelCode: this.applicationState.CurrentHotelCode,
        ArrivalDate: DateHelper.toServerDateFormatString(new Date()),
        LengthOfStay: 30,
      },
    });
  }

  async tableReservationLink() {
    this.hideMobileMenu();
    await this.navigationHelper.continue('/landing-page', '/guests');
  }

  async conferenceBookingLink() {
    this.hideMobileMenu();
    await this.navigationHelper.continue('/landing-page', '/conference-search');
  }

  private hideMobileMenu() {
    this.showMobileMenu = false;
  }
  clickLocale() {
    this.hideMobileMenu();
  }

  menuClick() {
    // Toggle showMobileMenu value.
    this.showMobileMenu = !this.showMobileMenu;
  }

  async logoClicked() {
    if (this.applicationState.CustomLogoRedirectURL !== '') {
      document.location.href = this.applicationState.CustomLogoRedirectURL;
    } else {
      this.basketService.basketBadgeNumberSet = '0';
      await this.navigationHelper.navigateToPage('/landing-page');
    }
  }

  async ratesClick() {
    this.hideMobileMenu();
    await this.navigationHelper.continue('/search', '/ratesRoom/1', {
      queryParams: { HotelCode: this.applicationState.CurrentHotelCode },
    });
  }

  async roomClick() {
    this.hideMobileMenu();
    await this.navigationHelper.continue('/search', '/room/1', {
      queryParams: { HotelCode: this.applicationState.CurrentHotelCode },
    });
  }

  async goBack() {
    await this.navigationHelper.goBack();
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  async goHome() {
    if (this.applicationState.CustomLogoRedirectURL !== '') {
      document.location.href = this.applicationState.CustomLogoRedirectURL;
    } else {
      this.basketService.basketBadgeNumberSet = '0';
      await this.navigationHelper.navigateToPage('/landing-page');
    }
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  private updateGiftCard() {
    const basket = this.basketService.get();
    if (basket) {
      // We allways save giftcards on index 0 in the basket on the bookingarray, just like seatings.
      const giftcards = basket.bookings[0].Giftcards;

      if (giftcards) {
        for (const item of giftcards) {
          item.TotalPrice = item.Count * item.Price;
        }
      }
      this.basketService.set(basket);
    }
  }

  async next() {
    this.updateGiftCard();
    await this.navigationHelper.continueMobileNavigationbar(true, false);
  }

  private confirmResetAndRunAction(action: () => void) {
    const basket = this.basketService.get();
    if (basket && basket.bookings.filter((booking: Booking) => booking.HasPromotionForCustomer).length !== 0) {
      if (!this.messageConfirmResetTemplate) {
        throw new Error(`Template for reset confirmation message must exist`);
      }
      const message = this.messenger.show(this.messageConfirmResetTemplate, {
        confirm: async () => {
          action();
          this.messenger.close(message);
          await this.router.navigate(['/room/1'], {
            queryParams: BasketService.BuildQueryParams(basket.params),
          });
        },
        cancel: () => {
          this.messenger.close(message);
        },
      });
    } else {
      action();
    }
  }

  private async applyLanguage(lang: string | undefined) {
    await this.loader.using(
      async () => {
        try {
          this.cultures = await this.localeService.getCultures();
          const locale = await this.localeService.determineLocale(this.cultures, lang);
          await this.localeService.setLocale(locale);
        } catch (err) {
          this.log.fatal(`App applyLanguage() failed. ${(err as Error).message}`);
          await this.localeService.setLocale(Locale.GB);
          throw err;
        }
      },
      undefined,
      false,
    );
  }

  private applyPromocode(promocode: PromoCodeString | undefined) {
    if (promocode !== undefined) {
      this.promotionService.promocode$.next(promocode);
    }
  }

  private async checkForCustomerToken(customerToken: string | undefined) {
    if (customerToken) {
      if (!this.messageActiveAccount) {
        throw new Error(`messageActiveAccount variable must exists`);
      }
      const email = await this.getCustomerEmailByToken(customerToken);
      const message = this.messenger.show(
        this.messageActiveAccount,
        {
          email,
          activate: async (f: NgForm) => {
            if (f.valid) {
              const password = f.form.get('password');
              if (password) {
                try {
                  await this.activateCustomer(customerToken, password.value);
                  this.messenger.close(message);
                  const queryParams: AppQueryParams = {
                    customerToken: undefined,
                  };
                  await this.router.navigate([], {
                    replaceUrl: true,
                    relativeTo: this.route,
                    queryParamsHandling: 'merge',
                    queryParams,
                  });
                } catch (err) {
                  message.data.error = (err as Error).message;
                }
              } else {
                message.data.error = `password field must exist`;
              }
            } else {
              Object.keys(f.form.controls).forEach((key) => {
                const control = f.form.get(key);
                if (control) {
                  control.markAsTouched();
                }
              });
            }
          },
          cancel: async () => {
            this.messenger.close(message);
            const queryParams: AppQueryParams = { customerToken: undefined };
            await this.router.navigate([], {
              replaceUrl: true,
              relativeTo: this.route,
              queryParamsHandling: 'merge',
              queryParams,
            });
          },
        },
        () => {
          message.data.cancel();
        },
      );
    }
  }
}

interface ErrorMessage {
  text: string;
  close: () => void;
}

interface ConfirmResetMessage {
  confirm: () => void;
  cancel: () => void;
}

interface ActivateAccountMessage {
  email: string;
  error?: string;
  activate: (f: NgForm) => void;
  cancel: () => void;
}

interface GuestMessage {
  close: () => void;
}

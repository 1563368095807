import { Messages } from './index';

export const messages: Messages = {
  APA_CompanyLogin: `selskapet innlogging`,
  APA_Logout: `Logg ut`,
  APA_Welcome: `Hallo`,
  APA_ShowPromo: `Kampanjekode`,
  APA_PromoLabel: `Skriv inn kampanjekoden`,
  APA_Promo: `Kampanjekode`,
  APA_PromoSubmit: `Bruk`,
  APA_PromoChange: `endring`,
  APA_ActivateHead: `Aktiver pålogging`,
  APA_ActivateDesc: `Skriv inn passord for å aktivere pålogging for`,
  APA_Password: `Passord`,
  APA_ConfirmPassword: `bekreft passord`,
  APA_PasswordHasUpper: `Passordet må inneholde minst én stor bokstav (A-Z)`,
  APA_PasswordHasLower: `Passord må inneholde minst en liten bokstav (a-z)`,
  APA_PasswordHasNumber: `Passordet må inneholde minst ett tall (0-9)`,
  APA_PasswordLength8: `Passordet må inneholde minst 8 tegn`,
  APA_ConfirmPasswordEqualTo: `Passord stemmer ikke`,
  APA_Activate: `Aktiver`,
  APA_CustomerNotFound: `Beklager at vi ikke kan finne kundepost i ut database`,
  APA_ResetBasket: `Alt vil bli fjernet fra kurven. Er du sikker?`,
  APA_Address: `Adresse`,
  APA_Contact: `Kontakt`,
  APA_VatNumber: `CVR:`,
  APA_PromoMessageStart: `Kampagne koden`,
  APA_ValidTime: `Gyldigheds periode`,
  APA_PromoMessageEnd: `Koden er gyldig på de produkter der er markeret med en procentvis rabat`,
  APA_AQCDecription: `Aldersbeskrivelse`,
  APA_Year: `år`,
  APA_Book: `Bestill`,

  CPA_NothingAvailable_EmptyText: `Vennligst velg en ny dato nedenfor, eller gå tilbake og endre informasjonen fra det første skrittet`,
  CPA_NothingAvailable: `Det er ingen tilgjengelige rom som tilfredsstiller dine kriterier`,
  CPA_SoldOut: `Utsolgt`,
  CPA_CustomerInvalid: `Kunden er ikke gyldig`,
  CPA_PromoInvalid: `Kampanjekoden du skrev inn er ugyldig.`,
  CPA_PromoBetween: `Kampanjekoden du skrev inn er bare gyldig hvis oppholdet perioden er mellom:`,

  RPA_More: `Vis mer`,
  RPA_Select: `Å velge`,
  RPA_Selected: `valgt`,
  RPA_From: `Starter fra`,
  RPA_Image: `Ikke noe bilde`,
  RPA_Description: `Beskrivelse`,
  RPA_ChooseRoom: `Velg rom`,
  RPA_ChoosePackage: `Velg Pakke`,
  RPA_ChooseCabin: `Velg hytte`,
  RPA_ChooseHouse: `Velg hus`,
  RPA_ChooseUnit: `Velg enhet`,
  RPA_BackRate: `Velg en annen pakke`,
  RPA_MaxPersons: `Maksimalt antall personer`,
  RPA_ChooseYourRoom: `Velg rommet ditt`,
  RPA_ChooseYourCabin: `Velg hytta`,
  RPA_ChooseYourHouse: `Velg huset`,
  RPA_ChooseYourUnit: `Velg enheten din`,
  RPA_ChooseYourPackage: `Velg pakken`,

  APA_Services: `tjenester`,
  APA_Add: `Legg til`,
  APA_Added: `La til`,
  APA_Remove: `Fjerne`,
  APA_ContentDialog: `Velg antall personer og dager du vil ha tjenesten valgt. Mellom datoene`,
  APA_ChooseYourAddOns: `Velg ekstra kjøp`,
  APA_TotalPrice: `Totalkostnad`,
  APA_TotalInBasket: `stk, totalt`,
  APA_TotalOf: `Velg mengde av`,

  CUP_Header: `gjest detaljer`,
  CUP_Accept: `Aksepterer`,
  CUP_FName: `Navn`,
  CUP_FNameReq: `Fornavn er påkrevd`,
  CUP_FPhoneReq: `Mobilnummer er påkrevd`,
  CUP_LName: `Etternavn`,
  CUP_LNameReq: `Etternavn er påkrevd`,
  CUP_Address: `Adresse`,
  CUP_AddressReq: `Adresse er påkrevd`,
  CUP_ZipCode: `Postnummer`,
  CUP_ZipCodeReq: `Postnummer er nødvendig`,
  CUP_City: `By`,
  CUP_CityReq: `Byen er nødvendig`,
  CUP_Contry: `Land`,
  CUP_ContryReq: `Land er nødvendig`,
  CUP_Phone: `Mobiltelefonnummer`,
  CUP_PhoneReq: `Mobiltelefon er nødvendig`,
  CUP_Email: `e-post`,
  CUP_EmailRepeat: `Gjenta e-post`,
  CUP_EmailReq: `E-post er påkrevd`,
  CUP_EmailInvalid: `E-post er ikke gyldig`,
  CUP_Comment: `Kommentar`,
  CUP_NeedSubscribe: `Abonner på nyhetsbrev`,
  CUP_AcceptTerms: `Vilkår og betingelser`,
  CUP_BookNow: `Bestill nå!`,
  CUP_CancelPolicyHeader: `Avbestillingsforsikring`,
  CUP_CancelPolicyDesc: `Du har muligheten til å avbryte oppholdet ditt hvis du ikke kan gjøre det. Se mer informasjon om avbestillingsforsikring i vår booking forhold.`,
  CUP_CancelPolicyLabel: `Ja, jeg ønsker å kjøpe avbestillingsforsikring for`,
  CUP_GuestInfo: `Gjest informasjon`,
  CUP_BookingOverview: `Reserveringsoversikt`,
  CUP_PayWithGiftcard: `Å betale med gavekort`,
  CUP_PayWithGiftcardClick: `Klikk her`,
  CUP_PayWithGiftcardDescription: `Skriv inn koden din fra gavekortet ditt`,
  CUP_PayWithGiftcardError: `Gavekort er ikke aktive eller ble ikke funnet i systemet.`,
  CUP_PayWithGiftcardStatus: `Gavekort aktivt`,
  CUP_PayWithGiftcardDiscount: `Gavekortbeløp`,
  CUP_PayWithGiftcardHeader: `Gavekort`,
  CUP_PayWithGiftcardInformation: `Vil du løse inn et gavekort?`,
  CUP_PayWithGiftcardPlaceholder: `Gavekortkode`,
  CUP_PayWithGiftcardRedeem: `Løs inn`,
  CUP_BookingOverviewToPay: `Å betale`,

  PAL_GoToPayment: `Gå til betaling`,
  PAL_PaymentComplete: `Denne betalingen er fullført`,
  PAL_PaymentNotAvailable: `Betaling på denne reservation er ikke tilgængelig`,

  PAR_Payment: `innbetaling`,
  PAR_SeekAuthorization: `MERK: I feltene nedenfor, er beløpet vi trekke på kontoen din angitt på betalingen. Hvis 0 er angitt, vil vi bruke dine kredittkortopplysninger utelukkende for å garantere din reservasjon.`,
  PAR_TotalLabel: `Pris for bestillingen`,
  PAR_TotalPayLabel: `Pris å betale`,
  PAR_OBSLabel: `MERK: bli på siden til kvitteringssiden vises med et reservasjonsnummer Kostnaður at gjalda`,
  PAR_OBSLabelGiftcard: `MERK: bli på siden til kvitteringssiden vises med et ordrenummer`,
  PAR_FailedToPay: `Unnlatt å betale. Sjekk ditt kortnummer.`,
  PAR_CardInvalid: `Kortnummeret er ikke gyldig`,
  PAR_CardUnknown: `Korttype i ikke kjent`,
  PAR_CardMonthInvalid: `Måned er ugyldig`,
  PAR_CardYearInvalid: `Året er ugyldig`,
  PAR_CardCVCInvalid: `CVV-koden er ugyldig`,
  PAR_FieldRequired: `Dette feltet er obligatorisk`,
  PAR_Cancel: `Avbryt`,
  PAR_Pay: `Betale`,
  PAR_PaymentMethod: `Betalingsmetoder`,
  PAR_Cardnumber: `Kortnummer`,
  PAR_MonthPlaceholder: `MM`,
  PAR_YearPlaceholder: `ÅÅ`,

  PFP_Header: `Hov! Dessverre har oppstått en feil`,
  PFP_Info: `Og bestillingen har ikke gått gjennom. Vennligst prøv igjen ved å klikke på knappen nedenfor. Ingen penger har blitt trukket fra kontoen din.`,
  PFP_TryAgain: `Prøv igjen`,

  REP_Header: `Takk for din bestilling,`,
  REP_Comment: `Nedenfor vil du finne kvitteringen for bestillingen.`,
  REP_DateRooms: `Reisedato`,
  REP_Return: `Gå til nettsiden`,
  REP_OrderId: `Din ordrenummer`,
  REP_ReservationId: `Din reservasjon ID`,
  REP_CustomerInfo: `Personlig informasjon`,

  REP_Name: `Navn`,
  REP_PostCity: `ZipCode & City`,
  REP_Phone: `telefon`,
  REP_Address: `Adresse`,
  REP_Country: `Land`,
  REP_Email: `e-post`,
  REP_Thanks: `takk for din bestilling`,
  REP_ThanksInfo: `Vi vil sende deg all den informasjonen om kort tid.`,
  REP_ThanksPayment: `Vår betaling er fullført`,

  LOA_Addons: `Laster add-ons`,
  LOA_CustomerInfo: `Laster kundeinformasjon`,
  LOA_ActivatingCustomer: `aktivering kunde`,
  LOA_Translations: `Legge oversettelser`,
  LOA_Configuration: `Laster konfigurasjon`,
  LOA_Availability: `Laster tilgjengelighet`,
  LOA_CreatingReservation: `Opprette bestilling`,
  LOA_HotelInformation: `Laster informasjon`,
  LOA_Countries: `Legge land`,
  LOA_PreparingPaymnent: `forbereder betaling`,
  LOA_ApplyingPromo: `Bruk av kampanjekode`,
  LOA_Rooms: `Legge rom`,
  LOA_Rates: `Legge priser`,
  LOA_LoggingIn: `Logger inn`,
  LOA_SendingInformation: `sende informasjon`,

  COM_Continue: `Fortsette`,
  COM_OK: `OK`,
  COM_Persons: `Mennesker`,
  COM_Guest_10: `Voksne`,
  COM_Guest_9: `Teens`,
  COM_Guest_7: `spedbarn`,
  COM_Guest_11: `pensjonister`,
  COM_Guest_8: `barn`,
  COM_Adult: `Voksen`,
  COM_Child: `Barn`,
  COM_Room: `Rom`,
  COM_Cabin: `Hytte`,
  COM_House: `Hus`,
  COM_Unit: `Enhet`,
  COM_Arrival: `Ankomst`,
  COM_Departure: `Avgang`,
  COM_Promocode: `Rabattkode`,
  COM_Rooms: `Rom`,
  COM_AddOns: `Add-ons`,
  COM_TotalGuests: `antall gjester`,
  COM_Price: `Pris`,
  COM_TotalPrice: `Totalpris`,
  COM_TotalPriceWithTransactionId: `betalte totalt med transactionId`,
  COM_TotalReservedWithTransactionId: `reservert totalt med transactionId`,
  COM_Close: `Lukk`,
  COM_Back: `Tilbake`,
  COM_Error: `Info`,
  COM_Info: `Informasjon`,
  COM_Confirm: `Bekrefte`,
  COM_Package: `Pakke`,
  COM_SoldOutError: `Det er ingen tilgjengelige rom eller pakker for denne dagen`,
  COM_SearchNew: `Nytt søk`,
  COM_TableReservation: `Bordreservasjon`,
  COM_TableNumberOfPersons: `Antall personer ved bordet`,
  COM_Guests: `Gjestene`,
  COM_TableChooseProduct: `Velg dato og produkt`,
  COM_TablePlaceGuests: `Du må plassere`,
  COM_TableChooseProducts: `Velg produkt(er)`,
  COM_TableNumberOfGuestsForProduct: `Antall gjester for dette produktet`,
  COM_TableBookingFlowAddProduct: `Ønsker du å reservere bord på oppholdet ditt?`,
  COM_TableIfNotPressContinue: `Trykk ellers på fortsett`,
  COM_AddToBasket: `Annonse til kurven`,
  COM_AddedToBasket: `Lagt til i handlekurven`,
  COM_Type: `Type`,
  COM_SeatingSoldOut: `Ingenting tilgjengelig for øyeblikket`,
  COM_Main: `Hjemmeside`,
  COM_Conference: `Konference`,
  COM_Quantity: `Mengde`,

  LFO_Email: `e-post`,
  LFO_EmailRequried: `E-post er påkrevd`,
  LFO_EmailInvalid: `E-post er ikke gyldig`,
  LFO_Password: `Passord`,
  LFO_PasswordRequried: `Passord kreves`,
  LFO_ForgotPassword: `Får ikke tilgang til kontoen din?`,
  LFO_Login: `Logg Inn`,
  LFO_BackToLogin: `Tilbake til Logg inn`,
  LFO_Send: `Sende`,
  LFO_MessageSend: `Message sende!`,
  LFO_LoginError: `Feil e-post eller passord`,
  LFO_RecoveryError: `Påloggingsinformasjon ugyldig`,

  SFO_ChooseHotel: `Våre hoteller`,
  SFO_ChooseHolidayResort: `Våre Ferie resorts`,
  SFO_ChooseCenter: `Våre senter`,
  SFO_ChooseDepartment: `Velg Avdeling`,
  SFO_ParamsHeader: `Velg dato`,
  SFO_Guests: `Gjester i rommet`,
  SFO_GuestsCabin: `Gjester i Hytte`,
  SFO_GuestsHouse: `Gjester i Hus`,
  SFO_GuestsUnit: `Gjester i enhet`,
  SFO_AddRoom: `Legg rommet`,
  SFO_AddCabin: `Legg hytter`,
  SFO_AddHouse: `Legg hus`,
  SFO_AddUnit: `Legg enheter`,
  SFO_BookStay: `opphold`,
  SFO_BookEvent: `Event`,
  SFO_BookTakeAway: `Ta bort`,
  SFO_BookTableReservation: `Tabell reservasjon`,
  SFO_BookGiftcard: `Gavekort`,
  SFO_GiftcardMetaData: `Grunnleggende data for gavekortene dine`,
  SFO_ChooseGiftcard: `Velg gavekort`,
  SFO_NumberOfGiftcards: `Hvor mange av de valgte gavekortene vil du kjøpe?`,
  SFO_GiftcardAmount: `Tast inn beløp`,
  SFO_GiftcardEnterDetails: `Fyll ut detaljer for gavekortene dine`,
  SFO_ChooseGiftcardDescription: `Kjøp et gavekort til noen du bryr deg om`,
  SFO_GiftcardDetails: `Gavekortdetaljer`,
  SFO_GiftcardTo: `Hvem er gavekortet til (valgfritt)`,
  SFO_GiftcardFrom: `Hvem er gavekortet fra (valgfritt)`,
  SFO_GiftcardMessage: `Skriv en hilsen (maks 100 tegn) (valgfritt)`,
  SFO_BookArrangement: `Ordning`,
  SFO_BookConcert: `Konsert`,
  SFO_BookTableArangement: `Bord / arrangement`,
  SFO_BookTableEvent: `Bord / arrangement`,
  SFO_BordTakeAway: `Bord / ta bort`,
  SFO_BookTicket: `Billett`,
  SFO_BookLecture: `Foredrag`,
  SFO_BookTable: `Reservere et bord`,
  SFO_BookSpa: `Spa`,
  SFO_BookExperience: `Opplevelse`,
  SFO_ChooseMonth: `Velg måned`,
  SFO_NoRooms: `Ingen tilgjengelige rom funnet for den valgte perioden!`,
  SFO_MinLOS: `Den valgte perioden krever bestilling av minimum`,
  SFO_Nights: `netter`,
  SFO_MaxLOS: `I den valgte perioden er det mulig å bestille maks`,

  CAL_WeekStart: `1`,
  CAL_M01: `Januar`,
  CAL_M02: `Februar`,
  CAL_M03: `Mars`,
  CAL_M04: `April`,
  CAL_M05: `Mai`,
  CAL_M06: `Juni`,
  CAL_M07: `Juli`,
  CAL_M08: `August`,
  CAL_M09: `September`,
  CAL_M10: `Oktober`,
  CAL_M11: `November`,
  CAL_M12: `Desember`,
  CAL_D0: `Søndag`,
  CAL_D1: `Mandag`,
  CAL_D2: `Tirsdag`,
  CAL_D3: `Onsdag`,
  CAL_D4: `Torsdag`,
  CAL_D5: `Fredag`,
  CAL_D6: `Lørdag`,
  CAL_Selected: `valgt`,
  CAL_Occupied: `lukket`,
  CAL_Arrival: `stengt for ankomst`,
  CAL_Departure: `stengt for avgang`,
  CAL_Mon: `M`,
  CAL_Tirs: `T`,
  CAL_Ons: `O`,
  CAL_Tors: `T`,
  CAL_Fre: `F`,
  CAL_Lor: `L`,
  CAL_Sun: `S`,
  CAL_Available: `Tilgjengelig`,
  CAL_NotPossible: `Ikke mulig`,

  BIN_ChangeDate: `Endre dato`,
  BIN_ChangeRoom: `Prøve rom`,
  BIN_ChangeAddOns: `Endre add-ons`,
  BIN_NoAddOns: `Ingen tjenester lagt`,
  BIN_AddOns: `tjenester lagt`,
  BIN_Reservation: `Din reservasjon`,
  BIN_Details: `Grunnleggende detaljer`,
  BIN_RoomCount: `Rom`,
  BIN_CabinCount: `Hytter`,
  BIN_HouseCount: `Hus`,
  BIN_UnitCount: `Enheter`,
  BIN_Roomtype: `Romtype`,
  BIN_Cabintype: `Hyttetype`,
  BIN_Housetype: `Type hus`,
  BIN_Unittype: `Enhetstype`,
  BIN_Packagetype: `Pakke type`,
  BIN_BasketEmpty: `Kurven er tom`,
  BIN_BasketGoToCheckout: `Gå til utsjekk`,

  EXC_EmptyBasket: `Basket must not be empty`,

  SEA_Date: `dato`,
  SEA_Time: `tid`,
  SEA_Quantity: `nummer`,
  SEA_Skip: `Hopp over`,
  SEA_ChooseEvent: `Velg en opplevelse`,
  SEA_DescriptionStart: `Velg`,
  SEA_DescriptionEnd: `nedenfor eller dato i kalenderen`,
  SEA_SoldOut: `Velg en opplevelse`,
  SEA_SoldOutContinue: `Trykk på Fortsett for å hoppe over`,
  SEA_SeatingStandAloneTitle: `Velg en dato eller en av de som vises alternativer`,
  SEA_ResetCalendar: `Vis alle`,

  MAT_CALENDAR_Calendar_Label: `S`,
  MAT_CALENDAR_Close_Calendar_Label: `S`,
  MAT_CALENDAR_End_Date_Label: `S`,
  MAT_CALENDAR_Next_Month_Label: `S`,
  MAT_CALENDAR_Next_Multi_Year_Label: `S`,
  MAT_CALENDAR_Next_Year_Label: `S`,
  MAT_CALENDAR_Open_Calendar_Label: `S`,
  MAT_CALENDAR_Prev_Month_Label: `S`,
  MAT_CALENDAR_Prev_Multi_Year_Label: `S`,
  MAT_CALENDAR_Prev_Year_Label: `S`,
  MAT_CALENDAR_Start_Date_Label: `S`,
  MAT_CALENDAR_Switch_To_Month_View_Label: `S`,
  MAT_CALENDAR_Switch_To_Multi_Year_View_Label: `S`,
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'number' })
export class NumberPipe implements PipeTransform {
  /**
   * @param format should looks like '(h )(d,)(D2)' where
   * after `h` is ` ` and it is thousands delimiter
   * after `d` is `,` and it is decimal part delimiter
   * after `D` is `2` and it is how many decimal number is used
   */
  static Parse(amount: string, format: string) {
    if (!amount) {
      return null;
    }
    const f = this.ParseFormat(format);
    return parseFloat(amount.replace(new RegExp(f.h, 'g'), '').replace(f.d, '.'));
  }

  /**
   * @param format should looks like '(h )(d,)(D2)' where
   * after `h` is ` ` and it is thousands delimiter
   * after `d` is `,` and it is decimal part delimiter
   * after `D` is `2` and it is how many decimal number is used
   */
  static Format(amount: number, format = '(h.)(d,)(D2)') {
    if (typeof amount !== 'number') {
      return '';
    }
    const f = this.ParseFormat(format);
    const parts = amount.toFixed(f.D).split(/[.,]/);
    return (
      parts[0]
        .split('')
        .reverse()
        .reduce((a, b, i) => (i % 3 ? b : b + f.h) + a) + (f.D ? f.d + parts[1] : '')
    );
  }

  private static ParseFormat(format: string) {
    const f = { D: 2, d: ',', h: ' ' };
    format.split(/[()]/).forEach((n) => {
      switch (n[0]) {
        case 'D':
          f.D = Math.max(parseInt(n.substr(1), 10), 0);
          break; // numbers after dot
        case 'd':
          f.d = n.substr(1) || f.d;
          break; // number delimiter('.' or ',')
        case 'h':
          f.h = n.substr(1) || f.h;
          break; // large number parts delimiter: 1000000 => 1 000 000
        default:
      }
    });
    return f;
  }

  transform(value: number | string | null | undefined, format?: string) {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (typeof value === 'string') {
      value = parseFloat(value.replace(',', '.'));
    }
    return NumberPipe.Format(value, format);
  }
}

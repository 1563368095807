<div class="container" *ngIf="hotel">
  <div *ngIf="logo" style="margin-top: 5vh" class="mobileLogo mobileLogo-remove">
    <img [src]="logo" />
  </div>
  <div class="row">
    <div class="col m6 s12">
      <div class="search-form-container">
        <div class="search-box-design" (click)="onHotelChange()">
          <h3>Hotel</h3>
          <hr />
          <div style="padding-left: 5px; padding-top: 12px">
            <h4>{{ hotel.Name }}</h4>
          </div>
        </div>
        <div class="search-box-design" (click)="onGuestChange()">
          <h3>Personer</h3>
          <hr />
          <div style="padding-left: 5px; padding-top: 12px">
            <h4>{{ selectedGuestCount }}</h4>
          </div>
        </div>
        <div class="search-box-design" (click)="dateClick()">
          <h3>Dato</h3>
          <hr />
          <div style="padding: 5px; padding-top: 12px" *ngIf="startDate && endDate">
            <h4>
              {{ startDate.getDate() }}. {{ startMonth | t | async }} - {{ endDate.getDate() }}.
              {{ endMonth | t | async }}
            </h4>
          </div>
          <div style="padding: 5px; padding-top: 12px" *ngIf="!startDate && !endDate">
            <h4>Vælg dato</h4>
          </div>
        </div>
        <div class="form-btn" style="float: right">
          <button
            type="submit"
            routerLink="/conference-rooms"
            class="btn btn-primary with-shadow"
            id="stayBooking"
            [disabled]="!startDate || !endDate">
            {{ 'COM_Continue' | t | async }}
          </button>
        </div>
      </div>
    </div>
    <div class="col m6 s12 mobile-hide">
      <div class="search-room-details">
        <div class="header">
          <h4>Meeting rooms</h4>
          <hr />
        </div>
        <div style="height: 92%; overflow: auto; overflow-x: hidden">
          <div *ngIf="conferenceRooms">
            <div class="details-panel" *ngFor="let item of conferenceRooms">
              <div class="details grow" (click)="conferenceRoomClick(item.RoomTypeName)">
                <div style="width: 10rem; float: left">
                  <img style="width: 100%" *ngIf="item.ImageOfRoom1" src="{{ item.ImageOfRoom1 }}" />
                  <div
                    *ngIf="!item.ImageOfRoom1"
                    style="width: 100%; height: 110px; background-color: gray; text-align: center; line-height: 100px">
                    <div
                      style="
                        font-size: 25px;
                        display: inline-block;
                        vertical-align: middle;
                        line-height: normal;
                        margin-right: 30px;
                      ">
                      <span class="fa fa-camera" style="font-size: 40px; color: #999999; margin-left: 10px"></span>
                      <h2 style="margin-left: 25px; color: #999999">
                        {{ 'RPA_Image' | t | async }}
                      </h2>
                    </div>
                  </div>
                  <p style="margin-top: 20px">Fra 1000 DKK / pr. Person</p>
                </div>
                <div>
                  <p style="margin-left: 11rem; font-size: 25px">
                    {{ item.RoomTypeName }}
                  </p>
                  <hr />
                  <div style="margin-left: 11rem">
                    <p [innerHTML]="item.ShortDescription"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="overlay" style="height: 100vh; width: 100vw" *ngIf="showCalendar" (click)="dateClick()"></div>

<ng-template #hotelChange let-message>
  <div class="conference-container-search">
    <div class="conference-header-search">
      <p style="margin-top: 20px" class="dynamicTextColor">
        {{ applicationState.HotelLabel | t | async }}
      </p>
    </div>
    <div class="conference-search">
      <div class="conference-body-search">
        <div class="conference-hotelcard-search grow" *ngFor="let item of hotels" (click)="message.accept(item.Code)">
          <img class="mobile-hide" style="height: 6rem; float: left" src="{{ item.Image }}" />
          <h2 style="padding-top: 2.1rem">{{ item.Name }}</h2>
          <i
            *ngIf="item.Code === hotel.Code"
            style="color: forestgreen; position: absolute; right: 10px; bottom: 10px"
            class="far fa-check-circle"></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #personDropdown let-message>
  <div class="conference-container-picker">
    <div class="conference-container-guestcount">
      <p class="dynamicTextColor">Antal Personer</p>
    </div>
    <select [value]="selectedGuestCount" (change)="message.accept($event.target.value)">
      <option *ngFor="let count of guestCountValues" [value]="count" [selected]="selectedGuestCount">
        {{ count }}
      </option>
    </select>
  </div>
</ng-template>

<div class="dialog-calendar" *ngIf="showCalendar && calendarDates">
  <div class="month">
    <ul>
      <li
        class="fas fa-arrow-left prev"
        style="margin-top: 15px; margin-right: 40px; cursor: pointer"
        (click)="prevMonth()"></li>
      <li
        class="fas fa-arrow-right next"
        style="margin-top: 15px; margin-left: 40px; cursor: pointer"
        (click)="nextMonth()"></li>
      <li>
        <p>{{ calendarDates.currentMonth | t | async }}</p>
        <span style="font-size: 18px">{{ calendarDates.currentYear }}</span>
      </li>
    </ul>
  </div>
  <ul class="weekdays">
    <li>{{ 'CAL_Mon' | t | async }}</li>
    <li>{{ 'CAL_Tirs' | t | async }}</li>
    <li>{{ 'CAL_Ons' | t | async }}</li>
    <li>{{ 'CAL_Tors' | t | async }}</li>
    <li>{{ 'CAL_Fre' | t | async }}</li>
    <li>{{ 'CAL_Lor' | t | async }}</li>
    <li>{{ 'CAL_Sun' | t | async }}</li>
  </ul>
  <ul class="days">
    <app-calendar-tile
      *ngFor="let item of calendarDates.days"
      (clickedDay)="clickedDate($event)"
      [date]="item"
      [componentRequestor]="'conference'"></app-calendar-tile>
  </ul>
  <div style="display: flex; background-color: #eeeeee; padding: 10px">
    <div style="width: 25px; height: 25px; background-color: #f87e7d; margin-right: 5px"></div>
    <p>{{ 'CAL_Occupied' | t | async }}</p>
    <div style="width: 25px; height: 25px; background-color: white; margin-left: 10px; margin-right: 5px"></div>
    <p>{{ 'CAL_Available' | t | async }}</p>
    <div style="width: 25px; height: 25px; background-color: #a9a9a9; margin-left: 10px; margin-right: 5px"></div>
    <p>{{ 'CAL_NotPossible' | t | async }}</p>
  </div>
</div>

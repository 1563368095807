import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslationModule } from 'src/app/modules/translation';

import { CalendarInputComponent } from './components/calendar-input/calendar-input.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ExpandableMediaListComponent } from './components/expandable-media-list/expandable-media-list.component';
import { PopupComponent } from './components/popup/popup.component';
import { SlideDownComponent } from './components/animation/slide-down/slide-down.component';

import { CharPipe, DatePipe, GuestsPipe, NumberPipe, PricePipe } from './pipes';

import {
  EqualToValidatorDirective,
  HasLowerValidatorDirective,
  HasNumberValidatorDirective,
  HasUpperValidatorDirective,
} from './validators';

import { MaxHeightDirective } from './directives/max-height.directive';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, RouterModule, TranslationModule],
  declarations: [
    GuestsPipe,
    DatePipe,
    CharPipe,
    NumberPipe,
    PricePipe,
    CalendarComponent,
    CheckboxComponent,
    DropdownComponent,
    CalendarInputComponent,
    ExpandableMediaListComponent,
    PopupComponent,
    EqualToValidatorDirective,
    HasLowerValidatorDirective,
    HasUpperValidatorDirective,
    HasNumberValidatorDirective,
    MaxHeightDirective,
    SlideDownComponent,
  ],
  exports: [
    GuestsPipe,
    DatePipe,
    CharPipe,
    NumberPipe,
    PricePipe,
    CalendarComponent,
    CheckboxComponent,
    DropdownComponent,
    CalendarInputComponent,
    ExpandableMediaListComponent,
    PopupComponent,
    EqualToValidatorDirective,
    HasLowerValidatorDirective,
    HasUpperValidatorDirective,
    HasNumberValidatorDirective,
    MaxHeightDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslationModule,
    SlideDownComponent,
  ],
})
export class SharedModule {}

import { Component } from '@angular/core';
import moment, { Moment } from 'moment';
import { ApplicationStateService, HotelService, Loader, LocaleService, SeatingData } from '../../core';
import { BehaviorSubject, combineLatest, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HighlightDate } from './seating-date-calendar.component';
import { sortBy, uniqBy } from 'lodash-es';
import { NavigationHelper } from 'src/app/helpers/navigation-helper';

@Component({
  selector: 'app-seating-standalone',
  templateUrl: './seating-standalone.component.html',
})
export class SeatingStandaloneComponent {
  chosenDate$ = new BehaviorSubject<Moment | null>(null);
  minDate: Moment;
  maxDate: Moment;
  state: ApplicationStateService | undefined;

  seatingData$ = combineLatest([this.locale.locale$, this.chosenDate$]).pipe(
    switchMap(([locale, chosenDate]) =>
      from(this.buildSeatingData()).pipe(
        map((seatings) => {
          const { availableDates, highlightedDates } = this.getAvailableDates(seatings);
          const syncedSeatings = this.syncSeatings(seatings, chosenDate);

          return {
            locale,
            seatings: syncedSeatings,
            chosenDate,
            highlightedDates,
            availableDates,
          };
        }),
      ),
    ),
  );

  constructor(
    private hotelService: HotelService,
    private applicationState: ApplicationStateService,
    private locale: LocaleService,
    private loader: Loader,
    private navigationHelper: NavigationHelper,
  ) {
    // Initialize minDate and maxDate that the user can choose
    this.minDate = moment(new Date()).startOf('day');
    this.maxDate = moment(new Date()).add(1, 'year').endOf('day');
    this.state = this.applicationState;
  }

  resetDate() {
    this.chosenDate$.next(null);
  }

  async buildSeatingData() {
    return await this.loader.using(async () => {
      // TODO: Should hotelCode also be link-able here?
      const seatingSpectra = await this.hotelService.GetECommerceSeatings(
        this.applicationState.CurrentHotelCode,
        this.minDate.toDate(),
        this.maxDate.toDate(),
        await this.locale.getLocale(),
      );

      let seatings: SeatingStandaloneUIModel[] = [];
      if (seatingSpectra && seatingSpectra.length !== 0) {
        const seatingsData = seatingSpectra.filter((x) => x.Rates !== null);

        // Create a map to map the characteristics that are already added
        const alreadyAddedCharacteristics: Partial<{ [K in string]: boolean }> = {};
        for (const seatingData of seatingsData) {
          const availableDates: Moment[] = [];
          const highlightedDates: HighlightDate[] = seatingData.Rates.map<HighlightDate>((val) => {
            // Only add to available dates if booking is available
            if (val.MaxPers > 0) {
              availableDates.push(moment(val.Arrival));
            }

            return {
              date: new Date(val.Arrival),
              occupied: val.MaxPers === 0,
              inBasket: false,
            };
          });

          // If we already got a characteristics just updated the available dates
          if (alreadyAddedCharacteristics[seatingData.Characteristics]) {
            seatings = seatings.map((s) => {
              if (s.characteristics === seatingData.Characteristics) {
                return {
                  ...s,
                  highlightedDates: [...s.highlightedDates, ...highlightedDates],
                  availableDates: [...s.availableDates, ...availableDates],
                };
              }

              return s;
            });
            continue;
          }

          const seatingPortal = await this.hotelService.GetECommerceSeatingPortalInfo(
            this.applicationState.CurrentHotelCode,
            this.minDate.toDate(),
            this.maxDate.toDate(),
            seatingData.ItemNumber,
            false,
            '',
          );

          const info = seatingPortal.find((x) => x.ID === seatingData.ItemNumber);
          if (info) {
            const seatingUiModel: SeatingStandaloneUIModel = {
              image: info.Image,
              header: info.Name,
              description: info.Long,
              sortIndex: info.SortIndex,
              seatingId: info.ID,
              seatingData: seatingData,
              characteristics: seatingData.Characteristics,
              highlightedDates: highlightedDates,
              availableDates: availableDates,
              show: true,
            };

            seatings.push(seatingUiModel);
            alreadyAddedCharacteristics[seatingData.Characteristics] = true;
          }
        }
      }

      seatings.sort((a, b) => a.sortIndex - b.sortIndex);
      return seatings;
    });
  }

  getAvailableDates(seatings: SeatingStandaloneUIModel[]) {
    let highlightedDates: HighlightDate[] = [];
    let availableDates: Moment[] = [];

    for (const seating of seatings) {
      availableDates = [...availableDates, ...seating.availableDates];
      highlightedDates = [...highlightedDates, ...seating.highlightedDates];
    }

    // We sort it with occupied being prioritized as first
    const newHighDates = sortBy(highlightedDates, (date) => {
      // We create a date at the start of the day, because we wan't to prioritize occupancy over time of day
      const startOfDay = moment(date.date).startOf('day');
      return `${startOfDay.toISOString()}_${date.occupied ? '1' : '0'}`;
    });

    // We can assume it's prioritized, then we just need to make the array unique
    const uniqHighlightedDates = uniqBy(newHighDates, (date) => date.date.toDateString());

    return {
      highlightedDates: uniqHighlightedDates,
      availableDates,
    };
  }

  async goBack() {
    await this.navigationHelper.goBack(true);
  }

  syncSeatings(seatings: SeatingStandaloneUIModel[], chosenDate: Moment | null) {
    if (chosenDate === null) {
      return seatings;
    }

    const minDate = chosenDate.clone().startOf('day');
    const maxDate = chosenDate.clone().endOf('day');
    for (const seating of seatings) {
      // Check if seating has any dates that meets the threshold
      seating.show =
        seating.highlightedDates.some((d) => minDate.isBefore(d.date) && maxDate.isAfter(d.date) && !d.occupied) &&
        seating.availableDates.length !== 0;
    }

    return seatings;
  }
}

interface SeatingStandaloneUIModel {
  image: string;
  header: string;
  description: string;
  sortIndex: number;
  seatingId: string;
  seatingData: SeatingData;
  characteristics: string;
  highlightedDates: HighlightDate[];
  availableDates: Moment[];
  show: boolean;
}

<div *ngIf="data$ | async as data" class="page-content room-page fadeBody">
  <div class="container">
    <a class="desktop-show mobile-hide applicationColor" style="position: absolute; font-size: 20px" (click)="goBack()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >
    <a
      class="desktop-show mobile-hide applicationColor"
      *ngIf="returnFromBasket"
      (click)="onMoveToNextClick()"
      style="font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>
    <a
      class="desktop-show mobile-hide"
      *ngIf="!returnFromBasket"
      style="pointer-events: none; color: gray; font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>

    <p
      class="desktop-show mobile-hide"
      style="text-align: center; font-size: 40px; font-weight: 500; margin-left: 8.5%">
      {{ applicationState.ChooseYourRoom | t | async }}
    </p>
    <p class="desktop-hide mobile-show" style="text-align: center; font-size: 40px; font-weight: 500">
      {{ applicationState.ChooseYourRoom | t | async }}
    </p>
    <h2 style="text-align: center; margin-top: 20px">
      <span class="v-middle"
        >{{ applicationState.RoomLabel | t | async }} {{ data.bookingIndex | char }} (<span class="text-case-normal">{{
          basket.params.rooms[data.bookingIndex] | guests | async
        }}</span
        >)</span
      >
    </h2>
    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />
  </div>

  <app-expandable-media-list [data]="data.rooms" [selected]="data.room">
    <ng-template #itemTemplate let-room>
      <div
        class="card my-0 d-flex flex-column {{ room.selected ? 'active' : '' }} grow"
        (click)="$event.stopPropagation(); onRoomClick($event, room.item)">
        <ng-template #roomPriceFromTempalte>
          {{ 'RPA_From' | t | async }}
          <mark class="right">{{ room.item.FromPrice | price | async }}</mark>
        </ng-template>
        <div class="flex-grow-1">
          <div class="card-image">
            <div class="centered-image">
              <img *ngIf="room.item.Image" [src]="room.item.Image" draggable="false" />
              <div
                *ngIf="!room.item.Image"
                style="width: 100%; background-color: gray; height: 100%; text-align: center; line-height: 220px">
                <div
                  style="
                    font-size: 100px;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: normal;
                    margin-right: 70px;
                  ">
                  <span class="fa fa-camera" style="font-size: 100px; color: #999999"></span>
                  <h2 style="margin-left: 70px; color: #999999">
                    {{ 'RPA_Image' | t | async }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="overel-container">
              <div class="overel overel-right">
                <button
                  *ngIf="!room.selected"
                  style="pointer-events: none"
                  type="button"
                  class="btn btn-primary btn-mini"
                  (click)="onRoomClick($event, room.item)">
                  {{ 'RPA_More' | t | async }}
                </button>
                <button
                  *ngIf="room.selected"
                  style="pointer-events: none"
                  type="button"
                  class="btn btn-success btn-mini"
                  (click)="onRoomClick($event, room.item)">
                  {{ 'RPA_Selected' | t | async }}
                </button>
              </div>
            </div>
          </div>
          <div class="card-header">
            <h3 class="text-primary" [innerHTML]="room.item.Name"></h3>
          </div>
        </div>
        <hr style="width: 90%; margin-left: 5%; margin-bottom: 20px; border-width: 2px" />
        <div class="card-body">
          <h3>
            <ng-container [ngTemplateOutlet]="roomPriceFromTempalte"></ng-container>
          </h3>
        </div>
      </div>
    </ng-template>
    <ng-template #selectedItemTemplate let-item>
      <div class="container fadeBody">
        <div class="row">
          <div class="col s12">
            <div class="list-group">
              <ng-template ngFor let-rate [ngForOf]="data.rates">
                <div class="list-group-item" [attr.data-left]="rate.Quantity">
                  <ng-template #rateDescriptionTemplate>
                    <h2 *ngIf="rate.Name" [innerHTML]="rate.Name"></h2>
                  </ng-template>
                  <ng-template #ratePriceTempalte>
                    <ng-template [ngIf]="rate.OriginalPrice !== rate.Price"
                      ><del>{{ rate.OriginalPrice | price | async }}</del
                      ><br
                    /></ng-template>
                    {{ rate.Price | price | async }}
                  </ng-template>
                  <div class="hide-l">
                    <ng-container [ngTemplateOutlet]="rateDescriptionTemplate"></ng-container>
                  </div>
                  <div class="row">
                    <div class="col m6 s12 l3">
                      <div class="preview-image">
                        <div class="wrap-small">
                          <div class="ribbon-small" *ngIf="rate.OriginalPrice !== rate.Price">
                            <span class="glow"></span>
                            <span
                              style="
                                font-size: 25px;
                                font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                              "
                              >-{{ rate.PromotionDisCount }}</span
                            >
                            <span style="font-size: 20px">%</span>
                          </div>
                          <img *ngIf="rate.Image" style="width: 100%; height: auto" [src]="rate.Image" />
                          <div
                            *ngIf="!rate.Image"
                            style="
                              width: 100%;
                              background-color: gray;
                              height: 100%;
                              text-align: center;
                              line-height: 220px;
                            ">
                            <div
                              style="
                                font-size: 100px;
                                display: inline-block;
                                vertical-align: middle;
                                line-height: normal;
                                margin-right: 70px;
                              ">
                              <span class="fa fa-camera" style="font-size: 100px; color: #999999"></span>
                              <h2 style="margin-left: 70px; color: #999999">
                                {{ 'RPA_Image' | t | async }}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col m6 s12 l9">
                      <div class="hide-m hide-s">
                        <ng-container [ngTemplateOutlet]="rateDescriptionTemplate"></ng-container>
                      </div>
                      <div class="row">
                        <div class="col l8 s12">
                          <div class="section" [innerHTML]="rate.Long"></div>
                          <a
                            style="color: dodgerblue; cursor: pointer"
                            (click)="onRateSelect(data.bookingIndex, item, rate)"
                            >{{ 'RPA_More' | t | async }}</a
                          >
                        </div>
                        <div class="col l4 s12 clearfix">
                          <div class="left">
                            <div class="h4 form-text text-primary">
                              <ng-container [ngTemplateOutlet]="ratePriceTempalte"></ng-container>
                            </div>
                          </div>
                          <div
                            *ngIf="{
                              selected:
                                basket.bookings[data.bookingIndex]?.RoomCode === item.Code &&
                                basket.bookings[data.bookingIndex]?.RateCode === rate.Code
                            } as status"
                            class="right">
                            <button
                              *ngIf="status.selected"
                              type="button"
                              class="btn btn-primary-outline with-shadow"
                              [ngClass]="{ active: status.selected }"
                              (click)="onSelectedRateClick(data.bookingIndex, item, rate)">
                              {{ 'RPA_Selected' | t | async }}
                            </button>
                            <button
                              *ngIf="!status.selected"
                              type="button"
                              class="btn btn-primary-outline with-shadow"
                              [ngClass]="{ active: status.selected }"
                              (click)="onBookClick(data.bookingIndex, item, rate)">
                              {{ 'APA_Book' | t | async }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style="position: absolute; left: 0; right: 0" />
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </app-expandable-media-list>
</div>

<ng-template #messageRoomGalleryTempalte let-message>
  <div *ngIf="message.selectedImage" class="show-desktop">
    <div
      class="dialog-container-gallery fadeBody"
      [ngStyle]="{
        'background-image':
          'linear-gradient(to right, rgba(0, 0, 0), rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0)), url(' +
          message.selectedImage +
          ')'
      }">
      <div class="dynamicTextColor content-title">
        <p *ngIf="message.roomData.Short" [innerHTML]="message.roomData.Short" class="section"></p>
      </div>
      <div class="content-body dynamicTextColor">
        <p *ngIf="message.roomData.Long" [innerHTML]="message.roomData.Long" class="section"></p>
      </div>
      <button
        type="button"
        style="margin-left: 20px; width: 250px; margin-top: 40px"
        class="btn btn-primary-outline with-shadow"
        (click)="message.accept()">
        {{ applicationState.ChooseRoomLabel | t | async }}</button
      ><br />
      <button
        type="button"
        style="margin-left: 20px; width: 250px; margin-top: 0"
        class="btn btn-primary-outline with-shadow"
        (click)="message.close()">
        {{ 'COM_Close' | t | async }}
      </button>
      <div class="content-footer-left dynamicTextColor">
        <p>{{ 'RPA_MaxPersons' | t | async }}: {{ message.roomData.MaxPers }}</p>
      </div>
      <div *ngIf="message.imageArray.length !== 0" class="content-footer-right dynamicTextColor">
        <div style="width: 120px; display: inline-flex; cursor: pointer" *ngFor="let item of message.imageArray">
          <img
            src="{{ item }}"
            draggable="false"
            style="max-height: 70px"
            class="grow"
            (click)="changeImage(item, message)" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!message.selectedImage" class="show-desktop">
    <div
      style="
        margin: auto;
        width: 980px;
        min-height: 295px;
        padding: 20px;
        margin-top: 15%;
        background: rgba(0, 0, 0, 0.5);
      ">
      <div style="text-align: center; color: white; margin-bottom: 2%">
        <h1 *ngIf="message.roomData.Short" [innerHTML]="message.roomData.Short" class="section"></h1>
      </div>
      <div style="color: white; text-align: center">
        <p *ngIf="message.roomData.Long" [innerHTML]="message.roomData.Long" class="section"></p>
      </div>
      <div style="margin-left: 17rem">
        <button
          type="button"
          style="width: 200px"
          class="btn btn-primary-outline with-shadow"
          (click)="message.close()">
          {{ 'COM_Close' | t | async }}
        </button>
        <button
          type="button"
          style="width: 200px"
          class="btn btn-primary-outline with-shadow"
          (click)="message.accept()">
          {{ applicationState.ChooseRoomLabel | t | async }}
        </button>
      </div>
      <div class="dynamicTextColor">
        <p>{{ 'RPA_MaxPersons' | t | async }}: {{ message.roomData.MaxPers }}</p>
      </div>
    </div>
  </div>

  <div class="show-mobile">
    <div class="dialog-container-gallery-mobile fadeBody">
      <div>
        <img class="content-Image" src="{{ message.selectedImage }}" />
      </div>
      <div
        style="display: inline-flex; margin-top: 10px; margin-left: 44%"
        class="applicationColor"
        *ngIf="message.imageArray.length > 1">
        <i class="fas fa-arrow-circle-left" (click)="message.prevImage(message.selectedImage)"></i>
        <i class="fas fa-arrow-circle-right" (click)="message.nextImage(message.selectedImage)"></i>
      </div>
      <div class="dynamicTextColor content-title-mobile">
        <p *ngIf="message.roomData.Short" [innerHTML]="message.roomData.Short" class="section"></p>
      </div>
      <div class="content-body-mobile dynamicTextColor">
        <p *ngIf="message.roomData.Long" [innerHTML]="message.roomData.Long" class="section"></p>
      </div>
      <div class="content-footer-mobile">
        <button
          type="button"
          style="width: 11em; margin-right: 2px"
          class="btn btn-primary-outline with-shadow"
          (click)="message.close()">
          {{ 'COM_Close' | t | async }}
        </button>
        <button
          type="button"
          style="width: 11em"
          class="btn btn-primary-outline with-shadow"
          (click)="message.accept()">
          {{ applicationState.ChooseRoomLabel | t | async }}</button
        ><br />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #messageRateGalleryTempalte let-message>
  <div *ngIf="message.selectedImage" class="show-desktop">
    <div
      class="dialog-container-gallery fadeBody"
      [ngStyle]="{
        'background-image':
          'linear-gradient(to right, rgba(0, 0, 0), rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0)), url(' +
          message.selectedImage +
          ')'
      }">
      <div class="dynamicTextColor content-title">
        <p *ngIf="message.rateData.Name" [innerHTML]="message.rateData.Name" class="section"></p>
      </div>
      <div class="content-body dynamicTextColor">
        <p *ngIf="message.rateData.Long" [innerHTML]="message.rateData.Long" class="section"></p>
      </div>
      <button
        type="button"
        style="margin-left: 20px; width: 250px; margin-top: 40px"
        class="btn btn-primary-outline with-shadow"
        (click)="message.accept()">
        {{ 'APA_Book' | t | async }}</button
      ><br />
      <button
        type="button"
        style="margin-left: 20px; width: 250px; margin-top: 0"
        class="btn btn-primary-outline with-shadow"
        (click)="message.close()">
        {{ 'COM_Close' | t | async }}
      </button>
      <div class="content-footer-left dynamicTextColor">
        {{ 'COM_Price' | t | async }}
        {{ message.rateData.Price | price | async }}
      </div>
      <div *ngIf="message.imageArray.length !== 0" class="content-footer-right dynamicTextColor">
        <div style="width: 120px; display: inline-flex; cursor: pointer" *ngFor="let item of message.imageArray">
          <img
            src="{{ item }}"
            draggable="false"
            style="max-height: 70px"
            class="grow"
            (click)="changeImage(item, message)" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!message.selectedImage" class="show-desktop">
    <div
      style="
        margin: auto;
        width: 980px;
        min-height: 295px;
        padding: 20px;
        margin-top: 15%;
        background: rgba(0, 0, 0, 0.5);
      ">
      <div style="text-align: center; color: white; margin-bottom: 2%">
        <p *ngIf="message.rateData.Name" [innerHTML]="message.rateData.Name" class="section"></p>
      </div>
      <div style="color: white; text-align: center">
        <p *ngIf="message.rateData.Long" [innerHTML]="message.rateData.Long" class="section"></p>
      </div>
      <div style="margin-left: 17rem">
        <button
          type="button"
          style="width: 200px"
          class="btn btn-primary-outline with-shadow"
          (click)="message.close()">
          {{ 'COM_Close' | t | async }}
        </button>
        <button
          type="button"
          style="width: 200px"
          class="btn btn-primary-outline with-shadow"
          (click)="message.accept()">
          {{ 'APA_Book' | t | async }}
        </button>
      </div>
      <div class="dynamicTextColor">
        {{ 'COM_Price' | t | async }}
        {{ message.rateData.Price | price | async }}
      </div>
    </div>
  </div>

  <div class="show-mobile">
    <div class="dialog-container-gallery-mobile fadeBody">
      <div>
        <img class="content-Image" src="{{ message.selectedImage }}" />
      </div>
      <div
        style="display: inline-flex; margin-top: 10px; margin-left: 44%"
        class="applicationColor"
        *ngIf="message.imageArray.length > 1">
        <i class="fas fa-arrow-circle-left" (click)="message.prevImage(message.selectedImage)"></i>
        <i class="fas fa-arrow-circle-right" (click)="message.nextImage(message.selectedImage)"></i>
      </div>
      <div class="content-title-mobile">
        <p *ngIf="message.rateData.Name" [innerHTML]="message.rateData.Name" class="section"></p>
      </div>
      <div class="content-body-mobile">
        <p *ngIf="message.rateData.Long" [innerHTML]="message.rateData.Long" class="section"></p>
      </div>
      <div class="content-footer-mobile">
        <button
          type="button"
          style="width: 11em; margin-right: 2px"
          class="btn btn-primary-outline with-shadow"
          (click)="message.close()">
          {{ 'COM_Close' | t | async }}
        </button>
        <button
          type="button"
          style="width: 11em"
          class="btn btn-primary-outline with-shadow"
          (click)="message.accept()">
          {{ 'APA_Book' | t | async }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ApplicationStateService,
  Basket,
  BasketService,
  BookingService,
  HotelService,
  Loader,
  LocaleService,
} from 'src/app/core';

import { BookingInformation, GiftcardPaymentModel, ReservationType } from 'src/app/core/modules/payment';
import { Guid, GuidHelper } from '../../helpers';
import { NavigationHelper } from '../../helpers/navigation-helper';
import { PaymentFlowEnum } from '../../core/modules/payment';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './AcceptPayment.component.html',
})
export class AcceptPaymentComponent implements OnInit, OnDestroy {
  private sub: any;
  private qsub: any;
  reservation: ReservationType | undefined;
  currency: string | undefined;

  constructor(
    private bookingService: BookingService,
    private basketService: BasketService,
    private loader: Loader,
    private applicationState: ApplicationStateService,
    private navigationHelper: NavigationHelper,
    private localeService: LocaleService,
    private hotelService: HotelService,
    private activatedRoute: ActivatedRoute,
  ) {}

  async ngOnInit() {
    window.scrollTo(0, 0);
    const basket = this.basketService.get();

    if (
      basket &&
      basket.bookings[0].Giftcards &&
      basket.bookings[0].Giftcards.length !== 0 &&
      this.applicationState.UseNetAxept
    ) {
      await this.ProgressGiftcardAxept(basket);
    } else if (this.applicationState.UseNetAxept) {
      await this.progressAxeptMessage();
    } else if (
      basket &&
      basket.bookings[0].Giftcards &&
      basket.bookings[0].Giftcards.length !== 0 &&
      this.applicationState.UseNetsEasy
    ) {
      const responseModel = {} as PaymentCompletedModel;

      this.activatedRoute.queryParams.subscribe((params: Params) => {
        responseModel.paymentId = params['paymentid'];
      });

      if (responseModel.paymentId) {
        await this.EasyGiftcardPaymentAccepted(responseModel);
      } else {
        await this.navigationHelper.navigateToPage('/customer');
      }
    } else if (this.applicationState.UseNetsEasy) {
      const responseModel = {} as PaymentCompletedModel;
      let usingPaymentLink = false;
      let resGuid: Guid;

      this.activatedRoute.queryParams.subscribe((params: Params) => {
        responseModel.paymentId = params['paymentid'];
        usingPaymentLink = params['origin'] == 'paymentlink';
        resGuid = params['resGuid'];
        if (resGuid && resGuid !== GuidHelper.Empty) {
          this.applicationState.ReservationGuid = resGuid;
        }
      });

      if (responseModel.paymentId) {
        await this.EasyPaymentAccepted(responseModel);
      } else if (usingPaymentLink) {
        await this.navigationHelper.navigateToPage(`/payment-link/${this.applicationState.ReservationGuid}`);
      } else {
        const res = this.bookingService.getReservationOrFail();
        await this.bookingService.CancelReservation(res.reservationId);

        await this.navigationHelper.navigateToPage('/customer');
      }
    }
  }

  ngOnDestroy() {
    if (this.sub && this.qsub) {
      this.sub.unsubscribe();
      this.qsub.unsubscribe();
    }
  }

  async ProgressGiftcardAxept(basket: Basket) {
    let authkey;
    let orderId;
    let responseCode;
    const cards = basket.bookings[0].Giftcards;

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      authkey = params['transactionId'];
      orderId = params['orderId'];
      responseCode = params['responseCode'];
    });

    if (authkey && orderId && cards && responseCode === 'OK') {
      // Payment completed
      this.basketService.basketBadgeNumberSet = '0';

      let amount = 0;

      cards.forEach((x) => {
        amount += x.TotalPrice;
      });

      await this.bookingService.RegisterGiftcardPayment(Number(orderId), authkey as Guid);
      this.applicationState.GiftcardPayment = '';

      const reservationType = this.bookingService.buildReservationTypeForGiftcard(
        basket,
        amount,
        `spectraapiorder-${orderId}`,
      );

      await this.bookingService.completePayment(reservationType);
      this.basketService.clear();

      await this.navigationHelper.navigateToPage('/reciept');
    } else {
      await this.navigationHelper.navigateToPage('/customer');
    }
  }

  async EasyPaymentAccepted(netsResponse: PaymentCompletedModel) {
    const res = this.bookingService.getReservationOrFail();
    const giftcard = this.bookingService.getSessionGiftcard();

    await this.loader.using(async () => {
      await this.bookingService.registerPayment(
        this.applicationState.CurrentHotelCode,
        res.resGuid!,
        netsResponse.paymentId,
        res.PaymentFlowToUse,
      );

      if (giftcard && giftcard.Amount > 0) {
        if (res.resGuid) {
          giftcard.resGuid = res.resGuid;
          giftcard.currency = res.payment.currency;
          await this.hotelService.payAmountWithGiftcard(giftcard);
        }
      }

      const bookingInformation = this.bookingService.bookingInformation.get()
        ? this.bookingService.bookingInformation.get()
        : ({
            HotelCode: this.applicationState.CurrentHotelCode,
          } as BookingInformation);

      await this.bookingService.sendNotification(bookingInformation, await this.localeService.getLocale());

      await this.bookingService.completePayment(res);
      this.basketService.clear();

      await this.navigationHelper.navigateToPage('/reciept');
    });
  }

  async EasyGiftcardPaymentAccepted(netsResponse: PaymentCompletedModel) {
    await this.loader.using(async () => {
      this.basketService.basketBadgeNumberSet = '0';

      const basket = this.basketService.get();

      const giftcardPaymentModel = this.bookingService.giftcardEasyInfo.get();

      if (giftcardPaymentModel && basket) {
        const model = {
          CurrencyCode: giftcardPaymentModel.GiftcardPaymentModel.CurrencyCode,
          OrderId: giftcardPaymentModel.GiftcardPaymentModel.OrderId,
          PaidAmount: giftcardPaymentModel.GiftcardPaymentModel.PaidAmount,
        } as GiftcardPaymentModel;

        await this.bookingService.RegisterGiftcardPayment(Number(model.OrderId), netsResponse.paymentId);
        this.applicationState.GiftcardPayment = '';

        const reservationType = this.bookingService.buildReservationTypeForGiftcard(
          basket,
          Number(model.PaidAmount),
          model.OrderId,
        );

        await this.bookingService.completePayment(reservationType);
        this.basketService.clear();

        await this.navigationHelper.navigateToPage('/reciept');
      }
    });
  }
  async progressAxeptMessage() {
    await this.loader.using(async () => {
      try {
        const url = new URL(window.location.href);
        const res = this.bookingService.getReservationOrFail();
        const giftcard = this.bookingService.getSessionGiftcard();
        const transactionId = url.searchParams.get('transactionId');
        const responseCode = url.searchParams.get('responseCode');
        const paymentFlow = url.searchParams.get('paymentFlow');
        const isPaymentLink = this.applicationState.PaymentlinkCallBack === 'false' || undefined ? false : true;

        // Payment completed
        if (transactionId && res && paymentFlow && responseCode === 'OK') {
          this.basketService.basketBadgeNumberSet = '0';

          await this.bookingService.registerPayment(
            this.applicationState.CurrentHotelCode,
            res.resGuid!,
            transactionId as Guid,
            Number(paymentFlow) as PaymentFlowEnum,
          );

          if (giftcard && giftcard.Amount > 0) {
            if (res.resGuid) {
              giftcard.resGuid = res.resGuid;
              await this.hotelService.payAmountWithGiftcard(giftcard);
            }
          }

          const bookingInformation = this.bookingService.bookingInformation.get()
            ? this.bookingService.bookingInformation.get()
            : ({
                HotelCode: this.applicationState.CurrentHotelCode,
              } as BookingInformation);

          await this.bookingService.sendNotification(bookingInformation, await this.localeService.getLocale());

          await this.bookingService.completePayment(res);
          this.basketService.clear();

          await this.navigationHelper.continue('/AcceptPayment/1', '/reciept');

          // Payment canceled
        } else {
          if (res && !isPaymentLink) {
            await this.bookingService.CancelReservation(res.reservationId);
          }

          if (!isPaymentLink) await this.navigationHelper.navigateToPage('/customer');
          else await this.navigationHelper.navigateToPage(`/payment-link/${res.resGuid!}`);
        }
      } catch (err) {
        console.log(`CustomerRoute access Acceptpayment failed: ${(err as Error).message}`);
        await this.navigationHelper.navigateToPage('/payment-failed');
        throw err;
      }
    }, 'LOA_PreparingPaymnent');
  }
}

interface PaymentCompletedModel {
  paymentId: Guid;
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LogService } from '@com/logging';
import dayjs from 'dayjs';
import { lastValueFrom, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApplicationStateService, Loader, LocaleService } from 'src/app/core';
import {
  ActionStack,
  AspDateString,
  /*SpectraDate,*/ ConfigHandler,
  DateHelper,
  UTCDateTimeString,
} from 'src/app/helpers';
import { StayAvailabilityResponse } from '../../../core/modules/hotel/data-hotel.service';

export interface CalendarConfig {
  showLegend: boolean;
  min: Date | null;
  max: Date | null;
  date: Date | null;
  hovered: Date[] | null;
  selected: Date[] | null;
  start: Date | null;
  showOnlyCurrentMonthDays: boolean;
  available: StayAvailabilityResponse[] | null;
  showMonths: number;
  month: dayjs.Dayjs;
  startOfWeek: number;
  full: boolean;
  stay: number | undefined;
}

export interface CalendarDateChangeEvent {
  date: Date;
  month: dayjs.Dayjs;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent implements CalendarConfig, OnInit, OnDestroy, OnChanges {
  @Input()
  set config(config: Partial<CalendarConfig> | undefined) {
    if (config) {
      this.configStack
        .push(async () => {
          await this.applyConfig(config);
        })
        .catch(undefined);
    }
  }
  private subscriptions: Subscription[] = [];
  private over = undefined as Date | undefined;

  newxClicked: boolean | undefined;
  @Input() loadingdData: boolean | undefined;
  @Input() isArrivalDate: boolean | undefined;
  configStack = new ActionStack<void>(undefined);
  showLegend = false;
  min = null as Date | null;
  max = null as Date | null;
  date = null as Date | null;
  hovered = null as Date[] | null;
  selected = null as Date[] | null;
  start = null as Date | null;
  showOnlyCurrentMonthDays = true;
  available = null as StayAvailabilityResponse[] | null;
  showMonths = 1;
  month = dayjs().startOf('month');
  startOfWeek = 1; // 1 - monday, 0 - sunday
  valid = false;
  full = false;
  stay: number | undefined;
  showLoader = false;
  months: Promise<Month>[] = [];
  monthsSubstract: number[] = [];
  days: string[] = [];
  @Output() dateChange = new EventEmitter<CalendarDateChangeEvent>();
  @Output() monthChange = new EventEmitter<Date>();
  @Output() hover = new EventEmitter<Date>();
  @Output() validChange = new EventEmitter<boolean>();
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef<HTMLDivElement> | undefined;

  scroll = 0;

  constructor(
    private log: LogService,
    private loader: Loader,
    private localeService: LocaleService,
    private applicationstate: ApplicationStateService,
  ) {}

  ngOnChanges(): void {
    this.showLoader = this.loadingdData === undefined ? false : this.loadingdData;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.localeService.translations$.subscribe((translations) => {
        this.config = {
          startOfWeek: +translations.CAL_WeekStart || 0 /* can't be 1 because zero became unreachable */,
        };
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  onPrevMonthClick() {
    this.config = { month: this.month.add(-1, 'month') };
    const date = this.month.toDate();
    this.monthChange.emit(DateHelper.addMonth(date, -1));
  }

  onNextMonthClick() {
    this.config = { month: this.month.add(1, 'month') };
    const date = this.month.toDate();
    this.monthChange.emit(DateHelper.addMonth(date, 2));
  }

  onDayClick(day: Day) {
    if (!day.available && this.isArrivalDate) {
      return;
    }

    if (day.arrival && this.isArrivalDate) {
      return;
    }
    this.log.debug(`Calendar onDayClick(${day.date})`);
    if (day.enabled) {
      this.dateChange.emit({
        date: day.date,
        month: this.month,
      });
    }
  }

  async onMouseMove(day: Day) {
    if (!this.over || !DateHelper.isEqual(this.over, day.date)) {
      this.over = day.date;
      if (!this.date) {
        await this.refreshMonths();
      }
      this.hover.emit(day.date);
    }
  }

  async onCalendarScroll() {
    const translations: {
      [key: string]: string;
    } = await lastValueFrom(this.localeService.translations$.pipe(first()));

    const el = this.scrollContainer && this.scrollContainer.nativeElement;
    if (el) {
      this.scroll = el.scrollHeight - el.scrollTop - el.clientHeight;
      if (el.scrollHeight - el.scrollTop - el.clientHeight <= 250) {
        const month = this.min ? DateHelper.asDayjs(this.min).startOf('month') : this.month;
        this.monthChange.emit(DateHelper.addMonth(month.toDate(), this.months.length));
        this.months.push(
          CalendarComponent.BuildMonthAsync(month.add(this.months.length, 'month'), this.startOfWeek, translations),
        );

        await this.refreshMonths();
      } else if (el.scrollHeight - el.scrollTop - el.clientHeight >= 600) {
        const month = this.min ? DateHelper.asDayjs(this.min).startOf('month') : this.month;
        // used to figure out how many month to substract from current month when scrolling back
        const length = this.monthsSubstract[this.monthsSubstract.length - 1];

        let numberToAdd = 1;

        if (length === undefined) {
          this.monthsSubstract.push(numberToAdd);
        } else {
          numberToAdd = numberToAdd + 1;
          if (numberToAdd < 12) {
            this.monthsSubstract.push(numberToAdd);
          }
        }

        this.monthChange.emit(DateHelper.addMonth(month.toDate(), this.monthsSubstract.length));

        await this.refreshMonths();
      }
    }
  }

  async applyConfig(config: Partial<CalendarConfig>) {
    const changes = ConfigHandler.Apply(this, config);
    if (this.min && this.date && this.date.getTime() < this.min.getTime()) {
      this.date = this.min;
    }
    if (this.max && this.date && this.date.getTime() > this.max.getTime()) {
      this.date = this.max;
    }
    if (changes.date && this.date) {
      if (this.start && this.start.getTime() > this.date.getTime()) {
        this.start = this.date;
        this.date = null;
      } else {
        if (!changes.month) {
          const d = DateHelper.asDayjs(this.date);
          const diff = d.diff(this.month, 'month', true);
          if (diff < 0 || diff >= this.showMonths) {
            this.month = d.startOf('month');
            changes.month = true;
          }
        }
      }
    }
    if (changes.month || changes.startOfWeek || changes.showMonths || changes.date || changes.start) {
      await this.loader.using(async () => {
        const translations: {
          [key: string]: string;
        } = await lastValueFrom(this.localeService.translations$.pipe(first()));
        this.days = [0, 1, 2, 3, 4, 5, 6].map((day) =>
          (translations as { [key: string]: string })[`CAL_D${(day + this.startOfWeek + 7) % 7}`].substring(0, 1),
        );
        if (!this.full) {
          this.months = CalendarComponent.BuildMonths(this.month, this.startOfWeek, this.showMonths, translations);
        } else {
          const month = this.min ? DateHelper.asDayjs(this.min).startOf('month') : this.month;
          const diff = Math.floor(
            DateHelper.asDayjs(this.date || this.start || new Date(month.valueOf())).diff(month, 'month', true),
          );
          this.months = CalendarComponent.BuildMonths(month, this.startOfWeek, Math.max(diff + 3, 3), translations);
        }
      }, 'LOA_HotelInformation');
    }
    await this.refreshMonths();
  }

  scrollToMonth() {
    const calendarContentEl = this.scrollContainer && this.scrollContainer.nativeElement;
    if (calendarContentEl) {
      const selected = calendarContentEl.getElementsByClassName('selected');
      if (selected.length > 0) {
        const dayEl = selected[0].parentElement;
        if (dayEl) {
          const monthEl = dayEl.parentElement;
          if (monthEl) {
            const monthRect = monthEl.getBoundingClientRect();
            const calendarContentRect = calendarContentEl.getBoundingClientRect();
            calendarContentEl.scrollTop = calendarContentEl.scrollTop + monthRect.top - calendarContentRect.top;
          }
        }
      }
    }
  }

  private async refreshMonths() {
    if (this.months) {
      (await Promise.all(this.months)).forEach(({ month, weeks }) => {
        weeks.forEach((week) => {
          week.days.forEach((day) => {
            // Available and clickable dates
            day.enabled =
              (!this.min || this.min.getTime() <= day.date.getTime()) &&
              (!this.max || this.max.getTime() >= day.date.getTime());

            // Decide if days are shown as available or unavailable in calendar
            if (this.available && this.available.length !== 0) {
              const date = this.available.find(
                (x) =>
                  DateHelper.FromUtcDate(x.Date.toString() as UTCDateTimeString).getDate() === day.date.getDate() &&
                  DateHelper.FromUtcDate(x.Date.toString() as UTCDateTimeString).getMonth() === day.date.getMonth() &&
                  DateHelper.FromUtcDate(x.Date.toString() as UTCDateTimeString).getFullYear() ===
                    day.date.getFullYear(),
              );
              if (date) {
                if (date.Reason === 'CFA') {
                  day.arrival = true;
                } else if (date.Reason === 'CFD') {
                  day.departure = true;
                } else if (
                  date.Reason === 'HOTELCLOSED' ||
                  date.Reason === 'HOTELCLOSEDDAYCOLOR' ||
                  date.Reason === 'CL' ||
                  date.Reason === 'NOTHINGVACANT' ||
                  date.Reason === 'NORATECODES' ||
                  date.Reason === 'NORATECODEAVAILABILITY'
                ) {
                  day.available = false;
                } else {
                  day.available = true;
                }
              } else {
                day.available = true;
              }
            }

            // Selected dates
            day.selected = !!(
              (this.date && DateHelper.isEqual(this.date, day.date)) ||
              (!this.date && this.start && DateHelper.isEqual(this.start, day.date)) ||
              (this.selected && !!this.selected.find((d) => DateHelper.isEqual(d, day.date))) ||
              (!this.date &&
                this.start &&
                this.over &&
                day.enabled &&
                this.over.getTime() >= day.date.getTime() &&
                this.start.getTime() <= day.date.getTime())
            );

            if (this.start) {
              // FirstSelected date calendar
              day.selectedFirst = !!(
                this.selected &&
                this.selected.length > 0 &&
                DateHelper.isEqual(day.date, this.selected[0])
              );

              if (this.selected !== null && this.applicationstate.DepartureDate !== null) {
                this.selected.push(this.applicationstate.DepartureDate);
              }

              // LastSelected date in calendar
              day.selectedLast = !!(
                this.selected &&
                this.selected.length > 0 &&
                DateHelper.isEqual(day.date, this.selected[this.selected.length - 1])
              );
            }

            day.hovered = !!(this.hovered && this.hovered.find((d) => DateHelper.isEqual(d, day.date)));
            day.hoveredFirst = !!(
              this.hovered &&
              this.hovered.length > 0 &&
              DateHelper.isEqual(day.date, this.hovered[0])
            );
            day.hoveredLast = !!(
              this.hovered &&
              this.hovered.length > 0 &&
              DateHelper.isEqual(day.date, this.hovered[this.hovered.length - 1])
            );
            day.visible = !this.showOnlyCurrentMonthDays || day.date.getMonth() /*.getUTCMonth()*/ === month;
            CalendarComponent.updateCls(day);
          });
        });
      });
    } else {
      this.log.warn('Calendar months are not ready yet.');
    }
    this.invalidate();
  }

  private invalidate() {
    let valid = true;
    if (this.available) {
      const available = this.available;
      if (valid && this.selected) {
        const selected = this.selected.slice(0, this.selected.length - 1); // it's ok when last day in not available for arrival
        if (
          selected.find(
            (date) =>
              !available.find((d) =>
                DateHelper.isEqual(DateHelper.FromAspDate(d.Date.toString() as AspDateString), date),
              ),
          )
        ) {
          valid = false;
        }
      }
    }
    if (this.valid !== valid) {
      this.valid = valid;
      this.validChange.emit(this.valid);
    }
  }

  private static updateCls(day: Day) {
    day.cls = '';
    if (day.visible) {
      if (day.selected) {
        day.cls += 'selected ';
      }
      if (day.selectedFirst) {
        day.cls += 'selected-first ';
      }
      if (day.selectedLast) {
        day.cls += 'selected-last ';
      }
      if (day.hovered) {
        day.cls += 'marked ';
      }
      if (day.hoveredFirst) {
        day.cls += 'marked-first ';
      }
      if (day.hoveredLast) {
        day.cls += 'marked-last ';
      }
      if (!day.available) {
        day.cls += 'occupied ';
      }
      if (day.arrival) {
        day.cls += 'arrival ';
      }
      if (day.departure) {
        day.cls += 'departure ';
      }
      if (!day.enabled) {
        day.cls += 'disabled ';
      }
    } else {
      day.cls += 'hidden ';
    }
  }

  private static BuildMonths(
    startMonth: dayjs.Dayjs,
    startOfWeek: number,
    showMonths: number,
    translations: { [key: string]: string },
  ) {
    return Array.from(Array(showMonths), async (item, index) => {
      const m = startMonth.add(index, 'month');
      return await this.BuildMonthAsync(m, startOfWeek, translations);
    });
  }

  private static async BuildMonthAsync(m: dayjs.Dayjs, startOfWeek: number, translations: { [key: string]: string }) {
    return await new Promise<Month>((resolve, reject) => {
      setTimeout(() => {
        try {
          resolve(this.BuildMonth(m, startOfWeek, translations));
        } catch (err) {
          reject(err);
        }
      }, 0);
    });
  }

  private static BuildMonth(m: dayjs.Dayjs, startOfWeek: number, translations: { [key: string]: string }) {
    const month = {
      month: m.month(),
      name: `${translations[m.format('[CAL_M]MM')]} ${m.format('YYYY')}`,
      weeks: [] as Week[],
    };
    const startOfMonth = m.startOf('month');
    const shiftedStartOfMonth = startOfMonth.add(-startOfWeek, 'day');
    const shiftedStartofWeek = shiftedStartOfMonth.startOf('week');
    const startDay = shiftedStartofWeek.add(startOfWeek, 'day');
    const endDay = m.endOf('month');
    let date = new Date(startDay.year(), startDay.month(), startDay.date());
    const end = new Date(endDay.year(), endDay.month(), endDay.date());
    let week: Week = { days: [] };
    while (date.getTime() <= end.getTime()) {
      for (let i = 0; i < 7; i++) {
        const day: Day = {
          available: true,
          date,
          enabled: true,
          selected: false,
          selectedFirst: false,
          selectedLast: false,
          visible: true,
          hovered: false,
          hoveredFirst: false,
          hoveredLast: false,
          cls: '',
          arrival: false,
          departure: false,
          closed: false,
        };
        this.updateCls(day);
        week.days.push(day);
        date = DateHelper.addDays(date, 1);
      }
      month.weeks.push(week);
      week = { days: [] };
    }
    return month;
  }
}

interface Month {
  month: number;
  name: string;
  weeks: Week[];
}

interface Week {
  days: Day[];
}

interface Day {
  date: Date;
  visible: boolean;
  selected: boolean;
  selectedFirst: boolean;
  selectedLast: boolean;
  enabled: boolean;
  available: boolean;
  arrival: boolean;
  departure: boolean;
  closed: boolean;
  hovered: boolean;
  hoveredFirst: boolean;
  hoveredLast: boolean;
  cls: string;
}

<div class="page-content fadeBody" *ngIf="isDataAvailable">
  <div class="container">
    <a
      class="desktop-show mobile-hide applicationColor"
      style="position: absolute; font-size: 20px"
      (click)="clearBasket()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >
    <a
      class="desktop-show mobile-hide applicationColor"
      *ngIf="continueActive"
      style="font-size: 20px; float: right"
      (click)="next()"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>
    <a
      class="desktop-show mobile-hide"
      *ngIf="!continueActive"
      style="pointer-events: none; color: gray; font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>

    <p class="desktop-hide mobile-show" style="text-align: center; font-size: 40px; font-weight: 500">
      {{ 'SFO_ChooseGiftcard' | t | async }}
    </p>
    <p class="desktop-show mobile-hide" style="text-align: center; font-size: 40px; font-weight: 500; margin-left: 9%">
      {{ 'SFO_ChooseGiftcard' | t | async }}
    </p>

    <h2 style="text-align: center; margin-top: 20px">
      <span class="v-middle">{{ 'SFO_ChooseGiftcardDescription' | t | async }}</span>
    </h2>

    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />
  </div>
  <app-expandable-media-list [data]="data">
    <ng-template #itemTemplate let-card>
      <div
        class="card my-0 d-flex flex-column {{ card.item.Selected ? 'active' : '' }} grow"
        (click)="giftcardClicked(card.item)"
        (mouseenter)="mouseEnter(card.item)"
        (mouseleave)="mouseLeave(card.item)">
        <ng-template #roomPriceFromTempalte>
          {{ 'COM_Price' | t | async }}
          <mark class="right">{{ card.item.Price | price | async }}</mark>
        </ng-template>
        <div class="flex-grow-1">
          <div class="card-image">
            <div class="centered-image">
              <!--Description overlay-->
              <div
                *ngIf="card.item.ShowDescription || card.item.Selected"
                class="description"
                style="
                  background-color: rgba(0, 0, 0, 0.7);
                  position: absolute;
                  width: 100%;
                  height: 14rem;
                  padding: 20px;
                ">
                <p [innerHTML]="card.item.Description" style="overflow-wrap: break-word; color: white"></p>
              </div>

              <!--Image design-->
              <img *ngIf="card.item.ImageUrl" [src]="card.item.ImageUrl" draggable="false" />
              <div
                *ngIf="!card.item.ImageUrl"
                style="width: 100%; background-color: gray; height: 100%; text-align: center; line-height: 220px">
                <div
                  style="
                    font-size: 100px;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: normal;
                    margin-right: 70px;
                  ">
                  <span class="fa fa-camera" style="font-size: 100px; color: #999999"></span>
                  <h2 style="margin-left: 70px; color: #999999">
                    {{ 'RPA_Image' | t | async }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="overel-container">
              <div class="overel overel-right">
                <button
                  *ngIf="!card.item.Selected"
                  style="pointer-events: none"
                  type="button"
                  class="btn btn-primary btn-mini">
                  {{ 'COM_AddToBasket' | t | async }}
                </button>
                <button
                  *ngIf="card.item.Selected"
                  style="pointer-events: none"
                  type="button"
                  class="btn btn-success btn-mini">
                  {{ 'COM_AddedToBasket' | t | async }}
                </button>
              </div>
            </div>
          </div>
          <div class="card-header">
            <h3 class="text-primary">{{ card.item.Name }}</h3>
          </div>
        </div>
        <hr style="width: 90%; margin-left: 5%; margin-bottom: 20px; border-width: 2px" />
        <div class="card-body">
          <h3>
            <ng-container [ngTemplateOutlet]="roomPriceFromTempalte"></ng-container>
          </h3>
        </div>
      </div>
    </ng-template>
  </app-expandable-media-list>
  <div class="container text-center" *ngIf="continueActive" style="margin-bottom: 100px">
    <div class="section">
      <button type="button" class="btn btn-primary with-shadow" (click)="next()">
        {{ 'COM_Continue' | t | async }}
      </button>
    </div>
  </div>
</div>

<ng-template #messageHotelTermsTempalte let-message>
  <div class="dialog-container-table">
    <div class="dialog-header-table">
      {{ message.name }}
    </div>
    <div class="dialog-table">
      <div class="dialog-body-table">
        <p>{{ 'SFO_NumberOfGiftcards' | t | async }}</p>
        <div class="with-toggle focusable">
          <input
            type="number"
            min="1"
            max="50"
            maxlength="2"
            [value]="message.count"
            [ngModel]="message.count"
            (ngModelChange)="message.count = $event" />
        </div>
      </div>
      <div class="text-center dialog-footer-table">
        <button
          style="min-width: 150px"
          class="default btn btn-primary with-shadow mb-0"
          type="button"
          (click)="message.close()">
          {{ 'PAR_Cancel' | t | async }}
        </button>
        <button
          style="min-width: 150px; margin-left: 5px"
          class="default btn btn-primary with-shadow mb-0"
          type="button"
          (click)="message.accept(message)">
          {{ 'COM_Continue' | t | async }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateHelper, Guid } from '../../../helpers';
import { ApplicationStateService, BasketService, BookingService, Loader, OriginEnum } from '../../services';
import { ConfigService } from '../config';
import { LocaleService } from '../locale';
import { environment } from '../../../../environments/environment';
import { GiftcardPaymentModel, ReservationType } from './reservation.service';
import { lastValueFrom } from 'rxjs';
import { NavigationHelper } from 'src/app/helpers/navigation-helper';

@Injectable()
export class PaymentService {
  constructor(
    private applicationService: ApplicationStateService,
    private http: HttpClient,
    private config: ConfigService,
    private localeService: LocaleService,
    private loader: Loader,
    private navigationHelper: NavigationHelper,
    private bookingService: BookingService,
    private basketService: BasketService,
  ) {}

  async makeNetsEasyPaymentUrl(
    origin: any,
    resGuid: Guid,
    reservation: ReservationType | undefined,
    giftcardNumber: string,
  ) {
    let paymentFlow = 2;
    let endDate = '';
    if (reservation) {
      paymentFlow = reservation.PaymentFlowToUse;

      if (reservation.rooms.length !== 0) {
        const date = DateHelper.FromAspDate(reservation.rooms[0].departure);
        endDate = DateHelper.toServerDateFormatString(date);
      } else {
        endDate = DateHelper.toServerDateFormatString(new Date());
      }
    }

    const [appSettings] = await Promise.all([this.config.getAppSettings()]);
    const isPaymentLink = this.applicationService.Origin === OriginEnum.PaymentLink ? true : false;

    const res = await lastValueFrom(
      this.http.get<PaymentModel>(
        `${
          environment.production ? appSettings.ECommerceDataProvider : 'https://localhost:44343'
        }/HandleEasyPayment?hotelCode=${
          this.applicationService.CurrentHotelCode
        }&resGuid=${resGuid}&langCode=${await this.localeService.getLocale()}&paymentFlow=${paymentFlow}&endDate=${endDate}&giftcardNumber=${giftcardNumber}&isPaymentLink=${isPaymentLink}`,
      ),
    );

    return res;
  }

  async makeNetAxeptPaymentUrl(origin: any, reservation: ReservationType, giftcardNumber: string) {
    let paymentFlow = 2;
    let endDate = '';
    if (reservation) {
      paymentFlow = reservation.PaymentFlowToUse;

      if (reservation.rooms.length !== 0) {
        const date = DateHelper.FromAspDate(reservation.rooms[0].departure);
        endDate = DateHelper.toServerDateFormatString(date);
      } else {
        endDate = DateHelper.toServerDateFormatString(new Date());
      }
    }

    const isPaymentLink = this.applicationService.Origin === OriginEnum.PaymentLink ? true : false;

    const [appSettings] = await Promise.all([this.config.getAppSettings()]);
    const res = await lastValueFrom(
      this.http.get<AxeptPaymentResponseModel>(
        `${
          environment.production ? appSettings.ECommerceDataProvider : 'https://localhost:44343'
        }/HandleAxeptPayment?hotelCode=${this.applicationService.CurrentHotelCode}&resGuid=${
          reservation.resGuid
        }&langCode=${await this.localeService.getLocale()}&paymentFlow=${paymentFlow}&endDate=${endDate}&giftcardNumber=${giftcardNumber}&isPaymentLink=${isPaymentLink}`,
      ),
    );

    return res;
  }

  async startupPayment(giftcardNumber = '') {
    window.scrollTo(0, 0);
    if (this.applicationService.UseNetsEasy && this.applicationService.Origin !== OriginEnum.GiftCard) {
      await this.loader.using(async () => {
        try {
          // If the basketservice.value is null, we should send the customer back to payment-link window, as we then know that the customer was send from where.
          // For the basketservice not to be null, the customer must have been going through the onlinebooking steps.
          const basket = this.basketService.get();
          if (basket === null) {
            this.applicationService.Origin = OriginEnum.PaymentLink;
            if (this.applicationService.ReservationId && this.applicationService.ReservationId !== '') {
              this.applicationService.ReservationGuid = this.applicationService.ReservationId as Guid;
            }
          }

          const sessionReservation = this.bookingService.getReservationOrFail();

          const p = await this.makeNetsEasyPaymentUrl(
            this.applicationService.Origin,
            sessionReservation.resGuid!,
            sessionReservation,
            giftcardNumber,
          );

          if (p) {
            window.open(p.HostedPaymentPageUrl, '_self');
          }
        } catch (ex) {
          if ((ex as Error).message.includes('Reservation must be defined')) {
            await this.navigationHelper.navigateToPage('search');
          } else {
            await this.navigationHelper.navigateToPage('payment-failed');
          }
        }
      });
    } else if (this.applicationService.UseNetsEasy && this.applicationService.Origin === OriginEnum.GiftCard) {
      await this.loader.using(async () => {
        // If the basketservice.value is null, we should send the customer back to payment-link window, as we then know that the customer was send from where.
        // For the basketservice not to be null, the customer must have been going through the onlinebooking steps.
        const basket = this.basketService.get();
        if (basket === null) {
          this.applicationService.Origin = OriginEnum.PaymentLink;
          if (this.applicationService.ReservationId && this.applicationService.ReservationId !== '') {
            this.applicationService.ReservationGuid = this.applicationService.ReservationId as Guid;
          }
        }

        const p = this.bookingService.giftcardEasyInfo.get();

        if (p) {
          window.open(p.HostedPaymentPageUrl, '_self');
        }
      });
    } else if (this.applicationService.UseNetAxept && this.applicationService.Origin !== OriginEnum.GiftCard) {
      await this.loader.using(async () => {
        try {
          // If the basketservice.value is null, we should send the customer back to payment-link window, as we then know that the customer was send from where.
          // For the basketservice not to be null, the customer must have been going through the onlinebooking steps.
          const basket = this.basketService.get();
          if (basket === null) {
            this.applicationService.Origin = OriginEnum.PaymentLink;
            if (this.applicationService.ReservationId && this.applicationService.ReservationId !== '') {
              this.applicationService.ReservationGuid = this.applicationService.ReservationId as Guid;
            }
          }

          const reservation = this.bookingService.getReservationOrFail();

          const p = await this.makeNetAxeptPaymentUrl(this.applicationService.Origin, reservation, giftcardNumber);
          window.open(p.PaymentUrl, '_self');
        } catch (ex) {
          await this.navigationHelper.navigateToPage('payment-failed');
        }
      });
    } else if (this.applicationService.UseNetAxept && this.applicationService.Origin === OriginEnum.GiftCard) {
      await this.loader.using(async () => {
        window.open(this.applicationService.GiftcardPayment, '_self');
      });
    }
  }
}

export interface AxeptPaymentResponseModel {
  PaymentUrl: string;
  orderId: string;
  paidAmount: number;
}

export interface PaymentInfo {
  cardNumber: string;
  cardCvc: string;
  cardExpMonth: string;
  cardExpYear: string;
}

export interface PaymentModel {
  PaymentId: string;
  CheckoutKey?: any;
  HostedPaymentPageUrl: string;
  Error: string;
  GiftcardPaymentModel: GiftcardPaymentModel;
}

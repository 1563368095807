export class ConfigHandler {
  static Apply<T>(target: T, source: Partial<T>) {
    const changes: { [K in keyof T]?: boolean } = {};
    for (const key of Object.keys(source)) {
      const k = key as keyof T;
      const val = source[k] as T[keyof T] | undefined;
      if (val !== undefined && target[k] !== val) {
        target[k] = val;
        changes[k] = true;
      }
    }
    return changes;
  }
}

<div class="page-content add-on-page fadeBody">
  <div class="container">
    <a class="desktop-show mobile-hide applicationColor" style="position: absolute; font-size: 20px" (click)="goBack()"
      ><i class="fas fa-arrow-left"></i>{{ 'COM_Back' | t | async }}</a
    >
    <a
      class="desktop-show mobile-hide applicationColor"
      (click)="onMoveToNextClick()"
      style="font-size: 20px; float: right"
      >{{ 'COM_Continue' | t | async }}<i style="margin-left: 10px" class="fas fa-arrow-right"></i
    ></a>
    <p class="desktop-show mobile-hide" style="text-align: center; font-size: 40px; font-weight: 500; margin-left: 10%">
      {{ 'APA_ChooseYourAddOns' | t | async }}
    </p>
    <p class="desktop-hide mobile-show" style="text-align: center; font-size: 40px; font-weight: 500">
      {{ 'APA_ChooseYourAddOns' | t | async }}
    </p>
    <hr
      style="
        height: 2.5px;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(transparent),
          to(transparent),
          color-stop(50%, var(--primary-application-color))
        );
      " />
  </div>

  <ng-template [ngIf]="addOns$" let-addOns$>
    <app-expandable-media-list [data]="addOns$ | async">
      <ng-template #itemTemplate let-addOn>
        <app-add-on-card [addOn]="addOn.item" (itemSelect)="onItemSelect($event)"></app-add-on-card>
      </ng-template>
    </app-expandable-media-list>
  </ng-template>

  <div class="container text-center" style="margin-bottom: 70px">
    <div class="section">
      <button type="button" class="btn btn-primary with-shadow" (click)="onMoveToNextClick()">
        {{ 'COM_Continue' | t | async }}
      </button>
    </div>
  </div>
</div>

<div class="calendar" [ngClass]="{ full: full }">
  <ng-template [ngIf]="months.length > 0">
    <div *ngIf="showLoader">
      <div class="calendar-loading-overlay"></div>

      <div class="loaderbox-small">
        <div class="spinner-box-small">
          <div class="blue-orbit-small leo-small"></div>

          <div class="green-orbit-small leo-small"></div>

          <div class="red-orbit-small leo-small"></div>

          <div class="white-orbit-small w1 leo-small"></div>
          <div class="white-orbit-small w2 leo-small"></div>
          <div class="white-orbit-small w3 leo-small"></div>
        </div>
      </div>

      <div class="loaderbox-small">
        <div class="sk-chase-small">
          <div class="sk-chase-dot-small"></div>
          <div class="sk-chase-dot-small"></div>
          <div class="sk-chase-dot-small"></div>
          <div class="sk-chase-dot-small"></div>
          <div class="sk-chase-dot-small"></div>
          <div class="sk-chase-dot-small"></div>
        </div>
      </div>

      <p class="load-message-small">{{ 'LOA_Availability' | t | async }}</p>
    </div>
    <div *ngIf="full" class="calendar-weekdays text-primary">
      <div class="calendar-week">
        <div *ngFor="let day of days" class="calendar-day">{{ day }}</div>
      </div>
    </div>
    <div class="calendar-content row" (scroll)="onCalendarScroll($event)" #scrollContainer>
      <div
        *ngFor="let month of months; first as first; last as last"
        class="calendar-month col s12 {{ !full && months.length > 1 ? 'l6' : 'l12' }}">
        <ng-template [ngIf]="month | async" let-m>
          <div class="calendar-header text-primary" *ngIf="showLoader">
            <button *ngIf="!full && first" type="button" disabled class="btn btn-round calendar-btn left">
              <i class="fas fa-chevron-left"></i>
            </button>
            <div class="form-text">{{ m.name }}</div>
            <button *ngIf="!full && last" type="button" disabled class="btn btn-round calendar-btn right">
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
          <div class="calendar-header text-primary" *ngIf="!showLoader">
            <button
              *ngIf="!full && first"
              type="button"
              class="btn btn-round calendar-btn left"
              (click)="onPrevMonthClick()">
              <i class="fas fa-chevron-left"></i>
            </button>
            <div class="form-text">{{ m.name }}</div>
            <button
              *ngIf="!full && last"
              type="button"
              class="btn btn-round calendar-btn right"
              (click)="onNextMonthClick()">
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
          <div class="calendar-weekdays text-primary">
            <div class="calendar-week">
              <div *ngFor="let day of days" class="calendar-day">{{ day }}</div>
            </div>
          </div>
          <div class="calendar-weeks">
            <div *ngFor="let week of m.weeks" class="calendar-week">
              <div
                *ngFor="let day of week.days"
                class="calendar-day {{ day.cls }}"
                (click)="onDayClick(day)"
                (mousemove)="onMouseMove(day)">
                <div class="calendar-day-text">
                  {{ day.date.getDate() }}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="showLegend" class="calendar-legend">
      <div class="calendar-legend-item">
        <span class="legend-icon occupied"></span>
        {{ 'CAL_Occupied' | t | async }}
      </div>
      <div class="calendar-legend-item">
        <span class="legend-icon arrival"></span>
        {{ 'CAL_Arrival' | t | async }}
      </div>
      <div class="calendar-legend-item">
        <span class="legend-icon departure"></span>
        {{ 'CAL_Departure' | t | async }}
      </div>
    </div>
  </ng-template>
</div>

import { Injectable } from '@angular/core';
import { LogService } from '@com/logging';
import { RoomGuests } from 'src/app/helpers';
import { environment } from 'src/environments/environment';
import { DataConfigService, IConfigService } from './data-config.service';

@Injectable()
export class ConfigService implements IConfigService {
  private version = environment.version;
  private defaultFavicon = this.getCurrentFavicon() || 'favicon.ico';
  private styleElement: HTMLStyleElement | null = null;

  constructor(private log: LogService, private dataConfigService: DataConfigService) {}

  async getConfiguration() {
    const res = await this.dataConfigService.getConfiguration();
    return res;
  }

  async getAppSettings() {
    const res = (await this.getConfiguration()).AppSettings;
    if (!environment.production && this.urlMustBeModified(res.WidgetHandlerUrl)) {
      this.log.debug(
        `Not production environment; prefixing URLs with ${environment.testBaseUrl}: WidgetHandler, BookingService, CultureHandler, CustomerService`,
      );
      res.WidgetHandlerUrl = environment.testBaseUrl + res.WidgetHandlerUrl;
      res.BookingServiceUrl = environment.testBaseUrl + res.BookingServiceUrl;
      res.CultureHandlerUrl = environment.testBaseUrl + res.CultureHandlerUrl;
      res.CustomerServiceUrl = environment.testBaseUrl + res.CustomerServiceUrl;
    }

    return res;
  }

  urlMustBeModified(url: { length: number }): boolean {
    return url.length < 25;
  }

  async getCustomerParams() {
    return (await this.getConfiguration()).CustomerParams;
  }

  async getLoggingParams() {
    const res = (await this.getConfiguration()).Logging;
    if (!environment.production && this.urlMustBeModified(res.LoggingServiceUrl)) {
      res.LoggingServiceUrl = environment.testBaseUrl + res.LoggingServiceUrl;
    }

    return res;
  }

  async getPaymentMethods() {
    return (await this.getConfiguration()).PaymentMethods;
  }

  async getInitialSearchParams() {
    return (await this.getConfiguration()).InitialSearchParams;
  }

  async getSearchParams() {
    return (await this.getConfiguration()).SearchParams;
  }

  async getGaParams() {
    return (await this.getConfiguration()).GaParams;
  }

  async getDefaultRoomGuests() {
    return new RoomGuests([2, 0, 0, 0, 0, 0]);
  }

  applyStyles(styles: string) {
    if (!this.styleElement) {
      this.styleElement = document.createElement('style');
    }
    this.styleElement.appendChild(document.createTextNode(styles));
    if (!document.head) {
      throw new Error('Failed to set custom styles because head in not available');
    }
    document.head.appendChild(this.styleElement);
  }

  applyFavicon(path: string | undefined) {
    const link = this.getFaviconElement();
    if (!link) {
      throw new Error('Favicon is not declared in HTML');
    }
    link.setAttribute('href', path || this.defaultFavicon);
  }

  getVersion() {
    return this.version;
  }

  getVersionAsNumber() {
    return `0.${this.version.replace(/\./g, '')}`;
  }

  private getFaviconElement() {
    return document.getElementById('favicon');
  }

  private getCurrentFavicon() {
    const link = this.getFaviconElement();
    return link ? link.getAttribute('href') : undefined;
  }

  static AddPixel(src: string) {
    const elem = document.createElement('img');
    elem.setAttribute('src', src);
    elem.setAttribute('height', '1');
    elem.setAttribute('width', '1');
    elem.setAttribute('visibility', 'hidden');
    document.body.appendChild(elem);
  }
}

export type CultureCode = 'CC-cc';
